// import { useEffect, useState } from "react"
// import ReactModal from "react-modal"
// import './Home.css'
// import { useNavigate, useLocation } from "react-router-dom"
// import TestSeries from "./TestSeries"

// const Home = () => {
//     //1. register email -> email verification model (home) -> set password -> goal -> mobile model (home) -> otp -> home
//     //2. register mobile -> otp -> goal -> email model (home) -> email verification model (home) -> set password -> home
//     //3. register login -> forgot password -> email verification model (home) -> set password -> home
//     const navigate = useNavigate()
//     const { state } = useLocation();
//     const [openEmailModel, setOpenEmailModel] = useState(false)
//     const [openEmailVerificationModel, setOpenEmailVerificationModel] = useState(false)
//     const [openMobileModel, setOpenMobileModel] = useState(false)
//     const [enableEmailBtn, setEnableEmailBtn] = useState(false)
//     const [enableMobileBtn, setEnableMobileBtn] = useState(false)
//     const [fullName, setFullName] = useState("")
//     const [email, setEmail] = useState(state.email)
//     const [mobile, setMobile] = useState(state.mobile)
//     const [modelType, setModelType] = useState(state.modelType)

//     useEffect( () => {
//         if (modelType !== undefined && modelType !== "" && modelType == "email") {
//             console.log("1 " + modelType)
//             setOpenEmailModel(true)
//         } else if (modelType !== undefined && modelType !== "" && modelType == "mobile") {
//             console.log("2 " + modelType)
//             setOpenMobileModel(true)
//         } else if (modelType !== undefined && modelType !== "" && modelType == "email-verification") {
//             console.log("3 " + modelType)
//             setOpenEmailVerificationModel(true)
//         } else {
//             console.log("4 " + modelType)
//         }
//     },[modelType])

//     const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

//     const handleCloseEmailModel = () => {
//         //sent email and close email dialog and open verification model
//         fetchEmailApi()
//         // setOpenEmailModel(false)
//         // handleOpenEmailVerificationModel()
//     };

//     const fetchEmailApi = async () => {
//         try {
//             let data = {"email_id": email, "mobile_number": mobile, "full_name": fullName};
//             let url = process.env.REACT_APP_PINNACLE_BASE_URL + '/email-verification-mobile-fullname';
//             console.log(url);
//             const response = await fetch(url, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(data),
//             });
    
//             console.log('Response Status:', response.status);
    
//             if (response.ok) { 
//                 const jsonData = await response.json();
//                 console.log('JSON Data:', jsonData);
//                 console.log("status " + jsonData.status);
//                 if (jsonData.status === 'success') {
//                     //show verification model
//                     setOpenEmailModel(false)
//                     handleOpenEmailVerificationModel()
//                 } else {
//                     //failed to sent email
//                     console.log('failed to sent email')
//                 }
//             } else {
//                 console.log('Response Error:', response.statusText);
//             }
//         } catch (err) {
//             console.log('Error fetching data:');
//             console.error(err);
//         }
        
//     }

//     const handleOpenEmailModel = () => { 
//         setOpenEmailModel(true)
//     };
 
//     const handleOpenEmailVerificationModel = () => {
//         setOpenEmailVerificationModel(true)
//     };

//     const handleCloseEmailVerificationModel = () => {
//         setOpenEmailVerificationModel(false)
//     };

//     const setPassword = () => {
//         handleCloseEmailVerificationModel()
//         navigate("/", {state: {keyStr: "setpassword"}})
//     }

//     const handleFullName = (e) => {
//         setFullName(e.target.value)
//         if (e.target.value.length > 0 && email.length > 0 && isEmail(email)) {
//             setEnableEmailBtn(true)
//         } else {
//             setEnableEmailBtn(false)
//         }
//     }

//     const handleEmail = (e) => {
//         setEmail(e.target.value)
//         if (e.target.value.length > 0 && fullName.length > 0 && isEmail(e.target.value)) {
//             setEnableEmailBtn(true)
//         } else {
//             setEnableEmailBtn(false)
//         }
//     }

//     const handleMobile = (e) => {
//         setMobile(e.target.value.slice(0, 10));
//         if (e.target.value.length >= 10 && fullName.length > 0) {
//             setEnableMobileBtn(true)
//         } else {
//             setEnableMobileBtn(false)
//         }
//     }

//     const handleCloseMobileModel = () => {
//         fetchMobileApi()
        
//     }

//     const fetchMobileApi = async () => {
//         try {
//             let data = {"email_id": email, "mobile_number": mobile, "full_name": fullName};
//             let url = process.env.REACT_APP_PINNACLE_BASE_URL + '/mobile-verification-email-fullname';
//             console.log(url);
//             const response = await fetch(url, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(data),
//             });
    
//             console.log('Response Status:', response.status);
    
//             if (response.ok) { 
//                 const jsonData = await response.json();
//                 console.log('JSON Data:', jsonData);
//                 console.log("status " + jsonData.status);
//                 if (jsonData.status === 'success') {
//                     //show verification model
//                     setOpenMobileModel(false)
//                     navigate("/", {state: {route: "otp", email: email, mobile: mobile, otp: jsonData.otp}})
//                     //handleOpenEmailVerificationModel()
//                 } else {
//                     //failed to sent email
//                     console.log('failed to sent email')
//                 }
//             } else {
//                 console.log('Response Error:', response.statusText);
//             }
//         } catch (err) {
//             console.log('Error fetching data:');
//             console.error(err);
//         }
        
//     }

//     return (
//         <div>
//             <TestSeries/>
//             <ReactModal 
//                 isOpen={openEmailModel} 
//                 contentLabel="Minimal Modal Example"
//                 className="Modal"
//                 overlayClassName="Overlay"
//             >
//                 <div>
                    
//                     <span className="sign-up-span">Sign-up incomplete !</span>
                    
//                     <input
//                         onChange={handleFullName}
//                         value={fullName} 
//                         className="name-input" 
//                         type="text" 
//                         placeholder="Enter complete name"/>
                    
//                     <input
//                         onChange={handleEmail}
//                         value={email}
//                         className="name-input" 
//                         type="text" 
//                         placeholder="Enter email address"/>
                    
//                     <button 
//                         onClick={handleCloseEmailModel} 
//                         className={ "continue-button-" + (enableEmailBtn ? 'enable' : 'disable') } 
//                         type="submit" >continue</button>
                    
//                 </div>
//             </ReactModal>
//             <ReactModal 
//                 isOpen={openEmailVerificationModel} 
//                 contentLabel="Minimal Modal Example"
//                 className="Modal"
//                 overlayClassName="Overlay"
//             >
//                 <div>
//                     <span className="sign-up-span">Sign-up incomplete !</span>
//                     <span className="email-span">email verification</span>
//                     <span className="email-id-span">{email}</span>
//                     <span className="instruction-span">We have sent you verification email in your email ID {email}</span>
//                     <span className="instruction-span">Check your email inbox and verify it.</span>

//                 </div>
//             </ReactModal>
//             <ReactModal 
//                 isOpen={openMobileModel} 
//                 contentLabel="Minimal Modal Example"
//                 className="Modal"
//                 overlayClassName="Overlay"
//             >
//                 <div>
                    
//                     <span className="sign-up-span">Sign-up incomplete !</span>
                    
//                     <input
//                         onChange={handleFullName}
//                         value={fullName} 
//                         className="name-input" 
//                         type="text" 
//                         placeholder="Enter complete name"/>
                    
//                     <input
//                         onChange={handleMobile}
//                         value={mobile}
//                         className="name-input" 
//                         type="number" 
//                         placeholder="Enter mobile number"/>
                    
//                     <button 
//                         onClick={handleCloseMobileModel} 
//                         className={ "continue-button-" + (enableMobileBtn ? 'enable' : 'disable') } 
//                         type="submit" >continue</button>
                    
//                 </div>
//             </ReactModal>
//         </div>
//     );
// }

// export default Home;




import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ProductImages from "./ProductImages";
import ProductInfo from "./ProductInfo";
import ProductReviews from "./ProductReviews";
import RelatedProducts from "./RelatedProducts";
import Footer from "./Footer";
import classes from "./ProductDetails.module.css";
import Layout from "./Layout";

const ProductDetails = () => {
  const [filteredData, setFilteredData] = useState(null);
  const [selectedExams, setSelectedExams] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedMode, setSelectedMode] = useState([]);

  // useEffect(() => {
  //   console.log("selectedExams", selectedExams);
  // }, [selectedExams]);

  return (
    <Layout>
      <div>
        <ProductInfo />
      </div>
    </Layout>
  );
};

export default ProductDetails;
