// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TermCondition_term_condition_main_div__Um2\\+9{
    margin-top: 1rem;
    font-size: 14px;
}

.TermCondition_term_condition_main_div__Um2\\+9 h1{
    font-size: 20px;
    color: black;
}

.TermCondition_term_condition_main_div__Um2\\+9 h2{
    font-size: 20px;
    color: black;
}

.TermCondition_term_condition_main_div__Um2\\+9 strong{
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/component/TermCondition.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".term_condition_main_div{\n    margin-top: 1rem;\n    font-size: 14px;\n}\n\n.term_condition_main_div h1{\n    font-size: 20px;\n    color: black;\n}\n\n.term_condition_main_div h2{\n    font-size: 20px;\n    color: black;\n}\n\n.term_condition_main_div strong{\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"term_condition_main_div": `TermCondition_term_condition_main_div__Um2+9`
};
export default ___CSS_LOADER_EXPORT___;
