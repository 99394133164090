// import React, { useState } from "react";
// import classes from "./AddPaperCodeCglTier2.module.css";
// import { Row, Col, Modal, Button, Table } from "react-bootstrap";
// // import Datepicker from 'react-datepicker';
// // import 'react-datepicker/dist/react-datepicker.css';

// const Addpapercodecgltier2 = () => {
//   const [subjects, setSubjects] = [];
//   const [selectedSubjects, setSelectedSubjects] = useState([]);
//   const [examMode, setExamMode] = useState([]);
//   const [selectedExamMode, setSelectedExamMode] = useState([]);
//   const [paperCode, setPaperCode] = useState("");
//   const [paperTitle, setPaperTitle] = useState("");
//   const [totalQuestion, setTotalQuestion] = useState("");
//   const [totalMarks, setTotalMarks] = useState("");
//   const [totalTime, setTotalTime] = useState("");
//   const [selectedLanguage, setSelectedLanguage] = useState([]);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [selectedActiveStatus, setSelectedActiveStatus] = useState(null);
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(12.0);
//   const [selectedEndDate, setSelectedEndDate] = useState(null);
//   const [selectedEndTime, setSelectedEndTime] = useState(12.0);
//   const [formatDate, setFormatDate] = useState();

//   const [startDate, setStartDate] = useState("");
//   const [startTime, setStartTime] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [endTime, setEndTime] = useState("");

//   const [insertPaperCode, setInsertPaperCode] = useState();
//   // const [isChecked1, setIsChecked1] = useState();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // log the form data
//     // console.log("handleSubmit:");
//     // console.log("SELECTED SUBJECT", selectedSubjects);
//     // console.log("paperCode " + paperCode);
//     // console.log("Start Time: " + startTime);
//     // console.log(selectedSubjects);
//     // console.log(selectedExamMode);
//     // console.log(paperCode);
//     // console.log(paperTitle);
//     // console.log(totalQuestion);
//     // console.log(totalMarks);
//     // console.log(totalTime);
//     // console.log(selectedLanguage);
//     // console.log(selectedOption);
//     // console.log(selectedActiveStatus);
//     // console.log(selectedDate);
//     // console.log(selectedTime);
//     // console.log(selectedEndDate);
//     // console.log(selectedEndTime);
//     // console.log(formatDate);
//     // console.log(startDate);
//     // console.log(startTime);
//     // console.log(endDate);
//     // console.log(endTime);

//     let json = {};
//     let data = [];
//     for (let i = 0; i < selectedSubjects.length; i++) {
//       const subject = selectedSubjects[i];
//       console.log("subjects" + subject);
//       console.log("selectedExamMode " + selectedEndTime);

//       let language = " ";
//       for (let l = 0; l < selectedLanguage.length; l++) {
//         const lang = selectedLanguage[i];
//         language += "" + lang.language;
//       }

//       // alert(json)
//     }
//     json = {
//       exam_mode_id: selectedExamMode,
//       section: 1,
//       // subject_id: subject.id,
//       paper_code: paperCode,
//       test_title: paperTitle,
//       questions: totalQuestion,
//       time: totalTime,
//       marks: totalMarks,
//       // language: language,
//       test_type: selectedOption,
//       status: selectedActiveStatus,
//       // start_date: formatDate(selectedDate),
//       start_time: selectedTime,
//       // end_date: formatDate(selectedEndDate),
//       end_time: selectedEndTime,
//     };
//     console.log("JSON DATA", json);
//     alert(json);
//     data.push(json);

//     console.log(data);
//     // insertPaperCode(data)
//   };

//   // const handleSubmit = (e) => {
//   //   e.preventDefault();
//   //     // log the form data
//   //   console.log('handleSubmit:');
//   //   let data = [];
//   //   if (isChecked1) {
//   //     let language = '';
//   //       for (let i = 0; i <selectedLanguage.length; i++){
//   //         const lang = selectedLanguage.length[i];
//   //         language += " " + lang.language;
//   //       }
//   //       let json = {exam_mode_id: selectedExamMode, section: 1, paper_code: paperCode, test_title:paperTitle,
//   //       question:totalQuestion, time:totalTime, marks:setTotalMarks, total_question: totalQuestion,
//   //     total_time:totalTime, total_marks:totalMarks, language: language, test_type: selectedOption,
//   //     status:selectedActiveStatus, start_date: formatDate(selectedDate), start_time:selectedTime,
//   //     end_date:formatDate(selectedEndDate), end_time:selectedEndTime}
//   //   }
//   //   console.log(json);
//   //   data.push(json);
//   // };

//   // const insertPaperCode = async(data) => {
//   //   try {
//   //     const responce = await fetch('')
//   //   } catch (error) {

//   //   }
//   // }

//   const [inputRows, setInputRows] = useState([
//     [
//       { value: "", selectValue: "" },
//       { value: "" },
//       { value: "" },
//       { value: "" },
//     ],
//   ]);

//   const handleAddRow = () => {
//     setInputRows([
//       ...inputRows,
//       [
//         { value: "", selectValue: "" },
//         { value: "" },
//         { value: "" },
//         { value: "" },
//       ],
//     ]);
//   };

//   const handleRemoveRow = (index) => {
//     const updatedInputRows = [...inputRows];
//     updatedInputRows.splice(index, 1);
//     setInputRows(updatedInputRows);
//   };

//   const handleSelectChange = (event, rowIndex) => {
//     const updatedInputRows = [...inputRows];
//     updatedInputRows[rowIndex][0].selectValue = event.target.value;
//     setInputRows(updatedInputRows);
//   };

//   const handleInputChange = (event, rowIndex, columnIndex) => {
//     const updatedInputRows = [...inputRows];
//     updatedInputRows[rowIndex][columnIndex].value = event.target.value;
//     setInputRows(updatedInputRows);
//   };

//   return (
//     <div>
//       <h1 className={classes.create_papercodetier2_h1}>
//         Create Paper Code tier2
//       </h1>
//       {inputRows.map((row, rowIndex) => (
//         <div className={classes.select_form} key={rowIndex}>
//           <select
//             value={row[0].selectValue}
//             onChange={(event) => handleSelectChange(event, rowIndex)}
//             // style={{ width: "200%", marginRight: "10px" }} // Doubled the width of the select tag
//             className={classes.selectClass}
//           >
//             <option value="">Select</option>
//             <option value="1">Section 1</option>
//             <option value="2">Section 2</option>
//             <option value="3">Section 3</option>
//           </select>
//           {row.slice(1).map((input, columnIndex) => (
//             <input
//               key={columnIndex}
//               type="text"
//               value={input.value}
//               onChange={(event) =>
//                 handleInputChange(event, rowIndex, columnIndex + 1)
//               }
//               placeholder={
//                 columnIndex === 0
//                   ? "Enter Question"
//                   : columnIndex === 1
//                   ? "Enter Time"
//                   : columnIndex === 2
//                   ? "Enter Marks"
//                   : `Enter Something ${rowIndex + 1},${columnIndex + 0}...`
//               }
//               style={{ marginRight: "10px", marginTop: "5px" }}
//             />
//           ))}
//           {rowIndex === 0 && (
//             <button className={classes.plus_min_btn_asd} onClick={handleAddRow}>
//               +
//             </button>
//           )}
//           {rowIndex > 0 && ( // Render "-" button for rows other than the first row
//             <button
//               className={classes.minus_min_btn_asd}
//               onClick={() => handleRemoveRow(rowIndex)}
//             >
//               {" "}
//               -{" "}
//             </button>
//           )}
//         </div>
//       ))}
//       <form onSubmit={handleSubmit}>
//         <div className={classes.form_group}>
//           <label className={classes.exam_mode_label}>Exam Mode :</label>
//           <select>
//             <option value="" disabled selected>
//               exam mode
//             </option>
//             <option value="1" className={classes.option1}>
//               Mock Test : Full Length
//             </option>
//             <option value="2" className={classes.option1}>
//               Sectional Test
//             </option>
//             <option value="3" className={classes.option1}>
//               Chapter test
//             </option>
//             <option value="4" className={classes.option1}>
//               PYP - Full length test
//             </option>
//             <option value="5" className={classes.option1}>
//               PYP - Full length (New Pattern)
//             </option>
//             <option className={classes.option1}>Current Affairs Test</option>
//             <option className={classes.option1}>Challening Test</option>
//           </select>
//         </div>

//         <div className={classes.input_div}>
//           <label className={classes.paper_code_label}>Paper Code :</label>
//           <input
//             className={classes.control_form}
//             type="text"
//             name="paper code"
//             placeholder="Enter Paper Code"
//             value={paperCode}
//             onChange={(e) => setPaperCode(e.target.value)}
//           />
//         </div>

//         <div className={classes.paper_title_div}>
//           <label className={classes.paper_title_label}>Paper Title :</label>
//           <input
//             className={classes.control_form}
//             type="text"
//             name="paper title"
//             placeholder="Enter Paper Title"
//             value={paperTitle}
//             onChange={(e) => setPaperTitle(e.target.value)}
//           />
//         </div>

//         <div className={classes.total_ques_div}>
//           <label className={classes.total_ques_label}>Total Question :</label>
//           <input
//             className={classes.control_form}
//             type="text"
//             name="total question"
//             placeholder="Enter Total Question"
//             value={totalQuestion}
//             onChange={(e) => setTotalQuestion(e.target.value)}
//           />
//         </div>

//         <div className={classes.total_marks_div}>
//           <label className={classes.total_marks_label}>Total Marks :</label>
//           <input
//             className={classes.control_form}
//             type="text"
//             name="total marks"
//             placeholder="Enter Total Marks"
//             value={totalMarks}
//             onChange={(e) => setTotalMarks(e.target.value)}
//           />
//         </div>

//         <div className={classes.total_marks_div}>
//           <label className={classes.total_marks_label}>Total Time :</label>
//           <input
//             className={classes.control_form}
//             type="text"
//             name="total Time"
//             value={totalTime}
//             onChange={(e) => setTotalTime(e.target.value)}
//             placeholder="Enter Total Time"
//           />
//         </div>

//         <div className={classes.choose_lang_div}>
//           <label className={classes.choose_lang_label}>Choose language :</label>
//           <select>
//             <option>English</option>
//             <option>Hindi</option>
//           </select>
//         </div>
//         <div>
//           <Row>
//             <Col className={classes.choose_type_col}>Choose type :</Col>
//             <Col>
//               <label className={classes.live_label}>Live</label>
//               <input
//                 className={classes.radio_btn}
//                 type="radio"
//                 id="live"
//                 name="live"
//                 value="live"
//                 style={{ float: "left" }}
//               />
//             </Col>
//             <Col>
//               <label className={classes.live_label}>Mock</label>
//               <input
//                 className={classes.radio_btn}
//                 type="radio"
//                 id="mock"
//                 name="live"
//                 value="mock"
//               />
//             </Col>
//           </Row>
//         </div>

//         <div className={classes.type_status}>
//           <lable className={classes.status_type}>Status :</lable>
//           <select>
//             <option value="" disabled selected>
//               Select Anyone
//             </option>
//             <option>Active</option>
//             <option>Inactive</option>
//           </select>
//         </div>

//         <div>
//           <label>Start Date :</label>
//           {/* <Datepicker selected={selectedDate} onChange={date=>setSelectedDate(date)} showYearDropdown style={{width:"500px"}}/> */}
//           <input
//             className={classes.control_form}
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             required
//           />
//         </div>

//         <div>
//           <label>Paper Start Time :</label>
//           <input
//             className={classes.control_form}
//             type="time"
//             value={startTime}
//             onChange={(e) => setStartTime(e.target.value)}
//             required
//           />
//         </div>

//         <div>
//           <label>End Date :</label>
//           <input
//             className={classes.control_form}
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//           />
//         </div>

//         <div>
//           <label>Paper End Time :</label>
//           <input
//             className={classes.control_form}
//             type="time"
//             value={endTime}
//             onChange={(e) => setEndTime(e.target.value)}
//             required
//           />
//         </div>
//         <div className={classes.sub_btn_div}>
//           <button
//             className={classes.submit_btn}
//             type="submit"
//             name="submit"
//             value="submit"
//           >
//             submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Addpapercodecgltier2;

import React, { useState, useRef } from "react";
import DatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";
import classes from "./AddPaperCodeCglTier2.module.css";
import Swal from "sweetalert2";
import moment from "moment";
import Cookies from "universal-cookie";
// import "react-datepicker/dist/react-datepicker.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-select/dist/css/bootstrap-select.min.css";

import axios from "axios";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";

const Addpapercodecgltier2 = () => {
  const maxSections = 50;

  const [formData, setFormData] = useState({
    list: [],
    time: [],
    marks: [],
    questions: [],
    exam_mode: "",
    paper_code: "",
    test_title: "",
    total_question: "",
    total_marks: "",
    total_time: "",
    test_type: "",
    status: "",
    start_date: "",
    end_date: "",
    choose_language: [],
    start_time: "",
    end_time: "",
  });

  const [sections, setSections] = useState([
    { list: "", time: "", marks: "", questions: "" },
  ]);

  const multiselectRef = useRef();

  // if (multiselectRef.current) {
  //   multiselectRef.current.resetSelectedValues();
  // }

  // document.querySelector('input[name="test_type"]:checked').checked = false;

  // const checkedRadioButton = document.querySelector(
  //   'input[name="test_type"]:checked'
  // );
  // if (checkedRadioButton) {
  //   checkedRadioButton.checked = false;
  // }

  // if (multiselectRef.current) {
  //   multiselectRef.current.resetSelectedValues();
  // }
  console.log("SECTIONS", sections);
  console.log("FORMDATA", formData);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prevent '/' character in the input
    const sanitizedValue = value.replace(/\//g, '-');
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));
  };

  const handleTypeChange = (e) => {
    setFormData({
      ...formData,
      test_type: e.target.value,
    });
  };

  const handleSectionChange = (index, e) => {
    const { name, value } = e.target;
    const newSections = [...sections];
    newSections[index][name] = value;
    setSections(newSections);
  };

  const addSection = () => {
    if (sections.length < maxSections) {
      setSections([
        ...sections,
        { list: "", time: "", marks: "", questions: "" },
      ]);
    } else {
      alert("max sections limit reached");
    }
  };

  const removeSection = (index) => {
    if (sections.length > 1) {
      const newSections = [...sections];
      newSections.splice(index, 1);
      setSections(newSections);
    } else {
      alert("at least one section is required");
    }
  };

  const languageOptions = ["English", "Hindi"];

  const handleLanguageSelect = (selectedList) => {
    setFormData((prevData) => ({
      ...prevData,
      choose_language: selectedList,
    }));
  };

  const handleLanguageRemove = (selectedList) => {
    setFormData((prevData) => ({
      ...prevData,
      choose_language: selectedList,
    }));
  };

  const handleDateChange1 = (date) => {
    console.log("Date Check:", date);
    setFormData({
      ...formData,
      start_date: date,
    });
  };

  const handleDateChange2 = (date) => {
    console.log("Date Check:", date);
    setFormData({
      ...formData,
      end_date: date,
    });
  };

  const handleTimeChange1 = (time) => {
    setFormData({
      ...formData,
      start_time: time,
    });
  };

  const handleTimeChange2 = (time) => {
    setFormData({
      ...formData,
      end_time: time,
    });
  };

  const validateForm = () => {
    const {
      exam_mode,
      paper_code,
      test_title,
      total_question,
      total_marks,
      total_time,
      test_type,
      status,
      start_date,
      start_time,
      end_date,
      end_time,
      choose_language,
    } = formData;
    if (
      sections.some(
        (section) =>
          !section.list || !section.time || !section.marks || !section.questions
      )
    ) {
      alert("Please fill in all fields for each section.");
      return false;
    }
    if (!exam_mode) {
      alert("Please select and exam mode");
      return false;
    }

    if (!paper_code) {
      alert("Please enter a paper code");
      return false;
    }
    if (!test_title) {
      alert("Please enter a paper title");
      return false;
    }

    if (!total_question) {
      alert("Please enter the total number of questions");
      return false;
    }

    if (!total_marks) {
      alert("Please enter the total marks");
      return false;
    }

    if (!total_time) {
      alert("Please enter the total time");
      return false;
    }
    if (!choose_language.length) {
      alert("Please select at least one language");
      return false;
    }

    if (!test_type) {
      alert("please select a test type");
      return false;
    }
    if (!start_date) {
      alert("Please select a start date");
      return false;
    }

    if (!end_date) {
      alert("Please select and end date");
      return false;
    }
    if (!end_time) {
      alert("Please select an end time");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formattedValues = {
      ...formData,
      start_date: formData.start_date
        ? moment(formData.start_date).format("YYYY-MM-DD")
        : null,
      start_time: formData.start_time
        ? moment(formData.start_time).format("HH:mm")
        : null,
      end_date: formData.end_date
        ? moment(formData.end_date).format("YYYY-MM-DD")
        : null,
      end_time: formData.end_time
        ? moment(formData.end_time).format("HH:mm")
        : null,
    };

    try {
      // Format the start and end times

      // Prepare the form data for submission
      const submissionData = {
        ...formattedValues,
        list: Array.isArray(sections)
          ? sections.map((section) => section.list)
          : [],
        time: Array.isArray(sections)
          ? sections.map((section) => section.time)
          : [],
        marks: Array.isArray(sections)
          ? sections.map((section) => section.marks)
          : [],
        questions: Array.isArray(sections)
          ? sections.map((section) => section.questions)
          : [],
        test_title: formData.test_title,
        total_question: formData.total_question,
        total_marks: formData.total_marks,
        choose_language: formData.choose_language,
        test_type: formData.test_type,
        // Add formatted end time
      };

      // Send the data to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/papercode/PaperCodeMasterTableCgltier2`,
        // "http://localhost:5000/papercode/PaperCodeMasterTableCgltier2",
        submissionData
      );
      alert("Paper Code Inserted Successfully");
      console.log(response.data);
      setFormData({
        list: [],
        time: [],
        marks: [],
        questions: [],
        exam_mode: "",
        paper_code: "",
        test_title: "",
        total_question: "",
        total_marks: "",
        total_time: "",
        test_type: "",
        status: "",
        start_date: "",
        end_date: "",
        choose_language: [],
        start_time: "",
        end_time: "",
      });

      setSections([{ list: "", time: "", marks: "", questions: "" }]);

      if (multiselectRef.current) {
        multiselectRef.current.resetSelectedValues();
      }

      const checkedRadioButton = document.querySelector(
        'input[name="test_type"]:checked'
      );
      if (checkedRadioButton) {
        checkedRadioButton.checked = false;
      }
    } catch (error) {
      console.log(error);
      alert("Error submitting the form");
      console.error(
        "There was an error submitting the form:",
        error.response || error.message
      );
    }
  };
  return (
    <div className={classes.content_wrapper}>
    <h1 className={classes.create_paper_tier2_h1}>Create Paper Code Tier 2</h1>
      <form onSubmit={handleSubmit}>
        {sections.map((section, index) => (
          <div className="list_wrapper" key={index}>
            <div className="row">
              <div className="col-xs-5 col-sm-5 col-md-5">
                <div className="form-group">
                  <label htmlFor="list">Section:</label>
                  <select
                    style={{
                      float: "left",
                      width: "100%",
                      borderRadius: "4px",
                      padding: "5px",
                      marginBottom: "15px",
                      border: "1px solid #8080805c",
                      outline: "none",
                    }}
                    name="list"
                    value={section.list}
                    onChange={(e) => handleSectionChange(index, e)}
                    required
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="1">Section 1</option>
                    <option value="2">Section 2</option>
                    <option value="3">Section 3</option>
                  </select>
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2">
                <div className={classes.form_group}>
                  <label htmlFor="questions">Questions:</label>
                  <input
                    type="text"
                    name="questions"
                    className="form-control"
                    placeholder="Enter Questions"
                    value={section.questions}
                    onChange={(e) => handleSectionChange(index, e)}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2">
                <div className={classes.form_group}>
                  <label htmlFor="time">Time:</label>
                  <input
                    type="text"
                    name="time"
                    className="form-control"
                    placeholder="Enter Time"
                    value={section.time}
                    onChange={(e) => handleSectionChange(index, e)}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-md-2">
                <div className={classes.form_group}>
                  <label htmlFor="marks">Marks:</label>
                  <input
                    type="text"
                    name="marks"
                    className="form-control"
                    placeholder="Enter Marks"
                    value={section.marks}
                    onChange={(e) => handleSectionChange(index, e)}
                  />
                </div>
              </div>
              <div className="col-xs-1 col-sm-1 col-md-1">
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => removeSection(index)}
                >
                  -
                </button>
                {index === sections.length - 1 && (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={addSection}
                  >
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}

        <div className={classes.form_group}>
          <label htmlFor="exam_mode">Exam Mode:</label>
          <select
            style={{
              float: "left",
              width: "100%",
              borderRadius: "6px",
              padding: "10px",
              marginBottom: "15px",
              border: "1px solid #8080805c",
              outline: "none",
            }}
            name="exam_mode"
            value={formData.exam_mode}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Exam Mode
            </option>
            <option value="1">Mock test-Full length</option>
            {/* <option value="2">Sectional Test</option>
            <option value="3">Chapter wise Test</option>
            <option value="4">PYP-Full length</option> */}
           <option value="5">PYP-Full length(New Pattern)</option>
          </select>
        </div>

        <div className={classes.form_group}>
          <label htmlFor="paper_code">Paper Code:</label>
          <input
            type="text"
            name="paper_code"
            className="form-control"
            placeholder="Enter Paper Code"
            value={formData.paper_code}
            onChange={handleChange}
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="test_title">Paper Title:</label>
          <input
            type="text"
            name="test_title"
            className="form-control"
            placeholder="Enter Paper Title"
            value={formData.test_title}
            onChange={handleChange}
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="total_question">Total Questions:</label>
          <input
            type="text"
            name="total_question"
            className="form-control"
            placeholder="Enter Total Questions"
            value={formData.total_question}
            onChange={handleChange}
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="total_marks">Total Marks:</label>
          <input
            type="text"
            name="total_marks"
            className="form-control"
            placeholder="Enter Total Marks"
            value={formData.total_marks}
            onChange={handleChange}
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="total_time">Total Time:</label>
          <input
            type="text"
            name="total_time"
            className="form-control"
            placeholder="Enter Total Time"
            value={formData.total_time}
            onChange={handleChange}
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="choose_language">Choose Language:</label>
          <Multiselect
            options={languageOptions}
            ref={multiselectRef}
            onSelect={handleLanguageSelect}
            onRemove={handleLanguageRemove}
            isObject={false} // Since language options are simple strings, not objects
            className="form-control selectpicker"
            required
          />
        </div>

        <div>
          <Row className={classes.choose_raw}>
            <Col className={classes.choose_type_col}>Choose type :</Col>
            <Col>
              <label className={classes.live_label}>Live</label>
              <input
                className={classes.radio_btn}
                type="radio"
                id="live"
                name="test_type"
                value="live"
                onChange={handleTypeChange}
                required
              />
            </Col>
            <Col>
              <label className={classes.live_label}>Mock</label>
              <input
                className={classes.radio_btn}
                type="radio"
                id="mock"
                name="test_type"
                value="mock"
                onChange={handleTypeChange}
                required
              />
            </Col>
          </Row>
        </div>

        <div className={classes.form_group}>
          <label htmlFor="status">Status:</label>
          <select
            style={{
              float: "left",
              width: "100%",
              borderRadius: "4px",
              padding: "5px",
              marginBottom: "15px",
              border: "1px solid #8080805c",
              outline: "none",
            }}
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Status
            </option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>

        <div className={classes.form_group}>
          <label htmlFor="start_date">Start Date:</label>
          <DatePicker
            className={classes.form_group1}
            selected={formData.start_date}
            onChange={handleDateChange1}
            dateFormat="yyyy-MM-dd"
            placeholderText="start date"
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="start_time">Start Time:</label>
          <DatePicker
            className={classes.form_group1}
            selected={formData.start_time}
            onChange={handleTimeChange1}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="HH:mm"
            placeholderText="Start Time"
            required
          />
        </div>

        
        <div className={classes.form_group}>
          <label htmlFor="end_date">End Date:</label>
          <DatePicker
            className={classes.form_group1}
            selected={formData.end_date}
            onChange={handleDateChange2}
            dateFormat="yyyy-MM-dd"
            placeholderText="End Date"
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="end_time">End Time:</label>
          <DatePicker
            className={classes.form_group1}
            selected={formData.end_time}
            onChange={handleTimeChange2}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="HH:mm"
            placeholderText="End Time"
            required
          />
        </div>
        <div className={classes.sub_button} >
          <button type="submit" className="btn btn-success mt-3">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Addpapercodecgltier2;
