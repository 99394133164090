// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.MyTestSeries_my_test_series_main_div__Hucdk {
  display: flex;
  margin-top: 8px;
  gap: 1rem;
  flex-wrap: wrap;
}

.MyTestSeries_my_test_series_ul__QpCmR img {
  height: 11px;
  width: 11px;
  margin: 4px 6px 0 0;
}

.MyTestSeries_my_test_series_li__xbTIR {
  list-style: none;
  color: #000;
  font-size: 14px;
  display: flex;
  margin: 6px 0 18px 0;
}

.MyTestSeries_test_data_div__6JmYg {
  border: 0.2px solid lightsteelblue;
  height: auto;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 8px;
  width: 22rem;
}

.MyTestSeries_test_data_div__6JmYg h3 {
  font-size: 20px;
  font-weight: bold;
  font-feature-settings: "pcap", "c2pc";
  font-variant: all-petite-caps;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: lightsteelblue;
  height: 4.5rem;
}

.MyTestSeries_btn_div__rzez2 {
  list-style: none;
  padding: 8px;
  margin: 0 0 0 68px;
}

.MyTestSeries_show_more_btn__M\\+QMS {
  border: none;
  background-color: lightsteelblue;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 110px;
  height: 36px;
  border-radius: 18px;
}

.MyTestSeries_show_more_btn__M\\+QMS:hover {
  background-color: #4f6ec6;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/component/MyTestSeries.module.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,kCAAkC;EAClC,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,qCAA6B;EAA7B,6BAA6B;EAC7B,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gCAAgC;EAChC,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["\n.my_test_series_main_div {\n  display: flex;\n  margin-top: 8px;\n  gap: 1rem;\n  flex-wrap: wrap;\n}\n\n.my_test_series_ul img {\n  height: 11px;\n  width: 11px;\n  margin: 4px 6px 0 0;\n}\n\n.my_test_series_li {\n  list-style: none;\n  color: #000;\n  font-size: 14px;\n  display: flex;\n  margin: 6px 0 18px 0;\n}\n\n.test_data_div {\n  border: 0.2px solid lightsteelblue;\n  height: auto;\n  color: black;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  margin-bottom: 24px;\n  gap: 8px;\n  width: 22rem;\n}\n\n.test_data_div h3 {\n  font-size: 20px;\n  font-weight: bold;\n  font-variant: all-petite-caps;\n  color: #000;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  background-color: lightsteelblue;\n  height: 4.5rem;\n}\n\n.btn_div {\n  list-style: none;\n  padding: 8px;\n  margin: 0 0 0 68px;\n}\n\n.show_more_btn {\n  border: none;\n  background-color: lightsteelblue;\n  color: #000;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  width: 110px;\n  height: 36px;\n  border-radius: 18px;\n}\n\n.show_more_btn:hover {\n  background-color: #4f6ec6;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"my_test_series_main_div": `MyTestSeries_my_test_series_main_div__Hucdk`,
	"my_test_series_ul": `MyTestSeries_my_test_series_ul__QpCmR`,
	"my_test_series_li": `MyTestSeries_my_test_series_li__xbTIR`,
	"test_data_div": `MyTestSeries_test_data_div__6JmYg`,
	"btn_div": `MyTestSeries_btn_div__rzez2`,
	"show_more_btn": `MyTestSeries_show_more_btn__M+QMS`
};
export default ___CSS_LOADER_EXPORT___;
