import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Form, Button, Alert, Modal, InputGroup, } from "react-bootstrap";
import Home from "../component/Home"
import './EmailVerification.css';
import { useSelector, useDispatch } from "react-redux";
import { addProfile } from "../redux/slices/userProfileSlice";

import { useAuthContext } from "../hooks/useAuthContext";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";

const SetPassword = () => {
  // const [show, setShow] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showMessage, setShowMessage] = useState(null); // State to show the response message
  const [error, setError] = useState(null); // State to show validation errors
  // const dispatch = useDispatch();
  const reduxDispatch = useDispatch();
  const { dispatch } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const userProfile = useSelector((state) => state.userProfile);
  const navigate = useNavigate();

  const handleSetPassword = async (e) => {
    e.preventDefault(); // Prevent form submission
    if (!userProfile._id || !userProfile.verified_mobile_number || !userProfile.verified_email) {
      setError("Please Verify your email address and mobile. ");

      setTimeout(() => {
        setError(null);
        navigate('/login');
      }, 2000);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const data = { _id: userProfile._id, password: password, passwordConfirm: confirmPassword };
    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/set-password",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        console.log("responseData: " , responseData);
        setShowMessage(responseData.message); // Show the response message
        // dispatch(addProfile(responseData.data));
        const token = responseData.token;
        console.log("responseToken: " , token);
        
        localStorage.setItem("userData", JSON.stringify(responseData.data));
        localStorage.setItem("token", token);
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 24);
        localStorage.setItem("expiration", expiration.toISOString());
        // dispatch login action
        console.log("GOOGLE AUTH ADD PROFILE IN REDUX", responseData.data)
        reduxDispatch(addProfile(responseData.data));
        dispatch({ type: "LOGIN", payload: responseData.token });
        

        setTimeout(() => {
          setShowMessage(null);
          navigate('/');
        }, 2000);

      } else {
        setShowMessage(responseData.message);
        console.error("Something went wrong in setting the password");
        throw new Error("Could not set the password");
      }
    } catch (error) {
      console.error("Error setting the password:", error);
      setError(error.message);
    }
  };

  const passwordBody = (
    <Form>
      <h1>Set Password</h1>
      <Form.Group controlId="formPassword">
        <Form.Label>Please enter your password and confirm password.</Form.Label>
        <Row className="m-1">
        <InputGroup>
          <Form.Control
            // type="password"
            type={showPassword ? "text" : "password"} // Dynamically toggle type
            placeholder="Enter your password"
            className="input-field"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ paddingRight: "40px", borderRadius: "5px" }} // Add padding to avoid overlap with the icon
          />
            <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px", // Position icon inside the input box
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    zIndex: "10",
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>
        </Row>
        <Row className="m-1">
        <InputGroup>
          <Form.Control
            // type="password"
            type={showConfirmPassword ? "text" : "password"} // Dynamically toggle type
            placeholder="Confirm your password"
            className="input-field"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ paddingRight: "40px", borderRadius: "5px" }} // Add padding to avoid overlap with the icon
          />
              <InputGroup.Text
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    position: "absolute",
                    right: "10px", // Position icon inside the input box
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    zIndex: "10",
                  }}
                >
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>
        </Row>
        {error && <Alert variant="danger">{error}</Alert>}
        {showMessage && <Alert variant="success">{showMessage}</Alert>}
      </Form.Group>
    </Form>
  );

  const title = <p>Sign-up incomplete</p>
  

  const footer = (
    <Button
      variant="primary"
      onClick={handleSetPassword}
    >
      Submit
    </Button>
  );

  return (
    <div>
      <Home />
      <Modal show={true} onHide={() => {}} backdrop="static" keyboard={false} className="modal-centered">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{passwordBody}</Modal.Body>
        <Modal.Footer>
          {footer}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SetPassword;







// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   Row,
//   Col,
//   Form,
//   Button,
//   Alert,
//   Modal,
// } from "react-bootstrap";

// import Home from "./Home";
// import OverlayModal from "../Component/OverlayModal";
// import './EmailVerification.css'

// import { useSelector } from "react-redux";
// import { useDispatch } from 'react-redux';
// import { addProfile } from "../redux/slices/userProfileSlice";


// const SetPassword = () => {
//   const [show, setShow] = useState(true);
//   const [email, setEmail] = useState("");
//   const [showMessage, setShowMessage] = useState(null); // State to show the response message
//   const dispatch = useDispatch();

//   const items = useSelector((state) => state);
//   console.log("Items", items);
//   const navigate = useNavigate();

//   const handleEmailLogin = async (e) => {
//     e.preventDefault(); // Prevent form submission
//     if(!items.userProfile._id) {
//       navigate('/login');
//     }
//     // const email_id = email;
//     const data = { _id: items.userProfile._id, email_id: email,  };
//     console.log("Items Inside Handler", items.userProfile._id);
//     console.log(data);
//     try {
//       const response = await fetch(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/emailVerificationAfterMobile",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify(data),
//         }
//       );
//       if (response.ok) {
//         const responseData = await response.json();
//         console.log(responseData);

//         setShowMessage(responseData.message); // Show the response message
//         dispatch(addProfile(responseData.data));

//       } else {
//         console.error("Something went wrong in sending email verification");
//         throw new Error("Could not fetch events");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const emailBody = (
//     <Form>
//       <Form.Group controlId="formEmail">
//         <Form.Label>
//           <p>Please enter your password.</p>
//         </Form.Label>
//         <Row className="m-1">
//           <Form.Control
//             type="email"
//             placeholder="enter your email id"
//             className="input-field"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//         </Row>
//       </Form.Group>
//     </Form>
//   );

//   //   const tempOverlayModal = (
//   //     <OverlayModal
//   //       show={modalShow}
//   //       handleClose
//   //       title="Email Verification"
//   //       body={emailBody}
//   //       footer={
//   //         <Button
//   //           variant="primary"
//   //           onClick={() => {
//   //             /* Add save functionality here */
//   //           }}
//   //         >
//   //           Save Changes
//   //         </Button>
//   //       }
//   //     />
//   //   );

//   const title = "Email Verification Required";

//   const footer = (
//     <Button
//       variant="primary"
//       onClick={(e) => {
//         handleEmailLogin(e)
//         /* Add save functionality here */
//       }}
//     >
//       Submit
//     </Button>
//   );

//   return (
//     <div>
//       <Home />
//       <Modal show={show} onHide={() => {}} backdrop="static" keyboard={false} className="modal-centered">
//         <Modal.Header>
//           <Modal.Title>{title}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>{showMessage? showMessage: emailBody}</Modal.Body>
//         <Modal.Footer>
//           {footer || (
//             <>
//               <Button variant="secondary" onClick={() => {}}>
//                 Close
//               </Button>
//               <Button variant="primary" onClick={() => {}}>
//                 Save Changes
//               </Button>
//             </>
//           )}
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default SetPassword;
