import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import classes from "./EditTest.module.css";

import Cookies from "universal-cookie";

function EditTest({ test, srNo, onClose, onUpdate }) {
  const [formData, setFormData] = useState({ ...test });
  console.log(srNo);

  const cookies = new Cookies();

  const token = cookies.get("token");

  useEffect(() => {
    setFormData(test);
  }, [test]);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRadioChange = (value) => {
    setFormData({ ...formData, status: value });
  };

  const handleUpdateTest = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/update_test",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Updated!",
          text: "The test has been updated successfully.",
          showConfirmButton: false,
          timer: 1500,
        });
        onUpdate(formData);
        // setFormData(response.data.data);
        onClose();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while updating the test.",
      });
    }
  };

  return (
    <div className={classes.editContainer}>
      <button onClick={onClose} className={classes.closeEdit}>
        ×
      </button>
      <h2>Edit Test</h2>
      <form onSubmit={handleUpdateTest}>
        <div className={classes.formGroup}>
          <label>Sr. No:</label>
          <input type="text" value={srNo} readOnly disabled />
        </div>
        <div className={classes.formGroup}>
          <label>Order List:</label>
          <input
            type="text"
            name="test_order_list"
            value={formData.test_order_list}
            onChange={handleFormChange}
          />
        </div>
        <div className={classes.formGroup}>
          <label>Exam Mode Name:</label>
          <select
            name="exam_mode_id"
            value={formData.exam_mode_id}
            onChange={handleFormChange}
            disabled
          >
            <option value="1">Mock full length</option>
            <option value="2">Sectional</option>
            <option value="3">chapter wise</option>
            <option value="4">PYP-Full length</option>
            <option value="5">Pyp-Full length(New pattern)</option>
          </select>
        </div>
        <div className={classes.formGroup}>
          <label>Paper Code:</label>
          <input
            type="text"
            name="paper_code"
            value={formData.paper_code}
            onChange={handleFormChange}
            disabled
          />
        </div>
        <div className={classes.formGroup}>
          <label>Paid Status:</label>
          <select
            name="paid_status"
            value={formData.paid_status}
            onChange={handleFormChange}
            // disabled
          >
            <option value="1">Free</option>
            <option value="2">PAID</option>
          </select>
        </div>
        <div className={classes.formGroup}>
          <label>Test Paper Type:</label>
          <select
            name="add_test_type"
            value={formData.add_test_type}
            onChange={handleFormChange}
            disabled
          >
            <option value="1">Practice</option>
            <option value="2">Ranking</option>
          </select>
        </div>
        <div className={classes.formGroupbtn}>
          <label>Paper Status:</label>
          <div className={classes.container}>
            <div className={classes.radioLabel}>
              <input
                type="radio"
                value={1}
                title="Show test to the students"
                checked={formData.status === 1}
                onChange={() => handleRadioChange(1)}
              />
              Active
            </div>
            <div className={classes.radioLabel}>
              <input
                type="radio"
                value={0}
                title="Do not show test to the students"
                checked={formData.status === 0}
                onChange={() => handleRadioChange(0)}
              />
              Inactive
            </div>
          </div>
        </div>
        <div className={classes.bothbtn}>
          <button type="submit" className={classes.submitBtn}>
            Update Test
          </button>
          <button type="button" onClick={onClose} className={classes.cancelBtn}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditTest;
