import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import TagManager from "react-gtm-module";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AuthContextProvider } from "./context/AuthContext";

// Initialize Google Tag Manager
// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID, // Use REACT_APP prefix to properly load environment variables
// };

// // console.log("GTM ID", process.env.REACT_APP_GTM_ID);

// if (tagManagerArgs.gtmId) {
//   console.log("Initializing Google Tag Manager with ID:", tagManagerArgs.gtmId);
//   TagManager.initialize(tagManagerArgs);
// }

function requestNotificationPermission() {
  if ("Notification" in window && Notification.permission !== "granted") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
      } else {
        console.warn("Notification permission denied.");
      }
    });
  }
}

// Register Service Worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/clevertap-sw.js`)
    .then((registration) => {
      console.log(
        "Service Worker registered successfully:",
        registration.scope
      );

      // registration.pushManager.getSubscription().then((subscription) => {
      //   if (!subscription) {
      //     console.log("User not subscribed. Subscribing...");
      //     const vapidPublicKey =
      //       "BFIuz0C0wKocUU9WVA3BBMbZohEUTDykOyuwU0hNy8pVeEy605h3gZh2MnE7scC8ptGiubY4Sp_niuIjCK6Xiio";
      //     const applicationServerKey = urlB64ToUint8Array(vapidPublicKey);

      //     registration.pushManager
      //       .subscribe({
      //         userVisibleOnly: true,
      //         applicationServerKey: applicationServerKey,
      //       })
      //       .then((newSubscription) => {
      //         console.log("User subscribed:", newSubscription);
      //         // Send subscription details to server or CleverTap
      //       })
      //       .catch((error) => {
      //         console.error("Failed to subscribe user:", error);
      //       });
      //   } else {
      //     console.log("User already subscribed:", subscription);
      //   }
      // });
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import TagManager from "react-gtm-module";
// import reportWebVitals from "./reportWebVitals";

// import { Provider } from "react-redux";
// import { store } from "./redux/store";
// import { AuthContextProvider } from "./context/AuthContext";

// function requestNotificationPermission() {
//   if ("Notification" in window && Notification.permission !== "granted") {
//     Notification.requestPermission().then((permission) => {
//       if (permission === "granted") {
//         console.log("Notification permission granted.");
//       } else {
//         console.warn("Notification permission denied.");
//       }
//     });
//   }
// }

// // Register Service Worker
// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register(`${process.env.PUBLIC_URL}/clevertap-sw.js`)
//     .then((registration) => {
//       console.log(
//         "Service Worker registered successfully:",
//         registration.scope
//       );
//     })
//     .catch((error) => {
//       console.error("Service Worker registration failed:", error);
//     });
// }

// // Show CleverTap Notifications Prompt
// if (window.clevertap && window.clevertap.notifications) {
//   window.clevertap.notifications.push({
//     apnsWebPushId: "<apple web push id>",
//     apnsWebPushServiceUrl: "<safari package service url>",
//     titleText: "Would you like to receive Push Notifications?",
//     bodyText:
//       "We promise to only send you relevant content and give you updates on your transactions",
//     okButtonText: "Sign me up!",
//     rejectButtonText: "No thanks",
//     okButtonColor: "#F28046",
//     askAgainTimeInSeconds: 5,
//     serviceWorkerPath: `${process.env.PUBLIC_URL}/clevertap-sw.js`,
//   });
// }

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <AuthContextProvider>
//         <App />
//       </AuthContextProvider>
//     </Provider>
//   </React.StrictMode>
// );

// reportWebVitals();
