import React, { useState } from "react";
import ReactDom from "react-dom";
import classes from "./UpdateTestSeriesProduct.module.css";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Cookies from "universal-cookie";

const Backdrop = ({ handleChangeOverlayModel }) => {
  return (
    <div onClick={handleChangeOverlayModel} className={classes.backdrop}></div>
  );
};

const DeleteTestSeriesProduct = ({
  handleChangeDeleteOverlayModel,
  selectedId,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const cookies = new Cookies();

  const token = cookies.get("token");

  const handleDeleteTestSeriesName = async (selectedId) => {
    setLoading(true);
    setSuccess(false);
    try {
      const data = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/admin/deletetestseriesnamefromproduct",
        {
          id: selectedId,
        },
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.log({ message: "an internal server error occurs", error });
      setLoading(false);
    }
  };

  //   console.log(selectedId);
  return (
    <div className={classes.updateOverlayModel}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h2>DeleteTestSeriesName</h2>
        <RxCross1 onClick={handleChangeDeleteOverlayModel} />
      </div>
      <div>
        {loading ? (
          <ClipLoader
            size={50}
            color={"#123abc"}
            loading={loading}
            radius="9"
            css={{ display: "block", margin: "0 auto" }}
          />
        ) : success ? (
          <div>Deleted Successfully</div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <label>Delete Test Series Name</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <button onClick={handleChangeDeleteOverlayModel}>No</button>
                <button onClick={() => handleDeleteTestSeriesName(selectedId)}>
                  Yes
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <button onClick={handleChangeDeleteOverlayModel} style={{ width: "40%" }}>
        close
      </button>
    </div>
  );
};

const DeleteTestSeriesProductOverlay = ({
  handleChangeDeleteOverlayModel,
  selectedId,
}) => {
  return (
    <>
      {ReactDom.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}

      {ReactDom.createPortal(
        <DeleteTestSeriesProduct
          handleChangeDeleteOverlayModel={handleChangeDeleteOverlayModel}
          selectedId={selectedId}
        />,
        document.getElementById("updatetestseriesinproduct")
      )}
    </>
  );
};

export default DeleteTestSeriesProductOverlay;
