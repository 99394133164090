import { useContext } from "react";
// import { useLocation } from "react-router-dom";
// import Cookies from "universal-cookie";

import { AuthContext } from "../context/AuthContext";
// console.log(AuthContext);

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error("useAuthContext must be used inside an AuthContextProvider");
  }

  return context;
};
