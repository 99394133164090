import { Row, Col } from "react-bootstrap";
import MathElement from "./MathMl";
import { MathJax, MathJaxContext } from "better-react-mathjax";
const SolutionInstruction = ({
  data,
  show_paper,
  questionPaperFun,
  language,
  subject,
}) => {
  // Sanitize the input by removing unwanted characters and tags
  // const sanitizeText = (text) => {
  //   if (!text) return "";
  //   return text
  //     .replace(/<[^>]*>?/gm, "") // Removes HTML tags
  //     .replace(/\\n|\\r|\n|\r/g, " ") // Replace newlines with spaces
  //     .trim(); // Remove leading and trailing whitespace
  // };

  // console.log("================================:", data, language);

  const config = {
    loader: { load: ["input/tex", "input/mml", "output/chtml"] }, // Load both TeX and MathML for MathJax
    tex: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
    },
  };

  const hasHtmlContent = (text) => /<[^>]+>/.test(text);

  const renderMathContent = (content) => {
    return hasHtmlContent(content) ? (
      // If content includes HTML, render with dangerouslySetInnerHTML for mixed content
      <div dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
      // If pure math content, render with MathJax
      <MathJax>{content}</MathJax>
    );
  };

  console.log(data);
  return (
    <>
      <MathJaxContext config={config}>
        {show_paper && (
          <Col md={9} className="ques-row mobile-width100">
            <Row className="questin-paper-div1">
              <Col className="question-section-sol-all-Question">
                <h2 className="sol-question_paper_heading">Question Paper</h2>
                <hr />
                {data &&
                  data.map((qs) => (
                    <>
                      <div key={qs.qid}>
                        {" "}
                        {/* <MathElement elements={sanitizeText(qs.question)} /> */}
                        {language === "English"
                          ? // <MathElement elements={sanitizeText(qs.question)} />
                            renderMathContent(qs.question)
                          : // <MathElement
                            //   elements={sanitizeText(qs.hindi_question)}
                            // />
                            renderMathContent(qs.hindi_question)}
                      </div>
                      <hr />
                    </>
                  ))}
              </Col>
            </Row>
            <Row className="sol-bottom-div m-0 p-0">
              <div className="d-flex justify-content-center align-items-center">
                <button className="back-button-sol" onClick={questionPaperFun}>
                  Back
                </button>
              </div>
            </Row>
          </Col>
        )}
      </MathJaxContext>
    </>
  );
};
export default SolutionInstruction;
