import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    addProfile: (state, action) => {
      // console.log("Reducer: Adding profile:", action.payload);
      Object.assign(state, action.payload);
    },
    reset: () => initialState, // Reset to initial state
  },
});

export const { addProfile, reset } = userProfileSlice.actions;

export default userProfileSlice.reducer;
