// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_PrivacyPolicy_div__AsNiU{
    margin-top: 1.5rem;
}

.PrivacyPolicy_PrivacyPolicy_div__AsNiU h2{
    font-size: 25px;
    font-weight: 500;
    line-height: 3rem;
}

.PrivacyPolicy_PrivacyPolicy_div__AsNiU h3{
    font-size: 22px;
    font-weight: 500;
    line-height: 3rem;
    color: #4f6ec6;
}

.PrivacyPolicy_PrivacyPolicy_div__AsNiU h4{
    font-size: 19px;
    font-weight: 500;
    line-height: 2rem;
}

.PrivacyPolicy_PrivacyPolicy_div__AsNiU p{
    font-size: 15px;
    line-height: 2rem;
    color: #000;
}`, "",{"version":3,"sources":["webpack://./src/component/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".PrivacyPolicy_div{\n    margin-top: 1.5rem;\n}\n\n.PrivacyPolicy_div h2{\n    font-size: 25px;\n    font-weight: 500;\n    line-height: 3rem;\n}\n\n.PrivacyPolicy_div h3{\n    font-size: 22px;\n    font-weight: 500;\n    line-height: 3rem;\n    color: #4f6ec6;\n}\n\n.PrivacyPolicy_div h4{\n    font-size: 19px;\n    font-weight: 500;\n    line-height: 2rem;\n}\n\n.PrivacyPolicy_div p{\n    font-size: 15px;\n    line-height: 2rem;\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PrivacyPolicy_div": `PrivacyPolicy_PrivacyPolicy_div__AsNiU`
};
export default ___CSS_LOADER_EXPORT___;
