import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./IndividualTestSeriesTierCard.module.css";
import check from "../images/check.png";
import ssctier1 from "../images/ssctier1.png";
// import classes from "./Card.module.css";
// import axios from "axios";

const IndividualTestSeriesTierCard = ({ testpassdata, product_id }) => {
  const navigate = useNavigate();

  console.log(testpassdata);

  const handleIndividiualTestSeriesChange = async (examId, postId, tierId) => {
    // console.log(id);
    navigate(`/testseriessingle/${examId}/${postId}/${tierId}/${product_id}`);
  };

  const handleIndividiualTestSeries1Change = async (
    exam_id,
    post_id,
    tier_id
  ) => {
    navigate(
      `/testseriessingle/${exam_id}/${post_id}/${tier_id}/${product_id}`
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.TierCard_heading_section}>
        {/* <div className={classes.exam_icon_title}>
          <p className={classes.left}>
            <img
              style={{ height: "28px", width: "32px" }}
              src={ssctier1}
              alt=""
            />
          </p>
          <p className={classes.right}>TIER-I</p>
        </div> */}

        <h6 className={classes.TierCard_describe_category}>
          {testpassdata && testpassdata.post_tier_name}
          {testpassdata && testpassdata.ExamPost}
        </h6>
      </div>

      <div className={classes.TierCard_card_description_section}>
        <h5>Features</h5>
        <div className={classes.TierCard_individual_unorder_list}>
          <ul className={classes.TierCard_basic_list_item}>
            <li className={classes.sub_list_item}>
              <img className={classes.check_box_img} src={check} />
              <p>Included mock test : {testpassdata.mockTestCount}</p>
            </li>
            <li className={classes.sub_list_item}>
              <img className={classes.check_box_img} src={check} />
              <p>
                Included previous Year : {testpassdata.previousYearTestCount}
              </p>
            </li>
            <li className={classes.sub_list_item}>
              <img className={classes.check_box_img} src={check} />
              <p>Included sectional test : {testpassdata.sectionalTestCount}</p>
            </li>
            <li className={classes.sub_list_item}>
              <img className={classes.check_box_img} src={check} />
              <p>
                Included chapterwise test : {testpassdata.chapterwiseTestCount}
              </p>
            </li>
          </ul>
        </div>
        {/* <div className={classes.scratch_card_div}>
          <p>use coupen code for extra discount ...</p>
          <button className={classes.apply_code_btn}>Apply code</button>
        </div> */}

        <div className={classes.TierCard_card_footer_section}>
          <div className={classes.TierCard_card_btn_div}>
            {/* <button
              className={classes.TierCard_buy_now_section}
              onClick={() => handleIndividiualTestSeriesChange(testpassdata.id)}
            >
              buy now
            </button> */}
            <button
              className={classes.TierCard_show_more_btn}
              onClick={() =>
                handleIndividiualTestSeries1Change(
                  testpassdata.exam_id,
                  testpassdata.exam_post_id,
                  testpassdata.id
                )
              }
            >
              Explore Now
            </button>
          </div>
        </div>

        {/* <div className={classes.price_sub_section}> */}
        {/* <label className={classes.strike_through_section}> */}
        {/* {testpassdata && testpassdata.price} */}
        {/* </label> */}
        {/* <label>{testpassdata && testpassdata.price}</label> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default IndividualTestSeriesTierCard;
