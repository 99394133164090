import React from "react";
import unathorize from "./images/no report.png";

const UnAuthorized = () => {
  return (
    <div style={{ alignItems: "center" }}>
      <h1>Unauthorized</h1>
      <img
        style={{ width: "600px", height: "400Px" }}
        src={unathorize}
        alt="unauthorize"
      />
      <p>You do not have permission to view this page</p>
    </div>
  );
};

export default UnAuthorized;
