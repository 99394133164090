// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateTestSeriesProduct_backdrop__MjGxz {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(105, 105, 105, 0.75);
}

.UpdateTestSeriesProduct_updateOverlayModel__QL7NH {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10vh;
  background-color: #fff;
  left: 30%;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  gap: 2rem;
  /* height: 40vh; */
  width: 55%;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/UpdateTestSeriesProduct.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,SAAS;EACT,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,uCAAuC;EACvC,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(105, 105, 105, 0.75);\n}\n\n.updateOverlayModel {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  top: 10vh;\n  background-color: #fff;\n  left: 30%;\n  z-index: 100;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  padding: 8px;\n  gap: 2rem;\n  /* height: 40vh; */\n  width: 55%;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `UpdateTestSeriesProduct_backdrop__MjGxz`,
	"updateOverlayModel": `UpdateTestSeriesProduct_updateOverlayModel__QL7NH`
};
export default ___CSS_LOADER_EXPORT___;
