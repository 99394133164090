import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import classes from "./PaymentSuccess.module.css";
import Check from "../images/check.png"; // Success image

const PaymentSuccess = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(8); // Countdown for redirection
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      if (orderId) {
        try {
          const response = await fetch(
            // `http://localhost:5000/api/v1/payment/check-status/${orderId}`
            `https://onlineexam.ssccglpinnacle.com/api/v1/payment/check-status/${orderId}`
          );
          const result = await response.json();
          console.log(response);

          setData(result); // Set the data from the backend
        } catch (error) {
          console.error("Unable to get your payment details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPaymentDetails();
  }, [orderId]);

  useEffect(() => {
    if (data && data.status === "Success") {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: "order_complete",
        order_value: data.details?.order_amount || 0,
        order_id: data.order_id || orderId,
      });
    }
  }, [data, orderId]);

  useEffect(() => {
    if (!loading && data) {
      // Countdown timer for redirection
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      // Redirect after 8 seconds
      const redirect = setTimeout(() => {
        if (data.status === "Success") {
          window.location.href =
            "https://testportal.ssccglpinnacle.com/mytestseries";
        } else {
          window.location.href = "https://testportal.ssccglpinnacle.com";
        }
      }, 8000);

      // Cleanup timer and redirect
      return () => {
        clearInterval(timer);
        clearTimeout(redirect);
      };
    }
  }, [loading, data]);

  if (loading) {
    return <p>Loading payment details...</p>;
  }

  if (!data) {
    return <p>No payment details available.</p>;
  }

  return (
    <div className={classes.payment_container}>
      {/* Show the check or cross based on payment status */}
      {data.status === "Success" ? (
        <>
          <img
            src={Check}
            alt="Payment Successful"
            className={classes.check_icon}
          />
          <h2 className={classes.success_message}>Payment Successful</h2>
        </>
      ) : (
        <>
          <h2 className={classes.failed_message}>
            Payment Failed <span>✖</span>
          </h2>
        </>
      )}
      <p className={classes.redirect_message}>
        Redirecting in {countdown} seconds...
      </p>
      <table className={classes.details_table}>
        <tbody>
          <tr>
            {data.order_id && (
              <td>
                <strong>Order ID:</strong>
              </td>
            )}
            {data.order_id && <td>{data.order_id}</td>}
            {data.details?.order_amount && (
              <>
                <td>
                  <strong>Order Amount:</strong>
                </td>
                <td>₹{data.details.order_amount}</td>
                <td>
                  <strong>Order ID:</strong>
                </td>
                <td>{data.details.cf_order_id}</td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentSuccess;
