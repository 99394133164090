import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Card.module.css";
import TestSeriesUnlockOverlay from "./TestSeriesUnlockOverlay";
import check from "../images/check.png";
// import axios from "axios";

const Card = ({ passdata, productData }) => {
  console.log("line 8", passdata);
  console.log("line 9", productData);
  // const [individualTestPass, setIndividualTestPass] = useState(null);
  // const [testPassExamValue, setTestPassExamValue] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);

  const navigate = useNavigate();

  // const handleJoinTestPassChange = (examid) => {
  //   // setTestPassExamValue(examid);
  //   // navigate(`/individualexamtestpasssection/${examid}`);
  //   setIsModelOpen(!isModelOpen);
  // };

// Ensure productData is an array even if it's null or undefined
const isPurchased = (productData || []).some(
  (product) => parseInt(product.product_id) === passdata.id
);

  const handleJoinTestPassChange = (product_id) => {
    console.log("Line 23", product_id);
    // setIsModelOpen(!isModelOpen);
      navigate(`/individualexamtestpassbuy/${product_id}`);
  };

  const handleShowMoreTestChange = (product_id) => {
    console.log("line 26", product_id);
    navigate(`/individualexamtestpasssection/${product_id}`);
  };

  // const handleShowMoreTestChange = (exam_id) => {
  //   console.log("line 30", exam_id);
  //   navigate(`/testseriessingle/${exam_id}`);
  // };

  return (
    <>
      <div className={classes.container}>
        {/* {isModelOpen && (
          <TestSeriesUnlockOverlay
            passData={passdata}
            handleModelChange={handleJoinTestPassChange}
          />
        )} */}

        <div className={classes.full_card_div}>
          <div className={classes.heading_section}>
            <h4 className={classes.describe_category}>
              {passdata && passdata.Product_title}
            </h4>
          </div>
          <div className={classes.card_description_section}>
            <h5>Features</h5>
            <div className={classes.individual_unorder_list}>
              <ul className={classes.basic_list_item}>
                <li style={{ display: "flex", gap: "1rem" }}>
                  <img src={check} />
                  Included Test :{passdata.totalTests}{" "}
                </li>
                {passdata.description.map((item, index) => (
                  <li key={index} style={{ display: "flex", gap: "1rem" }}>
                    <img src={check} alt="check" />
                    {item}
                  </li>
                ))}

                {/* <li style={{ display: "flex", gap: "1rem" }}>
                  {" "}
                  <img src={check} />
                  Full Mock Test based on Latest Pattern
                </li>
                <li style={{ display: "flex", gap: "1rem" }}>
                  {" "}
                  <img src={check} />
                  sectional based on different level{" "}
                </li>
                <li style={{ display: "flex", gap: "1rem" }}>
                  {" "}
                  <img src={check} />
                  chapterwise based on weightage
                </li>
                <li style={{ display: "flex", gap: "1rem" }}>
                  {" "}
                  <img src={check} />
                  Previous Year Included
                </li> */}
              </ul>
            </div>

            <div className={classes.footer_sub_section}>
              <p>
                {" "}
                Price :{" "}
                <span className={classes.card_price_p}>
                  {" "}
                  ₹ {passdata.product_max_price}
                </span>
              </p>
              <p> ₹ {passdata.product_price}</p>{" "}
              {/* use coupon code for extra discount...<b>Apply code</b> */}
            </div>

            <div className={classes.card_footer_section}>
              <div className={classes.card_btn_div}>
                <button
                  className={classes.show_more_btn}
                  onClick={() => handleShowMoreTestChange(passdata.id)}
                >
                  show more
                </button>
                {/* <button
                  className={classes.buy_now_section}
                  // onClick={() => handleJoinTestPassChange(passdata.exam_id)}
                  onClick={() => handleJoinTestPassChange(passdata.id)}
                >
                  buy now
                </button> */}
            {isPurchased ? (
                <p className={classes.purchased_text}>Purchased</p>
              ) : (
                <button
                  className={classes.buy_now_section}
                  onClick={() => handleJoinTestPassChange(passdata.id)}
                >
                  Buy Now
                </button>
              )}
              </div>
              <div className={classes.price_sub_section}>
                <label className={classes.strike_through_section}>
                  {/* {passdata && passdata.max_price} */}
                </label>
                {/* <label>{passdata && passdata.price}</label> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
