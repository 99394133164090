import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import TagManager from 'react-gtm-module';
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AuthContextProvider } from "./context/AuthContext";

// Initialize Google Tag Manager
// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID, // Use REACT_APP prefix to properly load environment variables
// };

// // console.log("GTM ID", process.env.REACT_APP_GTM_ID);

// if (tagManagerArgs.gtmId) {
//   console.log("Initializing Google Tag Manager with ID:", tagManagerArgs.gtmId);
//   TagManager.initialize(tagManagerArgs);
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
