import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "@wiris/mathtype-tinymce6"; // Import the WIRIS MathType plugin
import classes from "./EditQuestion.module.css";
import wirisConfig from "./wirisConfig";

const EditQuestion = ({
  questionData,
  _idData,
  onSave,
  selectedLanguage,
  exam_tier_id,
}) => {
  const [editedData, setEditedData] = useState({ ...questionData });

  useEffect(() => {
    setEditedData({ ...questionData });
  }, [questionData]);


  const getWirisLicenseKey = () => {
    try {
      const key = Object.keys(wirisConfig.licenseKeys)[0];
      return wirisConfig.licenseKeys[key];
    } catch (error) {
      console.error("Error retrieving WIRIS license key:", error);
      return "";
    }
  };

  const options = {
    paste_data_images: true,
    height: 500,
    menubar: true,
    external_plugins: {
      tiny_mce_wiris: "/plugins/mathtype/plugin.min.js",
      // tiny_mce_wiris: "/plugins/mathtype/plugin.min.js",
    },
    wirisplugin: {
      license: getWirisLicenseKey(),
    },
    plugins: [
      "autolink",
      "checklist",
      "export",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "help",
      "wordcount",
      "tiny_mce_wiris",
    ],
    toolbar: [
      "undo redo | casechange blocks | bold italic backcolor | " +
        "alignleft aligncenter alignright alignjustify | " +
        "bullist numlist checklist outdent indent | removeformat | fontsize | code table help " +
        "tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry | link image | preview",
    ],
    content_style:
      "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
    htmlAllowedTags: [".*"],
    htmlAllowedAttrs: [".*"],
    extended_valid_elements: "*[.*]",
    setup: function (editor) {
      // Listen to the 'init' event
      editor.on('init', function (e) {
        console.log('TinyMCE initialized:', e);
      });
  
      // Listen to the 'change' event
      editor.on('change', function (e) {
        console.log('Content changed:', editor.getContent());
      });
  
      // Listen to the 'click' event inside the editor
      editor.on('click', function (e) {
        console.log('Editor clicked:', e);
      });
  
      // Listen to error events
      editor.on('error', function (e) {
        console.error('Error in TinyMCE:', e);
      });
    }
  };

  const handleEditorChange = (value, key) => {
    console.log("HANDLE EDITOR CHANGE VALUE ", {[key]: value});
    setEditedData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSelectChange = (event, key) => {
    setEditedData((prevData) => ({
      ...prevData,
      [key]: event.target.value,
    }));
  };

  const handleSaveClick = () => {
    onSave(editedData, _idData, exam_tier_id);
  };

  return (
    <div>
      <h2>Edit Question</h2>
      {Object.keys(editedData).map((key,index) => (
        <div key={key} className={classes.editorContainer}>
          <h3>{key}</h3>

          {key === "answer" ? (
            <select
              value={editedData[key]}
              onChange={(event) => handleSelectChange(event, key)}
            >
              <option value={editedData[key]}>{editedData[key]}</option>
              <option value="a">a</option>
              <option value="b">b</option>
              <option value="c">c</option>
              <option value="d">d</option>
              <option value="none">none of the above</option>
            </select>
          ) : (
            <Editor
              key={index}
              init={options}
              value={editedData[key]}
              onEditorChange={(value) => handleEditorChange(value, key)}
            />
          )}
        </div>
      ))}
      <button className="save-question" onClick={handleSaveClick}>
        Save Question
      </button>
    </div>
  );
};

export default EditQuestion;

// import React, { useState, useEffect } from "react";
// import { Editor } from "@tinymce/tinymce-react";
// import "@wiris/mathtype-tinymce6"; // Import the WIRIS MathType plugin
// // import "tinymce/tinymce";
// import classes from "./EditQuestion.module.css";
// import wirisConfig from './wirisConfig';

// const EditQuestion = ({ questionData, _idData, onSave, selectedLanguage }) => {
//   const [editedData, setEditedData] = useState({ ...questionData });
//   console.log("_idData",_idData);

//   useEffect(() => {
//     setEditedData({ ...questionData });
//   }, [questionData]);

//   useEffect(() => {
//     // Load external WIRIS script if needed
//     const script = document.createElement('script');
//     script.src =` ${window.location.origin}/node_modules/@wiris/mathtype-tinymce5/plugin.min.js`;
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const getWirisLicenseKey = () => {
//     // Assuming you are using the first key-value pair for the license
//     const key = Object.keys(wirisConfig.licenseKeys)[0];
//     return wirisConfig.licenseKeys[key];
//   };

//   console.log("editedData",editedData);

//   const options = {
//     paste_data_images: true,
//     height: 500,
//     menubar: true,
//     external_plugins: {
//       // tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
//       tiny_mce_wiris: "/plugins/mathtype/plugin.min.js",
//     },
//     // external_plugins: {
//     //   tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
//     // },
//     wirisplugin: {
//       license: getWirisLicenseKey(),
//     },
//     plugins: [
//       "autolink",
//       "checklist",
//       "export",
//       "lists",
//       "link",
//       "image",
//       "charmap",
//       "preview",
//       "anchor",
//       "searchreplace",
//       "visualblocks",
//       "fullscreen",
//       "insertdatetime",
//       "media",
//       "table",
//       "help",
//       "wordcount",
//       "tiny_mce_wiris",
//       // "@wiris/mathtype-tinymce4/plugin.min.js",
//     ],
//     toolbar: [
//       "undo redo | casechange blocks | bold italic backcolor | " +
//         "alignleft aligncenter alignright alignjustify | " +
//         "bullist numlist checklist outdent indent | removeformat |fontsize | code table help tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | link image | preview",
//     ],

//     // toolbar: [
//     //   ("undo redo | formatselect | " +
//     //     "bold italic backcolor | alignleft aligncenter " +
//     //     "alignright alignjustify | bullist numlist outdent indent | " +
//     //     "removeformat | help") |
//     //     "+tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
//     // ],

//     content_style:
//       "body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }",
//     htmlAllowedTags: [".*"],
//     htmlAllowedAttrs: [".*"],
//     extended_valid_elements: "*[.*]",
//   };

//   // const handleEditorChange = (value, key) => {
//   //   setEditedData((prevData) => ({
//   //     ...prevData,
//   //     [key]: { ...prevData[key], option: value },
//   //   }));
//   // };

//   const handleEditorChange = (value, key) => {
//     // console.log("editedData:", editedData);
//     // console.log("key:", key);
//     // console.log(value);
//     setEditedData((prevData) => ({
//       ...prevData,
//       [key]: value,
//     }));
//     console.log("key:", key, "value:", value);
//   };

//   const handleSaveClick = () => {
//     console.log("after saving");
//     console.log(editedData);
//     onSave(editedData, _idData);
//     // Additional save logic...
//   };

//   return (
//     <div>
//       <h2>Edit Question</h2>
//       {/* { console.log("Edit Question", editedData)} */}
//       {Object.keys(editedData).map((key) => (
//         <div key={key} className={classes.editorContainer}>
//           <h3>{key}</h3>
//           {console.log(editedData[key])}

//           <Editor
//             init={options}
//             value={editedData[key]}
//             onEditorChange={(value) => handleEditorChange(value, key)}
//           />
//         </div>
//       ))}
//       <button className="save-question" onClick={handleSaveClick}>
//         Save Question
//       </button>
//     </div>
//   );
// };

// export default EditQuestion;

// import React, { useState, useRef } from "react";
// import { Editor } from "@tinymce/tinymce-react";
// import '@wiris/mathtype-tinymce6'; // Import the WIRIS MathType plugin
// import 'tinymce/tinymce';
// import classes from "./DocxReader2.module.css";

// export const QuestionOption = ({ label, option, images, onSave }) => {
//   const generateImageTag = (image) => {
//     return `<img src="${image.imageUrl}" alt="Image" />`;
//   };

//   // const editorRef = useRef();
//   const [editorContent, setEditorContent] = useState(() => getEditorContent(option, images));

//   const options = {
//     paste_data_images: true,
//     height: 500,
//     menubar: true,
//     external_plugins: {
//       tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
//     },
//     plugins: [
//       'autolink', 'checklist', 'export',
//       'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
//       'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'PowerPaste',
//     ],
//     toolbar: [
//       'undo redo | casechange blocks | bold italic backcolor | ' +
//       'alignleft aligncenter alignright alignjustify | ' +
//       'bullist numlist checklist outdent indent | removeformat |fontsize | code table help tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | link image | preview',
//     ],
//     content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }',
//     htmlAllowedTags: ['.*'],
//     htmlAllowedAttrs: ['.*'],
//     extended_valid_elements: '*[.*]',
//   };

//   function getEditorContent(option, images) {
//     let content = option;

//     if (images && images.length > 0) {
//       images.forEach((image) => {
//         content += generateImageTag(image);
//       });
//     }
//     return content;
//   }

//   const handleEditorChange = (value) => {
//     setEditorContent(value);
//     // onSave(value);
//     // console.log("TINYMCE", value);
//     // console.log("editorRef.current.getContent()",editorRef.current.getContent());
//   };

//   return (
//     <div>
//       <h1>{label}</h1>
//       <div>
//         <Editor
//           // onInit={(evt, editor) => editorRef.current = editor}
//           key={option}
//           init={options}
//           value={editorContent}
//           onEditorChange={handleEditorChange}
//         />
//       </div>
//     </div>
//   );
// };

// const EditQuestion = ({ questionData, onSave, selectedLanguage }) => {
//   const [editedData, setEditedData] = useState({ ...questionData });

//   // Function to handle saving each option
//   // const handleSaveOption = (optionKey, value) => {
//   //   setEditedData((prevData) => ({
//   //     ...prevData,
//   //     [optionKey]: {
//   //       ...prevData[optionKey],
//   //       option: value,
//   //     },
//   //   }));
//   // };

//   const handleSaveOption = (optionKey, value) => {
//     setEditedData((prevData) => ({
//       ...prevData,
//       [optionKey]: {
//         ...prevData[optionKey],
//         option: value,
//       },
//     }));
//   };

//   const handleSaveClick = () => {
//     // Call the onSave function with the updated data
//     onSave(editedData);
//     // ... rest of your save logic
//   };

//   return (
//     <div>
//       <h2>Edit Question</h2>
//       <label>
//         Question:
//         <QuestionOption
//           label="Question"
//           option={
//             selectedLanguage === "English"
//               ? editedData.question.option
//               : editedData.hindiquestions.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.question.image
//               : editedData.question.image
//           }
//           onSave={(value) => handleSaveOption("question", value)}
//         />
//       </label>
//       <label>
//         Option A:
//         <QuestionOption
//           label="Option A"
//           option={
//             selectedLanguage === "English"
//               ? editedData.optionA.option
//               : editedData.hindioption.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.optionA.image
//               : editedData.optionA.image
//           }
//           onSave={(value) => handleSaveOption("optionA", value)}
//         />
//       </label>
//       <label>
//         Option B:
//         <QuestionOption
//           label="Option B"
//           option={
//             selectedLanguage === "English"
//               ? editedData.optionB.option
//               : editedData.hindioptionB.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.optionB.image
//               : editedData.optionB.image
//           }
//           onSave={(value) => handleSaveOption("optionB", value)}
//         />
//       </label>
//       <label>
//         Option C:
//         <QuestionOption
//           label="Option C"
//           option={
//             selectedLanguage === "English"
//               ? editedData.optionC.option
//               : editedData.hindioptionC.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.optionC.image
//               : editedData.optionC.image
//           }
//           onSave={(value) => handleSaveOption("optionC", value)}
//         />
//       </label>
//       <label>
//         Option D:
//         <QuestionOption
//           label="Option D"
//           option={
//             selectedLanguage === "English"
//               ? editedData.optionD.option
//               : editedData.hindioptionD.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.optionD.image
//               : editedData.hindioptionD.image
//           }
//           onSave={(value) => handleSaveOption("optionD", value)}
//         />
//       </label>
//       <label>
//         Section:
//         <QuestionOption
//           label="Section"
//           option={editedData.section}
//           onSave={(value) => handleSaveOption("section", value)}
//         />
//       </label>
//       <label>
//         Solution:
//         <QuestionOption
//           label="Solution"
//           option={
//             selectedLanguage === "English"
//               ? editedData.solutions.option
//               : editedData.hindiSolutions.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.solutions.image
//               : editedData.hindiSolutions.image
//           }
//           onSave={(value) => handleSaveOption("solutions", value)}
//         />
//       </label>
//       <label>
//         Answer:
//         <QuestionOption
//           label="Answer"
//           option={editedData.answer.option}
//           images={
//             selectedLanguage === "English"
//               ? editedData.answer.image
//               : editedData.answer.image
//           }
//           onSave={(value) => handleSaveOption("answer", value)}
//         />
//       </label>
//       <button className="save-question" onClick={handleSaveClick}>
//         Save Question
//       </button>
//     </div>
//   );
// };

// export default EditQuestion;

// import React, { useState } from "react";
// import EditorComponent from "./EditorComponent";

// import { Editor } from '@tinymce/tinymce-react';
// import '@wiris/mathtype-tinymce6'; // Import the WIRIS MathType plugin
// import 'tinymce/tinymce';
// import classes from "./DocxReader2.module.css";

// export const QuestionOption = ({ label, option, images, onSave }) => {
//   const generateImageTag = (image) => {
//     return `<img src="${image.imageUrl}" alt="Image" />`;
//   };

//   const [editorContent, setEditorContent] = useState(() => getEditorContent(option, images));

//   const options = {
//     paste_data_images: true,
//     height: 500,
//     menubar: true,
//     external_plugins: {
//       tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
//     },
//     plugins: [
//       'autolink', 'checklist', 'export',
//       'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
//       'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'PowerPaste',
//     ],
//     toolbar: [
//       'undo redo | casechange blocks | bold italic backcolor | ' +
//       'alignleft aligncenter alignright alignjustify | ' +
//       'bullist numlist checklist outdent indent | removeformat |fontsize | code table help tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | link image | preview',
//     ],
//     content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px }',
//     htmlAllowedTags: ['.*'],
//     htmlAllowedAttrs: ['.*'],
//     extended_valid_elements: '*[.*]',
//   };

//   function getEditorContent(option, images) {
//     let content = option;

//     if (images && images.length > 0) {
//       images.forEach((image) => {
//         content += generateImageTag(image);
//       });
//     }
//     return content;
//   }

//   const handleEditorChange = (value) => {
//     setEditorContent(value);
//     onSave(value);
//     console.log("TINYMCE", value);
//   };

//   return (
//     <div>
//       <h1>{label}</h1>
//       <div>
//         <Editor
//           key={option}
//           init={options}
//           value={editorContent}
//           onEditorChange={handleEditorChange}
//         />
//       </div>
//     </div>
//   );
// };

// const EditQuestion = ({ questionData, onSave, selectedLanguage }) => {
//   const [editedData, setEditedData] = useState({ ...questionData });

//     // Function to handle saving each option
//     const handleSaveOption = (optionKey, value) => {
//       setEditedData((prevData) => ({
//         ...prevData,
//         [optionKey]: {
//           ...prevData[optionKey],
//           option: value,
//         },
//       }));
//     };

//     const handleSaveClick = () => {
//       // Call the onSave function with the updated data
//       onSave(editedData);
//       // ... rest of your save logic
//     };

//   return (
//     <div>
//       <h2>Edit Question</h2>
//       <label>
//         Question:
//         <QuestionOption
//           option={
//             selectedLanguage === "English"
//               ? editedData.question.option
//               : editedData.hindiquestions.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.question.image
//               : editedData.question.image
//           }
//           onSave={(value) => handleSaveOption("question", value)}
//         />
//       </label>
//       <label>
//         Option A:
//         <QuestionOption
//           option={
//             selectedLanguage === "English"
//               ? editedData.optionA.option
//               : editedData.hindioption.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.optionA.image
//               : editedData.optionA.image
//           }
//           onSave={(value) => handleSaveOption("optionA", value)}
//           // onSave={onSave}
//         />
//       </label>
//       <label>
//         Option B:
//         <QuestionOption
//           option={
//             selectedLanguage === "English"
//               ? editedData.optionB.option
//               : editedData.hindioptionB.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.optionB.image
//               : editedData.optionB.image
//           }
//           onSave={(value) => handleSaveOption("optionB", value)}
//           // onSave={onSave}
//         />
//       </label>
//       <label>
//         Option C:
//         <QuestionOption
//           option={
//             selectedLanguage === "English"
//               ? editedData.optionC.option
//               : editedData.hindioptionC.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.optionC.image
//               : editedData.optionC.image
//           }
//           onSave={(value) => handleSaveOption("optionC", value)}
//           // onSave={onSave}
//         />
//       </label>
//       <label>
//         Option D:
//         <QuestionOption
//           option={
//             selectedLanguage === "English"
//               ? editedData.optionD.option
//               : editedData.hindioptionD.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.optionD.image
//               : editedData.hindioptionD.image
//           }
//           onSave={(value) => handleSaveOption("optionD", value)}
//           // onSave={onSave}
//         />
//       </label>
//       <label>
//         Section:
//         <QuestionOption
//           option={editedData.section}
//         />
//       </label>
//       <label>
//         Solution:
//         <QuestionOption
//           option={
//             selectedLanguage === "English"
//               ? editedData.solutions.option
//               : editedData.hindiSolutions.option
//           }
//           images={
//             selectedLanguage === "English"
//               ? editedData.solutions.image
//               : editedData.hindiSolutions.image
//           }
//           onSave={(value) => handleSaveOption("solutions", value)}
//           // onSave={onSave}
//         />
//       </label>
//       <label>
//         Answer:
//         <QuestionOption
//           option={editedData.answer.option}
//           images={
//             selectedLanguage === "English"
//               ? editedData.answer.image
//               : editedData.answer.image
//           }
//           onSave={(value) => handleSaveOption("answer", value)}
//           // onSave={onSave}
//         />
//       </label>
//       <button className="save-question" onClick={handleSaveClick}>
//         Save Question
//       </button>
//     </div>
//   );
// };

// export default EditQuestion;
