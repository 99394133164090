// src/components/TestSeriesProductList.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const AddTestSeriesInProduct = () => {
  const [data, setData] = useState({
    products: [],
    exams: [],
    posts: [],
    tiers: [],
    modes: [],
    testSeriesList: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedExam, setSelectedExam] = useState("");
  const [selectedPost, setSelectedPost] = useState("");
  const [selectedTier, setSelectedTier] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const [selectedTestSeries, setSelectedTestSeries] = useState("");

  const cookies = new Cookies();

  const token = cookies.get("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/fetchDataToAddTestSeriesInProduct",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const modeHandler = async (e) => {
    setSelectedMode(e.target.value);
    // Add logic to handle change if needed, such as updating test series list based on selected mode
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <h1>Test Series Product List</h1>

      <section>
        <h2>Products</h2>
        <select
          value={selectedProduct}
          onChange={(e) => setSelectedProduct(e.target.value)}
        >
          <option value="" hidden>
            Select a product
          </option>
          {data.products.map((product) => (
            <option key={product.id} value={product.id}>
              {product.id}. {product.product_title}
            </option>
          ))}
        </select>
      </section>

      <section>
        <h2>Exams</h2>
        <select
          value={selectedExam}
          onChange={(e) => setSelectedExam(e.target.value)}
        >
          <option value="" hidden>
            Select an exam
          </option>
          <option value="0">All</option>
          {data.exams.map((exam) => (
            <option key={exam.id} value={exam.id}>
              {exam.id}. {exam.exam_name}
            </option>
          ))}
        </select>
      </section>

      <section>
        <h2>Posts</h2>
        <select
          value={selectedPost}
          onChange={(e) => setSelectedPost(e.target.value)}
        >
          <option value="" hidden>
            Select a post
          </option>
          <option value="0">All</option>
          {data.posts.map((post) => (
            <option key={post.id} value={post.id}>
              {post.id}. {post.exam_post_name}
            </option>
          ))}
        </select>
      </section>

      <section>
        <h2>Tiers</h2>
        <select
          value={selectedTier}
          onChange={(e) => setSelectedTier(e.target.value)}
        >
          <option value="" hidden>
            Select a tier
          </option>
          <option value="0">All</option>
          {data.tiers.map((tier) => (
            <option key={tier.id} value={tier.id}>
              {tier.id}. {tier.exam_post_tier_name}
            </option>
          ))}
        </select>
      </section>

      <section>
        <h2>Modes</h2>
        <select value={selectedMode} onChange={modeHandler}>
          <option value="" hidden>
            Select a mode
          </option>
          <option value="0">All</option>
          {data.modes.map((mode) => (
            <option key={mode.id} value={mode.id}>
              {mode.id}. {mode.exam_mode_name}
            </option>
          ))}
        </select>
      </section>

      <section>
        <h2>Test Series</h2>
        <select
          value={selectedTestSeries}
          onChange={(e) => setSelectedTestSeries(e.target.value)}
        >
          <option value="" hidden>
            Select Test Series
          </option>
          {data.testSeriesList.map((testSeries) => (
            <option key={testSeries.id} value={testSeries.id}>
              {testSeries.id}. {testSeries.exam_mode_name}
            </option>
          ))}
        </select>
      </section>
    </div>
  );
};

export default AddTestSeriesInProduct;

// // src/components/TestSeriesProductList.js
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const AddTestSeriesInProduct = () => {
//   const [data, setData] = useState({
//     products: [],
//     exams: [],
//     posts: [],
//     tiers: [],
//     modes: [],
//   });
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [selectedExam, setSelectedExam] = useState('');
//   const [selectedPost, setSelectedPost] = useState('');
//   const [selectedTier, setSelectedTier] = useState('');
//   const [selectedMode, setSelectedMode] = useState('');
//   const [selectedTestSeries, setSelectedTestSeries] = useState('');

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(process.env.REACT_APP_PINNACLE_BASE_URL + "/fetchDataToAddTestSeriesInProduct");
//         setData(response.data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const modeHandler = (e) => {
//     setSelectedMode(e.target.value)};
//     // const reqData = {
//     //     selectedProduct,
//     //     selectedExam,
//     //     selectedPost,
//     //     selectedTier,
//     //     selectedMode
//     // };
//     // try {
//     //     const response = await axios.post(process.env.REACT_APP_PINNACLE_BASE_URL + "/fetchDataToAddTestSeriesInProduct",
//     //         body: reqData
//     //     );
//     //     setTestSeriesList(response.data);
//     //     setLoading(false);
//     //   } catch (error) {
//     //     setError(error);
//     //     setLoading(false);
//     //   }

//   }

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div>
//       <h1>Test Series Product List</h1>

//       <section>
//         <h2>Products</h2>
//         <select value={selectedProduct} onChange={e => setSelectedProduct(e.target.value)}>
//           <option value="" hidden >Select a product</option>
//           {data.products.map(product => (
//             <option key={product.id} value={product.id}>{product.id}. {product.product_title}</option>
//           ))}
//         </select>
//       </section>

//       <section>
//         <h2>Exams</h2>
//         <select value={selectedExam} onChange={e => setSelectedExam(e.target.value)}>
//           <option value="" hidden >Select an exam</option>
//           <option value="0"  >All</option>
//           {data.exams.map(exam => (
//             <option key={exam.id} value={exam.id}>{exam.id}. {exam.exam_name}</option>
//           ))}
//         </select>
//       </section>

//       <section>
//         <h2>Posts</h2>
//         <select value={selectedPost} onChange={e => setSelectedPost(e.target.value)}>
//           <option value="" hidden >Select a post</option>
//           <option value="0" >All</option>
//           {data.posts.map(post => (
//             <option key={post.id} value={post.id}>{post.id}.{post.exam_post_name}</option>
//           ))}
//         </select>
//       </section>

//       <section>
//         <h2>Tiers</h2>
//         <select value={selectedTier} onChange={e => setSelectedTier(e.target.value)}>
//           <option value="" hidden>Select a tier</option>
//           <option value="0">All</option>
//           {data.tiers.map(tier => (
//             <option key={tier.id} value={tier.id}>{tier.id}. {tier.exam_post_tier_name}</option>
//           ))}
//         </select>
//       </section>

//       <section>
//         <h2>Modes</h2>
//         <select value={selectedMode} onChange={modeHandler}>
//           <option value="" hidden >Select a mode</option>
//           <option value="0">All</option>
//           {data.modes.map(mode => (
//             <option key={mode.id} value={mode.id}>{mode.id}. {mode.exam_mode_name}</option>
//           ))}
//         </select>
//       </section>

//       <section>
//         <h2>Test Series </h2>
//         <select value={selectedTestSeries} onChange={e => setSelectedTestSeries(e.target.value)}>
//           <option value="" hidden >Select Test Series</option>
//           {data.TestSeriesList.map(testSeries => (
//             <option key={testSeries.id} value={testSeries.id}>{testSeries.id}. {testSeries.exam_mode_name}</option>
//           ))}
//         </select>
//       </section>
//     </div>
//   );
// };

// export default AddTestSeriesInProduct;
