// import React, { useEffect, useState } from "react";
// import classes from "./ContactUs.module.css";
// import axios from "axios";
// import Layout from "./Layout";


// const ContactUs = () => {
//     const [contactUsContent, setContactUsContent] = useState("");
//     const [error, setError] = useState("");
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         const fetchContactUsContent = async () => {
//             try {
//                 const baseUrl = "http://localhost:5000";
//                 if (!baseUrl) {
//                     throw new Error("Base URL is not defined");
//                 }
//                 const response = await axios.get(`${baseUrl}/api/v1/term-and-condition`);

//                 if (response.data && response.data.data) {
//                     console.log("CONTACT_US_AND_DATA:", response.data);
//                     setContactUsContent(response.data.data.contact_us);
//                 } else {
//                     setError("Failed to load Contact and Us");
//                 }
//             } catch (error) {
//                 console.error("Error fetching contact us:", error);
//                 setError("An error occurred while fetching Contact us");
//             } finally {
//                 setLoading(false);
//             }
//         };
//         fetchContactUsContent();
//     }, []);
//   return (
//     <Layout>
//         <div className={classes.ContactUs_div}>
//             <h2>Contact Us</h2>
//             {loading && <p>Loading Contact Us...</p>}
//             {error && <p className={classes.ContactUs}>{error}</p>}
//             {!loading && !error && (
//                 <div className={classes.contactUsContent} dangerouslySetInnerHTML={{__html: contactUsContent }}/>
//             )}
//         </div>
//     </Layout>
//   );
// };

// export default ContactUs;



import React, { useEffect, useState } from "react";
import classes from "./ContactUs.module.css";
import axios from "axios";
import Layout from "./Layout";

const ContactUs = () => {
    const [contactUsContent, setContactUsContent] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true); // Set loading to true initially

    useEffect(() => {
        const fetchContactUsContent = async () => {
            try {
                // const baseUrl = "http://localhost:5000";
                // if (!baseUrl) {
                //     throw new Error("Base URL is not defined");
                // }

                const response = await axios.get(`${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/terms-and-conditions`);
                
                if (response.data) {
                    console.log("TERMS_AND_CONDITIONS_DATA:", response.data);
                    setContactUsContent(response.data.data.contact_us || "");
                } else {
                    setError("Failed to load terms and conditions.");
                }
            } catch (error) {
                console.error("Error fetching terms and conditions:", error);
                setError("An error occurred while fetching terms and conditions.");
            } finally {
                setLoading(false);
            }
        };

        fetchContactUsContent();
    }, []);

    return (
        <Layout>
            <div className={classes.ContactUs_div}>
                {/* <h2>Contact Us</h2> */}

                {loading && <p>Loading Contact Us...</p>}
                {error && <p className={classes.error}>{error}</p>}
                {!loading && !error && (
                    <div
                        className={classes.contactUsContent}
                        dangerouslySetInnerHTML={{ __html: contactUsContent }}
                    />
                )}
            </div>
        </Layout>
    );
};

export default ContactUs;

