import { Row, Col } from "react-bootstrap";
import MathElement from "./MathMl";
const SolutionInstruction = ({ data, show_paper, questionPaperFun, language }) => {
  // Sanitize the input by removing unwanted characters and tags
  const sanitizeText = (text) => {
    if (!text) return "";
    return text
      .replace(/<[^>]*>?/gm, "") // Removes HTML tags
      .replace(/\\n|\\r|\n|\r/g, " ") // Replace newlines with spaces
      .trim(); // Remove leading and trailing whitespace
  };

  // console.log("================================:", data, language);
  return (
    <>
      {show_paper && (
        <Col md={9} className="ques-row mobile-width100">
          <Row className="questin-paper-div1">
            <Col className="question-section-sol-all-Question">
              <h2 className="sol-question_paper_heading">Question Paper</h2>
              <hr />
              {data &&
                data.map((qs) => (
                  <>
                    <div key={qs.qid}>
                      {" "}
                      {/* <MathElement elements={sanitizeText(qs.question)} /> */}
                      {language === 'English' ? (
                      <MathElement elements={sanitizeText(qs.question)} />
                    ) : (
                      <MathElement elements={sanitizeText(qs.hindi_question)} />
                    )}
                    </div>
                    <hr />
                  </>
                ))}
            </Col>
          </Row>
          <Row className="sol-bottom-div m-0 p-0">
            <div className="d-flex justify-content-center align-items-center">
              <button className="back-button-sol" onClick={questionPaperFun}>
                Back
              </button>
            </div>
          </Row>
        </Col>
      )}
    </>
  );
};
export default SolutionInstruction;
