import "./header1.css";
import "./TestSeries.css";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import TestOverview from "./TestOverview";
// import TestSeriesHeader from './TestSeriesHeader'
//import TestSeriesFooter from './TestSeriesFooter'
import SpecificTestseries from "./SpecificTestseries";
/* import Faq from './Faq'
import TestSeriesAbout from './TestSeriesAbout' */

function TestseriesList() {
  const { product_id, exam_id, post_id, exam_mode_id, tier_id } = useParams();
  const [TierName, setPostTierName] = useState();
  const [ExamID, setExamID] = useState();

  useEffect(() => {
    async function tierId() {
      let tier_ids = { tier_id: tier_id };
      let res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/Breadcrump",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tier_ids),
        }
      );
      //   console.log(tier_ids);
      let tier = await res.json();
      console.log(tier);
      setExamID(tier[0]["ExamId"]);
      setPostTierName(tier[0]["PostTierId"]);
      console.log("line 35", tier[0]["ExamId"]);
    }

    tierId();
  }, []);

  return (
    <>
      {/* <TestSeriesHeader /> */}
      <TestOverview
        ExamID={exam_id}
        TierID={tier_id}
        TierName={TierName}
        exam_mode_id={exam_mode_id}
        post_id={post_id}
        product_id={product_id}
      />
      <SpecificTestseries
        ExamID={exam_id}
        post_id={post_id}
        TierID={tier_id}
        product_id={product_id}
        // exam_post_id={tier_id}
        exam_mode_id={exam_mode_id}
      />
      {/* <TestSeriesAbout />
      <Faq /> */}
      {/* <TestSeriesFooter /> */}
    </>
  );
}

export default TestseriesList;
