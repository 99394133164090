// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfileCard_user_profile__ef6vm {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    max-width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.UserProfileCard_icon__mcAtg {
    width: 100px;
    height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/component/UserProfileCard.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,qCAAqC;AACzC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".user_profile {\n    background-color: white;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 16px;\n    max-width: 300px;\n    text-align: center;\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n}\n\n.icon {\n    width: 100px;\n    height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user_profile": `UserProfileCard_user_profile__ef6vm`,
	"icon": `UserProfileCard_icon__mcAtg`
};
export default ___CSS_LOADER_EXPORT___;
