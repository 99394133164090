
// import React from "react";
// import Header from "./Header";
// import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import classes from "./Layout.module.css";
// import SubHeader from "./SubHeader";

// const Layout = ({ children }) => {
//   return (
//     <div className={classes.container_fluid}>
//       <div className={classes.header}>
//         <Header />
//         <SubHeader/>
//       </div>
//       <div className={classes.sidebar}>
//         <Sidebar />
//       </div>
//       <div className={classes.main}>
//         {children}
//       </div>
//       <div className={classes.footer}>
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default Layout;

import React,{useState} from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import classes from "./Layout.module.css";

// Import FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Layout = ({ children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <div className={classes.abc}>
        <button className={classes.toggle_button} onClick={toggleSidebar}>
          <FontAwesomeIcon icon={sidebarVisible ? faBars : faTimes} />
        </button>
      </div>
      <div className={classes.container_fluid}>
        <div className={classes.header}>
          <Header />
        </div>

        <div className={classes.content}>
          <div className={`${classes.sidebar} ${sidebarVisible ? classes.sidebar_show : classes.sidebar_hide}`}>
            <Sidebar />
          </div>
          <div className={classes.content_div}>
            <div className={classes.main}>{children}</div>
          </div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;

// import React from "react";
// import Header from "./Header";
// import Sidebar from "./Sidebar";
// import Footer from "./Footer";
// import classes from "./Layout.module.css";

// const Layout = ({ children }) => {
//   return (
//     <div className={classes.container}>
//         <div className={classes.layout_sub_section}>
//           <div className={classes.dashboard_sidebar}>
//             <Sidebar />
//           </div>
//           <div className={classes.dasboard_outlet}>
//             <div><Header /></div>
//             <div className={classes.main_content}>{children}</div>
//           </div>
//         </div>
//         <Footer />
//     </div>
//   );
// };

// export default Layout;
