// import { useMemo } from "react";
// import { Row, Col } from "react-bootstrap";
// import MathJax from "react-mathjax-preview";

// import CountUp from "./CountUp";
// import MathElement from "./MathMl";
// import ReportModel from "./ReportModel";
// import "./header1.css";

// function Questions({
//   question,
//   qid,
//   chooseOption,
//   optionItem,
//   option1,
//   option2,
//   option3,
//   option4,
//   hindi_question,
//   // hindi_option1,
//   // hindi_option2,
//   // hindi_option3,
//   // hindi_option4,
//   option1_hindi,
//   option2_hindi,
//   option3_hindi,
//   option4_hindi,
//   language,
//   positive_marks,
//   negative_marks,
//   singleTm,
//   hrs,
//   mins,
//   secs,
//   pause,
//   Next,
//   subject,
//   paper_code,
//   choosed_answer,
//   theArray,

//   // item_data,
// }) {
//   {
//     /*
//   {console.log(`
//     HEADER DATA PASSED
//     NEXT = ${Next}
//     chooseFun = ${chooseOption}

//     OPTIONITEM = ${optionItem}
//     ITEM QID = ${qid}
//     PAPERCODE = ${paper_code}
//     LANGUAGE = ${language}
//     SINGLETM = ${singleTm}
//     PAUSE = ${pause}
//     SUBJECT = ${subject}
//     CHOOSED_ANSWER = ${choosed_answer}
//     THE_ARRAY = ${theArray}

//     ITEM = ${item_data}
//     QUESTION = ${question}
//     OPTION1 = ${option1},
//     OPTION2 = ${option2},
//     OPTION3 = ${option3},
//     OPTION4 = ${option4},
//     HINDI_QUEST = ${hindi_question},
//     HINDI_option1 = ${hindi_option1},
//     HINDI_option2 = ${hindi_option2},
//     HINDI_option3 = ${hindi_option3},
//     HINDI_option4 = ${hindi_option4},
//     POSITIVE_MARK = ${positive_marks},
//     NEGATIVE_MARK = ${negative_marks},
//     PAUSE = ${pause},
//     HRS = ${hrs},
//     MINS = ${mins},
//     SECS = ${secs},
//     PAUSE = ${pause}
//   `)}

//   */
//   }

//   {
//     /*
//     HEADER DATA PASSED
//     chooseFun = e => {
//       setOption(e.target.value);
//     }
//     SINGLETM = sTm => {
//       setSingleTm(sTm);
//     }
//     THE_ARRAY =
//     NEXT = Next

//     OPTIONITEM = c
//     ITEM QID = 1
//     PAPERCODE = 3213
//     LANGUAGE = English
//     PAUSE = false
//     SUBJECT = 6
//     CHOOSED_ANSWER = c

//     ITEM = undefined
//     QUESTION =  <p>1. </span><span style="font-family:Times New Roman"> </span><span style="font-family:Times New Roman">‘Sty’ is related to ‘Pig’ , in the same way as ‘Byre’ is related to :
//     OPTION1 =  <p>Eagle</span><span style="font-family:Times New Roman">    </span><span style="font-family:Times New Roman">   </span></p>,
//     OPTION2 =  <p>Cow</span></p>,
//     OPTION3 =  <p>Tiger         </span></p>,
//     OPTION4 =  <p>Hen</span></p>,
//     HINDI_QUEST = <p><span style="font-family: Baloo;">1. &lsquo;&#2360;&#2369;&#2309;&#2352;&rsquo; &#2325;&#2366; &lsquo;&#2358;&#2370;&#2325;&#2352;&#2358;&#2366;&#2354;&#2366;&rsquo; &#2360;&#2375; &#2336;&#2368;&#2325; &#2357;&#2361;&#2368; &#2360;&#2306;&#2348;&#2306;&#2343; &#2361;&#2376; &#2332;&#2379; &#2360;&#2306;&#2348;&#2306;&#2343; &lsquo;&#2327;&#2379;&#2358;&#2366;&#2354;&#2366;&rsquo; &#2325;&#2366; ____ &#2360;&#2375; &#2361;&#2376; | </span></p>
// ,
//     HINDI_option1 = undefined,
//     HINDI_option2 = undefined,
//     HINDI_option3 = undefined,
//     HINDI_option4 = undefined,
//     POSITIVE_MARK = 2,
//     NEGATIVE_MARK = 0.5,
//     PAUSE = false,
//     HRS = 00,
//     MINS = 55,
//     SECS = 52,
//     PAUSE = false
// */
//   }

//   let timememo = useMemo(() => {
//     return [...theArray].reverse().filter((svq) => svq.item_no == qid);
//   }, []);

//   if (timememo != "" && timememo != undefined) {
//     var optionVal = timememo[0]["option"];
//     if (optionVal == "0") {
//       var optionVal = choosed_answer;
//     }
//     if (timememo[0]["SingleTm"] != "" && timememo[0]["SingleTm"] != undefined) {
//       const [hr, mn, sc] = timememo[0]["SingleTm"].toString().split(":");
//       var MinSecs = {
//         hours: parseInt(hr),
//         minutes: parseInt(mn),
//         seconds: parseInt(sc),
//       };
//     }
//   } else {
//     var optionVal = choosed_answer;
//     var MinSecs = {
//       hours: parseInt(hrs),
//       minutes: parseInt(mins),
//       seconds: parseInt(secs),
//     };
//   }

//   var m,
//     urls = [],
//     optionurl1 = [],
//     optionurl2 = [],
//     optionurl3 = [],
//     optionurl4 = [],
//     rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;

//   while ((m = rex.exec(question))) {
//     urls.push(m[1]);
//   }
//   while ((m = rex.exec(option1))) {
//     optionurl1.push(m[1]);
//   }
//   while ((m = rex.exec(option2))) {
//     optionurl2.push(m[1]);
//   }
//   while ((m = rex.exec(option3))) {
//     optionurl3.push(m[1]);
//   }
//   while ((m = rex.exec(option4))) {
//     optionurl4.push(m[1]);
//   }

//   const singleTime = (stm) => {
//     singleTm(stm);
//   };

//   const headerExtra = (
//     <Row className="question-row justify-content-center">
//       <Col md={6} className="mobile-width30-perc mobile-float-left ">
//         <span className="question-no mobile-font-size11 mobile-margin-top15">
//           Qusetion No. {qid}
//         </span>
//       </Col>
//       <Col
//         md={2}
//         className="mark-section mobile-width30-perc mobile-float-left text-center"
//       >
//         <p className="marks mobile-font-size12">Marks</p>
//         <span className="positive-mark">+{positive_marks}</span>
//         <span className="minus-mark">-{negative_marks}</span>
//       </Col>
//       <Col
//         md={2}
//         className="single-time-margin mobile-width15-perc mobile-float-left mobile-margin-top7 text-center"
//       >
//         <p className="single-time">Time</p>
//         <span className="single-timer">
//           <CountUp
//             MinSecs={MinSecs}
//             paper_code={paper_code}
//             Paper_id={qid}
//             singleTime={singleTime}
//             pause={pause}
//           />
//         </span>
//       </Col>
//       <Col
//         md={2}
//         className="report-div mobile-width15-perc mobile-float-left mobile-margin-top10 text-center"
//       >
//         <ReportModel question_id={qid} paper_code={paper_code} />
//       </Col>
//     </Row>
//   );

//   const latestHeader = (
//     <Row className="question-row justify-content-center align-items-center">
//       <Col
//         md={8}
//         className="mobile-width30-perc mobile-float-left text-center d-flex align-items-center"
//       >
//         <span className="question-no mobile-font-size11 mobile-margin-top15">
//           Qusetion No. {qid}
//         </span>
//       </Col>
//       <Col
//         md={2}
//         className="mark-section mobile-width30-perc mobile-float-left text-center "
//       >
//         <div className="d-flex flex-column justify-content-center">
//           <p className="marks mobile-font-size12 m-1">Marks</p>
//           <div>
//             <span className="positive-mark">+{positive_marks}</span>
//             <span className="minus-mark">-{negative_marks}</span>
//           </div>
//         </div>
//       </Col>
//       <Col
//         md={1}
//         className="single-time-margin mobile-width15-perc mobile-float-left mobile-margin-top7 text-center d-flex align-items-center"
//       >
//         <div className="d-flex flex-column justify-content-center">
//           <p className="single-time m-2">Time</p>
//           <span className="single-timer">
//             <CountUp
//               MinSecs={MinSecs}
//               paper_code={paper_code}
//               Paper_id={qid}
//               singleTime={singleTime}
//               pause={pause}
//             />
//           </span>
//         </div>
//       </Col>
//       <Col
//         md={1}
//         className="report-div mobile-width15-perc mobile-float-left mobile-margin-top10 text-center d-flex align-items-center"
//       >
//         <ReportModel question_id={qid} paper_code={paper_code} />
//       </Col>
//     </Row>
//   );

//   return (
//     <>
//       <Row className="question-row justify-content-center">
//         <Col
//           md={8}
//           className="mobile-width30-perc mobile-float-left text-center d-flex"
//           style={{ paddingLeft: "1.5rem" }}
//         >
//           <span className="question-no mobile-font-size11 mobile-margin-top15">
//             Question No. {qid}
//           </span>
//         </Col>
//         <Col
//           md={4}
//           className="mark-section mobile-width30-perc mobile-float-left text-center m-0 p-0"
//         >
//           <table
//             className="question-index-table table"
//             style={{ marginBottom: 0, borderBottom: "1px solid #ddd" }}
//           >
//             <thead className="thead-light">
//               <tr>
//                 <th className="align-middle text-center">Marks</th>
//                 <th className="align-middle text-center">Time</th>
//                 <th className="align-middle text-center">Report</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td className="align-middle text-center">
//                   {" "}
//                   {/* Added text-center class */}
//                   <span className="positive-mark">+{positive_marks}</span>
//                   <span className="minus-mark">-{negative_marks}</span>
//                 </td>
//                 <td className="align-middle text-center">
//                   {" "}
//                   {/* Added text-center class */}
//                   <span className="single-timer">
//                     <CountUp
//                       MinSecs={MinSecs}
//                       paper_code={paper_code}
//                       Paper_id={qid}
//                       singleTime={singleTime}
//                       pause={pause}
//                     />
//                   </span>
//                 </td>
//                 <td className="align-middle text-center">
//                   {" "}
//                   {/* Added text-center class */}
//                   <ReportModel question_id={qid} paper_code={paper_code} />
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </Col>
//       </Row>

//       <Row className="question-with-option" key={qid}>
//         <Col>
//           <Row className="qusetion-option">
//             <Col className="ques">
//               {Next == "Next" ? (
//                 language == "Hindi" && subject != 7 ? (
//                   <MathElement elements={hindi_question} />
//                 ) : (
//                   <MathElement elements={question} />
//                 )
//               ) : language == "Hindi" && subject != 7 ? (
//                 urls == "" ? (
//                   <MathJax math={hindi_question} />
//                 ) : (
//                   <MathElement elements={hindi_question} />
//                 )
//               ) : urls == "" ? (
//                 <MathJax math={question} />
//               ) : (
//                 <MathElement elements={question} />
//               )}
//             </Col>
//           </Row>

//           <Row style={{ marginTop: "2%" }} className="question-options-row">
//             <Row className="options">
//               <label>
//                 <input
//                   type="radio"
//                   value="a"
//                   checked={
//                     optionItem != "0" && optionItem != undefined
//                       ? optionItem == "a"
//                       : optionVal == "a"
//                       ? optionVal
//                       : optionItem == "a"
//                   }
//                   name="option"
//                   onChange={chooseOption}
//                 />
//                 {Next == "Next" ? (
//                   language == "Hindi" && subject != 7 ? (
//                     <MathElement
//                       elements={option1_hindi?.replace(/\&nbsp;/g, "")}
//                     />
//                   ) : (
//                     <MathElement elements={option1.replace(/\&nbsp;/g, "")} />
//                   )
//                 ) : language == "Hindi" && subject != 7 ? (
//                   optionurl1 == "" ? (
//                     <MathJax math={option1_hindi?.replace(/\&nbsp;/g, "")} />
//                   ) : (
//                     <MathElement
//                       elements={option1_hindi?.replace(/\&nbsp;/g, "")}
//                     />
//                   )
//                 ) : optionurl1 == "" ? (
//                   <MathJax math={option1.replace(/\&nbsp;/g, "")} />
//                 ) : (
//                   <MathElement elements={option1.replace(/\&nbsp;/g, "")} />
//                 )}
//               </label>
//             </Row>
//             <Row className="options">
//               <label>
//                 <input
//                   type="radio"
//                   value="b"
//                   checked={
//                     optionItem != "0" && optionItem != undefined
//                       ? optionItem == "b"
//                       : optionVal == "b"
//                       ? optionVal
//                       : optionItem == "b"
//                   }
//                   name="option"
//                   onChange={chooseOption}
//                 />{" "}
//                 {Next == "Next" ? (
//                   language == "Hindi" && subject != 7 ? (
//                     <MathElement
//                       elements={option2_hindi?.replace(/\&nbsp;/g, "")}
//                     />
//                   ) : (
//                     <MathElement elements={option2.replace(/\&nbsp;/g, "")} />
//                   )
//                 ) : language == "Hindi" && subject != 7 ? (
//                   optionurl2 == "" ? (
//                     <MathJax math={option2_hindi?.replace(/\&nbsp;/g, "")} />
//                   ) : (
//                     <MathElement
//                       elements={option2_hindi?.replace(/\&nbsp;/g, "")}
//                     />
//                   )
//                 ) : optionurl2 == "" ? (
//                   <MathJax math={option2.replace(/\&nbsp;/g, "")} />
//                 ) : (
//                   <MathElement elements={option2.replace(/\&nbsp;/g, "")} />
//                 )}
//               </label>
//             </Row>
//             <Row className="options">
//               <label>
//                 <input
//                   type="radio"
//                   value="c"
//                   checked={
//                     optionItem != "0" && optionItem != undefined
//                       ? optionItem == "c"
//                       : optionVal == "c"
//                       ? optionVal
//                       : optionItem == "c"
//                   }
//                   name="option"
//                   onChange={chooseOption}
//                 />
//                 {Next == "Next" ? (
//                   language == "Hindi" && subject != 7 ? (
//                     <MathElement
//                       elements={option3_hindi?.replace(/\&nbsp;/g, "")}
//                     />
//                   ) : (
//                     <MathElement elements={option3.replace(/\&nbsp;/g, "")} />
//                   )
//                 ) : language == "Hindi" && subject != 7 ? (
//                   optionurl3 == "" ? (
//                     <MathJax math={option3_hindi?.replace(/\&nbsp;/g, "")} />
//                   ) : (
//                     <MathElement
//                       elements={option3_hindi?.replace(/\&nbsp;/g, "")}
//                     />
//                   )
//                 ) : optionurl3 == "" ? (
//                   <MathJax math={option3.replace(/\&nbsp;/g, "")} />
//                 ) : (
//                   <MathElement elements={option3.replace(/\&nbsp;/g, "")} />
//                 )}
//               </label>
//             </Row>
//             <Row className="options">
//               <label>
//                 <input
//                   type="radio"
//                   value="d"
//                   checked={
//                     optionItem != "0" && optionItem != undefined
//                       ? optionItem == "d"
//                       : optionVal == "d"
//                       ? optionVal
//                       : optionItem == "d"
//                   }
//                   name="option"
//                   onChange={chooseOption}
//                 />{" "}
//                 {Next == "Next" ? (
//                   language == "Hindi" && subject != 7 ? (
//                     <MathElement
//                       elements={option4_hindi?.replace(/\&nbsp;/g, "")}
//                     />
//                   ) : (
//                     <MathElement elements={option4.replace(/\&nbsp;/g, "")} />
//                   )
//                 ) : language == "Hindi" && subject != 7 ? (
//                   optionurl4 == "" ? (
//                     <MathJax math={option4_hindi?.replace(/\&nbsp;/g, "")} />
//                   ) : (
//                     <MathElement
//                       elements={option4_hindi?.replace(/\&nbsp;/g, "")}
//                     />
//                   )
//                 ) : optionurl4 == "" ? (
//                   <MathJax math={option4.replace(/\&nbsp;/g, "")} />
//                 ) : (
//                   <MathElement elements={option4.replace(/\&nbsp;/g, "")} />
//                 )}
//               </label>
//             </Row>
//           </Row>
//         </Col>
//       </Row>
//     </>
//   );
// }
// export default Questions;

import React, { useMemo } from "react";
import { Row, Col } from "react-bootstrap";
// import MathJax from "react-mathjax-preview";
import { MathJax, MathJaxContext } from "better-react-mathjax";

import CountUp from "./CountUp";
import MathElement from "./MathMl";
import ReportModel from "./ReportModel";
import "./header1.css";

function Questions({
  question,
  qid,
  chooseOption,
  optionItem,
  option1,
  option2,
  option3,
  option4,
  hindi_question,
  option1_hindi,
  option2_hindi,
  option3_hindi,
  option4_hindi,
  language,
  positive_marks,
  negative_marks,
  singleTm,
  hrs,
  mins,
  secs,
  pause,
  Next,
  subject,
  paper_code,
  choosed_answer,
  theArray,
}) {
  // Replace \n characters in question and options
  // const replaceNewlines = (text) => text?.replace(/\\n/g, ' ').replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\\r/g, ' ');
  const replaceNewlines = (text) => text;

  const sanitizedQuestion = replaceNewlines(question);
  const sanitizedOption1 = replaceNewlines(option1);
  const sanitizedOption2 = replaceNewlines(option2);
  const sanitizedOption3 = replaceNewlines(option3);
  const sanitizedOption4 = replaceNewlines(option4);

  const sanitizedHindiQuestion = replaceNewlines(hindi_question);
  const sanitizedOption1Hindi = replaceNewlines(option1_hindi);
  const sanitizedOption2Hindi = replaceNewlines(option2_hindi);
  const sanitizedOption3Hindi = replaceNewlines(option3_hindi);
  const sanitizedOption4Hindi = replaceNewlines(option4_hindi);

  let timememo = useMemo(() => {
    return [...theArray].reverse().filter((svq) => svq.item_no == qid);
  }, [theArray, qid]);

  if (timememo.length > 0) {
    var optionVal = timememo[0]["option"];
    if (optionVal == "0") {
      optionVal = choosed_answer;
    }
    if (timememo[0]["SingleTm"]) {
      const [hr, mn, sc] = timememo[0]["SingleTm"].toString().split(":");
      var MinSecs = {
        hours: parseInt(hr),
        minutes: parseInt(mn),
        seconds: parseInt(sc),
      };
    }
  } else {
    optionVal = choosed_answer;
    MinSecs = {
      hours: parseInt(hrs),
      minutes: parseInt(mins),
      seconds: parseInt(secs),
    };
  }

  var m,
    urls = [],
    optionurl1 = [],
    optionurl2 = [],
    optionurl3 = [],
    optionurl4 = [],
    rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;

  while ((m = rex.exec(sanitizedQuestion))) {
    urls.push(m[1]);
  }
  while ((m = rex.exec(sanitizedOption1))) {
    optionurl1.push(m[1]);
  }
  while ((m = rex.exec(sanitizedOption2))) {
    optionurl2.push(m[1]);
  }
  while ((m = rex.exec(sanitizedOption3))) {
    optionurl3.push(m[1]);
  }
  while ((m = rex.exec(sanitizedOption4))) {
    optionurl4.push(m[1]);
  }

  const singleTime = (stm) => {
    singleTm(stm);
  };

  const config = {
    loader: { load: ["input/tex", "input/mml", "output/chtml"] }, // Load both TeX and MathML for MathJax
    tex: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
    },
  };

  const hasHtmlContent = (text) => /<[^>]+>/.test(text);

  const renderMathContent = (content) => {
    return hasHtmlContent(content) ? (
      // If content includes HTML, render with dangerouslySetInnerHTML for mixed content
      <div dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
      // If pure math content, render with MathJax
      <MathJax>{content}</MathJax>
    );
  };

  const headerExtra = (
    <Row className="question-row justify-content-center">
      <Col md={6} className="mobile-width30-perc mobile-float-left ">
        <span className="question-no mobile-font-size11 mobile-margin-top15">
          Question No. {qid}
        </span>
      </Col>
      <Col
        md={2}
        className="mark-section mobile-width30-perc mobile-float-left text-center"
      >
        <p className="marks mobile-font-size12">Marks</p>
        <span className="positive-mark">+{positive_marks}</span>
        <span className="minus-mark">-{negative_marks}</span>
      </Col>
      <Col
        md={2}
        className="single-time-margin mobile-width15-perc mobile-float-left mobile-margin-top7 text-center"
      >
        <p className="single-time">Time</p>
        <span className="single-timer">
          <CountUp
            MinSecs={MinSecs}
            paper_code={paper_code}
            Paper_id={qid}
            singleTime={singleTime}
            pause={pause}
          />
        </span>
      </Col>
      <Col
        md={2}
        className="report-div mobile-width15-perc mobile-float-left mobile-margin-top10 text-center"
      >
        <ReportModel question_id={qid} paper_code={paper_code} />
      </Col>
    </Row>
  );

  const latestHeader = (
    <Row className="question-row justify-content-center align-items-center">
      <Col
        md={8}
        className="mobile-width30-perc mobile-float-left text-center d-flex align-items-center"
      >
        <span className="question-no mobile-font-size11 mobile-margin-top15">
          Question No. {qid}
        </span>
      </Col>
      <Col
        md={2}
        className="mark-section mobile-width30-perc mobile-float-left text-center "
      >
        <div className="d-flex flex-column justify-content-center">
          <p className="marks mobile-font-size12 m-1">Marks</p>
          <div>
            <span className="positive-mark">+{positive_marks}</span>
            <span className="minus-mark">-{negative_marks}</span>
          </div>
        </div>
      </Col>
      <Col
        md={1}
        className="single-time-margin mobile-width15-perc mobile-float-left mobile-margin-top7 text-center d-flex align-items-center"
      >
        <div className="d-flex flex-column justify-content-center">
          <p className="single-time m-2">Time</p>
          <span className="single-timer">
            <CountUp
              MinSecs={MinSecs}
              paper_code={paper_code}
              Paper_id={qid}
              singleTime={singleTime}
              pause={pause}
            />
          </span>
        </div>
      </Col>
      <Col
        md={1}
        className="report-div mobile-width15-perc mobile-float-left mobile-margin-top10 text-center d-flex align-items-center"
      >
        <ReportModel question_id={qid} paper_code={paper_code} />
      </Col>
    </Row>
  );

  return (
    <>
      <MathJaxContext config={config}>
        <Row className="question-row justify-content-center">
          <Col
            md={8}
            className="mobile-width30-perc mobile-float-left text-center d-flex"
            style={{ paddingLeft: "1.5rem" }}
          >
            <span className="question-no mobile-font-size11 mobile-margin-top15">
              Question No. {qid}
            </span>
          </Col>
          <Col
            md={4}
            className="mark-section mobile-width30-perc mobile-float-left text-center m-0 p-0"
          >
            <table
              className="question-index-table table"
              style={{ marginBottom: 0, borderBottom: "1px solid #ddd" }}
            >
              <thead className="thead-light">
                <tr>
                  <th className="align-middle text-center">Marks</th>
                  <th className="align-middle text-center">Time</th>
                  <th className="align-middle text-center">Report</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle text-center">
                    <span className="positive-mark">+{positive_marks}</span>
                    <span className="minus-mark">-{negative_marks}</span>
                  </td>
                  <td className="align-middle text-center">
                    <span className="single-timer">
                      <CountUp
                        MinSecs={MinSecs}
                        paper_code={paper_code}
                        Paper_id={qid}
                        singleTime={singleTime}
                        pause={pause}
                      />
                    </span>
                  </td>
                  <td className="align-middle text-center">
                    <ReportModel question_id={qid} paper_code={paper_code} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row className="question-with-option" key={qid}>
          <Col>
            <Row className="qusetion-option">
              <Col className="ques">
                {Next == "Next"
                  ? language == "Hindi" && subject != 7
                    ? // <MathElement elements={sanitizedHindiQuestion} />
                      renderMathContent(sanitizedHindiQuestion)
                    : // <MathElement elements={sanitizedQuestion} />
                      renderMathContent(sanitizedQuestion)
                  : language == "Hindi" && subject != 7
                  ? urls.length === 0
                    ? // <MathJax math={sanitizedHindiQuestion} />
                      renderMathContent(sanitizedHindiQuestion)
                    : // <MathElement elements={sanitizedHindiQuestion} />
                      renderMathContent(sanitizedHindiQuestion)
                  : urls.length === 0
                  ? // <MathJax math={sanitizedQuestion} />
                    renderMathContent(sanitizedQuestion)
                  : // <MathElement elements={sanitizedQuestion} />
                    renderMathContent(sanitizedQuestion)}
              </Col>
            </Row>

            <Row style={{ marginTop: "2%" }} className="question-options-row">
              <Row className="options">
                <label>
                  <input
                    type="radio"
                    value="a"
                    checked={
                      optionItem !== "0" && optionItem !== undefined
                        ? optionItem === "a"
                        : optionVal === "a"
                    }
                    name="option"
                    onChange={chooseOption}
                  />
                  {Next === "Next"
                    ? language === "Hindi" && subject !== 7
                      ? // <MathElement
                        //   elements={sanitizedOption1Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption1Hindi)
                      : // <MathElement
                        //   elements={sanitizedOption1.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption1)
                    : language === "Hindi" && subject !== 7
                    ? optionurl1.length === 0
                      ? // <MathJax
                        //   math={sanitizedOption1Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption1Hindi)
                      : // <MathElement
                        //   elements={sanitizedOption1Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption1Hindi)
                    : optionurl1.length === 0
                    ? // <MathJax math={sanitizedOption1.replace(/&nbsp;/g, "")} />
                      renderMathContent(sanitizedOption1)
                    : // <MathElement
                      //   elements={sanitizedOption1.replace(/&nbsp;/g, "")}
                      // />
                      renderMathContent(sanitizedOption1)}
                </label>
              </Row>
              <Row className="options">
                <label>
                  <input
                    type="radio"
                    value="b"
                    checked={
                      optionItem !== "0" && optionItem !== undefined
                        ? optionItem === "b"
                        : optionVal === "b"
                    }
                    name="option"
                    onChange={chooseOption}
                  />
                  {Next === "Next"
                    ? language === "Hindi" && subject !== 7
                      ? // <MathElement
                        //   elements={sanitizedOption2Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption2Hindi)
                      : // <MathElement
                        //   elements={sanitizedOption2.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption2)
                    : language === "Hindi" && subject !== 7
                    ? optionurl2.length === 0
                      ? // <MathJax
                        //   math={sanitizedOption2Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption2Hindi)
                      : // <MathElement
                        //   elements={sanitizedOption2Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption2Hindi)
                    : optionurl2.length === 0
                    ? // <MathJax math={sanitizedOption2.replace(/&nbsp;/g, "")} />
                      renderMathContent(sanitizedOption2)
                    : // <MathElement
                      //   elements={sanitizedOption2.replace(/&nbsp;/g, "")}
                      // />
                      renderMathContent(sanitizedOption2)}
                </label>
              </Row>
              <Row className="options">
                <label>
                  <input
                    type="radio"
                    value="c"
                    checked={
                      optionItem !== "0" && optionItem !== undefined
                        ? optionItem === "c"
                        : optionVal === "c"
                    }
                    name="option"
                    onChange={chooseOption}
                  />
                  {Next === "Next"
                    ? language === "Hindi" && subject !== 7
                      ? // <MathElement
                        //   elements={sanitizedOption3Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption3Hindi)
                      : // <MathElement
                        //   elements={sanitizedOption3.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption3)
                    : language === "Hindi" && subject !== 7
                    ? optionurl3.length === 0
                      ? // <MathJax
                        //   math={sanitizedOption3Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption3Hindi)
                      : // <MathElement
                        //   elements={sanitizedOption3Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption3Hindi)
                    : optionurl3.length === 0
                    ? // <MathJax math={sanitizedOption3.replace(/&nbsp;/g, "")} />
                      renderMathContent(sanitizedOption3)
                    : // <MathElement
                      //   elements={sanitizedOption3.replace(/&nbsp;/g, "")}
                      // />
                      renderMathContent(sanitizedOption3)}
                </label>
              </Row>
              <Row className="options">
                <label>
                  <input
                    type="radio"
                    value="d"
                    checked={
                      optionItem !== "0" && optionItem !== undefined
                        ? optionItem === "d"
                        : optionVal === "d"
                    }
                    name="option"
                    onChange={chooseOption}
                  />
                  {Next === "Next"
                    ? language === "Hindi" && subject !== 7
                      ? // <MathElement
                        //   elements={sanitizedOption4Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption4Hindi)
                      : // <MathElement
                        //   elements={sanitizedOption4.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption4)
                    : language === "Hindi" && subject !== 7
                    ? optionurl4.length === 0
                      ? // <MathJax
                        //   math={sanitizedOption4Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption4Hindi)
                      : // <MathElement
                        //   elements={sanitizedOption4Hindi?.replace(/&nbsp;/g, "")}
                        // />
                        renderMathContent(sanitizedOption4Hindi)
                    : optionurl4.length === 0
                    ? // <MathJax math={sanitizedOption4.replace(/&nbsp;/g, "")} />
                      renderMathContent(sanitizedOption4)
                    : // <MathElement
                      //   elements={sanitizedOption4.replace(/&nbsp;/g, "")}
                      // />
                      renderMathContent(sanitizedOption4)}
                </label>
              </Row>
            </Row>
          </Col>
        </Row>
      </MathJaxContext>
    </>
  );
}

export default Questions;
// import { Row, Col } from "react-bootstrap";
// import { MathJax, MathJaxContext } from "better-react-mathjax";
// import CountUp from "./CountUp";
// import ReportModel from "./ReportModel";
// import { useMemo } from "react";

// function Questions({
//   question,
//   qid,
//   chooseOption,
//   optionItem,
//   option1,
//   option2,
//   option3,
//   option4,
//   hindi_question,
//   hindi_option1,
//   hindi_option2,
//   hindi_option3,
//   hindi_option4,
//   language,
//   positive_marks,
//   negative_marks,
//   singleTm,
//   hrs,
//   mins,
//   secs,
//   pause,
//   Next,
//   subject,
//   paper_code,
//   choosed_answer,
//   theArray,
// }) {
//   // Configuration for better-react-mathjax
//   const mathJaxConfig = {
//     loader: { load: ["input/tex", "output/chtml"] },
//     tex: {
//       inlineMath: [["$", "$"]],
//       displayMath: [["$$", "$$"]],
//     },
//   };

//   let timememo = useMemo(() => {
//     return [...theArray].reverse().filter((svq) => svq.item_no === qid);
//   }, [theArray, qid]);

//   let optionVal = choosed_answer;
//   let MinSecs = {
//     hours: parseInt(hrs),
//     minutes: parseInt(mins),
//     seconds: parseInt(secs),
//   };

//   if (timememo.length > 0) {
//     optionVal = timememo[0]["option"] || choosed_answer;
//     if (timememo[0]["SingleTm"]) {
//       const [hr, mn, sc] = timememo[0]["SingleTm"].split(":");
//       MinSecs = {
//         hours: parseInt(hr),
//         minutes: parseInt(mn),
//         seconds: parseInt(sc),
//       };
//     }
//   }

//   // Check if content includes HTML (e.g., image URLs)
//   const hasHtmlContent = (text) => /<[^>]+>/.test(text);

//   const renderContent = (content) => {
//     return hasHtmlContent(content) ? (
//       // If content includes HTML, render with dangerouslySetInnerHTML for mixed content
//       <div dangerouslySetInnerHTML={{ __html: content }} />
//     ) : (
//       // If pure math content, render with MathJax
//       <MathJax>{content}</MathJax>
//     );
//   };

//   return (
//     <MathJaxContext config={mathJaxConfig}>
//       <Row className="question-row">
//         <Col md={6} className="mobile-width30-perc mobile-float-left">
//           <span className="question-no mobile-font-size11 mobile-margin-top15">
//             Question No. {qid}
//           </span>
//         </Col>
//         <Col
//           md={3}
//           className="mark-section mobile-width30-perc mobile-float-left"
//         >
//           <p className="marks mobile-font-size12">Marks</p>
//           <span className="positive-mark">+{positive_marks}</span>
//           <span className="minus-mark">-{negative_marks}</span>
//         </Col>
//         <Col
//           md={1}
//           className="single-time-margin mobile-width15-perc mobile-float-left mobile-margin-top7"
//         >
//           <p className="single-time">Time</p>
//           <span className="single-timer">
//             <CountUp
//               MinSecs={MinSecs}
//               paper_code={paper_code}
//               Paper_id={qid}
//               singleTime={singleTm}
//               pause={pause}
//             />
//           </span>
//         </Col>
//         <Col
//           md={2}
//           className="report-div mobile-width15-perc mobile-float-left mobile-margin-top10"
//         >
//           <ReportModel question_id={qid} paper_code={paper_code} />
//         </Col>
//       </Row>

//       <Row className="question-with-option" key={qid}>
//         <Col>
//           <Row className="qusetion-option">
//             <Col className="ques">
//               {Next === "Next"
//                 ? language === "Hindi" && subject !== 7
//                   ? renderContent(hindi_question)
//                   : renderContent(question)
//                 : language === "Hindi" && subject !== 7
//                 ? renderContent(hindi_question)
//                 : renderContent(question)}
//             </Col>
//           </Row>

//           {/* Render options */}
//           <Row style={{ marginTop: "2%" }}>
//             <Col className="options">
//               {["a", "b", "c", "d"].map((option, index) => (
//                 <div key={option}>
//                   <label>
//                     <input
//                       type="radio"
//                       value={option}
//                       checked={
//                         optionItem !== "0" && optionItem !== undefined
//                           ? optionItem === option
//                           : optionVal === option
//                       }
//                       name="option"
//                       onChange={chooseOption}
//                     />
//                     {Next === "Next"
//                       ? language === "Hindi" && subject !== 7
//                         ? renderContent(
//                             [
//                               hindi_option1,
//                               hindi_option2,
//                               hindi_option3,
//                               hindi_option4,
//                             ][index]
//                           )
//                         : renderContent(
//                             [option1, option2, option3, option4][index]
//                           )
//                       : language === "Hindi" && subject !== 7
//                       ? renderContent(
//                           [
//                             hindi_option1,
//                             hindi_option2,
//                             hindi_option3,
//                             hindi_option4,
//                           ][index]
//                         )
//                       : renderContent(
//                           [option1, option2, option3, option4][index]
//                         )}
//                   </label>
//                 </div>
//               ))}
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </MathJaxContext>
//   );
// }

// export default Questions;
