// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserList_user_list__0b0MK {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.UserList_search__9kgnL {
  margin-bottom: 20px;
}

.UserList_table__8\\+lM7 {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
}

.UserList_table__8\\+lM7 th,
.UserList_table__8\\+lM7 td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.UserList_pagination__pMyRs {
  margin-top: 20px;
}

.UserList_pagination__pMyRs button {
  cursor: pointer;
  margin: 0 2px;
}
`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/UserList.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,aAAa;AACf","sourcesContent":[".user_list {\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  font-size: 25px;\n  margin-top: 10px;\n  margin-bottom: 20px;\n}\n\n.search {\n  margin-bottom: 20px;\n}\n\n.table {\n  width: 100%;\n  border-collapse: collapse;\n  border-spacing: 0;\n  border: 1px solid #ccc;\n}\n\n.table th,\n.table td {\n  border: 1px solid #ccc;\n  padding: 8px;\n  text-align: left;\n}\n\n.pagination {\n  margin-top: 20px;\n}\n\n.pagination button {\n  cursor: pointer;\n  margin: 0 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user_list": `UserList_user_list__0b0MK`,
	"search": `UserList_search__9kgnL`,
	"table": `UserList_table__8+lM7`,
	"pagination": `UserList_pagination__pMyRs`
};
export default ___CSS_LOADER_EXPORT___;
