import "./TestSeries.css";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import TestSeriesHeader from "./TestSeriesHeader";
// import TestSeriesFooter from "./TestSeriesFooter";
import { useParams } from "react-router";
import TestOverview from "./TestOverview";
import { BsClockFill } from "react-icons/bs";
import { AiOutlineFile } from "react-icons/ai";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { useCookies } from "react-cookie";
import { useAuthContext } from "../hooks/useAuthContext";
// import { addProfile } from "../redux/slices/userProfileSlice";
import { useSelector } from "react-redux";

function TestSeriesUnlockSection() {
  const [cookies, setCookie] = useCookies();
  const { token } = useAuthContext();
  const userProfile = useSelector((state) => state.userProfile);
  //   const { testid, exam_post_id, exam_mode_id } = useParams();
  const {
    exam_id,
    post_id,
    test_series_id,
    TierID,
    exam_mode_id,
    product_id,
  } = useParams();
  const [TestData, setTestData] = useState([]);
  const navigate = useNavigate();
  const [TestAccess, setTestAccess] = useState();
  const [TierName, setPostTierName] = useState();
  const [ExamID, setExamID] = useState();
  const [show, setShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [showEmail, setEmailShow] = useState(false);
  const [RegisteredStd, setRegistered] = useState();
  const [mobile, setMobile] = useState("");
  const [Otp, setOtp] = useState();
  const [EnterOtp, setEnterOtp] = useState();
  const [EmailID, setEmailID] = useState();
  const [FullName, setFullName] = useState();

  // Define the handleShow function to handle the "Unlock" button
  const handleShow = () => {
    setShow(true); // This will trigger the modal to open
  };

  // Close modal function
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      // let test_data = [{ email_id: cookies.email_id, test_series_id: testid }];
      let test_data = [
        { email_id: userProfile.email_id, test_series_id: test_series_id },
      ];
      let test_data_request = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/TestSeriesDetailsSection",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(test_data),
        }
      );
      let test_data_response = await test_data_request.json();
      console.log("Test_data_response", test_data_response);
      setTestData(test_data_response);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const testAccessData = async () => {
      let test_access = [
        {
          email_id: userProfile.email_id,
          exam_id: exam_id,
          post_id: post_id,
          // tier_id: exam_post_id,
          tier_id: TierID,
          exam_mode_id: exam_mode_id,
          product_id: product_id,
        },
      ];
      let test_access_request = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/testSeriesAccess",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(test_access),
        }
      );
      let test_access_response = await test_access_request.json();
      setTestAccess(test_access_response.user_status);
      console.log("test_access_response", test_access_response);
    };
    testAccessData();
  }, [exam_id, post_id, TierID, exam_mode_id]);

  const BuyFun = () => {
    // window.location.href =
    //   "https://ssccglpinnacle.com/product/Pinnacle-Test-Pass-";
    navigate(`/individualexamtestpassbuy/${product_id}`);
  };
  const startTest = (event) => {
    let exam_mode_id = event.target.getAttribute("exam_mode_id");
    let test_series_id = event.target.getAttribute("test_series_id");
    let papercode = event.target.getAttribute("paper_code");
    let test_title = event.target.getAttribute("title");
    if (
      //   (exam_post_id == 4 && exam_mode_id == 1) ||
      //   (exam_post_id == 4 && exam_mode_id == 5)
      (TierID == 4 && exam_mode_id == 1) ||
      (TierID == 4 && exam_mode_id == 5)
    ) {
      // navigate(
      //   `/instructionmtssection/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
      //     test_series_id
      //   )}/${btoa(test_title)}`
      // );
      navigate(
        `/instructionmtssection/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
          test_series_id
        )}/${btoa(test_title)}/${TierID}/${exam_id}/${post_id}/${product_id}`
      );
    } else {
      // navigate(
      //   `/instructionsection/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
      //     test_series_id
      //   )}/${btoa(test_title)}`
      // );
      navigate(
        `/instructionsection/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
          test_series_id
        )}/${btoa(test_title)}/${TierID}/${exam_id}/${post_id}/${product_id}`
      );
    }
  };
  const analysis = (event) => {
    let exam_mode_id = event.target.getAttribute("exam_mode_id");
    let test_series_id = event.target.getAttribute("test_series_id");
    let papercode = event.target.getAttribute("paper_code");
    let title = event.target.getAttribute("title");
    if (
      //   (exam_post_id == 4 && exam_mode_id == 1) ||
      //   (exam_post_id == 4 && exam_mode_id == 5)
      (TierID == 4 && exam_mode_id == 1) ||
      (TierID == 4 && exam_mode_id == 5)
    ) {
      navigate(
        `/attemptedmtssection/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
          test_series_id
        )}/${title}/${TierID}/${exam_id}/${post_id}/${product_id}`
      );
    } else {
      navigate(
        `/attemptedsection/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
          test_series_id
        )}/${title}/${TierID}/${exam_id}/${post_id}/${product_id}`
      );
    }
  };
  const ResumeTest = async (event) => {
    let exam_mode_id = event.target.getAttribute("exam_mode_id");
    let test_series_id = event.target.getAttribute("test_series_id");
    let papercode = event.target.getAttribute("paper_code");
    let language = "English";
    let qs_details = {
      email_id: userProfile.email_id,
      paper_code: papercode,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
    };
    let res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/pauseQuestionSection",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(qs_details),
      }
    );
    let qsdt = await res.json();
    console.log("QuestionData", qsdt);
    let paperids = qsdt.pause_question;
    console.log("PAPER_IDS", paperids);
    if (
      (TierID == 4 && exam_mode_id == 1) ||
      (TierID == 4 && exam_mode_id == 5)
      //   (exam_post_id == 4 && exam_mode_id == 1) ||
      //   (exam_post_id == 4 && exam_mode_id == 5)
    ) {
      navigate(
        `/testmtssection/${btoa(language)}/${btoa(papercode)}/${btoa(
          exam_mode_id
        )}/${btoa(test_series_id)}/${btoa(
          paperids
        )}/${TierID}/${exam_id}/${post_id}/${product_id}`
      );
    } else {
      navigate(
        `/testsection/${btoa(language)}/${btoa(papercode)}/${btoa(
          exam_mode_id
        )}/${btoa(test_series_id)}/${btoa(
          paperids
        )}/${TierID}/${exam_id}/${post_id}/${product_id}`
      );
    }
  };
  useEffect(() => {
    async function tierIds() {
      // let tier_ids = { tier_id: exam_post_id };
      let tier_ids = { tier_id: TierID };
      let res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/Breadcrump",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tier_ids),
        }
      );
      let tier = await res.json();
      setExamID(tier[0]["ExamId"]);
      setPostTierName(tier[0]["PostTierId"]);
    }

    tierIds();
  }, []);

  const ValidationFun = () => {
    alert(`Please Enter Valid Mobile Number`);
  };
  return (
    <>
      {/* <TestSeriesHeader /> */}
      <TestOverview
        ExamID={exam_id}
        post_id={post_id}
        // TierID={exam_post_id}
        TierID={TierID}
        TierName={TierName}
        exam_mode_id={exam_mode_id}
        // test_series_id={testid}
        test_series_id={test_series_id}
      />
      <div className="float-left width-100-perc padding-top50">
        <div className="test-pass-container">
          <Row>
            {TestData.map((tdm, i) => (
              <>
                {tdm.Questions == "" ? (
                  ""
                ) : (
                  <Col md={4} className="margin-bottom30" key={i}>
                    {" "}
                    <div className="full-width float-left border-blue padding-9px-9px display-flex align-items-center">
                      <div className="width-70-perc float-left">
                        <p>{tdm.Title}</p>
                        <p>
                          <span className="margin-right10">
                            <HiQuestionMarkCircle className="font-size-18 margin-right3 margin-top-minus3" />
                            {tdm.Questions}
                          </span>{" "}
                          <span className="margin-right10">
                            <AiOutlineFile className="font-size-18 margin-right3 margin-top-minus3" />
                            {tdm.Marks}
                          </span>{" "}
                          <span>
                            <BsClockFill className="font-size-18 margin-right3 margin-top-minus3" />
                            {tdm.Time}
                          </span>
                        </p>
                      </div>
                      <div className="width-30-perc float-left margin-center">
                        {TestAccess == 1 ? (
                          tdm.PaperStatus == 2 ? (
                            <button
                              onClick={startTest}
                              test_series_id={test_series_id}
                              //   test_series_id={testid}
                              exam_mode_id={tdm.exam_mode_id}
                              paper_code={tdm.paper_code}
                              title={tdm.Title}
                              className="background-none border-9191ff color-9191ff br5-important"
                            >
                              Start Test
                            </button>
                          ) : tdm.PaperStatus == 1 ? (
                            <button
                              onClick={analysis}
                              test_series_id={test_series_id}
                              //   test_series_id={testid}
                              exam_mode_id={tdm.exam_mode_id}
                              paper_code={tdm.paper_code}
                              title={tdm.Title}
                              className="background-none border-9191ff color-9191ff br5-important"
                            >
                              View Result
                            </button>
                          ) : (
                            <button
                              onClick={ResumeTest}
                              //   test_series_id={testid}
                              test_series_id={test_series_id}
                              exam_mode_id={tdm.exam_mode_id}
                              paper_code={tdm.paper_code}
                              title={tdm.Title}
                              className="background-none border-9191ff color-9191ff br5-important"
                            >
                              Resume Test
                            </button>
                          )
                        ) : (
                          <button
                            onClick={RegisteredStd == "0" ? handleShow : BuyFun}
                            // test_series_id={testid}
                            test_series_id={test_series_id}
                            exam_mode_id={tdm.exam_mode_id}
                            paper_code={tdm.paper_code}
                            title={tdm.Title}
                            className="background-none border-9191ff color-9191ff br5-important"
                          >
                            Unlock
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="full-width float-left background-f1f1f1">
                      <p className="padding-0px-9px">
                        Syllabus | {tdm.languages}
                      </p>
                    </div>
                  </Col>
                )}
              </>
            ))}
          </Row>
        </div>
      </div>

      {/* <TestSeriesFooter /> */}
    </>
  );
}

export default TestSeriesUnlockSection;
