import React, { useState } from "react";
import { Container, Form, Button, Alert, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { jwtDecode } from "jwt-decode";
import ForgotPassword from "./ForgotPassword";
import GoogleAuth from "./GoogleAuth";

import { useDispatch } from "react-redux";
import { useAuthContext } from "../../hooks/useAuthContext";
import { addProfile } from "../../redux/slices/userProfileSlice";
import { RiTreasureMapFill } from "react-icons/ri";

const cookies = new Cookies();

function LoginComponent() {
  const [inputField, setInputField] = useState("");
  const [forgotInputField, setForgotInputField] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState(null); // State to show the response message
  const [isError, setIsError] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const { dispatch } = useAuthContext();
  const reduxDispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent form submission
    // Functionality for regular login
    console.log("Regular login functionality", inputField, password);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/; // Indian mobile number format

    let data;
    if (emailRegex.test(inputField)) {
      // setInputField('email')
      data = { email: inputField, password };
    } else if (mobileNumberRegex.test(inputField)) {
      data = { mobileNumber: inputField, password };
      // setInputField('mobileNumber')
    } else {
      // setInputField('');
      setIsError(true);
      setShowMessage("Invalid Email/ Mobile number Address");
      return;
    }

    console.log("LoginData", data);
    // console.log(
    //   "PROCESS.ENV.REACT_APP_PINNACLE_BASE_URL",
    //   process.env.REACT_APP_PINNACLE_BASE_URL
    // );
    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
          // credentials: "include",
        }
      );
      // console.log("Response", response);
      const responseData = await response.json();

      if (response.ok) {
        console.log(responseData);
        setShowMessage(responseData.message);
        setPassword("");
        setInputField("");

        // stored token to local storage
        const token = responseData.token;
        // localStorage.setItem("userData", JSON.stringify(responseData.data));
        // localStorage.setItem("token", token);

        // const expiration = new Date();
        const expiration = new Date(Date.now() + 1000 * 60 * 60 * 24 * 60);
        const localExpiration = expiration.setHours(expiration.getHours() + 24);

        // localStorage.setItem("expiration", expiration.toISOString());

        // stored token in cookie
        const decode = jwtDecode(token);

        const cookieOptions = {
          expires: expiration,
          secure: true,
          domain: ".ssccglpinnacle.com",
          sameSite: "None",
          path: "/",
        };
        // const cookieOptions = {
        //   expires: expiration,
        //   secure: process.env.NODE_ENV === "production",
        //   sameSite: process.env.NODE_ENV === "production" ? "None" : "Lax",
        //   domain:
        //     process.env.NODE_ENV === "production"
        //       ? ".ssccglpinnacle.com"
        //       : "localhost",
        //   path: "/",
        // };

        cookies.set("token", token, cookieOptions);

        cookies.set(
          "userData",
          JSON.stringify(responseData.data),
          cookieOptions
        );

        console.log("Token stored in cookies:", cookies.get("token"));
        console.log("User data stored in cookies:", cookies.get("userData"));

        // cookies.set("jwt", responseData.token, {
        //   expires: new Date(decode.exp),
        // });

        reduxDispatch(addProfile(responseData.data));
        // dispatch login action
        // dispatch({ type: "LOGIN", payload: { token: responseData.token } });
        dispatch({ type: "LOGIN", payload: responseData.token });

        setTimeout(() => {
          setShowMessage(null); // hide the response message
          navigate("/");
          // clearInterval(timerRef.current);
          // setLoginWithMobile(true);
          // setStartTimer(true);
          // setSeconds(initialSeconds);
        }, 5000);
        // } else {
        //   console.error(responseData);
        //   setIsError(true);
        //   setShowMessage(responseData.message);
        //   console.log("message", responseData.message);
        // }
      } else {
        console.error(responseData);
        setIsError(true);

        // Combine backend message with additional note
        const additionalMessage = (
          <>
            Note: if you are an old student, then use the same email ID and
            mobile for registration on{" "}
            <a
              href="https://testportal.ssccglpinnacle.com/login"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "blue",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              new web
            </a>{" "}
            or{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "blue",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              app
            </a>{" "}
            which you have used for purchasing.
          </>
        );

        const combinedMessage = (
          <>
            <div style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}>
              {responseData.message}
              <br />
              {additionalMessage}
            </div>
          </>
        );

        setIsError(true);
        setShowMessage(combinedMessage); // Display combined message
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowMessage(error.message);
    }
  };

  const mobileEmailChangeHandler = (e) => {
    setShowMessage(null);
    setInputField(e.target.value);
  };

  const passwordChangeHandler = (e) => {
    setShowMessage(null);
    setPassword(e.target.value);
  };

  const forgotPasswordChangeHandler = (e) => {
    setShowMessage(null);
    setForgotInputField(e.target.value);
  };

  const forgetPasswordSubmitHandler = async (e) => {
    e.preventDefault(); // Prevent form submission
    // Functionality for regular login
    console.log("Regular login functionality", forgotInputField);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/; // Indian mobile number format

    let data;
    if (emailRegex.test(inputField)) {
      data = { email: forgotInputField };
    } else if (mobileNumberRegex.test(inputField)) {
      data = { mobileNumber: forgotInputField };
    } else {
      // setInputField('');
      setIsError(true);
      setShowMessage("Invalid Email/ Mobile number Address");
      return;
    }

    // console.log("LoginData", data);

    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/users/ForgotPassword",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      // console.log("Response", response);
      const responseData = await response.json();

      if (response.ok) {
        console.log(responseData);
        setShowMessage(responseData.message);
        setForgotInputField("");

        // stored token to local storage
        const token = responseData.token;
        // localStorage.setItem("userData", JSON.stringify(responseData.data));
        // localStorage.setItem("token", token);

        // const expiration = new Date();
        const expiration = new Date(Date.now() + 1000 * 60 * 60 * 24 * 60);
        const localExpiration = expiration.setHours(expiration.getHours() + 24);

        // localStorage.setItem("expiration", expiration.toISOString());

        // stored token in cookie
        const decode = jwtDecode(token);

        const cookieOptions = {
          expires: expiration,
          secure: true,
          domain: ".ssccglpinnacle.com",
          sameSite: "None",
          path: "/",
        };

        // console.log("Setting token:", token);
        // console.log("Setting userData:", responseData.data);

        // const cookieOptions = {
        //   expires: expiration,
        //   secure: process.env.NODE_ENV === "production",
        //   sameSite: process.env.NODE_ENV === "production" ? "None" : "Lax",
        //   domain:
        //     process.env.NODE_ENV === "production"
        //       ? ".ssccglpinnacle.com"
        //       : "localhost",
        //   path: "/",
        // };

        cookies.set("token", token, cookieOptions);
        cookies.set(
          "userData",
          JSON.stringify(responseData.data),
          cookieOptions
        );

        console.log("line 256", cookies.set);

        // cookies.set("expiration", expiration.toISOString(), cookieOptions);

        // cookies.set("userData", JSON.stringify(responseData.data), {
        //   expires: expiration,
        //   secure: true,
        //   domain: ".ssccglpinnacle.com",
        //   sameSite: "None",
        //   path: "/",
        // });
        // // dispatch login action
        reduxDispatch(addProfile(responseData.data));
        // dispatch({ type: "LOGIN", payload: { token: responseData.token } });
        dispatch({ type: "LOGIN", payload: responseData.token });

        setTimeout(() => {
          setShowMessage(null); // hide the response message
          navigate("/");
          // clearInterval(timerRef.current);
          // setLoginWithMobile(true);
          // setStartTimer(true);
          // setSeconds(initialSeconds);
        }, 5000);
        // } else {
        //   console.error(responseData);
        //   setIsError(true);
        //   setShowMessage(responseData.message);
        // }
      } else {
        console.error(responseData);
        setIsError(true);

        // Combine backend message with additional note
        const additionalMessage = (
          <>
            Note: if you are an old student, then use the same email ID and
            mobile for registration on{" "}
            <a
              href="https://testportal.ssccglpinnacle.com/login"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "blue",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              new web
            </a>{" "}
            or{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "blue",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              app
            </a>{" "}
            which you have used for purchasing.
          </>
        );

        const combinedMessage = (
          <>
            <div style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}>
              {responseData.message}
              <br />
              {additionalMessage}
            </div>
          </>
        );

        setIsError(true);
        setShowMessage(combinedMessage); // Display combined message
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowMessage(error.message);
    }
  };

  const emailBody = (
    <Container>
      <Form>
        <Form.Group controlId="formEmailMobile" className="form-div">
          <Form.Label>Enter your email or mobile number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email or Mobile number"
            className="input-field"
            value={forgotInputField}
            // onChange={(e) => setInputField(e.target.value)}
            onChange={forgotPasswordChangeHandler}
          />
        </Form.Group>
        {showMessage && (
          <Alert variant={isError ? "danger" : "success"}>{showMessage}</Alert>
        )}
      </Form>
    </Container>
  );

  const title = <h3>Forgot Password !</h3>;

  const footer = (
    <Button
      variant="primary"
      disabled={false} // Disable button if terms are not accepted
      onClick={(e) => forgetPasswordSubmitHandler(e)}
    >
      Submit
    </Button>
  );

  const handleForgotPassword = () => {
    // Functionality for Forgot Password
    // setShow(true);
    setIsForgotPassword(true);
    console.log("Forgot Password functionality");
  };

  const PasswordSuccessfullyChangedHandler = () => {
    setIsForgotPassword(false);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  return (
    <div>
      {!isForgotPassword ? (
        <Form>
          <div style={{ marginBottom: "2rem" }}>
            <GoogleAuth />
          </div>
          <Form.Group controlId="formEmail" className="form-div">
            <Form.Label>Enter your email or mobile number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email or mobile number"
              className="input-field"
              value={inputField}
              // onChange={(e) => setInputField(e.target.value)}
              onChange={mobileEmailChangeHandler}
            />
          </Form.Group>
          <Form.Group controlId="formPassword" className="form-div">
            <Form.Label>Password</Form.Label>
            <Form.Control
              // type="password"
              type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
              placeholder="Password"
              className="input-field"
              value={password}
              // onChange={(e) => setPassword(e.target.value)}
              onChange={passwordChangeHandler}
            />
            {/* <Form.Check
              type="checkbox"
              label="Show password"
              checked={showPassword}
              onChange={toggleShowPassword}
              className="mt-2"
            /> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <input
                type="checkbox"
                id="showPasswordCheckbox"
                checked={showPassword}
                onChange={toggleShowPassword}
                style={{
                  width: "18px", // Adjust checkbox size
                  height: "18px",
                  border: "2px solid black", // Dark border
                  borderRadius: "3px", // Optional rounded corners
                  cursor: "pointer",
                  backgroundColor: "white", // Ensure background color matches
                }}
              />
              <label
                htmlFor="showPasswordCheckbox"
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                Show password
              </label>
            </div>
            <style>
              {`
      #showPasswordCheckbox:checked {
        background-color: #007bff; /* Change to desired color */
        border-color: #007bff; /* Border color for checked state */
        color: white; /* Text color inside the checkbox if needed */
      }
    `}
            </style>
          </Form.Group>
          <div className="text-right">
            <Button
              variant="link"
              onClick={handleForgotPassword}
              className="forgot-password-btn"
            >
              Forgot Password?
            </Button>
          </div>
          <Button
            variant="primary"
            onClick={handleLogin}
            className="login-submit-btn"
          >
            Login
          </Button>
          {/*
        <Button variant="link" className="login-with-gmail-btn">
          Login with Gmail
        </Button>
        */}

          {showMessage && (
            <Alert variant={isError ? "danger" : "success"}>
              {showMessage}
            </Alert>
          )}
        </Form>
      ) : (
        <ForgotPassword
          PasswordSuccessfullyChanged={PasswordSuccessfullyChangedHandler}
        />
      )}

      {/*

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        // className="modal-centered"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{emailBody}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>

      */}
    </div>
  );
}

export default LoginComponent;

// import React, {useState} from "react";
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';

// function LoginComponent() {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [showMessage, setShowMessage] = useState(null); // State to show the response message

//     const handleLogin = async (e) => {
//       e.preventDefault(); // Prevent form submission
//       // Functionality for regular login
//       console.log("Regular login functionality", email, password);

//       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//       if (!email || !emailRegex.test(email)) {
//         setShowMessage("Invalid Email Address");
//         return;
//       }

//       const data = {email_id: email, password}
//       console.log("LoginData",data);
//       // try {
//       //   const response = await fetch(
//       //     process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/emailVerification",
//       //     {
//       //       method: 'POST',
//       //       headers: { "Content-Type": "application/json" },
//       //       body: JSON.stringify(data),
//       //     }
//       //   );
//       //   if (response.ok){
//       //     const responseData = await response.json();
//       //     console.log(responseData);
//       //     setShowMessage(responseData.message);
//       //   } else {
//       //     console.error("Something went wrong. Please try again after sometime.");
//       //     setShowMessage("Something went wrong. Please try again after sometime.");
//       //   }
//       // } catch (error) {
//       //   console.error("Error fetching data:", error);
//       //   setShowMessage(error.message);
//       // }
//     };

//     const handleForgotPassword = () => {
//         // Functionality for Forgot Password
//         console.log("Forgot Password functionality");
//     };

//   return (
//     <div>
//       <Form>
//         <Form.Group controlId="formLogin" className="form-div">
//           <Form.Label>Enter your email or mobile number</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Email or mobile number"
//             className="input-field"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           <Form.Label>Password</Form.Label>
//           <Form.Control
//             type="password"
//             placeholder="Password"
//             className="input-field"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//         </Form.Group>
//         <div className="text-right">
//           <Button
//             variant="link"
//             onClick={handleForgotPassword}
//             className="forgot-password-btn"
//           >
//             Forgot Password?
//           </Button>
//         </div>
//         <Button
//           variant="primary"
//           onClick={handleLogin}
//           className="login-submit-btn"
//         >
//           Login
//         </Button>
//         <Button variant="link" className="login-with-gmail-btn">
//           Login with Gmail
//         </Button>
//       </Form>
//     </div>
//   );
// }

// export default LoginComponent;
