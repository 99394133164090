// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_carousel_div__pc6lU {
  width: 100%;
  height: 44vh;
  margin-top: 12px;
}

.Carousel_carousel_div__pc6lU img{
    width: 98%;
    height: 44vh;
}

.Carousel_carousel_img__HrOqN {
  width: 100%;
  height: 40vh;
  position: relative;
  display: flex;
  justify-content: center;
}

.Carousel_carousel_btn__aF2hS {
  position: absolute;
  bottom: -52px;
}

.Carousel_carousel_btn__aF2hS button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.Carousel_hidden__J94HR {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Carousel_carousel_div__pc6lU {
    width: 100%;
    height: 33vh;
    margin-top: 4px;
  }

  .Carousel_carousel_div__pc6lU img{
    height: 30vh;
    width: 98%;
  }

  .Carousel_carousel_btn__aF2hS {
    position: absolute;
    bottom: 100px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/Carousel.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,aAAa;EACf;AACF","sourcesContent":[".carousel_div {\n  width: 100%;\n  height: 44vh;\n  margin-top: 12px;\n}\n\n.carousel_div img{\n    width: 98%;\n    height: 44vh;\n}\n\n.carousel_img {\n  width: 100%;\n  height: 40vh;\n  position: relative;\n  display: flex;\n  justify-content: center;\n}\n\n.carousel_btn {\n  position: absolute;\n  bottom: -52px;\n}\n\n.carousel_btn button {\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.hidden {\n  display: none;\n}\n\n@media only screen and (min-width: 768px) and (max-width: 1024px) {\n  .carousel_div {\n    width: 100%;\n    height: 33vh;\n    margin-top: 4px;\n  }\n\n  .carousel_div img{\n    height: 30vh;\n    width: 98%;\n  }\n\n  .carousel_btn {\n    position: absolute;\n    bottom: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel_div": `Carousel_carousel_div__pc6lU`,
	"carousel_img": `Carousel_carousel_img__HrOqN`,
	"carousel_btn": `Carousel_carousel_btn__aF2hS`,
	"hidden": `Carousel_hidden__J94HR`
};
export default ___CSS_LOADER_EXPORT___;
