// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RefundPolicy_refund_policy_div__Osh5v{
    margin-top: 1.5rem;
}

.RefundPolicy_refund_policy_div__Osh5v h2{
    font-size: 25px;
    font-weight: bold;
    line-height: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/component/RefundPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".refund_policy_div{\n    margin-top: 1.5rem;\n}\n\n.refund_policy_div h2{\n    font-size: 25px;\n    font-weight: bold;\n    line-height: 4rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refund_policy_div": `RefundPolicy_refund_policy_div__Osh5v`
};
export default ___CSS_LOADER_EXPORT___;
