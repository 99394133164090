// import React, { useState } from 'react';
// import axios from 'axios'; // Import axios

// const TestseriesAdd = () => {
//   const [seriesTitle, setSeriesTitle] = useState('');
//   const [totalPapers, setTotalPapers] = useState('');
//   const [totalMarks, setTotalMarks] = useState('');
//   const [totalDuration, setTotalDuration] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();
   
//     try {
//       // Create a FormData object to send file data along with other fields
//       const formData = new FormData();
//       formData.append('seriesTitle', seriesTitle);
//       formData.append('totalPapers', totalPapers);
//       formData.append('totalMarks', totalMarks);
//       formData.append('totalDuration', totalDuration);

//       console.log('Form data:', formData);

//       // Make the API request to add the product
//       const response = await axios.post(process.env.REACT_APP_PINNACLE_BASE_URL + '/testseries/add', formData, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       // Handle the response if needed
//       console.log('Product added:', response.data);

//       // Reset form fields after successful submission
//       setSeriesTitle('');
//       setTotalPapers('');
//       setTotalMarks('');
//       setTotalDuration('');
//     } catch (error) {
//       // Handle error if API request fails
//       console.error('Error adding product:', error);
//     }
//   };

//   return (
//     <div>
//       <h1>Add Test Series</h1>
//       <form onSubmit={handleSubmit}>
//         <label>
//           Series Title:
//           <input
//             type="text"
//             value={seriesTitle}
//             onChange={(e) => setSeriesTitle(e.target.value)}
//             required
//           />
//         </label>
//         <label>
//           Total Papers:
//           <input
//             type="number"
//             value={totalPapers}
//             onChange={(e) => setTotalPapers(e.target.value)}
//             required
//           />
//         </label>
//         <label>
//           Total Marks:
//           <input
//             type="number"
//             value={totalMarks}
//             onChange={(e) => setTotalMarks(e.target.value)}
//             required
//           />
//         </label>
//         <label>
//           Total Duration:
//           <input
//             type="text"
//             value={totalDuration}
//             onChange={(e) => setTotalDuration(e.target.value)}
//             required
//           />
//         </label>
//         <button type="submit">Add Test Series</button>
//       </form>
//     </div>
//   );
// };

// export default TestseriesAdd;

import React from 'react'

const TestseriesAdd = () => {
  return (
    <div>TestseriesAdd</div>
  )
}

export default TestseriesAdd