import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CashFreeButton from "./CashFreeButton";
import ProductHeader from "./ProductHeader"; // Import the new component
import classes from "./IndividualExamTestPassBuy.module.css"; // Import CSS module
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout"; //

function IndividualExamTestPassBuy() {
  const { token, dispatch: authDispatch } = useAuthContext();
  const reduxDispatch = useDispatch();
  const user = useSelector((state) => state.userProfile);
  const navigate = useNavigate();

  const { product_id } = useParams(); // Get product_id from route params
  const [product, setProduct] = useState(null);
  const [examName, setExamName] = useState([]);
  const [examPostName, setExamPostName] = useState([]);
  const [examModeName, setExamModeName] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const handleBuyProduct = () => {
    navigate(`/individualexamtestpassbuy/${121}`);
  };

  useEffect(() => {
    // Fetch the product details using product_id
    const fetchProduct = async () => {
      setLoading(true); // Start loading
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/individualexamtestpassbuy/${product_id}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching product: ${response.statusText}`);
        }

        const data = await response.json();

        if (data.data && data.data.length > 0) {
          setProduct(data.data[0]); // Assuming the API returns an array
        } else {
          setProduct(null); // If no product is found
        }
        setExamName(data.exam_name_list);
        setExamPostName(data.exam_post_names_list);
        setExamModeName(data.exam_mode_list);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchProduct();
  }, [product_id]); // Add product_id as a dependency to refetch when it changes

  // Show a loading message while the product is being fetched
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner component
  }

  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.header}>
        <ProductHeader
          title="Pinnacle SSC TCS Test Pass"
          handleBuyProduct={handleBuyProduct}
          price="499"
          offer="Festival offer"
        />
        <h1>{product.Product_title}</h1>
        <p className={classes.offer}>
          Special offer till 11:59 PM today Rs {product.product_price}/-
        </p>
        </div>
        <div className={classes.content}>
          {/* Render the description on the left */}
          {product.description && product.description.length > 0 && (
            <div className={classes.descriptionSection}>
              <div className={classes.buttonSection}>
                <h5>Exam:</h5>
                {examName.map((exam, index) => (
                  <button key={index} className={classes.infoButton}>
                    {exam}
                  </button>
                ))}
              </div>
              <div className={classes.buttonSection}>
                <h5>Exam Included:</h5>
                {examPostName.map((post, index) => (
                  <button key={index} className={classes.infoButton}>
                    {post}
                  </button>
                ))}
              </div>
              {/* Render Exam Modes buttons */}
              <div className={classes.buttonSection}>
                <h5>Exam Modes:</h5>
                {examModeName.map((mode, index) => (
                  <button key={index} className={classes.infoButton}>
                    {mode}
                  </button>
                ))}
              </div>
              <div className={classes.description}>
                <h5>Product Description</h5>
                <div
                  dangerouslySetInnerHTML={{ __html: product.description1[0] }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={classes.card}>
            <h2>{product.Product_title}</h2>
            <img
              src={product.imgUrl[0]}
              className={classes.product_img}
              // alt="Product"
            />
            <p>Validity: {product.product_validation_period} days</p>
            <p>
              Price: <strong>{product.product_price}</strong> {"  "}{" "}
              <strike>{product.product_max_price}</strike>
            </p>
            <CashFreeButton
              productId={product_id}
              user={user}
              buttonText="Buy Now"
              className={classes.buyNowBtn}
            />
          </div>
      </div>
    </Layout>
  );
}

export default IndividualExamTestPassBuy;
