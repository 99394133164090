import React, { useState, useEffect } from "react";
import classes from "./AddTestSeriesProduct.module.css";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";

const AddTestSeriesProduct = () => {
  const [productData, setProductData] = useState([]);
  const [testSeriesData, setTestSeriesData] = useState([]);
  const [addedProduct, setAddedProduct] = useState(null);
  const [addedTestSeries, setAddedTestSeries] = useState(null);

  const getData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/gettestseriesproductdata"
      );

      console.log(response.data);

      setProductData(
        response.data.productData.map((product) => ({
          value: product.id,
          label: product.product_title,
        }))
      );
      setTestSeriesData(
        response.data.testSeriesData.map((testSeries) => ({
          value: testSeries.id,
          label: testSeries.test_series_name,
        }))
      );
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  // console.log(productData);
  // console.log(testSeriesData);

  useEffect(() => {
    getData();
  }, []);

  // const handleProductSelect = (selectedList, selectedItem) => {
  //   setAddedProduct(selectedItem);
  // };

  // const handleTestSeriesSelect = (selectedList, selectedItem) => {
  //   setAddedTestSeries(selectedList);
  // // };

  console.log(addedProduct);

  console.log(addedTestSeries);

  const formData = {
    product_id: addedProduct,
    test_series_id: addedTestSeries,
  };

  const addTestSeriesInProduct = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/addedtestseriesinproduct",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Data successfully added:", response.data);
      alert("testseriesaddedsuccessfully");
      // Handle response as needed
    } catch (error) {
      console.error("Error adding data:", error);
      // Handle error
    }
  };

  const handleAddedProduct = (selectedList, selectedItem) => {
    setAddedProduct(selectedItem);
  };

  const handleRemoveProduct = (selectedList, removedItem) => {
    setAddedProduct(null);
  };

  const handleAddedTestSeries = (selectedList, selectedItem) => {
    setAddedTestSeries(selectedItem);
  };

  const handleRemoveTestSeries = (selectedList, removeItem) => {
    setAddedTestSeries(null);
  };

  console.log(addedProduct);
  console.log(addedTestSeries);
  return (
    <div>
      <h2>Add Test series in Product</h2>
      <div className={classes.working_area_start}>
        <div className={classes.label_intro_section}>
          <label>Product Name</label>
          <Multiselect
            options={productData}
            displayValue="label"
            selectionLimit={1}
            onSelect={handleAddedProduct}
            onRemove={handleRemoveProduct}
          />
        </div>
        <div className={classes.label_intro_section}>
          <label>Test series Name</label>
          <Multiselect
            options={testSeriesData}
            displayValue="label"
            selectionLimit={1}
            onSelect={handleAddedTestSeries}
            onRemovee={handleRemoveTestSeries}
          />
        </div>
        <div className={classes.basic_functionality_of_submit_button}>
          <button
            onClick={addTestSeriesInProduct}
            className={classes.save_button}
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTestSeriesProduct;
