import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";

import Home from "../component/Home";
import "./EmailVerification.css";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addProfile } from "../redux/slices/userProfileSlice";

const EmailVerification = () => {
  const [show, setShow] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [showMessage, setShowMessage] = useState(null); // State to show the response message
  const [submitButton, setSubmitButton] = useState(false); // State to show the response message
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.userProfile);
  console.log("userProfile", userProfile);
  const navigate = useNavigate();

  const handleEmailLogin = async (e) => {
    e.preventDefault(); // Prevent form submission
    if (!userProfile._id) {
      setTimeout(() => {
        setShowMessage(
          "Please Register with a valid email address or mobile number "
        );
        navigate("/login");
      }, 1500);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setShowMessage("Invalid Email Address");
      return;
    }

    // const email_id = email;
    const data = { _id: userProfile._id, email_id: email, fullName };
    console.log("Items Inside Handler", userProfile._id);
    console.log(data);
    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/users/emailVerificationAfterMobile",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);

        setShowMessage(responseData.message); // Show the response message
        dispatch(addProfile(responseData.data));
        setSubmitButton(true);
      } else {
        console.error("Something went wrong in sending email verification");
        // throw new Error("Could not fetch events");
      }
    } catch (error) {
      setShowMessage(error.message);
      console.error("Error fetching data:", error);
    }
  };

  const emailBody = (
    <Form>
      <Form.Group controlId="FullName">
        <Form.Label>
          <p>Please enter your Name & email address to proceed further.</p>
        </Form.Label>
        <Row className="m-1">
          <Form.Control
            type="text"
            placeholder="Full Name"
            className="input-field"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </Row>
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Row className="m-1">
          <Form.Control
            type="email"
            placeholder="email id"
            className="input-field"
            value={email}
            autoComplete="email"  // Explicitly specify email-only autocomplete
            name="email"           // Use a clear, specific name for email
            onChange={(e) => setEmail(e.target.value)}
          />
        </Row>
        {showMessage ? (
          <Alert variant="success" className="alert">
            {showMessage}
          </Alert>
        ) : (
          ""
        )}
      </Form.Group>
    </Form>
  );

  //   const tempOverlayModal = (
  //     <OverlayModal
  //       show={modalShow}
  //       handleClose
  //       title="Email Verification"
  //       body={emailBody}
  //       footer={
  //         <Button
  //           variant="primary"
  //           onClick={() => {
  //             /* Add save functionality here */
  //           }}
  //         >
  //           Save Changes
  //         </Button>
  //       }
  //     />
  //   );

  const title = <h1>Sign-up incomplete !</h1>;

  const footer = (
    <Button
      variant="primary"
      onClick={(e) => {
        handleEmailLogin(e);
        /* Add save functionality here */
      }}
      disabled={submitButton}
    >
      Submit
    </Button>
  );

  return (
    <div>
      <Home />
      <Modal
        show={show}
        onHide={() => {}}
        backdrop="static"
        keyboard={false}
        className="modal-centered"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{emailBody}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmailVerification;
