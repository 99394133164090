// App.js

import React from "react";
import ProductDetails from "./ProductDetails";
import "./TestSeries.css";
// import IndividualTestPass from "./IndividualTestPass";

const TestSeries = () => {
  return (
    <>
      <ProductDetails />
      {/* <IndividualTestPass /> */}
    </>
  );
};

export default TestSeries;
