// import React, { useEffect, useState } from "react";
// import classes from "./Carousel.module.css";
// import img_1 from "../images/img_1.png";
// import img_2 from "../images/img_2.jpg";
// import img_3 from "../images/img_3.png";
// import img_4 from "../images/img_4.jpg";
// import img_5 from "../images/img_5.jpg";
// import { IoIosArrowDropleftCircle } from "react-icons/io";
// import { IoIosArrowDroprightCircle } from "react-icons/io";

// const Carousel = () => {
//   const [carousel, setCarousel] = useState(0);

//   const data = [img_1, img_2, img_3, img_4, img_5];

//   const HandleMinus = () => {
//     // setCarousel(carousel+1)
//     // setCarousel(carousel === 4? 0: carousel+1)
//     setCarousel(carousel === data.length - 1 ? 0 : carousel + 1);
//   };

//   const HandlePlus = () => {
//     setCarousel(carousel === 0 ? data.length - 1 : carousel - 1);
//   };

//   useEffect(() => {
//     const carouselClear = setInterval(() => {
//       HandlePlus();
//     }, 2000);
//     return () => clearInterval(carouselClear);
//   }, [carousel]);

//   return (
//     <>
//       <div className={classes.carousel_div}>
//         {data.map((img, i) => (
//           <div className={`${classes.carousel_img} ${carousel === i? classes.block : classes.hidden }`} key={i}>
//             <img
//               src={img}
//               alt="image_1"
//               style={{ width: "100%", height: "80vh" }}
//               className={classes.hidden}
//             />
//             <div className={classes.carousel_btn}>
//               <button onClick={HandleMinus}>
//                 <IoIosArrowDropleftCircle />
//               </button>
//               <button onClick={HandlePlus}>
//                 <IoIosArrowDroprightCircle />
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </>
//   );
// };

// export default Carousel;

// Carousel.js
import React, { useEffect, useState } from "react";
import classes from "./Carousel.module.css";
import img_1 from "../images/Add a heading (1).jpg";
import img_3 from "../images/Add a heading (2).jpg";
import img_5 from "../images/Add a heading (2).jpg";
// import {
//   IoIosArrowDropleftCircle,
//   IoIosArrowDroprightCircle,
// } from "react-icons/io";

import { GoDotFill } from "react-icons/go";

const Carousel = () => {
  const [carousel, setCarousel] = useState(0);
  const data = [img_1, img_3, img_5];

  const handleNext = () => {
    setCarousel((carousel + 1) % data.length);
  };

  const handlePrevious = () => {
    setCarousel(carousel === 0 ? data.length - 1 : carousel - 1);
  };

  const handleImageClick = () => {
    window.location.href =
      "https://testportal.ssccglpinnacle.com/individualexamtestpassbuy/121";
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 5000);
    return () => clearInterval(interval);
  }, [carousel]);

  return (
    <div className={classes.carousel_div}>
      {data.map((img, i) => (
        <div
          className={`${classes.carousel_img} ${
            carousel === i ? classes.block : classes.hidden
          }`}
          key={i}
        >
          <img
            src={img}
            alt={`carousel ${i}`}
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
            // style={{ width: "98%", height: "40vh" }}
          />
          <div className={classes.carousel_btn}>
            <button onClick={handlePrevious}>
              <GoDotFill />
            </button>
            <button onClick={handleNext}>
              <GoDotFill />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Carousel;
