import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import classes from "./Transactions.module.css";
import Cookies from "universal-cookie";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Page index for ReactPaginate (starts from 0)
  const [totalPages, setTotalPages] = useState(0);
  const [tokenError, setTokenError] = useState(false); // State to track token error
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page (entries shown)

  const cookies = new Cookies();
  const token = cookies.get("token");

  // Log the token to check if it exists
  console.log("Token:", token);

  // Fetch transactions data from the backend API
  const fetchTransactions = async (page, search = "") => {
    if (!token) {
      setTokenError(true); // Set token error if missing
      console.error("Authorization token is missing");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/transactions`,
        {
          params: { page: page + 1, limit: rowsPerPage, search },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTransactions(response.data.transactions);
      setTotalPages(response.data.totalPages);
      setTokenError(false); // Reset token error if request succeeds
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setTokenError(true); // Set token error for 401 response
        console.error(
          "Unauthorized: Check the token validity or server configuration"
        );
      } else {
        console.error("Error fetching transactions:", error);
      }
    }
  };

  useEffect(() => {
    fetchTransactions(currentPage, searchTerm); // Fetch data on initial load and when currentPage or searchTerm changes
  }, [currentPage, searchTerm, rowsPerPage]); // Add rowsPerPage to dependencies to refetch data when it changes

  // Handle page change
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Trigger search on button click
  const handleSearchClick = () => {
    setCurrentPage(0); // Reset to the first page when a new search is initiated
    fetchTransactions(0, searchTerm);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value)); // Update rowsPerPage
    setCurrentPage(0); // Reset to the first page
  };

  return (
    <div className={classes.transaction_container}>
      <h1>Transactions Report</h1>
      {tokenError && (
        <p className={classes.error}>
          Authorization token is missing or invalid. Please log in again.
        </p>
      )}
      <div className={classes.controlContainer}>
        <div className={classes.searchContainer}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className={classes.searchInput}
          />
          <button onClick={handleSearchClick} className={classes.searchButton}>
            Search
          </button>
        </div>
        <div className={classes.entriesControl}>
          <label>Show Entries</label>
          <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            {/* <option value={20}>20</option> */}
            {/* <option value={50}>50</option> */}
            <option value={100}>100</option>
            {/* <option value={500}>500</option> */}
          </select>
        </div>
      </div>
      <table className={classes.transaction_table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Email ID</th>
            <th>Mobile No.</th>
            <th>Product Name</th>
            <th>Amount</th>
            <th>Payment Mode</th>
            <th>Status</th>
            <th className={classes.dateColumn}>Date & Time</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.id}</td>
              <td>
                {transaction.student ? transaction.student.full_name : "N/A"}
              </td>
              <td>{transaction.email_id}</td>
              <td>
                {transaction.student
                  ? transaction.student.mobile_number
                  : "N/A"}
              </td>
              <td>{transaction.product_name}</td>
              <td>{transaction.pay_amount || "N/A"}</td>
              <td>{transaction.payment_mode || "N/A"}</td>
              <td>{transaction.message || "N/A"}</td>
              {/* <td>
                {transaction.ts
                  ? new Date(transaction.ts).toLocaleString()
                  : "N/A"}
              </td> */}
              <td>
                {transaction.ts
                  ? new Date(transaction.ts).toLocaleDateString("en-CA") + // "en-CA" gives date in "YYYY-MM-DD" format
                    " " +
                    new Date(transaction.ts).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: true,
                    })
                  : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={classes.pagination}
        activeClassName={classes.active}
      />
    </div>
  );
};

export default Transactions;
