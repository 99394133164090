import "./Attempted.css";
import "./mobilestyle.css";
import logo from "../images/Bubble-Preloader.gif";
import { Row, Col, Container } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import {
  FaPercent,
  FaFileAlt,
  FaRegStar,
  FaUserCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { BsTrophy } from "react-icons/bs";
import { SiApplepodcasts } from "react-icons/si";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useCookies } from "react-cookie";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import CompareTable from "./CompareTable";

import { useAuthContext } from "../hooks/useAuthContext";
import { addProfile } from "../redux/slices/userProfileSlice";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const Attempted = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [totalMark, setTotalmarks] = useState();
  const [label, setLabel] = useState([]);
  const [graphdata, setGraphData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [cutoff, setCutoff] = useState();
  const {
    exam_mode_id,
    paper_code,
    test_series_id,
    title,
    tier_id,
    exam_id,
    post_id,
    product_id,
  } = useParams();
  const exam_mode_ids = atob(exam_mode_id);
  const papercode = atob(paper_code);
  const testseriesid = atob(test_series_id);
  const testtitle = title;
  const [SubjectID, setSubjectID] = useState();
  const [userScoreGraphIndex, setUserScoreGraphIndex] = useState(-1);
  const [AverageScoreGraphIndex, setAverageScoreGraphIndex] = useState(-1);
  const [medianScoreGraphIndex, setMedianScoreGraphIndex] = useState(-1);
  const [rating, setRating] = useState(null);

  const userProfile = useSelector((state) => state.userProfile);
  const { token } = useAuthContext();

  useEffect(() => {
    // Push the current state into the history stack
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      // Whenever the back or forward button is pressed, we push the state again
      window.history.pushState(null, document.title, window.location.href);
    };

    // Listen for popstate event, which occurs when the back or forward buttons are clicked
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Cleanup event listener when the component unmounts
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  // console.log("token: " , token);
  // console.log("userProfile: " , userProfile);

  const ratingChanged = async (newRating) => {
    let rating_data = {
      paper_code: paper_code,
      // email_id: "neerajit@ssccglpinnacle.com",
      // email_id: "anshulji100@gmail.com",
      email_id: userProfile.email_id,
      exam_mode_id: exam_mode_ids,
      test_series_id: testseriesid,
      rating: newRating,
    };
    const response = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/rating",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(rating_data),
      }
    );
    setRating(newRating);

    const responseData = await response.json();
    console.log(responseData);
  };

  // const ratingChanged = async (newRating) => {
  //   // let rating_data = { 'paper_code': papercode, 'email_id': 'neerajit@ssccglpinnacle.com', 'exam_mode_id': exam_mode_ids, 'test_series_id': testseriesid, 'rating': newRating }
  //   let rating_data = {
  //     paper_code: papercode,
  //     email_id: "anshulji100@gmail.com",
  //     exam_mode_id: exam_mode_ids,
  //     test_series_id: testseriesid,
  //     rating: newRating,
  //   };
  //   await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/rating", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(rating_data),
  //   });
  // };

  function findIndexInRange(number, ranges) {
    let index = -1;
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i].split("-");
      const lowerBound = parseFloat(range[0]);
      const upperBound = parseFloat(range[1]);
      if (number >= lowerBound && number <= upperBound) {
        index = i;
        break;
      }
    }
    return index;
  }

  useEffect(() => {
    async function paperDetails() {
      // let paper_details = { 'paper_code': papercode, 'email_id': 'neerajit@ssccglpinnacle.com', 'exam_mode_id': exam_mode_ids, 'test_series_id': testseriesid }
      let paper_details = {
        paper_code: papercode,
        // email_id: "anshulji100@gmail.com",
        email_id: userProfile.email_id,
        exam_mode_id: exam_mode_ids,
        test_series_id: testseriesid,
      };
      console.log("USE_EFFECT PAPER_DETAILS", paper_details);
      try {
        let answers_data = await fetch(
          process.env.REACT_APP_PINNACLE_BASE_URL + "/attempted",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(paper_details),
          }
        );
        let answers_result = await answers_data.json();

        if (answers_data.ok) {
          console.log("USE_EFFECT ANSWERS_RESULT", answers_result);

          setData(answers_result);
          setTotalmarks(answers_result[0]["TotalMarks"]);
          setCutoff(answers_result[0].obc);
          setSubjectID(answers_result[0].Subjects[0]["subject_id"]);

          setLabel(answers_result[0]["graph"][0]["label"]);
          setGraphData(answers_result[0]["graph"][0]["data"]);
          setRating(answers_result[0]["ratingData"]?.rating);

          setLoading(false);

          const ranges = answers_result[0]["graph"][0]["label"];
          // console.log("RANGE",ranges);
          const number = answers_result[0].Score;
          // console.log("number", number);
          const averageMarks = answers_result[0].averageMarks;
          const medianMarks = answers_result[0].medianTotalMarks;

          // let index = -1;

          // for (let i = 0; i < ranges.length; i++) {
          //     const range = ranges[i].split('-');
          //     const lowerBound = parseFloat(range[0]);
          //     const upperBound = parseFloat(range[1]);

          //     if (number >= lowerBound && number <= upperBound) {
          //         index = i;
          //         break;
          //     }
          // }

          // console.log("Index:", index);
          // setUserScoreGraphIndex(index);
          setUserScoreGraphIndex(findIndexInRange(number, ranges));
          setAverageScoreGraphIndex(findIndexInRange(averageMarks, ranges));
          setMedianScoreGraphIndex(findIndexInRange(medianMarks, ranges));
        } else {
          Swal.fire({
            icon: "error",
            title: `Error: ${answers_result.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          throw new Error(answers_result.message);
        }
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Error: ${e}`,
          showConfirmButton: false,
          timer: 1500,
        });
        throw new Error(e);
      }
    }

    paperDetails();
  }, []);

  const marks_distribution = {
    labels: label,
    datasets: [
      {
        label: "Students",
        // fill: false,
        // lineTension: 0.0,
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "#0AD0F4",
        borderWidth: 2,
        data: graphdata,
      },
    ],
  };

  const GOtoSolution = () => {
    // navigate(
    //   `/solution/${btoa(exam_mode_ids)}/${btoa(papercode)}/${btoa(
    //     testseriesid
    //   )}/${testtitle}/${btoa(SubjectID)}/${tier_id}`
    // );
    navigate(
      `/Solution/${btoa(exam_mode_ids)}/${btoa(papercode)}/${btoa(
        testseriesid
      )}/${testtitle}/${btoa(
        SubjectID
      )}/${tier_id}/${exam_id}/${post_id}/${product_id}`
    );
  };

  const CutoffFuction = (e) => {
    setCutoff(e.target.value);
  };

  const GoToTestFun = () => {
    // navigate(`/testseries/${cookies.email_id}`);
    console.log(btoa(testseriesid), typeof btoa(testseriesid));
    console.log(btoa(exam_mode_ids), typeof btoa(exam_mode_ids));

    navigate(
      `/TestSeriesUnlock/${exam_id}/${post_id}/${testseriesid}/${tier_id}/${exam_mode_ids}/${product_id}`
    );
    console.log(exam_id, post_id, test_series_id, tier_id, exam_mode_ids);
    // navigate(
    //   `/TestSeriesUnlock/${exam_id}/${post_id}/${test_series_id}/${tier_id}/${exam_mode_id}`
    // );

    // navigate(`/TestSeriesUnlock/${testseriesid}/${tier_id}/${exam_mode_ids}`);
    // /TestSeriesUnlock/:testid/:exam_post_id/:exam_mode_id
  };

  console.log("/:TierID", tier_id);

  if (data && graphdata && userScoreGraphIndex) {
    console.log("CHECKING DATA SCORE ", data[0].Score);
    console.log("graphdata", graphdata[userScoreGraphIndex]);
  }

  return (
    <>
      <div className="MyContainer">
        <div className="section1 mobile-row">
          <div className="mobile-display-none_one" style={{ padding: "0" }}>
            <img
              src="https://ssccglpinnacle.com/images/pinnacle_logo.png"
              alt="logo"
            />
          </div>
          <div className="test_tittle_div">
            <p className="test-title mobile-fontsize15">{testtitle} </p>
          </div>
          <div className="rating_area_div mobile-display-none_two">
            <p className="rate-title">Rate the Test</p>
            <p className="rate-test">
              {!rating && (
                <ReactStars
                  value={0}
                  count={5}
                  onChange={ratingChanged}
                  size={35}
                  edit={true}
                  activeColor="#ffd700"
                />
              )}
              {rating && (
                <ReactStars
                  value={rating}
                  count={5}
                  onChange={ratingChanged}
                  size={35}
                  edit={true}
                  activeColor="#ffd700"
                />
              )}
            </p>
          </div>
          <div className="mobile-display-show">
            <button className="gtt" onClick={GoToTestFun}>
              Go To Tests
            </button>
            <button className="go-sol" onClick={GOtoSolution}>
              Solution
            </button>
          </div>
        </div>
        <div className="section2">
          <Container className="mobile-container95 mobile-padding0">
            <Row className="mobile-width100-perc mobile-margin0 mobile-row">
              <Col>
                <h5 className="performance-title">
                  Overall Performance Summary
                </h5>
              </Col>
            </Row>
            {isLoading ? (
              <h3 style={{ textAlign: "center" }}>
                <img src={logo} alt="loading..." />
              </h3>
            ) : (
              data.map((item, i) => (
                <Fragment key={i}>
                  <Row className="performance-div mobile-height-auto-important mobile-float-left mobile-width100 mobile-row">
                    <Col
                      //   md={3}
                      className="performance-icon mobile-width50-perc mobile-margin-bottom12 mobile-margin-top12 d-flex align-items-center justify-content-start"
                    >
                      <div className="rank-icon">
                        <FaRegStar />
                      </div>
                      <div className="title-div">
                        <p>
                          {item.Rank} <span>/ {item.TotalStudent}</span>
                        </p>
                        <p className="help-text">Rank</p>
                      </div>
                    </Col>
                    <Col
                      //   md={3}
                      className="performance-icon mobile-width50-perc mobile-margin-bottom12 mobile-margin-top12 d-flex align-items-center justify-content-start"
                    >
                      <div className="trophy-icon">
                        <BsTrophy />
                      </div>
                      <div className="title-div">
                        <p>
                          {item.Score} <span>/ {item.TotalMarks}</span>
                        </p>
                        <p className="help-text">Score</p>
                      </div>
                    </Col>
                    <Col
                      //   md={2}
                      className="performance-icon mobile-width50-perc mobile-margin-bottom12 mobile-margin-top12 d-flex align-items-center justify-content-start"
                    >
                      <div className="attempted-icon">
                        <FaFileAlt />
                      </div>
                      <div className="title-div">
                        <p>
                          {item.Attempted} <span>/ {item.TotalQuestions}</span>
                        </p>
                        <p className="help-text">Attempted</p>
                      </div>
                    </Col>
                    <Col
                      //   md={2}
                      className="performance-icon mobile-width50-perc mobile-margin-bottom12 mobile-margin-top12 d-flex align-items-center justify-content-start"
                    >
                      <div className="accuracy-icon">
                        <SiApplepodcasts />
                      </div>
                      <div className="title-div">
                        <p>
                          {item.Accuracy} <span> %</span>
                        </p>
                        <p className="help-text">Accuracy</p>
                      </div>
                    </Col>
                    <Col
                      //   md={2}
                      className="performance-icon mobile-width50-perc mobile-margin-bottom12 mobile-margin-top12 d-flex align-items-center justify-content-start"
                    >
                      <div className="percentile-icon">
                        <FaPercent />
                      </div>
                      <div className="title-div">
                        <p>
                          {item.Percentile} <span> %</span>
                        </p>
                        <p className="help-text">Percentile</p>
                      </div>
                    </Col>
                  </Row>

                  <Row className="Sectional-Summary mobile-width100 mobile-row">
                    <Col md={3}>
                      <h5 className="Summary-name">Sectional Summary</h5>
                    </Col>
                    {item.cutoff_status == 0 ? (
                      ""
                    ) : (
                      <Col md={9} className="cutoff-section">
                        <h6>Estimated cutoffs :</h6>
                        <select className="cutoff" onChange={CutoffFuction}>
                          <option value={item.obc}>OBC</option>
                          <option value={item.ews}>EWS</option>
                          <option value={item.sc}>SC</option>
                          <option value={item.st}>ST</option>
                          <option value={item.esm}>ESM</option>
                          <option value={item.ur}>UR</option>
                          <option value={item.oh}>OH</option>
                          <option value={item.vh}>HH</option>
                          <option value={item.vh}>VH</option>
                          <option value={item.Others_PWD}>Others-PWD </option>
                        </select>
                      </Col>
                    )}
                  </Row>

                  <Row className="tb-card mobile-width100 overflow-x-scroll mobile-row">
                    <table className="table-grid" id="table-id">
                      <thead>
                        <tr>
                          <th scope="col">
                            <span className="lt-blue">
                              Section Name<span></span>
                            </span>
                          </th>
                          <th scope="col">
                            <span className="lt-blue">Score</span>
                          </th>
                          <th scope="col">
                            <span className="lt-blue">Attempted</span>
                          </th>
                          <th scope="col">
                            <span className="lt-blue">Accuracy</span>
                          </th>
                          <th scope="col">
                            <span className="lt-blue">Time</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.Subjects.map((sb, j) => (
                          <>
                            <tr key={j}>
                              <td data-label="Section Name">
                                <span className="txt-bold">
                                  {sb.SubjectName}
                                </span>
                              </td>

                              <td data-label="Score" className="score">
                                <div
                                  className="indicator_progress marks_indicator_progress"
                                  style={{ width: sb.score_percentage + "%" }}
                                ></div>
                                <span className="txt-bold">{sb.SubScore}</span>
                                <span className="lt-blue">
                                  {" "}
                                  / {sb.SubTotalMarks}
                                </span>
                              </td>

                              <td data-label="Attempted" className="attempted">
                                <div
                                  className="indicator_progress attempted_indicator_progress"
                                  style={{
                                    width: sb.attempted_percentage + "%",
                                  }}
                                ></div>
                                <span className="txt-bold">
                                  {sb.SubAttempted}
                                </span>
                                <span className="lt-blue">
                                  {" "}
                                  / {sb.SubTotalQuestion}
                                </span>
                              </td>

                              <td data-label="Accuracy" className="accuracy">
                                <div
                                  className="indicator_progress accuracy_indicator_progress"
                                  style={{ width: sb.SubAccuracy + "%" }}
                                ></div>
                                <span className="txt-bold">
                                  {sb.SubAccuracy} %
                                </span>
                              </td>

                              <td data-label="Time" className="time">
                                <div
                                  className="indicator_progress time_indicator_progress"
                                  style={{ width: sb.time_percentage + "%" }}
                                ></div>
                                <span className="txt-bold">
                                  {sb.SubTakingTime}
                                </span>
                                <span className="lt-blue">
                                  {" "}
                                  / {sb.SubTotalTime} min
                                </span>
                              </td>
                            </tr>
                          </>
                        ))}
                        <tr>
                          <td scope="row" data-label="Section Name">
                            <h6>Overall</h6>
                          </td>

                          <td data-label="Score" className="score">
                            <div
                              className="indicator_progress marks_indicator_progress"
                              style={{ width: item.your_score_perc + "%" }}
                            ></div>
                            <h6 style={{ margin: "0px" }}>
                              {item.Score}
                              <span className="lt-blue">
                                {" "}
                                /{item.TotalMarks}
                              </span>
                            </h6>
                            {item.cutoff_status == 0 ? (
                              ""
                            ) : (
                              <h6 style={{ fontSize: "12px", color: "red" }}>
                                <FaExclamationCircle /> {cutoff} cut-off
                              </h6>
                            )}{" "}
                            <span
                              className="lt-blue"
                              style={{ color: "#e4174f" }}
                            >
                              <i
                                className="fas fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </td>

                          <td data-label="Attempted" className="attempted">
                            <div
                              className="indicator_progress attempted_indicator_progress"
                              style={{ width: item.y_total_attempted + "%" }}
                            ></div>
                            <h6>
                              {item.Attempted}
                              <span className="lt-blue">
                                {" "}
                                /{item.TotalQuestions}
                              </span>
                            </h6>
                          </td>

                          <td data-label="Accuracy" className="accuracy">
                            <div
                              className="indicator_progress accuracy_indicator_progress"
                              style={{ width: item.Accuracy + "%" }}
                            ></div>
                            <h6>{item.Accuracy} %</h6>
                          </td>
                          <td data-label="Time" className="time">
                            <div
                              className="indicator_progress time_indicator_progress"
                              style={{ width: item.your_time_perc + "%" }}
                            ></div>
                            <h6>
                              {item.TotalTimeTaken}
                              <span className="lt-blue">
                                {" "}
                                / {item.Totaltime} min
                              </span>
                            </h6>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>

                  <Row className="compare-topper-div mobile-width100 mobile-margin0 mobile-row">
                    <Col md={9} className="mobile-padding0">
                      <Row className="mobile-row">
                        <Col>
                          <h5 className="compare-title">Compare with Topper</h5>
                        </Col>
                      </Row>

                      <CompareTable data={item.transformedData} />

                      <Row className="mobile-row">
                        <Col>
                          <h5 className="mark-distribution-title">
                            Marks Distribution
                          </h5>
                        </Col>
                      </Row>
                      <Row className="mark-distribution mobile-row">
                        <Col className="mobile-width100 mobile-padding-right0">
                          {/*
                                                    <Line
                                                        data={marks_distribution}
                                                        options={{
                                                            title: {
                                                                display: true,
                                                                text: 'Marks Distribution',
                                                                fontSize: 20
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position: 'right'
                                                            },
                                                        }}
                                                        plugins= {{
                                                                annotation: {
                                                                  annotations: {
                                                                    pointAnnotation: {
                                                                      type: 'label',
                                                                      xValue: 12,  // Specify the x-axis value where "You are here" should appear
                                                                      yValue: 80, // Specify the y-axis value, adjust according to your data
                                                                      content: 'You are here: 123',
                                                                      backgroundColor: 'rgba(255, 99, 132, 0.25)',
                                                                      borderRadius: 4,
                                                                      color: 'blue',
                                                                      font: {
                                                                        weight: 'bold'
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }}
                                                    />
                                                     */}
                          <Line
                            data={marks_distribution}
                            options={{
                              responsive: true,
                              scales: {
                                x: {
                                  title: {
                                    display: true,
                                    text: "Marks",
                                  },
                                },
                                y: {
                                  title: {
                                    display: true,
                                    text: "No. of Students",
                                  },
                                  min: 0, // Optional: You can set a minimum value if needed
                                  // max: Math.max(...graphdata) * 1.1, // Increase the maximum value to add space above the highest point
                                  afterBuildTicks: (scale) => {
                                    // Calculate the difference between two consecutive ticks
                                    const ticks = scale.ticks;
                                    if (ticks.length > 1) {
                                      const tickSpacing =
                                        ticks[1].value - ticks[0].value;
                                      // Add the tickSpacing to the maximum value
                                      scale.max =
                                        Math.max(...graphdata) + tickSpacing;
                                    }
                                  },
                                },
                              },

                              plugins: {
                                // title: {
                                //     display: true,
                                //     text: 'Marks Distribution',
                                //     fontSize: 20
                                // },
                                legend: {
                                  display: false,
                                  // position: 'top'
                                },

                                annotation: {
                                  annotations: {
                                    medianMarks: {
                                      type: "label",
                                      content: `Median ${data[0].medianTotalMarks}`,
                                      // scaleID: 'y',
                                      xValue: medianScoreGraphIndex,
                                      yValue:
                                        graphdata[medianScoreGraphIndex] * 1.02,
                                      backgroundColor: "white",
                                      borderRadius: 50,
                                      // borderColor: 'transparent',
                                      borderWidth: 0,
                                      color: "black",
                                      font: {
                                        // weight: 'bold',
                                        size: 11,
                                      },
                                      // position: (context) => {
                                      //   return graphdata[medianScoreGraphIndex] === graphdata[AverageScoreGraphIndex] ? 'end' : 'start';
                                      // }
                                      position: "end",
                                    },
                                    averageMarks: {
                                      type: "label",
                                      content: `Average ${data[0].averageMarks}`,
                                      // scaleID: 'y',
                                      xValue: AverageScoreGraphIndex,
                                      yValue: graphdata[AverageScoreGraphIndex],
                                      backgroundColor: "white",
                                      borderRadius: 50,
                                      // borderColor: 'transparent',
                                      // borderWidth: 20,
                                      color: "black",
                                      font: {
                                        // weight: 'bold',
                                        size: 11,
                                      },
                                      position: (context) => {
                                        return graphdata[
                                          medianScoreGraphIndex
                                        ] === graphdata[AverageScoreGraphIndex]
                                          ? "start"
                                          : "end";
                                      },
                                      // position: "start",
                                    },
                                    pointAnnotation: {
                                      type: "label",
                                      content: `You are here: ${data[0].Score}`,
                                      xValue: userScoreGraphIndex,
                                      // yValue: graphdata[userScoreGraphIndex] * 1.25,
                                      yValue: graphdata[userScoreGraphIndex],
                                      backgroundColor: "white",
                                      borderRadius: 50,
                                      color: "black",
                                      font: {
                                        // weight: 'bold',
                                        size: 11,
                                      },
                                      position: "start",
                                    },
                                  },
                                },
                                // tooltips: {
                                //     callbacks: {
                                //         label: function(tooltipItem, data) {
                                //             console.log("TOOLTIPS DATA",data);
                                //             var label = data.datasets[tooltipItem.datasetIndex].label || 'abc';
                                //             if (label) {
                                //                 label += ': ';
                                //                 console.log(label);
                                //             }
                                //             label += '(' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
                                //             return label;
                                //         }
                                //     }
                                // },
                              },
                            }}
                          />

                          {/*
                          <Line
                            data={marks_distribution}
                            options={{
                              responsive: true,
                              scales: {
                                x: {
                                  title: {
                                    display: true,
                                    text: "Marks",
                                  },
                                },
                                y: {
                                  title: {
                                    display: true,
                                    text: "No. of Students",
                                  },
                                  min: 0,
                                  afterBuildTicks: (scale) => {
                                    const ticks = scale.ticks;
                                    if (ticks.length > 1) {
                                      const tickSpacing =
                                        ticks[1].value - ticks[0].value;
                                      scale.max =
                                        Math.max(...graphdata) + tickSpacing;
                                    }
                                  },
                                },
                              },
                              plugins: {
                                legend: {
                                  display: false,
                                },
                                annotation: {
                                  annotations: {
                                    medianMarks: {
                                      type: "label",
                                      content: `Median ${data[0].medianTotalMarks}`,
                                      xValue: medianScoreGraphIndex,
                                      yValue: graphdata[medianScoreGraphIndex],
                                      backgroundColor: "white",
                                      borderRadius: 50,
                                      color: "black",
                                      font: {
                                        size: 11,
                                      },
                                      position: (context) => {
                                        // Adjust label position above or below the line
                                        if (medianScoreGraphIndex === 0)
                                          return "end"; // start of the graph
                                        if (
                                          medianScoreGraphIndex ===
                                          graphdata.length - 1
                                        )
                                          return "start"; // end of the graph
                                        return "center";
                                      },
                                      adjustScaleRange: true, // Ensures the label stays within the chart bounds
                                      padding: "0px",
                                    },
                                    averageMarks: {
                                      type: "label",
                                      content: `Average ${data[0].averageMarks}`,
                                      xValue: AverageScoreGraphIndex,
                                      yValue: graphdata[AverageScoreGraphIndex],
                                      backgroundColor: "white",
                                      borderRadius: 50,
                                      color: "black",
                                      font: {
                                        size: 11,
                                      },
                                      position: (context) => {
                                        // Adjust label position above or below the line
                                        if (AverageScoreGraphIndex === 0)
                                          return "end"; // start of the graph
                                        if (
                                          AverageScoreGraphIndex ===
                                          graphdata.length - 1
                                        )
                                          return "start"; // end of the graph
                                        return "center";
                                      },
                                      adjustScaleRange: true, // Ensures the label stays within the chart bounds
                                      padding: "0px",
                                    },
                                    pointAnnotation: {
                                      type: "label",
                                      content: `You are here: ${data[0].Score}`,
                                      xValue: userScoreGraphIndex,
                                      yValue: graphdata[userScoreGraphIndex],
                                      backgroundColor: "white",
                                      borderRadius: 50,
                                      color: "black",
                                      font: {
                                        size: 11,
                                      },
                                      position: (context) => {
                                        // Adjust label position above or below the line
                                        if (userScoreGraphIndex === 0)
                                          return "end"; // start of the graph
                                        if (
                                          userScoreGraphIndex ===
                                          graphdata.length - 1
                                        )
                                          return "start"; // end of the graph
                                        return "center"; // center of the graph
                                      },
                                      adjustScaleRange: true, // Ensures the label stays within the chart bounds
                                      padding: "0px",
                                    },
                                  },
                                },
                              },
                            }}
                          />
                          */}
                        </Col>
                      </Row>
                    </Col>

                    <Col md={3} className="mobile-padding0">
                      <Row className="mobile-row">
                        <Col>
                          <h5 className="compare-title">Top Rankers</h5>
                        </Col>
                      </Row>
                      <Row className="ranker-div mobile-width100-perc mobile-overflow-x-scroll mobile-margin0 mobile-row">
                        {item.TopRanker.map((tp, m) => (
                          <>
                            <Col key={m}>
                              <span className="ranking">{m + 1}.</span>
                              <div className="ranker-img">
                                <FaUserCircle />
                              </div>
                              <div className="ranker-marks">
                                <p
                                  className="ranker-help-text"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {" "}
                                  {tp.Name}
                                </p>
                                <p className="om">
                                  {tp.RankerMarks}{" "}
                                  <span className="tm">/ {totalMark}</span>
                                </p>
                              </div>
                            </Col>
                            <hr />
                          </>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Fragment>
              ))
            )}
          </Container>
        </div>
      </div>
    </>
  );
};
export default Attempted;
