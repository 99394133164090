import React from "react";
import classes from "./Footer.module.css";
import savedImage from "../images/vecteezy_icon-save-related-to-button-download-symbol-line-style_.jpg";

const SavedQuestionsCard = () => {
  return (
    <>
      <div className={classes.container}>
        <div className={classes.footer_individual_section}>
          <img
            className={classes.image_sub_section}
            src={savedImage}
            alt="savedimage"
          />
          <h4>save Question & continue </h4>
          <p>
            You can save important question.In solution section you can see your
            saved question and also reattempt question
          </p>
        </div>
      </div>
    </>
  );
};

export default SavedQuestionsCard;
