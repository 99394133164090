import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt, // Dashboard icon
  faUsers, // Users icon
  faShoppingCart, // Products icon
  faBook, // TestSeries icon
  // faFileAlt, // PaperCode icon
  faCaretUp,
  faCaretDown,
  faTimes,
  faCaretRight,
  faExclamationTriangle, // Error Record icon
} from "@fortawesome/free-solid-svg-icons";
// import "./Sidebar.css";
import classes from "./Sidebar.module.css";

const Sidebar = ({ toggleSidebar }) => {
  const handleToggleSidebar = () => {
    toggleSidebar();
  };

  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isTestseriesOpen, setIsTestseriesOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const toggleUsers = () => {
    setIsUsersOpen(!isUsersOpen);
  };

  const toggleProducts = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const toggleTestseries = () => {
    setIsTestseriesOpen(!isTestseriesOpen);
  };

  const toggleReportError = () => {
    setIsReportOpen(!isReportOpen);
  };

  return (
    <div className={classes.sidebar}>
      {/* Add cross button to close sidebar */}
      <button className={classes.closeButton} onClick={handleToggleSidebar}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className={classes.logo}>PINNACLE</div>
      <ul className={classes.nav}>
        <li className={classes.nav_item}>
          <NavLink
            to="/admin/dashboard"
            className={classes.nav_link}
            activeClassName="active"
          >
            <FontAwesomeIcon
              icon={faTachometerAlt}
              className={classes.list_icon}
            />
            Dashboard
          </NavLink>
        </li>
        <li
          className={`${isUsersOpen ? classes.open : ""} ${classes.nav_item}`}
        >
          <div className={classes.nav_link} onClick={toggleUsers}>
            <FontAwesomeIcon icon={faUsers} className={classes.list_icon} />
            Users
            <FontAwesomeIcon
              icon={isUsersOpen ? faCaretUp : faCaretDown}
              className={classes.carrot_icon}
            />
          </div>
          {isUsersOpen && (
            <ul className={classes.sub_nav}>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/users/add"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Add User
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/users/list"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  User List
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/users/students"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Students
                </NavLink>
              </li>

              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/users/transactions"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Transaction
                </NavLink>
              </li>
            </ul>
          )}
        </li>

        <li
          className={`${isProductsOpen ? classes.open : ""} ${
            classes.nav_item
          }`}
        >
          <div className={classes.nav_link} onClick={toggleProducts}>
            <FontAwesomeIcon
              icon={faShoppingCart}
              className={classes.list_icon}
            />
            Products
            <FontAwesomeIcon
              icon={isProductsOpen ? faCaretUp : faCaretDown}
              className={classes.carrot_icon}
            />
          </div>
          {isProductsOpen && (
            <ul className={classes.sub_nav}>
              {/* <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/products/add"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Add Product
                </NavLink>
              </li> */}
              {/* <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/products/list"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Product List
                </NavLink>
              </li> */}
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/products/newproducts"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  New Products
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/products/allproducts"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  All Products
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/products/test_series_product_add"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Add Test Series In Product
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/products/list_test_series_in_product"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  List Test Series In Product
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li
          className={`${isTestseriesOpen ? classes.open : ""} ${
            classes.nav_item
          }`}
        >
          <div className={classes.nav_link} onClick={toggleTestseries}>
            <FontAwesomeIcon icon={faBook} className={classes.list_icon} />
            <span>TestSeries</span>
            <FontAwesomeIcon
              icon={isTestseriesOpen ? faCaretUp : faCaretDown}
              // icon={isTestseriesOpen ? faCaretDown : faCaretRight }
              className={`${classes.carrot_icon} fa fa-caret-up`}
            />
          </div>
          {isTestseriesOpen && (
            <ul className={classes.sub_nav}>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/papercode/createpapercode"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Create Paper Details
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/papercode/add_test_series"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Create Test Series
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/papercode/list_test_series"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  List Test Series
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/papercode/add"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Upload Paper
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/papercode/uploadpapertiertwo"
                  className={classes.sub_nav_link}
                >
                  Upload Paper Tier 2
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/addpaperdetails/cgltier2"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Create Paper Details Tier 2
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/papercode/list"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Upload Paper List
                </NavLink>
              </li>
              {/* <li className="{classes.sub_nav_item}">
                <NavLink
                  to="/admin/testseries/add"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Add TestSeries
                </NavLink>
              </li> */}
              {/* <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/testseries/list"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  TestSeries List
                </NavLink>
              </li> */}
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/testseries/addpaper"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Add Paper In Test Series
                </NavLink>
              </li>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/testseries/udatestartdate"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Update Start Date
                </NavLink>
              </li>
            </ul>
          )}
        </li>

        {/* ***********Test Series Records ************** */}

        <li
          className={`${isUsersOpen ? classes.open : ""} ${classes.nav_item}`}
        >
          <div className={classes.nav_link} onClick={toggleReportError}>
            <FontAwesomeIcon icon={faExclamationTriangle} className={classes.list_icon} />
            <span>Test Series Record</span>
            <FontAwesomeIcon
              icon={isReportOpen ? faCaretUp : faCaretDown}
              className={`${classes.carrot_icon} fa fa-caret-up`}
            />
          </div>
          {isReportOpen && (
            <ul className={classes.sub_nav}>
              <li className={classes.sub_nav_item}>
                <NavLink
                  to="/admin/testrecord/errorrecord"
                  className={classes.sub_nav_link}
                  activeClassName="active"
                >
                  Error record
                </NavLink>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
