// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateProduct_backdrop__xGumj {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(105, 105, 105, 0.75);
}

.UpdateProduct_updateOverlayModel__H9D9B {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 5vh;
  background-color: #fff;
  left: 10%;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  gap: 15px;
  height: 90vh;
  width: 80%;
  overflow-y: auto;
}

.UpdateProduct_user_input_section__DAROp {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/UpdateProduct.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,QAAQ;EACR,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,uCAAuC;EACvC,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,YAAY;EACZ,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(105, 105, 105, 0.75);\n}\n\n.updateOverlayModel {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  top: 5vh;\n  background-color: #fff;\n  left: 10%;\n  z-index: 100;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  padding: 8px;\n  gap: 15px;\n  height: 90vh;\n  width: 80%;\n  overflow-y: auto;\n}\n\n.user_input_section {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `UpdateProduct_backdrop__xGumj`,
	"updateOverlayModel": `UpdateProduct_updateOverlayModel__H9D9B`,
	"user_input_section": `UpdateProduct_user_input_section__DAROp`
};
export default ___CSS_LOADER_EXPORT___;
