// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditQuestion_editorContainer__HFCoI {
    /* position: relative; */
    z-index: 1;
}

.EditQuestion_wrs_editor__19MRU .EditQuestion_wrs_tickContainer__jrLxl {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/EditQuestion.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,UAAU;AACd;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".editorContainer {\n    /* position: relative; */\n    z-index: 1;\n}\n\n.wrs_editor .wrs_tickContainer {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorContainer": `EditQuestion_editorContainer__HFCoI`,
	"wrs_editor": `EditQuestion_wrs_editor__19MRU`,
	"wrs_tickContainer": `EditQuestion_wrs_tickContainer__jrLxl`
};
export default ___CSS_LOADER_EXPORT___;
