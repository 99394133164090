import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import classes from "./AddPaper.module.css";
import AddTestSeriesPackage from "./AddTestSeriesPackage";
import ShowTest from "./ShowTest";
import Cookies from "universal-cookie";

function AddPaper() {
  const [activeAddTestId, setActiveAddTestId] = useState(null);
  const [activeShowTestId, setActiveShowTestId] = useState(null);
  const [fetchData, setFetchData] = useState([]);
  const [testSeriesNameModalData, setTestSeriesNameModalData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showTestData, setShowTestData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [postTierID, setPostTierID] = useState(null);

  const cookies = new Cookies();

  const token = cookies.get("token");
  const showAddTestOverlay = (id, testSeriesName, post_tier_id) => {
    setActiveAddTestId(id);
    setTestSeriesNameModalData(testSeriesName);
    setPostTierID(post_tier_id);
    document.body.style.overflow = "hidden";
  };

  const hideAddTestOverlay = () => {
    setActiveAddTestId(null);
    document.body.style.overflow = "visible";
  };

  const showShowTestOverlay = async (id) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/admin/getpapercodesoftestseries",
        { test_series_id: id },
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      setShowTestData(response.data);
      console.log("SHOW TEST DATA: ", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
    setActiveShowTestId(id);
    document.body.style.overflow = "hidden";
  };

  const hideShowTestOverlay = () => {
    setActiveShowTestId(null);
    document.body.style.overflow = "visible";
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/gettestseriesname",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        setFetchData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredData = searchQuery
    ? fetchData.filter((data) =>
        data.test_series_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : fetchData;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = fetchData.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className={classes.form}>
      <h1 className={classes.add_paper_h1}>ADD the Paper in Test Series</h1>
      <input
        type="text"
        placeholder="Search Test Series"
        className={classes.searchInput}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <table className={classes.testTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Test Series Name</th>
            <th>Category</th>
            <th>Price</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {currentItems.map((data, index) => (
            <tr key={index}>
              <td>{data.id}</td>
              <td>{data.test_series_name}</td>
              <td>
                {data.category_id === 1
                  ? "SSC"
                  : data.category_id === 2
                  ? "Delhi Police"
                  : "Railway"}
              </td>
              <td>{data.price ? data.price : "-----"}</td>
              <td>{data.ts ? data.ts : data.createdAt}</td>
              <td className={classes.add_show_td}>
                <button
                  style={{
                    padding: "5px",
                    width: "100px",
                    height: "40px",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    showAddTestOverlay(
                      data.id,
                      data.test_series_name,
                      data.post_tier_id
                    )
                  }
                >
                  + Add Test
                </button>

                {activeAddTestId === data.id &&
                  ReactDOM.createPortal(
                    <AddTestSeriesPackage
                      id={data.id}
                      test_series_name={data.test_series_name}
                      post_tier_id={data.post_tier_id}
                      onClose={hideAddTestOverlay}
                    />,
                    document.getElementById("overlay-root")
                  )}

                <button
                  style={{
                    padding: "5px",
                    width: "100px",
                    height: "40px",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                  onClick={() => showShowTestOverlay(data.id)}
                >
                  Show Test
                </button>

                {activeShowTestId === data.id &&
                  ReactDOM.createPortal(
                    <ShowTest
                      id={showTestData}
                      test_series_name={data.test_series_name}
                      post_tier_id={data.post_tier_id}
                      onClose={hideShowTestOverlay}
                    />,
                    document.getElementById("overlay-root")
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={classes.pagination}>
        <ul>
          {Array.from(
            { length: Math.ceil(fetchData.length / itemsPerPage) },
            (_, i) => (
              <li
                key={i}
                onClick={() => paginate(i + 1)}
                className={`${classes.paginationItem} ${
                  currentPage === i + 1 ? classes.active : ""
                }`}
              >
                {i + 1}
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
}

export default AddPaper;

// import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";
// import axios from "axios";
// import ShowTest from "./ShowTest";
// import AddTestSeriesPackage from "./AddTestSeriesPackage";
// import classes from "./AddPaper.module.css";

// function AddPaper() {
//   const [addTestOverlayVisible, setAddTestOverlayVisible] = useState(false);
//   const [showTestOverlayVisible, setShowTestOverlayVisible] = useState(false);
//   const [fetchData, setFetchData] = useState([]);
//   const [ test_series_name_modal_data, set_test_series_name_modal_data] = useState("");
//   const [idModalData, setIdModalData] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(10);
//   const [showTestData, setShowTestData] = useState([]);

//   const showAddTestOverlay = (id, test_series_name) => {
//     setAddTestOverlayVisible(id);
//     // setIdModalData(id);
//     set_test_series_name_modal_data(test_series_name);
//     document.body.style.overflow = "hidden";
//   };

//   const hideAddTestOverlay = () => {
//     setAddTestOverlayVisible(false);
//     document.body.style.overflow = "visible";
//   };

//   const showShowTestOverlay = async (id) => {
//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/getpapercodesoftestseries",
//         {
//           test_series_id: id,
//         }
//       );
//       // console.log(response.data);
//       setShowTestData(response.data);
//     } catch (error) {
//       console.error("Error posting data:", error);
//     }
//     // let array = [];
//     // array = showTestData;
//     // console.log(array);

//     setShowTestOverlayVisible(id);
//     document.body.style.overflow = "hidden";
//     // setIdModalData(array);
//     // console.log(idModalData);
//   };

//   const hideShowTestOverlay = () => {
//     setShowTestOverlayVisible(false);
//     document.body.style.overflow = "visible";
//   };

//   useEffect(() => {
//     // Cleanup the body overflow when the component unmounts
//     return () => {
//       document.body.style.overflow = "visible";
//     };
//   }, []);

//   useEffect(() => {
//     // Fetch data from an API

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           process.env.REACT_APP_PINNACLE_BASE_URL + "/gettestseriesname"
//         );
//         // setId(response.data[1].count_id + 1);
//         setFetchData(response.data[0]);
//         // console.log("Response from backend:", response.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = fetchData.slice(indexOfFirstItem, indexOfLastItem);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   return (
//     <div className={classes.form}>
//       <h1 className={classes.add_paper_h1}>ADD the Paper in Test Series</h1>
//       <table className={classes.testTable}>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Test Series Name</th>
//             <th>Category</th>
//             <th>Price</th>
//             <th>Date</th>
//             <th>Action</th>
//           </tr>
//         </thead>

//         <tbody>
//           {currentItems.map((data, index) => (
//             <tr key={index}>
//               <td>{data.id}</td>
//               <td>{data.test_series_name}</td>
//               <td>
//                 {data.category_id === 1
//                   ? "SSC"
//                   : data.category_id === 2
//                   ? "Delhi Police"
//                   : "Railway"}
//               </td>
//               <td>{data.price ? data.price : "-----"}</td>
//               <td>{data.ts ? data.ts : data.createdAt}</td>
//               <td colSpan="5" className={classes.add_show_td} >
//                 <button
//                   style={{
//                     padding: "5px",
//                     width: "100px",
//                     height: "40px",
//                     textAlign: "center",
//                     alignItems: "center",
//                   }}
//                   onClick={() =>
//                     showAddTestOverlay(data.id, data.test_series_name)
//                   }
//                 >
//                   + Add Test
//                 </button>

//                 {addTestOverlayVisible &&
//                   ReactDOM.createPortal(
//                     <AddTestSeriesPackage
//                       id={idModalData}
//                       test_series_name={test_series_name_modal_data}
//                       onClose={hideAddTestOverlay}
//                     />,
//                     document.getElementById("add-testpaper")
//                   )}
//                 <button
//                   style={{
//                     padding: "5px",
//                     width: "100px",
//                     height: "40px",
//                     textAlign: "center",
//                     alignItems: "center",
//                   }}
//                   onClick={() => showShowTestOverlay(data.id)}
//                 >
//                   Show Test
//                 </button>
//                 {showTestOverlayVisible &&
//                   ReactDOM.createPortal(
//                     <ShowTest
//                       id={showTestData}
//                       onClose={hideShowTestOverlay}
//                     />,
//                     document.getElementById("show-testpaper")
//                   )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className={classes.pagination}>
//         <ul>
//           {Array.from(
//             {
//               length: Math.ceil(fetchData.length / itemsPerPage),
//             },
//             (_, i) => (
//               <li
//                 key={i}
//                 onClick={() => paginate(i + 1)}
//                 className={`${classes.paginationItem} ${
//                   currentPage === i + 1 ? classes.active : ""
//                 }`}
//               >
//                 {i + 1}
//               </li>
//             )
//           )}
//         </ul>
//       </div>
//     </div>
//   );
// }

// export default AddPaper;
