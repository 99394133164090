import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tinymce from "tinymce";
import { Editor } from "@tinymce/tinymce-react";
import "tinymce/tinymce";
import classes from "./CreateTestSeries.module.css";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

const CreateTestSeries = () => {
  const [productName, setProductName] = useState("");
  const [testSeriesName, setTestSeriesName] = useState("");
  const [price, setPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [testSeriesStartDate, setTestSeriesStartDate] = useState("");
  const [paperStartTime, setPaperStartTime] = useState("");
  const [testSeriesEndDate, settestSeriesEndDate] = useState("");
  const [paperEndTime, setPaperEndTime] = useState("");
  const [id, setId] = useState();
  const [content, setContent] = useState("");
  const [existingNames, setExistingNames] = useState([]);

  const navigate = useNavigate();
  const cookies = new Cookies();

  const token = cookies.get("token");

    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/gettestseriesname",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        console.log("response Testseries Name: ", response.data);
        setId(response.data[0].id + 1);
        setExistingNames(response.data.map(item => item.test_series_name.toLowerCase()));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !productName.length ||
      !testSeriesName.length ||
      !testSeriesStartDate.length ||
      !paperStartTime.length ||
      !paperEndTime.length ||
      !testSeriesEndDate.length ||
      !content.length
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Input fields must not be empty.",
      });
      return false;
    }

    if (existingNames.includes(testSeriesName.toLowerCase())) {
      Swal.fire({
        icon: "error",
        title: "Duplicate Test Series Name",
        text: "A test series with this name already exists. Please choose a different name.",
      });
      return;
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0];
    };

    const formatTime = (timeString) => {
      const [hours, minutes] = timeString.split(":");
      return `${hours}:${minutes}`;
    };

    const formattedTestSeriesStartDate = formatDate(testSeriesStartDate);
    const formattedTestSeriesEndDate = formatDate(testSeriesEndDate);
    const formattedPaperStartTime = formatTime(paperStartTime);
    const formattedPaperEndTime = formatTime(paperEndTime);

    const formData = {
      id: id,
      category_id: productName,
      post_tier_id: 0,
      test_type_id: 0,
      test_series_name: testSeriesName,
      price: price,
      max_price: maxPrice,
      test_series_start_date: formattedTestSeriesStartDate,
      test_series_start_time: formattedPaperStartTime,
      test_series_end_date: formattedTestSeriesEndDate,
      test_series_end_time: formattedPaperEndTime,
      content: content,
      orderList: 0,
      status: 1,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/admin/addtestseriesname",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Test Series Inserted Successfully",
        });
      }
      setProductName("");
      setTestSeriesName("");
      setPrice("");
      setMaxPrice("");
      setTestSeriesStartDate("");
      setPaperStartTime("");
      settestSeriesEndDate("");
      setPaperEndTime("");
      setContent("");
      // Refetch data to update the ID for the next entry
      fetchData();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error submitting data",
      });
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div>
      <div className={classes.test_series_description}>
        <h1 className={classes.create_testseries_h1}>Add Test Series Name</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.test_series_produt}>
          <label>
            Product Name
            <select
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            >
              <option value="" disabled hidden>
                Select Anyone{" "}
              </option>
              <option value="1">ssc</option>
              <option value="3">Railway</option>
              <option value="2">Delhi police</option>
              <option value="4">Teaching </option>
              <option value="5">state pcs</option>
              <option value="6">Upsc</option>
              <option value="7">others</option>
              <option value="8">ACIO -IB(2000post)</option>
              <option value="9">up police </option>
              <option value="10">Hssc</option>
            </select>
          </label>
        </div>
        <div className={classes.test_series_produt}>
          <label>
            Test Series Name
            <input
              type="text"
              value={testSeriesName}
              onChange={(e) => setTestSeriesName(e.target.value)}
              required
            ></input>
          </label>
        </div>
        <div className={classes.test_series_produt}>
          <label>
            Price
            <input
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </label>
        </div>
        <div className={classes.test_series_produt}>
          <label>
            Max Price
            <input
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              required
            />
          </label>
        </div>
        <div className={classes.test_series_produt}>
          <label>
            Test Series Start Date:
            <input
              type="date"
              value={testSeriesStartDate}
              onChange={(e) => setTestSeriesStartDate(e.target.value)}
              required
            />
          </label>
        </div>
        <div className={classes.test_series_produt}>
          <label>
            Paper start Time:
            <input
              type="time"
              onChange={(e) => setPaperStartTime(e.target.value)}
              value={paperStartTime}
              required
            ></input>
          </label>
        </div>
        <div className={classes.test_series_produt}>
          <label>
            Test Series End Date :
            <input
              type="date"
              value={testSeriesEndDate}
              onChange={(e) => settestSeriesEndDate(e.target.value)}
              required
            />
          </label>
        </div>
        <div className={classes.test_series_produt}>
          <label>
            Paper End Time:
            <input
              type="time"
              value={paperEndTime}
              onChange={(e) => setPaperEndTime(e.target.value)}
              required
            />
          </label>
        </div>
        <p>Description</p>
        <Editor value={content} onEditorChange={(value) => setContent(value)} />
        <div
          className={`${classes.publsish_button} ${classes.centered_button}`}
        >
          <button
            className={classes.publish_button_type}
            type="submit"
            onClick={handleSubmit}
          >
            PUBLISH
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTestSeries;

// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import tinymce from "tinymce";
// import { Editor } from "@tinymce/tinymce-react";
// import "tinymce/tinymce";
// import classes from "./CreateTestSeries.module.css";
// import axios from "axios";
// import { loadAsync } from "jszip";
// import DatePicker from "react-datepicker";

// // import { NavLink } from "react-router-dom";
// const CreateTestSeries = () => {
//   const [productName, setProductName] = useState("");
//   const [testSeriesName, setTestSeriesName] = useState("");
//   const [price, setPrice] = useState("");
//   const [maxPrice, setMaxPrice] = useState("");
//   const [testSeriesStartDate, setTestSeriesStartDate] = useState("");
//   const [paperStartTime, setPaperStartTime] = useState("");
//   const [testSeriesEndDate, settestSeriesEndDate] = useState("");
//   const [paperEndTime, setPaperEndTime] = useState("");
//   const [id, setId] = useState();
//   const [content, setContent] = useState("");

//   const navigate = useNavigate();
//   const handleProductNameChange = (e) => {
//     setProductName(e.target.value);
//     console.log(productName);
//   };

//   useEffect(() => {
//     // Fetch data from an API

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           process.env.REACT_APP_PINNACLE_BASE_URL + "/gettestseriesname"
//         );
//         console.log("response Testseries Name: ", response.data);
//         setId(response.data[0].id + 1);

//         // console.log("Response from backend:", response.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     console.log("ID", id);

//     fetchData();
//   }, [handleProductNameChange]);

//   const handleTestseriesNameChange = (e) => {
//     setTestSeriesName(e.target.value);
//   };

//   const handlePriceChange = (e) => {
//     setPrice(e.target.value);
//   };

//   const handleMaxPriceChange = (e) => {
//     setMaxPrice(e.target.value);
//   };

//   const handleTestSeriesStartDateChange = (e) => {
//     setTestSeriesStartDate(e.target.value);
//   };

//   const handlePaperStartTimeChange = (e) => {
//     setPaperStartTime(e.target.value);
//   };

//   const handleTestSeriesEndDateChange = (e) => {
//     settestSeriesEndDate(e.target.value);
//     console.log(testSeriesEndDate);
//   };

//   const handlePaperEndTimeChange = (e) => {
//     setPaperEndTime(e.target.value);
//   };

//   const handleEditorChange = (value) => {
//     setContent(value);
//     console.log(content);
//   };

//   // const handleListTestSeriesName = () => {
//   //   navigate("admin/papercode/list_test_series");
//   // };

//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();

//   //   if (
//   //     !productName.length ||
//   //     !testSeriesName.length ||
//   //     !testSeriesStartDate.length ||
//   //     !paperStartTime.length ||
//   //     !paperEndTime.length ||
//   //     !testSeriesName.length ||
//   //     !content.length
//   //   ) {
//   //     alert("Input Field must not be empty.");
//   //     return false;
//   //   }

//   //   console.log(
//   //     `testSeriesStartDate ${testSeriesStartDate},
//   //     testSeriesStartDate ${testSeriesStartDate.toLocaleDateString()},
//   //     paperStartTime ${paperStartTime},
//   //     testSeriesEndDate ${testSeriesEndDate},
//   //     testSeriesEndDate ${testSeriesEndDate.toLocaleDateString()},
//   //     testSeriesEndTime ${paperEndTime}`
//   //   );

//   //   const formData = {
//   //     id: id,
//   //     category_id: productName,
//   //     post_tier_id: 0,
//   //     test_type_id: 0,
//   //     test_series_name: testSeriesName,
//   //     price: price,
//   //     max_price: maxPrice,
//   //     test_series_start_date: testSeriesStartDate,
//   //     test_series_start_time: paperStartTime,
//   //     test_series_end_date: testSeriesEndDate,
//   //     test_series_end_time: paperEndTime,
//   //     content: content,
//   //     orderList: 0,
//   //     status: 1,
//   //   };

//   //   const response = await axios.post(
//   //     process.env.REACT_APP_PINNACLE_BASE_URL + "/addtestseriesname",
//   //     formData,
//   //     {
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //     }
//   //   );
//   //   console.log(response);
//   //   if (response.data) {
//   //     alert("Test Series Inserted Successfully");
//   //   }
//   //   setProductName("");
//   //   setTestSeriesName("");
//   //   setPrice("");
//   //   setMaxPrice("");
//   //   setTestSeriesStartDate("");
//   //   setPaperStartTime("");
//   //   settestSeriesEndDate("");
//   //   setPaperEndTime("");
//   //   setContent("");
//   // };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (
//       !productName.length ||
//       !testSeriesName.length ||
//       !testSeriesStartDate.length ||
//       !paperStartTime.length ||
//       !paperEndTime.length ||
//       !testSeriesEndDate.length ||
//       !content.length
//     ) {
//       alert("Input Field must not be empty.");
//       return false;
//     }

//     const formatDate = (dateString) => {
//       const date = new Date(dateString);
//       return date.toISOString().split('T')[0]; // Extracts the date part (YYYY-MM-DD)
//     };

//     const formatTime = (timeString) => {
//       const [hours, minutes] = timeString.split(':');
//       return `${hours}:${minutes}`; // Keeps only the HH:MM part
//     };

//     const formattedTestSeriesStartDate = formatDate(testSeriesStartDate);
//     const formattedTestSeriesEndDate = formatDate(testSeriesEndDate);
//     const formattedPaperStartTime = formatTime(paperStartTime);
//     const formattedPaperEndTime = formatTime(paperEndTime);

//     console.log(
//       `testSeriesStartDate ${formattedTestSeriesStartDate},
//       paperStartTime ${formattedPaperStartTime},
//       testSeriesEndDate ${formattedTestSeriesEndDate},
//       testSeriesEndTime ${formattedPaperEndTime}`
//     );

//     const formData = {
//       id: id,
//       category_id: productName,
//       post_tier_id: 0,
//       test_type_id: 0,
//       test_series_name: testSeriesName,
//       price: price,
//       max_price: maxPrice,
//       test_series_start_date: formattedTestSeriesStartDate,
//       test_series_start_time: formattedPaperStartTime,
//       test_series_end_date: formattedTestSeriesEndDate,
//       test_series_end_time: formattedPaperEndTime,
//       content: content,
//       orderList: 0,
//       status: 1,
//     };

//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/addtestseriesname",
//         formData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       console.log(response);
//       if (response.data) {
//         alert("Test Series Inserted Successfully");
//       }
//       setProductName("");
//       setTestSeriesName("");
//       setPrice("");
//       setMaxPrice("");
//       setTestSeriesStartDate("");
//       setPaperStartTime("");
//       settestSeriesEndDate("");
//       setPaperEndTime("");
//       setContent("");
//     } catch (error) {
//       console.error("Error submitting data:", error);
//     }
//   };

//   return (
//     <div>
//       <div className={classes.test_series_description}>
//         <h1 className={classes.create_testseries_h1}>Add Test Series Name</h1>

//         {/* <button onClick={handleListTestSeriesName}>List Test SeriesName</button> */}
//       </div>
//       <form>
//         <div className={classes.test_series_produt}>
//           <label>
//             Product Name
//             <select
//               value={productName}
//               onChange={(e) => setProductName(e.target.value)}
//               required
//             >
//               <option value="" disabled hidden>
//                 Select Anyone{" "}
//               </option>
//               <option value="1">ssc</option>
//               <option value="2">Delhi police</option>
//               <option value="3">Railway</option>
//               <option value="4">Teaching </option>
//               <option value="5">state pcs</option>
//               <option value="6">Upsc</option>
//               <option value="7">others</option>
//               <option value="8">ACIO -IB(2000post)</option>
//               <option value="9">up police </option>
//               <option value="10">Hssc</option>
//             </select>
//           </label>
//         </div>
//         <div className={classes.test_series_produt}>
//           <label>
//             Test Series Name
//             <input
//               type="text"
//               value={testSeriesName}
//               onChange={(e) => setTestSeriesName(e.target.value)}
//               required
//             ></input>
//           </label>
//         </div>
//         <div className={classes.test_series_produt}>
//           <label>
//             Price
//             <input
//               // type="number"
//               value={price}
//               onChange={(e) => setPrice(e.target.value)}
//               required
//             />
//           </label>
//         </div>
//         <div className={classes.test_series_produt}>
//           <label>
//             Max Price
//             <input
//               // type="number"
//               value={maxPrice}
//               onChange={(e) => setMaxPrice(e.target.value)}
//               required
//             />
//           </label>
//         </div>
//         <div className={classes.test_series_produt}>
//           <label>
//             Test Series Start Date:
//             <input
//               type="date"
//               value={testSeriesStartDate}
//               onChange={(e) => setTestSeriesStartDate(e.target.value)}
//               required
//             />
//           </label>
//         </div>

//         <div className={classes.test_series_produt}>
//           <label>
//             Paper start Time:
//             <input
//               type="time"
//               onChange={(e) => setPaperStartTime(e.target.value)}
//               value={paperStartTime}
//               required
//             ></input>
//           </label>
//         </div>

//         <div className={classes.test_series_produt}>
//           <label>
//             Test Series End Date :
//             <input
//               type="date"
//               value={testSeriesEndDate}
//               onChange={(e) => settestSeriesEndDate(e.target.value)}
//               required
//             />
//           </label>
//         </div>
//         <div className={classes.test_series_produt}>
//           <label>
//             Paper End Time:
//             <input
//               type="time"
//               value={paperEndTime}
//               onChange={(e) => setPaperEndTime(e.target.value)}
//               required
//             />
//           </label>
//         </div>
//         <p>Description</p>
//         <Editor value={content} onEditorChange={(value) => setContent(value)} />
//         <div
//           className={`${classes.publsish_button} ${classes.centered_button}`}
//         >
//           <button
//             className={classes.publish_button_type}
//             type="submit"
//             onClick={handleSubmit}
//           >
//             PUBLISH
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default CreateTestSeries;
