import React, { useState } from 'react';
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
// import './CreateUserForm.css';
import classes from "./CreateUserForm.module.css";

const CreateUserForm = () => {
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('active');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic to handle form submission here
  };

  return (
    <div className={classes.create_user_form}>
      <h1 className={classes.add_user}>Add User</h1>
      <form onSubmit={handleSubmit}>
        <div className={classes.form_group}>
          <label>Email:</label>
          <input type="email" value={email} onChange={handleEmailChange} required />
        </div>
        <div className={classes.form_group}>
          <label>Mobile Number:</label>
          <input type="tel" pattern="[0-9]{10}" value={mobile} onChange={handleMobileChange} required />
        </div>
        <div className={classes.form_group}>
          <label>Username:</label>
          <input type="text" value={username} onChange={handleUsernameChange} required />
        </div>
        <div className={classes.form_group}>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        {/* <div>
          <Row className={classes.choose_raw}>
            <Col className={classes.choose_type_col}>Choose type :</Col>
            <Col>
              <label className={classes.live_label}>Live</label>
              <input
                className={classes.radio_btn}
                type="radio"
                id="live"
                name="live"
                value="live"
                style={{ float: "left" }}
              />
            </Col>
            <Col>
              <label className={classes.live_label}>Mock</label>
              <input
                className={classes.radio_btn}
                type="radio"
                id="mock"
                name="live"
                value="mock"
              />
            </Col>
          </Row>
        </div> */}
        <div className={classes.crete_btn_position}>
        <button className={classes.creat_btn} type="submit">Create</button>
        </div>
      </form>
    </div>
  );
};

export default CreateUserForm;
