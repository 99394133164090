import React, { useState, useEffect } from "react";
import classes from "./ShowTest.module.css";
import { FaPencilAlt } from "react-icons/fa";
import { RiDeleteBin2Fill } from "react-icons/ri";
import axios from "axios";
import EditTest from "./EditTest.js";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

function ShowTest({ onClose, id, test_series_name }) {
  const [data, setData] = useState([]);
  const [editTest, setEditTest] = useState(null);
  const [editSrNo, setEditSrNo] = useState(null);

  const cookies = new Cookies();

  const token = cookies.get("token");

  useEffect(() => {
    setData(id);
  }, [id]);

  // const handleDeleteTest = async (paper_code, test_series_id) => {
  //   let formData = { test_series_id: test_series_id, paper_code: paper_code };
  //   try {
  //     const response = await axios.post(process.env.REACT_APP_PINNACLE_BASE_URL + "/deletetest", {
  //       formData,
  //     });

  //     if (response.status === 200) {
  //       const updatedData = data.filter(
  //         (test) =>
  //           test.test_series_id !== test_series_id ||
  //           test.paper_code !== paper_code
  //       );

  //       setData(updatedData);
  //     }
  //   } catch (error) {
  //     console.log("Error deleting test:", error);
  //   }
  // };

  const handleTestUpdate = (updatedTest) => {
    setData((prevData) =>
      prevData.map((test) =>
        test._id === updatedTest._id ? updatedTest : test
      )
    );
    setEditTest(null);
    setEditSrNo(null);
  };
  const handleDeleteTest = async (paper_code, test_series_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this test?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let formData = {
          test_series_id: test_series_id,
          paper_code: paper_code,
        };
        try {
          const response = await axios.post(
            process.env.REACT_APP_PINNACLE_BASE_URL +
              "/api/v1/admin/deletetest",
            formData,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // console.log("RESPONSE", response);
          if (response.status === 200) {
            const updatedData = data.filter(
              (test) =>
                test.test_series_id !== test_series_id ||
                test.paper_code !== paper_code
            );

            setData(updatedData);
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "The test has been deleted successfully.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while deleting the test.",
          });
          console.log("Error deleting test:", error);
        }
      }
    });
  };

  // const handleEditTest = (paper_code, test_series_id) => {
  //   // Add your edit functionality here
  //   console.log("Edit Test:", paper_code, test_series_id);
  // };

  const handleEditTest = (test, index) => {
    setEditTest(test);
    setEditSrNo(index + 1);
  };

  const handleCloseEdit = () => {
    setEditTest(null);
    setEditSrNo(null);
  };

  return (
    <div className={classes.overlay}>
      <div className={classes.overlay_content}>
        <button
          onClick={onClose}
          type="button"
          className={classes.close}
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
        <h2>Test Series Name : {test_series_name}</h2>
        <table>
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Order List</th>
              <th>Exam Mode Name</th>
              <th>Paper Code</th>
              <th>Paid Status</th>
              <th>Test Paper Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((showdata, index) => (
              <tr key={showdata._id}>
                {/* <td>{editSrNo !== null ? editSrNo : index + 1}</td> */}
                <td>
                  {editTest && editTest._id === showdata._id
                    ? editSrNo
                    : index + 1}
                </td>
                <td>{showdata.test_order_list}</td>
                <td>
                  {showdata.exam_mode_id === 1
                    ? "Mock full length"
                    : showdata.exam_mode_id === 2
                    ? "Sectional"
                    : showdata.exam_mode_id === 3
                    ? "chapter wise"
                    : showdata.exam_mode_id === 4
                    ? "PYP-Full length"
                    : showdata.exam_mode_id === 5
                    ? "Pyp-Full length(New pattern)"
                    : ""}
                </td>
                <td>{showdata.paper_code}</td>
                <td>
                  {showdata.paid_status === 1
                    ? "Free"
                    : showdata.paid_status === 2
                    ? "PAID"
                    : ""}
                </td>
                <td>
                  {showdata.add_test_type === "1" ? "Practice" : "Ranking"}
                </td>
                <td className={classes.actionbtn}>
                  <button
                    className={classes.editbtn}
                    onClick={() => handleEditTest(showdata, index)}
                  >
                    <FaPencilAlt />
                  </button>
                  <button
                    className={classes.deletebtn}
                    onClick={() =>
                      handleDeleteTest(
                        showdata.paper_code,
                        showdata.test_series_id
                      )
                    }
                  >
                    <RiDeleteBin2Fill />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {editTest && (
          <div className={classes.editOverlay}>
            <EditTest
              test={editTest}
              srNo={editSrNo}
              onClose={handleCloseEdit}
              onUpdate={handleTestUpdate}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ShowTest;
