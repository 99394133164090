import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import classes from "./UpdateTestSeriesOverlayModel.module.css";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import Cookies from "universal-cookie";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onCancel} />;
};

const UpdateTestSeriesOverlayModel = (props) => {
  const [content, setContent] = useState("");
  const [id, setId] = useState(props.id);
  const [examId, setExamId] = useState("");
  const [examModeId, setExamModeId] = useState("");
  const [testSeriesName, setTestSeriesName] = useState(props.test_series_name);
  const [price, setPrice] = useState(props.price);
  const [status, setStatus] = useState(props.status);
  const [postTierId, setPostTierId] = useState("");
  const [fetchedPostTierTable, setFetchedPostTierTable] = useState([]);
  const notifier = [{ post_tier_name: "Select Any Exam First !!!" }];
  let formData = { exam_id: examId };
  const cookies = new Cookies();

  const token = cookies.get("token");

  const examIdClickHandler = (e) => {
    e.preventDefault();
    setExamId(e.target.value);
    // try{
    //   // const response = await axios.get(process.env.REACT_APP_PINNACLE_BASE_URL + "/getposttiertable")
    // } catch(e){}
  };

  useEffect(() => {
    const fetchData = async () => {
      if (examId > 0) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_PINNACLE_BASE_URL +
              "/api/v1/admin/getposttiertable",
            formData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("FETCHED POST TIER TABLE", response.data);
          setFetchedPostTierTable(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setFetchedPostTierTable(notifier);
      }
    };

    fetchData();
  }, [examId]);

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const updateHandler = async () => {
    const formData = {
      id: id,
      category_id: examId,
      post_tier_id: postTierId,
      test_type_id: examModeId,
      test_series_name: testSeriesName,
      price: price,
      status: status,
      content: content,
    };

    try {
      const url = `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/updatetesttiername`;

      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Update successful", response.data);
    } catch (error) {
      console.error(
        "Error updating test series:",
        error.response?.data || error.message
      );
      return null;
    }

    props.onClose();
  };

  return (
    <>
      <div className={classes.modal}>
        <button
          onClick={props.onClose}
          type="button"
          className={classes.close}
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
        <h2> Update test series</h2>
        <div className={classes.type_of_test_series}>
          <label className={classes.subsection_of_overlay_model}>
            TestSeriesName
            <input
              className={classes.subinput}
              value={testSeriesName}
              onChange={(event) => setTestSeriesName(event.target.value)}
            />
          </label>
          <label className={classes.subsection_of_overlay_model}>
            Price
            <input
              value={price}
              className={classes.subinput}
              onChange={(event) => setPrice(event.target.value)}
            />
          </label>
        </div>
        <div className={classes.type_of_test_series}>
          <label className={classes.subsection_of_overlay_model}>
            Exam
            <select className={classes.subinput} onChange={examIdClickHandler}>
              <option value="0">Select Exam</option>
              <option value="1">SSC</option>
              <option value="3">Railway</option>
              <option value="2">Delhi Police</option>
            </select>
          </label>

          <label className={classes.subsection_of_overlay_model}>
            Post
            <select
              className={classes.subinput}
              onChange={(event) => setPostTierId(event.target.value)}
            >
              {fetchedPostTierTable.map((data) => (
                <option key={data.post_tier_id} value={data.post_tier_id}>
                  {data.post_tier_name}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className={classes.type_of_test_series}>
          <label className={classes.subsection_of_overlay_model}>
            Mode
            <select
              className={classes.subinput}
              onChange={(event) => setExamModeId(event.target.value)}
            >
              <option value="0">Select Exam Mode</option>
              <option value="1">Mock test-Full Length</option>
              <option value="2">Sectional Test</option>
              <option value="3">Chapter Test</option>
              <option value="4">PYP-Full Length</option>
              <option value="5">PYP-Full Length New Pattern</option>
            </select>
          </label>
        </div>
        <Editor value={content} onEditorChange={handleEditorChange} />
        <div className={classes.inputSection}>
          <label className={classes.subsection_of_overlay_model}>Status</label>
          <div className={classes.checkboxsection}>
            <input
              className={classes.close_the_radio_button}
              type="radio"
              checked={status === 1}
              onChange={() => setStatus(1)}
            />
            <label className={classes.basicLabel}>Active</label>
          </div>
          <div className={classes.checkboxsection}>
            <input
              className={classes.close_the_radio_button}
              type="radio"
              checked={status === 0}
              onChange={() => setStatus(0)}
            />
            <label className={classes.basicLabel}>Inactive</label>
          </div>
        </div>

        <div className={classes.btns}>
          <button className={classes.can_btn} onClick={props.onCancel}>
            Cancel
          </button>
          <button className={classes.save_btn} onClick={updateHandler}>
            save
          </button>
        </div>

        <div className={classes.section_of_close_button}>
          <button
            className={classes.action_perform_button}
            onClick={props.onClose}
          >
            close
          </button>
        </div>
      </div>
    </>
  );
};

const OverlayModal = (props) => {
  useEffect(() => {
    document.body.classList.add(classes.no_scroll);

    return () => {
      document.body.classList.remove(classes.no_scroll);
    };
  }, []);

  return (
    <>
      {ReactDom.createPortal(
        <Backdrop onCancel={props.onCancel} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDom.createPortal(
        <UpdateTestSeriesOverlayModel
          id={props.id}
          test_series_name={props.test_series_name}
          price={props.price}
          status={props.status}
          onClose={props.onClose}
          onCancel={props.onCancel}
        />,
        document.getElementById("overlay-root")
      )}
    </>
  );
};

export default OverlayModal;
