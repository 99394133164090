// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactUs_ContactUs_div__OfxTR {
    margin-top: 2rem;
    display: grid;
    justify-content: center;
    align-items: center;
}

.ContactUs_ContactUs_div__OfxTR h2{
    font-size: 25px;
    font-weight: bold;
    line-height: 3rem;     
    margin: 10px;   
}

/* .ContactUs_div p{
    display: flex;
    justify-content: center;
    align-items: center;
} */`, "",{"version":3,"sources":["webpack://./src/component/ContactUs.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;;;;GAIG","sourcesContent":[".ContactUs_div {\n    margin-top: 2rem;\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n.ContactUs_div h2{\n    font-size: 25px;\n    font-weight: bold;\n    line-height: 3rem;     \n    margin: 10px;   \n}\n\n/* .ContactUs_div p{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContactUs_div": `ContactUs_ContactUs_div__OfxTR`
};
export default ___CSS_LOADER_EXPORT___;
