import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import UpdateProductInTestSeriesOverlay from "./UpdateTestSeriesInProductOverlay";
import DeleteTestSeriesProductOverlay from "./DeleteTestSeriesProductOverlay";
import Cookies from "universal-cookie";

const ListTestSeriesProduct = () => {
  const [testSeriesProductData, setTestSeriesProductData] = useState(null);
  const [ismodelOpen, setIsModelOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [deletemodel, setDeleteModel] = useState(false);
  const [deleteId, setSelectedeleteId] = useState(null);
  // const [handleDeleteModelOpen, sethandleDeleteModelOpen] = useState(false);
  const cookies = new Cookies();

  const token = cookies.get("token");

  const fetchTestSeriesData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/admin/gettestseriesdatainproduct"
      );
      console.log(response.data);
      setTestSeriesProductData(response.data.fetcheddatasuccessfully);
    } catch (error) {
      // console.log("i got an error", error);
    }
  };
  useEffect(() => {
    fetchTestSeriesData();
  }, [selectedProduct, deleteId]);

  const handleChangeOverlayModel = (product) => {
    // console.log(product);
    setIsModelOpen(!ismodelOpen);
    setSelectedProduct(product);
  };

  const handleChangeDeleteOverlayModel = (selectedId) => {
    setDeleteModel(!deletemodel);
    setSelectedeleteId(selectedId);
  };

  return (
    <div>
      {ismodelOpen && (
        <UpdateProductInTestSeriesOverlay
          handleChangeOverlayModel={handleChangeOverlayModel}
          selectedProduct={selectedProduct}
          testSeriesProductData={testSeriesProductData}
        />
      )}

      {deletemodel && (
        <DeleteTestSeriesProductOverlay
          handleChangeDeleteOverlayModel={handleChangeDeleteOverlayModel}
          selectedId={deleteId}
        />
      )}
      <table>
        <thead>
          <tr>
            <td>id</td>
            <td>Product Name</td>

            <td>Test Series Name</td>
            <td>Date</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {testSeriesProductData &&
            testSeriesProductData.map((testSeriesList) => (
              <tr>
                <td style={{ fontSize: "12px" }}>{testSeriesList.id}</td>
                <td style={{ fontSize: "12px" }}>
                  {testSeriesList.productName}
                </td>
                <td style={{ fontsSize: "12px" }}>
                  {testSeriesList.testSeriesName}
                </td>
                <td style={{ fontSize: "12px" }}>{testSeriesList.date}</td>
                <td>
                  <div style={{ display: "flex", "flex-direction": "column" }}>
                    <FaPencilAlt
                      onClick={() => handleChangeOverlayModel(testSeriesList)}
                    />
                    <div>
                      <MdDelete
                        onClick={() =>
                          handleChangeDeleteOverlayModel(testSeriesList.id)
                        }
                        style={{
                          fontSize: "16px",
                          color: "red",
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListTestSeriesProduct;
