// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .container{
    padding: 20;
    margin: 0 auto;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: white;
  }
  
  .pagination button:disabled {
    background-color: #f1f1f1;
    cursor: not-allowed;
  } */


  .ErrorRecord_rows_per_page__L-xlJ {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .ErrorRecord_rows_per_page__L-xlJ select {
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px;
    border: 1px solid #ccc;
  }
  
  .ErrorRecord_pagination__z0yt9 {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
  }
  
  .ErrorRecord_pagination__z0yt9 button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
  }
  
  .ErrorRecord_pagination__z0yt9 button.ErrorRecord_active__8lrIz {
    background-color: #007bff;
    color: white;
  }
  
  .ErrorRecord_pagination__z0yt9 button:disabled {
    background-color: #f1f1f1;
    cursor: not-allowed;
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/ErrorRecord.module.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;KA2BK;;;EAGH;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,sBAAsB;IACtB,uBAAuB;IACvB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB","sourcesContent":["/* .container{\n    padding: 20;\n    margin: 0 auto;\n}\n\n.pagination {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    margin-top: 20px;\n  }\n  \n  .pagination button {\n    padding: 5px 10px;\n    border: 1px solid #ccc;\n    background-color: white;\n    cursor: pointer;\n  }\n  \n  .pagination button.active {\n    background-color: #007bff;\n    color: white;\n  }\n  \n  .pagination button:disabled {\n    background-color: #f1f1f1;\n    cursor: not-allowed;\n  } */\n\n\n  .rows_per_page {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n  }\n  \n  .rows_per_page select {\n    margin-left: 5px;\n    margin-right: 5px;\n    padding: 5px;\n    border: 1px solid #ccc;\n  }\n  \n  .pagination {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    margin-top: 20px;\n  }\n  \n  .pagination button {\n    padding: 5px 10px;\n    border: 1px solid #ccc;\n    background-color: white;\n    cursor: pointer;\n  }\n  \n  .pagination button.active {\n    background-color: #007bff;\n    color: white;\n  }\n  \n  .pagination button:disabled {\n    background-color: #f1f1f1;\n    cursor: not-allowed;\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rows_per_page": `ErrorRecord_rows_per_page__L-xlJ`,
	"pagination": `ErrorRecord_pagination__z0yt9`,
	"active": `ErrorRecord_active__8lrIz`
};
export default ___CSS_LOADER_EXPORT___;
