import React, { useState } from 'react';
// import './CompareTable.css'; // Import the CSS file

const CompareTable = ({ data }) => {
    const [selectedSubject, setSelectedSubject] = useState('Overall');

    // Function to handle subject change
    const handleSubjectChange = (subject) => {
        setSelectedSubject(subject);
    };

    // Get the data for the selected subject
    const displayData = selectedSubject === 'Overall'
        ? data.overall
        : data.subjects.find(sub => sub.SubjectName === selectedSubject);

    return (
        <div>
            {/* Render subject buttons */}
            <div className="subject-buttons">
                <button
                    onClick={() => handleSubjectChange('Overall')}
                    className={selectedSubject === 'Overall' ? 'active' : ''}
                >
                    Overall
                </button>
                {data.subjects.map((subject, index) => (
                    <button
                        key={index}
                        onClick={() => handleSubjectChange(subject.SubjectName)}
                        className={selectedSubject === subject.SubjectName ? 'active' : ''}
                    >
                        {subject.SubjectName}
                    </button>
                ))}
            </div>
            
            {/* Render comparison table */}
            <div className='compare-div overflow-x-scroll'>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Score</th>
                            <th>Accuracy</th>
                            <th>Correct</th>
                            <th>Wrong</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>You</td>
                            <td>
                                <div className="indicator_progress marks_indicator_progress" style={{ width: displayData.user.score_percentage + '%' }}></div>
                                <span className="c-txt-bold">{displayData.user.score}</span>
                                <span className="c-lt-blue"> / {displayData.user.total_marks}</span>
                            </td>
                            <td>
                                <div className="indicator_progress accuracy_indicator_progress" style={{ width: displayData.user.accuracy + '%' }}></div>
                                <span className="c-txt-bold">{displayData.user.accuracy}%</span>
                            </td>
                            <td>
                                <div className="indicator_progress correct_indicator_progress" style={{ width: displayData.user.correct_percentage + '%' }}></div>
                                <span className="c-txt-bold">{displayData.user.correct}</span>
                                <span className="c-lt-blue"> / {displayData.user.total_questions}</span>
                            </td>
                            <td>
                                <div className="indicator_progress wrong_indicator_progress" style={{ width: displayData.user.wrong_percentage + '%' }}></div>
                                <span className="c-txt-bold">{displayData.user.wrong}</span>
                                <span className="c-lt-blue"> / {displayData.user.total_questions}</span>
                            </td>
                            <td>
                                <div className="indicator_progress time_indicator_progress" style={{ width: displayData.user.time_percentage + '%' }}></div>
                                <span className="c-txt-bold">{displayData.user.time_taken}</span>
                                <span className="c-lt-blue"> / {displayData.user.total_time} mins</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Topper</td>
                            <td>
                                <div className="indicator_progress marks_indicator_progress" style={{ width: displayData.topper.score_percentage + '%' }}></div>
                                <span className="c-txt-bold">{displayData.topper.score}</span>
                                <span className="c-lt-blue"> / {displayData.topper.total_marks}</span>
                            </td>
                            <td>
                                <div className="indicator_progress accuracy_indicator_progress" style={{ width: displayData.topper.accuracy + '%' }}></div>
                                <span className="c-txt-bold">{displayData.topper.accuracy}%</span>
                            </td>
                            <td>
                                <div className="indicator_progress correct_indicator_progress" style={{ width: displayData.topper.correct_percentage + '%' }}></div>
                                <span className="c-txt-bold">{displayData.topper.correct}</span>
                                <span className="c-lt-blue"> / {displayData.topper.total_questions}</span>
                            </td>
                            <td>
                                <div className="indicator_progress wrong_indicator_progress" style={{ width: displayData.topper.wrong_percentage + '%' }}></div>
                                <span className="c-txt-bold">{displayData.topper.wrong}</span>
                                <span className="c-lt-blue"> / {displayData.topper.total_questions}</span>
                            </td>
                            <td>
                                <div className="indicator_progress time_indicator_progress" style={{ width: displayData.topper.time_percentage + '%' }}></div>
                                <span className="c-txt-bold">{displayData.topper.time_taken}</span>
                                <span className="c-lt-blue"> / {displayData.topper.total_time} mins</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CompareTable;







// import React, { useState } from "react";

// const CompareTable = ({ item }) => {
//   const [itemData, setItemData] = useState(item);
//   const [compareData, setCompareData] = useState(item.compare[0]);

//   const handleSubjectChange = (subject) => {
//     console.log(subject);
// };

//   return (
//     <div className="compare-div overflow-x-scroll">
//       {/* Render subject buttons */}
//       <div className="subject-buttons">
//         {compareData.subjects.map((subject, index) => (
//           <button
//             key={index}
//             onClick={() => handleSubjectChange(subject)}
//             // className={selectedSubject === subject ? "active" : ""}
//           >
//             {subject.SubjectName}
//           </button>
//         ))}
//       </div>

//       <table>
//         <thead>
//           <tr>
//             <th></th>
//             <th>Score</th>
//             <th>Accuracy</th>
//             <th>Correct</th>
//             <th>Wrong</th>
//             <th>Time</th>
//           </tr>
//         </thead>
//         <tbody>
//           <React.Fragment>
//             <tr>
//               <td>You</td>
//               <td>
//                 <div
//                   className="indicator_progress marks_indicator_progress"
//                   style={{ width: compareData.your_score_perc + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.YourScore}</span>
//                 <span className="c-lt-blue"> / {compareData.TotalScore}</span>
//               </td>
//               <td>
//                 <div
//                   className="indicator_progress accuracy_indicator_progress"
//                   style={{ width: compareData.YourAccuracy + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.YourAccuracy}%</span>
//               </td>
//               <td>
//                 <div
//                   className="indicator_progress correct_indicator_progress"
//                   style={{ width: compareData.your_correct_perc + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.YourCorrect}</span>
//                 <span className="c-lt-blue"> / {itemData.TotalQuestions}</span>
//               </td>
//               <td>
//                 <div
//                   className="indicator_progress wrong_indicator_progress"
//                   style={{ width: compareData.your_wrong_perc + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.YourWrong}</span>
//                 <span className="c-lt-blue"> / {itemData.TotalQuestions}</span>
//               </td>
//               <td>
//                 <div
//                   className="indicator_progress time_indicator_progress"
//                   style={{ width: compareData.your_time_perc + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.YourTime}</span>
//                 <span className="c-lt-blue"> / {itemData.TotalTime} mins</span>
//               </td>
//             </tr>
//             <tr>
//               <td>Topper</td>
//               <td>
//                 <div
//                   className="indicator_progress marks_indicator_progress"
//                   style={{ width: compareData.topper_score_perc + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.TopperScore}</span>
//                 <span className="c-lt-blue"> / {compareData.TotalScore}</span>
//               </td>
//               <td>
//                 <div
//                   className="indicator_progress accuracy_indicator_progress"
//                   style={{ width: compareData.TopperAccuracy + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">
//                   {compareData.TopperAccuracy}%
//                 </span>
//               </td>
//               <td>
//                 <div
//                   className="indicator_progress correct_indicator_progress"
//                   style={{ width: compareData.topper_correct_perc + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.TopperCorrect}</span>
//                 <span className="c-lt-blue"> / {itemData.TotalQuestions}</span>
//               </td>
//               <td>
//                 <div
//                   className="indicator_progress wrong_indicator_progress"
//                   style={{ width: compareData.topper_wrong_perc + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.TopperWrong}</span>
//                 <span className="c-lt-blue"> / {itemData.TotalQuestions}</span>
//               </td>
//               <td>
//                 <div
//                   className="indicator_progress time_indicator_progress"
//                   style={{ width: compareData.topper_time_perc + "%" }}
//                 ></div>
//                 <span className="c-txt-bold">{compareData.TopperTime}</span>
//                 <span className="c-lt-blue"> / {itemData.TotalTime} mins</span>
//               </td>
//             </tr>
//           </React.Fragment>
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default CompareTable;
