import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Layout from "./Layout";
import classes from "./MyTestSeries.module.css";
import check from "../images/check.png";
import { useNavigate } from "react-router-dom";

const MyTestSeries = () => {
  const [testSeriesData, setTestSeriesData] = useState([]);

  const navigate = useNavigate();

  const showMore = (product_id) => {
    navigate(`/individualexamtestpasssection/${product_id}`);
  };
  useEffect(() => {
    const fetchTestSeries = async () => {
      // console.log("i got called");
      try {
        const cookies = new Cookies();
        let userData = cookies.get("userData");
        if (typeof userData == "string") {
          try {
            userData = JSON.parse(userData);
          } catch (error) {
            console.error("Error parsing userData from cookies", error);
            return;
          }
        }

        // if (!userData || !userData.email_id) {
        //   console.log("No userData or email_id  found in cookies");
        //   return;
        // }

        // console.log(userData);

        const { email_id } = userData;
        const response = await axios.post(
          process.env.REACT_APP_PINNACLE_BASE_URL + "/mytestseries",
          {
            email_id,
          }
        );
        setTestSeriesData(response.data);
      } catch (error) {
        console.log("An internal server error occurs");
      }
    };
    fetchTestSeries();
  }, []);

  console.log(testSeriesData);
  return (
    <Layout>
      <div className={classes.my_test_series_main_div}>
        {testSeriesData &&
          testSeriesData.map((testdata, index) => (
            <div key={index} className={classes.test_data_div}>
              <h3>{testdata.Product_title}</h3>
              <ul className={classes.my_test_series_ul}>
                {testdata.description.map((desc, descIndex) => (
                  <>
                    <li key={descIndex} className={classes.my_test_series_li}>
                      <img src={check} alt="check" />
                      {desc}
                    </li>
                  </>
                ))}
                <li className={classes.btn_div}>
                  <button
                    className={classes.show_more_btn}
                    onClick={() => showMore(testdata.id)} // Pass the ID to showMore
                  >
                    Show More
                  </button>
                </li>
              </ul>
            </div>
          ))}
      </div>
    </Layout>
  );
};

export default MyTestSeries;
