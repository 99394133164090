// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProductReviews.css */

.product-reviews {
    margin-top: 20px;
  }
  
  .product-reviews h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .review {
    display: flex;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9; /* Change to your desired background color */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .user-avatar {
    flex: 0 0 60px;
    margin-right: 15px;
  }
  
  .user-avatar img {
    width: 100%;
    border-radius: 50%;
  }
  
  .review-content {
    flex: 1 1;
  }
  
  .review-content h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .review-content p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .review-content p:last-child {
    margin-bottom: 0;
  }
  
  .review-content .rating {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #f50057; /* Change to your desired rating color */
  }
  
  .review-content .rating i {
    margin-right: 5px;
  }
  
  /* Apply additional styles as needed */
  `, "",{"version":3,"sources":["webpack://./src/component/ProductReviews.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,yBAAyB,EAAE,4CAA4C;IACvE,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,cAAc,EAAE,wCAAwC;EAC1D;;EAEA;IACE,iBAAiB;EACnB;;EAEA,sCAAsC","sourcesContent":["/* ProductReviews.css */\n\n.product-reviews {\n    margin-top: 20px;\n  }\n  \n  .product-reviews h2 {\n    font-size: 20px;\n    margin-bottom: 10px;\n  }\n  \n  .review {\n    display: flex;\n    margin-bottom: 20px;\n    padding: 15px;\n    background-color: #f9f9f9; /* Change to your desired background color */\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .user-avatar {\n    flex: 0 0 60px;\n    margin-right: 15px;\n  }\n  \n  .user-avatar img {\n    width: 100%;\n    border-radius: 50%;\n  }\n  \n  .review-content {\n    flex: 1;\n  }\n  \n  .review-content h3 {\n    font-size: 18px;\n    margin-bottom: 5px;\n  }\n  \n  .review-content p {\n    font-size: 16px;\n    margin-bottom: 10px;\n  }\n  \n  .review-content p:last-child {\n    margin-bottom: 0;\n  }\n  \n  .review-content .rating {\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n    color: #f50057; /* Change to your desired rating color */\n  }\n  \n  .review-content .rating i {\n    margin-right: 5px;\n  }\n  \n  /* Apply additional styles as needed */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
