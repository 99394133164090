// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding: 20px;
}

.data-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.data-card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.data-card h2 {
  margin-bottom: 10px;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .data-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/Content.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,0CAA0C;AAC5C;;AAEA;EACE,mBAAmB;AACrB;;AAEA,kCAAkC;AAClC;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":[".content {\n  padding: 20px;\n}\n\n.data-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  gap: 20px;\n  margin-top: 20px;\n}\n\n.data-card {\n  border: 1px solid #ddd;\n  padding: 20px;\n  border-radius: 5px;\n  background-color: white;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.data-card h2 {\n  margin-bottom: 10px;\n}\n\n/* Media Query for Small Screens */\n@media (max-width: 768px) {\n  .data-grid {\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
