import React from 'react';
import Layout from "./Layout";
import classes from "./PreviousYearExams.module.css";


const exams = [
  { name: "SSC CGL", papers: 335, image: "https://ssccglpinnacle.com/images/ssc-logo.png" },
  { name: "SSC CHSL", papers: 50, image: "https://ssccglpinnacle.com/images/ssc-logo.png" },
  { name: "SSC CPO", papers: 310, image: "https://ssccglpinnacle.com/images/ssc-logo.png" },
  { name: "SSC GD", papers: 30, image: "https://ssccglpinnacle.com/images/ssc-logo.png" },
  { name: "SSC MTS", papers: 51, image: "https://ssccglpinnacle.com/images/ssc-logo.png" },
  { name: "SSC Steno", papers: 335, image: "https://ssccglpinnacle.com/images/ssc-logo.png" },
  { name: "RRB ALP", papers: 335, image: "https://ssccglpinnacle.com/images/railway-logo.png" },
  { name: "RRB GroupD", papers: 335, image: "https://ssccglpinnacle.com/images/railway-logo.png" },
  { name: "RRB RPF", papers: 335, image: "https://ssccglpinnacle.com/images/railway-logo.png" },
  { name: "RRB Technician", papers: 335, image: "https://ssccglpinnacle.com/images/railway-logo.png" },
  { name: "RRB NTPC", papers: 335, image: "https://ssccglpinnacle.com/images/railway-logo.png" },
  { name: "RRB JE", papers: 335, image: "https://ssccglpinnacle.com/images/railway-logo.png" },
  { name: "Delhi Police", papers: 335, image: "	https://ssccglpinnacle.com/images/delhi-police-logo.png" },
  { name: "Delhi Police", papers: 335, image: "	https://ssccglpinnacle.com/images/delhi-police-logo.png" },
  { name: "Delhi Police MTS", papers: 335, image: "	https://ssccglpinnacle.com/images/delhi-police-logo.png" },
  { name: "Delhi Police Driver", papers: 335, image: "https://ssccglpinnacle.com/images/delhi-police-logo.png" },
];

const PreviousYearExams = () => {
  const payMe = () => {
    alert("Proceed to payment");
  };

  return (
    <Layout>
      <div className={classes.heading_section}>
        <h2>Explore Previous Year of all exams</h2>
      </div>
      <div className={classes.heading1_section}>
        <h5>Also unlock 27000 PYP with Pinnacle</h5>
        <button className={classes.testseries_buy_button} onClick={payMe}>
          GET Test pass pro now
        </button>
      </div>
      <div className={classes.exams_container}>
        {exams.map((exam, index) => (
          <div key={index} className={classes.exam_card}>
            <img src={exam.image} alt={exam.name} className={classes.exam_image} />
            <div className={classes.exam_info}>
              <span>{exam.name}</span>
              <span>{exam.papers} paper{exam.papers > 1 ? 's' : ''}</span>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default PreviousYearExams;
