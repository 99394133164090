// import React, { useEffect } from "react";
// import classes from "./Footer.module.css";
// import { useNavigate } from "react-router";
// import { BiGlobe, BiRightArrowAlt } from "react-icons/bi";
// import { FaSquareYoutube } from "react-icons/fa6";
// import { BsTelegram } from "react-icons/bs";
// import { FaRegAddressBook } from "react-icons/fa6";
// import { FaBookAtlas } from "react-icons/fa6";
// import { SiTestcafe } from "react-icons/si";
// import gmail1 from "../images/gmail1.svg";
// import { Link } from "react-router-dom";

// const Footer = () => {
//   const navigate = useNavigate();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <footer className={classes.footer}>
//       <div className={classes.footerColumns}>
//         {/* ************** first section *******************  */}

//         <div className={classes.footerColumn}>
//           <div
//             className={classes.footerRow}
//             onClick={() => navigate("/aboutus")}
//           >
//             <div className={classes.footer_heading_imp}>About Us</div>
//           </div>
//           <div className={classes.footerRow}>
//             <div className={classes.footer_heading_imp}>
//               <a
//                 href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
//                 target="blank"
//                 className={classes["aTag"]}
//               >
//                 Download App
//               </a>
//             </div>
//           </div>

//           {/* <div className={classes.footerRow} onClick={() => navigate("/TearmAndCondition")}>
//             <div className={classes.footer_heading_imp} target="blank">
//               Terms &amp; Conditions
//             </div>
//           </div> */}

//           {/* <div className={classes.footerRow} onClick={() => navigate("/TearmAndCondition")}> */}
//           <div
//             className={classes.footerRow}
//             onClick={() => navigate("/termandcondition")}
//           >
//             <div className={classes.footer_heading_imp}>
//               Terms &amp; Conditions
//             </div>
//           </div>

//           <div
//             className={classes.footerRow}
//             onClick={() => navigate("/privacypolicy")}
//           >
//             <div className={classes.footer_heading_imp}>Privacy Policy</div>
//           </div>
//           <div
//             className={classes.footerRow}
//             onClick={() => navigate("/refundPolicy")}
//           >
//             <div className={classes.footer_heading_imp}>Refund Policy</div>
//           </div>
//           <div
//             className={classes.footerRow}
//             onClick={() => navigate("/contactus")}
//           >
//             <div className={classes.footer_heading_imp}>Contact Us</div>
//           </div>
//         </div>

//         {/************** second section ******************/}

//         <div className={classes.footerColumn}>
//           <h3 className={classes.heading_h3}>SSC CPO </h3>
//           <Link
//             className={classes.ssc_cpo_linkI}
//             to="https://testportal.ssccglpinnacle.com/testseriessingle/1/3/5/121"
//           >
//             <p>SSC CPO Tier I</p>
//           </Link>
//           <Link
//             className={classes.ssc_cpo_tierII}
//             to="https://testportal.ssccglpinnacle.com/testseriessingle/1/3/10/121"
//           >
//             <p>SSC CPO Tier II</p>
//           </Link>
//         </div>

//         {/* ********* Third section ************  */}

//         <div className={classes.footerColumn}>
//           <h3 className={classes.heading_h3}>SSC CGL</h3>
//           <Link
//             className={classes.ssc_cgl_I}
//             to="https://testportal.ssccglpinnacle.com/testseriessingle/1/1/1/121"
//           >
//             <p>SSC CGL Tier I</p>
//           </Link>

//           <Link
//             className={classes.ssc_cgl_II}
//             to="https://testportal.ssccglpinnacle.com/testseriessingle/1/1/2/121"
//           >
//             <p>SSC CGL Tier II</p>
//           </Link>
//         </div>

//         {/* *************** Fourth Section ************** */}

//         <div className={classes.footerColumn}>
//           <h3 className={classes.heading_h3}>TEST SERIES</h3>
//           <div className={classes.ssc_a_tag}>
//             <Link
//               to="https://testportal.ssccglpinnacle.com/individualexamtestpasssection/121"
//               target="blank"
//               className={classes.ssc_link}
//             >
//               SSC
//             </Link>
//           </div>
//           <div className={classes.railway_a_tag}>
//             <Link
//               to="https://testportal.ssccglpinnacle.com/individualexamtestpasssection/122"
//               target="blank"
//               className={classes.railway_link}
//             >
//               RAILWAY
//             </Link>
//           </div>
//           <div className={classes.delhi_police_a_tag}>
//             {/* <Link href="http://localhost:3000/testseriessingle/2/19/23"> */}
//             <p>DELHI POLICE</p>
//             {/* </Link> */}
//           </div>

//           <p>CTET</p>
//           <p>BANKING</p>
//           <p>UPSC</p>
//         </div>

//         {/* ************ FIVE SECTION *******************  */}

//         <div className={classes.footerColumn}>
//           <div className={classes.footerRow}>
//             <h3 className={classes.heading_h3}>CONNECT</h3>
//           </div>
//           <div className={classes.footerRow}>
//             <div className={classes.individual_div}>
//               <SiTestcafe className={classes.testicon} />
//               <Link
//                 to="https://testportal.ssccglpinnacle.com/"
//                 target="blank"
//                 className={classes["aTag"]}
//               >
//                 Test Portal
//               </Link>
//             </div>

//             <div className={classes.footerRow}>
//               <div className={classes.individual_div}>
//                 <FaBookAtlas className={classes.digitalBooks} />
//                 <Link
//                   to="https://ssccglpinnacle.com/books-catalog"
//                   target="blank"
//                   className={classes["aTag"]}
//                 >
//                   Digital Books
//                 </Link>
//               </div>
//             </div>

//             <div className={classes.individual_div}>
//               <FaRegAddressBook className={classes.printedBook} />
//               <Link
//                 to="https://ssccglpinnacle.com/videos"
//                 target="blank"
//                 className={classes["aTag"]}
//               >
//                 Videos
//               </Link>
//             </div>

//             <div className={classes.footerRow}>
//               <div className={classes.individual_div}>
//                 <FaSquareYoutube className={classes.youtubeicon} />
//                 <Link
//                   to="https://www.youtube.com/results?search_query=pinnacle+publication"
//                   target="blank"
//                   className={classes.a_tag}
//                 >
//                   <span>YouTube Channel</span>
//                 </Link>
//               </div>
//             </div>

//             {/* <div className={classes.footerRow}>
//               <div className={classes.individual_div}>
//                 <BsTelegram className={classes.telegramicon} />
//                 <Link
//                   href="https://t.me/ssccglpinnacleonline"
//                   target="blank"
//                   className={classes.a_Tag}
//                 >
//                   Telegram Channel
//                 </Link>
//               </div>
//             </div> */}
//           </div>
//         </div>

//         {/* ********** SIX SECTION *************  */}

//         <div>
//           <h3 className={classes.heading_h3}>CAREER</h3>
//           <div className={classes.career_div}>
//             <img
//               className={classes.gmail_image_section}
//               src={gmail1}
//               alt="gmail_footer_img"
//             />
//             <p>support@ssccglpinnacle.com</p>
//           </div>
//         </div>
//       </div>

//       {/* <div className={classes.footerColumnlast}>
//         <BiGlobe className={classes.footerGlobeIcon}  />
//         <div className={classes.footerLanguage}>English</div>
//       </div> */}

//       <div className={classes.footerCopyright}>
//         {/* <BiRightArrowAlt className={classes.footerCopyrightIcon} size={14} /> */}
//         <span>&copy; 2024 Pinnacle. All rights reserved.</span>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React, { useEffect } from "react";
import classes from "./Footer.module.css";
import { useNavigate } from "react-router";
import { BiGlobe, BiRightArrowAlt } from "react-icons/bi";
import { BsTelegram } from "react-icons/bs";
import { FaBookAtlas, FaRegKeyboard, FaRegAddressBook, FaSquareYoutube } from "react-icons/fa6";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { PiBookDuotone } from "react-icons/pi";

import { SiTestcafe } from "react-icons/si";
import gmail1 from "../images/gmail1.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <footer className={classes.footer}>
      <div className={classes.footerColumns}>
        {/* ************** first section *******************  */}

        <div className={classes.footerColumn}>
          <div
            className={classes.footerRow}
            onClick={() => navigate("/aboutus")}
          >
            <div className={classes.footer_heading_imp}>About Us</div>
          </div>
          <div className={classes.footerRow}>
            <div className={classes.footer_heading_imp}>
              <a
                href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
                target="_blank"
                rel="noopener noreferrer"
                className={classes["aTag"]}
              >
                Download App
              </a>
            </div>
          </div>

          <div
            className={classes.footerRow}
            onClick={() => navigate("/termandcondition")}
          >
            <div className={classes.footer_heading_imp}>
              Terms &amp; Conditions
            </div>
          </div>

          <div
            className={classes.footerRow}
            onClick={() => navigate("/privacypolicy")}
          >
            <div className={classes.footer_heading_imp}>Privacy Policy</div>
          </div>
          <div
            className={classes.footerRow}
            onClick={() => navigate("/refundPolicy")}
          >
            <div className={classes.footer_heading_imp}>Refund Policy</div>
          </div>
          <div
            className={classes.footerRow}
            onClick={() => navigate("/contactus")}
          >
            <div className={classes.footer_heading_imp}>Contact Us</div>
          </div>
        </div>

        {/************** second section ******************/}

        <div className={classes.footerColumn}>
          <h3 className={classes.heading_h3}>SSC CPO </h3>
          <Link
            className={classes.ssc_cpo_linkI}
            to="/testseriessingle/1/3/5/121"
          >
            <p>SSC CPO Tier I</p>
          </Link>
          <Link
            className={classes.ssc_cpo_tierII}
            to="/testseriessingle/1/3/10/121"
          >
            <p>SSC CPO Tier II</p>
          </Link>
        </div>

        {/* ********* Third section ************  */}

        <div className={classes.footerColumn}>
          <h3 className={classes.heading_h3}>SSC CGL</h3>
          <Link className={classes.ssc_cgl_I} to="/testseriessingle/1/1/1/121">
            <p>SSC CGL Tier I</p>
          </Link>

          <Link className={classes.ssc_cgl_II} to="/testseriessingle/1/1/2/121">
            <p>SSC CGL Tier II</p>
          </Link>
        </div>

        {/* *************** Fourth Section ************** */}

        <div className={classes.footerColumn}>
          <h3 className={classes.heading_h3}>TEST SERIES</h3>
          <div className={classes.ssc_a_tag}>
            <Link
              to="/individualexamtestpasssection/121"
              className={classes.ssc_link}
            >
              SSC
            </Link>
          </div>
          <div className={classes.railway_a_tag}>
            <Link
              to="/individualexamtestpasssection/122"
              className={classes.railway_link}
            >
              RAILWAY
            </Link>
          </div>

          {/*
          <div className={classes.delhi_police_a_tag}>
            <p>DELHI POLICE</p>
          </div>

          <p>CTET</p>
          <p>BANKING</p>
          <p>UPSC</p>
          */}
        </div>

        {/* ************ FIVE SECTION *******************  */}

        <div className={classes.footerColumn}>
          <div className={classes.footerRow}>
            <h3 className={classes.heading_h3}>CONNECT</h3>
          </div>
          <div className={classes.footerRow}>
            <div className={classes.individual_div}>
              <SiTestcafe className={classes.testicon} />
              <Link to="/" className={classes["aTag"]}>
                Test Portal
              </Link>
            </div>

            <div className={classes.individual_div}>
              <MdOutlineOndemandVideo className={classes.printedBook} />
              <Link
                to="https://ssccglpinnacle.com/videos"
                className={classes["aTag"]}
              >
                Videos
              </Link>
            </div>

            <div className={classes.footerRow}>
              <div className={classes.individual_div}>
                <PiBookDuotone className={classes.digitalBooks} />
                <Link
                  to="https://ssccglpinnacle.com/digital-catalog"
                  className={classes["aTag"]}
                >
                  Digital Books
                </Link>
              </div>
            </div>

            <div className={classes.footerRow}>
              <div className={classes.individual_div}>
                <FaBookAtlas className={classes.digitalBooks} />
                <Link
                  to="https://ssccglpinnacle.com/books-catalog"
                  className={classes["aTag"]}
                >
                  Books
                </Link>
              </div>
            </div>

            <div className={classes.individual_div}>
            {/*
              <FaRegAddressBook className={classes.printedBook} />
              */}
              <FaRegKeyboard />
              <Link
                to="https://ssccglpinnacle.com/typingexamselection"
                className={classes["aTag"]}
              >
                Typing
              </Link>
            </div>

            <div className={classes.footerRow}>
              <div className={classes.individual_div}>
                <FaSquareYoutube className={classes.youtubeicon} />
                <Link
                  to="https://www.youtube.com/@pinnaclepublications"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.a_tag}
                >
                  <span>YouTube Channel</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* ********** SIX SECTION *************  */}

        <div>
          <h3 className={classes.heading_h3}>CAREER</h3>
          <div className={classes.career_div}>
            <img
              className={classes.gmail_image_section}
              src={gmail1}
              alt="gmail_footer_img"
            />
            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com" target="_blank" rel="noopener noreferrer">support@ssccglpinnacle.com</a>
          </div>
        </div>
      </div>

      <div className={classes.footerCopyright}>
        <span>&copy; 2024 Pinnacle. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
