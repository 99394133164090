import React from 'react';
import Navbar from './Dashboard/Navbar';

const Users = () => {
  return (
    <div className="app">
        <div className="main">  
          <Navbar /> 
          <h1>Users Page</h1>
        </div>
    </div>
  );
};

export default Users;
