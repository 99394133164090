
import React from 'react';
import Layout from "./Layout";
import classes from "./PreviousYearQuestionPaper.module.css";

const questionPapers = [
  {
    year: "2023",
    tests: [
      {
        title: "SSC CGL 2023 Tier-I Official Paper (Held On: 17 Jul 2023 Shift 3)",
        users: "108.8k",
        questions: 100,
        marks: 200,
        time: "60 Mins",
      },
      {
        title: "SSC CGL 2023 Tier-I Official Paper (Held On: 17 Jul 2023 Shift 3)",
        users: "108.8k",
        questions: 100,
        marks: 200,
        time: "60 Mins",
      },
    ],
  },
  {
    year: "2022",
    tests: [
      {
        title: "SSC CGL 2022 Tier-I Official Paper (Held On: 17 Jul 2022 Shift 3)",
        users: "108.8k",
        questions: 100,
        marks: 200,
        time: "60 Mins",
      },
      {
        title: "SSC CGL 2022 Tier-I Official Paper (Held On: 17 Jul 2022 Shift 3)",
        users: "108.8k",
        questions: 100,
        marks: 200,
        time: "60 Mins",
      },
    ],
  },
];

const PreviousYearQuestionPaper = () => {
  const title = "SSC CGL 2023 Tier-I Official Paper (Held On: 17 Jul 2023 Shift 2)";
  const date = "Attempted on May 17";
  const rank = "158691/177016";
  const marks = "3.5/200 Marks";

  const handleDownload = () => {
    alert("Download PDF");
  };

  const handleAnalysis = () => {
    alert("Analysis");
  };

  const handleReattempt = () => {
    alert("Reattempt");
  };

  const payMe = () => {
    alert("Proceed to payment");
  };

  const handleUnlock = () => {
    alert("Unlock Now");
  };

  return (
    <Layout>
      <div className={classes.heading_section}>
        <h2>SSC CGL Previous year question papers</h2>
      </div>
      <div className={classes.heading1_section}>
        <h5>Also Unlock 27000 PYP with Pinnacle</h5>
        <button className={classes.testseries_buy_button} onClick={payMe}>
          GET Test Pass Pro Now
        </button>
      </div>
      <div className={classes.body_heading_section}>
        <h4>SSC CGL 2024 Question Paper (total test: 1)</h4>
        <div className={classes.test_pass_card}>
          <div className={classes.badge1}>FREE</div>
          <div className={classes.content}>
            <div className={classes.first_line}>
            <h4>{title}</h4>
            <button className={`${classes.btn} ${classes.download_btn}`} onClick={handleDownload}>Download PDF</button>
            <button className={`${classes.btn} ${classes.analysis_btn}`} onClick={handleAnalysis}>Analysis</button>
            </div>
            <p className={classes.date}>{date}</p>
            <div className={classes.info}>
              <span className={classes.rank}>Rank: {rank}</span>
              <span className={classes.marks}>Marks: {marks}</span>
            </div>
            <div className={classes.actions}>
              <button className={classes.reattempt_btn} onClick={handleReattempt}>Reattempt</button>
            </div>
          </div>
        </div>
      </div>
      {questionPapers.map((paper) => (
        <div key={paper.year}>
          <div className={classes.heading2_section}>
            {/* <h2>SSC CGL {paper.year} Question Paper (total test: {paper.tests.length})</h2> */}
            <h2>SSC CGL {paper.year} Question Paper (total test: 171)</h2>
          </div>
          {paper.tests.map((test, index) => (
            <div key={index} className={classes.test_pass_card}>
              <div className={classes.badge}>PRO</div>
              <div className={classes.content}>
              <div className={classes.first_line}>
                <h4>{test.title}</h4>
                  <button className={`${classes.btn} ${classes.download_btn}`} onClick={handleDownload}>Download PDF</button>
                  <button className={`${classes.btn} ${classes.unlock_btn}`} onClick={handleUnlock}>
                  <img src={require("../images/lock-alt-svgrepo-com.svg").default} alt="Lock Icon" className={classes.img} />
                    Unlock Now
                  </button>
                </div>
                <div className={classes.info}>
                  <span className={classes.users}>⚡ {test.users} Users</span>
                </div>
                <div className={classes.details}>
                  <span>{test.questions} Questions</span>
                  <span>{test.marks} Marks</span>
                  <span>{test.time}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </Layout>
  );
}

export default PreviousYearQuestionPaper;


