// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewProducts_new_pro_main_div__vy9H4{
    background: white;
    min-height: auto !important;
    width: 95%;
    padding-bottom: 40px;
}
.NewProducts_prod_heading__Y3U\\+p{
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 40px;
}
.NewProducts_form_editor__ugV1a{
    background: white;
    width: 90%;
    margin-left: 35px;
    margin-top: 20px;
}

.NewProducts_label_tag__oXe6m{
    display: flex;
    margin: 0 0 8px 8px;
    color: rgb(29, 28, 28);
    font-weight: bold;
}
.NewProducts_form_control__P-Xca{
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.NewProducts_batch_div__0Gy75{
    margin-top: 10px;
    margin-bottom: 10px;
}
.NewProducts_long_des_heading__-H2JA{
    display: flex;
    margin: 0 0 8px 4px;
}
.NewProducts_active_label__ODQjV{
    padding: 8px 28px 8px 12px;
}
.NewProducts_active_int__OmcU7{
    margin: 8px;
}

.NewProducts_publish_btn__v-aoA{
    background: #02549D;
    color: white;
    border: none;
    padding: 8px 44px;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/NewProducts.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,UAAU;IACV,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,UAAU;IACV,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".new_pro_main_div{\n    background: white;\n    min-height: auto !important;\n    width: 95%;\n    padding-bottom: 40px;\n}\n.prod_heading{\n    font-size: 20px;\n    margin-top: 10px;\n    margin-bottom: 20px;\n    margin-left: 40px;\n}\n.form_editor{\n    background: white;\n    width: 90%;\n    margin-left: 35px;\n    margin-top: 20px;\n}\n\n.label_tag{\n    display: flex;\n    margin: 0 0 8px 8px;\n    color: rgb(29, 28, 28);\n    font-weight: bold;\n}\n.form_control{\n    width: 100%;\n    padding: 8px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    box-sizing: border-box;\n}\n.batch_div{\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n.long_des_heading{\n    display: flex;\n    margin: 0 0 8px 4px;\n}\n.active_label{\n    padding: 8px 28px 8px 12px;\n}\n.active_int{\n    margin: 8px;\n}\n\n.publish_btn{\n    background: #02549D;\n    color: white;\n    border: none;\n    padding: 8px 44px;\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new_pro_main_div": `NewProducts_new_pro_main_div__vy9H4`,
	"prod_heading": `NewProducts_prod_heading__Y3U+p`,
	"form_editor": `NewProducts_form_editor__ugV1a`,
	"label_tag": `NewProducts_label_tag__oXe6m`,
	"form_control": `NewProducts_form_control__P-Xca`,
	"batch_div": `NewProducts_batch_div__0Gy75`,
	"long_des_heading": `NewProducts_long_des_heading__-H2JA`,
	"active_label": `NewProducts_active_label__ODQjV`,
	"active_int": `NewProducts_active_int__OmcU7`,
	"publish_btn": `NewProducts_publish_btn__v-aoA`
};
export default ___CSS_LOADER_EXPORT___;
