import React, { useState, useEffect } from "react";
// import Navbar from "./Navbar";
// import { useTable } from "react-table";
import axios from "axios";
import { FaPencil } from "react-icons/fa6";
import UpdateProductOverlayModel from "./UpdateProductOverlayModel";
import Cookies from "universal-cookie";

const AllProducts = () => {
  const [productData, setProductData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productMetaData, setProductMetaData] = useState(null);

  const cookies = new Cookies();

  const token = cookies.get("token");

  const fetchProductData = async () => {
    try {
      console.log(
        "process.env.REACT_APP_PINNACLE_BASE_URL",
        process.env.REACT_APP_PINNACLE_BASE_URL
      );
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/allproducts",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
        // { id: selectedProduct },
        // {
        //   headers: {
        //     "Content-Type": "applicaiton/json",
        //   },
        // }
      );
      // .then((response) => {
      //   console.log("api response received", response.data);
      // });
      // setProductData(response.data);
      console.log(response.data);
      setProductData(response.data);
    } catch (error) {
      console.error("Error fetching product data", error);
    }
  };
  // console.log(productData);

  useEffect(() => {
    fetchProductData();
  }, [selectedProduct]);

  const handleModelViewChange = (id) => {
    // console.log(id);
    setIsModelOpen(!isModelOpen);
    setSelectedProduct(id);
    console.log(selectedProduct);
  };

  useEffect(() => {
    const fetchProductMetaData = async () => {
      // console.log(selectedProduct);
      if (selectedProduct !== null) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_PINNACLE_BASE_URL +
              "/api/v1/admin/fetchindividualproductdetail",
            {
              product_id: selectedProduct,
            },

            {
              headers: {
                Authorization: `Bearer ${token} `,
              },
            }
          );

          // setProductMetaData(response.data);
          setProductMetaData(response.data);
          console.log(response.data);
        } catch (error) {
          // res.status(500).json({ message: "internal server error " });
          console.error("error fetching product data", error.message);
          console.error("error stack trace", error.stack);
        }
      }
    };
    fetchProductMetaData();
  }, [selectedProduct]);

  // console.log(productMetaData);
  return (
    <>
      {isModelOpen && (
        <UpdateProductOverlayModel
          handleModelViewChange={handleModelViewChange}
          // selectedProduct={selectedProduct}
          ProductData={productData.find(
            (product) => product.id === selectedProduct
          )}
          ProductMetaData={productMetaData}
        />
      )}
      <div>
        <table>
          <thead>
            <tr>
              <td>ID</td>
              <td>PRODUCT IMAGE</td>
              <td>PRODUCT TITLE</td>
              <td>PRODUCT PERMALINK</td>
              <td>status</td>
              <td>ts</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {productData.length > 0 &&
              productData.map((productinformation, index) => (
                <tr key={index}>
                  <td>{productinformation.id}</td>
                  <td>
                    <img
                      src={productinformation.product_image}
                      alt="product"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </td>
                  <td>{productinformation.product_title} </td>
                  <td>{productinformation.product_permalink}</td>
                  <td>{productinformation.status}</td>
                  <td>{productinformation.ts}</td>
                  <td>
                    <FaPencil
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleModelViewChange(productinformation.id)
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default AllProducts;
