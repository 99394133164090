import React from "react";
import ReactDom from "react-dom";
import { RxCross1 } from "react-icons/rx";
import classes from "./Product_buy_initiate.module.css";

const Backdrop = ({ handleOverlayModelChange }) => {
  return (
    <div onClick={handleOverlayModelChange} className={classes.backdrop}></div>
  );
};

const ProductBuyOverlay = ({ handleOverlayModelChange }) => {
  <div> hello how are you </div>;
};

const ProductBuyInitiate = ({ handleOverlayModelChange }) => {
  return (
    <>
      {ReactDom.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}

      {ReactDom.createPortal(
        <ProductBuyOverlay
          handleOverlayModelChange={handleOverlayModelChange}
        />,
        document.getElementById("updateoverlay-productroot")
      )}
    </>
  );
};

export default ProductBuyInitiate;
