// import React, { useEffect, useState } from "react";
// import classes from "./PrivacyPolicy.module.css";
// import Layout from "./Layout";
// import axios from "axios";

// const PrivacyPolicy = () => {
//     const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");
//     const [error, setError] = useState("");
//     const [loading , setLoading] = useState("");
    

//     useEffect(()=> {
//         const fetchPrivacyPolicyContent = async () => {
//             try {
//                 const baseUrl = "http://localhost:5000";

//                 if (!baseUrl) {
//                     throw new Error("Base URL is not defined");
//                 }

//                 const response = await axios.get(`${baseUrl}/api/v1/terms-and-conditions`);
//                 if (response.data) {
//                     console.log("POLICY PRIVACY DATA", response.data);
//                     setPrivacyPolicyContent(response.deta.data.terms_conditions || "");
//                 } else {
//                     setError("Failed to load policy and privacy");
//                 }
//             } catch (error) {
//                 console.error("Error fetching Privacy and Policy.", error);
//                 setError("An error occurred while fetching policy and privacy.");
//             }finally {
//                 setLoading(false);
//             }
//         };
//         fetchPrivacyPolicyContent();
//     }, []);

//   return (
//     <Layout>
//       <div className={classes.PrivacyPolicy_div}>
//         <h1>PrivacyPolicy</h1>
//       </div>
//     </Layout>
//   );
// };

// export default PrivacyPolicy;


import React, { useEffect, useState } from "react";
import classes from "./PrivacyPolicy.module.css";
import Layout from "./Layout";
import axios from "axios";

const PrivacyPolicy = () => {
    const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);  // Initialize as true because loading happens initially

    useEffect(() => {
        const fetchPrivacyPolicyContent = async () => {
            try {
                // const baseUrl = "http://localhost:5000";

                // if (!baseUrl) {
                //     throw new Error("Base URL is not defined");
                // }

                const response = await axios.get(`${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/terms-and-conditions`);
                if (response.data) {
                    console.log("POLICY PRIVACY DATA", response.data);
                    setPrivacyPolicyContent(response.data.data.privacy_policy || "");
                } else {
                    setError("Failed to load policy and privacy.");
                }
            } catch (error) {
                console.error("Error fetching Privacy and Policy.", error);
                setError("An error occurred while fetching policy and privacy.");
            } finally {
                setLoading(false);
            }
        };

        fetchPrivacyPolicyContent();
    }, []);

    return (
        <Layout>
            <div className={classes.PrivacyPolicy_div}>
                {loading && <p>Loading privacy policy...</p>}
                {error && <p className={classes.error}>{error}</p>}
                {!loading && !error && (
                    <div
                        className={classes.policyContent}
                        dangerouslySetInnerHTML={{ __html: privacyPolicyContent }}
                    />
                )}
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;
