// import React from "react";
// import Layout from "./Layout";

// const UserProfile = () => {
//   return (
//     <div>
//       <ul>
//         <li>Refer & Earn</li>
//         <li>Transcations</li>
//         <li>settings</li>
//         <li>Log out</li>
//       </ul>
//     </div>
//   );
// };

// export default UserProfile;


import React from 'react';
import { FcBusinessman } from "react-icons/fc";
// import { FcBusinesswoman } from "react-icons/fc";
import classes from "./UserProfileCard.module.css";
// import { useAuthContext } from "../../hooks/useAuthContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';



function UserProfileCard({ user, onShowMessage }) {

  const { token, dispatch } = useAuthContext();
  const navigate = useNavigate();

    const userProfile = useSelector((state) => state.userProfile);
    console.log("userProfile", userProfile);
    console.log("TOKEN",token);



  const logoutHandler = async (e) => {
    e.preventDefault();

    try {
      let data = { _id: userProfile._id };
      let url = process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/logout";

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        body: JSON.stringify(data),
      });

      const jsonData = await response.json();
      if (response.ok) {
        console.log("JSON Data:", jsonData);

        // Remove user from storage
        // reduxDispatch(addProfile(null));
        dispatch({ type: "LOGOUT" });

        // Clear local storage
        localStorage.removeItem("userData");
        localStorage.removeItem("expiration");
        localStorage.removeItem("token");
        onShowMessage(jsonData.message);

        navigate("/");
      } else {
        console.log("Response Error:", response.statusText);
        onShowMessage(jsonData.message);
      }
    } catch (err) {
      console.log("Error fetching data: " + err);
      onShowMessage(err.message);
    }
  };

  return (
    <div className={classes.user_profile}>
    {/* <img src={user.avatar} alt={`${user.name}'s avatar`} className={classes.avatar} /> */}
      {/* <FcBusinessman className={classes.icon} /> */}
      {/* <FcBusinesswoman /> */}
      {/* <h2>{user.userProfile.full_name}</h2>
      <p>{user.userProfile.email_id}</p> */}
      {/* <p>{user.register_type}</p> */}
      {/* <p>{user.bio}</p> */}

      {token ? (
        <>
        <FcBusinessman className={classes.icon} />
        {/* <h2>{user.userProfile.full_name}</h2> */}
        {/* <p>{user.userProfile.email_id}</p> */}
        <button type="submit" onClick={logoutHandler}>
          Logout
        </button>
        </>
      ) : (
        <button type="submit" onClick={() => navigate("/login")}>
          Login/Signup
        </button>
      )}
    </div>
  )
}

export default UserProfileCard;

