import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./FreeTest.module.css";
import TestSeriesUnlockOverlay from "./TestSeriesUnlockOverlay";
import check from "../images/check.png";
import axios from "axios";
import Layout from "./Layout";
import Carousel from "./Carousel";

const FreeTests = () => {
  const [testPassData, setTestPassData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedPassData, setSelectedPassData] = useState(null);

  const fetchData = async () => {
    try {
      console.log("i got called");
      const response = await axios.get(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/testpass"
      );
      setTestPassData(response.data);
    } catch (error) {
      console.error("Error fetching test pass data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleJoinTestPassChange = (passdata) => {
    setSelectedPassData(passdata);
    setIsModelOpen(true);
  };

  const handleShowMoreTestChange = (product_id) => {
    console.log("line 26", product_id);
    navigate(`/individualexamtestpasssection/${product_id}`);
  };

  const enabledIds = [121, 124, 126, 128, 129, 130, 131];
  const navigate = useNavigate();

  return (
    <>
      <Layout>
        {/* {isModelOpen && selectedPassData && (
        <TestSeriesUnlockOverlay
          passData={selectedPassData}
          handleModelChange={() => setIsModelOpen(false)}
        />
      )} */}

        <div>
          <Carousel />
        </div>

        <div className={classes.container}>
          {testPassData.map((passdata, index) => {
            const isButtonEnabled = enabledIds.includes(passdata.id);
            return (
              <div key={index} className={classes.full_card_div}>
                <div className={classes.heading_section}>
                  <h4 className={classes.describe_category}>
                    {passdata.Product_title}
                  </h4>
                </div>
                <div className={classes.card_description_section}>
                  <h5>Features</h5>
                  <div className={classes.individual_unorder_list}>
                    <ul className={classes.basic_list_item}>
                      <li style={{ display: "flex", gap: "1rem" }}>
                        <img src={check} alt="check" />
                        Included Test: {passdata.totalTests}
                      </li>
                      {passdata &&
                        passdata.description.map((item, index) => (
                          <li
                            key={index}
                            style={{ display: "flex", gap: "1rem" }}
                          >
                            <img src={check} alt="check" />
                            {item}
                          </li>
                        ))}

                      {/* <li style={{ display: "flex", gap: "1rem" }}>
                        <img src={check} alt="check" />
                        Full Mock Test based on Latest Pattern
                      </li>
                      <li style={{ display: "flex", gap: "1rem" }}>
                        <img src={check} alt="check" />
                        Sectional based on different level
                      </li>
                      <li style={{ display: "flex", gap: "1rem" }}>
                        <img src={check} alt="check" />
                        Chapterwise based on weightage
                      </li>
                      <li style={{ display: "flex", gap: "1rem" }}>
                        <img src={check} alt="check" />
                        Previous Year Included
                      </li> */}
                    </ul>
                  </div>

                  {/* <div className={classes.footer_sub_section}>
                  <p>
                    Price:{" "}
                    <span className={classes.card_price_p}>
                      ₹ {passdata.product_max_price}
                    </span>
                  </p>
                  <p> ₹ {passdata.product_price}</p>
                </div> */}

                  <div className={classes.card_footer_section}>
                    <div className={classes.card_btn_div}>
                      <button
                        className={classes.show_more_btn}
                        onClick={() => handleShowMoreTestChange(passdata.id)}
                        disabled={!isButtonEnabled}
                      >
                        Show More
                      </button>
                      {/* Uncomment if "Buy Now" functionality is needed */}
                      {/* <button
                      className={classes.buy_now_section}
                      onClick={() => handleJoinTestPassChange(passdata)}
                      disabled={!isButtonEnabled}
                    >
                      Buy Now
                    </button> */}
                    </div>
                    <div className={classes.price_sub_section}>
                      {/* Strike through price can be added here if needed */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    </>
  );
};

export default FreeTests;
