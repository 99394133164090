import React, { useEffect, useState, memo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { IoReorderThreeOutline } from "react-icons/io5";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import CountDownTimer from "./CountDownTimer";
import QuestionNavigation from "./QuestionNavigation";
import Questions from "./Questions";
import Qinstruction from "./Qinstruction";
import { MathJax, MathJaxContext } from "better-react-mathjax";

import { useAuthContext } from "../hooks/useAuthContext";
// import { addProfile } from "../redux/slices/userProfileSlice";
import { useSelector } from "react-redux";

import logo from "../images/Bubble-Preloader.gif";

import "./header1.css";
import "./mobilestyle.css";

function Header1({
  hrs,
  mins,
  secs,
  test_series_id,
  exam_mode_id,
  tier_id,
  paper_code,
  NotVisited,
  subjectID,
  subject_name,
  Answered,
  NotAnswered,
  Marked,
  MarkedAnswered,
  title,
  languages,
  pids,
  TestTp,
  exam_id,
  post_id,
  product_id,
}) {
  const hoursMinSecs = { hours: hrs, minutes: mins, seconds: secs };
  const [togglevalue, settogglevalue] = useState(false);
  const [cookies] = useCookies();
  const [paperid, setPaperid] = useState(pids);
  const [option, setOption] = useState();
  const [testType, setTestType] = useState("sectional");
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState(languages);
  const [subject, setSubject] = useState(subjectID);
  const [subjectData, setSubjectData] = useState([]);
  const [AnswerStatus, setAnswerStatus] = useState([]);
  const [AnsweredCount, setAnsweredCount] = useState(Answered);
  const [NotAnsweredCount, setNotAnsweredCount] = useState(NotAnswered);
  const [MarkedCount, setMarkedCount] = useState(Marked);
  const [MarkedAnsweredCount, setMarkedAnsweredCount] = useState(
    MarkedAnswered
  );
  const [NotVisitedCount, setNotVisitedCount] = useState(NotVisited);
  const [paper_ids, setPaper_ids] = useState();
  const [SubjectName, setSubjectName] = useState(subject_name);
  const [SingleTm, setSingleTm] = useState();
  const [show, setShow] = useState(false);
  const [pause, setPause] = useState(false);
  const [show2, setShow2] = useState(false);
  const [lastshow, setlastshow] = useState(false);
  const [rmTm, setrmTm] = useState();
  const [eidData, setEidData] = useState();
  const [PauseDetails, setPauseDetails] = useState([]);
  const [show_paper, setshow_paper] = useState(false);
  const [showQuestions, setshowQuestions] = useState(true);
  const [instructionShow, setInstructionShow] = useState(false);
  const [Next, setNext] = useState("Next");
  const [buttonType, setButtonType] = useState();
  const handle = useFullScreenHandle();
  const [theArray, setTheArray] = useState([]);
  const [FirstLetter, setFirstLetter] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [choosed_answer, setchoosed_answer] = useState();
  const [PrevValue, setPrevValue] = useState();
  const [CurrentPaperId, setCurrentPaperId] = useState();
  const [questionNavId, setQuestionNavId] = useState();
  const [lastQuestion, setLastQuestion] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const userProfile = useSelector((state) => state.userProfile);
  const { token } = useAuthContext();

  // console.log("token: " , token);
  // console.log("userProfile: " , userProfile);

  /* if (cookies.email_id) {
        history.pushState(null, null, location.href);
        window.onpopstate = function (event) {
            history.go(1);
        }
    } */

  const submitButtonRef = useRef(null);
  const onTimeUp = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click(); // Programmatically click the submit button
    }
  };

  const activeThreeoutline = (event) => {
    setIsActive((current) => !current);
  };

  const GoToFirstFun = () => {
    // console.log("GoToFirst fun subjectId", subjectID);
    setlastshow(false);
    setPaperid(1);
    // subjectData[0].sb_id
    // setSubject(subjectID);
    setSubject(subjectData[0].sb_id);
    setOption(data[0].answer ? data[0].answer : "");
  };

  const handleNoFirst = () => {
    setOption(data[paperid - 1].answer ? data[paperid - 1].answer : "");
    setlastshow(false);
  };

  const handleClose = async (e) => {
    setButtonType(e.target.value);
    setShow(false);
    setPause(!pause);
    setShow2(true);
    let paper_details = {
      // email_id: "neerajit@ssccglpinnacle.com",
      // email_id: "anshulji100@gmail.com",
      email_id: userProfile.email_id,
      paper_code: paper_code,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
    };
    let answers_result = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/answerDetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(paper_details),
      }
    );
    answers_result = await answers_result.json();
    console.log("ANSWER DETAILS", answers_result);
    setPauseDetails(answers_result);
  };

  const handleClose2 = () => {
    setShow2(false);
    setPause(!pause);
  };

  const handleCloseTest = async () => {
    let pause_details = {
      // email_id: "neerajit@ssccglpinnacle.com",
      // email_id: "anshulji100@gmail.com",
      email_id: userProfile.email_id,
      paper_code: paper_code,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
      rTem: rmTm,
      pause_question: paperid,
    };
    await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/pauseTest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(pause_details),
    });
    console.log("PAUSE DETAIL", pause_details);
    // navigate(`/testseries/neerajit@ssccglpinnacle.com`);
    // navigate(`/testseries/anshulji100@gmail.com`);
    navigate(
      `/TestSeriesUnlock/${exam_id}/${post_id}/${test_series_id}/${tier_id}/${exam_mode_id}/${product_id}`
    );
  };

  const handleCloseNo = () => setShow(false);

  const handleShow = () => setShow(true);

  ///// Updated submitTest function

  const submitTest = async () => {
    setIsSubmitting(true);

    try {
      let submit_details = {
        email_id: userProfile.email_id,
        paper_code: paper_code,
        exam_mode_id: exam_mode_id,
        test_series_id: test_series_id,
        rTem: rmTm,
        paper_id: paperid,
        SingleTm,
        subject,
      };

      console.log(submit_details);

      let submit_result = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(submit_details),
        }
      );

      submit_result = await submit_result.json();

      if (submit_result.status === "success") {
        if (TestTp === "live") {
          navigate(
            `/LiveTestResult/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
              test_series_id
            )}/${exam_id}/${post_id}/${tier_id}`
          );
        } else {
          navigate(
            `/attempted/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
              test_series_id
            )}/${title}/${tier_id}/${exam_id}/${post_id}/${product_id}`
          );
        }
      }
    } catch (e) {
      console.error("Error during submission:", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  // const submitTest = async () => {
  //   setIsSubmitting(true);

  //   try{
  //     let submit_details = {
  //       // email_id: "neerajit@ssccglpinnacle.com",
  //       email_id: userProfile.email_id,
  //       paper_code: paper_code,
  //       exam_mode_id: exam_mode_id,
  //       test_series_id: test_series_id,
  //       rTem: rmTm,
  //       paper_id: paperid,
  //       SingleTm,
  //       subject,
  //     };

  //     console.log(submit_details);

  //     let submit_result = await fetch(
  //       process.env.REACT_APP_PINNACLE_BASE_URL + "/submit",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(submit_details),
  //       }
  //     );

  //     submit_result = await submit_result.json();
  //     // console.log("SUBMIT RESULT", submit_result);

  //     if (submit_result.status == "success") {
  //       if (TestTp == "live") {
  //         // navigate(
  //         //   `/LiveTestResult/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
  //         //     test_series_id
  //         //   )}`
  //         // );
  //         navigate(
  //           `/LiveTestResult/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
  //             test_series_id
  //           )}/${exam_id}/${post_id}/${tier_id}`
  //         );
  //       } else {
  //         //  navigate(
  //         //   `/attempted/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
  //         //     test_series_id
  //         //   )}/${title}/${tier_id}`
  //         navigate(
  //           `/attempted/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
  //             test_series_id
  //           )}/${title}/${tier_id}/${exam_id}/${post_id}/${product_id}`
  //         );
  //       }
  //       setIsSubmitting(false);
  //     }
  //   } catch(e){
  //     console.error(e);
  //   }

  // };

  const toggle = () => {
    if (!togglevalue) settogglevalue(true);
    else settogglevalue(false);
  };

  const increment = async (e) => {
    let y = paperid - 1;

    // data[y].answer == '0' && option == '0' ?  data[y].answer = '0' : option == '0' && data[y].answer != '0' ? '' : data[y].answer = option

    // console.log("INCREMENT PAPERID", paperid);
    let eventId = e?.target?.id;

    setQuestionNavId(paperid + 1);
    // setQuestionNavId(paperid);

    let obj = {};
    if (parseInt(paperid) === data.length) {
      // setOption("0");
      // await answerFun(e.target.id, paperid, "nxt");
      answerFun(e.target.id, 1, "nxt");

      console.log("PAPERID IN INCREMENT", paperid);
      setPaperid(parseInt(paperid));
      answerFun(e.target.id, e.target.id, "nxt");
      // setOption(data[y].answer ? data[y].answer : "");
      setNext("Next");
      setLastQuestion(1);

      if (
        e.target.id == 1 &&
        data[y].answer == "0" &&
        (option == "" ||
          option == undefined ||
          option == 0 ||
          option == "undefined")
      ) {
        setPrevValue("2");
        obj = {
          item_no: paperid,
          Type: 2,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
      } else if (
        e.target.id == 1 &&
        (data[y].answer != "0" || option != "0") &&
        (data[y].answer != "" || option != "") &&
        (data[y].answer != "" || option != "0")
      ) {
        setPrevValue("1");
        obj = {
          item_no: paperid,
          Type: 1,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
      } else if (
        e.target.id == 4 &&
        data[y].answer == "0" &&
        (option == "0" || option == "")
      ) {
        setPrevValue("3");
        obj = {
          item_no: paperid,
          Type: 3,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
      } else if (
        e.target.id == 4 &&
        (data[y].answer != "0" || option != "0" || option == "")
      ) {
        setPrevValue("4");
        obj = {
          item_no: paperid,
          Type: 4,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
      } else {
        setPrevValue("2");
      }
      setlastshow(true);

      // data[y].answer = option;
      // console.log("CHECKING DATA[z].ANSWER UPDATION: ", data[y].answer);
      // data[y].answered_ques = PrevValue;
      // console.log(
      //   "CHECKING DATA[z].answered_ques UPDATION: ",
      //   data[y].answered_ques
      // );
    } else {
      setPaperid(parseInt(paperid) + 1);
      setOption(data[paperid].answer ? data[paperid].answer : "");
      console.log("data[paperid]", data[paperid]);
      // setOption(data[paperid].answer ? data[paperid].answer : "");
      // setOption("0");
      setLastQuestion(parseInt(paperid) + 1);
      if (
        e.target.id == 1 &&
        (data[y].answer == "0" ||
          data[y].answer == "" ||
          data[y].answer == undefined) &&
        (option == "" ||
          option == undefined ||
          option == 0 ||
          option == "undefined")
      ) {
        // console.log("PREV VALUE 2 IN IF CONDITION");
        setPrevValue("2");
        obj = {
          item_no: paperid,
          Type: 2,
          SingleTm: SingleTm,
          option: option,
        };
        // console.log("THE ARRAY UPDATE OBJECT", obj);
        setTheArray([...theArray, obj]);
      } else if (
        e.target.id == 1 &&
        (data[y].answer != "0" || option != "0") &&
        (data[y].answer != "" || option != "") &&
        (data[y].answer != "" || option != "0")
      ) {
        // console.log("PREV VALUE 1 IN ELSE IF CONDITION");
        setPrevValue("1");
        obj = {
          item_no: paperid,
          Type: 1,
          SingleTm: SingleTm,
          option: option,
        };
        // console.log("THE ARRAY UPDATE OBJECT", obj);
        setTheArray([...theArray, obj]);
      } else if (
        e.target.id == 4 &&
        // data[y].answer == "0"
        (data[y].answer == "0" || data[y].answer == "") &&
        (option == "0" || option == "")
      ) {
        setPrevValue("3");
        obj = {
          item_no: paperid,
          Type: 3,
          SingleTm: SingleTm,
          option: option,
        };
        // console.log("THE ARRAY UPDATE OBJECT", obj);
        setTheArray([...theArray, obj]);
      } else if (
        e.target.id == 4 &&
        (data[y].answer != "0" || option != "0" || option == "")
      ) {
        setPrevValue("4");
        obj = {
          item_no: paperid,
          Type: 4,
          SingleTm: SingleTm,
          option: option,
        };
        // console.log("THE ARRAY UPDATE OBJECT", obj);
        setTheArray([...theArray, obj]);
      } else {
        setPrevValue("2");
      }

      setchoosed_answer("");
      answerFun(e.target.id, parseInt(paperid) + 1, "nxt");
      setNext("Next");
      // console.log("OPTION CHECKING IN THE INCREMENT FUNCTION", option);
      data[y].answer = option;
      // console.log("CHECKING DATA[z].ANSWER UPDATION: ", data[y].answer);
      data[y].answered_ques = PrevValue;
    }
    console.log(
      "BEFORE :- OPTION CHECKING FOR 3RD & 4TH CONDITION",
      data[y].answer
    );
    console.log(
      "BEFORE :- ANSWER STATUS CHECKING FOR 3RD & 4TH CONDITION: ",
      data[y].answered_ques
    );
    data[y].answer = data[y].answer == "0" ? "" : data[y].answer;
    data[y].answered_ques = obj.Type;
    console.log(
      "AFTER :- OPTION CHECKING FOR 3RD & 4TH CONDITION",
      data[y].answer
    );
    console.log(
      "AFTER :- ANSWER STATUS CHECKING FOR 3RD & 4TH CONDITION: ",
      data[y].answered_ques
    );
  };

  const BlurAnswerValue = (prevAnswerValue) => {
    console.log("prevValue set to ", prevAnswerValue);
    setPrevValue(prevAnswerValue);
  };

  const questionNavigationFun = (e) => {
    // setPaperid(e.target.value);
    console.log("questionNavigationFun e.target.value", e.target.value);

    let z = e.target.value - 1;
    console.log("value of Z", z);
    console.log("questionNavigationFun data[z]", data[z]);

    setCurrentPaperId(e.target.value);

    if (questionNavId == e.target.value) {
    } else {
      setQuestionNavId(e.target.value);
      setOption(data[z].answer ? data[z].answer : "");

      console.log("PAPER ID IN QUESTION NAVIGATION FUNCTION", paperid);
      let tempAnswerStatus =
        (data[paperid - 1].answer == "0" || data[paperid - 1].answer == "") &&
        data[paperid - 1].answered_ques !== "3" &&
        data[paperid - 1].answered_ques !== 3
          ? "1"
          : data[paperid - 1].answered_ques === "3" ||
            data[paperid - 1].answered_ques === 3
          ? "4"
          : String(data[paperid - 1].answered_ques);
      console.log("tempAnswerStatus", tempAnswerStatus);
      answerFun(tempAnswerStatus, e.target.value, "nav");
      setPrevValue(
        // data[paperid-1].answer == "0" ? 2 : data[paperid-1].answered_ques
        // (data[paperid-1].answer == "0" || data[paperid-1].answer == "" || data[paperid-1].answered_ques != "3" || data[paperid-1].answered_ques != 3) ? "2" : String(data[paperid-1].answered_ques)
        (data[paperid - 1].answer == "0" || data[paperid - 1].answer == "") &&
          data[paperid - 1].answered_ques != "3" &&
          data[paperid - 1].answered_ques != 3
          ? "2"
          : String(data[paperid - 1].answered_ques)
      );
      const obj = {
        item_no: paperid,
        Type:
          (data[paperid - 1].answer == "0" || data[paperid - 1].answer == "") &&
          data[paperid - 1].answered_ques != "3" &&
          data[paperid - 1].answered_ques != 3
            ? "2"
            : String(data[paperid - 1].answered_ques),
        // data[paperid-1].answer == "0" ? 2 : data[paperid-1].answered_ques,
        SingleTm: SingleTm,
        option: data[paperid - 1].answer == "0" ? "" : data[paperid - 1].answer,
      };
      setTheArray([...theArray, obj]);
      console.log(
        "BEFORE :- OPTION CHECKING FOR 3RD & 4TH CONDITION",
        data[paperid - 1].answer
      );
      console.log(
        "BEFORE :- ANSWER STATUS CHECKING FOR 3RD & 4TH CONDITION: ",
        data[paperid - 1].answered_ques
      );
      data[paperid - 1].answer =
        data[paperid - 1].answer == "0" ? "" : data[paperid - 1].answer;
      console.log(
        "AFTER :- OPTION CHECKING FOR 3RD & 4TH CONDITION",
        data[paperid - 1].answer
      );
      data[paperid - 1].answered_ques = obj.Type;
      console.log(
        "AFTER :- ANSWER STATUS CHECKING FOR 3RD & 4TH CONDITION: ",
        data[paperid - 1].answered_ques
      );
      // data[paperid-1].answered_ques = data[paperid-1].answer == "0" ? 2 : data[paperid-1].answered_ques;
      // data[y].answered_ques = PrevValue;
      setPaperid(e.target.value);

      // if (
      //   // PrevValue != undefined ||
      //   // PrevValue != "1" ||
      //   // PrevValue != "2" ||
      //   // PrevValue != "3" ||
      //   // PrevValue != "4"
      //   PrevValue != undefined &&
      //   PrevValue != "1" &&
      //   PrevValue != "2" &&
      //   PrevValue != "3" &&
      //   PrevValue != "4"
      // ) {
      //   // console.log(
      //   //   "QUESTION_NAVIGATION_FUN CHECKING inside if PREV VALUE",
      //   //   PrevValue
      //   // );
      //   var PrevStatus = PrevValue.split(",").pop();
      // } else {
      //   // console.log(
      //   //   "QUESTION_NAVIGATION_FUN CHECKING else block PREV VALUE",
      //   //   PrevValue
      //   // );
      //   var PrevStatus = PrevValue;
      //   //var PrevStatus = choo;
      // }

      // if (PrevStatus == undefined || PrevStatus == "0") {
      //   answerFun("2", e.target.value, "nav");
      // } else if (PrevStatus == 1) {
      //   answerFun("1", e.target.value, "nav");
      // } else if (PrevStatus == 2) {
      //   answerFun("2", e.target.value, "nav");
      // } else if (PrevStatus == 3) {
      //   answerFun("3", e.target.value, "nav");
      // } else if (PrevStatus == 4) {
      //   answerFun("4", e.target.value, "nav");
      // }

      // if (PrevStatus == undefined || PrevStatus == "0") {
      //   const obj = {
      //     item_no: paperid,
      //     Type: 2,
      //     SingleTm: SingleTm,
      //     option: option,
      //   };
      //   // console.log("THE ARRAY OBJECT: ", obj);
      //   setTheArray([...theArray, obj]);
      // } else if (
      //   PrevStatus == 1 && lastQuestion != undefined
      //     ? data[lastQuestion - 1].answer != "0" &&
      //       data[lastQuestion - 1].answer != "" &&
      //       data[lastQuestion - 1].answer != undefined
      //     : PrevStatus == 1
      // ) {
      //   const obj = {
      //     item_no: paperid,
      //     Type: 1,
      //     SingleTm: SingleTm,
      //     option: option,
      //   };
      //   // console.log("THE ARRAY OBJECT: ", obj);
      //   setTheArray([...theArray, obj]);
      // } else if (
      //   PrevStatus == 2 && lastQuestion != undefined
      //     ? data[lastQuestion - 1].answer == "0" ||
      //       data[lastQuestion - 1].answer == ""
      //     : PrevStatus == 2
      // ) {
      //   const obj = {
      //     item_no: paperid,
      //     Type: 2,
      //     SingleTm: SingleTm,
      //     option: option,
      //   };
      //   // console.log("THE ARRAY OBJECT: ", obj);
      //   setTheArray([...theArray, obj]);
      // } else if (PrevStatus == 3) {
      //   const obj = {
      //     item_no: paperid,
      //     Type: 3,
      //     SingleTm: SingleTm,
      //     option: option,
      //   };
      //   // console.log("THE ARRAY OBJECT: ", obj);
      //   setTheArray([...theArray, obj]);
      // } else if (PrevValue == 4) {
      //   const obj = {
      //     item_no: paperid,
      //     Type: 4,
      //     SingleTm: SingleTm,
      //     option: option,
      //   };
      //   // console.log("THE ARRAY OBJECT: ", obj);
      //   setTheArray([...theArray, obj]);
      // }
      setIsActive(false);
    }

    setLastQuestion(e.target.value);
  };

  const answerFun = async (answer_status, CurrentPaperId, SaveType) => {
    console.log(`PROPS IN ANSWER_FUN
    Answer status: ${answer_status}
    CurrentPaperId: ${CurrentPaperId}
    SaveType: ${SaveType}
    `);

    let z = paperid - 1;

    let answers = {
      // email_id: "neerajit@ssccglpinnacle.com",
      // email_id: "anshulji100@gmail.com",
      email_id: userProfile.email_id,
      paper_code: paper_code,
      test_series_id: test_series_id,
      exam_mode_id: exam_mode_id,
      paper_id: paperid,
      CurrentPaperId: CurrentPaperId,
      option:
        option == "0" && data[z].answer != "0"
          ? data[z].answer
          : option == undefined
          ? ""
          : option != "0"
          ? option
          : "",
      answer_status: answer_status,
      SingleTm: SingleTm,
      rTem: rmTm,
      subject: subject,
      SaveType: SaveType,
    };

    console.log("Answers for tier 1", answers);

    // data[z].answer = option;
    // console.log("ANSWER_STATUS: ", answer_status);
    // console.log(
    //   "ANSWER_OPTIONS",
    //   option == "0" && data[z].answer != "0"
    //     ? data[z].answer
    //     : option == undefined
    //     ? ""
    //     : option != "0"
    //     ? option
    //     : ""
    // );

    // console.log("PAPER_ID IN ANSWER_FUN ", paperid);
    console.log("ANSWERS IN ANSWER_FUN", answers);
    console.log(
      "process.env.REACT_APP_PINNACLE_BASE_URL",
      process.env.REACT_APP_PINNACLE_BASE_URL
    );

    let answers_res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/save_next",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(answers),
      }
    );
    answers_res = await answers_res.json();

    // data[z].answer = option;
    // console.log("CHECKING DATA[z].ANSWER UPDATION: ", data[z].answer);
    // data[z].answered_ques = answer_status;
    // console.log("CHECKING DATA[z].answered_ques UPDATION: ", data[z].answered_ques)

    // console.log("SAVE_NEXT IN ANSWER_FUN", answers_res);
    // console.log("THE ARRAY IN ANSWER_FUN", theArray);
    setSubject(answers_res.sub_id);
    setSubjectName(answers_res.subjectname);
    setAnswerStatus(answers_res.answer_status);
    setAnsweredCount(answers_res.answered_count);
    setNotAnsweredCount(answers_res.notanswered_count);
    setMarkedCount(answers_res.marked_count);
    setMarkedAnsweredCount(answers_res.marked_answered_count);
    setNotVisitedCount(answers_res.not_visited);
    setPaper_ids(answers_res.paper_ids);
    setOption(answers_res.choosed_answer);
    //setchoosed_answer(answers_res.choosed_answer)
  };

  const chooseFun = (e) => {
    // console.log("CHOOSE FUNCTION SET OPTION", e.target);
    setOption(e.target.value);
  };

  const resetRadioState = async () => {
    setOption("");

    let c = paperid - 1;
    data[c].answer = "";
    setPrevValue("2");
    data[c].answered_ques = "2";
    console.log("data[c]", data[c]);

    const obj = {
      item_no: paperid,
      Type: 2,
      SingleTm: SingleTm,
      option: "",
    };
    setTheArray([...theArray, obj]);

    let cleardt = {
      paper_code: paper_code,
      // email_id: "neerajit@ssccglpinnacle.com",
      // email_id: "anshulji100@gmail.com",
      email_id: userProfile.email_id,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
      paper_id: paperid,
    };
    await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/clearResponse", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(cleardt),
    });

    // console.log("CLEAR RESPONSE", cleardt);
  };

  const testTypeFun = (e) => {
    const testType = e.target.value;
    setTestType(testType);
  };

  useEffect(() => {
    async function stateRes() {
      let dt = {
        paper_code: paper_code,
        // email_id: "neerajit@ssccglpinnacle.com",
        // email_id: "anshulji100@gmail.com",
        email_id: userProfile.email_id,
        exam_mode_id: exam_mode_id,
        test_series_id: test_series_id,
      };
      let state_res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/index",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dt),
        }
      );

      // console.log("INDEX", dt);
      state_res = await state_res.json();
      console.log("STATE_RES", state_res);
      setData(state_res[0]["details"]);

      setSubjectData(state_res[0]["subjects"]);

      setLoading(false);
    }

    stateRes();
  }, []);
  // console.log("data", data);

  useEffect(() => {
    async function stateRes2() {
      let dt = {
        paper_code: paper_code,
        // email_id: "neerajit@ssccglpinnacle.com",
        // email_id: "anshulji100@gmail.com",
        email_id: userProfile.email_id,
        exam_mode_id: exam_mode_id,
        test_series_id: test_series_id,
        question_no: paperid,
      };

      // console.log("STATE_RESPONSE_2", dt);

      let state_res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/pauseQuestionChoosedAnswer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dt),
        }
      );
      state_res = await state_res.json();
      // console.log("PAUSE_QUESTION_CHOOSED_ANSWER", state_res);
      //setData(state_res[0]['choosed_answer'])
      setchoosed_answer(state_res.choosed_answer);
      setOption(state_res.choosed_answer);
      setPrevValue(state_res.answer_status);
      // setSubjectName(state_res.subject_name);
      // setSubject(state_res.subject_id);
      setAnswerStatus(state_res.answer_status);
      setQuestionNavId(paperid);

      //setSubjectData(state_res[0]['subjects'])

      //setLoading(false)
    }

    stateRes2();
  }, []);

  useEffect(() => {
    async function eidReq() {
      // let eid = [{ email_id: "neerajit@ssccglpinnacle.com" }];
      let eid = [{ email_id: userProfile.email_id }];
      let eid_req = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/testName",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(eid),
        }
      );
      eid_req = await eid_req.json();
      // console.log("TESTNAME", eid_req);
      setEidData(eid_req[0]["full_name"]);
      setFirstLetter(eid_req[0]["full_name"].charAt(0));
    }

    // eidReq();

    setEidData(userProfile.full_name);
    setFirstLetter(userProfile.full_name.charAt(0));
  }, []);

  const languageFun = (e) => {
    setLanguage(e.target.value);
    setNext("Lg");
    //answerFun('2')
  };

  const subjectFun = async (e) => {
    // console.log(`value = ${e.target.value} ID = ${
    //   e.target.id
    // } DATA-ID = ${e.target.getAttribute("data-id")}
    // PAPER_CODE = ${paper_code}
    // `);
    // setCurrentPaperId(e.target.id);

    // console.log("THE ARRAY", theArray);
    // console.log("PAPERID", paperid);
    // console.log("CURRENT PAPERID", CurrentPaperId);

    setPaperid(e.target.id);
    setSubject(e.target.value);
    setSubjectName(e.target.getAttribute("data-id"));
    setOption(data[e.target.id - 1].answer);
    // console.log("CHECKING THE OPTION FROM DATA ", data[e.target.id - 1]);
    // console.log("CHECKING THE OPTION FROM DATA PAPER ID ", paperid);
    // console.log("CHECKING THE OPTION FROM DATA data[e.target.id - 1].answer ", data[e.target.id - 1].answer);

    let answers = {
      // email_id: "neerajit@ssccglpinnacle.com",
      email_id: userProfile.email_id,
      paper_code: paper_code,
      test_series_id: test_series_id,
      exam_mode_id: exam_mode_id,
      paper_id: paperid,
      CurrentPaperId: e.target.id,
      option: data[paperid - 1].answer == "0" ? "" : data[paperid - 1].answer,
      answer_status:
        data[paperid - 1].answered_ques == "0"
          ? "1"
          : data[paperid - 1].answered_ques,
      SingleTm: SingleTm,
      rTem: rmTm,
      subject: subject,
    };

    // console.log("SINGLE_TM", SingleTm);
    // console.log("OPTION", data[e.target.id - 1].answer);
    // console.log("PAPER_ID", paperid);
    // console.log("ANSWER OBJECT BEFORE SAVING", answers);

    let answers_res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/save_next",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(answers),
      }
    );

    answers_res = await answers_res.json();
    console.log("SAVE_NEXT", answers_res);
    console.log("Rendering component, paperid:", paperid, "subject:", subject);

    // console.log("ANSWER DATA FROM DATABASE", answers_res);
    setSubject(answers_res.sub_id);
    setSubjectName(answers_res.subjectname);
    setAnswerStatus(answers_res.answer_status_new);

    setAnsweredCount(answers_res.answered_count);
    setNotAnsweredCount(answers_res.notanswered_count);
    setMarkedCount(answers_res.marked_count);
    setMarkedAnsweredCount(answers_res.marked_answered_count);
    setNotVisitedCount(answers_res.not_visited);

    setOption(answers_res.choosed_answer);
    // setPrevValue("" + answers_res.answer_status_new);
    setchoosed_answer(answers_res.choosed_answer);
    setQuestionNavId(e.target.id);

    setPrevValue(
      data[paperid - 1].answer == "0" ? 2 : data[paperid - 1].answered_ques
    );
    const obj = {
      item_no: paperid,
      Type:
        data[paperid - 1].answer == "0" ? 2 : data[paperid - 1].answered_ques,
      SingleTm: SingleTm,
      option: data[paperid - 1].answer == "0" ? "" : data[paperid - 1].answer,
    };
    setTheArray([...theArray, obj]);
  };

  // console.log("CHECKING SUBJECT", subject);
  // console.log("OPTION", option);
  // console.log("PAPERID", paperid);
  // // console.log("DATA", data[paperid-1]);
  // console.log("DATA", data);
  // console.log("THE ARRAY", theArray);

  // const subjectFun = async (e) => {
  //   console.log(`value = ${e.target.value} ID = ${
  //     e.target.id
  //   } DATA-ID = ${e.target.getAttribute("data-id")}
  //   PAPER_CODE = ${paper_code}
  //   `);

  //   console.log("THE ARRAY", theArray);
  //   console.log("PAPERID", paperid);
  //   console.log("CURRENT PAPERID", CurrentPaperId);

  //   setPaperid(e.target.id);
  //   setSubject(e.target.value);
  //   setSubjectName(e.target.getAttribute("data-id"));
  //   setOption(data[e.target.id - 1].answer);
  //   console.log("CHECKING THE OPTION FROM DATA ", data[e.target.id - 1]);

  //   // let z = paperid - 1;

  //   // let answers = {
  //   //   email_id: "neerajit@ssccglpinnacle.com",
  //   //   paper_code: paper_code,
  //   //   test_series_id: test_series_id,
  //   //   exam_mode_id: exam_mode_id,
  //   //   paper_id: paperid,
  //   //   CurrentPaperId: CurrentPaperId,
  //   //   option:
  //   //     option == "0" && data[z].answer != "0"
  //   //       ? data[z].answer
  //   //       : option == undefined
  //   //       ? ""
  //   //       : option != "0"
  //   //       ? option
  //   //       : "",
  //   //   answer_status: answer_status,
  //   //   SingleTm: SingleTm,
  //   //   rTem: rmTm,
  //   //   subject: subject,
  //   //   SaveType: SaveType,
  //   // };

  //   let answers = {
  //     email_id: "neerajit@ssccglpinnacle.com",
  //     paper_code: paper_code,
  //     test_series_id: test_series_id,
  //     exam_mode_id: exam_mode_id,
  //     paper_id: paperid,
  //     CurrentPaperId: e.target.id,
  //     // CurrentPaperId: CurrentPaperId,
  //     option: option,
  //     answer_status: AnswerStatus,
  //     SingleTm: SingleTm,
  //     rTem: rmTm,
  //     subject: subject,
  //   };

  //   console.log("PAPER_ID", paperid);
  //   console.log("ANSWER OBJECT BEFORE SAVING", answers);

  //   let answers_res = await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/save_next", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(answers),
  //   });

  //   answers_res = await answers_res.json();
  //   console.log("SAVE_NEXT", answers_res);

  //   console.log("ANSWER DATA FROM DATABASE", answers_res.choosed_answer);
  //   // setOption(answers_res.choosed_answer);
  //   setAnswerStatus(answers_res.answer_status_new);
  //   setPrevValue("" + answers_res.answer_status_new);
  //   setchoosed_answer(answers_res.choosed_answer);
  //   setQuestionNavId(e.target.id);
  //   setSubject(answers_res.sub_id);
  //   setSubjectName(answers_res.subjectname);
  // };

  const sTimeFun = (sTm) => {
    setSingleTm(sTm);
  };

  const rmTimeFun = (rTm) => {
    setrmTm(rTm);
  };

  const questionPaperFun = () => {
    if (!show_paper) {
      setshow_paper(true);
      setshowQuestions(false);
      setInstructionShow(false);
    } else {
      setshow_paper(false);
      setshowQuestions(true);
      setInstructionShow(false);
    }
  };

  const instructionFun = () => {
    if (!instructionShow) {
      setshow_paper(false);
      setshowQuestions(false);
      setInstructionShow(true);
    } else {
      setshow_paper(false);
      setshowQuestions(true);
      setInstructionShow(false);
    }
  };

  // if (rmTm == "00:00:00") {

  //     submitTest();

  // }

  // useEffect(() => {
  //   if (rmTm === "00:00:00" && !isSubmitting) {
  //     submitTest();
  //   }
  // }, [rmTm, isSubmitting]);

  if (rmTm === "00:00:00" && !isSubmitting) {
    submitTest(); // Trigger paper submission when the timer reaches zero and rmTm is "00:00:00"
  }

  // console.log("Headers re-rendering", paperid);

  // console.log("Headers re-rendering ");
  // console.log("CHECKING PREVVALUE", PrevValue);
  // console.log("CHECKING OPTION", option);
  // console.log("SUBJECT DATA", subject);

  return (
    <React.Fragment>
      <FullScreen handle={handle}>
        {isLoading ? (
          <h3 style={{ textAlign: "center" }}>
            <img src={logo} alt="loading..." />
          </h3>
        ) : (
          <Row className="main-container">
            <Row className="header1 mobile-box-shadow-none mobile-padding0">
              {" "}
              {/* mobile-height-14vh*/}
              <Col
                md={1}
                className="mobile-padding0 mobile-box-shadow80808042"
                style={{ padding: "0" }}
              >
                <h2>
                  <img
                    className="mobile-pinnacle-logo"
                    src="https://ssccglpinnacle.com/images/pinnacle_logo.png"
                    alt="logo"
                  />
                </h2>
              </Col>
              <Col md={5}>
                <h2 className="papercode-tile mobile-margin-top10 mobile-text-align-center">
                  {title}
                </h2>
              </Col>
              <Col md={3}>
                <h2 className="timer mobile-timer">
                  Time Left:
                  <CountDownTimer
                    hoursMinSecs={hoursMinSecs}
                    paper_code={paper_code}
                    Paper_id={paperid}
                    pause={pause}
                    rmTimeFun={rmTimeFun}
                    onTimeUp={onTimeUp}
                  />
                </h2>
              </Col>
              <Col
                md={2}
                className="mobile-display-none"
                style={{ paddingRight: "5px" }}
              >
                <button
                  className="full-screen"
                  onClick={handle.active == false ? handle.enter : handle.exit}
                >
                  Switch Full Screen
                </button>
              </Col>
              <Col
                md={1}
                className="mobile-display-none"
                style={{ paddingLeft: "0px" }}
              >
                {handle.active == true ? (
                  ""
                ) : (
                  <button className="pause" onClick={handleShow}>
                    Pause
                  </button>
                )}
              </Col>
            </Row>

            <Row className="q-sction mobile-q-sction">
              {togglevalue == true ? (
                <Col md={12} className="ques-row fullscreenmode">
                  <Row className="header2 mobile-header2">
                    <Col md={1}>
                      <h2 className="section">SECTIONS</h2>
                    </Col>

                    {testType && testType == "full" ? (
                      <Col md={9}>
                        <button className="subject active">Full Test</button>
                      </Col>
                    ) : (
                      <Col md={9} className="mobile-subject-scroll">
                        {subjectData &&
                          // console.log("SUBJECT DATA", subjectData, subject) &&
                          subjectData.map((sbj) => (
                            <button
                              className={`subject ${
                                sbj.sb_id == subject ? "active" : ""
                              } mobile-subject`}
                              value={sbj.sb_id}
                              id={sbj.ppr_id}
                              // id="17"
                              data-id={sbj.subject_name}
                              key={sbj.ppr_id}
                              onClick={subjectFun}
                            >
                              {sbj.subject_name}
                            </button>
                          ))}
                      </Col>
                    )}
                    <Col md={2}>
                      <span style={{ fontSize: "14px" }}>View In </span>
                      <select className="lang" onChange={languageFun}>
                        <option value="English">English</option>
                        <option value="Hindi">Hindi</option>
                      </select>
                    </Col>
                  </Row>

                  <Row className="questin-div ">
                    <Col md={12} className="question-section">
                      {data &&
                        data
                          .filter((pid) => pid.qid == paperid)
                          .map((item) => {
                            return (
                              <div key={item.qid}>
                                {console.log(`
                          HEADER DATA PASSED
                          ITEM QID = ${item.qid}
                          PAPERCODE = ${paper_code} 
                          CHOOSEOPTION = ${chooseFun}
                          OPTIONITEM = ${option}
                          ITEM = ${data}
                          LANGUAGE = ${language}
                          SINGLETM = ${sTimeFun}
                          PAUSE = ${pause}
                          SUBJECT = ${subject}
                          NEXT = ${Next}
                          CHOOSED_ANSWER = ${item.answer}
                          THE_ARRAY = ${theArray}
                          `)}
                                <Questions
                                  key={item.qid}
                                  paper_code={paper_code}
                                  chooseOption={chooseFun}
                                  optionItem={option}
                                  // item_data={data}
                                  {...item}
                                  language={language}
                                  singleTm={sTimeFun}
                                  pause={pause}
                                  subject={subject}
                                  Next={Next}
                                  choosed_answer={item.answer}
                                  theArray={theArray}
                                />
                              </div>
                            );
                          })}
                    </Col>
                  </Row>

                  <Row className="bottom-div fullscreenbottom">
                    <Col>
                      <button
                        className="mark-review"
                        id="4"
                        onClick={increment}
                      >
                        Mark for Review & Next
                      </button>
                      <button
                        className="clear-response"
                        onClick={resetRadioState}
                      >
                        Clear Response
                      </button>
                      <button className="save-next" id="1" onClick={increment}>
                        Save & Next
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <button
                      className="outer-buttton-full arrow m-0 p-0"
                      onClick={toggle}
                    >
                      <FaAngleLeft />
                    </button>
                  </Row>
                </Col>
              ) : (
                showQuestions && (
                  <Col md={9} className="ques-row mobile-width100">
                    <Row className="header2 mobile-header2 ">
                      <Col md={1} className="mobile-display-none">
                        <h2 className="section">SECTIONS</h2>
                      </Col>
                      {testType && testType == "full" ? (
                        <Col md={9}>
                          <button className="subject active">Full Test</button>
                        </Col>
                      ) : (
                        <Col md={9} className="mobile-subject-scroll">
                          {subjectData &&
                            subjectData.map((sbj) => (
                              <button
                                className={`subject ${
                                  sbj.sb_id == subject ? "active" : ""
                                } mobile-subject`}
                                value={sbj.sb_id}
                                id={sbj.ppr_id}
                                // id="17"
                                data-id={sbj.subject_name}
                                key={sbj.ppr_id}
                                onClick={subjectFun}
                              >
                                {sbj.subject_name}
                              </button>
                            ))}
                        </Col>
                      )}
                      <Col md={2} className="mobile-display-none">
                        <span style={{ fontSize: "14px" }}>View In </span>
                        <select className="lang" onChange={languageFun}>
                          <option value="English">English</option>
                          <option value="Hindi">Hindi</option>
                        </select>
                      </Col>
                    </Row>
                    <Row className="questin-div">
                      <Col md={12} className="question-section">
                        {data &&
                          data
                            .filter((pid) => pid.qid == paperid)
                            .map((item) => {
                              return (
                                <Questions
                                  key={item.qid}
                                  paper_code={paper_code}
                                  chooseOption={chooseFun}
                                  optionItem={option}
                                  {...item}
                                  language={language}
                                  singleTm={sTimeFun}
                                  pause={pause}
                                  subject={subject}
                                  Next={Next}
                                  choosed_answer={item.answer}
                                  theArray={theArray}
                                />
                              );
                            })}
                      </Col>
                    </Row>
                    <Row className="bottom-div mobile-width100-perc">
                      <Col>
                        <button
                          className="mark-review mobile-font-size12 mobile-text-change"
                          id="4"
                          onClick={increment}
                        >
                          <span id="4">Mark for Review & Next</span>
                        </button>
                        <button
                          className="clear-response mobile-font-size12 mobile-text-change-clear"
                          onClick={resetRadioState}
                        >
                          <span onClick={resetRadioState}>Clear Response</span>
                        </button>
                        <button
                          className="save-next"
                          id="1"
                          onClick={increment}
                        >
                          Save & Next
                        </button>
                      </Col>
                    </Row>
                    <Row className="">
                      <button
                        className="outer-buttton mobile-display-none"
                        onClick={toggle}
                      >
                        <FaAngleRight />
                      </button>
                    </Row>
                  </Col>
                )
              )}

              <Qinstruction
                data={data}
                show_paper={show_paper}
                instructionShow={instructionShow}
                questionPaperFun={questionPaperFun}
                instructionFun={instructionFun}
                language={language}
              />

              <button
                className="mobile-display-block mobile-three-outline desktop-display-none m-0 p-0 mobile-hamburger"
                onClick={activeThreeoutline}
              >
                <IoReorderThreeOutline />
              </button>

              {togglevalue == true ? (
                ""
              ) : (
                <Col
                  md={3}
                  className={
                    isActive
                      ? "nav-plate mobile-display-block mobile-nav-plate"
                      : "nav-plate mobile-display-none"
                  }
                >
                  <Row className="navigation_plate">
                    <Col className="plate" style={{ zIndex: "1000" }}>
                      <QuestionNavigation
                        qno={data}
                        questionNavigationfun={questionNavigationFun}
                        paper_code={paper_code}
                        full_name={eidData}
                        FirstLetter={FirstLetter}
                        subject={subject}
                        SubjectName={SubjectName}
                        test_type={testTypeFun}
                        testType={testType}
                        AnswerStatus={AnswerStatus}
                        AnsweredCount={AnsweredCount}
                        NotAnsweredCount={NotAnsweredCount}
                        MarkedCount={MarkedCount}
                        MarkedAnsweredCount={MarkedAnsweredCount}
                        NotVisitedCount={NotVisitedCount}
                        paperid={paperid ? paperid : 0}
                        theArray={theArray}
                        BlurAnswerValue={BlurAnswerValue}
                      />

                      <Row className="submit-colum ">
                        <Col className="p-0">
                          <button onClick={questionPaperFun}>
                            Question Paper
                          </button>
                        </Col>
                        <Col className="p-0">
                          <button onClick={instructionFun}>Instructions</button>
                        </Col>

                        <Row className="submit-test p-0">
                          <Col className="p-0">
                            {handle.active == true ? (
                              ""
                            ) : (
                              <button
                                onClick={handleClose}
                                value="submit"
                                ref={submitButtonRef}
                              >
                                Submit Test
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Row>
        )}

        <Modal size="md" backdrop="static" show={show}>
          <Modal.Body className="are-you-sure">
            Are you sure you want to pause this test?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseNo}>
              No
            </Button>
            <Button
              variant="primary"
              className="pause-yes"
              onClick={handleClose}
              value="pause"
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/**/}
        <Modal size="lg" backdrop="static" show={show2} onHide={handleClose2}>
          <Modal.Body>
            <Table bordered>
              <thead className="model-thead">
                <tr>
                  <th>Section</th>
                  <th>No. of Questions</th>
                  <th>Answered</th>
                  <th>Not Answered</th>
                  <th>Marked for Review</th>
                  <th>Not Visited</th>
                </tr>
              </thead>
              <tbody className="model-tbody">
                {PauseDetails &&
                  PauseDetails.map((dt, i) => (
                    <tr key={i}>
                      <td>{dt.subject_name}</td>
                      <td>{dt.questions}</td>
                      <td>{dt.answered}</td>
                      <td>{dt.notanswered_count}</td>
                      <td>{dt.marked_answered_count}</td>
                      <td>{dt.not_visited}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
          {buttonType == "submit" || buttonType == "" ? (
            <Modal.Footer>
              <Button
                variant="secondary"
                className="go-to-submit-test"
                onClick={handleClose2}
                disabled={isSubmitting}
              >
                Close
              </Button>
              <Button
                // variant={isSubmitting? "disabled": "primary"}
                className="resume-test"
                onClick={submitTest}
                disabled={isSubmitting}
              >
                {/* Submit */}
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Modal.Footer>
          ) : (
            <Modal.Footer>
              <Button
                variant="secondary"
                className="go-to-test"
                onClick={handleCloseTest}
              >
                Tests
              </Button>
              <Button
                variant="primary"
                className="resume-test"
                onClick={handleClose2}
              >
                Resume Test
              </Button>
            </Modal.Footer>
          )}
        </Modal>

        <Modal size="md" backdrop="static" show={lastshow}>
          <Modal.Body className="are-you-sure" style={{ fontSize: "14px" }}>
            You have reached the last question of the exam. Do you want to go to
            the first question ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleNoFirst}>
              No
            </Button>
            <Button
              variant="primary"
              className="pause-yes"
              onClick={GoToFirstFun}
              value="pause"
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </FullScreen>
    </React.Fragment>
  );
}
export default memo(Header1);
