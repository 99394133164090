import React from "react";
import { useNavigate } from "react-router-dom";
// import {  useSubmit } from "react-router-dom"; // Form, json, redirect, useNavigate,
import { GoogleLogin } from "@react-oauth/google"; //  useGoogleLogin

import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { useAuthContext } from "../../hooks/useAuthContext";

import { addProfile } from "../../redux/slices/userProfileSlice";

const cookies = new Cookies();

const GoogleAuth = () => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { dispatch } = useAuthContext();

  //   const submit = useSubmit();
  const googleSubmitHandler = async (data) => {
    const authData = {
      idToken: data.credential,
    };

    console.log(authData);

    const response = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/google-login",
      {
        method: "POST",
        headers: {
          // 'Authorization': 'Bearer ' + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authData),
      }
    );

    const resData = await response.json();
    console.log("resData", resData);

    // if (response.status >= 400 && response.status < 500) {
    //   // response.status >= 400 && response.status <= 500  // response.status === 422 || response.status === 401
    //   return response;
    // }

    if (!response.ok) {
      console.error(resData);

      // throw json({message: "Could not authenticate user"}, {status: 500});
      // throw new Response(JSON.stringify({ message: "could not fetch events" }), {
      //   status: 500,
      // });
    }

    if (resData.token) {
      const token = resData.token;
      // console.log(`token: ${token}`);

      // localStorage.setItem("userData", JSON.stringify(resData.data));
      // localStorage.setItem("token", token);
      // const expiration = new Date();
      const expiration = new Date(Date.now() + 1000 * 60 * 60 * 24 * 60);
      expiration.setHours(expiration.getHours() + 24);
      // localStorage.setItem("expiration", expiration.toISOString());
      cookies.set("token", token, {
        expires: expiration,
        secure: true,
        domain: ".ssccglpinnacle.com",
        sameSite: "None",
        path: "/",
      });

      cookies.set("userData", JSON.stringify(resData.data), {
        expires: expiration,
        secure: true,
        domain: ".ssccglpinnacle.com",
        sameSite: "None",
        path: "/",
      });

      // const cookieOptions = {
      //   expires: expiration, // Set expiration for cookies
      //   secure: process.env.NODE_ENV === "production",
      //   sameSite: process.env.NODE_ENV === "production" ? "None" : "Lax",
      //   domain:
      //     process.env.NODE_ENV === "production"
      //       ? ".ssccglpinnacle.com"
      //       : "localhost",
      //   path: "/",
      // };

      console.log("Environment:", process.env.NODE_ENV);
      // console.log("Setting token cookie:", token, cookieOptions);
      // console.log("Setting userData cookie:", resData.data, cookieOptions);

      // cookies.set("token", token, cookieOptions);
      // cookies.set("userData", JSON.stringify(resData.data), cookieOptions);

      // cookies.set("expiration", expiration.toISOString(), cookieOptions);
      // dispatch login action
      console.log("GOOGLE AUTH ADD PROFILE IN REDUX", resData.data);
      reduxDispatch(addProfile(resData.data));
      // dispatch({ type: "LOGIN", payload: { token: resData.token } });
      dispatch({ type: "LOGIN", payload: resData.token });
      setTimeout(() => {
        navigate("/");
      }, 5000);
    } else {
      reduxDispatch(addProfile(resData.data));
      navigate("/mobile-verification");
    }
  };
  return (
    <>
      <GoogleLogin
        // type="icon"
        // theme="filled_black"
        size="large"
        text="continue_with"
        onSuccess={(credentialResponse) => {
          //   submit(credentialResponse, { method: "post" });
          googleSubmitHandler(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </>
  );
};
export default GoogleAuth;

// import React from "react";
// import { GoogleLogin } from "@react-oauth/google";
// import { useNavigate } from "react-router-dom";

// const GoogleAuth = () => {
//   const navigate = useNavigate();

//   const googleSubmitHandler = async (data) => {
//     try {
//       const authData = {
//         idToken: data.credential,
//       };

//       const response = await fetch("http://localhost:8080/api/v1/users/google-login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(authData),
//       });

//       // if (response.status >= 400 && response.status < 500) {
//       //   console.error("Client-side error:", response);
//       //   return;
//       // }

//       // if (!response.ok) {
//       //   throw new Error("Could not authenticate user");
//       // }

//       const resData = await response.json();
//       const token = resData.token;

//       console.log(`RESPONSE`, response);
//       console.log("RES_DATA", resData);
//       console.log("TOKEN", token);

//       // localStorage.setItem("token", token);
//       // const expiration = new Date();
//       // expiration.setHours(expiration.getHours() + 24);
//       // localStorage.setItem("expiration", expiration.toISOString());

//       // navigate("/");
//     } catch (error) {
//       console.error("Error during Google login:", error);
//     }
//   };

//   return (
//     <>
//       <GoogleLogin
//         theme="filled_black"
//         size="medium"
//         text="continue_with"
//         onSuccess={googleSubmitHandler}
//         onError={() => {
//           console.log("Login Failed");
//         }}
//       />
//     </>
//   );
// };

// export default GoogleAuth;
