
// import React, { useEffect, useState } from 'react';
// import classes from "./ErrorRecord.module.css";
// import axios from 'axios';

// const ErrorRecord = () => {
//   const [errorRecord, setErrorRecord] = useState([]);
//   const [fullName, setFullName] = useState([]);
//   const [examMode, setExamMode] = useState([]);
//   const [totalPages, setTotalPages] = useState(1); // Total number of pages
//   const [currentPage, setCurrentPage] = useState(1); // Current page
//   const [rowsPerPage, setRowsPerPage] = useState(10); // Number of records per page
//   const [totalRecords, setTotalRecords] = useState(0); // Total number of records from API
//   const token = 'your_token_here'; // Replace with actual token
//   const visiblePages = 5; // Number of page buttons visible at a time

//   // Function to fetch paginated error records
//   const fetchErrorRecordStudents = async (page = 1) => {
//     try {
//       const response = await axios.get(
//         "http://localhost:5000/errorRecord",
//         {
//           params: { page, limit: rowsPerPage },
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setErrorRecord(response.data.errorrecordStudent); // Set error records
//       setFullName(response.data.studentData); // Set student full names
//       setExamMode(response.data.examModeName); // Set exam mode data
//       setTotalPages(Math.ceil(response.data.totalRecords / rowsPerPage)); // Calculate total pages
//       setTotalRecords(response.data.totalRecords); // Set total number of records

//     } catch (error) {
//       console.error("Error fetching error report data:", error);
//     }
//   };

//   // Fetch the error record data when the component loads, rowsPerPage, or currentPage changes
//   useEffect(() => {
//     fetchErrorRecordStudents(currentPage);
//   }, [currentPage, rowsPerPage]);

//   // Function to handle page change
//   const handlePageChange = (page) => {
//     setCurrentPage(page); // Set the current page and trigger data fetching
//   };

//   // Function to handle next and previous buttons
//   const handleNextPage = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   // Handle changing rows per page
//   const handleRowsPerPageChange = (event) => {
//     setRowsPerPage(parseInt(event.target.value));
//     setCurrentPage(1); // Reset to the first page when rows per page changes
//   };

//   // Pagination logic with ellipsis
//   const getVisiblePages = () => {
//     const pageNumbers = [];
//     const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
//     const endPage = Math.min(totalPages, startPage + visiblePages - 1);

//     if (startPage > 1) {
//       pageNumbers.push(1);
//       if (startPage > 2) {
//         pageNumbers.push('...');
//       }
//     }

//     for (let i = startPage; i <= endPage; i++) {
//       pageNumbers.push(i);
//     }

//     if (endPage < totalPages) {
//       if (endPage < totalPages - 1) {
//         pageNumbers.push('...');
//       }
//       pageNumbers.push(totalPages);
//     }

//     return pageNumbers;
//   };

//   // Function to format the date and time
//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleString(); // Will show date and time in a localized format
//   };

//   return (
//     <>
//       <div className={classes.container}>
//         <div className={classes.report_error_div}>
//           <h1 className={classes.report_error_h1}>Report Error List</h1>
//         </div>

//         {/* Rows per page dropdown */}
//         <div className={classes.rows_per_page}>
//           <label htmlFor="rowsPerPage">Show </label>
//           <select
//             id="rowsPerPage"
//             value={rowsPerPage}
//             onChange={handleRowsPerPageChange}
//           >
//             <option value={5}>5</option>
//             <option value={10}>10</option>
//             <option value={20}>20</option>
//             <option value={50}>50</option>
//           </select>
//           <span> entries</span>
//         </div>

//         {/* Table */}
//         <div>
//           <table className={classes.ErrorRecord_table}>
//             <thead className={classes.ErrorRecord_thead}>
//               <tr className={classes.ErrorRecord_tr}>
//                 <th>ID</th>
//                 <th>Email ID</th>
//                 <th>Paper Code</th>
//                 <th>Exam Mode Name</th>
//                 <th>Question Number</th>
//                 <th>Report Type</th>
//                 <th>Reports</th>
//                 <th>Date</th>
//               </tr>
//             </thead>
//             <tbody>
//               {errorRecord.map((row) => (
//                 <tr key={row.id}>
//                   {/* Truncate ID to 3 characters and append '...' */}
//                   <td>{row.id.slice(0, 3)}...</td>
//                   <td>{row.email_id}</td>
//                   <td>{row.paper_code}</td>
//                   <td>{row.exam_mode}</td>
//                   <td>{row.question_no}</td>
//                   <td>{row.reports_type}</td>
//                   <td>{row.reports}</td>
//                   {/* Format the date to only show date and time */}
//                   <td>{formatDate(row.ts)}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {/* Pagination Controls */}
//         <div className={classes.pagination}>
//           <button onClick={handlePrevPage} disabled={currentPage === 1}>
//             Previous
//           </button>

//           {getVisiblePages().map((pageNumber, index) => (
//             <button
//               key={index}
//               onClick={() => handlePageChange(pageNumber)}
//               className={currentPage === pageNumber ? classes.active : ''}
//               disabled={pageNumber === '...'}
//             >
//               {pageNumber}
//             </button>
//           ))}

//           <button onClick={handleNextPage} disabled={currentPage === totalPages}>
//             Next
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ErrorRecord;




import React, { useEffect, useState } from 'react';
import classes from "./ErrorRecord.module.css";
import axios from 'axios';
import Cookies from "universal-cookie";

const ErrorRecord = () => {
  const [errorRecord, setErrorRecord] = useState([]);
  const [fullName, setFullName] = useState([]);
  const [examMode, setExamMode] = useState([]);
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of records per page
  const [totalRecords, setTotalRecords] = useState(0); // Total number of records from API

  // const [selectedExamMode, setSelectedExamMode] = useState('');
  // const [selectedPaperCode, setSelectedPaperCode] = useState('');
 
  const cookies = new Cookies();
  const token = cookies.get("token");

  // Log the token to check if it exists
  console.log("Token:", token);
  const visiblePages = 5; // Number of page buttons visible at a time

  // const fetchReportedQuestionData = async () => {
  //   try {
  //     const response = await axios.post(
  //       'http://localhost:5000/reportedquestion',
  //     )
  //   } catch (error) {
  //     console.log("Error Reported Question:", error);
  //   }
  // }


  // Function to fetch paginated error records
  const fetchErrorRecordStudents = async (page = 1) => {
    try {
      const response = await axios.get(
        // "http://localhost:5000/errorRecord",
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/errorRecord`,
        {
          params: { page, limit: rowsPerPage },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setErrorRecord(response.data.errorrecordStudent); // Set error records
      setFullName(response.data.studentData); // Set student full names
      setExamMode(response.data.examModeName); // Set exam mode data
      setTotalPages(Math.ceil(response.data.totalRecords / rowsPerPage)); // Calculate total pages
      setTotalRecords(response.data.totalRecords); // Set total number of records

    } catch (error) {
      console.error("Error fetching error report data:", error);
    }
  };

  // Fetch the error record data when the component loads, rowsPerPage, or currentPage changes
  useEffect(() => {
    fetchErrorRecordStudents(currentPage);
  }, [currentPage, rowsPerPage]);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page); // Set the current page and trigger data fetching
  };

  // Function to handle next and previous buttons
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle changing rows per page
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset to the first page when rows per page changes
  };

  // Pagination logic with ellipsis
  const getVisiblePages = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    const endPage = Math.min(totalPages, startPage + visiblePages - 1);

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  // Function to format the date and time
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Will show date and time in a localized format
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.report_error_div}>
          <h1 className={classes.report_error_h1}>Report Error List</h1>
        </div>

        {/* Rows per page dropdown */}
        <div className={classes.rows_per_page}>
          <label htmlFor="rowsPerPage">Show </label>
          <select
            id="rowsPerPage"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          <span> entries</span>
        </div>

        {/* Table */}
        <div>
          <table className={classes.ErrorRecord_table}>
            <thead className={classes.ErrorRecord_thead}>
              <tr className={classes.ErrorRecord_tr}>
                <th>ID</th>
                <th>Email ID</th>
                <th>Paper Code</th>
                <th>Exam Mode Name</th>
                <th>Question Number</th>
                <th>Report Type</th>
                <th>Reports</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {errorRecord.map((row) => (
                <tr key={row.id}>
                  {/* Truncate ID to 3 characters and append '...' */}
                  <td>{row.id.slice(0, 3)}...</td>
                  <td>{row.email_id}</td>
                  <td>{row.paper_code}</td>
                  <td>{row.exam_mode}</td>
                  <td>{row.question_no}</td>
                  <td>{row.reports_type}</td>
                  <td>{row.reports}</td>
                  {/* Format the date to only show date and time */}
                  <td>{formatDate(row.ts)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className={classes.pagination}>
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>

          {getVisiblePages().map((pageNumber, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(pageNumber)}
              className={currentPage === pageNumber ? classes.active : ''}
              disabled={pageNumber === '...'}
            >
              {pageNumber}
            </button>
          ))}

          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default ErrorRecord;




