import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./component/Home";
import Payment from "./component/Payment";
import TestSeries from "./component/TestSeries";
import TestSeriesSingle from "./component/TestSeriesSingle";
import TestseriesList from "./component/TestseriesList";
import TestSeriesUnlock from "./component/TestSeriesUnlock";
import PaymentButton from "./component/PaymentButton";
import Instruction from "./component/Instruction";
import Attempted from "./component/Attempted";
import Solution from "./component/Solution";
import InstructionNew from "./component/InstructionNew";
import Checkout from "./component/Checkout";
import Test from "./component/Test";
import Index from "./admin/Index";
// import CleverTap from "clevertap-web-sdk";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import Return from "./component/Return";
// import Notify from "./component/Notify";
import IndividualTestPass from "./component/IndividualTestPass";
import IndividualExamTestPassBuy from "./component/IndividualExamTestPassBuy";
// import IndividualExamTestPass from "./component/IndividualExamTestPass";
// import SSCExamTestPass from "./component/SSCExamTestPass";
// import RailwayExamTestPass from "./component/RailwayExamTestPass";
// import DelhiPoliceExamTestPass from "./component/DelhiPoliceExamTestPass";
import PreviousYearQuestionPaper from "./component/PreviousYearQuestionPaper";
import PreviousYearExams from "./component/PreviousYearExams";
// import useCleverTap from "./useCleverTap";

import LineGraph from "./LineGraph";
import SscCglTier1 from "./component/SscCglTier1";
// import SscCglTier2 from "./component/SscCglTier2";
// import BaseOnMatricInter from "./component/BaseOnMatricInter";
// import IndividualTestSeriesTierCard from "./component/IndividualTestSeriesTierCard";
import IndividualExamPostCard from "./component/IndividualExamPostCard";
import IndividualExamPostTierTestSeriesCard from "./component/IndividualExamPostTierTestSeriesCard";
import ExamModeCard from "./component/ExamModeCard";
import MyOrders from "./component/MyOrders";
import UserProfile from "./component/UserProfileCard";

// import Home from "./Pages/Home";
import Login from "./Pages/Login";
import VerifiedEmail from "./Pages/VerifiedEmail";
import EmailVerification from "./Pages/EmailVerification";
import MobileVerification from "./Pages/MobileVerification";
import SetPassword from "./Pages/SetPassword";
import AuthContextProvider from "../src/context/AuthContext";

import { useAuthContext } from "./hooks/useAuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { LiveChatWidget } from "@livechat/widget-react"; // Import the LiveChatWidget
import { useState } from "react";
import UnAuthroized from "./UnAuthorized";
import RoleBasedAccess from "./RoleBasedRoute";
import FreeTest from "./component/FreeTest";
import TestSeriesUnlockSection from "./component/TestSeriesUnlockSection";
import InstructionSection from "./component/InstructionSection";
import InstructionSectionNew from "./component/InstructionSectionNew";
import InstructionSectionMts from "./component/InstructionSectionMts";
import InstructionSectionNewMts from "./component/InstructionSectionNewMts";
import TestSection from "./component/TestSection";
import TestSectionMts from "./component/TestSectionMts";
import AttemptedSection from "./component/AttemptedSection";
import SolutionSection from "./component/SolutionSection";
import MyTestSeries from "./component/MyTestSeries";
import TermCondition from "./component/TermCondition";
import AboutUsPage from "./component/AboutUsPage";
import PrivacyPolicy from "./component/PrivacyPolicy";
import RefundPolicy from "./component/RefundPolicy";
import ContactUs from "./component/ContactUs";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import PaymentSuccess from "./component/PaymentSuccess";
import { useEffect } from "react";

function App() {
  const { token, loading } = useAuthContext();

  // useEffect(() => {
  //   try {
  //     // Initialize CleverTap
  //     CleverTap.init("94R-Z4K-RK7Z", { region: "global" });
  //     console.log("CleverTap Initialized Successfully");

  //     // Make clevertap global for debugging
  //     window.clevertap = CleverTap;

  //     // Push a test event
  //     CleverTap.event.push("App Opened", { debug: true });

  //     // Update state to reflect successful initialization
  //     setCleverTapInitialized(true);
  //   } catch (err) {
  //     console.error("CleverTap Initialization Error:", err);
  //   }
  // }, []);

  // function CleverTapTracker() {
  //   const [cleverTapInitialized, setCleverTapInitialized] = useState(false);
  //   const location = useLocation();

  //   useEffect(() => {
  //     if (!cleverTapInitialized) {
  //       try {
  //         // Initialize CleverTap
  //         CleverTap.init(
  //           { id: "84R-Z4K-RK7Z" }, // Replace with your CleverTap Account ID
  //           { region: "global" }, // Replace with your region, if not global
  //           { TARGET_DOMAIN: ".ssccglpinnacle.com" } // Use the correct target domain
  //         );

  //         console.log("CleverTap Initialized Successfully");

  //         // Push test events
  //         CleverTap.event.push("Test Event", { debug: true });
  //         console.log("Test Event Pushed");
  //         console.log("Cookies After Initialization:", document.cookie);
  //         CleverTap.event.push("Page Viewed", { pageName: location.pathname });

  //         // Update initialization state
  //         setCleverTapInitialized(true);
  //         window.clevertapInitialized = true;
  //       } catch (error) {
  //         console.error("CleverTap Initialization Error:", error);
  //       }
  //     } else {
  //       // Track page views for subsequent navigations
  //       CleverTap.event.push("Page Viewed", { pageName: location.pathname });
  //       console.log("Page Viewed Event Sent:", location.pathname);
  //     }
  //   }, [location.pathname, cleverTapInitialized]);
  // }

  // const location = useLocation(); // Get the current location

  // // List the paths where you do not want to show the LiveChat widget
  // const hideChatPaths = ["/test", "/testsection"];

  // // Check if the current path includes any of the specified paths
  // const shouldHideChat = hideChatPaths.some((path) =>
  //   location.pathname.startsWith(path)
  // );

  console.log(token);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="spinner"></div>
        <h1>LOADING...</h1>
      </div>
    );
  }

  return (
    <>
      <GoogleOAuthProvider clientId="503062323805-hltkq848u9d8m3kp8led9oeaetribetr.apps.googleusercontent.com">
        <BrowserRouter>
          {/* Render the LiveChatWrapper here 
        <LiveChatWrapper />
        */}
          {/* <CleverTapTracker /> */}

          <Routes>
            {/*
            <Route exact path="/" element={token ? <Home />: <Navigate to="/login" />} />
            */}
            <Route exact path="/" element={<Home />} />
            <Route path="/rolebased" element={<RoleBasedAccess />} />
            <Route
              exact
              path="/login"
              element={token ? <Navigate to="/" /> : <Login />}
            />
            <Route
              exact
              path="/email-verification/:user_id/:verification_token"
              element={!token ? <VerifiedEmail /> : <Navigate to="/" />}
            />
            <Route
              exact
              path="/email-verification"
              element={!token ? <EmailVerification /> : <Navigate to="/" />}
            />
            <Route
              exact
              path="/mobile-verification"
              element={!token ? <MobileVerification /> : <Navigate to="/" />}
            />
            <Route
              exact
              path="/set-password"
              element={!token ? <SetPassword /> : <Navigate to="/" />}
            />
            <Route exact path="/freetest" element={<FreeTest />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/testseries" element={<TestSeries />} />
            <Route
              exact
              path="/individualexamtestpasssection/:product_id"
              element={<IndividualTestPass />}
            />
            <Route
              exact
              path="/individualexamtestpassbuy/:product_id"
              element={<IndividualExamTestPassBuy />}
            />
            <Route exact path="/myorders" element={<MyOrders />} />
            <Route path="/mytestseries" element={<MyTestSeries />} />
            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            {/* <Route
              exact
              path="/individualtestseriestierdata/:exam_id/:post_id"
              element={<IndividualExamPostCard />}
            /> */}
            {/* <Route
              exact
              path="/testseriessingle/:exam_id/:post_id/:tier_id"
              element={<TestSeriesSingle />}
            /> */}
            <Route
              path="/testseriessingle/:exam_id/:post_id/:tier_id/:product_id"
              element={<TestSeriesSingle />}
            />
            {/* <Route
              exact
              path="/testserieslist/:exam_mode_id/:tier_id"
              element={<TestseriesList />}
            /> */}
            <Route
              exact
              path="/TestseriesList/:exam_id/:post_id/:tier_id/:exam_mode_id/:product_id"
              element={<TestseriesList />}
            />
            {/* <Route
              exact
              path="/TestSeriesUnlock/:exam_id/:post_id/:test_series_id/:exam_post_id/:exam_mode_id"
                            // /test/English/831/1/2/1/2/1/1/121
                            // http://localhost:3000/test/RW5nbGlzaA==/U1NDIENHTCAyMDIzIEZ1bGwgTW9jayAwMQ==/ODMx/Mg==/MQ==/Mg==/1/1/121
                                                              http://localhost:3000/test/RW5nbGlzaA==/MzIyNQ==/MQ==/Mg==/Mw==/1/1/1/121
              element={<TestSeriesUnlock />}
            /> */}
            <Route
              exact
              path="/TestSeriesUnlock/:exam_id/:post_id/:test_series_id/:TierID/:exam_mode_id/:product_id"
              element={<TestSeriesUnlock />}
            />
            <Route
              path="/TestSeriesUnlockSection/:exam_id/:post_id/:test_series_id/:TierID/:exam_mode_id/:product_id"
              element={<TestSeriesUnlockSection />}
            />
            {/* <Route
              path="/instruction/:exam_mode_id/:papercode/:test_series_id/:test_title/:tier_id"
              element={<Instruction />}
            /> */}
            <Route
              path="/instruction/:exam_mode_id/:papercode/:test_series_id/:test_title/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <Instruction /> : <Navigate to="/login" />}
            />
            <Route
              path="/InstructionNew/:exam_mode_id/:papercode/:test_series_id/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <InstructionNew /> : <Navigate to="/login" />}
            />{" "}
            *
            <Route
              path="/instructionsection/:exam_mode_id/:papercode/:test_series_id/:test_title/:tier_id/:exam_id/:post_id/:product_id"
              element={
                token ? <InstructionSection /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/InstructionSectionNew/:exam_mode_id/:papercode/:test_series_id/:test_title/:tier_id/:exam_id/:post_id/:product_id"
              element={
                token ? <InstructionSectionNew /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/instructionmtssection/:exam_mode_id/:papercode/:test_series_id/:test_title/:tier_id/:exam_id/:post_id/:product_id"
              element={
                token ? <InstructionSectionMts /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/InstructionSectionNewMts/:exam_mode_id/:papercode/:test_series_id/:test_title/:tier_id/:exam_id/:post_id/:product_id"
              element={
                token ? <InstructionSectionNewMts /> : <Navigate to="/login" />
              }
            />
            {/* <Route
              path="/Test/:language/:papercode/:exam_mode_id/:test_series_id/:paperids/:tier_id"
              element={<Test />}
            /> */}
            <Route
              path="/test/:language/:papercode/:exam_mode_id/:test_series_id/:paperids/:tier_id/:exam_id/:post_id/:product_id"
              element={
                token ? (
                  <MathJaxContext>
                    <Test />{" "}
                  </MathJaxContext>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/testsection/:language/:papercode/:exam_mode_id/:test_series_id/:paperids/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <TestSection /> : <Navigate to="/login" />}
            />
            <Route
              path="/testmtssection/:language/:papercode/:exam_mode_id/:test_series_id/:paperids/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <TestSectionMts /> : <Navigate to="/login" />}
            />
            <Route
              path="/attempted/:exam_mode_id/:paper_code/:test_series_id/:title/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <Attempted /> : <Navigate to="/login" />}
            />
            <Route
              path="/attemptedsection/:exam_mode_id/:paper_code/:test_series_id/:title/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <AttemptedSection /> : <Navigate to="/login" />}
            />
            <Route
              path="/attemptedmtssection/:exam_mode_id/:paper_code/:test_series_id/:title/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <AttemptedSection /> : <Navigate to="/login" />}
            />
            {/* <Route
              path="/Solution/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID/:tier_id"
              element={<Solution />}
            /> */}
            <Route
              path="/Solution/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <Solution /> : <Navigate to="/login" />}
            />
            <Route
              path="/solutionsection/:exam_mode_ids/:papercode/:testseriesid/:testtitle/:SubjectID/:tier_id/:exam_id/:post_id/:product_id"
              element={token ? <SolutionSection /> : <Navigate to="/login" />}
            />
            {/* <Route
              exact
              path="/individualexamtestpasssection/:exam_id"
              element={<IndividualExamTestPass />}
            /> */}
            {/* <Route
              exact
              path="/home/sscexamtestpass"
              element={<SSCExamTestPass />}
            /> */}
            <Route
              exact
              path="/home/ssccgltier/:tierId"
              element={<SscCglTier1 />}
            />
            <Route
              exact
              path="/home/basedonexammode/:exam_mode_id"
              element={<ExamModeCard />}
            />
            {/* <Route
              exact
              path="/home/baseonmatric10+2"
              element={<BaseOnMatricInter />}
            /> */}
            <Route path="/termandcondition" element={<TermCondition />} />
            <Route path="/aboutus" element={<AboutUsPage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/RefundPolicy" element={<RefundPolicy />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route
              exact
              path="/home/previousyearquestionspaper"
              element={<PreviousYearQuestionPaper />}
            />
            <Route
              exact
              path="/home/previousyearexams"
              element={<PreviousYearExams />}
            />
            <Route exact path="/checkout" element={<Checkout />} />
            {/* <Route exact path="/admin/*" element={<Index />} /> */}
            <Route path="/admin/*" element={<Index />} />
            <Route path="/paymentbutton" element={<PaymentButton />} />
            <Route path="/rolebased" element={<RoleBasedAccess />} />
            <Route exact path="/home/useraccount" element={<UserProfile />} />
            <Route path="/unauthorized" element={<UnAuthroized />} />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
}

// function LiveChatWrapper() {
//   const location = useLocation(); // Get the current location

//   // List the paths where you do not want to show the LiveChat widget
//   const hideChatPaths = [
//     "/test",
//     "/testsection",
//   ];

//   // Check if the current path includes any of the specified paths
//   const shouldHideChat = hideChatPaths.some(path => location.pathname.startsWith(path));

//   return !shouldHideChat && <LiveChatWidget license="9452665" visibility="maximized"/>; // Conditionally render LiveChat
// }

export default App;
