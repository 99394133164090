

import { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

import Platform from '../component/loginComponent/Platform';
import './Login.css';
import DummyComponent from '../component/loginComponent/DummyComponent';
import LoginComponent from '../component/loginComponent/LoginComponent';

const Login = () => {
    const [isRegister, setIsRegister] = useState(true); // State to toggle between register and login forms

    return (
        <Container fluid className='parent-div'>
            <Row >
                <Col xs={12} lg={6}>
                    <Platform />
                </Col>
                <Col xs={12} lg={6}>
                    <div className='login-form'>
                        <Row>
                            <Col>
                                <h2>Get started with Pinnacle</h2>
                            </Col>
                        </Row>
                        <Row className="nav-buttons">
                            <Col className={`nav-item ${isRegister ? 'active' : ''}`}>
                                <Button variant="link" className='register-btn' onClick={() => setIsRegister(true)}>Register</Button>
                            </Col>
                            <Col className={`nav-item ${!isRegister ? 'active' : ''}`}>
                                <Button variant="link" className='login-btn' onClick={() => setIsRegister(false)}>Login</Button>
                            </Col>
                        </Row>
                        {isRegister ? (
                            <DummyComponent />
                        ) : (
                            <LoginComponent />
                            /*
                            <Form >
                                <Form.Group controlId="formLogin" className='form-div'>
                                    <Form.Label>Enter your email or mobile number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email or mobile number"
                                        className='input-field'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        className='input-field'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>
                                <div className="text-right">
                                    <Button variant="link" onClick={handleForgotPassword} className='forgot-password-btn'>Forgot Password?</Button>
                                </div>
                                <Button variant="primary" onClick={handleLogin} className='login-submit-btn'>Login</Button>
                                <Button variant="link" className='login-with-gmail-btn'>Login with Gmail</Button>
                            </Form>
                            */
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;








// import { useState } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// // import 'bootstrap/dist/css/bootstrap.min.css';

// import './Login.css';
// import Platform from '../Component/Platform';

// const Login = () => {
//     const [isRegister, setIsRegister] = useState(true); // State to toggle between register and login forms
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');

//     const handleOTP = () => {
//         // Functionality for OTP
//         console.log("OTP functionality");
//     };

//     const handleEmailLogin = () => {
//         // Functionality for Email Login
//         console.log("Email Login functionality");
//     };

//     const handleLogin = () => {
//         // Functionality for regular login
//         console.log("Regular login functionality");
//     };

//     return (
//         <Container fluid>
//             <Row>
//                 <Col xs={12} lg={6}>
//                     <Platform />
//                 </Col>
//                 <Col xs={12} lg={6}>
//                     <div className='login-form'>
//                         <Row>
//                             <Col>
//                                 <h2>Get started with Pinnacle</h2>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <Col className="text-right">
//                                 <Button variant="link" className='register-btn' onClick={() => setIsRegister(true)}>Register</Button>
//                             </Col>
//                             <Col className="text-right">
//                                 <Button variant="link" className='login-btn' onClick={() => setIsRegister(false)}>Login</Button>
//                             </Col>
//                         </Row>
//                         {isRegister ? (
//                             <Form>
//                                 <Form.Group controlId="formMobile">
//                                     <Form.Label>Please enter your mobile number to login/ register</Form.Label>
//                                     <Row>
//                                         <Col xs={2}>
//                                             <Form.Control type="text" value="+91" readOnly />
//                                         </Col>
//                                         <Col xs={10}>
//                                             <Form.Control
//                                                 type="text"
//                                                 placeholder="enter your 10 digit mobile number"
//                                                 value={mobileNumber}
//                                                 onChange={(e) => setMobileNumber(e.target.value)}
//                                             />
//                                         </Col>
//                                     </Row>
//                                     <Button variant="primary" onClick={handleOTP} className='get-otp-btn'>Get OTP</Button>
//                                 </Form.Group>
//                                 <Form.Group controlId="formEmail">
//                                     <Form.Label>or login/ register with email ID</Form.Label>
//                                     <Form.Control
//                                         type="email"
//                                         placeholder="enter your email id"
//                                         value={email}
//                                         onChange={(e) => setEmail(e.target.value)}
//                                     />
//                                     <Button variant="primary" onClick={handleEmailLogin} className='email-submit-btn'>Submit</Button>
//                                 </Form.Group>
//                                 <Button variant="link">or register with Gmail</Button>
//                             </Form>
//                         ) : (
//                             <Form>
//                                 <Form.Group controlId="formLogin">
//                                     <Form.Label>Enter your email or mobile number</Form.Label>
//                                     <Form.Control
//                                         type="text"
//                                         placeholder="Email or mobile number"
//                                         value={email}
//                                         onChange={(e) => setEmail(e.target.value)}
//                                     />
//                                 </Form.Group>
//                                 <Form.Group controlId="formPassword">
//                                     <Form.Label>Password</Form.Label>
//                                     <Form.Control
//                                         type="password"
//                                         placeholder="Password"
//                                         value={password}
//                                         onChange={(e) => setPassword(e.target.value)}
//                                     />
//                                 </Form.Group>
//                                 <Button variant="primary" onClick={handleLogin} className='login-submit-btn'>Login</Button>
//                             </Form>
//                         )}
//                     </div>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }

// export default Login;

