import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaPencilAlt } from "react-icons/fa";
import OverlayModal from "./UpdateTestSeriesOverlayModel";
import classes from "./ListTestSeries.module.css";
import Cookies from "universal-cookie";

const ListTestSeries = () => {
  const [fetchedData, setFetchedData] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [idModalData, setIdModalData] = useState("");
  const [
    test_series_name_modal_data,
    set_test_series_name_modal_data,
  ] = useState("");
  const [price_modal_data, set_price_modal_data] = useState("");
  const [status_modal_data, set_status_modal_data] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const cookies = new Cookies();

  const token = cookies.get("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/gettestseriesname",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        console.log(response.data);
        setFetchedData(response.data);
        // setId(response.data[1].count_id + 1);
        // console.log("Response from backend:", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlePencilClick = (id, test_series_name, price, status) => {
    setIsModelOpen(true);
    document.body.classList.add(classes.noScroll); // Disable scroll
    setIdModalData(id);
    set_test_series_name_modal_data(test_series_name);
    set_price_modal_data(price);
    set_status_modal_data(status);
  };

  const handleCloseModal = () => {
    setIsModelOpen(false);
    document.body.classList.remove(classes.noScroll); // Enable scroll
  };

  const handleCancel = () => {
    setIsModelOpen(false);
    document.body.classList.remove(classes.noScroll); // Enable scroll
  };

  // let date;
  // let readableDate;

  // {fetchedData.map((data)=>(
  //   date = new Date(data.createdAt)
  //   readableDate = date.toLocaleString('en-IN', { timeZone: 'IST' });
  // ))}
  // for (let i = 0; i < fetchedData.length; i++) {
  //   if (fetchedData[i].ts) {
  //     date = new Date(fetchedData[i].ts);
  //     readableDate = date.toLocaleDateString("en-IN", { timeZone: "IST" });
  //     fetchedData[i].createdAt = readableDate;
  //   } else if (fetchedData[i].createdAt) {
  //     date = new Date(fetchedData[i].createdAt);
  //     readableDate = date.toLocaleDateString("en-IN", { timeZone: "IST" });
  //     fetchedData[i].createdAt = readableDate;
  //   } else {
  //     fetchedData[i].createdAt = "Not available";
  //   }
  // }

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredData(fetchedData);
    } else {
      const filtered = fetchedData.filter((data) =>
        data.test_series_name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredData(filtered);
    }
  }, [searchTerm, fetchedData]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <h1 className={classes.test_serieslist_h1}>Test Series List</h1>
      <input
        type="text"
        placeholder="Enter Test Series Name"
        value={searchTerm}
        onChange={handleSearchChange}
        className={classes.searchInput}
      />
      {/* {console.log(test_series_name_modal_data)} */}
      {isModelOpen && (
        <div className={classes.overlay}>
          <OverlayModal
            id={idModalData}
            test_series_name={test_series_name_modal_data}
            price={price_modal_data}
            status={status_modal_data}
            onClose={handleCloseModal}
            onCancel={handleCancel}
          />
        </div>
      )}
      <div>
        <table>
          <thead>
            <tr className={classes.row_sub_section}>
              <td style={{ textAlign: "center" }}>ID</td>
              <td style={{ textAlign: "center" }}>Category Name </td>
              <td style={{ textAlign: "center" }}>Test Series Name</td>
              <td style={{ textAlign: "center" }}>Price</td>
              <td style={{ textAlign: "center" }}>Status</td>
              <td style={{ textAlign: "center" }}>Date</td>
              <td style={{ textAlign: "center" }}>Action</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((data, index) => (
              <tr className={classes.table_data_section} key={index}>
                <td>{data.id}</td>
                <td>{data.category_id}</td>
                <td>{data.test_series_name}</td>
                <td>{data.price}</td>
                <td>
                  {data.status === 1 ? (
                    <button style={{ backgroundColor: "green" }}>Active</button>
                  ) : (
                    <button style={{ backgroundColor: "red" }}>Inactive</button>
                  )}
                </td>
                <td>{data.createdAt ? data.createdAt : data.ts}</td>
                <td>
                  <FaPencilAlt
                    onClick={() =>
                      handlePencilClick(
                        data.id,
                        data.test_series_name,
                        data.price,
                        data.status
                      )
                    }
                    style={{ cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListTestSeries;
