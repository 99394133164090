import axios from "axios";
import Cookies from "universal-cookie";

const updateQuestion = async (
  paperCode,
  questionIndex,
  updatedQuestion,
  _idData,
  exam_mode_id,
  exam_tier_id
) => {
  const cookies = new Cookies();

  const token = cookies.get("token");

  try {
    console.log("updatedQuestion", updatedQuestion);
    console.log(
      `PAPERCODE ${paperCode}, QUESTION_INDEX ${questionIndex}, UPDATEDQUESTION ${updatedQuestion},_ID_DATA ${_idData}, EXAM_MODE_ID ${exam_mode_id} exam_tier_id ${exam_tier_id}`
    );
    // console.log(paperCode)
    // console.log(questionIndex)
    const response = await axios.put(
      `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/papercodes/${paperCode}/questions/${questionIndex}`,
      { updatedQuestion, _idData, exam_mode_id, exam_tier_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log("UPDATED QUESTION", updatedQuestion);

    console.log(response.data.message); // Display success message
    return true;
  } catch (error) {
    console.error("Error updating question:", error);
    return false;
  }
};

export default updateQuestion;
