import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import classes from "./UpdateMarksOverlay.module.css";
import ReactDom from "react-dom";
import axios from "axios";
import Cookies from "universal-cookie";

const Backdrop = () => {
  return <div className={classes.backdrop}></div>;
};

const UpdateMarksOverlay = ({ onClose, paper_code, marks, negativeMarks }) => {
  const [positiveMark, setPositiveMark] = useState(marks || "");
  const [negativeMark, setNegativeMark] = useState(negativeMarks || "");

  const cookies = new Cookies();
  const token = cookies.get("token");

  const handleFormDataSubmit = async () => {
    if (!positiveMark || !negativeMark) {
      alert("Please fill all fields before submitting.");
      return;
    }

    const formData = {
      paper_code: paper_code,
      marks: positiveMark,
      negativeMarks: Number(negativeMark), // Convert to number
    };

    console.log("Form data", formData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/update-marks`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Success response:", formData);

      if (response && response.status === 200) {
    
        alert("Marks Submitted Successfully!!!");
        onClose(); // Close the overlay on success
      } else {
        alert("Marks Submission Failed!!!");
      }
    } catch (error) {
      console.error("Error submitting form data", error);
      alert("Error submitting form data. Please try again.");
    } finally {
    }
  };

  return (
    <div className={classes.updateOverlayModel}>
          <div className={classes.header_section}>
            <h4>Update Marks</h4>
            <RxCross1 onClick={onClose} />
          </div>
          <div className={classes.marks_section}>
            <div>
              <label htmlFor="paperCode">Paper Code:</label>
              <input
                type="text"
                id="paperCode"
                value={paper_code}
                readOnly
                className={classes.readOnlyInput}
              />
            </div>
            <div>
              <label htmlFor="positiveMark">Positive Mark:</label>
              <input
                type="number"
                id="positiveMark"
                value={positiveMark}
                onChange={(e) => setPositiveMark(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="negativeMark">Negative Mark:</label>
              <input
                type="number"
                id="negativeMark"
                value={negativeMark}
                onChange={(e) => setNegativeMark(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.footer_section}>
            <button onClick={onClose} >
              Close
            </button>
            <button type="button" onClick={handleFormDataSubmit} >
              submit
            </button>
          </div>
    </div>
  );
};

const MarksOverlay = ({ paper_code, onClose, marks, negativeMarks }) => {
  return (
    <>
      {ReactDom.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDom.createPortal(
        <UpdateMarksOverlay
          paper_code={paper_code}
          onClose={onClose}
          marks={marks}
          negativeMarks={negativeMarks}
        />,
        document.getElementById("updateoverlay-root")
      )}
    </>
  );
};

export default MarksOverlay;
