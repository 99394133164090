import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ProductImages from "./ProductImages";
import ProductInfo from "./ProductInfo";
import ProductReviews from "./ProductReviews";
import RelatedProducts from "./RelatedProducts";
import Footer from "./Footer";
import classes from "./ProductDetails.module.css";
import Layout from "./Layout";

const ProductDetails = () => {
  const [filteredData, setFilteredData] = useState(null);
  const [selectedExams, setSelectedExams] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedMode, setSelectedMode] = useState([]);

  // useEffect(() => {
  //   console.log("selectedExams", selectedExams);
  // }, [selectedExams]);

  return (
    <Layout>
      <div>
        <ProductInfo />
      </div>
    </Layout>
  );
};

export default ProductDetails;
