import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "./IndividualExamModeCard.module.css";
import Layout from "./Layout";
import axios from "axios";
import IndividualExamPostTestSeriesCard from "./IndividualExamPostTestSeriesCard";

const IndividualExamPostCard = () => {
  const { exam_id, post_id } = useParams();
  // console.log(post_id);

  const [
    individualTestSeriesExamData,
    setIndividualTestSeriesExamData,
  ] = useState(null);

  const handleIndividualExamPostDataChange = async () => {
    try {
      const exam_id = Number(post_id);
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/getindividualexampostdata",
        {
          exam_post_id: post_id,
        }
      );
      setIndividualTestSeriesExamData(response.data);
      // console.log(response.data);
    } catch (error) {
      console.log("an error occured");
    }
  };

  // console.log(post_id);

  useEffect(() => {
    handleIndividualExamPostDataChange();
  }, [post_id]);

  return (
    <>
      <Layout>
        <div className={classes.individual_descrption_card_section}>
          {individualTestSeriesExamData &&
            individualTestSeriesExamData.map(
              (examtestseriescarddata, index) => (
                <IndividualExamPostTestSeriesCard
                  examtestseriescarddata={examtestseriescarddata} exam_id={exam_id} post_id={post_id}
                />
              )
            )}
        </div>
      </Layout>
    </>
  );
};

export default IndividualExamPostCard;
