import React, { useState, useRef } from "react";
import axios from "axios";
import DocxReader2 from "./DocxReader2";
import Multiselect from "multiselect-react-dropdown";
import classes from "./PapercodeAdd.module.css";
import Cookies from "universal-cookie";

const PapercodeAdd = () => {
  const [examMode, setExamMode] = useState("");
  const [paperCode, setPaperCode] = useState([]);
  const [selectedPaperCodes, setSelectedPaperCodes] = useState("");
  const [positiveMark, setPositiveMark] = useState("");
  const [negativeMark, setNegativeMark] = useState("");
  const [englishquestions, setQuestions] = useState([]);
  const [englishoptionA, setOptionsA] = useState([]);
  const [englishoptionB, setOptionsB] = useState([]);
  const [englishoptionC, setOptionsC] = useState([]);
  const [englishoptionD, setOptionsD] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [sections, setSections] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [hindiquestions, setHindiQuestions] = useState([]);
  const [HindioptionA, setHindiOptionsA] = useState([]);
  const [HindioptionB, setHindiOptionsB] = useState([]);
  const [HindioptionC, setHindiOptionsC] = useState([]);
  const [HindioptionD, setHindiOptionsD] = useState([]);
  const [hindiSolutions, setHindiSolutions] = useState([]);
  const [selectExam, setSelectExam] = useState("");
  const [selectExamPost, setSelectExamPost] = useState("");
  const [selectExamPostTier, setSelectExamPostTier] = useState("");
  const [examPostData, setExamPostData] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const multiselectRef = useRef();
  const docxReaderRef = useRef();
  const cookies = new Cookies();

  const token = cookies.get("token");

  const handleProcessedData = (data) => {
    setQuestions(data.englishquestions);
    setOptionsA(data.englishoptionA);
    setOptionsB(data.englishoptionB);
    setOptionsC(data.englishoptionC);
    setOptionsD(data.englishoptionD);
    setHindiQuestions(data.hindiquestions);
    setHindiOptionsA(data.HindioptionA);
    setHindiOptionsB(data.HindioptionB);
    setHindiOptionsC(data.HindioptionC);
    setHindiOptionsD(data.HindioptionD);
    setAnswers(data.answer);
    setSections(data.section);
    setSolutions(data.solutions);
    setHindiSolutions(data.hindisolutions);
  };

  const questionsData = {
    englishquestions,
    englishoptionA,
    englishoptionB,
    englishoptionC,
    englishoptionD,
    hindiquestions,
    HindioptionA,
    HindioptionB,
    HindioptionC,
    HindioptionD,
    answers,
    sections,
    solutions,
    hindiSolutions,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !examMode ||
      !selectedPaperCodes ||
      !selectExam ||
      !positiveMark ||
      !negativeMark ||
      !fileSelected
    ) {
      alert("Please fill all required fields.");
      return;
    }

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("examMode", examMode);
      formData.append("positiveMark", positiveMark);
      formData.append("negativeMark", negativeMark);
      formData.append("paperCode", selectedPaperCodes);
      formData.append("exam_id", selectExam);
      formData.append("exam_post_id", selectExamPost);
      formData.append("exam_post_tier_id", selectExamPostTier);
      formData.append("questionsData", JSON.stringify(questionsData));

      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/papercode/add",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token} `,
          },
        }
      );

      if (response.status === 201) {
        console.log(response);
        alert(response.data.message);

        resetForm();
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      alert(error.response.data.message);
      console.error("Error adding product:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setExamMode("");
    setPaperCode([]);
    setSelectedPaperCodes("");
    setPositiveMark("");
    setNegativeMark("");
    setQuestions([]);
    setOptionsA([]);
    setOptionsB([]);
    setOptionsC([]);
    setOptionsD([]);
    setHindiQuestions([]);
    setHindiOptionsA([]);
    setHindiOptionsB([]);
    setHindiOptionsC([]);
    setHindiOptionsD([]);
    setAnswers([]);
    setSections([]);
    setSolutions([]);
    setHindiSolutions([]);
    setSelectExam("");
    setSelectExamPost("");
    setSelectExamPostTier("");
    setExamPostData([]);
    setFileSelected(false);

    multiselectRef.current.resetSelectedValues();
    docxReaderRef.current.resetFields();
  };

  const handleSelectExamMode = async (selectedMode) => {
    try {
      setExamMode(selectedMode);
      if (selectedMode) {
        fetchPaperCodes(selectedMode);
      }
    } catch (error) {
      console.error("Error setting selected exam mode:", error);
    }
  };

  const fetchPaperCodes = async (selectedMode) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/getAllPaperCodes?exam_mode_id=${selectedMode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
      const codes = response.data.map((code) => ({
        value: { paper_code: code.paper_code },
        label: code.paper_code,
      }));
      setPaperCode(codes);
    } catch (error) {
      console.error("Error fetching paper codes:", error);
    }
  };

  const handleSelectExam = async (e) => {
    let selectedExamId = e.target.value;
    setSelectExam(selectedExamId);

    const response = await axios.post(
      process.env.REACT_APP_PINNACLE_BASE_URL +
        "/api/v1/admin/postselectedexamid",
      {
        exam_id: selectedExamId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("handleSelectExamResponse", response);
    setExamPostData(response.data);
  };

  const handleSelectExamPost = (e) => {
    let selectExamPostName = e.target.value;

    setSelectExamPost(selectExamPostName);
  };

  const handleFileSelected = () => {
    setFileSelected(true);
  };

  return (
    <div className={classes.formData}>
      <h1 className={classes.papercode_add_h1}>Paper Code Add</h1>
      {/* <form onSubmit={handleSubmit}> */}
      <form>
        <label className={classes.exam_mode_select}>
          Exam Mode:
          <select
            type="text"
            value={examMode}
            onChange={(e) => handleSelectExamMode(e.target.value)}
            required
          >
            <option value="" disabled hidden>
              Select Exam Mode
            </option>
            <option value="1">Mock test- Full length</option>
            <option value="2">Sectional Test</option>
            <option value="3">Chapter Test</option>
            <option value="4">PYP- Full Length</option>
            <option value="5">PYP-Full Length(New Pattern)</option>
          </select>
        </label>
        <label className={classes.paper_code}>Paper Code:</label>
        <Multiselect
          style={{ width: "100%" }}
          options={paperCode}
          selectedvalues={selectedPaperCodes}
          onSelect={(selectedList) => {
            const selectedValues = selectedList.map((item) => item.value);
            setSelectedPaperCodes(selectedValues[0].paper_code);
          }}
          onRemove={(selectedList) => {
            const selectedValues = selectedList.map((item) => item.value);
            setSelectedPaperCodes(selectedValues);
          }}
          displayValue="label"
          closeOnSelect="true"
          selectionLimit={1}
          ref={multiselectRef}
          required
        />
        <label className={classes.exam_mode_select}>
          Select Exam:
          <select value={selectExam} onChange={handleSelectExam} required>
            <option value="" disabled hidden>
              Select Exam
            </option>
            <option value="1">SSC</option>
            <option value="3">Railway</option>
            <option value="2">Delhi police</option>
            <option value="6">UPSC</option>
            <option value="5">State PCS</option>
            <option value="8">ACIO IB</option>
            <option value="4">Teaching</option>
            <option value="7">Others</option>
          </select>
          {selectExam && (
            <select
              value={selectExamPost}
              onChange={handleSelectExamPost}
              required
            >
              <option value="" disabled hidden>
                Select Exam Post
              </option>
              {examPostData.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.exam_post_name}
                </option>
              ))}
            </select>
          )}
          {selectExam === "1" && (
            <select
              value={selectExamPostTier}
              onChange={(e) => setSelectExamPostTier(e.target.value)}
              required
            >
              <option value="" disabled hidden>
                Select Exam Post Tier
              </option>
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
              <option value="4">Tier 4</option>
            </select>
          )}
        </label>
        <div className={classes.markinginput}>
          <label>
            Positive Mark:
            <input
              value={positiveMark}
              onChange={(e) => setPositiveMark(e.target.value)}
              required
            />
          </label>
        </div>
        <div className={classes.markinginput}>
          <label>
            Negative Mark:
            <input
              value={negativeMark}
              onChange={(e) => setNegativeMark(e.target.value)}
              required
            />
          </label>
        </div>
        <div className={classes.docx_reader_container}>
          <h4>Upload Question Paper</h4>
          <DocxReader2
            onProcessedData={handleProcessedData}
            ref={docxReaderRef}
            onFileSelected={handleFileSelected}
          />
        </div>
        <div className={classes.submit_button_container}>
          <button
            type="submit"
            className={classes.submit_button}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? "Submitting..." : "SUBMIT"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PapercodeAdd;

// import React, { useState, useEffect, useRef } from "react";
// // import DocxReader from "./DocxReader"; // Import the DocxReader component
// // import "./PapercodeAdd.css"; // Import your PapercodeAdd styles if you have them
// import classes from "./PapercodeAdd.module.css";
// import axios from "axios"; // Import axios
// import DocxReader2 from "./DocxReader2";
// import Multiselect from "multiselect-react-dropdown";

// const PapercodeAdd = () => {
//   const [examMode, setExamMode] = useState("");
//   const [paperCode, setPaperCode] = useState([]);
//   const [selectedPaperCodes, setSelectedPaperCodes] = useState("");
//   // const [totalQuestions, setTotalQuestions] = useState("");
//   // const [totalMarks, setTotalMarks] = useState("");
//   // const [totalTime, setTotalTime] = useState("");
//   const [positiveMark, setPositiveMark] = useState("");
//   const [negativeMark, setNegativeMark] = useState("");
//   // const [language, setLanguage] = useState("");
//   // const [type, setType] = useState("");
//   const [englishquestions, setQuestions] = useState([]);
//   const [englishoptionA, setOptionsA] = useState([]);
//   const [englishoptionB, setOptionsB] = useState([]);
//   const [englishoptionC, setOptionsC] = useState([]);
//   const [englishoptionD, setOptionsD] = useState([]);
//   const [answers, setAnswers] = useState([]);
//   const [sections, setSections] = useState([]);
//   const [solutions, setSolutions] = useState([]);
//   const [hindiquestions, setHindiQuestions] = useState([]);
//   const [HindioptionA, setHindiOptionsA] = useState([]);
//   const [HindioptionB, setHindiOptionsB] = useState([]);
//   const [HindioptionC, setHindiOptionsC] = useState([]);
//   const [HindioptionD, setHindiOptionsD] = useState([]);
//   const [hindiSolutions, setHindiSolutions] = useState([]);
//   const [selectExam, setSelectExam] = useState("");
//   const [selectExamPost, setSelectExamPost] = useState("");
//   const [selectExamPostTier, setSelectExamPostTier] = useState("");
//   const [examPostData, setExamPostData] = useState([]);

//   const multiselectRef = useRef();

//   // const [responseData, setResponseData] = useState([]);

//   const handleProcessedData = (data) => {
//     // console.log(typeof(data));
//     // console.log(data);
//     // console.log(data.englishquestions);
//     // console.log(data.englishoptionA)
//     // console.log(data.englishoptionB)
//     // console.log(data.englishoptionC)
//     // console.log(data.englishoptionD)
//     setQuestions(data.englishquestions);
//     setOptionsA(data.englishoptionA);
//     setOptionsB(data.englishoptionB);
//     setOptionsC(data.englishoptionC);
//     setOptionsD(data.englishoptionD);
//     setHindiQuestions(data.hindiquestions);
//     setHindiOptionsA(data.HindioptionA);
//     setHindiOptionsB(data.HindioptionB);
//     setHindiOptionsC(data.HindioptionC);
//     setHindiOptionsD(data.HindioptionD);
//     setAnswers(data.answer);
//     setSections(data.section);
//     setSolutions(data.solutions);
//     setHindiSolutions(data.hindisolutions);
//   };

//   const questionsData = {
//     englishquestions,
//     englishoptionA,
//     englishoptionB,
//     englishoptionC,
//     englishoptionD,
//     hindiquestions,
//     HindioptionA,
//     HindioptionB,
//     HindioptionC,
//     HindioptionD,
//     answers,
//     sections,
//     solutions,
//     hindiSolutions,
//   };

//   // console.log("questionsData", questionsData);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       // Create a FormData object to send file data along with other fields
//       const formData = new FormData();
//       formData.append("examMode", examMode);
//       // formData.append("paperCode", paperCode);
//       // formData.append("paperTitle", paperTitle);
//       // formData.append("totalQuestions", totalQuestions);
//       // formData.append("totalMarks", totalMarks);
//       // formData.append("totalTime", totalTime);
//       formData.append("positiveMark", positiveMark);
//       formData.append("negativeMark", negativeMark);
//       // formData.append("language", language);
//       formData.append("questionsData", JSON.stringify(questionsData));
//       // console.log("questionsData", questionsData);
//       // formData.append("paperType", type);
//       formData.append("paperCode", selectedPaperCodes);
//       formData.append("exam_id", selectExam);
//       formData.append("exam_post_id", selectExamPost);
//       // alert(selectExamPostTier)
//       formData.append("exam_post_tier_id", selectExamPostTier);

//       console.log("Form data:", formData);

//       // Make the API request to add the product
//       const response = await axios.post(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/papercode/add",
//         formData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       console.log("Response:", response);
//       if (response.status === 201) {
//         alert(response.data);
//       }

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const responseData = await response.json();
//       // console.log("Product added:", responseData);

//       // Handle the response if needed
//       console.log("Product added:", response.data);

//       // Reset form fields after successful submission

//       setExamMode("");
//       // setPaperCode("");
//       // setPaperTitle("");
//       // setTotalQuestions("");
//       // setTotalMarks("");
//       // setTotalTime("");
//       setPositiveMark("");
//       setNegativeMark("");
//       // setLanguage("");
//       // setType("");
//       setQuestions();
//       setOptionsA();
//       setOptionsB();
//       setOptionsC();
//       setOptionsD();
//       setHindiQuestions();
//       setHindiOptionsA();
//       setHindiOptionsB();
//       setHindiOptionsC();
//       setHindiOptionsD();
//       setAnswers();
//       setSections();
//       setSolutions();
//       setHindiSolutions();

//       multiselectRef.current.resetSelectedValues();
//     } catch (error) {
//       // Handle error if API request fails
//       console.error("Error adding product:", error);
//     }
//   };

//   const handleSelectExamMode = async (selectedMode) => {
//     try {

//       // const response = await axios.post(
//       //   process.env.REACT_APP_PINNACLE_BASE_URL + "/setSelectedExamMode",
//       //   { examMode: selectedMode },
//       //   { headers: { "content-Type": "application/json" } }
//       // );

//       // console.log("Selected exam mode response:", response.data);

//       setExamMode(selectedMode);
//       if (selectedMode) {
//         fetchPaperCodes(selectedMode);
//       }
//     } catch (error) {
//       console.error("Error setting selected exam mode:", error);
//     }
//   };

//   const fetchPaperCodes = async (selectedMode) => {
//     console.log(selectedMode);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/getAllPaperCodes?exam_mode_id=${selectedMode}`
//       );
//       console.log(response.data); // Log the response data

//       const codes = response.data.map((code) => ({
//         value: { paper_code: code.paper_code },
//         label: code.paper_code,
//       }));
//       setPaperCode(codes);
//     } catch (error) {
//       console.error("Error fetching paper codes:", error);
//     }
//   };

//   const handleSelectExam = async (e) => {
//     let selectedExamId = e.target.value;
//     setSelectExam(selectedExamId);
//     // alert(selectedExamId);

//     const response = await axios.post(
//       process.env.REACT_APP_PINNACLE_BASE_URL + "/postselectedexamid",
//       {
//         exam_id: selectedExamId,
//       }
//     );
//     // console.log(response.data);
//     setExamPostData(response.data);
//   };

//   const handleSelectExamPost = (e) => {
//     let selectExamPostName = e.target.value;
//     setSelectExamPost(selectExamPostName);
//     // alert(selectExamPostName)
//   };

//   return (
//     <div className={classes.formData}>
//     <h1 className={classes.papercode_add_h1}>Add Paper Code</h1>
//       {/* <h1>Add PaperCode</h1> */}
//       <form onSubmit={handleSubmit}>
//         <label className={classes.exam_mode_select}>
//           Exam Mode:
//           <select
//             type="text"
//             value={examMode}
//             onChange={(e) => {
//               handleSelectExamMode(e.target.value);
//             }}
//             required
//           >
//             <option value="" disabled hidden>Select Exam Mode</option>
//             <option value="1">Mock test- Full length</option>
//             <option value="2">Sectional Test</option>
//             <option value="3">Chapter Test</option>
//             <option value="4">PYP- Full Length</option>
//             <option value="5">PYP-Full Length(New Pattern)</option>
//           </select>
//         </label>
//         <label className={classes.paper_code}>Paper Code:</label>
//         <Multiselect
//           style={{ width: "100%" }}
//           options={paperCode}
//           selectedvalues={selectedPaperCodes}
//           onSelect={(selectedList) => {
//             const selectedValues = selectedList.map((item) => item.value);
//             setSelectedPaperCodes(selectedValues[0].paper_code);
//             console.log(selectedValues[0].paper_code);
//           }}
//           onRemove={(selectedList) => {
//             const selectedValues = selectedList.map((item) => item.value);
//             setSelectedPaperCodes(selectedValues);
//           }}
//           displayValue="label"
//           // singleSelect='true'
//           closeOnSelect="true"
//           selectionLimit={1}
//           ref={multiselectRef}
//             required
//         />

//         <label className={classes.exam_mode_select}>
//           select Exam
//           <select value={selectExam} onChange={handleSelectExam}>
//             <option value="" disabled hidden> select Exam</option>
//             <option value="1">SSC</option>
//             <option value="2">Delhi police</option>
//             <option value="3">Railway</option>
//             <option value="4">Teaching</option>
//             <option value="5">status pcs</option>
//             <option value="6"> upsc</option>
//             <option>others</option>
//             <option>ACIO IB</option>
//           </select>
//           {selectExam && (
//             <select
//               value={selectExamPost}
//               onChange={(e) => handleSelectExamPost(e)}
//             >
//               <option value="" disabled hidden>Select Exam post </option>
//               {examPostData.map((data) => (
//                 <option value={data.id}>{data.exam_post_name}</option>
//               ))}
//             </select>
//           )}
//           {selectExam === "1" ? (
//             <select
//               value={selectExamPostTier}
//               onChange={(e) => setSelectExamPostTier(e.target.value)}
//             >
//               <option value="" disabled hidden>Select Exam Post Tier </option>
//               <option value="1">Tier 1</option>
//               <option value="2">Tier 2</option>
//               <option value="3">Tier 3</option>
//               <option value="4"> Tier 4</option>
//             </select>
//           ) : (
//             ""
//           )}
//         </label>
//         <div className={classes.markinginput}>
//           <label>
//             Positive Mark:
//             <input
//               // type="number"
//               value={positiveMark}
//               onChange={(e) => setPositiveMark(e.target.value)}
//               required
//             />
//           </label>
//         </div>
//         <div className={classes.markinginput}>
//           <label>
//             Negative Mark:
//             <input
//               // type="number"
//               value={negativeMark}
//               onChange={(e) => setNegativeMark(e.target.value)}
//               required
//             />
//           </label>
//         </div>

//         <div className={classes.docx_reader_container}>
//           <h4>Upload Question Paper</h4>
//           {/* <DocxReader onProcessedData={handleProcessedData} />  */}
//           <DocxReader2 onProcessedData={handleProcessedData} />
//         </div>
//         <div className={classes.submit_button_container}>
//           <button type="submit" className={classes.submit_button}>
//             SUBMIT
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default PapercodeAdd;
