// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductHeader_component__8QRPA{
    display: flex;
    height: 5rem;
    background-color: rgb(58, 167, 197);
  }
  
  .ProductHeader_title__wfCds {
    font-size: 25px;
    text-align: center;
    padding: 15px;
    font-weight: bold;
    color: white;
  }
  
  .ProductHeader_buynow__CapPl {
    background-color: #ffee50; /* Yellow background */
    /* display: flex; Enable flexbox to center the content */
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    margin-top: 18px;
    color: black;
    border: none;
    padding: 0px 12px;
    height: 40px;
    font-size: 1.6rem;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap; /* Prevent text from wrapping */
    /* overflow: hidden; Hide any overflowing text */
    /* text-overflow: ellipsis; Add ellipsis if the text overflows */
    max-width: 400px; /* Set a maximum width if needed */
  }
  
  .ProductHeader_price__ou3We {
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    padding: 20px;
    color: white;
  }
  
  .ProductHeader_highlight__XbBsZ {
    color: #FFD700; /* Gold color for "Diwali" */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/component/ProductHeader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,mCAAmC;EACrC;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,sBAAsB;IACjD,wDAAwD;IACxD,uBAAuB,EAAE,oCAAoC;IAC7D,mBAAmB,EAAE,kCAAkC;IACvD,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,mBAAmB,EAAE,+BAA+B;IACpD,gDAAgD;IAChD,gEAAgE;IAChE,gBAAgB,EAAE,kCAAkC;EACtD;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,YAAY;EACd;;EAEA;IACE,cAAc,EAAE,4BAA4B;EAC9C","sourcesContent":[".component{\n    display: flex;\n    height: 5rem;\n    background-color: rgb(58, 167, 197);\n  }\n  \n  .title {\n    font-size: 25px;\n    text-align: center;\n    padding: 15px;\n    font-weight: bold;\n    color: white;\n  }\n  \n  .buynow {\n    background-color: #ffee50; /* Yellow background */\n    /* display: flex; Enable flexbox to center the content */\n    justify-content: center; /* Horizontally center the content */\n    align-items: center; /* Vertically center the content */\n    margin-top: 18px;\n    color: black;\n    border: none;\n    padding: 0px 12px;\n    height: 40px;\n    font-size: 1.6rem;\n    font-weight: bold;\n    cursor: pointer;\n    white-space: nowrap; /* Prevent text from wrapping */\n    /* overflow: hidden; Hide any overflowing text */\n    /* text-overflow: ellipsis; Add ellipsis if the text overflows */\n    max-width: 400px; /* Set a maximum width if needed */\n  }\n  \n  .price {\n    font-weight: bold;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    font-size: 25px;\n    padding: 20px;\n    color: white;\n  }\n  \n  .highlight {\n    color: #FFD700; /* Gold color for \"Diwali\" */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": `ProductHeader_component__8QRPA`,
	"title": `ProductHeader_title__wfCds`,
	"buynow": `ProductHeader_buynow__CapPl`,
	"price": `ProductHeader_price__ou3We`,
	"highlight": `ProductHeader_highlight__XbBsZ`
};
export default ___CSS_LOADER_EXPORT___;
