// import React, { useState } from 'react';
// import classes from "./SscCglTier1.module.css";
// import Layout from './Layout';
// import Slider from "@mui/material/Slider";
// import Box from "@mui/material/Box";

// const TestPassCard = ({ testPass, sliderValue, handleSliderChange, valuetext, marks, payMe}) => {
//     return (
//         <div className={classes.testseries_testpass_div}>
//           <h4>{testPass.name}</h4>
//           <p>Total Test: {testPass.number}</p>
//           <p>Price: {sliderValue}</p>
//           <p>Expire Period: {sliderValue} days</p>
//           <Box sx={{ width: 300 }}>
//             <Slider
//               aria-label="Custom marks"
//               defaultValue={20}
//               value={sliderValue}
//               onChange={handleSliderChange}
//               getAriaValueText={valuetext}
//               step={10}
//               valueLabelDisplay="auto"
//               marks={marks}
//             />
//           </Box>
//           <button className={classes.testseries_buy_button} onClick={payMe}>
//             Buy Now
//           </button>
//         </div>
//       );
// }

// const SscCglTier1 = () => {
//     const [sliderValue, setSliderValue] = useState(20);

//     const testPassData = [
//         { _id: "1", name: "Mock Test", exam: "ssc", number: "100"},
//         { _id: "2", name: "Sectional Test", exam: "SSC", number: "100"},
//         { _id: "3", name: "PYP JFull Length", exam: "SSC", number: "200"},
//         // { _id: "4", name: "SSC CGL Tier 1", exam: "SSC", number: "100"},
//         // { _id: "5", name: "SSC CGL Tier 2", exam: "SSC", number:"100"},
//         // { _id: "6", name: "SSC MTS Tier 1", exam: "SSC", number:"100"},
//         // { _id: "7", name: "SSC MTS Tier 2", exam: "SSC", number:"100"},
//         // { _id: "8", name: "SSC GD Tier 1", exam: "SSC", number:"100" },
//         // { _id: "9", name: "SSC Stenographer", exam: "SSC", number:"100" },
//         // { _id: "10", name: "SSC Selection post", exam: "SSC", number:"100" },
//       ];

//       const handleSliderChange = (event, newValue) => {
//         setSliderValue(newValue);
//       };

//       const valuetext = (value) => {
//         return `${value}`;
//       };

//       const marks = [
//         { value: 0, label: '0' },
//         { value: 20, label: '20' },
//         { value: 40, label: '40' },
//         { value: 60, label: '60' },
//         { value: 80, label: '80' },
//         { value: 100, label: '100' },
//       ];
//       const payMe = () => {
//         alert("Proceed to payment");
//       };

//   return (
//     <Layout>
//     <div className={classes.heading_of_individual_sub_section}>
//       <h1>SSC CGL TIER I</h1>
//       {/* <p>This is content for SSC Exams.</p> */}
//     </div>
//     <div className={classes.individual_card_section}>
//       {testPassData.map((testPass) => (
//         <TestPassCard
//           key={testPass._id}
//           testPass={testPass}
//           sliderValue={sliderValue}
//           handleSliderChange={handleSliderChange}
//           valuetext={valuetext}
//           marks={marks}
//           payMe={payMe}
//         />
//       ))}
//     </div>

//     <div className={classes.heading_of_individual_sub_section}>
//       <h1>SSC CHSL TIER I</h1>
//       {/* <p>This is content for SSC Exams.</p> */}
//     </div>
//     <div className={classes.individual_card_section}>
//       {testPassData.map((testPass) => (
//         <TestPassCard
//           key={testPass._id}
//           testPass={testPass}
//           sliderValue={sliderValue}
//           handleSliderChange={handleSliderChange}
//           valuetext={valuetext}
//           marks={marks}
//           payMe={payMe}
//         />
//       ))}
//     </div>

//     <div className={classes.heading_of_individual_sub_section}>
//       <h1>SSC CGL MTS TIER I</h1>
//       {/* <p>This is content for SSC Exams.</p> */}
//     </div>
//     <div className={classes.individual_card_section}>
//       {testPassData.map((testPass) => (
//         <TestPassCard
//           key={testPass._id}
//           testPass={testPass}
//           sliderValue={sliderValue}
//           handleSliderChange={handleSliderChange}
//           valuetext={valuetext}
//           marks={marks}
//           payMe={payMe}
//         />
//       ))}
//     </div>

//     <div className={classes.heading_of_individual_sub_section}>
//       <h1>SSC GD TIER I</h1>
//       {/* <p>This is content for SSC Exams.</p> */}
//     </div>
//     <div className={classes.individual_card_section}>
//       {testPassData.map((testPass) => (
//         <TestPassCard
//           key={testPass._id}
//           testPass={testPass}
//           sliderValue={sliderValue}
//           handleSliderChange={handleSliderChange}
//           valuetext={valuetext}
//           marks={marks}
//           payMe={payMe}
//         />
//       ))}
//     </div>

//     <div className={classes.heading_of_individual_sub_section}>
//       <h1>SSC Stenographer TIER I</h1>
//       {/* <p>This is content for SSC Exams.</p> */}
//     </div>
//     <div className={classes.individual_card_section}>
//       {testPassData.map((testPass) => (
//         <TestPassCard
//           key={testPass._id}
//           testPass={testPass}
//           sliderValue={sliderValue}
//           handleSliderChange={handleSliderChange}
//           valuetext={valuetext}
//           marks={marks}
//           payMe={payMe}
//         />
//       ))}
//     </div>

//     <div className={classes.heading_of_individual_sub_section}>
//       <h1>SSC Selection Post Tier I</h1>
//       {/* <p>This is content for SSC Exams.</p> */}
//     </div>
//     <div className={classes.individual_card_section}>
//       {testPassData.map((testPass) => (
//         <TestPassCard
//           key={testPass._id}
//           testPass={testPass}
//           sliderValue={sliderValue}
//           handleSliderChange={handleSliderChange}
//           valuetext={valuetext}
//           marks={marks}
//           payMe={payMe}
//         />
//       ))}
//     </div>

//     <div className={classes.heading_of_individual_sub_section}>
//       <h1>SSC CGL Tier 1</h1>
//       {/* <p>This is content for SSC Exams.</p> */}
//     </div>
//     <div className={classes.individual_card_section}>
//       {testPassData.map((testPass) => (
//         <TestPassCard
//           key={testPass._id}
//           testPass={testPass}
//           sliderValue={sliderValue}
//           handleSliderChange={handleSliderChange}
//           valuetext={valuetext}
//           marks={marks}
//           payMe={payMe}
//         />
//       ))}
//     </div>
//   </Layout>
//   )
// }

// export default SscCglTier1;

// import React, { useState, useEffect } from "react";
// import classes from "./SscCglTier1.module.css";
// import Layout from "./Layout";
// import Slider from "@mui/material/Slider";
// import Box from "@mui/material/Box";
// import axios from "axios";
// import TierCard from "./TierCard";
// import { useParams } from "react-router-dom";

// const TestPassCard = ({ testPass, sliderValue, handleSliderChange, valuetext, marks, payMe }) => {
//     const [dataStored, setDataStored] = useState(null)
//     return (

//         <div className={classes.testseries_testpass_div}>
//             <h4>{testPass.name}</h4>
//             <p>Total Test: {testPass.number}</p>
//             <p>Price: {sliderValue}</p>
//             <p>Expire Period: {sliderValue} days</p>
//             <Box sx={{ width: 300 }}>
//                 <Slider
//                     aria-label="Custom marks"
//                     defaultValue={20}
//                     value={sliderValue}
//                     onChange={handleSliderChange}
//                     getAriaValueText={valuetext}
//                     step={10}
//                     valueLabelDisplay="auto"
//                     marks={marks}
//                 />
//             </Box>
//             <button className={classes.testseries_buy_button} onClick={payMe}>
//                 Buy Now
//             </button>
//         </div>
//     );
// };

// const [sliderValue, setSliderValue] = useState(20);

// const testPassData = [
//     { _id: "1", name: "Mock Test", exam: "ssc", number: "100" },
//     { _id: "2", name: "Sectional Test", exam: "SSC", number: "100" },
//     { _id: "3", name: "PYP Full Length", exam: "SSC", number: "200" },
//     // ... additional test data if needed
// ];

// const sectionNames = [
//     "SSC CGL TIER I",
//     "SSC CHSL TIER I",
//     "SSC MTS TIER I",
//     "SSC GD TIER I",
//     "SSC Stenographer TIER I",
//     "SSC Selection Post TIER I",
//     "SSC CGL Tier 1"
// ];

// const handleSliderChange = (event, newValue) => {
//     setSliderValue(newValue);
// };

// const valuetext = (value) => {
//     return `${value}`;
// };

// const marks = [
//     { value: 0, label: '0' },
//     { value: 20, label: '20' },
//     { value: 40, label: '40' },
//     { value: 60, label: '60' },
//     { value: 80, label: '80' },
//     { value: 100, label: '100' },
// ];

// const payMe = () => {
//     alert("Proceed to payment");
// };

// const SscCglTier1 = () => {
//   const [tierData, setTierData] = useState(null);
//   const { tierId } = useParams();
//   console.log(tierId);

//   console.log(tierId);

//   const fetchData = async () => {
//     try {
//       const response = await axios.post(process.env.REACT_APP_PINNACLE_BASE_URL + "/gettierdata", {
//         value: tierId,
//       });
//       setTierData(response.data);
//       console.log(response.data);
//     } catch (error) {
//       console.log("an internal server error occur");
//     }
//   };
//   //   useEffect(() => {
//   //     fetchData();
//   //   }, [tier_id]);

//   useEffect(() => {
//     fetchData();
//   }, [tierId]);

//   return (
//     <>
//       <Layout>
//         {/* <button onClick={fetchData}>onclick</button> */}
//         <div className={classes.inner_div_section}>
//           {tierData && tierData.map((data, index) => <TierCard data={data} />)}
//         </div>
//       </Layout>
//     </>
//   );

// return (
//     <Layout>
//         {sectionNames.map(section => (
//             <div key={section} className={classes.tier1_div}>
//                 <div className={classes.heading_of_individual_sub_section}>
//                     <h1>{section}</h1>
//                     {/* <p>This is content for SSC Exams.</p> */}
//                 </div>
//                 <div className={classes.individual_card_section}>
//                     {testPassData.map(testPass => (
//                         <TestPassCard
//                             key={testPass._id}
//                             testPass={testPass}
//                             sliderValue={sliderValue}
//                             handleSliderChange={handleSliderChange}
//                             valuetext={valuetext}
//                             marks={marks}
//                             payMe={payMe}
//                         />
//                     ))}
//                 </div>
//             </div>
//         ))}
//     </Layout>
// };

// export default SscCglTier1;
