import Header1 from "./Header1";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../hooks/useAuthContext";
import { addProfile } from "../redux/slices/userProfileSlice";
import { useSelector } from "react-redux";

function Test() {
  const [cookies] = useCookies();
  const {
    exam_id,
    post_id,
    language,
    papercode,
    exam_mode_id,
    test_series_id,
    paperids,
    tier_id,
    product_id,
  } = useParams();

  const languages = atob(language);
  const paper_cod = atob(papercode);
  const exam_mod_id = atob(exam_mode_id);
  const test_seris_id = atob(test_series_id);
  const paperid = atob(paperids);
  const navigate = useNavigate();

  //test/:language/:papercode/:exam_mode_id/:test_series_id/:paperids/:tier_id/:exam_id/:post_id/:product_id

  // lang: RW5nbGlzaA==, papercode: U1NDIENHTCAyMDIzIEZ1bGwgTW9jayAwMQ==,
  //    exam_mode_id ODMx, test_series_id: Mg==,
  //    paperids: MQ==, tier_id: Mg==, exam_id: 1,
  //    post_id: 1, product_id: 121

  console.log(`lang: ${language}, papercode: ${papercode},
     exam_mode_id ${exam_mode_id}, test_series_id: ${test_series_id}, 
     paperids: ${paperids}, tier_id: ${tier_id}, exam_id: ${exam_id}, 
     post_id: ${post_id}, product_id: ${product_id}`);

  const [paper_code, setPaperCode] = useState();
  const [paperDetailPaperId, setPaperDetailPaperId] = useState();
  const [subjectID, setSubjectID] = useState("");
  const [hrs, setHours] = useState("");
  const [mins, setMinutes] = useState("");
  const [secs, setSeconds] = useState("");
  const [Answered, setAnsweredCount] = useState(0);
  const [NotAnswered, setNotAnsweredCount] = useState(0);
  const [Marked, setMarkedCount] = useState(0);
  const [MarkedAnswered, setMarkedAnsweredCount] = useState(0);
  const [NotVisited, setNotVisitedCount] = useState("");
  const [subject_name, setSubject_name] = useState("");
  const [title, setTitle] = useState();
  const [testType, setTestType] = useState();
  // const [tier, setTier] = useState();

  const userProfile = useSelector((state) => state.userProfile);
  const { token } = useAuthContext();

  useEffect(() => {
    // Push the current state into the history stack
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      // Whenever the back or forward button is pressed, we push the state again
      window.history.pushState(null, document.title, window.location.href);
    };

    // Listen for popstate event, which occurs when the back or forward buttons are clicked
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Cleanup event listener when the component unmounts
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  // console.log("token: " , token);
  // console.log("userProfile: " , userProfile);

  useEffect(() => {
    async function papersRes() {
      let papers = {
        paper_code: paper_cod,
        // email_id: "neerajit@ssccglpinnacle.com",
        // email_id: "mdahsan@ssccglpinnacle.com",
        // email_id: "anshulji100@gmail.com",
        email_id: userProfile.email_id,
      };
      console.log("PAPERS", papers);
      let papers_res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/paperCodeDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(papers),
        }
      );

      papers_res = await papers_res.json();
      console.log("TEST_PAPER_RESPONSE", papers_res);

      // setTier(papers_res.tier)
      setPaperDetailPaperId(papers_res.pid);
      setPaperCode(papers_res.paper_code);
      setSubjectID(papers_res.subject_id);
      setHours(papers_res.hrs);
      setMinutes(papers_res.mins);
      setSeconds(papers_res.secs);
      setSubject_name(papers_res.subject_name);
      setAnsweredCount(papers_res.answered_count);
      setNotAnsweredCount(papers_res.notanswered_count);
      setMarkedCount(papers_res.marked_count);
      setMarkedAnsweredCount(papers_res.marked_answered_count);
      setNotVisitedCount(papers_res.not_visited);
      setTitle(papers_res.title);
      setTestType(papers_res.test_type);
    }

    papersRes();
  }, [paper_cod]);

  console.log(Answered);

  return (
    <>
      <div className="App">
        {title && (
          <Header1
            hrs={hrs}
            mins={mins}
            test_series_id={test_seris_id}
            exam_mode_id={exam_mod_id}
            tier_id={tier_id}
            secs={secs}
            paper_code={paper_code}
            subjectID={subjectID}
            NotVisited={NotVisited}
            Answered={Answered}
            NotAnswered={NotAnswered}
            Marked={Marked}
            MarkedAnswered={MarkedAnswered}
            subject_name={subject_name}
            title={title}
            languages={languages}
            pids={paperDetailPaperId ? paperDetailPaperId : paperid}
            TestTp={testType}
            exam_id={exam_id}
            post_id={post_id}
            product_id={product_id}
          />
        )}
      </div>
    </>
  );
}

export default Test;
