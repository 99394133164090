import { Row, Col, Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { BsClockFill } from "react-icons/bs";
import { AiOutlineFile } from "react-icons/ai";
/* import TestSeriesHeader from './TestSeriesHeader'
import TestSeriesFooter from './TestSeriesFooter' */
import TestOverview from "./TestOverview";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { useCookies } from "react-cookie";
import ProductBuyInitiate from "./Product_Buy_Initiate";
import Spinner from "./Spinner"; // Import the Spinner component

import { useAuthContext } from "../hooks/useAuthContext";
// import { addProfile } from "../redux/slices/userProfileSlice";
import { useSelector } from "react-redux";

import "./TestSeries.css";

function TestSeriesUnlock() {
  const [cookies, setCookie] = useCookies();
  const [TestData, setTestData] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading
  const navigate = useNavigate();
  const [TestAccess, setTestAccess] = useState();
  const [CglAccess, setTestAccessCgl] = useState();
  const [TierName, setPostTierName] = useState();
  const [ExamID, setExamID] = useState();
  const [show, setShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [showEmail, setEmailShow] = useState(false);
  const [RegisteredStd, setRegistered] = useState();
  const [mobile, setMobile] = useState("");
  const [Otp, setOtp] = useState();
  const [EnterOtp, setEnterOtp] = useState();
  const [EmailID, setEmailID] = useState();
  const [FullName, setFullName] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [testSeriesName, setTestSeriesName] = useState();

  const userProfile = useSelector((state) => state.userProfile);
  const { token } = useAuthContext();

  // console.log("token: " , token);
  // console.log("userProfile: " , userProfile);

  // const handleOverlayModelChange = () => {
  //   setIsModelOpen(!isModelOpen);
  // };

  const {
    exam_id,
    post_id,
    test_series_id,
    TierID,
    exam_mode_id,
    product_id,
  } = useParams();

  console.log(TierID, exam_mode_id);

  useEffect(() => {
    async function testData() {
      let test_data = {
        // email_id: "neerajit@ssccglpinnacle.com",

        // email_id: "anshulji100@gmail.com",
        email_id: userProfile.email_id, // test_series_id: testid,
        test_series_id: test_series_id,

        // product_id: 122,
      };

      let test_data_request = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/TestSeriesDetails2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(test_data),
        }
      );
      let test_data_response = await test_data_request.json();
      console.log("API Response:", test_data_response);
      // console.log("TEST_DATA_RESPONSE", test_data_response);
      setTestSeriesName(test_data_response.test_series_name);
      setTestData(test_data_response.ar);
      setLoading(false); // Set loading to false once data is loaded
    }

    testData();
  }, []);

  // useEffect(() => {
  //   console.log(TestData);
  // }, []);

  useEffect(() => {
    async function testAccess() {
      console.log(" line 82 i got called");
      // let test_access = [{ email_id: "harishmodi129@gmail.com" }];
      let test_access = [
        {
          // email_id: "anshulji100@gmail.com",
          // test_series_id: testid,
          // email_id: "harishmodi@ssccglpinnacle.com",
          // email_id: "harishmodi129@gmail.com",
          // email_id: "anishkumar@ssccglpinnacle.com",
          email_id: userProfile.email_id,
          exam_id: exam_id,
          post_id: post_id,
          // tier_id: exam_post_id,
          tier_id: TierID,
          exam_mode_id: exam_mode_id,
          product_id: product_id,
        },
      ];
      let test_access_request = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/testSeriesAccess",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(test_access),
        }
      );
      let test_access_response = await test_access_request.json();
      setTestAccess(test_access_response.user_status);

      console.log("test_access_response", test_access_response.user_status);
    }

    testAccess();
  }, [exam_id, post_id, TierID, exam_mode_id]);

  useEffect(() => {
    async function tierIds() {
      // let tier_ids = { tier_id: exam_post_id };
      let tier_ids = { tier_id: TierID };
      let res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/Breadcrump",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tier_ids),
        }
      );
      let tier = await res.json();
      setExamID(tier[0]["ExamId"]);
      setPostTierName(tier[0]["PostTierId"]);
    }

    tierIds();
  }, []);

  // useEffect(() => {
  //   async function testAccessCgl() {
  //     // let test_access_cgl = [{ email_id: "neerajit@ssccglpinnacle.com" }];
  //     // let test_access_cgl = [{ email_id: "anshulji100@gmail.com" }];
  //     // let test_access_cgl = [{ email_id: "harishmodi129@gmail.com" }];
  //     let test_access_cgl = [{ email_id: userProfile.email_id }];

  //     let test_access_request_cgl = await fetch(
  //       process.env.REACT_APP_PINNACLE_BASE_URL + "/testSeriesAccessCglTier2",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(test_access_cgl),
  //       }
  //     );
  //     let test_access_response_cgl = await test_access_request_cgl.json();
  //     setTestAccessCgl(test_access_response_cgl.cgl_access);
  //     // console.log(test_access_response_cgl.cgl_access);
  //     console.log("line 112", test_access_response_cgl.cgl_access);
  //   }

  //   testAccessCgl();
  // }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const handleOtp = async () => {
  //   setOtpShow(true);
  //   setShow(false);

  //   let otp_data = { mobile: mobile, name: "Student" };
  //   let otp_request = await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/otp", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(otp_data),
  //   });
  //   let otp_response = await otp_request.json();
  //   setOtp(otp_response.OTP);
  //   //setOtp('1234');
  // };

  // const handleEmail = () => {
  //   if (EnterOtp == Otp) {
  //     setOtpShow(false);
  //     setEmailShow(true);
  //   } else {
  //     alert("Please enter the OTP sent to " + mobile);
  //   }
  // };

  const BuyFun = () => {
    // if (exam_post_id == 2) {
    // if (TierID == 2) {
    //   window.location.href =
    //     "https://ssccglpinnacle.com/product/SSC-CGL-Tier-2-Test-Package";
    // } else {
    //   window.location.href =
    //     "https://ssccglpinnacle.com/product/Pinnacle-Test-Pass-";
    // }
    // navigate('/')
    navigate(`/individualexamtestpassbuy/${product_id}`);
  };

  // const handleEmailSubmit = async () => {
  //   let days = 365;
  //   let expires = new Date();
  //   expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  //   setCookie("email_id", EmailID, {
  //     path: "/",
  //     expires,
  //   });
  //   setEmailShow(false);
  //   let std_details = [{ mobile, Otp, EmailID, FullName }];
  //   let product_buy_request = await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/studentInfo", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(std_details),
  //   });
  //   let product_buy_response = await product_buy_request.json();
  //   console.log(product_buy_response[0]["email_id"]);
  //   if (product_buy_response[0]["email_id"] != "") {
  //     window.location.href = `https://ssccglpinnacle.com/product.php?email_id=${EmailID}`;
  //   }
  // };

  /* useEffect(() => {
    async function emailResult(){
    let email = { 'email_id': cookies.email_id }
    let result = await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + '/checkregistration', {    
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(email)
    })
    let email_result = await result.json()
    setRegistered(email_result['registered'])
  }

  emailResult()
  }, []) */

  const MobileFun = (e) => {
    setMobile(e.target.value);
  };

  // const OtpFun = (e) => {
  //   setEnterOtp(e.target.value);
  // };

  const startTest = (event) => {
    let exam_mode_id = event.target.getAttribute("exam_mode_id");
    let test_series_id = event.target.getAttribute("test_series_id");
    let papercode = event.target.getAttribute("paper_code");
    let test_title = event.target.getAttribute("title");
    let TierID = event.target.getAttribute("TierID");

    // navigate(
    //   `/Instruction/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
    //     test_series_id
    //   )}/${test_title}/${TierID}`
    // );

    navigate(
      `/Instruction/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
        test_series_id
      )}/${test_title}/${TierID}/${exam_id}/${post_id}/${product_id}`
    );
  };

  const analysis = (event) => {
    let exam_mode_id = event.target.getAttribute("exam_mode_id");
    let test_series_id = event.target.getAttribute("test_series_id");
    let papercode = event.target.getAttribute("paper_code");
    let title = event.target.getAttribute("title");
    let TierID = event.target.getAttribute("TierID");

    // navigate(
    //   `/attempted/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
    //     test_series_id
    //   )}/${title}/${TierID}`
    // );
    navigate(
      `/attempted/${btoa(exam_mode_id)}/${btoa(papercode)}/${btoa(
        test_series_id
      )}/${title}/${TierID}/${exam_id}/${post_id}/${product_id}`
    );
  };

  const ResumeTest = async (event) => {
    let exam_mode_id = event.target.getAttribute("exam_mode_id");
    let test_series_id = event.target.getAttribute("test_series_id");
    let papercode = event.target.getAttribute("paper_code");
    let TierID = event.target.getAttribute("TierID");

    let language = "English";

    let qs_details = {
      // email_id: cookies.email_id,
      email_id: userProfile.email_id,
      paper_code: papercode,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
    };
    let res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/pauseQuestion",
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(qs_details),
      }
    );
    let qsdt = await res.json();

    let paperids = qsdt.pause_question;

    // navigate(
    //   `/test/${btoa(language)}/${btoa(papercode)}/${btoa(exam_mode_id)}/${btoa(
    //     test_series_id
    //   )}/${btoa(paperids)}/${TierID}`

    // );
    navigate(
      `/test/${btoa(language)}/${btoa(papercode)}/${btoa(exam_mode_id)}/${btoa(
        test_series_id
      )}/${btoa(paperids)}/${TierID}/${exam_id}/${post_id}/${product_id}`
    );
  };

  // const ValidationFun = () => {
  //   alert(`Please Enter Valid Mobile Number`);
  // };

  return (
    <>
      {/*
      {isModelOpen && (
        <ProductBuyInitiate
          handleOverlayModelChange={handleOverlayModelChange}
        />
      )}
       <TestSeriesHeader /> */}
      <TestOverview
        ExamID={exam_id}
        post_id={post_id}
        product_id={product_id}
        // TierID={exam_post_id}
        TierID={TierID}
        TierName={TierName}
        exam_mode_id={exam_mode_id}
        test_series_name={testSeriesName}
        // test_series_id={testid}
        test_series_id={test_series_id}
      />
      <div className="float-left width-100-perc padding-top50">
        <div className="test-pass-container">
          {/* {console.log(TestData)}; */}
          {loading ? (
            <Spinner />
          ) : (
            <Row>
              {/* {console.log(TestData)} */}
              {TestData.map((tdm, i) => (
                <>
                  {tdm.Questions == "" ? (
                    ""
                  ) : (
                    <Col md={4} className="margin-bottom30" key={i}>
                      <div className="full-width float-left border-blue padding-9px-9px display-flex align-items-center">
                        <div className="width-70-perc float-left">
                          <p>{tdm.Title}</p>
                          <p>
                            <span className="margin-right10">
                              <HiQuestionMarkCircle className="font-size-18 margin-right3 margin-top-minus3" />
                              {tdm.Questions}
                            </span>
                            <span className="margin-right10">
                              <AiOutlineFile className="font-size-18 margin-right3 margin-top-minus3" />
                              {tdm.Marks}
                            </span>
                            <span>
                              <BsClockFill className="font-size-18 margin-right3 margin-top-minus3" />
                              {tdm.Time}
                            </span>
                          </p>
                        </div>
                        <div className="width-30-perc float-left margin-center">
                          {!tdm.start_date == "" ? (
                            TestAccess == 1 ? (
                              tdm.PaperStatus == 2 ? (
                                <button
                                  onClick={startTest}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  Start Test
                                </button>
                              ) : tdm.PaperStatus == 1 ? (
                                <button
                                  onClick={analysis}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  View Result
                                </button>
                              ) : (
                                <button
                                  onClick={ResumeTest}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  Resume Test
                                </button>
                              )
                            ) : CglAccess == 1 && TierID == 2 ? (
                              // ) : CglAccess == 1 && exam_post_id == 2 ? (
                              tdm.PaperStatus == 2 ? (
                                <button
                                  onClick={startTest}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  Start Test
                                </button>
                              ) : tdm.PaperStatus == 1 ? (
                                <button
                                  onClick={analysis}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  View Result
                                </button>
                              ) : (
                                <button
                                  onClick={ResumeTest}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  Resume Test
                                </button>
                              )
                            ) : tdm.free_status == 0 ? (
                              tdm.PaperStatus == 2 ? (
                                <button
                                  onClick={startTest}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  Start Test
                                </button>
                              ) : tdm.PaperStatus == 1 ? (
                                <button
                                  onClick={analysis}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  View Result
                                </button>
                              ) : (
                                <button
                                  onClick={ResumeTest}
                                  // test_series_id={testid}
                                  test_series_id={test_series_id}
                                  exam_mode_id={tdm.exam_mode_id}
                                  paper_code={tdm.paper_code}
                                  title={tdm.Title}
                                  // TierID={exam_post_id}
                                  TierID={TierID}
                                  className="background-none border-9191ff color-9191ff br5-important"
                                >
                                  Resume Test
                                </button>
                              )
                            ) : (
                              <button
                                onClick={
                                  RegisteredStd == "0" ? handleShow : BuyFun
                                }
                                // test_series_id={testid}
                                test_series_id={test_series_id}
                                exam_mode_id={tdm.exam_mode_id}
                                paper_code={tdm.paper_code}
                                title={tdm.Title}
                                // TierID={exam_post_id}
                                TierID={TierID}
                                className="background-none border-9191ff color-9191ff br5-important"
                              >
                                Unlock
                              </button>
                            )
                          ) : (
                            <button className="background-none border-9191ff color-9191ff br5-important">
                              Coming Soon
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="full-width float-left background-f1f1f1">
                        {tdm.free_status == 0 ? (
                          <p className="padding-0px-9px">
                            Syllabus | {tdm.languages}
                            <span style={{ color: "red", float: "Right" }}>
                              Free Mock
                            </span>
                          </p>
                        ) : (
                          <p className="padding-0px-9px">
                            Syllabus | {tdm.languages}
                            {tdm.start_date != "" ? (
                              <span className="date-time">
                                ({tdm.start_date} {tdm.start_time})
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        )}
                      </div>
                    </Col>
                  )}
                </>
              ))}
            </Row>
          )}
        </div>
      </div>

      {/*

      <Modal show={show} onHide={handleClose}>
        <div className="mobilebody">
          <p
            style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
          >
            Continue to Attempt
          </p>
          <p style={{ textAlign: "center", fontSize: "13px" }}>
            Boost your exam preparation with us
          </p>
          <input
            type="number"
            placeholder="Enter Mobile Number"
            className="entermobile"
            onChange={MobileFun}
          />
        </div>
        <Button
          onClick={
            mobile.length < 10 || mobile.length > 12 ? ValidationFun : handleOtp
          }
          className="continue-btn"
        >
          Continue
        </Button>
      </Modal>

      <Modal show={otpshow}>
        <div className="mobilebody">
          <p
            style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
          >
            Enter OTP
          </p>
          <p style={{ textAlign: "center", fontSize: "13px" }}>
            Please enter the OTP sent to {mobile}
          </p>
          <input
            type="number"
            placeholder="Enter OTP"
            className="entermobile"
            onChange={OtpFun}
          />
        </div>
        <Button onClick={handleEmail} className="continue-btn">
          Submit OTP
        </Button>
      </Modal>

      <Modal show={showEmail}>
        <div className="mobilebody">
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: "4%",
              marginBottom: "1%",
            }}
          >
            Enter Your Name
          </p>
          <input
            type="text"
            placeholder="Enter Name"
            className="entermobile"
            onChange={(e) => setFullName(e.target.value)}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: "4%",
              marginBottom: "1%",
            }}
          >
            Enter Email ID
          </p>
          <input
            type="text"
            placeholder="Enter Email ID"
            className="entermobile"
            onChange={(e) => setEmailID(e.target.value)}
          />
        </div>
        <Button onClick={handleEmailSubmit} className="continue-btn">
          Continue
        </Button>
      </Modal>

      <TestSeriesFooter />
        */}
    </>
  );
}

export default TestSeriesUnlock;
