import React, { useState } from "react";
import ReactDom from "react-dom";
import "tinymce/tinymce";
import classes from "./UpdateProduct.module.css";
import { RxCross2 } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Editor } from "tinymce/tinymce";

const Backdrop = ({ handleModelViewChange }) => {
  return (
    <div onClick={handleModelViewChange} className={classes.backdrop}></div>
  );
};

const UpdateProductModel = ({
  handleModelViewChange,
  // selectedProduct,
  ProductData,
  ProductMetaData,
  selectedProduct,
}) => {
  // console.log(ProductData);
  // console.log(ProductMetaData);
  // console.log(ProductData.id);
  // console.log(selectedProduct);
  // const [productDescription, setProductDescription] = useState("");
  const [productStatus, setProductStatus] = useState(ProductData.status);
  const [updateProductTitle, setUpdateProductTitle] = useState(
    ProductData.product_title
  );
  const [productPermalink, setProductPermalink] = useState(
    ProductData.product_permalink
  );
  const [updateSeoTitle, setUpdateSeoTitle] = useState(ProductData.seo_title);
  const [updateSeoKeywords, setUpdateSeoKeywords] = useState(
    ProductMetaData && ProductMetaData[0].meta_key
  );
  const [updateSeoDescription, setUpdateSeoDescription] = useState(
    ProductMetaData && ProductMetaData[0].meta_keywords
  );
  const [updatePrice, setUpdatePrice] = useState(ProductData.product_price);
  const [updateMaxPrice, setUpdateMaxPrice] = useState(
    ProductData.product_max_price
  );

  const [updateValidationPeriod, setUpdateValidationPeriod] = useState(
    ProductData.product_validation_period
  );
  // const [updateProductDescription, setUpdateProductDescription] = useState(
  //   ProductData.product_description
  // );
  const [content, setContent] = useState(ProductData.product_description);
  const [productImage, setProductImage] = useState(ProductData.product_image);
  const [changedFields, setChangedFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  // const handleEditorChange = (e) => {
  //   setProductDescription(e.target.value);
  // };

  const handleProductUpdateChange = (e) => {
    console.log("this got clicked");
    const newStatus = e.target.value === "active" ? 1 : 0;
    setProductStatus(newStatus);
    setChangedFields((prev) => ({ ...prev, status: newStatus }));
  };

  const handleUpdateSeoTitle = (e) => {
    setUpdateSeoTitle(e.target.value);
    setChangedFields((prev) => ({ ...prev, seo_title: e.target.value }));
  };

  const handleUpdateSeoKeywordsChange = (e) => {
    setUpdateSeoKeywords(e.target.value);
    setChangedFields((prev) => ({ ...prev, meta_keywords: e.target.value }));
  };

  const handleUpdateProductPrice = (e) => {
    setUpdatePrice(e.target.value);
    setChangedFields((prev) => ({ ...prev, product_price: e.target.value }));
  };

  const handleUpdateProductMaxPrice = (e) => {
    setUpdateMaxPrice(e.target.value);
    setChangedFields((prev) => ({
      ...prev,
      product_max_price: e.target.value,
    }));
  };

  const handleUpdateProductValidationPeriod = (e) => {
    setUpdateValidationPeriod(e.target.value);
    setChangedFields((prev) => ({
      ...prev,
      product_validation_period: e.target.value,
    }));
  };
  const handleUpdateSeoDescriptionChange = (e) => {
    setUpdateSeoDescription(e.target.value);
    setChangedFields((prev) => ({
      ...prev,
      product_description: e.target.value,
    }));
  };

  const handleUpdateProductTitle = (e) => {
    setUpdateProductTitle(e.target.value);
    setChangedFields((prev) => ({ ...prev, product_title: e.target.value }));
  };

  const handleUpdateProductPermalink = (e) => {
    setProductPermalink(e.target.value);
    setChangedFields((prev) => ({
      ...prev,
      product_permalink: e.target.value,
    }));
  };

  const handleUpdateProductImage = (e) => {
    const file = e.target.files[0];
    setProductImage(file);
    setChangedFields((prev) => ({
      ...prev,
      product_image: file,
    }));
  };

  // const handleUpdateProductDataDescription = (value) => {
  //   setContent(value);

  //   setChangedFields((prev) => ({
  //     ...prev,
  //     product_description: value,
  //   }));
  // };

  const updateFormData = async (e) => {
    // console.log("this function got clicked");
    // console.log(selectedProduct);
    e.preventDefault();
    setLoading(true);

    console.log(changedFields);
    try {
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/updateproductData",
        {
          id: ProductData.id,
          changedFields,
        }
      );
      setAlertMessage("Product updated successfully");
      console.log("Product updated successfully", response.data);
    } catch (error) {
      setAlertMessage("Error updating product");
      console.error("Error updating product", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProduct = async (e) => {
    setLoading(true);

    try {
      const responsedata = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/deleteproductdata",
        {
          id: ProductData.id,
        }
      );
      setAlertMessage("product deleted successfully");
    } catch (error) {
      setAlertMessage("Error deleting product");
      console.log("product delted successfully", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <RxCross1 /> */}
      <div className={classes.updateOverlayModel}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>UPDATE PRODUCT</h4>
          {/* <RxCross2
            style={{ fontSize: "16px", background: "black" }}
            onClick={handleModelViewChange}
          />
          <i class="fa-solid fa-xmark"></i> */}
          {/* <h6>hello how are you</h6> */}
          <RxCross1
            style={{ cursor: "pointer" }}
            onClick={handleModelViewChange}
          />
        </div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {alertMessage && (
              <div
                className={`alert ${
                  alertMessage.includes("successfully")
                    ? "alert-success"
                    : "alert-danger"
                }`}
                role="alert"
              >
                {alertMessage}
              </div>
            )}
            <form
              style={{ display: "flex", flexDirection: "column" }}
              onSubmit={updateFormData}
            >
              <label>Title</label>
              <input
                type="text"
                value={updateProductTitle}
                placeholder="Title"
                onChange={handleUpdateProductTitle}
              />
              <label>Permalink</label>
              <input
                type="text"
                value={productPermalink}
                placeholder="Permalink"
                onChange={handleUpdateProductPermalink}
              />
              <label>SEO Title</label>
              <input
                type="text"
                value={updateSeoTitle}
                placeholder="seo title"
                onChange={handleUpdateSeoTitle}
              />
              <label>SEO keywords</label>
              <input
                type="text"
                // value={ProductMetaData[0].meta_key}
                value={updateSeoKeywords}
                onChange={handleUpdateSeoKeywordsChange}
                placeholder="Seo keywords"
              />
              <label>SEO Description</label>
              <input
                type="text"
                value={updateSeoDescription}
                // value={ProductMetaData[0].meta_keywords}
                placeholder="seo Description"
                onChange={handleUpdateSeoDescriptionChange}
              />
              <label>Price</label>
              <input value={updatePrice} onChange={handleUpdateProductPrice} />
              <label>Max Price</label>
              <input
                value={updateMaxPrice}
                onChange={handleUpdateProductMaxPrice}
              />
              <label>Validation Period</label>
              <input
                value={updateValidationPeriod}
                placeholder="validation Period in Days"
                onChange={handleUpdateProductValidationPeriod}
              />
              <div className={classes.user_input_section}>
                <label>upload a Product Image</label>
                <input type="file" onChange={handleUpdateProductImage} />
              </div>
              <div className={classes.user_input_section}>
                <label>Upload a Product Mobile Image</label>
                <input type="file" />
              </div>
              <div className={classes.user_input_section}>
                <label>Upload a PDF</label>
                <input type="file" />
              </div>
              <div>
                <Editor
                  value={content}
                  // onEditorChange={handleUpdateProductDataDescription}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "20%",
                }}
              >
                <label>
                  <input
                    type="radio"
                    value="active"
                    checked={productStatus === 1}
                    onChange={handleProductUpdateChange}
                  />
                  Active
                </label>
                <label>
                  <input
                    type="radio"
                    value="inactive"
                    onChange={handleProductUpdateChange}
                    checked={productStatus === 0}
                  />
                  Inactive
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                }}
              >
                <button
                  type="submit"
                  style={{
                    borderRadius: "5px",
                    background: "blue",
                    height: "35px",
                    color: "white",
                    border: "none",
                  }}
                  // onClick={updateFormData}
                >
                  Update Product
                </button>
                <button
                  onClick={handleDeleteProduct}
                  style={{
                    borderRadius: "5px",
                    background: "red",
                    height: "35px",
                    color: "white",
                    border: "none",
                  }}
                >
                  Delete Product
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};
const UpdateProductOverlayModel = ({
  handleModelViewChange,
  selectedProduct,
  ProductData,
  ProductMetaData,
}) => {
  // console.log(ProductData);
  // console.log(selectedProduct);
  console.log(ProductMetaData);

  return (
    <>
      {ReactDom.createPortal(
        <Backdrop handleModelViewChange={handleModelViewChange} />,
        document.getElementById("backdrop-root")
      )}

      {ReactDom.createPortal(
        <UpdateProductModel
          handleModelViewChange={handleModelViewChange}
          selectedProduct={selectedProduct}
          ProductData={ProductData}
          ProductMetaData={ProductMetaData}
        />,
        document.getElementById("updateoverlay-productroot")
      )}
    </>
  );
};
export default UpdateProductOverlayModel;
