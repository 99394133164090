import React from "react";
import classes from "./IndividualExamPostTestSeriesCard.module.css";
// import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TestSeriesUnlockOverlay from "./TestSeriesUnlock";

const IndividualExamPostTestSeriesCard = ({
  examtestseriescarddata,
  exam_id,
  post_id,
}) => {
  const navigate = useNavigate();

  const handleIndividualTierTestSeriesChange = (tier_id) => {
    navigate(`/testSeriesSingle/${exam_id}/${post_id}/${tier_id}`);
  };

  const handleIndividiualTestSeries2Change = (tier_id) => {
    navigate(`/testSeriessingle/${exam_id}/${post_id}/${tier_id}`);
  }

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.IndividualExam_heading_section}>
          <h6 className={classes.IndividualExam_describe_category}>
            {examtestseriescarddata && examtestseriescarddata.post_tier_name}
          </h6>
        </div>
        <div className={classes.IndividualExam_card_description_section}>
          <h5>Features</h5>
          <div className={classes.IndividualExam_individual_unorder_list}>
            <ul className={classes.IndividualExam_basic_list_item}>
              <li>Mock Test based on latest pattern</li>
              <li>Previous year included pdf</li>
              <li>sectional based on different level </li>
              <li>chapterwise based on weightage</li>
            </ul>
          </div>

          <div className={classes.IndividualExam_card_footer_section}>
            <div className={classes.IndividualExam_card_btn_div}>
              <button
                className={classes.IndividualExam_buy_section}
                onClick={() => handleIndividualTierTestSeriesChange(examtestseriescarddata.id)}              >
                buy now
              </button>
              <button
                className={classes.IndividualExam_show_more_btn}
                onClick={() => handleIndividiualTestSeries2Change(examtestseriescarddata.id)}
              >
                show more
              </button>
            </div>

            
            <div className={classes.IndividualExam_footer_sub_section}>
              <p>
                use coupon code for extra discount...<b>Apply code</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualExamPostTestSeriesCard;
