// import React, { useState, useRef } from "react";
// import Multiselect from "multiselect-react-dropdown";
// import axios from "axios";
// import classes from "./UploadPaperTier2.module.css";

// const UploadPaperTier2 = () => {
//   const [examMode, setExamMode] = useState("");
//   const [paperCodes, setPaperCodes] = useState([]);
//   const [selectedPaperCodes, setSelectedPaperCodes] = useState([]);

//   const multiselectRef = useRef();
//   const docxReaderRef = useRef();

//   const fetchPaperCodes = async (selectedMode) => {
//     try {
//       const response = await axios.get(
//         "http://localhost:5000/getPaperCodestier2"
//       );

//       const codes = response.data.map((code) => ({
//         value: code.paper_code,
//         label: code.paper_code,
//       }));
//       setPaperCodes(codes);
//     } catch (error) {
//       console.error("Error fetching paper codes:", error);
//     }
//   };

//   const resetForm = () => {
//     setExamMode("");
//     setPaperCodes([]);
//     setSelectedPaperCodes([]);

//     if (multiselectRef.current) {
//       multiselectRef.current.resetSelectedValues();
//     }
//     if (docxReaderRef.current) {
//       docxReaderRef.current.value = "";
//     }
//   };

//   return (
//     <>
//       <h1 className={classes.create_paper_tier2_h1}>Paper Code Add Tier 2</h1>
//       <label className={classes.exam_mode_select}>Exam Mode:</label>
//       <select
//         value={examMode}
//         className={classes.exam_mode_value}
//         onChange={(e) => {
//           setExamMode(e.target.value);
//           fetchPaperCodes(e.target.value);
//         }}
//       >
//         <option value="1">Mock Test -Full length</option>
//         {/* <option value="2">Sectional</option>
//         <option value="3">Chapter wise </option>
//         <option value="4">PYP Full length</option> */}
//         <option value="5">PYP-Full length(New Pattern)</option>
//       </select>

//       <label className={classes.paper_code}>Paper Code:</label>
//       <Multiselect
//         style={{ width: "100%" }}
//         options={paperCodes}
//         selectedValues={selectedPaperCodes}
//         onSelect={(selectedList) => {
//           setSelectedPaperCodes(selectedList);
//         }}
//         onRemove={(selectedList) => {
//           setSelectedPaperCodes(selectedList);
//         }}
//         displayValue="label"
//         closeOnSelect={true}
//         selectionLimit={1}
//         ref={multiselectRef}
//         required
//       />

//       {/* <label>Select Exam</label>
//       <input></input> */}
//       <label className={classes.exam_mode_select}>Select Exam:</label>
//       <select className={classes.exam_mode_selected}>
//         <option value="" disabled hidden>
//           Select Exam
//         </option>
//         <option value="1">SSC</option>
//         <option value="3">Railway</option>
//         <option value="2">Delhi police</option>
//         <option value="6">UPSC</option>
//         <option value="5">State PCS</option>
//         <option value="8">ACIO IB</option>
//         <option value="4">Teaching</option>
//         <option value="7">Others</option>
//       </select>
//       {/* {selectExam && (
//             <select
//               value={selectExamPost}
//               onChange={handleSelectExamPost}
//               required
//             >
//               <option value="" disabled hidden>
//                 Select Exam Post
//               </option>
//               {examPostData.map((data) => (
//                 <option key={data.id} value={data.id}>
//                   {data.exam_post_name}
//                 </option>
//               ))}
//             </select>
//           )}
//           {selectExam === "1" && (
//             <select
//               value={selectExamPostTier}
//               onChange={(e) => setSelectExamPostTier(e.target.value)}
//               required
//             >
//               <option value="" disabled hidden>
//                 Select Exam Post Tier
//               </option>
//               <option value="1">Tier 1</option>
//               <option value="2">Tier 2</option>
//               <option value="3">Tier 3</option>
//               <option value="4">Tier 4</option>
//             </select>
//           )} */}

//       <option>Positive Mark:</option>
//       <input
//         className={classes.positiveMark}
//         // value={positiveMark}
//         // onChange={(e) => setPositiveMark(e.target.value)}
//       />

//       <option>Negative Mark:</option>
//       <input
//         className={classes.negativeMark}
//         // value={negativeMark}
//         // onChange={(e) => setNegativeMark(e.target.value)}
//         required
//       />

//       <div className={classes.docx_reader_container}>
//         <h4>Upload Question Paper</h4>
//         {/* <DocxReader2
//           onProcessedData={handleProcessedData}
//           ref={docxReaderRef}
//           onFileSelected={handleFileSelected}
//         /> */}
//         <input type="file"></input>
//       </div>
//       <div className={classes.submit_button_container}>
//         <button
//           type="submit"
//           className={classes.submit_button}
//           // disabled={isSubmitting}
//           // onClick={handleSubmit}
//         >Submit
//           {/* {isSubmitting ? "Submitting..." : "SUBMIT"} */}
//         </button>
//       </div>

//       {/* <label>Positive Marks:</label>
//       <input></input>
//       <label>Negative Marks</label>
//       <label>upload Question Paper</label>
//       <input type="file"></input> */}
//       {/* <button type="button" onClick={resetForm}>
//         Reset Form
//       </button> */}
//     </>
//   );
// };

// export default UploadPaperTier2;

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import DocxReader2 from "./DocxReader2";
import Multiselect from "multiselect-react-dropdown";
import classes from "./PapercodeAdd.module.css";
import Cookies from "universal-cookie";

const UploadPaperCodeTier2 = () => {
  const [examMode, setExamMode] = useState("");
  const [paperCode, setPaperCode] = useState([]);
  const [selectedPaperCodes, setSelectedPaperCodes] = useState([]);
  const [positiveMark, setPositiveMark] = useState("");
  const [negativeMark, setNegativeMark] = useState("");
  const [englishquestions, setQuestions] = useState([]);
  const [englishoptionA, setOptionsA] = useState([]);
  const [englishoptionB, setOptionsB] = useState([]);
  const [englishoptionC, setOptionsC] = useState([]);
  const [englishoptionD, setOptionsD] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [sections, setSections] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [hindiquestions, setHindiQuestions] = useState([]);
  const [HindioptionA, setHindiOptionsA] = useState([]);
  const [HindioptionB, setHindiOptionsB] = useState([]);
  const [HindioptionC, setHindiOptionsC] = useState([]);
  const [HindioptionD, setHindiOptionsD] = useState([]);
  const [hindiSolutions, setHindiSolutions] = useState([]);
  const [selectExam, setSelectExam] = useState("");
  const [selectExamPost, setSelectExamPost] = useState("");
  const [selectExamPostTier, setSelectExamPostTier] = useState("");
  const [examPostData, setExamPostData] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);

  const multiselectRef = useRef();
  const docxReaderRef = useRef();
  const cookies = new Cookies();

  const token = cookies.get("token");

  const handleProcessedData = (data) => {
    setQuestions(data.englishquestions);
    setOptionsA(data.englishoptionA);
    setOptionsB(data.englishoptionB);
    setOptionsC(data.englishoptionC);
    setOptionsD(data.englishoptionD);
    setHindiQuestions(data.hindiquestions);
    setHindiOptionsA(data.HindioptionA);
    setHindiOptionsB(data.HindioptionB);
    setHindiOptionsC(data.HindioptionC);
    setHindiOptionsD(data.HindioptionD);
    setAnswers(data.answer);
    setSections(data.section);
    setSolutions(data.solutions);
    setHindiSolutions(data.hindisolutions);
  };

  const questionsData = {
    englishquestions,
    englishoptionA,
    englishoptionB,
    englishoptionC,
    englishoptionD,
    hindiquestions,
    HindioptionA,
    HindioptionB,
    HindioptionC,
    HindioptionD,
    answers,
    sections,
    solutions,
    hindiSolutions,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(selectedPaperCodes);
    console.log("State before submission:", {
      examMode,
      selectedPaperCodes,
      positiveMark,
      negativeMark,
      selectExam,
      selectExamPost,
      selectExamPostTier,
    });

    // if (

    //   // !examMode ||
    //   // !selectedPaperCodes.length ||
    //   // !selectExam ||
    //   // !positiveMark ||
    //   // !negativeMark ||
    //   // !fileSelected
    // ) {
    //   alert("Please fill all required fields.");
    //   return;
    // }

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("examMode", examMode);
      formData.append("positiveMark", positiveMark);
      formData.append("negativeMark", negativeMark);
      formData.append("paperCode", selectedPaperCodes);
      formData.append("exam_id", selectExam);
      formData.append("exam_post_id", selectExamPost);
      formData.append("exam_post_tier_id", selectExamPostTier);
      formData.append("questionsData", JSON.stringify(questionsData));


      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      console.log(JSON.stringify(selectedPaperCodes));
      console.log(data);
      // console.log("formData:",  selectedPaperCodes.map(item => item.paper_code) );

      console.log(formData);

      // const response = await axios.post(
      //   "http://localhost:5000/papercode/addTier2",
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );

      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/papercode/addCglTier2",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token} `,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        resetForm();
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.message || "Something went wrong!");
      console.error("Error adding product:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setExamMode("");
    setPaperCode([]);
    setSelectedPaperCodes([]);
    setPositiveMark("");
    setNegativeMark("");
    setQuestions([]);
    setOptionsA([]);
    setOptionsB([]);
    setOptionsC([]);
    setOptionsD([]);
    setHindiQuestions([]);
    setHindiOptionsA([]);
    setHindiOptionsB([]);
    setHindiOptionsC([]);
    setHindiOptionsD([]);
    setAnswers([]);
    setSections([]);
    setSolutions([]);
    setHindiSolutions([]);
    setSelectExam("");
    setSelectExamPost("");
    setSelectExamPostTier("");
    setExamPostData([]);
    setFileSelected(false);

    multiselectRef.current.resetSelectedValues();
    docxReaderRef.current.resetFields();
  };

  const handleSelectExamMode = async (selectedMode) => {
    try {
      setExamMode(selectedMode);
    } catch (error) {
      console.error("Error setting selected exam mode:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (examMode) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_PINNACLE_BASE_URL}/getPaperCodestier2`,
            // "http://localhost:5000/getPaperCodestier2",
            {
              params: {
                exam_mode_id: examMode, // Use the selected exam mode here
              },
            }
          );
          // setData(response.data);

          console.log(response.data);

          const codes = response.data.map((code) => ({
            value: { paper_code: code.paper_code },
            label: code.paper_code,
          }));
          setPaperCode(codes);

          console.log(paperCode);
          // Get data from response
          // Store the data in state
        } catch (error) {
          console.error("Error retrieving paper code data:", error);
        }
      }
    };

    fetchData();
  }, [examMode]);

  // Dependency array includes examMode

  console.log(data);
  const handleSelectExam = async (e) => {
    let selectedExamId = e.target.value;
    setSelectExam(selectedExamId);

    const response = await axios.post(
      process.env.REACT_APP_PINNACLE_BASE_URL +
        "/api/v1/admin/postselectedexamid",
      {
        exam_id: selectedExamId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setExamPostData(response.data);
  };

  const handleSelectExamPost = (e) => {
    let selectExamPostName = e.target.value;
    setSelectExamPost(selectExamPostName);
  };

  const handleFileSelected = () => {
    setFileSelected(true);
  };

  // console.log("line 387", data);

  return (
    <div className={classes.formData}>
      <h1 className={classes.papercode_add_h1}>Paper Code Add Tier 2</h1>
      <form>
        <label className={classes.exam_mode_select}>
          Exam Mode:
          <select
            type="text"
            value={examMode}
            onChange={(e) => handleSelectExamMode(e.target.value)}
            // required
          >
            <option value="" disabled hidden>
              Select Exam Mode
            </option>
            <option value="1">Mock test- Full length</option>
            {/* <option value="2">Sectional Test</option>
            <option value="3">Chapter Test</option>
            <option value="4">PYP- Full Length</option> */}
            <option value="5">PYP-Full Length(New Pattern)</option>
          </select>
        </label>

            <label className={classes.paper_code}>Paper Code:</label>
            <Multiselect
              style={{ width: "100%" }}
              options={paperCode} // use the correct array containing the paper code data
              onSelect={(selectedList) => {
                const selectedValues = selectedList.map((item) => item.value);
                setSelectedPaperCodes(selectedValues[0].paper_code);
              }}
              onRemove={(selectedList) => {
                const selectedValues = selectedList.map((item) => item.value);
                setSelectedPaperCodes(selectedValues);
              }}
              displayValue="label" // display the actual paper code value
              closeOnSelect={true}
              selectionLimit={1}
              ref={multiselectRef}
              // required
            />

        <label className={classes.exam_mode_select}>
          Select Exam:
          <select value={selectExam} onChange={handleSelectExam} required>
            <option value="" disabled hidden>
              Select Exam
            </option>
            <option value="1">SSC</option>
            <option value="3">Railway</option>
            <option value="2">Delhi police</option>
            <option value="6">UPSC</option>
            <option value="5">State PCS</option>
            <option value="8">ACIO IB</option>
            <option value="4">Teaching</option>
            <option value="7">Others</option>
          </select>
          {selectExam && (
            <select
              value={selectExamPost}
              onChange={handleSelectExamPost}
              required
            >
              <option value="" disabled hidden>
                Select Exam Post
              </option>
              {examPostData.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.exam_post_name}
                </option>
              ))}
            </select>
          )}
          {selectExam === "1" && (
            <select
              value={selectExamPostTier}
              onChange={(e) => setSelectExamPostTier(e.target.value)}
              // required
            >
              <option value="" disabled hidden>
                Select Exam Post Tier
              </option>
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
              <option value="4">Tier 4</option>
            </select>
          )}
        </label>
        <div className={classes.markinginput}>
          <label>
            Positive Mark:
            <input
              value={positiveMark}
              onChange={(e) => setPositiveMark(e.target.value)}
              // required
            />
          </label>
        </div>
        <div className={classes.markinginput}>
          <label>
            Negative Mark:
            <input
              value={negativeMark}
              onChange={(e) => setNegativeMark(e.target.value)}
              // required
            />
          </label>
        </div>
        <div className={classes.docx_reader_container}>
          <h4>Upload Question Paper</h4>
          <DocxReader2
            onProcessedData={handleProcessedData}
            ref={docxReaderRef}
            onFileSelected={handleFileSelected}
          />
        </div>
        <div className={classes.submit_button_container}>
          <button
            type="submit"
            className={classes.submit_button}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? "Submitting..." : "SUBMIT"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadPaperCodeTier2;
