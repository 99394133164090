import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import Annotation  from 'chartjs-plugin-annotation';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Annotation);

export default function LineGraph() {
    const marks = [1200, 2900, 1800, 500, 2000, 2300, 3400, 4500, 6700, 9000];
    const average = marks.reduce((acc, cur) => acc + cur, 0) / marks.length;
    console.log("average: ", average)
    const sortedMarks = [...marks].sort((a, b) => a - b);
    console.log("sortedMarks: ", sortedMarks);
    const median = sortedMarks.length % 2 !== 0 ? sortedMarks[Math.floor(sortedMarks.length / 2)] : (sortedMarks[sortedMarks.length / 2 - 1] + sortedMarks[sortedMarks.length / 2]) / 2;
    console.log("median", median);
    const currentUserMark = 4500; // Assuming this is the specific user's mark for demonstration

    const data = {
        labels: ['0 to 13', '13 to 26', '26 to 39', '39 to 52', '52 to 65', '65 to 78', '78 to 91', '91 to 104', '104 to 117', '117 to 130'],
        datasets: [
            {
                label: 'Marks per Decile',
                data: marks,
                borderColor: 'blue',
                backgroundColor: 'rgba(0, 123, 255, 0.5)',
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Marks per Decile'
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Marks'
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            annotation: {
                annotations: {
                    pointAnnotation: {
                        type: 'label',
                        // xValue: 12,
                        // yValue: 8,
                        content: 'You are here: 123',
                        backgroundColor: 'rgba(255, 99, 132, 0.095)',
                        borderRadius: 4,
                        color: 'blue',
                        font: {
                            weight: 'bold'
                        }
                    },
                    averagePoint: {
                        type: 'point',
                        xValue: marks.indexOf(average),
                        yValue: average,
                        backgroundColor: 'yellow',
                        radius: 5,
                        label: {
                            content: `Average: ${average.toFixed(2)}`,
                            enabled: true,
                            position: 'top'
                        }
                    },
                    medianPoint: {
                        type: 'point',
                        xValue: marks.indexOf(median),
                        yValue: median,
                        backgroundColor: 'green',
                        radius: 5,
                        label: {
                            content: `Median: ${median.toFixed(2)}`,
                            enabled: true,
                            position: 'top'
                        }
                    },
                    userMark: {
                        // type: 'point',
                        type: 'label',
                        content: 'You are here: 123',
                        xValue: marks.indexOf(currentUserMark),
                        yValue: currentUserMark,
                        backgroundColor: 'purple',
                        radius: 5,
                        label: {
                            content: 'You are here',
                            enabled: true,
                            position: 'right'
                        }
                    }
                }
            }
        }
    };

    return <Line data={data} options={options} />;
}


// {
//     type: 'doughnut',
//     data: {
//       labels: labels,
//       datasets: [{
//         backgroundColor: Utils.colors({
//           color: Utils.color(0),
//           count: DATA_COUNT
//         }),
//         data: Utils.numbers({
//           count: DATA_COUNT,
//           min: 0,
//           max: 100
//         }),
//         datalabels: {
//           anchor: 'end'
//         }
//       }, {
//         backgroundColor: Utils.colors({
//           color: Utils.color(1),
//           count: DATA_COUNT
//         }),
//         data: Utils.numbers({
//           count: DATA_COUNT,
//           min: 0,
//           max: 100
//         }),
//         datalabels: {
//           anchor: 'center',
//           backgroundColor: null,
//           borderWidth: 0
//         }
//       }, {
//         backgroundColor: Utils.colors({
//           color: Utils.color(2),
//           count: DATA_COUNT
//         }),
//         data: Utils.numbers({
//           count: DATA_COUNT,
//           min: 0,
//           max: 100
//         }),
//         datalabels: {
//           anchor: 'start'
//         }
//       }]
//     },
//     options: {
//       plugins: {
//         datalabels: {
//           backgroundColor: function(context) {
//             return context.dataset.backgroundColor;
//           },
//           borderColor: 'white',
//           borderRadius: 25,
//           borderWidth: 2,
//           color: 'white',
//           display: function(context) {
//             var dataset = context.dataset;
//             var count = dataset.data.length;
//             var value = dataset.data[context.dataIndex];
//             return value > count * 1.5;
//           },
//           font: {
//             weight: 'bold'
//           },
//           padding: 6,
//           formatter: Math.round
//         }
//       },
//   // Core options
//       aspectRatio: 4 / 3,
//       cutoutPercentage: 32,
//       layout: {
//         padding: 32
//       },
//       elements: {
//         line: {
//           fill: false
//         },
//         point: {
//           hoverRadius: 7,
//           radius: 5
//         }
//       },
//     }
//   }



// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
// import Annotation  from 'chartjs-plugin-annotation';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Annotation);

// export default function LineGraph() {
//     // Generate random marks for 50 students
//     const marks = Array.from({ length: 50 }, () => Math.floor(Math.random() * 131)); // Random marks between 0 and 130
//     console.log("Marks: ", marks);

//     // Calculate average and median marks
//     const average = marks.reduce((acc, cur) => acc + cur, 0) / marks.length;
//     const sortedMarks = [...marks].sort((a, b) => a - b);
//     const median = sortedMarks.length % 2 !== 0 ? sortedMarks[Math.floor(sortedMarks.length / 2)] : (sortedMarks[sortedMarks.length / 2 - 1] + sortedMarks[sortedMarks.length / 2]) / 2;

//     const data = {
//         labels: ['0 to 13', '13 to 26', '26 to 39', '39 to 52', '52 to 65', '65 to 78', '78 to 91', '91 to 104', '104 to 117', '117 to 130'],
//         datasets: [
//             {
//                 label: 'Number of Students',
//                 data: [marks.filter(mark => mark >= 0 && mark <= 13).length, marks.filter(mark => mark > 13 && mark <= 26).length, marks.filter(mark => mark > 26 && mark <= 39).length, marks.filter(mark => mark > 39 && mark <= 52).length, marks.filter(mark => mark > 52 && mark <= 65).length, marks.filter(mark => mark > 65 && mark <= 78).length, marks.filter(mark => mark > 78 && mark <= 91).length, marks.filter(mark => mark > 91 && mark <= 104).length, marks.filter(mark => mark > 104 && mark <= 117).length, marks.filter(mark => mark > 117 && mark <= 130).length],
//                 borderColor: 'blue',
//                 backgroundColor: 'rgba(0, 123, 255, 0.5)',
//             }
//         ]
//     };

//     const options = {
//         scales: {
//             y: {
//                 beginAtZero: true,
//                 title: {
//                     display: true,
//                     text: 'Number of Students'
//                 }
//             },
//             x: {
//                 title: {
//                     display: true,
//                     text: 'Marks Range'
//                 }
//             }
//         },
//         plugins: {
//             legend: {
//                 display: false
//             },
//             annotation: {
//                 annotations: {
//                     averagePoint: {
//                         type: 'point',
//                         xValue: '5', // Marks range '52 to 65'
//                         yValue: average,
//                         backgroundColor: 'yellow',
//                         radius: 5,
//                         label: {
//                             content: `Average: ${average.toFixed(2)}`,
//                             enabled: true,
//                             position: 'top'
//                         }
//                     },
//                     medianPoint: {
//                         type: 'point',
//                         xValue: '5', // Marks range '52 to 65'
//                         yValue: median,
//                         backgroundColor: 'green',
//                         radius: 5,
//                         label: {
//                             content: `Median: ${median.toFixed(2)}`,
//                             enabled: true,
//                             position: 'top'
//                         }
//                     }
//                 }
//             }
//         }
//     };

//     return <Line data={data} options={options} />;
// }




// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
// import Annotation from 'chartjs-plugin-annotation';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Annotation);

// export default function LineGraph() {
//     // Generate random marks for 50 students
//     // const marks = Array.from({ length: 50 }, () => Math.floor(Math.random() * 131)); // Random marks between 0 and 130
//     // console.log("Marks: ", marks);

//     const marks = [2, 2, 6, 8, 9, 10, 10, 12, 13, 18, 19, 21, 21, 25, 25, 27, 27, 30, 31, 32, 34, 34, 47, 48, 49, 50, 55, 60, 61, 64, 66, 68, 69, 72, 81, 86, 88, 89, 94, 96, 105, 107, 107, 113, 115, 120, 121, 122, 128, 129]
//     // Calculate average and median marks
//     const average = marks.reduce((acc, cur) => acc + cur, 0) / marks.length;
//     console.log("average",average);

//     const sortedMarks = [...marks].sort((a, b) => a - b);

//     // console.log("sortedMarks", sortedMarks)
//     const median = sortedMarks.length % 2 !== 0 ? sortedMarks[Math.floor(sortedMarks.length / 2)] : (sortedMarks[sortedMarks.length / 2 - 1] + sortedMarks[sortedMarks.length / 2]) / 2;

//     console.log("median", median);

//     // Calculate the count of students in each marks range
//     const counts = Array.from({ length: 10 }, (_, index) => {
//         const lowerBound = index * 13;
//         const upperBound = lowerBound + 13;
//         return marks.filter(mark => mark >= lowerBound && mark < upperBound).length;
//     });
//     console.log("Counts: ", counts);

//     // Find the maximum count of students
//     // const maxCount = Math.max(...counts);
//     const maxCount = Math.ceil(Math.max(...counts) * 1.1);


//     const data = {
//         labels: ['0 to 13', '13 to 26', '26 to 39', '39 to 52', '52 to 65', '65 to 78', '78 to 91', '91 to 104', '104 to 117', '117 to 130'],
//         datasets: [
//             {
//                 label: 'Number of Students',
//                 data: counts,
//                 borderColor: 'blue',
//                 backgroundColor: 'rgba(0, 123, 255, 0.5)',
//             }
//         ]
//     };

    
//     const currentUserMark = 77;
//     const rangeIndex = Math.floor(currentUserMark / 13);

//     const options = {
//         scales: {
//             y: {
//                 beginAtZero: true,
//                 title: {
//                     display: true,
//                     text: 'Number of Students'
//                 },
//                 max: maxCount // Set the maximum value for the y-axis
//             },
//             x: {
//                 title: {
//                     display: true,
//                     text: 'Marks Range'
//                 }
//             }
//         },
//         plugins: {
//             legend: {
//                 display: false
//             },
//             annotation: {
//                 annotation: {
//                     annotations: {
//                         averagePoint: {
//                             type: 'point',
//                             xValue: '5', // Marks range '52 to 65'
//                             yValue: average,
//                             backgroundColor: 'yellow',
//                             radius: 5,
//                             label: {
//                                 content: `Average: ${average.toFixed(2)}`,
//                                 enabled: true,
//                                 position: 'top'
//                             }
//                         },
//                         medianPoint: {
//                             type: 'point',
//                             xValue: '5', // Marks range '52 to 65'
//                             yValue: median,
//                             backgroundColor: 'green',
//                             radius: 5,
//                             label: {
//                                 content: `Median: ${median.toFixed(2)}`,
//                                 enabled: true,
//                                 position: 'top'
//                             }
//                         },
//                         userMark: {
//                             type: 'point',
//                             xValue: currentUserMark,
//                             yValue: rangeIndex,
//                             backgroundColor: 'purple',
//                             radius: 5000,
//                             label: {
//                                 content: 'You are here',
//                                 enabled: true,
//                                 position: 'right'
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     };


//     return <Line data={data} options={options} />;
// }
