import React from "react";
import { useNavigate } from "react-router-dom";
import ReactDom from "react-dom";

import { RxCross1 } from "react-icons/rx";
import CashFreeButton from "./CashFreeButton";
import classes from "./TestSeriesUnlock.module.css";
// import scratchcard from "../images/scratchcard.jpg";
// import axios from "axios";
// import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";

const Backdrop = ({ handleModelChange }) => {
  return <div className={classes.backdrop} onClick={handleModelChange}></div>;
};

const TestSeriesUnloack = ({ handleModelChange, passData }) => {
  const items = useSelector((state) => state.userProfile);

  console.log("line 12 testseriesunlock", passData);

  const navigate = useNavigate();

  const formData = {
    id: passData.id,
  };

  return (
    <div className={classes.updateOverlayModel}>
      <RxCross1 onClick={handleModelChange} style={{ cursor: "pointer" }} />

      <div className={classes.product_purchase_section}>
        <div className={classes.arrange_section}>
          <h4> Product :</h4>
          <h5> {passData.Product_title}</h5>
        </div>

        <div className={classes.arrange_section}>
          <h4> Validation Period :</h4>
          <h5>{passData.product_validation_period}</h5>
        </div>
      </div>

      <div className={classes.product_purchase_section}>
        <div className={classes.arrange_section}>
          <h4>Product Price :</h4>
          {/* <h4>{passData.Product_price}</h4> */}
          <h5>{passData.product_price}</h5>
        </div>

        <div className={classes.arrange_section}>
          <h4>Discounted Price :</h4>
          {/* // gettestpassData && gettestpassData[0].product_max_price */}

          <h5>{passData.product_max_price}</h5>
        </div>
      </div>
      <div className={classes.scratch_card_div}>
        <p>use coupen code for extra discount ...</p>
        <button className={classes.apply_code_btn}>Apply code</button>
        {/* <img
          style={{ height: "38px", width: "42px" }}
          src={scratchcard}
          alt=""
        /> */}
      </div>
      <div className={classes.pay_now_btn}>
        {/*
        <button>Pay Now</button>
        */}
        <CashFreeButton productId={passData.id} user={items} />
      </div>
    </div>
  );
};

const TestSeriesUnlockOverlay = ({ handleModelChange, passData }) => {
  return (
    <>
      {ReactDom.createPortal(
        <Backdrop handleModelChange={handleModelChange} />,
        document.getElementById("backdrop-root")
      )}

      {ReactDom.createPortal(
        <TestSeriesUnloack
          passData={passData}
          handleModelChange={handleModelChange}
        />,
        document.getElementById("updateoverlay-root")
      )}
    </>
  );
};

export default TestSeriesUnlockOverlay;
