import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";

import OtpVerification from "./OtpVerification";
import editIcon from "../../assets/edit.png";
import classes from "./DummyComponent.module.css";
// import CleverTap from "clevertap-web-sdk";

import { useDispatch } from "react-redux";
import { addProfile } from "../../redux/slices/userProfileSlice";
import GoogleAuth from "./GoogleAuth";

const DummyComponent = ({ initialSeconds = 180 }) => {
  const [loginWithMobile, setLoginWithMobile] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsModalShow, setTermsModalShow] = useState(false); // State for terms modal
  const [termsContent, setTermsContent] = useState(""); // State to store fetched terms content

  const [seconds, setSeconds] = useState(initialSeconds);
  const [startTimer, setStartTimer] = useState(false);
  const [showMessage, setShowMessage] = useState(null); // State to show the response message
  const timerRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (startTimer && seconds > 0) {
      timerRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(timerRef.current);
  }, [startTimer, seconds]);

  const handleEditMobileNumber = (e) => {
    setLoginWithMobile(false);
    setStartTimer(false);
    clearInterval(timerRef.current);
    setShowMessage(null); // hide the response message
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault(); // Prevent form submission

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setShowMessage("Invalid Email Address");
      return;
    }

    const email_id = email;
    const data = { email_id };
    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/users/emailVerification",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData);

        setShowMessage(responseData.message); // Show the response message

        // setTimeout(() => {
        //   navigate("/"); // Navigate after 1.5 seconds
        // }, 1500);
      } else {
        console.error("Something went wrong in sending email verification");
        setShowMessage("responseData.message");
        // throw new Error("Could not fetch events");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowMessage(error.message);
    }
  };

  const handleSendOtp = async (useWhatsApp = false) => {
    const mobileNumberRegex = /^[6-9]\d{9}$/; // Indian mobile number format
    if (!mobileNumber || !mobileNumberRegex.test(mobileNumber)) {
      setShowMessage("Invalid Mobile Number");
      return;
    }

    // console.log("MOBILE_NUMBER", mobileNumber);

    // const data = new FormData();
    // data.append('mobileNumber', mobileNumber);

    const data = useWhatsApp ? { whatsApp: 1, mobileNumber } : { mobileNumber };
    console.log("data", data);

    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/users/mobileVerification",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      // const responseData = await response.json();
      // console.log(responseData);
      const responseData = await response.json();
      if (response.ok) {
        console.log("responseData", responseData);
        console.log("responseData.data", responseData.data);

        setShowMessage(responseData.message); // Show the response message

        setTimeout(() => {
          setShowMessage(null); // hide the response message
          clearInterval(timerRef.current);
          setLoginWithMobile(true);
          setStartTimer(true);
          setSeconds(initialSeconds);
        }, 2000);
      } else {
        console.error("Something went wrong in sending OTP");
        setShowMessage(responseData.message); // Show the response message
        // throw new Error("Something went wrong in sending OTP");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowMessage(error.message); // Show the response message
    }
  };

  // const handleWhatsAppOtp = async () => {

  //   console.log("MOBILE_NUMBER", mobileNumber);

  //   // const data = new FormData();
  //   // data.append('mobileNumber', mobileNumber);

  //   // const data = { mobileNumber}
  //   const data = { mobileNumber };

  //   console.log("data", data);

  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/mobileVerification-whatsApp-otp",
  //       {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify(data),
  //       }
  //     );
  //     // const responseData = await response.json();
  //     // console.log(responseData);
  //     if (response.ok) {
  //       const responseData = await response.json();
  //       console.log("responseData", responseData);
  //       console.log("responseData.data", responseData.data);

  //       setShowMessage(responseData.message); // Show the response message

  //       setTimeout(() => {
  //         setShowMessage(null); // hide the response message
  //         clearInterval(timerRef.current);
  //         setLoginWithMobile(true);
  //         setStartTimer(true);
  //         setSeconds(initialSeconds);
  //       }, 2000);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleVerifyOtp = async (mobileOtp) => {
    const data = { otp: mobileOtp, mobileNumber };

    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/users/mobileOtpVerificationReport",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);

        setShowMessage(responseData.message); // Show the response message
        dispatch(addProfile(responseData.data));

        // with the exception of one of Identity, Email, or FBID
        // each of the following fields is optional

        if (window.clevertap) {
          // const formattedPhone = responseData.data.mobile_number
          //   ? `+91${responseData.data.mobile_number.trim()}`
          //   : null;
          window.clevertap.onUserLogin.push({
            Site: {
              Name: responseData.data.full_name || null,
              Identity: responseData.data.id || null,
              Email: responseData.data.email_id || " ",
              Phone: `(+91) ${responseData.mobile_number}`,
              "MSG-email": false,
              "MSG-push": true,
              "MSG-sms": true,
              "MSG-whatsapp": true,
            },
          });
          console.log("CleverTap profile updated successfully");
        } else {
          console.error("CleverTap is not available on the window object");
        }

        setTimeout(() => {
          setShowMessage(null); // hide the response message
          navigate("/email-verification"); // Navigate after 2 seconds
          // clearInterval(timerRef.current);
          // setLoginWithMobile(true);
          // setStartTimer(true);
          // setSeconds(initialSeconds);
        }, 2000);
      } else {
        console.error("Wrong OTP");
        setShowMessage("Wrong OTP"); // Show the response message
        // throw new Error("Could not fetch events");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowMessage(error.message);
    }
  };

  const fetchTermsContent = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/terms-and-conditions"
      );
      if (response.ok) {
        const data = await response.json();
        console.log("FORGOT PASSWORD", data);
        setTermsContent(data.data.terms_conditions);
      } else {
        setTermsContent("Failed to load terms and conditions.");
      }
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
      setTermsContent(error.message);
    }
  };

  const handleShowTermsModal = () => {
    fetchTermsContent();
    setTermsModalShow(true);
  };

  const handleCloseTermsModal = () => {
    setTermsModalShow(false);
  };

  const mobileChangeHandler = (e) => {
    setMobileNumber(e.target.value);
    setShowMessage(null);
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
    setShowMessage(null);
  };

  return (
    <Container>
      {!loginWithMobile && (
        <Form>
          <Row style={{ marginBottom: "2rem" }}>
            <GoogleAuth />
          </Row>
          <Form.Group controlId="formMobile" className="form-div">
            <Form.Label>Please enter your mobile number to register</Form.Label>
            <Row>
              <Col xs={2}>
                <Form.Control
                  type="text"
                  className="input-field"
                  value="+91"
                  readOnly
                />
              </Col>
              <Col xs={7} className="p-0">
                <Form.Control
                  // type="text"
                  type="tel" // Specifies this as a telephone number input
                  placeholder="enter your 10 digit mobile number"
                  className="input-field"
                  value={mobileNumber}
                  onChange={(e) => mobileChangeHandler(e)}
                  autoComplete="tel" // Hints the browser to suggest only phone numbers
                  maxLength={10} // Ensures the user can only enter a 10-digit number
                />
              </Col>
              <Col xs={3}>
                <Button
                  variant="primary"
                  onClick={() => handleSendOtp(false)}
                  className="get-otp-btn"
                >
                  Get OTP
                </Button>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>or register with email ID</Form.Label>
            <Row>
              <Col xs={9} className="pe-0">
                <Form.Control
                  type="email"
                  placeholder="enter your email id"
                  className="input-field"
                  value={email}
                  onChange={(e) => emailChangeHandler(e)}
                  autoComplete="email" // Explicitly specify email-only autocomplete
                  name="email" // Use a clear, specific name for email
                />
              </Col>
              <Col xs={3}>
                <Button
                  variant="primary"
                  onClick={handleEmailLogin}
                  className="email-submit-btn"
                >
                  Submit
                </Button>
              </Col>
            </Row>
            {/* <Row>
              <GoogleAuth />
            </Row> */}
          </Form.Group>
        </Form>
      )}

      {loginWithMobile && (
        <div>
          <div className={classes.mobileNumber}>
            <p>(+91) {mobileNumber}</p>
            <img
              src={editIcon}
              onClick={handleEditMobileNumber}
              alt="Edit Phone No."
            />
          </div>
          <div>
            {startTimer && seconds > 0 && (
              <div className={classes.resendOtp}>
                <p style={{ fontSize: "11px", color: "darkblue" }}>
                  Resend OTP in &nbsp;
                  {Math.floor(seconds / 60)}:
                  {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                  &nbsp; minutes
                </p>
                <button
                  className={classes.actionButton}
                  onClick={() => handleSendOtp(true)}
                >
                  WhatsApp
                </button>
              </div>
            )}

            {startTimer && seconds === 0 && (
              <div className={classes.resendOtp}>
                <button
                  className={classes.actionButton}
                  onClick={() => handleSendOtp(false)}
                >
                  Resend OTP
                </button>
                <button
                  className={classes.actionButton}
                  // onClick={() => handleWhatsAppOtp()}
                  onClick={() => handleSendOtp(true)}
                >
                  WhatsApp
                </button>
              </div>
            )}

            <div>
              <label style={{ width: "100%" }}>
                <p>Enter One Time Password (OTP)</p>
                {/* <Form.Check
                  type="checkbox"
                  id="termsCheckbox"
                  // label="I agree to terms & conditions"
                  label={
                    <span
                      onClick={handleShowTermsModal}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      I agree to terms & conditions
                    </span>
                  }
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                /> */}
                <Form.Check
                  type="checkbox"
                  id="termsCheckbox"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px", // Spacing between checkbox and label
                  }}
                >
                  <Form.Check.Input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    style={{
                      width: "18px", // Custom checkbox size
                      height: "18px",
                      border: "2px solid black", // Thick border for checkbox
                      borderRadius: "4px", // Rounded corners
                      appearance: "none", // Remove default browser styling
                      backgroundColor: termsAccepted ? "#007bff" : "white", // Custom background color when checked
                      cursor: "pointer",
                    }}
                  />
                  <Form.Check.Label
                    htmlFor="termsCheckbox"
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline", // Optional underline for the label text
                    }}
                    onClick={handleShowTermsModal} // Opens terms modal on label click
                  >
                    I agree to terms & conditions
                  </Form.Check.Label>
                </Form.Check>
                <OtpVerification
                  handleVerifyOtp={handleVerifyOtp}
                  termsAccepted={termsAccepted}
                />
              </label>
            </div>
          </div>
        </div>
      )}
      {showMessage && (
        <Alert variant="success" className={classes.alert}>
          {showMessage}
        </Alert>
      )}

      <Modal show={termsModalShow} onHide={handleCloseTermsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {termsContent ? (
            <div dangerouslySetInnerHTML={{ __html: termsContent }} />
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTermsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DummyComponent;

// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate  } from "react-router-dom";
// import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";

// import OtpVerification from "./OtpVerification";
// import editIcon from "../assets/edit.png";
// import classes from "./DummyComponent.module.css";

// const DummyComponent = ({ initialSeconds = 20 }) => {
//   const [loginWithMobile, setLoginWithMobile] = useState(false);
//   const [mobileNumber, setMobileNumber] = useState("");

//   const [loginWithEmail, setLoginWithEmail] = useState(false);
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');

//   const [seconds, setSeconds] = useState(initialSeconds);
//   const [startTimer, setStartTimer] = useState(false);
//   const [showMessage, setShowMessage] = useState(null); // State to show the response message
//   const timerRef = useRef(null);

//   const navigate = useNavigate();

//   useEffect(() => {
//     if (startTimer && seconds > 0) {
//       timerRef.current = setInterval(() => {
//         setSeconds((prevSeconds) => prevSeconds - 1);
//       }, 1000);
//     }

//     return () => clearInterval(timerRef.current);
//   }, [startTimer, seconds]);

//   const handleEditMobileNumber = (e) => {
//     setLoginWithMobile(false);
//     setStartTimer(false);
//     clearInterval(timerRef.current);
//   };

//   const handleEmailLogin = async (e) => {
//     e.preventDefault(); // Prevent form submission
//     const email_id = email;
//     const data = { email_id };
//     try {
//       const response = await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + '/api/v1/users/emailVerification', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(data),
//       });
//       if (response.ok) {
//         const responseData = await response.json();
//         console.log(responseData);

//         setShowMessage(responseData.message); // Show the response message

//         setTimeout(() => {
//           navigate("/"); // Navigate after 2 seconds
//         }, 1500);

//       } else {
//         console.error('Something went wrong in sending email verification');
//         throw new Error("Could not fetch events");
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const handleSendOtp = async () => {
//     if (!mobileNumber) {}
//       clearInterval(timerRef.current);
//       setLoginWithMobile(true);
//       setStartTimer(true);
//       setSeconds(initialSeconds);
//       const data = new formData({
//         mobileNumber
//       });
//     try {
//       const response = await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + '/api/v1/users/mobileVerification',
//         {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json'},
//           body: data,
//         }
//       );
//       const responseData = await response.json();
//       console.log(responseData);

//     } catch (error){
//       console.error('Error fetching data:', error);
//     }

//   };

//   return (
//     <Container>
//       {!loginWithMobile && (
//         <Form>
//           <Form.Group controlId="formMobile" className="form-div">
//             <Form.Label>Please enter your mobile number to register</Form.Label>
//             <Row>
//               <Col xs={2}>
//                 <Form.Control
//                   type="text"
//                   className="input-field"
//                   value="+91"
//                   readOnly
//                 />
//               </Col>
//               <Col xs={7} className="p-0">
//                 <Form.Control
//                   type="text"
//                   placeholder="enter your 10 digit mobile number"
//                   className="input-field"
//                   value={mobileNumber}
//                   onChange={(e) => setMobileNumber(e.target.value)}
//                 />
//               </Col>
//               <Col xs={3}>
//                 <Button
//                   variant="primary"
//                   onClick={handleSendOtp}
//                   className="get-otp-btn"
//                 >
//                   Get OTP
//                 </Button>
//               </Col>
//             </Row>
//           </Form.Group>
//           <Form.Group controlId="formEmail">
//             <Form.Label>or register with email ID</Form.Label>
//             <Row>
//               <Col xs={9} className='pe-0'>
//                 <Form.Control
//                   type="email"
//                   placeholder="enter your email id"
//                   className='input-field'
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//               </Col>
//               <Col xs={3}>
//                 <Button
//                   variant="primary"
//                   onClick={handleEmailLogin}
//                   className='email-submit-btn'
//                 >
//                   Submit
//                 </Button>
//               </Col>
//             </Row>
//           </Form.Group>
//         </Form>
//       )}
//       {showMessage && (
//         <Alert variant="success" className={classes.alert}>
//           {showMessage}
//         </Alert>
//       )}
//       {loginWithMobile && (
//         <div>
//           <div className={classes.mobileNumber}>
//             <p>(+91) {mobileNumber}</p>
//             <img
//               src={editIcon}
//               onClick={handleEditMobileNumber}
//               alt="Edit Phone No."
//             />
//           </div>
//           <div>
//             {startTimer && seconds > 0 && (
//               <p style={{ fontSize: "11px", color: "darkblue" }}>
//                 Resend OTP in &nbsp;
//                 {Math.floor(seconds / 60)}:
//                 {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
//                 &nbsp; minutes
//               </p>
//             )}

//             {startTimer && seconds === 0 && (
//               <div className={classes.resendOtp}>
//                 <button
//                   className={classes.actionButton}
//                   onClick={handleSendOtp}
//                 >
//                   Resend OTP
//                 </button>
//               </div>
//             )}

//             <div>
//               <label style={{ width: '100%' }}>
//                 <p>Enter OTP:</p>
//                 <OtpVerification />
//               </label>
//             </div>
//           </div>
//         </div>
//       )}
//     </Container>
//   );
// };

// export default DummyComponent;
