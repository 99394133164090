import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Confirm from "../assets/confirm.svg";
import "./VerifiedEmail.css";

import { useDispatch } from "react-redux";
import { addProfile } from "../redux/slices/userProfileSlice";

const VerifiedEmail = () => {
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null); // Add status state for better handling of success and fail
  const [loading, setLoading] = useState(true); // Add loading state

  const { user_id, verification_token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyEmail = async () => {
      const data = { user_id, verification_token };
      try {
        const response = await fetch(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/users/email-verification-report",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        if (response.ok) {
          console.log("RESPONSE_DATA", responseData);
          dispatch(addProfile(responseData.data));

          setMessage(responseData.message);
          setStatus(responseData.status); // Set status to handle different conditions

          setTimeout(() => {
            setMessage(null); // hide the response message
            if (
              responseData.existingUser ||
              (responseData.data.mobile_number &&
                responseData.data.verified_mobile_number &&
                responseData.data.email_id &&
                responseData.data.verified_email)
            ) {
              navigate("/set-password");
            } else {
              navigate("/mobile-verification");
            }
          }, 2000); // Navigate after 2 seconds
        } else {
          console.log("RESPONSE_DATA", responseData);
          setMessage(responseData.message);
          setStatus(responseData.status);
        }
      } catch (error) {
        // setMessage('Error verifying email. Please try again later.');
        setMessage(error.message);
        setStatus("Error");
        console.error("Error verifying email:", error);
      } finally {
        setLoading(false); // Set loading to false once the request is complete
      }
    };

    verifyEmail();
  }, [user_id, verification_token]);

  if (loading) {
    return (
      <div className="VerifiedEmailMessage">
        <div className="spinner"></div> {/* Add your spinner here */}
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="VerifiedEmailMessage">
      {status === "Success" ? (
        <>
          <img src={Confirm} alt="Verified Email Message" />
          <h1>Email Verified!</h1>
          <p>{message}</p>
        </>
      ) : (
        <>
          <h1>Verification Failed</h1>
          <p>{message}</p>
          <button
            className="btn  btn-primary mt-3"
            onClick={() => navigate("/login")}
          >
            TRY AGAIN
          </button>
        </>
      )}
    </div>
  );
};

export default VerifiedEmail;
