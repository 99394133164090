// import React, { useState } from "react";
// import classes from "./Sidebar.module.css";
// import { useNavigate } from "react-router-dom";
// import pinnacle from "../images/pinnacle.png";

// import { BsJournalRichtext } from "react-icons/bs";
// import { GoQuestion } from "react-icons/go";
// // import { IoListOutline } from "react-icons/io5";
// // import { TbCategoryPlus } from "react-icons/tb";
// import { BiCategoryAlt } from "react-icons/bi";
// import { MdBurstMode } from "react-icons/md";
// import { BiCart } from "react-icons/bi";

// const Sidebar = () => {
//   const [selectedExam, setSelectedExam] = useState(null);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedMode, setSelectedMode] = useState(null);
//   const [showExams, setShowExams] = useState(false);
//   const [showCategories, setShowCategories] = useState(false);
//   const [showModes, setShowModes] = useState(false);

//   const navigate = useNavigate();

//   const exams = [
//     { id: 121, name: "SSC" },
//     // { id: 123, name: "Delhi Police" },
//     { id: 122, name: "Railway" },
//   ];

//   const modes = [
//     { id: 128, name: "Full Test" },
//     { id: 129, name: "Sectional Test" },
//     { id: 130, name: "Chapter Test" },
//     { id: 131, name: "Previous Test" },
//   ];

//   const categories = [
//     { id: 126, name: "Tier 1" },
//     { id: 127, name: "Tier 2" },
//   ];

//   const handleExamChange = (product_id) => {
//     setSelectedExam(product_id);
//     navigate(`/individualexamtestpasssection/${product_id}`);
//   };

//   const handleCategoryChange = (product_id) => {
//     setSelectedCategory(product_id);
//     navigate(`/individualexamtestpasssection/${product_id}`);
//   };

//   const handleModeChange = (product_id) => {
//     setSelectedMode(product_id);
//     navigate(`/individualexamtestpasssection/${product_id}`);
//   };

//   const handleCheckMyOrder = () => {
//     navigate(`/myorders`);
//   };

//   const handleAdminSection = () => {
//     // Navigate to the /admin route
//     navigate("/admin");
//   };

//   const handleFreetestClick = () => {
//     navigate("/freetest");
//   };

//   const handleShowMyTestSeries = () => {
//     navigate("/mytestseries");
//   };

//   return (
//     <div className={classes.sidebar_testseries}>
//       <div className={classes.filter_section}>
//         <ul className={classes.sub_list_item}>
//           <li
//             className={classes.side_bar_heading_section}
//             onClick={handleFreetestClick}
//           >
//             <span>
//               <GoQuestion />
//             </span>{" "}
//             Free Test
//           </li>

//           {/* <li className={classes.side_bar_heading_section}>Paid Test</li> */}
//           {/* <li className={classes.side_bar_heading_section}>Select all</li> */}
//           <li
//             className={classes.side_bar_heading_section}
//             onClick={() => setShowExams(!showExams)}
//           >
//             <span>
//               <BsJournalRichtext />
//             </span>{" "}
//             Based on Exams
//             {showExams && (
//               <ul>
//                 <div className={classes.nest_sub_list_item}>
//                   {exams.map((exam) => (
//                     <li
//                       className={classes.sub_list_item}
//                       key={exam.id}
//                       onClick={() => handleExamChange(exam.id)}
//                     >
//                       {exam.name}
//                     </li>
//                   ))}
//                 </div>
//               </ul>
//             )}
//           </li>
//           <li
//             className={classes.side_bar_heading_section}
//             onClick={() => setShowCategories(!showCategories)}
//           >
//             {" "}
//             <span>
//               <BiCategoryAlt />
//             </span>{" "}
//             Based on category
//             {showCategories && (
//               <ul>
//                 <div className={classes.nest_sub_list_item}>
//                   {categories.map((tier) => (
//                     <li
//                       key={tier.id}
//                       onClick={() => handleCategoryChange(tier.id)}
//                     >
//                       {tier.name}
//                     </li>
//                   ))}
//                 </div>
//               </ul>
//             )}
//           </li>
//           <li
//             className={classes.side_bar_heading_section}
//             onClick={() => setShowModes(!showModes)}
//           >
//             <span>
//               <MdBurstMode />
//             </span>{" "}
//             Based on Exam mode
//             {showModes && (
//               <ul>
//                 <div className={classes.nest_sub_list_item}>
//                   {modes.map((mode) => (
//                     <li
//                       className={classes.sub_list_item}
//                       key={mode.id}
//                       onClick={() => handleModeChange(mode.id)}
//                     >
//                       {mode.name}
//                     </li>
//                   ))}
//                 </div>
//               </ul>
//             )}
//           </li>
//           <li
//             className={classes.side_bar_heading_section}
//             onClick={handleCheckMyOrder}
//           >
//             {" "}
//             <span>
//               <BiCart />
//             </span>{" "}
//             My Orders
//           </li>
//           {/* <li onClick={handleAdminSection}>admin</li> */}
//           <li
//             className={classes.side_bar_heading_section}
//             onClick={handleShowMyTestSeries}
//           >
//             {" "}
//             My Test series
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;



import React, { useState } from "react";
import classes from "./Sidebar.module.css";
import { useNavigate } from "react-router-dom";
import { BsJournalRichtext } from "react-icons/bs";
import { GoQuestion } from "react-icons/go";
import { BiCategoryAlt } from "react-icons/bi";
import { MdBurstMode } from "react-icons/md";
import { BiCart } from "react-icons/bi";
import { SiTestcafe } from "react-icons/si";

const Sidebar = () => {
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [showExams, setShowExams] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showModes, setShowModes] = useState(false);

  const navigate = useNavigate();

  const exams = [
    { id: 121, name: "SSC" },
    { id: 122, name: "Railway" },
  ];

  const modes = [
    { id: 128, name: "Full Test" },
    { id: 129, name: "Sectional Test" },
    { id: 130, name: "Chapter Test" },
    { id: 131, name: "Previous Test" },
  ];

  const categories = [
    { id: 126, name: "Tier 1" },
    { id: 127, name: "Tier 2" },
  ];

  const handleExamChange = (product_id) => {
    setSelectedExam(product_id);
    // Scroll to top when navigating to prevent sidebar jump
    window.scrollTo(0, 0); 
    navigate(`/individualexamtestpasssection/${product_id}`);
  };

  const handleCategoryChange = (product_id) => {
    setSelectedCategory(product_id);
    window.scrollTo(0, 0); 
    navigate(`/individualexamtestpasssection/${product_id}`);
  };

  const handleModeChange = (product_id) => {
    setSelectedMode(product_id);
    window.scrollTo(0, 0); 
    navigate(`/individualexamtestpasssection/${product_id}`);
  };

  const handleCheckMyOrder = () => {
    window.scrollTo(0, 0); 
    navigate(`/myorders`);
  };

  const handleFreetestClick = () => {
    window.scrollTo(0, 0); 
    navigate("/freetest");
  };

  const handleShowMyTestSeries = () => {
    window.scrollTo(0, 0); 
    navigate("/mytestseries");
  };

  return (
    <div className={classes.sidebar_testseries}>
      <div className={classes.filter_section}>
        <ul className={classes.sub_list_item}>
          <li
            className={classes.side_bar_heading_section}
            onClick={handleCheckMyOrder}
          >
            {" "}
            <span>
              <BiCart />
            </span>{" "}
            My Orders
          </li>
          <li
            className={classes.side_bar_heading_section}
            onClick={handleShowMyTestSeries}
          >
            <SiTestcafe />
            {" "}
            My Test series
          </li>
          <li
            className={classes.side_bar_heading_section}
            onClick={handleFreetestClick}
          >
            <span>
              <GoQuestion />
            </span>{" "}
            Free Test
          </li>

          <li
            className={classes.side_bar_heading_section}
            onClick={() => setShowExams(!showExams)}
          >
            <span>
              <BsJournalRichtext />
            </span>{" "}
            Based on Exams
            {showExams && (
              <ul>
                <div className={classes.nest_sub_list_item}>
                  {exams.map((exam) => (
                    <li
                      className={classes.sub_list_item}
                      key={exam.id}
                      onClick={() => handleExamChange(exam.id)}
                    >
                      {exam.name}
                    </li>
                  ))}
                </div>
              </ul>
            )}
          </li>
          <li
            className={classes.side_bar_heading_section}
            onClick={() => setShowCategories(!showCategories)}
          >
            {" "}
            <span>
              <BiCategoryAlt />
            </span>{" "}
            Based on category
            {showCategories && (
              <ul>
                <div className={classes.nest_sub_list_item}>
                  {categories.map((tier) => (
                    <li
                      key={tier.id}
                      onClick={() => handleCategoryChange(tier.id)}
                    >
                      {tier.name}
                    </li>
                  ))}
                </div>
              </ul>
            )}
          </li>
          <li
            className={classes.side_bar_heading_section}
            onClick={() => setShowModes(!showModes)}
          >
            <span>
              <MdBurstMode />
            </span>{" "}
            Based on Exam mode
            {showModes && (
              <ul>
                <div className={classes.nest_sub_list_item}>
                  {modes.map((mode) => (
                    <li
                      className={classes.sub_list_item}
                      key={mode.id}
                      onClick={() => handleModeChange(mode.id)}
                    >
                      {mode.name}
                    </li>
                  ))}
                </div>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
