// import pi1 from '../assets/pi-1.png';
// import pi2 from '../assets/pi-2.png';
// import pi3 from '../assets/pi-3.png';
// import pi4 from '../assets/pi-4.png';
// import pi5 from '../assets/pi-5.png';
// import pi6 from '../assets/pi-6.png';
// import './Platform.css';

import pi1 from '../../assets/pi-1.png';
import pi2 from '../../assets/pi-2.png';
import pi3 from '../../assets/pi-3.png';
import pi4 from '../../assets/pi-4.png';
import pi5 from '../../assets/pi-5.png';
import pi6 from '../../assets/pi-6.png';
import './Platform.css';

const Platform = () => {
    return (
    <div className='main-div'> 
        <span className='plateform-span'>Platform</span>
        <div className='p-div'>
            <div className='content-div'>
                <img src={pi1} alt="Books - Pinnacle Publications"/>
                <span className='text-span'>Books - Pinnacle Publications</span>
            </div>
            <div className='content-div'>
                <img src={pi2} alt="Digital books"/>
                <span className='text-span'>Digital books</span>
            </div>
            <div className='content-div'>
                <img src={pi3} alt="Test portal"/>
                <span className='text-span'>Test portal</span>
            </div>
            <div className='content-div'>
                <img src={pi4} alt="Typing Software"/>
                <span className='text-span'>Typing Software</span>
            </div>
            <div className='content-div'>
                <img src={pi5} alt="Videos Portal"/>
                <span className='text-span'>Videos Portal</span>
            </div>
            <div className='content-div'>
                <img src={pi6} alt="Research and Development"/>
                <span className='text-span'>Research and Development</span>
            </div>
            
        </div>
    </div>);
}

export default Platform;






// import pi1 from '../assets/pi-1.png';
// import pi2 from '../assets/pi-2.png';
// import pi3 from '../assets/pi-3.png';
// import pi4 from '../assets/pi-4.png';
// import pi5 from '../assets/pi-5.png';
// import pi6 from '../assets/pi-6.png';
// import './Platform.css';

// const Platform = () => {
//     return (
//     <div className='main-div'> 
//         <span className='plateform-span'>Platform</span>
//         <div className='p-div'>
//             <div className='content-div'>
//                 <img src={pi1} alt="pi1"/>
//                 <span className='text-span'>Books - Pinnacle Publications</span>
//             </div>
//             <div className='content-div'>
//                 <img src={pi2} alt="pi1"/>
//                 <span className='text-span'>Digital books</span>
//             </div>
//             <div className='content-div'>
//                 <img src={pi3} alt="pi1"/>
//                 <span className='text-span'>Test portal</span>
//             </div>
//             <div className='content-div'>
//                 <img src={pi4} alt="pi1"/>
//                 <span className='text-span'>Typing Software</span>
//             </div>
//             <div className='content-div'>
//                 <img src={pi5} alt="pi1"/>
//                 <span className='text-span'>Videos Portal</span>
//             </div>
//             <div className='content-div'>
//                 <img src={pi6} alt="pi1"/>
//                 <span className='text-span'>Research and Development</span>
//             </div>
            
//         </div>
//     </div>);
// }

// export default Platform;