import { configureStore } from "@reduxjs/toolkit";
import userProfileReducer from "./slices/userProfileSlice";

// import { authMiddleWare } from "../middleware/authmiddleware";

const actionLogger = (store) => (next) => (action) => {
  if (!action.type) {
    console.error("Dispatched action with empty type:", action);
    console.trace(); // Capture stack trace for debugging
  } else {
    console.log("Dispatching action:", action);
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    userProfile: userProfileReducer,
  },
});

// console.log("Initial Redux store state:", store.getState());
