// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateOverlay_backdrop__mhxuJ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(105, 105, 105, 0.75);
}

.UpdateOverlay_updateOverlayModel__P7PHK {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 5vh;
  background-color: #fff;
  left: 30%;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px;
  gap: 15px;
  height: 45vh;
  width: 42%;
}

.UpdateOverlay_choose_range__LYk8R {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgb(224, 221, 221);
}

.UpdateOverlay_choose_subject__8Qa92 {
  border-bottom: 1px solid rgb(201, 200, 200);
  display: flex;
  flex-direction: column;
}

.UpdateOverlay_choose_subject__8Qa92 > select {
  outline: none;
  height: 2rem;
  border-radius: 5px;
}

.UpdateOverlay_header_section__RUx\\+h {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(221, 220, 220);
}

.UpdateOverlay_footer_section__nQGhh {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.UpdateOverlay_footer_section__nQGhh > button {
  border-radius: 5px;
  height: 35px;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/UpdateOverlay.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,QAAQ;EACR,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,uCAAuC;EACvC,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,2CAA2C;AAC7C;;AAEA;EACE,2CAA2C;EAC3C,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(105, 105, 105, 0.75);\n}\n\n.updateOverlayModel {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  top: 5vh;\n  background-color: #fff;\n  left: 30%;\n  z-index: 100;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  padding: 8px;\n  gap: 15px;\n  height: 45vh;\n  width: 42%;\n}\n\n.choose_range {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  border-bottom: 1px solid rgb(224, 221, 221);\n}\n\n.choose_subject {\n  border-bottom: 1px solid rgb(201, 200, 200);\n  display: flex;\n  flex-direction: column;\n}\n\n.choose_subject > select {\n  outline: none;\n  height: 2rem;\n  border-radius: 5px;\n}\n\n.header_section {\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid rgb(221, 220, 220);\n}\n\n.footer_section {\n  display: flex;\n  flex-direction: row;\n  gap: 40px;\n}\n\n.footer_section > button {\n  border-radius: 5px;\n  height: 35px;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `UpdateOverlay_backdrop__mhxuJ`,
	"updateOverlayModel": `UpdateOverlay_updateOverlayModel__P7PHK`,
	"choose_range": `UpdateOverlay_choose_range__LYk8R`,
	"choose_subject": `UpdateOverlay_choose_subject__8Qa92`,
	"header_section": `UpdateOverlay_header_section__RUx+h`,
	"footer_section": `UpdateOverlay_footer_section__nQGhh`
};
export default ___CSS_LOADER_EXPORT___;
