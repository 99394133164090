// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 320px) and (max-width: 900px) {
  .mobile-three-outline_tierII {
    width: 46px;
    background: #1fbad6;
    border: none;
    position: absolute;
    /* bottom: 65px;    */
    /* right: 2px; */
    top: 0px;
    padding: 0px 5px;
    border-radius: 2px;
    color: white;
    font-size: 25px;
  }

  .sol-language-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* position: relative; */
    top: 2px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/SolutionSection.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,QAAQ;IACR,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,wBAAwB;IACxB,QAAQ;EACV;AACF","sourcesContent":["@media only screen and (min-width: 320px) and (max-width: 900px) {\n  .mobile-three-outline_tierII {\n    width: 46px;\n    background: #1fbad6;\n    border: none;\n    position: absolute;\n    /* bottom: 65px;    */\n    /* right: 2px; */\n    top: 0px;\n    padding: 0px 5px;\n    border-radius: 2px;\n    color: white;\n    font-size: 25px;\n  }\n\n  .sol-language-section {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    /* position: relative; */\n    top: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
