import React, { useEffect, useState } from 'react'
import Layout from './Layout';
import axios from 'axios';
import classes from "./AboutUsPage.module.css";


const AboutUsPage = () => {
    const [aboutUsContent, setAboutUsContent] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("");
    
    useEffect(() => {
       const fetchAboutUsContent = async () => {
            try {
                // const baseUrl = "http://localhost:5000";

                // if (!baseUrl) {
                //     throw new Error("Base URL is not defined")
                // }

                //fetch data using axios
                const response = await axios.get(`${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/terms-and-conditions`);

                if (response.data) {
                    console.log("TERMS_AND_CONDITIONS_DATA ABOUT US:",response.data);
                    setAboutUsContent(response.data.data.about_us || "");
                } else {
                    setError("Failed to load about us page");
                }
            } catch (error) {
                console.error("Error fetching about us page:", error);
                setError("An error occurred while fetching about us page");
            } finally {
                setLoading(false);
            }
        };

        fetchAboutUsContent();
    }, []);

  return (
    <Layout>
    <div className={classes.about_us_page_div}>
    {/*
        <h2>About us</h2>
        */}
        <h2>Abous us: Pinnacle Coaching</h2>
        {loading && <p>Loading about page...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && (
            <div dangerouslySetInnerHTML={{__html:aboutUsContent}}/>
        )}
    </div>

    </Layout>
  )
}

export default AboutUsPage;