import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Modal,
  InputGroup,
} from "react-bootstrap";

import OtpVerification from "./OtpVerification";
import editIcon from "../../assets/edit.png";
import classes from "./DummyComponent.module.css";

import { useSelector, useDispatch } from "react-redux";
import { addProfile } from "../../redux/slices/userProfileSlice";

import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";

const ForgotPassword = ({
  numberOfDigits = 6,
  initialSeconds = 180,
  PasswordSuccessfullyChanged,
}) => {
  const [loginWithMobile, setLoginWithMobile] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [inputField, setInputField] = useState("");
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [passwordResedOtp, setPasswordResedOtp] = useState("");

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsModalShow, setTermsModalShow] = useState(false); // State for terms modal
  const [termsContent, setTermsContent] = useState(""); // State to store fetched terms content

  const [isEmail, setIsEmail] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);

  const [seconds, setSeconds] = useState(initialSeconds);
  const [startTimer, setStartTimer] = useState(false);
  const [showMessage, setShowMessage] = useState(null); // State to show the response message
  const timerRef = useRef(null);
  const otpBoxReference = useRef([]);

  const userProfile = useSelector((state) => state.userProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (startTimer && seconds > 0) {
      timerRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(timerRef.current);
  }, [startTimer, seconds]);

  const handleEditMobileNumber = (e) => {
    setLoginWithMobile(false);
    setStartTimer(false);
    clearInterval(timerRef.current);
    setShowMessage(null); // hide the response message
  };

  //   const handleEmailLogin = async (e) => {
  //     e.preventDefault(); // Prevent form submission

  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     if (!email || !emailRegex.test(email)) {
  //       setShowMessage("Invalid Email Address");
  //       return;
  //     }

  //     const email_id = email;
  //     const data = { email_id };
  //     try {
  //       const response = await fetch(
  //         process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/emailVerification",
  //         {
  //           method: "POST",
  //           headers: { "Content-Type": "application/json" },
  //           body: JSON.stringify(data),
  //         }
  //       );
  //       if (response.ok) {
  //         const responseData = await response.json();
  //         console.log(responseData);

  //         setShowMessage(responseData.message); // Show the response message

  //         // setTimeout(() => {
  //         //   navigate("/"); // Navigate after 1.5 seconds
  //         // }, 1500);
  //       } else {
  //         console.error("Something went wrong in sending email verification");
  //         setShowMessage("Something went wrong. Please try again after sometime.");
  //         throw new Error("Could not fetch events");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setShowMessage("Something went wrong. Please try again after sometime.");

  //     }
  //   };

  const handleSendOtp = async (useWhatsApp = false) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileNumberRegex = /^[6-9]\d{9}$/; // Indian mobile number format

    let data;
    // if(!useWhatsApp) {
    //     if (emailRegex.test(inputField)) {
    //       data = { email: inputField };
    //       // setIsEmail(true);
    //     } else if (mobileNumberRegex.test(inputField)) {
    //       data = { mobileNumber: inputField };
    //       setMobileNumber(inputField)
    //       // setIsEmail(false);
    //     } else {
    //       // setInputField('');
    //       setIsError(true);
    //       setShowMessage("Invalid Email/ Mobile number Address");
    //       return;
    //     }
    // } else {
    //     if (emailRegex.test(inputField)) {
    //         data = { email: inputField, whatsApp: 1 };
    //         // setIsEmail(true);
    //       } else if (mobileNumberRegex.test(inputField)) {
    //         data = { mobileNumber: inputField, whatsApp: 1 };
    //         setMobileNumber(inputField);
    //       } else {
    //         // setInputField('');
    //         setIsError(true);
    //         setShowMessage("Invalid Email/ Mobile number Address");
    //         return;
    //       }
    // }
    if (emailRegex.test(inputField)) {
      data = useWhatsApp
        ? { email_id: inputField, whatsApp: 1 }
        : { email_id: inputField };
      setIsEmail(true);
    } else if (mobileNumberRegex.test(inputField)) {
      data = useWhatsApp
        ? { mobileNumber: inputField, whatsApp: 1 }
        : { mobileNumber: inputField };
      setMobileNumber(inputField);
      setIsEmail(false);
    } else {
      // setIsError(true);
      setShowMessage("Invalid Email/ Mobile number Address");
      return;
    }

    console.log("data", data);

    try {
      const response = await fetch(process.env.REACT_APP_PINNACLE_BASE_URL +"/api/v1/users/PasswordResetSendOtp",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      console.log("Response status:", response.status);
      // const responseData = await response.json();
      // console.log(responseData);
      const responseData = await response.json();
      console.log("respoonseData", responseData);
      console.log("responseData", responseData);
      if (response.ok) {
        console.log("responseData", responseData);
        console.log("responseData.data", responseData.data);

        setShowMessage(responseData.message); // Show the response message

        setTimeout(() => {
          // if (data.mobileNumber) {
          // }
          setShowMessage(null); // hide the response message
          clearInterval(timerRef.current);
          setLoginWithMobile(true);
          setStartTimer(true);
          setSeconds(initialSeconds);
        }, 2000);
      } else if (response.status === 400) {
        const additionalMessage = (
          <>
            Note: if you are an old student, then use the same email ID and
            mobile for registration on{" "}
            <a
              href="https://testportal.ssccglpinnacle.com/login"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "blue",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              new web
            </a>{" "}
            or{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "blue",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              app
            </a>{" "}
            which you have used for purchasing.
          </>
        );

        // Combine the backend message and additional message
        const combinedMessage = (
          <>
            <div style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}>
              {responseData.message}
              <br />
              {additionalMessage}
            </div>
          </>
        );

        setShowMessage(combinedMessage);
      } else {
        setShowMessage(responseData.message);

        // Show the response message
        console.error("Something went wrong in sending OTP");
        throw new Error("Could not fetch events");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowMessage("Something went wrong in sending OTP"); // Show the response message
    }
  };

  // const handleVerifyOtp = async (mobileOtp) => {
  //   let data;
  //   if (isEmail) {
  //     data = { passwordResetOtp: mobileOtp, email_id: inputField };
  //   } else {
  //     data = { passwordResetOtp: mobileOtp, mobileNumber: inputField };
  //   }
  //   console.log("DATA", data);

  //   try {
  //     const response = await fetch(
  //       process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/PasswordResetVerifyOtp",
  //       {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify(data),
  //       }
  //     );

  //     if (response.ok) {
  //       const responseData = await response.json();
  //       console.log(responseData);

  //       setShowMessage(responseData.message); // Show the response message
  //       // dispatch(addProfile(responseData.data));
  //       setShowPasswordField(true); // Show the password field

  //       setTimeout(() => {
  //         // setShowMessage(null); // hide the response message
  //         // navigate("/email-verification"); // Navigate after 2 seconds
  //         // clearInterval(timerRef.current);
  //         // setLoginWithMobile(true);
  //         // setStartTimer(true);
  //         // setSeconds(initialSeconds);
  //       }, 2000);
  //     } else {
  //       console.error("Wrong OTP");
  //       setShowMessage("Wrong OTP"); // Show the response message
  //       // throw new Error("Could not fetch events");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // const fetchTermsContent = async () => {
  //   try {
  //     // const response = await axios.get(`${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/terms-and-conditions`);
  //     const response = await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/terms-and-conditions");
  //     if (response.ok) {
  //       const data = await response.json();
  //       setTermsContent(data.content);
  //     } else {
  //       setTermsContent("Failed to load terms and conditions.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching terms and conditions:", error);
  //     setTermsContent(error.message);
  //   }
  // };


  const fetchTermsContent = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/terms-and-conditions`);
      if (response.ok) {
        const data = await response.json();

        console.log("FORGOT PASSWORD", data);
        setTermsContent(data.data.terms_conditions);
      } else {
        setTermsContent("Failed to load terms and conditions.");
      }
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
      setTermsContent(error.message);
    }
  };
  

  function handleChange(value, index) {
    setShowMessage(null);
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);
    // setOtpLocal(newArr);
    // setOtp(newArr.join(""));
    console.log(value);
    console.log(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    setShowMessage(null);
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }

    if (
      (e.target.value && index < numberOfDigits - 1) ||
      (e.key === "Enter" && index < numberOfDigits - 1)
    ) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  async function handleVerifyOtp() {
    const mobileOtp = parseInt(otp.join(""), 10);
    console.log("mobileOtp", mobileOtp);
    if (mobileOtp.toString().length === 6) {
      console.log("SENDING OTP VALUE IS ", mobileOtp);
      setPasswordResedOtp(mobileOtp);

      // handleVerifyOtp(mobileOtp);
      let data;
      if (isEmail) {
        data = {
          passwordResetOtp: mobileOtp,
          email_id: inputField,
        };
      } else {
        data = {
          passwordResetOtp: mobileOtp,
          mobileNumber: inputField,
        };
      }
      console.log("DATA", data);

      try {
        const response = await fetch(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/users/PasswordResetVerifyOtp",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }
        );

        const responseData = await response.json();
        if (response.ok) {
          console.log(responseData);

          setShowMessage(responseData.message); // Show the response message
          // dispatch(addProfile(responseData.data));

          setTimeout(() => {
            setShowPasswordField(true); // Show the password field
            setShowMessage(null); // hide the response message
            // navigate("/email-verification"); // Navigate after 2 seconds
            // clearInterval(timerRef.current);
            // setLoginWithMobile(true);
            // setStartTimer(true);
            // setSeconds(initialSeconds);
          }, 2000);
        } else {
          console.error(responseData.message);
          setShowMessage(responseData.message); // Show the response message
          // throw new Error("Could not fetch events");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowMessage(error.message);
      }
    } else {
      setShowMessage("❌ Wrong OTP Please Check Again");
    }
  }

  const handleShowTermsModal = () => {
    fetchTermsContent();
    setTermsModalShow(true);
  };

  const handleCloseTermsModal = () => {
    setTermsModalShow(false);
  };

  async function handlePasswordReset() {
    let data;
    if (isEmail) {
      data = {
        passwordResetOtp: passwordResedOtp,
        email_id: inputField,
        password: password,
        passwordConfirm: confirmPassword,
      };
    } else {
      data = {
        passwordResetOtp: passwordResedOtp,
        mobileNumber: inputField,
        password: password,
        passwordConfirm: confirmPassword,
      };
    }
    console.log("DATA", data);

    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/PasswordReset",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData);

        setShowMessage(responseData.message); // Show the response message

        setTimeout(() => {
          setShowMessage(null); // Show the response message
          PasswordSuccessfullyChanged();
          setLoginWithMobile(false);
          setShowPasswordField(false); // Show the password field
        }, 2000);
      } else {
        console.error(responseData.message);
        setShowMessage(responseData.message); // Show the response message
        // throw new Error("Could not fetch events");
      }
    } catch (error) {
      setShowMessage(error.message);
      console.error("Error fetching data:", error);
    }
  }

  return (
    <Container>
      {!loginWithMobile && (
        <Form>
          <Form.Group controlId="formMobile" className="form-div">
            <Form.Label>Please enter your Mobile number or Email </Form.Label>
            <Row>
              <Col xs={9} className="p-0">
                <Form.Control
                  type="text"
                  placeholder="Mobile number or Email"
                  className="input-field"
                  value={inputField}
                  onChange={(e) => {
                    setInputField(e.target.value);
                    setShowMessage(null);
                  }}
                />
              </Col>
              <Col xs={3}>
                <Button
                  variant="primary"
                  onClick={() => handleSendOtp(false)}
                  className="get-otp-btn"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      )}

      {loginWithMobile && !showPasswordField && (
        <div>
          <div className={classes.mobileNumber}>
            <p>{mobileNumber ? `(+91) ${mobileNumber}` : inputField}</p>
            <img src={editIcon} onClick={handleEditMobileNumber} alt="Edit " />
          </div>
          <div>
            {startTimer && seconds > 0 && (
              <div className={classes.resendOtp}>
                <p style={{ fontSize: "11px", color: "darkblue" }}>
                  Resend OTP in &nbsp;
                  {Math.floor(seconds / 60)}:
                  {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                  &nbsp; minutes
                </p>
                {mobileNumber && (
                  <button
                    className={classes.actionButton}
                    onClick={() => handleSendOtp(true)}
                  >
                    WhatsApp
                  </button>
                )}
              </div>
            )}

            {startTimer && seconds === 0 && (
              <div className={classes.resendOtp}>
                <button
                  className={classes.actionButton}
                  onClick={() => handleSendOtp(false)}
                >
                  Resend OTP
                </button>
                {mobileNumber && (
                  <button
                    className={classes.actionButton}
                    // onClick={() => handleWhatsAppOtp()}
                    onClick={() => handleSendOtp(true)}
                  >
                    WhatsApp
                  </button>
                )}
              </div>
            )}

            <div>
              <label style={{ width: "100%" }}>
                <p>Enter One Time Password (OTP)</p>
                {/* <Form.Check
                  type="checkbox"
                  id="termsCheckbox"
                  // label="I agree to terms & conditions"
                  label={
                    <span
                      onClick={handleShowTermsModal}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      I agree to terms & conditions
                    </span>
                  }
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                /> */}
                <Form.Check
                  type="checkbox"
                  id="termsCheckbox"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px", // Spacing between checkbox and label
                  }}
                >
                  <Form.Check.Input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    style={{
                      width: "18px", // Custom checkbox size
                      height: "18px",
                      border: "1px solid black", // Thick border for checkbox
                      borderRadius: "4px", // Rounded corners
                      appearance: "none", // Remove default browser styling
                      backgroundColor: termsAccepted ? "#007bff" : "white", // Custom background color when checked
                      cursor: "pointer",
                    }}
                  />
                  <Form.Check.Label
                    htmlFor="termsCheckbox"
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline", // Optional underline for the label text
                    }}
                    onClick={handleShowTermsModal} // Opens terms modal on label click
                  >
                    I agree to terms & conditions
                  </Form.Check.Label>
                </Form.Check>
                {/*
                <OtpVerification
                  handleVerifyOtp={handleVerifyOtp}
                  termsAccepted={termsAccepted}
                />
                  */}

                <div className={` ${classes.otpInputDiv}`}>
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      value={digit}
                      name="mobileOtp"
                      maxLength={1}
                      onChange={(e) => handleChange(e.target.value, index)}
                      onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                      ref={(reference) =>
                        (otpBoxReference.current[index] = reference)
                      }
                      // className={classes.otpInputBox}
                      className={classes.input}
                    />
                  ))}
                  <button
                    className={` ${classes.actionButton} `}
                    onClick={handleVerifyOtp}
                    disabled={!termsAccepted} // Disable button if terms are not accepted
                  >
                    Verify OTP
                  </button>
                </div>
              </label>
            </div>
          </div>
        </div>
      )}
      {showPasswordField && (
        <Form>
          {/*
           */}
          <h1>Set Password</h1>
          <Form.Group controlId="formPassword">
            <Form.Label>
              Please enter your password and confirm password.
            </Form.Label>
            <Row className="m-1">
              <InputGroup>
                <Form.Control
                  // type="password"
                  type={showPassword ? "text" : "password"} // Dynamically toggle type
                  placeholder="Enter your password"
                  className="input-field"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ paddingRight: "40px", borderRadius: "5px" }} // Add padding to avoid overlap with the icon
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px", // Position icon inside the input box
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    zIndex: "10",
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>
            </Row>
            <Row className="m-1">
              <InputGroup>
                <Form.Control
                  // type="password"
                  type={showConfirmPassword ? "text" : "password"} // Dynamically toggle type
                  placeholder="Confirm your password"
                  className="input-field"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{ paddingRight: "40px", borderRadius: "5px" }} // Add padding to avoid overlap with the icon
                />
                <InputGroup.Text
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    position: "absolute",
                    right: "10px", // Position icon inside the input box
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    zIndex: "10",
                  }}
                >
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>
            </Row>
            <Button
              variant="primary"
              onClick={() => handlePasswordReset()}
              className="get-otp-btn"
            >
              Submit
            </Button>
          </Form.Group>
        </Form>
      )}

      {showMessage && (
        <Alert variant="success" className={classes.alert}>
          {showMessage}
        </Alert>
      )}

      <Modal show={termsModalShow} onHide={handleCloseTermsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {termsContent ? (
            <div dangerouslySetInnerHTML={{ __html: termsContent }} />
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTermsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ForgotPassword;
