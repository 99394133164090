// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListTestSeries_test_serieslist_h1__mcl6W {
  text-align: center;
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 30px;
  color:#787878;
}

.ListTestSeries_list_test_series_table_section__ewCYy {
    height: 500px;
    overflow-y: auto;
    /* position: fixed; */
  }

.ListTestSeries_table_data_section__ucK5I {
    text-align: center;
  }

.ListTestSeries_table_data_section__ucK5I > td {
    text-align: center;
  }

.ListTestSeries_row_sub_section__2gXIe {
    background-color: #f0f0f0;
    font-weight: bold;
  }

.ListTestSeries_overlay__FKSj4 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
.ListTestSeries_no_scroll__Kc0pD {
    overflow: hidden;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/ListTestSeries.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;AACf;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,qBAAqB;EACvB;;AAEF;IACI,kBAAkB;EACpB;;AAEF;IACI,kBAAkB;EACpB;;AAEF;IACI,yBAAyB;IACzB,iBAAiB;EACnB;;AAEF;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oCAAoC;EACtC;;AAEF;IACI,gBAAgB;EAClB","sourcesContent":[".test_serieslist_h1 {\n  text-align: center;\n  font-size: 25px;\n  margin-top: 10px;\n  margin-bottom: 30px;\n  color:#787878;\n}\n\n.list_test_series_table_section {\n    height: 500px;\n    overflow-y: auto;\n    /* position: fixed; */\n  }\n\n.table_data_section {\n    text-align: center;\n  }\n\n.table_data_section > td {\n    text-align: center;\n  }\n\n.row_sub_section {\n    background-color: #f0f0f0;\n    font-weight: bold;\n  }\n\n.overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(0, 0, 0, 0.5);\n  }\n  \n.no_scroll {\n    overflow: hidden;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"test_serieslist_h1": `ListTestSeries_test_serieslist_h1__mcl6W`,
	"list_test_series_table_section": `ListTestSeries_list_test_series_table_section__ewCYy`,
	"table_data_section": `ListTestSeries_table_data_section__ucK5I`,
	"row_sub_section": `ListTestSeries_row_sub_section__2gXIe`,
	"overlay": `ListTestSeries_overlay__FKSj4`,
	"no_scroll": `ListTestSeries_no_scroll__Kc0pD`
};
export default ___CSS_LOADER_EXPORT___;
