// Spinner.js
import React from "react";
import "./Spinner.css";

const Spinner = () => (
  <div className="spinner-overlay">
    <div className="spinner"></div>
    <span>
      <h1>LOADING...</h1>
    </span>
  </div>
);

export default Spinner;

// // Spinner.js
// import React from 'react';
// import './Spinner.css';

// const Spinner = () => (
//   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//   <div className="spinner"></div>
//   <h1>LOADING...</h1>
// </div>
// );

// export default Spinner;
