import React, { useState, useRef } from "react";
import classes from "./AddTestSeriesPackage.module.css";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

function AddTestSeriesPackage({ onClose, id, test_series_name, post_tier_id }) {
  const [price, setPrice] = useState("");
  const [examMode, setExamMode] = useState(""); // Default value
  const [paperCodes, setPaperCodes] = useState([]);
  // const [paperStatus, setPaperStatus] = useState(null); // Default value
  const [paidStatus, setPaidStatus] = useState(""); // Default value
  const [paperType, setPaperType] = useState(""); // Default value
  const [selectedPaperCodes, setSelectedPaperCodes] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const multiselectRef = useRef();
  const cookies = new Cookies();

  const token = cookies.get("token");
  const handleCancel = () => {
    onClose();
  };

  const handleSave = async () => {
    if (
      !examMode ||
      !price ||
      !paidStatus ||
      !paperType ||
      selectedPaperCodes.length === 0
    ) {
      alert("Input field must not be empty!");
      return false;
    }

    setIsSubmitting(true);

    const formData = {
      id: id,
      examMode: examMode,
      selectedPaperCodes,
      paidStatus: paidStatus,
      paperType: paperType,
      price: price,
    };

    console.log("formdata", formData);

    try {
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/admin/addpapercodesintestseries",
        formData,
        {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("line 63", response);
      if (response.status === 200) {
        resetForm();

        Swal.fire({
          icon: "success",
          title: response.data.message || "Paper Code Added Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: `Error: ${response.data.error}|| "An error occured while updating the test`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error("Error setting selected exam mode:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSelectedExammode = async (selectedMode) => {
    setExamMode(selectedMode);
    if (selectedMode) {
      fetchPaperCodes(selectedMode);
    }
  };

  console.log("selected paperCode", selectedPaperCodes);

  const fetchPaperCodes = async (selectedMode) => {
    // console.log("i got called");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/getAllPaperCodes?exam_mode_id=${selectedMode}&post_tier_id= ${post_tier_id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Paper Codes Response:", response.data);

      setPaperCodes(
        response.data.map((code) => ({
          value: { paper_code: code.paper_code, status: code.status },
          label: code.paper_code,
        }))
      );
    } catch (error) {
      console.error("Error fetching paper codes:", error);
    }
  };

  const resetForm = () => {
    setPrice("");
    setExamMode("");
    setPaidStatus("");
    setPaperType("");
    setSelectedPaperCodes([]);
    multiselectRef.current.resetSelectedValues();
  };

  return (
    <div className={classes.overlay}>
      <div className={classes.overlay_content}>
        <button
          onClick={onClose}
          type="button"
          className={classes.close}
          aria-hidden="true"
        >
          ×
        </button>
        <h1>Add Test Series Package</h1>
        <b>Test Series Name</b>
        <input type="text" name="test" value={test_series_name} readOnly />
        <b>Price</b>
        <input
          type="text"
          name="price"
          placeholder="Enter Price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <b>Exam Mode</b>
        <select
          value={examMode}
          onChange={(e) => handleSelectedExammode(e.target.value)}
        >
          <option value="">Select anyone</option>
          <option value="1">Mock test-Full length</option>
          <option value="2">Sectional Test</option>
          <option value="3">Chapter Test</option>
          <option value="4">PYP- Full Length</option>
          <option value="5">PYP- Full Length(New Pattern)</option>
        </select>

        <b>Paper Code</b>
        <Multiselect
          options={paperCodes}
          selectedvalues={selectedPaperCodes}
          onSelect={(selectedList) =>
            setSelectedPaperCodes(selectedList.map((item) => item.value))
          }
          onRemove={(selectedList) =>
            setSelectedPaperCodes(selectedList.map((item) => item.value))
          }
          displayValue="label"
          ref={multiselectRef}
        />
        <b>Paid Status</b>
        <select
          value={paidStatus}
          onChange={(e) => setPaidStatus(e.target.value)}
        >
          <option value="">Select anyone</option>
          <option value="1">Free</option>
          <option value="2">PAID</option>
        </select>
        <b>Paper Type</b>
        <select
          value={paperType}
          onChange={(e) => setPaperType(e.target.value)}
        >
          <option value="">Select</option>
          <option value="1">Practice</option>
          <option value="2">Ranking</option>
        </select>
        <div className={classes.save_cancel_btn}>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleSave} disabled={isSubmitting}>
            {isSubmitting ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddTestSeriesPackage;

// import React, { useState, useRef  } from "react";
// import classes from "./AddTestSeriesPackage.module.css";
// import axios from "axios";
// import Multiselect from "multiselect-react-dropdown";
// import Swal from "sweetalert2";

// function AddTestSeriesPackage({ onClose, id, test_series_name }) {
//   const [price, setPrice] = useState("");
//   const [examMode, setExamMode] = useState(""); // Default value
//   const [paperCodes, setPaperCodes] = useState([]);
//   const [paidStatus, setPaidStatus] = useState(""); // Default value
//   const [paperType, setPaperType] = useState(""); // Default value
//   const [selectedPaperCodes, setSelectedPaperCodes] = useState("");

//   const handleCancel = () => {
//     // Add any additional cleanup or logic before closing the modal
//     onClose();
//   };

//   const handleSave = async () => {
//     if (!examMode || !price || !paidStatus || !paperType || !selectedPaperCodes) {
//       alert("Input field must not be empty!");
//       return false;
//     }

//     const formData = {
//       id: id,
//       examMode: examMode,
//       selectedPaperCodes: selectedPaperCodes,
//       paidStatus: paidStatus,
//       paperType: paperType,
//     };
//     // console.log(formData)

//     //post api initiated
//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/addpapercodesintestseries",
//         formData,
//         { headers: { "content-Type": "application/json" } }
//       );
//       if(response.statusText==='OK'){
//         setPrice("")
//         setExamMode("");
//         setPaidStatus("");
//         setPaperType("");
//         setSelectedPaperCodes("");

//         Swal.fire({
//           icon: "success",
//           title: "Paper Code Added Successful!",
//           showConfirmButton: false,
//           timer: 1500,
//         });
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: `Error: ${response.data.error}`,
//           showConfirmButton: false,
//           timer: 1500,
//         });
//       }
//     } catch (error) {
//       console.error("Error setting selected exam mode:", error);
//     }

//     // After saving, close the modal
//     // onClose();
//   };

//   const handleSelectedExammode = async (selectedMode) => {
//     try {
//       // const response = await axios.post(
//       //   process.env.REACT_APP_PINNACLE_BASE_URL + "/setSelectedExamMode",
//       //   { examMode: selectedMode },
//       //   { headers: { "content-Type": "application/json" } }
//       // );

//       // console.log("Selected exam mode response:", response.data);
//       setExamMode(selectedMode);
//       if (selectedMode) {
//         fetchPaperCodes(selectedMode);
//       }
//     } catch (error) {
//       console.error("Error setting selected exam mode:", error);
//     }
//   };

//   const fetchPaperCodes = async (selectedMode) => {
//     // console.log("selected Exam mode id:", selectedMode);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/getAllPaperCodes?exam_mode_id=${selectedMode}`
//       );

//       console.log("Paper Codes Response:", response.data);
//       setPaperCodes(
//         response.data.map((code) => ({
//           value: { paper_code: code.paper_code, status: code.status },
//           label: code.paper_code,
//         }))
//       );
//     } catch (error) {
//       console.error("Error fetching paper codes:", error);
//     }
//   };

//   return (
//     <div className={classes.overlay}>
//       <div className={classes.overlay_content}>
//         <button
//           onClick={onClose}
//           type="button"
//           className="close"
//           data-dismiss="modal"
//           aria-hidden="true"
//         >
//           ×
//         </button>
//         <h1>Add Test Series Package</h1>
//         <b>Test Series Name</b>
//         <input type="text" name="test" value={test_series_name} readOnly />
//         <b>Price</b>
//         <input
//           type="text"
//           name="test"
//           placeholder="Enter Price"
//           value={price}
//           onChange={(e) => setPrice(e.target.value)}
//         />
//         <b>Exam Mode</b>
//         <select
//           value={examMode}
//           onChange={(e) => handleSelectedExammode(e.target.value)}
//         >
//           <option value="0">select anyone</option>
//           <option value="1">Mock test-Full length</option>
//           <option value="2">Sectional Test</option>
//           <option value="3">Chapter Test</option>
//           <option value="4">PYP- Full Length</option>
//           <option value="5">PYP- Full Length(New Pattern)</option>
//         </select>

//         <br />
//         <b>Paper Code</b>
//         <Multiselect
//           options={paperCodes}
//           selectedvalues={selectedPaperCodes}
//           onSelect={(selectedList) => {
//             const selectedValues = selectedList.map((item) => item.value);
//             setSelectedPaperCodes(selectedValues);
//           }}
//           onRemove={(selectedList) => {
//             const selectedValues = selectedList.map((item) => item.value);
//             setSelectedPaperCodes(selectedValues);
//           }}
//           displayValue="label"
//           // ref={multiselectRef}
//         />
//         <br />
//         <b>Paid Status</b>
//         <br />
//         <select
//           value={paidStatus}
//           onChange={(e) => setPaidStatus(e.target.value)}
//         >
//           <option value="0">select anyone</option>
//           <option value="1">Free</option>
//           <option value="2">PAID</option>
//         </select>
//         <br />
//         <b>Paper Type</b>
//         <br />
//         <select
//           value={paperType}
//           onChange={(e) => setPaperType(e.target.value)}
//         >
//           <option value="0">select</option>
//           <option value="1">Pratice</option>
//           <option value="2">Ranking</option>
//         </select>
//         <br />
//         <div className={classes.save_cancel_btn}>
//           <button onClick={handleCancel}>Cancel</button>
//           <button onClick={handleSave}>Save</button>
//         </div>
//         <br />
//       </div>
//     </div>
//   );
// }

// export default AddTestSeriesPackage;
