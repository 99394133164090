// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyOrders_container__-NbBu {
  padding: 1.5rem;
}

.MyOrders_header__I9w68 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MyOrders_button_link__JLMag, .MyOrders_button_books__3-xPD {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  margin: 5px 5px;
  text-align: center;
}

.MyOrders_button_link__JLMag a, .MyOrders_button_books__3-xPD a {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
}

.MyOrders_message__4NB2j {
  font-size: 16px;
  color: gray;
}

.MyOrders_table__GcFLf {
  width: 100%;
  margin-top: 5px;
}

.MyOrders_productTitle__aicR6 {
  cursor: pointer;
  color: blue;
}
.MyOrders_main_content__-RtN7{
  margin-bottom: 10rem;
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
.MyOrders_button_link__JLMag, .MyOrders_button_books__3-xPD {
  padding: 4px 8px;
  font-size: 14px;
  width: 100%; /* Full width buttons for smaller screens */
}
}

@media (max-width: 480px) {
.MyOrders_button_link__JLMag, .MyOrders_button_books__3-xPD {
  padding: 3px 6px;
  font-size: 12px;
}
}
  
  `, "",{"version":3,"sources":["webpack://./src/component/MyOrders.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,oBAAoB;AACtB;;AAEA,6CAA6C;AAC7C;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW,EAAE,2CAA2C;AAC1D;AACA;;AAEA;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA","sourcesContent":[".container {\n  padding: 1.5rem;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.button_link, .button_books {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 5px 10px;\n  font-size: 16px;\n  border-radius: 5px;\n  cursor: pointer;\n  display: inline-block;\n  margin: 5px 5px;\n  text-align: center;\n}\n\n.button_link a, .button_books a {\n  color: white;\n  text-decoration: none;\n  display: block;\n  width: 100%;\n  height: 100%;\n}\n\n.message {\n  font-size: 16px;\n  color: gray;\n}\n\n.table {\n  width: 100%;\n  margin-top: 5px;\n}\n\n.productTitle {\n  cursor: pointer;\n  color: blue;\n}\n.main_content{\n  margin-bottom: 10rem;\n}\n\n/* Responsive adjustments for small screens */\n@media (max-width: 768px) {\n.button_link, .button_books {\n  padding: 4px 8px;\n  font-size: 14px;\n  width: 100%; /* Full width buttons for smaller screens */\n}\n}\n\n@media (max-width: 480px) {\n.button_link, .button_books {\n  padding: 3px 6px;\n  font-size: 12px;\n}\n}\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MyOrders_container__-NbBu`,
	"header": `MyOrders_header__I9w68`,
	"button_link": `MyOrders_button_link__JLMag`,
	"button_books": `MyOrders_button_books__3-xPD`,
	"message": `MyOrders_message__4NB2j`,
	"table": `MyOrders_table__GcFLf`,
	"productTitle": `MyOrders_productTitle__aicR6`,
	"main_content": `MyOrders_main_content__-RtN7`
};
export default ___CSS_LOADER_EXPORT___;
