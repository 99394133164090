import React, { useState, useEffect } from "react";
import classes from "./NewProducts.module.css";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import "tinymce/tinymce";
import tinymce from "tinymce";
import Cookies from "universal-cookie";

import Multiselect from "multiselect-react-dropdown";

const NewProducts = () => {
  const [productTitle, setProductTitle] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [ProductpermaLink, setProductPermaLink] = useState("");
  const [productPrice, setProductPrice] = useState(0);
  const [productMaxPrice, setProductMaxPrice] = useState(0);
  const [productvalidationPeriod, setproductValidationPeriod] = useState(0);
  const [productimage, setProductImage] = useState("");
  const [productMobileImage, setProductMobileImage] = useState("");
  const [productPdf, setProductPdf] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [uploadId, setUploadId] = useState(1);

  const [status, setStatus] = useState(0);

  const [examData, setExamData] = useState(null);
  const [examPostData, setExamPostData] = useState(null);

  const [examPostTierData, setExamPostTierData] = useState(null);
  const [examModeData, setExamModeData] = useState(null);
  const [selectedExamPostData, setSelectedExamPostData] = useState([]);
  // const [selectedExamModeId, setSelectedExamModeId] = useState(null);
  const [selectedExams, setSelectedExams] = useState([]);

  const [selectedExamPostTierData, setSelectedExamPostTierData] = useState([]);

  const [selectedExamModeData, setSelectedExamModeData] = useState([]);

  const cookies = new Cookies();

  const token = cookies.get("token");

  const handleTitleinputChange = (e) => {
    setProductTitle(e.target.value);
    // console.log(productTitle);
  };

  const handleSeoKeywordChange = (e) => {
    setSeoKeywords(e.target.value);
  };

  const handleSeoTitleChange = (e) => {
    setSeoTitle(e.target.value);
  };

  const handleSeoDescriptionChange = (e) => {
    setSeoDescription(e.target.value);
  };

  const handlePermalinkChange = (e) => {
    setProductPermaLink(e.target.value);
  };

  const handleCurrentPriceChange = (e) => {
    const price = parseInt(e.target.value);
    setProductPrice(price);
  };

  const handleMaxPriceChange = (e) => {
    const maxPrice = parseInt(e.target.value);
    setProductMaxPrice(maxPrice);
  };

  const handleProductValidationChange = (e) => {
    const validationPeriod = parseInt(e.target.value);
    setproductValidationPeriod(validationPeriod);
  };

  const handleImageUploadChange = (e) => {
    console.log("This function got clicked");
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      const fileName = file.name;
      setProductImage(fileName);
    }
  };
  const handleMoblieImageUploadChange = (e) => {
    const file = e.target.files[0];
    setProductMobileImage(file);
  };

  const handlePdfUploadChange = (e) => {
    const file = e.target.files[0];
    setProductPdf(file);
  };

  const handleStatusChange = (e) => {
    const statusValue = parseInt(e.target.value);
    setStatus(statusValue);
  };

  const handleEditorChange = (value) => {
    setProductDescription(value);
    // console.log(content);
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();

    // if (
    //   !productTitle ||
    //   !seoKeywords ||
    //   !seoDescription ||
    //   !seoTitle ||
    //   !productPrice ||
    //   !productMaxPrice ||
    //   !productvalidationPeriod ||
    //   !productDescription
    //   // !varietyId ||
    //   // !selectedVarietyId||
    //   // !exam_id||!exam_post
    // ) {
    //   alert("Please fill in all fields.");
    //   return;
    // }

    // Create a FormData object
    const formData = new FormData();
    // console.log(formData);

    // Append form data to the FormData object
    formData.append("product_title", productTitle);
    // formData.append("seoKeywords", seoKeywords);
    // formData.append("seoDescription", seoDescription);
    formData.append("product_permalink", ProductpermaLink);
    formData.append("seo_title", seoTitle);
    formData.append("product_price", productPrice);
    formData.append("product_max_price", productMaxPrice);
    formData.append("product_validation_period", productvalidationPeriod);
    formData.append("product_image", productimage);
    formData.append("product_mobile_image", productMobileImage);
    formData.append("product_pdf", productPdf);
    formData.append("product_description", productDescription);
    formData.append("upload_id", uploadId);
    formData.append("status", status !== undefined ? status : 0);
    formData.append("exam_id", JSON.stringify(selectedExams));
    formData.append("exam_post_id", JSON.stringify(selectedExamPostData));
    formData.append(
      "exam_post_tier_id",
      JSON.stringify(selectedExamPostTierData)
    );
    formData.append("exam_mode_id", JSON.stringify(selectedExamModeData));

    try {
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/addnewproduct",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token} `,
          },
        }
      );

      console.log(response.data);
      // console.log("Success:", response.data.success);

      if (response.data.success) {
        alert("Product inserted successfully!");
        const lastId = response.data.newProduct.id;
        const metaKeyData = await axios.post(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/addproductmetakeyvalue",
          {
            product_id: lastId,
            meta_key: seoKeywords,
            meta_keywords: seoDescription,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token} `,
            },
          }
        );
        // console.log("lastid:", lastId);
      }

      // console.log(lastId);
      setProductTitle("");
      setProductPermaLink("");
      setSeoTitle("");
      setProductPrice(0);
      setProductMaxPrice(0);
      setproductValidationPeriod(0);
      setProductDescription("");
      setStatus(0);
      setSeoDescription("");
      setSeoKeywords("");
      setProductImage("");
      setProductPdf("");
      setProductMobileImage("");
      // setSelectedVarietyId("");
      // setVariousData("");
      // setSelectedVariety("");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const selectExamChange = async () => {
      try {
        const examdata = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/chooseyourexamdata",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        setExamData(examdata.data);
        console.log(examdata.data);
      } catch (error) {
        console.log("an internal server error occur");
      }
    };
    selectExamChange();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch exam post data
        const examPostResponse = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/selectyourexampost",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        setExamPostData(examPostResponse.data);
        console.log("Exam Post Data:", examPostResponse.data);

        // Fetch exam post tier data
        const examPostTierResponse = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/selectyourposttierdata",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        setExamPostTierData(examPostTierResponse.data);
        console.log("Exam Post Tier Data:", examPostTierResponse.data);

        // Fetch exam mode data
        const examModeResponse = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/getyourexammodedata",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        setExamModeData(examModeResponse.data);
        console.log("Exam Mode Data:", examModeResponse.data);
      } catch (error) {
        console.log("An internal server error occurred");
      }
    };

    fetchData();
  }, []);

  const handleSelectExams = (selectedList) => {
    // console.log("line 271", selectedList);
    // console.log("line272", selectedItem);
    setSelectedExams(selectedList.map((item) => item.id));
  };

  const handleselectPostData = (selectedPostList) => {
    setSelectedExamPostData(selectedPostList.map((item) => item.id));
  };

  const handlePostTierData = (selectedPostTier) => {
    setSelectedExamPostTierData(selectedPostTier.map((item) => item.id));
  };

  const handleModeDataSelect = (selectedExamMode) => {
    setSelectedExamModeData(selectedExamMode.map((item) => item.id));
  };

  // useEffect(() => {
  //   console.log(" line 276 Selected Exam IDs State:", selectedExams);
  // }, [selectedExams]);

  // useEffect(() => {
  //   console.log("line 285 selected Exam PostIds", selectedExamPostData);
  // }, [selectedExamPostData]);

  // useEffect(() => {
  //   console.log("line 293 selected Exam PosttierIds", selectedExamPostTierData);
  // }, [selectedExamPostTierData]);

  // useEffect(() => {
  //   console.log("line 301 selected Exam mode", selectedExamModeData);
  // }, [selectedExamModeData]);

  return (
    <>
      <div className={classes.new_pro_main_div}>
        <h3 className={classes.prod_heading}>ADD PRODUCT</h3>

        <form className={classes.form_editor}>
          <div className={classes.form_group}>
            <label className={classes.label_tag}>Title :</label>
            <input
              type="text"
              name="title"
              className={classes.form_control}
              placeholder="title"
              onChange={handleTitleinputChange}
            />
          </div>
          <div className={classes.form_group}>
            <label className={classes.label_tag}>SEO Keywords :</label>
            <input
              type="text"
              name="seo_keyword"
              className={classes.form_control}
              placeholder="SEO KEYWORDS"
              onChange={handleSeoKeywordChange}
            />
          </div>
          <div className={classes.form_group}>
            <label className={classes.label_tag}>SEO Description :</label>
            <input
              type="text"
              name="seo_description"
              className={classes.form_control}
              placeholder="SEO DESCRIPTION"
              onChange={handleSeoDescriptionChange}
            />
          </div>

          <div>
            {examData && (
              <Multiselect
                options={examData}
                displayValue="exam_name"
                placeholder="select Exams"
                showCheckbox="true"
                selectedValues={examData.filter((item) =>
                  selectedExams.includes(item.id)
                )}
                onSelect={handleSelectExams}
                onRemove={handleSelectExams}
              ></Multiselect>
            )}

            {examPostData && (
              <Multiselect
                options={examPostData}
                displayValue="exam_post_name"
                placeholder="select exam post "
                showCheckbox="true"
                selectedValues={examPostData.filter((postdata) =>
                  selectedExamPostData.includes(postdata.id)
                )}
                onSelect={handleselectPostData}
                onRemove={handleselectPostData}
              ></Multiselect>
            )}
            {examPostTierData && (
              <Multiselect
                options={examPostTierData}
                displayValue="post_tier_name"
                placeholder="select exam post tier"
                showCheckbox="true"
                selectedValues={examPostTierData.filter((tierdata) =>
                  selectedExamPostTierData.includes(tierdata.id)
                )}
                onSelect={handlePostTierData}
                onRemove={handlePostTierData}
              ></Multiselect>
            )}
            {examModeData && (
              <Multiselect
                options={examModeData}
                displayValue="exam_mode_name"
                showCheckbox="true"
                selectedValues={examModeData.filter((modedata) =>
                  selectedExamModeData.includes(modedata.id)
                )}
                onSelect={handleModeDataSelect}
                onRemove={handleModeDataSelect}
              ></Multiselect>
            )}
          </div>

          <div className={classes.form_group}>
            <label className={classes.label_tag}>Permalink :</label>
            <input
              type="text"
              name="permalink"
              className={classes.form_control}
              placeholder="Permalink"
              onChange={handlePermalinkChange}
            />
          </div>

          <div className={classes.form_group}>
            <label className={classes.label_tag}>Price :</label>
            <input
              type="text"
              name="price"
              className={classes.form_control}
              placeholder="price"
              onChange={handleCurrentPriceChange}
            />
          </div>

          <div className={classes.form_group}>
            <label className={classes.label_tag}>MRP :</label>
            <input
              type="text"
              name="max_price"
              className={classes.form_control}
              placeholder="1299.00"
              onChange={handleMaxPriceChange}
            />
          </div>
          <div className={classes.form_group}>
            <label className={classes.label_tag}>Validation Period :</label>
            <input
              type="text"
              name="validation_period"
              className={classes.form_control}
              placeholder="validation period in day"
              onChange={handleProductValidationChange}
            />
          </div>
          <div className={classes.form_group}>
            <label className={classes.label_tag}>
              Image Upload (512*512px) :
            </label>
            <input
              type="file"
              name="image_upload"
              className={classes.form_control}
              onChange={handleImageUploadChange}
            />
          </div>
          <div className={classes.form_group}>
            <label className={classes.label_tag}>
              Mobile Image Upload (460*400px)
            </label>
            <input
              type="file"
              name="mobile_image_upload"
              className={classes.form_control}
              onChange={handleMoblieImageUploadChange}
            />
          </div>
          <div className={classes.form_group}>
            <label className={classes.label_tag}>PDF Upload :</label>
            <input
              type="file"
              name="pdf_upload"
              className={classes.form_control}
              onChange={handlePdfUploadChange}
            />
          </div>
          <div className={classes.batch_div}>
            <label>
              Batch{" "}
              <span style={{ color: "red" }}>
                (only for descriptive product)
              </span>
            </label>
          </div>

          {/* <div>
            <label>Short Description :</label>
            <textarea id="shorr_desc" name="" className={classes.form_control}></textarea>
          </div> */}
          <div className={classes.long_des}>
            <label className={classes.long_des_heading}>
              Long Description :
            </label>
            <Editor onEditorChange={handleEditorChange} />
          </div>

          <div className={classes.form_group}>
            <label className={classes.control_label}>Status :</label>
            <br />
            <label className={classes.active_label}>
              <input
                type="radio"
                name="status"
                className={classes.active_int}
                value="1"
                onChange={handleStatusChange}
              />
              Active
            </label>
            <label className={classes.inactive_label}>
              <input
                type="radio"
                name="status"
                className={classes.active_int}
                value="0"
                onChange={handleStatusChange}
              />
              Inactive
            </label>
          </div>
          <button className={classes.publish_btn} onClick={handleSubmitData}>
            PUBLISH
          </button>
        </form>
      </div>
    </>
  );
};

export default NewProducts;
