import React from 'react';
import CreateUserForm from './CreateUserForm';

const AddUser = () => {
  return (
    <div>
      <CreateUserForm />
    </div>
  );
};

export default AddUser;
