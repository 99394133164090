// import React, { useState, useEffect, useRef } from "react";
// import {
//   Container,
//   Row,
//   Col,
//   Form,
//   Button,
//   Alert,
//   Modal,
// } from "react-bootstrap";
// import DummyComponent from "../Component/DummyComponent";

// function MobileVerification() {
//   const [mobileNumber, setMobileNumber] = useState("");

//   const handleSendOtp = async () => {
//     if (!mobileNumber) return; // Add this line to prevent running when mobileNumber is empty

//     console.log("MOBILE_NUMBER", mobileNumber);

//     // const data = new FormData();
//     // data.append('mobileNumber', mobileNumber);
//     const data = { mobileNumber };

//     // try {
//     //   const response = await fetch(
//     //     process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/mobileVerification",
//     //     {
//     //       method: "POST",
//     //       headers: { "Content-Type": "application/json" },
//     //       body: JSON.stringify(data),
//     //     }
//     //   );
//     //   // const responseData = await response.json();
//     //   // console.log(responseData);
//     //   if (response.ok) {
//     //     const responseData = await response.json();
//     //     console.log("responseData", responseData);
//     //     console.log("responseData.data", responseData.data);

//     //     setShowMessage(responseData.message); // Show the response message

//     //     setTimeout(() => {
//     //       setShowMessage(null); // hide the response message
//     //       clearInterval(timerRef.current);
//     //       setLoginWithMobile(true);
//     //       setStartTimer(true);
//     //       setSeconds(initialSeconds);
//     //     }, 2000);
//     //   }
//     // } catch (error) {
//     //   console.error("Error fetching data:", error);
//     // }
//   };

//   const emailBody = (
//     <Form>
//       <Form.Group controlId="formMobile" className="form-div">
//         <Form.Label>Please enter your mobile number to register</Form.Label>
//         <Row>
//           <Col xs={2}>
//             <Form.Control
//               type="text"
//               className="input-field"
//               value="+91"
//               readOnly
//             />
//           </Col>
//           <Col xs={7} className="p-0">
//             <Form.Control
//               type="text"
//               placeholder="enter your 10 digit mobile number"
//               className="input-field"
//               value={mobileNumber}
//               onChange={(e) => setMobileNumber(e.target.value)}
//             />
//           </Col>
//           <Col xs={3}>
//             <Button
//               variant="primary"
//               onClick={handleSendOtp}
//               className="get-otp-btn"
//             >
//               Get OTP
//             </Button>
//           </Col>
//         </Row>
//       </Form.Group>
//     </Form>
//   );

//     const footer = (
//     <Button
//       variant="primary"
//       onClick={(e) => {
//         handleEmailLogin(e)
//         /* Add save functionality here */
//       }}
//     >
//       Submit
//     </Button>
//   );

//   return (
//     <div>
//       <Home />
//       <Modal show={show} onHide={() => {}} backdrop="static" keyboard={false} className="modal-centered">
//         <Modal.Header>
//           <Modal.Title>{title}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>{showMessage? showMessage: emailBody}</Modal.Body>
//         <Modal.Footer>
//           {footer || (
//             <>
//               <Button variant="secondary" onClick={() => {}}>
//                 Close
//               </Button>
//               <Button variant="primary" onClick={() => {}}>
//                 Save Changes
//               </Button>
//             </>
//           )}
//         </Modal.Footer>
//       </Modal>
//     </div>
// )}

// export default MobileVerification;

// {!loginWithMobile && (
// )}
// {showMessage && (
//   <Alert variant="success" className={classes.alert}>
//     {showMessage}
//   </Alert>
// )}
// {loginWithMobile && (
//   <div>
//     <div className={classes.mobileNumber}>
//       <p>(+91) {mobileNumber}</p>
//       <img
//         src={editIcon}
//         onClick={handleEditMobileNumber}
//         alt="Edit Phone No."
//       />
//     </div>
//     <div>
//       {startTimer && seconds > 0 && (
//         <p style={{ fontSize: "11px", color: "darkblue" }}>
//           Resend OTP in &nbsp;
//           {Math.floor(seconds / 60)}:
//           {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
//           &nbsp; minutes
//         </p>
//       )}

//       {startTimer && seconds === 0 && (
//         <div className={classes.resendOtp}>
//           <button
//             className={classes.actionButton}
//             onClick={handleSendOtp}
//           >
//             Resend OTP
//           </button>
//         </div>
//       )}

//       <div>
//         <label style={{ width: '100%' }}>
//           <p>Enter OTP:</p>
//           <OtpVerification handleVerifyOtp={handleVerifyOtp}/>
//         </label>
//       </div>
//     </div>
//   </div>
// )}

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import axios from "axios";

import Home from "../component/Home";
// import OverlayModal from "../Component/OverlayModal";
// import OtpVerification from "../Component/OtpVerification";
import editIcon from "../assets/edit.png";
import "./EmailVerification.css";
import classes from "../component/loginComponent/DummyComponent.module.css";

import { useSelector, useDispatch } from "react-redux";
import { addProfile } from "../redux/slices/userProfileSlice";

const MobileVerification = ({ initialSeconds = 20, numberOfDigits = 6 }) => {
  const [show, setShow] = useState(true);
  const [loginWithMobile, setLoginWithMobile] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [fullName, setFullName] = useState("");
  // const [otp, setOtp] = useState(""); // State to store OTP value
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsModalShow, setTermsModalShow] = useState(false); // State for terms modal
  const [termsContent, setTermsContent] = useState(""); // State to store fetched terms content

  const [seconds, setSeconds] = useState(initialSeconds);
  const [startTimer, setStartTimer] = useState(false);
  const [showMessage, setShowMessage] = useState(null); // State to show the response message
  const timerRef = useRef(null);

  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);

  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);

  console.log("userProfile", userProfile);
  const navigate = useNavigate();

  useEffect(() => {
    if (startTimer && seconds > 0) {
      timerRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(timerRef.current);
  }, [startTimer, seconds]);

  const handleEditMobileNumber = (e) => {
    setLoginWithMobile(false);
    setStartTimer(false);
    clearInterval(timerRef.current);
    setShowMessage(null); // hide the response message
  };

  // const handleSendOtp = async (e) => {
  const handleSendOtp = async (useWhatsApp = false) => {
    //e.preventDefault(); // Prevent form submission

    if (!userProfile._id) {
      navigate("/login");
    }

    // if (!mobileNumber) {
    //   setShowMessage("Mobile Number must not be empty");
    //   return; // Add this line to prevent running when mobileNumber is empty
    // }

    const mobileNumberRegex = /^[6-9]\d{9}$/; // Indian mobile number format
    if (!mobileNumber || !mobileNumberRegex.test(mobileNumber)) {
      setShowMessage("Invalid Mobile Number");
      return;
    }

    // const email_id = email;

    // const data = { _id: items.userProfile._id, email_id: email,  };
    const data = useWhatsApp
      ? { _id: userProfile._id, whatsApp: 1, mobileNumber, fullName }
      : { _id: userProfile._id, mobileNumber, fullName };
    // const data = { mobileNumber };

    // console.log("Items Inside Handler", items.userProfile._id);
    console.log("data", data);
    console.log("USEWHATSAPP", useWhatsApp);

    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/users/mobileVerificationAfterEmail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      console.log(response);
      if (response.ok) {
        const responseData = await response.json();
        console.log("responseData", responseData);

        setShowMessage(responseData.message); // Show the response message
        dispatch(addProfile(responseData.data));

        setTimeout(() => {
          setShowMessage(null); // hide the response message
          clearInterval(timerRef.current);
          setLoginWithMobile(true);
          setStartTimer(true);
          setSeconds(initialSeconds);
        }, 2000);
      } else {
        // console.error("Something went wrong in sending OTP");
        console.error("This Mobile number is already registered");

        // setShowMessage("Something went wrong in sending OTP");
        // Show the response message
        setShowMessage("Mobile number already registered");
        throw new Error("Could not fetch events");
      }
    } catch (error) {
      console.log(error);
      console.error("Error fetching data:", error);
      // setShowMessage("Something went wrong in sending OTP"); // Show the response message
      setShowMessage(
        "This mobile number is already registered.Please try to register using another mobile number"
      );
    }
  };

  //   const handleSendOtp = async () => {
  //     //if (!mobileNumber) return; // Add this line to prevent running when mobileNumber is empty

  //     // console.log("MOBILE_NUMBER", mobileNumber);

  //     // const data = new FormData();
  //     // data.append('mobileNumber', mobileNumber);
  //     // const data = { mobileNumber };

  //     // try {
  //     //   const response = await fetch(
  //     //     process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/mobileVerification",
  //     //     {
  //     //       method: "POST",
  //     //       headers: { "Content-Type": "application/json" },
  //     //       body: JSON.stringify(data),
  //     //     }
  //     //   );
  //     //   // const responseData = await response.json();
  //     //   // console.log(responseData);
  //     //   if (response.ok) {
  //     //     const responseData = await response.json();
  //     //     console.log("responseData", responseData);
  //     //     console.log("responseData.data", responseData.data);

  //     //     setShowMessage(responseData.message); // Show the response message

  //     //     setTimeout(() => {
  //     //       setShowMessage(null); // hide the response message
  //     //       clearInterval(timerRef.current);
  //     //       setLoginWithMobile(true);
  //     //       setStartTimer(true);
  //     //       setSeconds(initialSeconds);
  //     //     }, 2000);
  //     //   }
  //     // } catch (error) {
  //     //   console.error("Error fetching data:", error);
  //     // }
  //   };

  function handleChange(value, index) {
    // setOtpError(null);
    setShowMessage(null);
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);
    // setOtpLocal(newArr);
    // setOtp(newArr.join(""));
    console.log(value);
    console.log(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    // setOtpError(null);
    setShowMessage(null);
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }

    if (
      (e.target.value && index < numberOfDigits - 1) ||
      (e.key === "Enter" && index < numberOfDigits - 1)
    ) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  // async function handleOnSubmit() {
  //   const mobileOtp = parseInt(otp.join(""), 10);
  //   console.log("mobileOtp", mobileOtp);
  //   if (mobileOtp.toString().length === 6) {
  //     console.log("SENDING OTP VALUE IS ", mobileOtp);
  //     handleVerifyOtp(mobileOtp);

  //   } else {
  //     setShowMessage("❌ Wrong OTP Please Check Again");
  //   }
  // }

  const fetchTermsContent = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/terms-and-conditions"
      );
      if (response.ok) {
        const data = await response.json();
        setTermsContent(data.content);
      } else {
        setTermsContent("Failed to load terms and conditions.");
      }
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
      setTermsContent(error.message);
    }
  };

  const handleShowTermsModal = () => {
    fetchTermsContent();
    setTermsModalShow(true);
  };

  const handleCloseTermsModal = () => {
    setTermsModalShow(false);
  };

  const handleVerifyOtp = async () => {
    const mobileOtp = parseInt(otp.join(""), 10);
    // const data = { otp: mobileOtp, mobileNumber };
    // if(otp.length < 6) return setOtpError("❌ Wrong OTP Please Check Again");
    if (mobileOtp.length < 6)
      return setShowMessage("❌ Wrong OTP Please Check Again");
    const data = { otp: mobileOtp, mobileNumber };

    try {
      const response = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/users/mobileOtpVerificationReport",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData);

        setShowMessage(responseData.message); // Show the response message
        dispatch(addProfile(responseData.data));

        setTimeout(() => {
          setShowMessage(null); // hide the response message
          if (
            responseData.data.mobile_number &&
            responseData.data.verified_mobile_number &&
            responseData.data.email_id &&
            responseData.data.verified_email
          ) {
            navigate("/set-password");
          } else {
            navigate("/email-verification"); // Navigate after 2 seconds
          }
        }, 2000);
      } else {
        setShowMessage(responseData.message);
      }
    } catch (error) {
      setShowMessage(error.message);
      // setShowMessage("Something went wrong. Please try again");
      console.error("Error fetching data:", error);
    }
  };

  const emailBody = (
    <Container>
      {!loginWithMobile && (
        <Form>
          <Form.Group controlId="FullName">
            <Form.Label>
              <p>Please enter your Name & Mobile number to proceed further.</p>
            </Form.Label>
            <Row className="m-0">
              <Form.Control
                type="text"
                placeholder="Full Name"
                className="input-field"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                autoFocus
              />
            </Row>
          </Form.Group>
          <Form.Group controlId="formMobile" className="form-div">
            <Row>
              <Col xs={2}>
                <Form.Control
                  type="text"
                  className="input-field"
                  value="+91"
                  readOnly
                />
              </Col>
              <Col xs={10} className="p-0">
                <Form.Control
                  // type="text"
                  type="tel" // Specifies this as a telephone number input
                  placeholder="enter your 10 digit mobile number"
                  className="input-field"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  autoComplete="tel" // Hints the browser to suggest only phone numbers
                  maxLength={10} // Ensures the user can only enter a 10-digit number
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      )}
      {loginWithMobile && (
        <div>
          <div className={classes.mobileNumber}>
            <p>(+91) {mobileNumber}</p>
            <img
              src={editIcon}
              onClick={handleEditMobileNumber}
              alt="Edit Phone No."
            />
          </div>
          <div>
            {startTimer && seconds > 0 && (
              <div className={classes.resendOtp}>
                <p style={{ fontSize: "11px", color: "darkblue" }}>
                  Resend OTP in &nbsp;
                  {Math.floor(seconds / 60)}:
                  {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
                  &nbsp; minutes
                </p>
                <button
                  className={classes.actionButton}
                  // onClick={() => handleWhatsAppOtp(true)}
                  onClick={() => handleSendOtp(true)}
                >
                  WhatsApp
                </button>
              </div>
            )}

            {startTimer && seconds === 0 && (
              <div className={classes.resendOtp}>
                <button
                  className={classes.actionButton}
                  onClick={() => handleSendOtp(false)}
                >
                  Resend OTP
                </button>
                <button
                  className={classes.actionButton}
                  // onClick={() => handleWhatsAppOtp(true)}
                  onClick={() => handleSendOtp(true)}
                >
                  WhatsApp
                </button>
              </div>
            )}

            <div>
              <label style={{ width: "100%" }}>
                <p>Enter OTP:</p>
                {/* <Form.Check
                  type="checkbox"
                  id="termsCheckbox"
                  // label="I agree to terms & conditions"
                  label={
                    <span
                      onClick={handleShowTermsModal}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      I agree to terms & conditions
                    </span>
                  }
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                /> */}
                <Form.Check
                  type="checkbox"
                  id="termsCheckbox"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px", // Spacing between checkbox and label
                  }}
                >
                  <Form.Check.Input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    style={{
                      width: "18px", // Custom checkbox size
                      height: "18px",
                      border: "2px solid black", // Thick border for the checkbox
                      borderRadius: "4px", // Optional rounded corners
                      appearance: "none", // Remove default browser styles
                      backgroundColor: termsAccepted ? "#007bff" : "white", // Background color when checked
                      cursor: "pointer", // Pointer cursor for interactivity
                    }}
                  />
                  <Form.Check.Label
                    htmlFor="termsCheckbox"
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline", // Underline for the label text
                      marginBottom: "0", // Remove default margin
                    }}
                    onClick={handleShowTermsModal} // Opens terms modal on label click
                  >
                    I agree to terms & conditions
                  </Form.Check.Label>
                </Form.Check>

                {/*
                  <OtpVerification handleVerifyOtp={handleVerifyOtp} setOtp={setOtp} />
                  */}
                <div className={` ${classes.otpInputDiv}`}>
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      value={digit}
                      name="mobileOtp"
                      maxLength={1}
                      onChange={(e) => handleChange(e.target.value, index)}
                      onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                      ref={(reference) =>
                        (otpBoxReference.current[index] = reference)
                      }
                      // className={classes.otpInputBox}
                      className={classes.input}
                    />
                  ))}
                  {/*
                    <button
                    className={` ${classes.actionButton} `}
                    onClick={handleOnSubmit}
                    >
                    Verify OTP
                    </button>
                    */}
                </div>
                {/*
                <p
                  className={`text-lg mt-4 ${
                    showMessage ? classes.errorMessage : ""
                  }`}
                >
                  {showMessage}
                </p>
                */}
              </label>
            </div>
          </div>
        </div>
      )}
      {showMessage && (
        <Alert variant="success" className={classes.alert}>
          {showMessage}
        </Alert>
      )}
    </Container>
  );

  const title = <h1>Sign-up incomplete !</h1>;

  const footer = (
    <Button
      variant="primary"
      disabled={loginWithMobile ? !termsAccepted : false} // Disable button if terms are not accepted
      onClick={() => {
        loginWithMobile ? handleVerifyOtp() : handleSendOtp(false);
        /* Add save functionality here */
      }}
    >
      Submit
    </Button>
  );

  return (
    <div>
      <Home />
      {!termsModalShow && (
        <Modal
          show={show}
          onHide={() => {}}
          backdrop="static"
          keyboard={false}
          className="modal-centered"
        >
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{emailBody}</Modal.Body>
          <Modal.Footer>{footer}</Modal.Footer>
        </Modal>
      )}

      <Modal show={termsModalShow} onHide={handleCloseTermsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {termsContent ? (
            <div dangerouslySetInnerHTML={{ __html: termsContent }} />
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTermsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MobileVerification;

// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   Row,
//   Col,
//   Form,
//   Button,
//   Alert,
//   Modal,
// } from "react-bootstrap";
// import axios from "axios";

// import Home from "../component/Home";
// import editIcon from "../assets/edit.png";
// import "./EmailVerification.css";
// import classes from "../component/loginComponent/DummyComponent.module.css";

// import { useSelector, useDispatch } from "react-redux";
// import { addProfile } from "../redux/slices/userProfileSlice";

// const MobileVerification = ({ initialSeconds = 20, numberOfDigits = 6 }) => {
//   const [show, setShow] = useState(true);
//   const [loginWithMobile, setLoginWithMobile] = useState(false);
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [fullName, setFullName] = useState("");
//   const [termsAccepted, setTermsAccepted] = useState(false);
//   const [termsModalShow, setTermsModalShow] = useState(false);
//   const [termsContent, setTermsContent] = useState("");

//   const [seconds, setSeconds] = useState(initialSeconds);
//   const [startTimer, setStartTimer] = useState(false);
//   const [showMessage, setShowMessage] = useState(null);
//   const timerRef = useRef(null);

//   const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
//   const otpBoxReference = useRef([]);

//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");

//   const dispatch = useDispatch();
//   const userProfile = useSelector((state) => state.userProfile);

//   const navigate = useNavigate();

//   useEffect(() => {
//     if (startTimer && seconds > 0) {
//       timerRef.current = setInterval(() => {
//         setSeconds((prevSeconds) => prevSeconds - 1);
//       }, 1000);
//     }

//     return () => clearInterval(timerRef.current);
//   }, [startTimer, seconds]);

//   const handleEditMobileNumber = () => {
//     setLoginWithMobile(false);
//     setStartTimer(false);
//     clearInterval(timerRef.current);
//     setShowMessage(null);
//   };

//   const handleSendOtp = async (useWhatsApp = false) => {
//     if (!userProfile._id) {
//       navigate("/login");
//     }

//     const mobileNumberRegex = /^[6-9]\d{9}$/;
//     if (!mobileNumber || !mobileNumberRegex.test(mobileNumber)) {
//       setShowMessage("Invalid Mobile Number");
//       return;
//     }

//     const data = useWhatsApp
//       ? { _id: userProfile._id, whatsApp: 1, mobileNumber, fullName }
//       : { _id: userProfile._id, mobileNumber, fullName };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/users/mobileVerificationAfterEmail`,
//         data
//       );

//       if (response.status === 200) {
//         const responseData = response.data;

//         setShowMessage(responseData.message);
//         dispatch(addProfile(responseData.data));

//         setTimeout(() => {
//           setShowMessage(null);
//           clearInterval(timerRef.current);
//           setLoginWithMobile(true);
//           setStartTimer(true);
//           setSeconds(initialSeconds);
//         }, 2000);
//       } else {
//         console.error("Something went wrong in sending OTP");
//         setShowMessage("Something went wrong in sending OTP");
//       }
//     } catch (error) {
//       console.error("Error sending OTP:", error);
//       setShowMessage("Something went wrong in sending OTP");
//     }
//   };

//   const handleChange = (value, index) => {
//     setShowMessage(null);
//     let newArr = [...otp];
//     newArr[index] = value;
//     setOtp(newArr);

//     if (value && index < numberOfDigits - 1) {
//       otpBoxReference.current[index + 1].focus();
//     }
//   };

//   const handleBackspaceAndEnter = (e, index) => {
//     setShowMessage(null);
//     if (e.key === "Backspace" && !e.target.value && index > 0) {
//       otpBoxReference.current[index - 1].focus();
//     }

//     if (
//       (e.target.value && index < numberOfDigits - 1) ||
//       (e.key === "Enter" && index < numberOfDigits - 1)
//     ) {
//       otpBoxReference.current[index + 1].focus();
//     }
//   };

//   const handleVerifyOtp = async () => {
//     const mobileOtp = parseInt(otp.join(""), 10);
//     if (otp.join("").length < 6) {
//       return setShowMessage("❌ Wrong OTP Please Check Again");
//     }

//     const data = { otp: mobileOtp, mobileNumber };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/users/mobileOtpVerificationReport`,
//         data
//       );

//       const responseData = response.data;
//       if (response.status === 200) {
//         setShowMessage(responseData.message);
//         dispatch(addProfile(responseData.data));

//         setTimeout(() => {
//           setShowMessage(null);
//           setShow(false);
//         }, 2000);
//       } else {
//         setShowMessage(responseData.message);
//       }
//     } catch (error) {
//       setShowMessage("Something went wrong. Please try again");
//       console.error("Error verifying OTP:", error);
//     }
//   };

//   const handleSetPassword = async () => {
//     if (password !== confirmPassword) {
//       setShowMessage("Passwords do not match");
//       return;
//     }

//     const data = { password, mobileNumber };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/users/setPassword`,
//         data
//       );

//       if (response.status === 200) {
//         setShowMessage("Password set successfully");
//         setTimeout(() => {
//           navigate("/login");
//         }, 2000);
//       } else {
//         setShowMessage("Failed to set password");
//       }
//     } catch (error) {
//       setShowMessage("Something went wrong. Please try again");
//       console.error("Error setting password:", error);
//     }
//   };

//   const fetchTermsContent = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/terms-and-conditions`
//       );
//       if (response.status === 200) {
//         setTermsContent(response.data.content);
//       } else {
//         setTermsContent("Failed to load terms and conditions.");
//       }
//     } catch (error) {
//       console.error("Error fetching terms and conditions:", error);
//       setTermsContent(error.message);
//     }
//   };

//   const handleShowTermsModal = () => {
//     fetchTermsContent();
//     setTermsModalShow(true);
//   };

//   const handleCloseTermsModal = () => {
//     setTermsModalShow(false);
//   };

//   const emailBody = (
//     <Container>
//       {!loginWithMobile && (
//         <Form>
//           <Form.Group controlId="FullName">
//             <Form.Label>
//               <p>Please enter your Name & Mobile number to proceed further.</p>
//             </Form.Label>
//             <Row className="m-0">
//               <Form.Control
//                 type="text"
//                 placeholder="Full Name"
//                 className="input-field"
//                 value={fullName}
//                 onChange={(e) => setFullName(e.target.value)}
//                 autoFocus
//               />
//             </Row>
//           </Form.Group>
//           <Form.Group controlId="formMobile" className="form-div">
//             <Row>
//               <Col xs={2}>
//                 <Form.Control
//                   type="text"
//                   className="input-field"
//                   value="+91"
//                   readOnly
//                 />
//               </Col>
//               <Col xs={10} className="p-0">
//                 <Form.Control
//                   type="text"
//                   placeholder="enter your 10 digit mobile number"
//                   className="input-field"
//                   value={mobileNumber}
//                   onChange={(e) => setMobileNumber(e.target.value)}
//                 />
//               </Col>
//             </Row>
//           </Form.Group>
//         </Form>
//       )}
//       {loginWithMobile && (
//         <div>
//           <div className={classes.mobileNumber}>
//             <p>(+91) {mobileNumber}</p>
//             <img
//               src={editIcon}
//               onClick={handleEditMobileNumber}
//               alt="Edit Phone No."
//             />
//           </div>
//           <div>
//             {startTimer && seconds > 0 && (
//               <div className={classes.resendOtp}>
//                 <p style={{ fontSize: "11px", color: "darkblue" }}>
//                   Resend OTP in &nbsp;
//                   {Math.floor(seconds / 60)}:
//                   {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
//                 </p>
//               </div>
//             )}
//             <div className={classes.termsContainer}>
//               <label>
//                 <input
//                   type="checkbox"
//                   className={classes.termsCheckbox}
//                   checked={termsAccepted}
//                   onChange={(e) => setTermsAccepted(e.target.checked)}
//                 />
//                 <span
//                   onClick={handleShowTermsModal}
//                   style={{ cursor: "pointer", textDecoration: "underline" }}
//                 >
//                   I accept terms and conditions
//                 </span>
//               </label>
//             </div>
//             <div className={classes.otpContainer}>
//               {otp.map((value, index) => (
//                 <input
//                   key={index}
//                   type="text"
//                   className={classes.otpField}
//                   value={value}
//                   maxLength={1}
//                   onChange={(e) => handleChange(e.target.value, index)}
//                   onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
//                   ref={(el) => (otpBoxReference.current[index] = el)}
//                   disabled={!termsAccepted}
//                 />
//               ))}
//             </div>
//             <button
//               className={classes.actionButton}
//               onClick={handleVerifyOtp}
//               disabled={!termsAccepted}
//             >
//               Verify OTP
//             </button>
//             <div className={classes.passwordContainer}>
//               <input
//                 type="password"
//                 placeholder="New Password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 disabled={!termsAccepted}
//               />
//               <input
//                 type="password"
//                 placeholder="Confirm Password"
//                 value={confirmPassword}
//                 onChange={(e) => setConfirmPassword(e.target.value)}
//                 disabled={!termsAccepted}
//               />
//               <button
//                 className={classes.actionButton}
//                 onClick={handleSetPassword}
//                 disabled={!termsAccepted || password !== confirmPassword}
//               >
//                 Set Password
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//       {showMessage && <Alert variant="danger">{showMessage}</Alert>}
//       {!loginWithMobile && (
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <Button onClick={() => handleSendOtp(false)} className="submit-btn">
//             Send OTP
//           </Button>
//           <Button onClick={() => handleSendOtp(true)} className="submit-btn">
//             Send via WhatsApp
//           </Button>
//         </div>
//       )}
//     </Container>
//   );

//   return (
//     <div>
//       <Modal show={show} size="lg" centered backdrop="static">
//         <Modal.Header className="header">
//           <Modal.Title>Mobile Verification</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="email-body">
//           {emailBody}
//           <Modal.Footer>
//             <Button
//               className="skip-btn"
//               onClick={() => {
//                 setShow(false);
//                 navigate("/set-password");
//               }}
//             >
//               Skip
//             </Button>
//           </Modal.Footer>
//         </Modal.Body>
//       </Modal>
//       <Home />
//       <Modal
//         show={termsModalShow}
//         onHide={handleCloseTermsModal}
//         centered
//         size="lg"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Terms and Conditions</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div
//             dangerouslySetInnerHTML={{ __html: termsContent }}
//             className={classes.termsModalBody}
//           />
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseTermsModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default MobileVerification;
