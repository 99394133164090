// import React, { useState, useEffect } from "react";
// import styles from "./Students.module.css";
// import axios from "axios"; // Importing axios for API calls
// import { Link } from "react-router-dom";
// import Cookies from "universal-cookie";

// const Students = () => {
//   const [data, setData] = useState([]); // Data from API
//   const [searchTerm, setSearchTerm] = useState(""); // Search term state
//   const [currentPage, setCurrentPage] = useState(1); // Pagination state
//   const [rowsPerPage] = useState(10); // Rows to show per page

//   const cookies = new Cookies();

//   const token = cookies.get("token");

//   // Fetch data from the API when the component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/register-students`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         ); // Making API call to the correct URL
//         console.log("Response", response.data);
//         setData(response.data); // Set the data in state
//       } catch (error) {
//         console.error("Error fetching student data:", error);
//       }
//     };

//     fetchData();
//   }, [token]);

//   // Pagination calculation
//   const indexOfLastRow = currentPage * rowsPerPage;
//   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
//   const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

//   // Handle search functionality
//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   // Filter the data based on search term (with undefined checks)
//   const filteredData = currentRows.filter((item) => {
//     const fullName = item.fullName?.toLowerCase() || "";
//     const email = item.email?.toLowerCase() || "";
//     const mobile = item.mobile || "";

//     return (
//       fullName.includes(searchTerm.toLowerCase()) ||
//       email.includes(searchTerm.toLowerCase()) ||
//       mobile.includes(searchTerm)
//     );
//   });

//   // Handle pagination
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   // Render the table rows
//   const renderRows = filteredData.map((row) => (
//     <tr key={row.id} className={styles.tableRow}>
//       <td>{row.id}</td>
//       <td>
//         <Link to={`/admin/users/studentdetails/${row.email_id}`} className={styles.linktext}>
//           {row.full_name}
//         </Link>
//       </td>
//       <td>{row.email_id}</td>
//       <td>{row.mobile_number}</td>
//       <td>{row.residence_state}</td>
//       <td>{row.password}</td>
//       <td>{row.status}</td>
//       <td>{row.ts.split(" ")[0]}</td>
//     </tr>
//   ));

//   // Generate pagination buttons
//   const pageNumbers = [];
//   for (let i = 1; i <= Math.ceil(data.length / rowsPerPage); i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <div className={styles.container}>
//       <h1 className={styles.title}>All Students</h1>
//       <input
//         type="text"
//         placeholder="Search..."
//         value={searchTerm}
//         onChange={handleSearch}
//         className={styles.searchInput}
//       />
//       <table className={styles.table}>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Full Name</th>
//             <th>Email ID</th>
//             <th>Mobile Number</th>
//             <th>State</th>
//             <th>Password</th>
//             <th>Active Status</th>
//             <th>Date</th>
//           </tr>
//         </thead>
//         <tbody>{renderRows}</tbody>
//       </table>

//       {/* Pagination */}
//       <nav>
//         <ul className={styles.pagination}>
//           {pageNumbers.map((number) => (
//             <li key={number} className={styles.pageItem}>
//               <button
//                 onClick={() => paginate(number)}
//                 className={styles.pageLink}
//               >
//                 {number}
//               </button>
//             </li>
//           ))}
//         </ul>
//       </nav>
//     </div>
//   );
// };

// export default Students;

// import React, { useState, useEffect } from "react";
// import styles from "./Students.module.css";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import Cookies from "universal-cookie";
// import Swal from "sweetalert2";

// const Students = () => {
//   const [data, setData] = useState([]); // Data from API
//   const [filteredData, setFilteredData] = useState([]); // Filtered data based on search parameters
//   const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
//   const [currentPage, setCurrentPage] = useState(1); // Current page
//   const [rowsPerPage] = useState(10); // Rows to show per page

//   const [students, setStudents] = useState([]); // Student
//   const [searchTerm, setSearchTerm] = useState(""); // Search term state

//   const [errorMessage, setErrorMessage] = useState(""); // State for error messages

//   const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
//   const [formData, setFormData] = useState({
//     fullName: "",
//     email: "",
//     mobile: "",
//     state: "",
//     password: "",
//     passwordConfirm: "",
//   }); // Form data for new student

//   const cookies = new Cookies();
//   const token = cookies.get("token");

//   // List of Indian states
//   const statesOfIndia = [
//     "Andhra Pradesh",
//     "Arunachal Pradesh",
//     "Assam",
//     "Bihar",
//     "Chhattisgarh",
//     "Goa",
//     "Gujarat",
//     "Haryana",
//     "Himachal Pradesh",
//     "Jharkhand",
//     "Karnataka",
//     "Kerala",
//     "Madhya Pradesh",
//     "Maharashtra",
//     "Manipur",
//     "Meghalaya",
//     "Mizoram",
//     "Nagaland",
//     "Odisha",
//     "Punjab",
//     "Rajasthan",
//     "Sikkim",
//     "Tamil Nadu",
//     "Telangana",
//     "Tripura",
//     "Uttar Pradesh",
//     "Uttarakhand",
//     "West Bengal",
//     "Andaman and Nicobar Islands",
//     "Chandigarh",
//     "Dadra and Nagar Haveli",
//     "Daman and Diu",
//     "Lakshadweep",
//     "Delhi",
//     "Puducherry",
//     "Ladakh",
//     "Jammu and Kashmir",
//   ];

//   // Function to fetch students based on the search term
//   const fetchStudents = async (searchTerm = "") => {
//     try {
//       const response = await axios.get(
//         `http://localhost:5000/register-student/search/${searchTerm}`
//       );
//       setStudents(response.data.students);
//       console.log(response.data);
//     } catch (error) {
//       console.error("Error fetching student data:", error);
//     }
//   };

//     // Fetch data from the API when the component mounts or pagination changes
//     useEffect(() => {
//       fetchStudents(searchTerm);
//     }, [ searchTerm ]);

//   // // Handle input change for the search field
//   // const handleSearch = (e) => {
//   //     setSearchTerm(e.target.value);
//   // };

//   // // Fetch students whenever the search term changes
//   // useEffect(() => {
//   // fetchStudents(searchTerm);
//   // }, [searchTerm]);

//   // Fetch data from the API when the component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/register-students`,
//           {
//             params: { page: currentPage, limit: rowsPerPage },
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         setData(response.data.students); // Set the data in state
//         setFilteredData(response.data.students); // Set the filtered data
//         setTotalPages(response.data.totalPages); // Set the total page
//       } catch (error) {
//         console.error("Error fetching student data:", error);
//       }
//     };

//     fetchData();
//   }, [currentPage, token, rowsPerPage]);

//   // Handle form input change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Clear previous error message
//     setErrorMessage("");

//     // Check if passwords match
//     if (formData.password !== formData.passwordConfirm) {
//       // Show SweetAlert for password mismatch
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Passwords do not match!",
//       });
//       return; // Stop form submission
//     }

//     // Prepare the new student object
//     const newStudent = {
//       full_name: formData.fullName,
//       email_id: formData.email,
//       mobile_number: formData.mobile,
//       residence_state: formData.state,
//       password: formData.password,
//       passwordConfirm: formData.passwordConfirm,
//       status: "Active", // Assuming default status
//       ts: new Date().toISOString(), // Assuming current timestamp
//     };

//     console.log(newStudent);

//     try {
//       // Send the new student data to your backend
//       const response = await axios.post(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/register-student`,
//         newStudent,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       // If the submission is successful, update the student list and close the modal
//       if (response.status === 201) {
//         console.log("Student added successfully:", response.data);

//         // Show success SweetAlert
//         Swal.fire({
//           icon: "success",
//           title: "Success",
//           text: "Student added successfully!",
//         });

//         // Optionally, you can re-fetch the students after successful submission
//         fetchStudents();

//         // Close the modal after submitting the form
//         setIsModalOpen(false);

//         // Clear the form data
//         setFormData({
//           fullName: "",
//           email: "",
//           mobile: "",
//           state: "",
//           password: "",
//           passwordConfirm: "",
//         });
//       }
//     } catch (error) {
//       console.error("Error adding new student:", error);
//     }
//   };

//   // Handle modal opening
//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   // Handle modal closing
//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   // Handle search input
//   const handleSearch = (event) => {
//     const searchValue = event.target.value.toLowerCase();
//     setSearchTerm(searchValue);

//     // // Filter the data based on search term (by full name, email, or mobile number)
//     // const filtered = data.filter(
//     //   (student) =>
//     //     student.full_name.toLowerCase().includes(searchValue) ||
//     //     student.email_id.toLowerCase().includes(searchValue) ||
//     //     student.mobile_number.includes(searchValue)
//     // );

//     // setFilteredData(filtered); // Set the filtered data
//     fetchStudents(searchTerm);
//   };

//   // Handle pagination
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   // Generate pagination buttons
//   const pageNumbers = [];
//   const displayPages = 5; // Number of pages to show around the current page
//   const lastPage = totalPages;

//   const getPageNumbers = () => {
//     const pages = [];

//     // Always show "Previous" button, but disable it when on the first page
//     pages.push("Previous");

//     // First page is always clickable, but current page is highlighted
//     pages.push(1);

//     // Ellipsis if currentPage is far from the first page
//     if (currentPage > displayPages) {
//       pages.push("...");
//     }

//     // Pages around the current page
//     const startPage = Math.max(2, currentPage - 2);
//     const endPage = Math.min(totalPages - 1, currentPage + 2);

//     for (let i = startPage; i <= endPage; i++) {
//       pages.push(i);
//     }

//     // Ellipsis if currentPage is far from the last page
//     if (currentPage < totalPages - displayPages) {
//       pages.push("...");
//     }

//     // Last page
//     if (totalPages > 1) {
//       pages.push(totalPages);
//     }

//     // Always show "Next" button, but disable it when on the last page
//     pages.push("Next");

//     return pages;
//   };

//   const handlePageClick = (page) => {
//     if (page === "Previous") {
//       paginate(currentPage - 1);
//     } else if (page === "Next") {
//       paginate(currentPage + 1);
//     } else if (page !== "...") {
//       paginate(page);
//     }
//   };

//   const pageNumbersToDisplay = getPageNumbers();

//   // Render the table rows based on filtered data
//   const renderRows = filteredData.map((row) => (
//     <tr key={row.email_id} className={styles.tableRow}>
//       <td>{row.id}</td>
//       <td>
//         <Link
//           to={`/admin/users/studentdetails/${row.email_id}`}
//           className={styles.linktext}
//         >
//           {row.full_name}
//         </Link>
//       </td>
//       <td>{row.email_id}</td>
//       <td>{row.mobile_number}</td>
//       <td>{row.residence_state}</td>
//       {/* <td>{row.password}</td> */}
//       <td>{row.status}</td>
//       {/* <td>{row.ts}</td> */}
//       <td>{row.ts ? new Date(row.ts).toISOString().split("T")[0] : "N/A"}</td>
//     </tr>
//   ));

//   return (
//     <div className={styles.container}>
//       <h1 className={styles.title}>All Students</h1>
//       <div className={styles.searchContainer}>
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchTerm}
//           onChange={handleSearch}
//           className={styles.searchInput}
//         />
//         <button onClick={openModal} className={styles.addbutton}>
//           + Add Student
//         </button>
//       </div>
//       <table className={styles.table}>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Full Name</th>
//             <th>Email ID</th>
//             <th>Mobile Number</th>
//             <th>State</th>
//             {/* <th>Password</th> */}
//             <th>Active Status</th>
//             <th>Date</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>{renderRows}</tbody>
//       </table>

//       {/* Pagination */}
//       {/* <nav>
//           <ul className={styles.pagination}>
//               {pageNumbersToDisplay.map((number, index) => (
//                 <li key={index} className={styles.pageItem}>
//                   <button
//                     onClick={() => handlePageClick(number)}
//                     className={`${styles.pageLink} ${
//                       number === currentPage ? styles.active : ""
//                     }`}
//                     disabled={number === currentPage || number === "..."}
//                   >
//                     {number}
//                   </button>
//                 </li>
//               ))}
//             </ul>
//           </nav> */}
//       <nav>
//         <ul className={styles.pagination}>
//           {pageNumbersToDisplay.map((number, index) => (
//             <li key={index} className={styles.pageItem}>
//               <button
//                 onClick={() => handlePageClick(number)}
//                 className={`${styles.pageLink} ${
//                   number === currentPage ? styles.active : ""
//                 }`}
//                 disabled={
//                   (number === "Previous" && currentPage === 1) ||
//                   (number === "Next" && currentPage === totalPages) ||
//                   number === currentPage ||
//                   number === "..."
//                 }
//               >
//                 {number}
//               </button>
//             </li>
//           ))}
//         </ul>
//       </nav>
//       {/* Modal Overlay */}
//       {isModalOpen && (
//         <div className={styles.modalOverlay}>
//           <div className={styles.modalContent}>
//             <button onClick={closeModal} className={styles.closeButton}>
//               ×
//             </button>
//             <h2>Add Student</h2>
//             <form onSubmit={handleSubmit}>
//               <div className={styles.formGroup}>
//                 <label>Full Name</label>
//                 <input
//                   type="text"
//                   name="fullName"
//                   value={formData.fullName}
//                   onChange={handleInputChange}
//                   placeholder="Enter your full name"
//                   required
//                 />
//               </div>

//               <div className={styles.formGroup}>
//                 <label>Email</label>
//                 <input
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleInputChange}
//                   placeholder="Enter your email"
//                   required
//                 />
//               </div>

//               <div className={styles.formGroup}>
//                 <label>Mobile</label>
//                 <input
//                   type="text"
//                   name="mobile"
//                   value={formData.mobile}
//                   onChange={handleInputChange}
//                   placeholder="Enter your mobile number"
//                   required
//                 />
//               </div>
//               <div className={styles.formGroup}>
//                 <label>State</label>
//                 <select
//                   name="state"
//                   value={formData.state}
//                   onChange={handleInputChange}
//                   className={styles.slectfield}
//                   required
//                 >
//                   <option value="">--Select State--</option>
//                   {statesOfIndia.map((state) => (
//                     <option key={state} value={state}>
//                       {state}
//                     </option>
//                   ))}
//                 </select>
//               </div>

//               <div className={styles.formGroup}>
//                 <label>Password</label>
//                 <input
//                   type="password"
//                   name="password"
//                   value={formData.password}
//                   onChange={handleInputChange}
//                   placeholder="Enter your password"
//                   required
//                 />
//               </div>
//               <div className={styles.formGroup}>
//                 <label>Confirm Password</label>
//                 <input
//                   type="password"
//                   name="passwordConfirm"
//                   value={formData.passwordConfirm}
//                   onChange={handleInputChange}
//                   placeholder="Enter your confirmation password"
//                   required
//                 />
//               </div>
//               <div className={styles.buttonGroup}>
//                 <button
//                   type="button"
//                   onClick={closeModal}
//                   className={styles.cancelButton}
//                 >
//                   Cancel
//                 </button>
//                 <button type="submit" className={styles.saveButton}>
//                   Save
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Students;



import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "./Students.module.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

// Debounce function
function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}

const Students = () => {
  const [data, setData] = useState([]); // Data from API
  const [filteredData, setFilteredData] = useState([]); // Filtered data based on search parameters
  const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [rowsPerPage] = useState(10); // Rows to show per page
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    state: "",
    password: "",
    passwordConfirm: "",
  }); // Form data for new student

  const cookies = new Cookies();
  const token = cookies.get("token");

  // List of Indian states
  const statesOfIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];

  // Function to fetch students based on pagination (API 1)
  const fetchPaginatedStudents = async (page = 1) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/register-students",
        {
          params: { page, limit: rowsPerPage },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data.students); // Set full paginated data
      setTotalPages(response.data.totalPages); // Set total pages for pagination
    } catch (error) {
      console.error("Error fetching paginated student data:", error);
    }
  };

  // Function to fetch students based on the search term (API 2 with pagination)
  const fetchSearchedStudents = async (searchTerm, page = 1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/register-student/search/${searchTerm}`,
        {
          params: { page, limit: rowsPerPage }, // Pagination for search results
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFilteredData(response.data.students); // Set filtered data (search results)
      setTotalPages(response.data.totalPages); // Set total pages for search results pagination
    } catch (error) {
      console.error("Error fetching searched student data:", error);
    }
  };

  // Debounced search function using useCallback
  const debouncedFetchSearchedStudents = useCallback(
    debounce(fetchSearchedStudents, 800),
    []
  );

  // Handle search input change
  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    setCurrentPage(1); // Reset to page 1 when search is initiated

    if (searchValue.lenth >= 3) {
      debouncedFetchSearchedStudents(searchValue, 1); // Fetch search results
    } else if (searchValue === "") {
      fetchPaginatedStudents(1); // Fetch paginated data if search is cleared
    }
  };

  useEffect(() => {
    if (searchTerm.length >= 3) {
      fetchSearchedStudents(searchTerm, currentPage); // Fetch search results with pagination
    } else {
      fetchPaginatedStudents(currentPage); // Fetch paginated data when no search term
    }
  }, [currentPage, searchTerm]); // Trigger on page and search term changes

  // Reset to the initial page and fetch home page data when the search text is cleared
  useEffect(() => {
    if (searchTerm.length === 0) {
      setCurrentPage(1); // Reset pagination to the first page
      fetchPaginatedStudents(1); // Fetch the default home page data for students (without search)
    }
  }, [searchTerm]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous error message
    setErrorMessage("");

    // Check if passwords match
    if (formData.password !== formData.passwordConfirm) {
      // Show SweetAlert for password mismatch
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Passwords do not match!",
      });
      return; // Stop form submission
    }

    // Prepare the new student object
    const newStudent = {
      full_name: formData.fullName,
      email_id: formData.email,
      mobile_number: formData.mobile,
      residence_state: formData.state,
      password: formData.password,
      passwordConfirm: formData.passwordConfirm,
      status: "Active", // Assuming default status
      ts: new Date().toISOString(), // Assuming current timestamp
    };

    try {
      // Send the new student data to your backend
      const response = await axios.post(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/register-student`,
        newStudent,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // If the submission is successful, update the student list and close the modal
      if (response.status === 201) {
        console.log("Student added successfully:", response.data);

        // Show success SweetAlert
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Student added successfully!",
        });

        // Close the modal after submitting the form
        setIsModalOpen(false);

        // Clear the form data
        setFormData({
          fullName: "",
          email: "",
          mobile: "",
          state: "",
          password: "",
          passwordConfirm: "",
        });
      }
    } catch (error) {
      console.error("Error adding new student:", error);
    }
  };

  // Handle modal opening
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Handle modal closing
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (studentId, studentEmail, studentMobile) => {
    try {
      // Confirmation dialog before delete
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (confirmed.isConfirmed) {
        // Send DELETE request to the backend
        await axios.delete(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/delete-student`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              id: studentId,
              email_id: studentEmail,
              mobile_number: studentMobile,
            },
          }
        );

        // Show success message
        Swal.fire("Deleted!", "Student has been deleted.", "success");

        // Remove the deleted student from the UI by filtering out the student from data
        setData((prevData) =>
          prevData.filter((student) => student.id !== studentId)
        );
      }
    } catch (error) {
      console.error("Error deleting student:", error);
      // Show error message in case of failure
      Swal.fire("Error!", "There was an error deleting the student.", "error");
    }
  };

  // Handle pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Generate pagination buttons
  const pageNumbers = [];
  const displayPages = 5; // Number of pages to show around the current page
  const lastPage = totalPages;

  const getPageNumbers = () => {
    const pages = [];

    // Always show "Previous" button, but disable it when on the first page
    pages.push("Previous");

    // First page is always clickable, but current page is highlighted
    pages.push(1);

    // Ellipsis if currentPage is far from the first page
    if (currentPage > displayPages) {
      pages.push("...");
    }

    // Pages around the current page
    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Ellipsis if currentPage is far from the last page
    if (currentPage < totalPages - displayPages) {
      pages.push("...");
    }

    // Last page
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    // Always show "Next" button, but disable it when on the last page
    pages.push("Next");

    return pages;
  };

  const handlePageClick = (page) => {
    if (page === "Previous") {
      paginate(currentPage - 1);
    } else if (page === "Next") {
      paginate(currentPage + 1);
    } else if (page !== "...") {
      paginate(page);
    }
  };

  const pageNumbersToDisplay = getPageNumbers();

  // Render the table rows based on filtered data
  const renderRows = (searchTerm.length >= 3 ? filteredData : data).map(
    (row) => (
      <tr key={row.email_id} className={styles.tableRow}>
        <td>{row.id}</td>
        <td>
          <Link
            to={`/admin/users/studentdetails/${row.email_id}`}
            className={styles.linktext}
          >
            {row.full_name}
          </Link>
        </td>
        <td>{row.email_id}</td>
        <td>{row.mobile_number}</td>
        <td>{row.residence_state}</td>
        {/* <td>{row.ts ? new Date(row.ts).toISOString().split("T")[0] : "N/A"}</td> */}
        <td>
  {row.ts
    ? `${new Date(row.ts).toISOString().split("T")[0]}  ${new Date(row.ts).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`
    : "N/A"}
</td>
        <td>{row.status}</td>
        <td>
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                color: "red",
                cursor: "pointer",
                marginLeft: "15px",
                fontSize: "16px",
              }}
              onClick={() =>
                handleDelete(row.id, row.email_id, row.mobile_number)
              }
            />
        </td>
      </tr>
    )
  );

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>All Students</h1>
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className={styles.searchInput}
        />
        <button onClick={openModal} className={styles.addbutton}>
          + Add Student
        </button>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Full Name</th>
            <th>Email ID</th>
            <th>Mobile Number</th>
            <th>State</th>
            <th>Date & Time</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </table>

      {/* Pagination */}
      <nav>
        <ul className={styles.pagination}>
          {pageNumbersToDisplay.map((number, index) => (
            <li key={index} className={styles.pageItem}>
              <button
                onClick={() => handlePageClick(number)}
                className={`${styles.pageLink} ${
                  number === currentPage ? styles.active : ""
                }`}
                disabled={
                  (number === "Previous" && currentPage === 1) ||
                  (number === "Next" && currentPage === totalPages) ||
                  number === currentPage ||
                  number === "..."
                }
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      {/* Modal Overlay */}
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button onClick={closeModal} className={styles.closeButton}>
              ×
            </button>
            <h2>Add Student</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label>Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  placeholder="Enter your full name"
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div className={styles.formGroup}>
                <label>Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  placeholder="Enter your mobile number"
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>State</label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  className={styles.slectfield}
                  required
                >
                  <option value="">--Select State--</option>
                  {statesOfIndia.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className={styles.formGroup}>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  placeholder="Enter your password"
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="passwordConfirm"
                  value={formData.passwordConfirm}
                  onChange={handleInputChange}
                  placeholder="Enter your confirmation password"
                  required
                />
              </div>
              <div className={styles.buttonGroup}>
                <button
                  type="button"
                  onClick={closeModal}
                  className={styles.cancelButton}
                >
                  Cancel
                </button>
                <button type="submit" className={styles.saveButton}>
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Students;
