// import React, { useState, useEffect } from "react";
// import Layout from "./Layout";
// import classes from "./TermCondition.module.css";

// const TermCondition = () => {
//   const [termsContent, setTermsContent] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState("");
//   useEffect(() => {
//     const fetchTermsContent = async () => {
//       try {
//         // Ensure the base URL environment variable is defined
//         const baseUrl = process.env.REACT_APP_PINNACLE_BASE_URL;
//         if (!baseUrl) {
//           throw new Error("Base URL is not defined");
//         }

//         const response = await fetch(`${baseUrl}/api/v1/terms-and-conditions`);

//         if (response.ok) {
//           const data = await response.json();
//           console.log("TERMS_AND_CONDITIONS_DATA:", data);
//           setTermsContent(data.data.terms_conditions || "");
//         } else {
//           setError("Failed to load terms and conditions.");
//         }
//       } catch (error) {
//         console.error("Error fetching terms and conditions:", error);
//         setError("An error occurred while fetching terms and conditions.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTermsContent();
//   }, []);

//   return (
//     <Layout>
//       {" "}
//       <div className={classes.term_condition_main_div}>
//         {loading && <p>Loading terms and conditions...</p>}
//         {error && <p className={classes.error}>{error}</p>}
//         {" "}
//         {!loading && !error && (
//           <div
//             className={classes.termsContent}
//             dangerouslySetInnerHTML={{ __html: termsContent }}
//           />
//         )}
//         {" "}
//         {/* 
//                Uncomment the following section if you want to display static content instead.
//                <div style={{ marginTop: "2rem" }} className={classes.term_condition_main_div}>
//                     <!-- Static Terms and Conditions Content -->
//                 </div>
//                 */}
//       </div>
//     </Layout>
//   );
// };

// export default TermCondition;





// import React, { useState, useEffect } from "react";
// import Layout from "./Layout";
// import classes from "./TermCondition.module.css";
// import axios from "axios";

// const TermCondition = () => {
//     const [termsContent, setTermsContent] = useState("");
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState("");

//     useEffect(() => {
//         const fetchTermsContent = async () => {
//             try {
//                 // Ensure the base URL environment variable is defined
//                 const baseUrl = "http://localhost:5000";
//                 if (!baseUrl) {
//                     throw new Error("Base URL is not defined");
//                 }

//                 // const response = await axios.get(
//                 //     "http://localhost:5000/errorRecord",

//                 // const response = await fetch(`${baseUrl}/api/v1/terms-and-conditions`);
//                 // http://localhost:5000/api/v1/terms-and-conditions

//                 const response = await axios.get("http://localhost:5000/api/v1/terms-and-conditions");

//                 if (response.ok) {
//                     const data = await response.json();
//                     console.log("FOOTER DETAILS", data);
//                     console.log("TERMS_AND_CONDITIONS_DATA:", data);
//                     setTermsContent(data.data.terms_conditions || "");

//                     // const data = await response.json();
//                     // console.log("FOOTER DETAIL",data);
//                     // setTermsContent(data.data.terms_conditions);
//                     // console.log("TERMS_AND_CONDITIONS_DATA:", data);
//                     // setTermsContent(data.data.terms_conditions || "");
//                 } else {
//                     setError("Failed to load terms and conditions.");
//                 }
//             } catch (error) {
//                 console.error("Error fetching terms and conditions:", error);
//                 setError("An error occurred while fetching terms and conditions.");
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchTermsContent();
//     }, []);

//     return (
//         <Layout>
//             <div className={classes.term_condition_main_div}>
//                 {loading && <p>Loading terms and conditions...</p>}
//                 {error && <p className={classes.error}>{error}</p>}
//                 {!loading && !error && (
//                     <div
//                         className={classes.termsContent}
//                         dangerouslySetInnerHTML={{ __html: termsContent }}
//                     />
//                 )}
//                 {/*
//                 Uncomment the following section if you want to display static content instead.
//                 <div style={{ marginTop: "2rem" }} className={classes.term_condition_main_div}>
//                     <!-- Static Terms and Conditions Content -->
//                 </div>
//                 */}
//             </div>
//         </Layout>
//     );
// };

// export default TermCondition;


import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import classes from "./TermCondition.module.css";
import axios from "axios";

const TermCondition = () => {
    const [termsContent, setTermsContent] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchTermsContent = async () => {
            try {
                // Ensure the base URL environment variable is defined
                // const baseUrl = "http://localhost:5000"; // Corrected base URL
                // if (!baseUrl) {
                //     throw new Error("Base URL is not defined");
                // }

                // Fetch data using axios
                const response = await axios.get(`${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/terms-and-conditions`);

                if (response.data) {
                    console.log("TERMS_AND_CONDITIONS_DATA:", response.data);
                    setTermsContent(response.data.data.terms_conditions || "");
                } else {
                    setError("Failed to load terms and conditions.");
                }
            } catch (error) {
                console.error("Error fetching terms and conditions:", error);
                setError("An error occurred while fetching terms and conditions.");
            } finally {
                setLoading(false);
            }
        };

        fetchTermsContent();
    }, []);

    return (
        <Layout>
            <div className={classes.term_condition_main_div}>
                {loading && <p>Loading terms and conditions...</p>}
                {error && <p className={classes.error}>{error}</p>}
                {!loading && !error && (
                    <div className={classes.termsContent} dangerouslySetInnerHTML={{ __html: termsContent }}/>
                )}
            </div>
        </Layout>
    );
};

export default TermCondition;