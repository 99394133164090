// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import EditQuestion from './EditQuestion';
// import updateQuestion from './updateQuestion';
// import './ViewQuestions.css';
// import parse from 'html-react-parser';

// const ViewQuestions = () => {
//   const { papercode } = useParams();
//   const [paper, setPaper] = useState(null);
//   const [editingIndex, setEditingIndex] = useState(-1);
//   const [selectedLanguage, setSelectedLanguage] = useState('English');

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_PINNACLE_BASE_URL}/papercodes/${papercode}`);
//       setPaper(response.data);
//       console.log("VIEW QUESTION", response.data)
//     } catch (error) {
//       console.error('Error fetching paper data:', error);
//     }
//   };

//   const handleQuestionEdit = async (index, editedQuestion) => {
//     const success = await updateQuestion(paper.paperCode, index, editedQuestion);

//     if (success) {
//     const updatedPaper = { ...paper };
//     updatedPaper.englishquestions[index].question = editedQuestion;
//     // You can similarly update other arrays for options, answers, etc.
//     setPaper(updatedPaper);
//     setEditingIndex(-1);
//     }
//   };

//   // Language selection dropdown
//   const handleLanguageChange = (event) => {
//     setSelectedLanguage(event.target.value);
//   };

//   if (!paper) {
//     return <div>Loading...</div>;
//   }

// const anish = "<p>anish <img src='https://d2jnyx7x72uicf.cloudfront.net/image_1706760747610.png' alt = 'ak'/></p>";

//   return (

//     <div>
//       <div className='question-heading-select'>
//       <h1>View Questions for Paper Code: {paper.paperCode}</h1>

//       <select value={selectedLanguage} onChange={handleLanguageChange}>
//         <option value="English">English</option>
//         <option value="Hindi">Hindi</option>
//       </select>
//       </div>

//       {paper.englishquestions.map((question, index) => (
//         <div key={index}>
//           <h2>Question {index + 1}:</h2>
//          {/* {console.log("questions representation starts here")}
//            {console.log(question.question)} */}
//           {/* {console.log(paper.hindiquestions[index].question)} */}
//           <p>{selectedLanguage === 'English' ? (paper.englishquestions[index].option ? parse( paper.englishquestions[index].option) : null  )
//           : (paper.hindiquestions[index].option ? parse(paper.hindiquestions[index].option) : null )}</p>
//           <p>{selectedLanguage === 'English' ?
//             <div> {question.image[0] ?  <div>
//               {question.image.map((imgItem,index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             : null}
//           </div>
//           :
//           <div>
//             {paper.hindiquestions[index].image[0] ?
//             <div>
//               {paper.hindiquestions[index].image.map((imgItem, index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             :null}
//           </div> }</p>

//           <h3>Options:</h3>
//           <ul>
//             <li>A) {selectedLanguage === 'English' ?
//             (paper.englishoptionA[index].option ? parse(paper.englishoptionA[index].option) : null  )
//             : (paper.HindioptionA[index].option ? parse(paper.HindioptionA[index].option) : null )
//            }
//            <p>{selectedLanguage === 'English' ?
//           <div>
//             {paper.englishoptionA[index].image[0] ?
//             <div>
//               {paper.englishoptionA[index].image.map((imgItem,index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             : null}
//           </div>
//           :
//           <div>
//             {paper.HindioptionA[index].image[0] ?
//             <div>
//               {paper.HindioptionA[index].image.map((imgItem, index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             :null}
//           </div> }</p>

//             </li>
//             <li>B) {selectedLanguage === 'English' ?
//              (paper.englishoptionB[index].option ? parse(paper.englishoptionB[index].option) : null )
//              :(paper.HindioptionB[index].option ? parse(paper.HindioptionB[index].option) : null)
//               }
//               <p>{selectedLanguage === 'English' ?
//           <div>
//             {paper.englishoptionB[index].image[0] ?
//             <div>
//               {paper.englishoptionB[index].image.map((imgItem,index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             : null}
//           </div>
//           :
//           <div>
//             {paper.HindioptionB[index].image[0] ?
//             <div>
//               {paper.HindioptionB[index].image.map((imgItem, index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             :null}
//           </div> }</p>

//               </li>
//             <li>C) {selectedLanguage === 'English' ?
//             (paper.englishoptionC[index].option ? parse(paper.englishoptionC[index].option) : null )
//             :
//             (paper.HindioptionC[index].option ? parse(paper.HindioptionC[index].option) : null )
//             }

//             <p>{selectedLanguage === 'English' ?
//           <div>
//             {paper.englishoptionC[index].image[0] ?
//             <div>
//               {paper.englishoptionC[index].image.map((imgItem,index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             : null}
//           </div>
//           :
//           <div>
//             {paper.HindioptionC[index].image[0] ?
//             <div>
//               {paper.HindioptionC[index].image.map((imgItem, index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             :null}
//           </div> }</p>

//             </li>
//             <li>D) {selectedLanguage === 'English' ?
//            (paper.englishoptionD[index].option ? parse(paper.englishoptionD[index].option) : null )
//             :
//              (paper.HindioptionD[index].option ? parse(paper.HindioptionD[index].option) : null )
//              }
//              <p>{selectedLanguage === 'English' ?
//           <div>
//             {paper.englishoptionD[index].image[0] ?
//             <div>
//               {paper.englishoptionD[index].image.map((imgItem,index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             : null}
//           </div>
//           :
//           <div>
//             {paper.HindioptionD[index].image[0] ?
//             <div>
//               {paper.HindioptionD[index].image.map((imgItem, index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             :null}
//           </div> }</p>

//              </li>
//           </ul>

//           <p>Answer: {paper.answers[index].option}</p>
//           <p>Solution: {selectedLanguage === 'English'
//           ?
//           (paper.solutions[index].option ? parse(paper.solutions[index].option) : null )
//           :
//           (paper.hindiSolutions[index].option ? parse(paper.hindiSolutions[index].option): null)
//           }
//           <p>{selectedLanguage === 'English' ?
//           <div>
//             {paper.solutions[index].image[0] ?
//             <div>
//               {paper.solutions[index].image.map((imgItem,index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             : null}
//           </div>
//           :
//           <div>
//             {paper.hindiSolutions[index].image[0] ?
//             <div>
//               {paper.hindiSolutions[index].image.map((imgItem, index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             :null}
//           </div> }</p>

//           </p>

//           {editingIndex === index ? (
//           // <EditQuestion
//           //   questionData={{
//           //     question: question.question,
//           //     optionA: paper.englishoptionA[index].option,
//           //     optionB: paper.englishoptionB[index].option,
//           //     optionC: paper.englishoptionC[index].option,
//           //     optionD: paper.englishoptionD[index].option,
//           //     hindiquestions: paper.hindiquestions[index].question,
//           //     hindioptionA: paper.HindioptionA[index].option,
//           //     hindioptionB: paper.HindioptionB[index].option,
//           //     hindioptionC: paper.HindioptionC[index].option,
//           //     hindioptionD: paper.HindioptionD[index].option,
//           //     sections: paper.sections[index],
//           //     solutions: paper.solutions[index].option,
//           //     hindiSolutions: paper.hindiSolutions[index].option,
//           //     // Pass other options, answers, and solution here
//           //     answer: paper.answers[index].option,
//           //   }}
//           //   selectedLanguage={selectedLanguage}
//           //   onSave={(editedQuestion) => handleQuestionEdit(index, editedQuestion)}
//           // />
//           <EditQuestion
//             questionData={{
//               question: paper.englishquestions[index],
//               optionA: paper.englishoptionA[index],
//               optionB: paper.englishoptionB[index],
//               optionC: paper.englishoptionC[index],
//               optionD: paper.englishoptionD[index],
//               hindiquestions: paper.hindiquestions[index],
//               hindioptionA: paper.HindioptionA[index],
//               hindioptionB: paper.HindioptionB[index],
//               hindioptionC: paper.HindioptionC[index],
//               hindioptionD: paper.HindioptionD[index],
//               sections: paper.sections[index],
//               solutions: paper.solutions[index],
//               hindiSolutions: paper.hindiSolutions[index],
//               // Pass other options, answers, and solution here
//               answer: paper.answers[index],
//             }}
//             selectedLanguage={selectedLanguage}
//             // onSave={(editedQuestion) => console.log("EDITED QUESTION",editedQuestion)}
//             onSave={(editedQuestion) => handleQuestionEdit(index, editedQuestion)}
//           />
//           ) : (
//             <button onClick={() => setEditingIndex(index)}>Edit Question</button>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ViewQuestions;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// // import EditQuestion from './EditQuestion';
// import EditQuestion from "./EditQuestion";
// import updateQuestion from "./updateQuestion";
// import classes from "./ViewQuestions.module.css";
// import parse from "html-react-parser";
// import UpdateSubjectOverlay from "./UpdateOverlay";

// const ViewQuestions = () => {
//   const { papercode, exam_mode_id } = useParams();
//   const [paper, setPaper] = useState(null);
//   const [editingIndex, setEditingIndex] = useState(-1);
//   const [selectedLanguage, setSelectedLanguage] = useState("English");
//   const [updateModel, setUpdateModel] = useState(false);

//   useEffect(() => {
//     fetchData();
//   }, [editingIndex]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/papercodes/${papercode}/${exam_mode_id}`
//       );

//       setPaper(response.data);
//       console.log("VIEW QUESTION", response.data);
//     } catch (error) {
//       console.error("Error fetching paper data:", error);
//     }
//   };

//   const handleQuestionEdit = async (index, editedQuestion, _idData) => {
//     const success = await updateQuestion(
//       papercode,
//       index,
//       editedQuestion,
//       _idData,
//       exam_mode_id
//     );
//     console.log("handleQuestionEdit", editedQuestion);

//     if (success) {
//       // const updatedPaper = { ...paper };
//       // updatedPaper[index].eng_question = editedQuestion.question;
//       // updatedPaper[index].correct_answer = editedQuestion.answer;
//       // updatedPaper[index].hindi_solution = editedQuestion.hindiSolutions;
//       // updatedPaper[index].hindi_question = editedQuestion.hindiquestions;
//       // updatedPaper[index].solution = editedQuestion.solutions;

//       // updatedPaper[index].option1 = editedQuestion.optionA;
//       // updatedPaper[index].option2 = editedQuestion.optionB;
//       // updatedPaper[index].option3 = editedQuestion.optionC;
//       // updatedPaper[index].option4 = editedQuestion.optionD;

//       // updatedPaper[index].option1_hindi = editedQuestion.hindioptionA;
//       // updatedPaper[index].option2_hindi = editedQuestion.hindioptionB;
//       // updatedPaper[index].option3_hindi = editedQuestion.hindioptionC;
//       // updatedPaper[index].option4_hindi = editedQuestion.hindioptionD;
//       // // updatedPaper.sections[index] = editedQuestion.sections;

//       // setPaper(updatedPaper);
//       setEditingIndex(-1);
//     }
//   };

//   // Language selection dropdown
//   const handleLanguageChange = (event) => {
//     setSelectedLanguage(event.target.value);
//   };

//   if (!paper) {
//     return <div>Loading...</div>;
//   }

//   const handleSubjectChange = () => {
//     setUpdateModel(!updateModel);
//   };

//   const anish =
//     "<p>anish <img src='https://d2jnyx7x72uicf.cloudfront.net/image_1706760747610.png' alt = 'ak'/></p>";
//   return (
//     <>
//       {updateModel && (
//         <UpdateSubjectOverlay
//           exam_mode_id={exam_mode_id}
//           paper_code={papercode}
//           handleSubjectChange={handleSubjectChange}
//         />
//       )}

//       <div>
//         <div className={classes.question_heading_select}>
//           <h1>View Questions for Paper Code: {paper.paperCode}</h1>
//           <button onClick={handleSubjectChange}>Edit subject</button>

//           <select value={selectedLanguage} onChange={handleLanguageChange}>
//             <option value="English">English</option>
//             <option value="Hindi">Hindi</option>
//           </select>
//         </div>

//         {paper?.map((question, index) => (
//           <div key={index}>
//             <h2>Question {index + 1}:</h2>
//             {/* {console.log("questions representation starts here")}
//            {console.log(question.question)} */}
//             {/* {console.log(paper.hindiquestions[index].question)} */}
//             <div>
//               {selectedLanguage === "English"
//                 ? paper[index].eng_question
//                   ? parse(paper[index].eng_question)
//                   : null
//                 : paper[index].hindi_question
//                 ? parse(paper[index].hindi_question)
//                 : null}
//             </div>
//             {/* <div>{selectedLanguage === 'English' ?
//             <div> {question.image[0] ?  <div>
//               {question.image.map((imgItem,index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             : null}
//           </div>
//           :
//           <div>
//             {paper.hindiquestions[index].image[0] ?
//             <div>
//               {paper.hindiquestions[index].image.map((imgItem, index)=>(
//                 <img src={imgItem.imageUrl} key={index} />
//               ))}
//             </div>
//             :null}
//           </div> }</div> */}

//             <h3>Options:</h3>
//             <ul>
//               <li>
//                 A){" "}
//                 {selectedLanguage === "English"
//                   ? paper[index].option1
//                     ? parse(paper[index].option1)
//                     : null
//                   : paper[index].option1_hindi
//                   ? parse(paper[index].option1_hindi)
//                   : null}
//               </li>
//               <li>
//                 B){" "}
//                 {selectedLanguage === "English"
//                   ? paper[index].option2
//                     ? parse(paper[index].option2)
//                     : null
//                   : paper[index].option2_hindi
//                   ? parse(paper[index].option2_hindi)
//                   : null}
//               </li>
//               <li>
//                 C){" "}
//                 {selectedLanguage === "English"
//                   ? paper[index].option3
//                     ? parse(paper[index].option3)
//                     : null
//                   : paper[index].option3_hindi
//                   ? parse(paper[index].option3_hindi)
//                   : null}
//               </li>
//               <li>
//                 D){" "}
//                 {selectedLanguage === "English"
//                   ? paper[index].option4
//                     ? parse(paper[index].option4)
//                     : null
//                   : paper[index].option4_hindi
//                   ? parse(paper[index].option4_hindi)
//                   : null}
//               </li>
//             </ul>

//             <p>
//               Answer:{" "}
//               {paper[index].correct_answer
//                 ? parse(paper[index].correct_answer)
//                 : null}
//             </p>
//             <div>
//               Solution:{" "}
//               {selectedLanguage === "English"
//                 ? paper[index].solution
//                   ? parse(paper[index].solution)
//                   : null
//                 : paper[index].hindi_solution
//                 ? parse(paper[index].hindi_solution)
//                 : null}
//             </div>

//             {editingIndex === index ? (
//               // <EditQuestion
//               //   questionData={{
//               //     question: question.question,
//               //     optionA: paper.englishoptionA[index].option,
//               //     optionB: paper.englishoptionB[index].option,
//               //     optionC: paper.englishoptionC[index].option,
//               //     optionD: paper.englishoptionD[index].option,
//               //     hindiquestions: paper.hindiquestions[index].question,
//               //     hindioptionA: paper.HindioptionA[index].option,
//               //     hindioptionB: paper.HindioptionB[index].option,
//               //     hindioptionC: paper.HindioptionC[index].option,
//               //     hindioptionD: paper.HindioptionD[index].option,
//               //     sections: paper.sections[index],
//               //     solutions: paper.solutions[index].option,
//               //     hindiSolutions: paper.hindiSolutions[index].option,
//               //     // Pass other options, answers, and solution here
//               //     answer: paper.answers[index].option,
//               //   }}
//               //   selectedLanguage={selectedLanguage}
//               //   onSave={(editedQuestion) => handleQuestionEdit(index, editedQuestion)}
//               // />
//               <EditQuestion
//                 questionData={{
//                   question: paper[index].eng_question,
//                   optionA: paper[index].option1,
//                   optionB: paper[index].option2,
//                   optionC: paper[index].option3,
//                   optionD: paper[index].option4,
//                   hindiquestions: paper[index].hindi_question,
//                   hindioptionA: paper[index].option1_hindi,
//                   hindioptionB: paper[index].option2_hindi,
//                   hindioptionC: paper[index].option3_hindi,
//                   hindioptionD: paper[index].option4_hindi,
//                   // sections: paper.sections[index],
//                   solutions: paper[index].solution,
//                   hindiSolutions: paper[index].hindi_solution,
//                   // Pass other options, answers, and solution here
//                   answer: paper[index].correct_answer,
//                 }}
//                 _idData={{ _id: paper[index]._id }}
//                 selectedLanguage={selectedLanguage}
//                 // onSave={(editedQuestion) => console.log("EDITED QUESTION",editedQuestion)}
//                 onSave={(editedQuestion, _idData) =>
//                   handleQuestionEdit(index, editedQuestion, _idData)
//                 }
//               />
//             ) : (
//               <div>
//                 <button
//                   onClick={() => setEditingIndex(index)}
//                   className={classes.editbtn}
//                 >
//                   Edit Question
//                 </button>
//                 <button className={classes.deletebtn}>Delete Question</button>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </>
//   );
// };

// export default ViewQuestions;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import EditQuestion from "./EditQuestion";
import updateQuestion from "./updateQuestion";
import classes from "./ViewQuestions.module.css";
import parse from "html-react-parser";
import UpdateSubjectOverlay from "./UpdateOverlay";
import UpdateMarksOverlay from "./UpdateMarksOverlay";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
// import MathJax from "react-mathjax-preview";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import MathElement from "../../../component/MathMl";

const ViewQuestions = () => {
  const { papercode, exam_mode_id } = useParams();
  const [paper, setPaper] = useState(null);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [updateModel, setUpdateModel] = useState(false);
  const [marks, setMarks] = useState();
  const [negativeMarks, setNegativeMarks] = useState();
  const [updateMarks, setUpdateMarks] = useState(false);

  const cookies = new Cookies();

  const token = cookies.get("token");

  useEffect(() => {
    fetchData();
  }, [editingIndex]);

  // useEffect(() => {
  //   console.log("MathJax loaded:", MathJax); // This should not be undefined
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/papercodes/${papercode}/${exam_mode_id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token} `,
  //         },
  //       }
  //     );
  //     setPaper(response.data);
  //     console.log("VIEW QUESTION", response.data);
  //   } catch (error) {
  //     console.error("Error fetching paper data:", error);
  //   }
  // };

  // const containsMath = (content) => {
  //   return /\$\$.*\$\$|\\\(.*\\\)|<math[^>]*>.*<\/math>/.test(content);
  // };
  const mathJaxConfig = {
    loader: { load: ["input/tex", "input/mathml", "output/chtml"] },
    showProcessingMessages: true, // Enable processing messages
    messageStyle: "normal", // Style for messages
    options: {
      enableMenu: false, // Disable MathJax context menu
    },
  };
  // const mathRegex = /(\$\$.*?\$\$|\\\(.*?\\\)|<math[^>]*>.*<\/math>)/g;

  // const processContent = (content) => {
  //   // Regular expressions to detect MathML and images
  //   const mathRegex = /(<math[^>]*>.*?<\/math>)/g; // Detect MathML
  //   const imageRegex = /<img[^>]*src=["']([^"']+)["'][^>]*>/g; // Detect image tags

  //   const textParts = [];
  //   const mathParts = [];
  //   const imageParts = [];

  //   // If the content is a string, handle MathML, images, and text
  //   if (typeof content === "string") {
  //     // Split the string by MathML
  //     const textAndMathParts = content.split(mathRegex);

  //     textAndMathParts.forEach((part, index) => {
  //       if (mathRegex.test(part)) {
  //         // Add MathML content to the mathParts array
  //         mathParts.push({
  //           type: "math",
  //           content: part,
  //           key: `math-${index}`,
  //         });
  //       } else {
  //         // Further split by image tags
  //         const textAndImageParts = part.split(imageRegex);

  //         textAndImageParts.forEach((subPart, subIndex) => {
  //           if (imageRegex.test(subPart)) {
  //             // Extract the image source
  //             const imgSrc = subPart.match(/src=["']([^"']+)["']/)[1];
  //             imageParts.push({
  //               type: "image",
  //               content: imgSrc,
  //               key: `image-${subIndex}`,
  //             });
  //           } else {
  //             // Add regular text content to the textParts array
  //             textParts.push({
  //               type: "text",
  //               content: subPart,
  //               key: `text-${subIndex}`,
  //             });
  //           }
  //         });
  //       }
  //     });
  //   }

  //   // Return an array combining all the parts
  //   return [...textParts, ...imageParts, ...mathParts];
  // };

  // const renderContent = (content) => {
  //   const contentParts = processContent(content);

  //   return contentParts.map((part) => {
  //     if (part.type === "text") {
  //       return <span key={part.key}>{parse(part.content)}</span>;
  //     } else if (part.type === "image") {
  //       return <img key={part.key} src={part.content} alt="inline image" />;
  //     } else if (part.type === "math") {
  //       return <MathJax.Node key={part.key}>{part.content}</MathJax.Node>;
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  const fetchData = async () => {
    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/papercodes/${papercode}/${exam_mode_id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // if(response1.data.status !== "Success"){

      // }

      setPaper(response1.data);
      setMarks(response1.data[0].marks);
      setNegativeMarks(response1.data[0].minus_marking);

      // if (response1.data.length > 0) {
      //   setPaper(response1.data);
      // } else {
      //   const response2 = await axios.get(
      //     `http://localhost:5000/papercodescgltier2/${papercode}/${exam_mode_id}`
      //   );
      //   setPaper(response2.data);
      // }

      // console.log("VIEW QUESTION", paper);
      console.log("VIEW QUESTION", response1.data);
      console.log("Marks:", response1.data[0].minus_marking);
    } catch (error) {
      console.error("Error fetching paper data:", error);
    }
  };

  console.log("Marks: ", marks);
  console.log("Negative Marks: ", negativeMarks);

  // console.log("line 596", paper);

  const handleDeleteQuestion = async (_idData, QuestionNo, exam_tier_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // console.log("i got called");
        // console.log(exam_tier_id);
        try {
          const response = await axios.delete(
            `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/papercodes/${papercode}/${exam_mode_id}/delete/${_idData}/${exam_tier_id}`,
            {
              headers: {
                Authorization: `Bearer ${token} `,
              },
            }
          );
          if (response.data.status === "success") {
            Swal.fire({
              icon: "success",
              title: `Paper Code ${papercode} Question no. ${QuestionNo} Successfully deleted!`,
              showConfirmButton: false,
              timer: 1500,
            });
            fetchData();
          } else {
            Swal.fire({
              icon: "error",
              title: `Error: ${response.data.message}`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: `Error: Unable to delete! Please try again later.`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  const handleQuestionEdit = async (
    index,
    editedQuestion,
    _idData,
    exam_tier_id
  ) => {
    const success = await updateQuestion(
      papercode,
      index,
      editedQuestion,
      _idData,
      exam_mode_id,
      exam_tier_id
    );
    console.log("line 652, handleQuestionEdit", editedQuestion);

    if (success) {
      setEditingIndex(-1);
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleSubjectChange = () => {
    setUpdateModel(!updateModel);
  };

  const handleMarksChange = () => {
    setUpdateMarks(!updateMarks);
  };

  // Close overlays
  const closeOverlays = () => {
    setUpdateMarks(false);
  };

  if (!paper) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className={classes.spinner}></div>
        <h1>LOADING ...</h1>
      </div>
    );
  }

  if (paper.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h1>No paper uploaded.</h1>
      </div>
    );
  }

  return (
    <>
      {updateModel && (
        <UpdateSubjectOverlay
          exam_mode_id={exam_mode_id}
          paper_code={papercode}
          handleSubjectChange={handleSubjectChange}
          exam_tier_id={paper[0].exam_tier_id}
          exam_post_id={paper[0].exam_post_id}
        />
      )}
      {updateMarks && (
        <UpdateMarksOverlay
          paper_code={papercode}
          marks={marks}
          negativeMarks={negativeMarks}
          // handleMarksChange={handleMarksChange}
          onClose={closeOverlays}
        />
      )}
      <div>
        <h1>View Questions for Paper Code: {papercode}</h1>
        <div className={classes.question_heading}>
          <h2>Total No. of Questions : {paper?.length}</h2>
          <div className={classes.question_heading_select}>
            <button onClick={handleSubjectChange}>Edit subject</button>
            <button onClick={handleMarksChange}>Edit Marks</button>
            <select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              className={classes.select_wrapper}
            >
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
            </select>
          </div>
        </div>

        {paper.map((question, index) => (
          <div key={index}>
            <h2>Question {paper[index].question_id}:</h2>
            <div className={classes.question_div}>
              {selectedLanguage === "English" ? (
                paper[index].eng_question ? (
                  // ? parse(paper[index].eng_question)
                  // ? <MathJax math={paper[index].eng_question} />
                  <MathElement elements={paper[index].eng_question} />
                ) : null
              ) : paper[index].hindi_question ? (
                // ? parse(paper[index].hindi_question)
                <MathElement elements={paper[index].hindi_question} />
              ) : null}
            </div>

            <h4>Options:</h4>
            <div>
              <div>
                (a)
                {selectedLanguage === "English" ? (
                  paper[index].option1 ? (
                    // ? parse(paper[index].option1)
                    <MathElement elements={paper[index].option1} />
                  ) : null
                ) : paper[index].option1_hindi ? (
                  // ? parse(paper[index].option1_hindi)
                  <MathElement elements={paper[index].option1_hindi} />
                ) : null}
              </div>
              <div>
                (b)
                {selectedLanguage === "English" ? (
                  paper[index].option2 ? (
                    // ? parse(paper[index].option2)
                    <MathElement elements={paper[index].option2} />
                  ) : null
                ) : paper[index].option2_hindi ? (
                  // ? parse(paper[index].option2_hindi)
                  <MathElement elements={paper[index].option2_hindi} />
                ) : null}
              </div>
              <div>
                (c)
                {selectedLanguage === "English" ? (
                  paper[index].option3 ? (
                    // ? parse(paper[index].option3)
                    <MathElement elements={paper[index].option3} />
                  ) : null
                ) : paper[index].option3_hindi ? (
                  // ? parse(paper[index].option3_hindi)
                  <MathElement elements={paper[index].option3_hindi} />
                ) : null}
              </div>
              <div>
                (d)
                {selectedLanguage === "English" ? (
                  paper[index].option4 ? (
                    // ? parse(paper[index].option4)
                    <MathElement elements={paper[index].option4} />
                  ) : null
                ) : paper[index].option4_hindi ? (
                  // ? parse(paper[index].option4_hindi)
                  <MathElement elements={paper[index].option4_hindi} />
                ) : null}
              </div>
            </div>

            <div>
              <h4> Answer: </h4>
              {paper[index].correct_answer ? (
                // ? parse(paper[index].correct_answer)
                <MathElement elements={paper[index].correct_answer} />
              ) : null}
            </div>
            <div className={classes.solution_div}>
              <h4> Solution: </h4>
              {selectedLanguage === "English" ? (
                paper[index].solution ? (
                  // ? parse(paper[index].solution)
                  <MathElement elements={paper[index].solution} />
                ) : null
              ) : paper[index].hindi_solution ? (
                // ? parse(paper[index].hindi_solution)
                <MathElement elements={paper[index].hindi_solution} />
              ) : null}
            </div>

            {editingIndex === index ? (
              <EditQuestion
                questionData={{
                  question: paper[index].eng_question,
                  optionA: paper[index].option1,
                  optionB: paper[index].option2,
                  optionC: paper[index].option3,
                  optionD: paper[index].option4,
                  hindiquestions: paper[index].hindi_question,
                  hindioptionA: paper[index].option1_hindi,
                  hindioptionB: paper[index].option2_hindi,
                  hindioptionC: paper[index].option3_hindi,
                  hindioptionD: paper[index].option4_hindi,
                  solutions: paper[index].solution,
                  hindiSolutions: paper[index].hindi_solution,
                  answer: paper[index].correct_answer,
                }}
                _idData={{ _id: paper[index]._id }}
                exam_tier_id={paper[index].exam_tier_id}
                selectedLanguage={selectedLanguage}
                onSave={(editedQuestion, _idData, exam_tier_id) =>
                  handleQuestionEdit(
                    index,
                    editedQuestion,
                    _idData,
                    exam_tier_id
                  )
                }
              />
            ) : (
              <div>
                <button
                  onClick={() => setEditingIndex(index)}
                  className={classes.editbtn}
                >
                  Edit Question
                </button>
                <button
                  className={classes.deletebtn}
                  onClick={() =>
                    handleDeleteQuestion(
                      paper[index]._id,
                      index + 1,
                      paper[index].exam_tier_id
                    )
                  }
                >
                  Delete Question
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ViewQuestions;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import EditQuestion from './EditQuestion';
// import updateQuestion from './updateQuestion';
// import './ViewQuestions.css';

// const ViewQuestions = () => {
//   const { papercode } = useParams();
//   const [paper, setPaper] = useState(null);
//   const [editingIndex, setEditingIndex] = useState(-1);
//   const [selectedLanguage, setSelectedLanguage] = useState('English');

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_PINNACLE_BASE_URL}/papercodes/${papercode}`);
//       setPaper(response.data);
//       // console.log("VIEW QUESTION", response.data);
//     } catch (error) {
//       console.error('Error fetching paper data:', error);
//     }
//   };

//   // const handleQuestionEdit = async (index, editedQuestion) => {
//   //   const success = await updateQuestion(paper.paperCode, index, editedQuestion);

//   //   if (success) {
//   //     setPaper((prevPaper) => {
//   //       const updatedPaper = { ...prevPaper };
//   //       console.log("EDITED QUESTION: " , editedQuestion)
//   //       console.log("updatedPaper", updatedPaper);

//   //       updatedPaper.englishquestions[index].option = editedQuestion.Question.option;
//   //       updatedPaper.englishoptionA[index].option = editedQuestion.OptionA.option;
//   //       updatedPaper.englishoptionB[index].option = editedQuestion.OptionB.option;
//   //       updatedPaper.englishoptionC[index].option = editedQuestion.OptionC.option;
//   //       updatedPaper.englishoptionD[index].option = editedQuestion.OptionD.option;
//   //       updatedPaper.answers[index].option = editedQuestion.Answer.option;
//   //       updatedPaper.solutions[index].option = editedQuestion.Solution.option;
//   //       updatedPaper.hindiquestions[index].option = editedQuestion.hindiquestions.option;
//   //       updatedPaper.HindioptionA[index].option = editedQuestion.hindioptionA.option;
//   //       updatedPaper.HindioptionB[index].option = editedQuestion.hindioptionB.option;
//   //       updatedPaper.HindioptionC[index].option = editedQuestion.hindioptionC.option;
//   //       updatedPaper.HindioptionD[index].option = editedQuestion.hindioptionD.option;
//   //       updatedPaper.hindiSolutions[index].option = editedQuestion.hindiSolutions.option;
//   //       // console.log("UPDATED QUESTION: " , updatedPaper.englishquestions[index].option)
//   //       // You can similarly update other arrays for options, answers, etc.
//   //       return updatedPaper;
//   //     });
//   //     setEditingIndex(-1);
//   //   }
//   // };

//   const handleQuestionEdit = async (index, editedQuestion) => {
//     const success = await updateQuestion(paper.paperCode, index, editedQuestion);

//     if (success) {
//       setPaper((prevPaper) => {
//         const updatedPaper = { ...prevPaper };
//         // Update the relevant properties using the correct structure
//         updatedPaper.englishquestions[index] = { option: editedQuestion.question };
//         updatedPaper.englishoptionA[index] = { option: editedQuestion.optionA };
//         updatedPaper.englishoptionB[index] = { option: editedQuestion.optionB };
//         updatedPaper.englishoptionC[index] = { option: editedQuestion.optionC };
//         updatedPaper.englishoptionD[index] = { option: editedQuestion.optionD };
//         updatedPaper.answers[index] = { option: editedQuestion.answer };
//         updatedPaper.solutions[index] = { option: editedQuestion.solutions };
//         updatedPaper.hindiquestions[index] = { option: editedQuestion.hindiquestions };
//         updatedPaper.HindioptionA[index] = { option: editedQuestion.hindioptionA };
//         updatedPaper.HindioptionB[index] = { option: editedQuestion.hindioptionB };
//         updatedPaper.HindioptionC[index] = { option: editedQuestion.hindioptionC };
//         updatedPaper.HindioptionD[index] = { option: editedQuestion.hindioptionD };
//         updatedPaper.hindiSolutions[index] = { option: editedQuestion.hindiSolutions };

//         return updatedPaper;
//       });
//       setEditingIndex(-1);
//     }
//   };

//   // Language selection dropdown
//   const handleLanguageChange = (event) => {
//     setSelectedLanguage(event.target.value);
//   };

//   const renderOption = (questionObj, language) => (
//     <li>
//       {language === 'English' ? questionObj.option : questionObj.option}
//       {/*
//       <p>
//         {language === 'English' ? (
//           <div> {questionObj.image[0] && questionObj.image.map((imgItem, index) => <img src={imgItem.imageUrl} key={index} />)}</div>
//         ) : (
//           <div> {questionObj.image[0] && questionObj.image.map((imgItem, index) => <img src={imgItem.imageUrl} key={index} />)}</div>
//         )}
//       </p>
//           */}
//       <p>
//             {language === 'English' ? (
//               <div>
//                 {questionObj.image?.[0] && questionObj.image.map((imgItem, index) => (
//                   <img src={imgItem.imageUrl} key={index} />
//                 ))}
//               </div>
//             ) : (
//               <div>
//                 {questionObj.image?.[0] && questionObj.image.map((imgItem, index) => (
//                   <img src={imgItem.imageUrl} key={index} />
//                 ))}
//               </div>
//             )}
//           </p>
//     </li>
//   );

//   if (!paper) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <div className='question-heading-select'>
//         <h1>View Questions for Paper Code: {paper.paperCode}</h1>

//         <select value={selectedLanguage} onChange={handleLanguageChange}>
//           <option value="English">English</option>
//           <option value="Hindi">Hindi</option>
//         </select>
//       </div>

//       {/* {console.log("PAPER.ENGLISHQUESTIONS", paper.englishquestions )} */}
//       {paper.englishquestions.map((question, index) => (
//         <div key={index}>
//           <h2>Question {index + 1}:</h2>
//           <p> {selectedLanguage === 'English' ? question.option : paper.hindiquestions[index].option} </p>
//           {/*
//           <p> {selectedLanguage === 'English' ? (
//               <div>
//                 {question.image[0] && question.image.map((imgItem, index) => ( <img src={imgItem.imageUrl} key={index} />))}
//               </div>
//             ) : (
//               <div>
//                 {paper.hindiquestions[index].image[0] && paper.hindiquestions[index].image.map((imgItem, index) => (
//                     <img src={imgItem.imageUrl} key={index} />
//                   ))}
//               </div>
//             )}
//           </p>
//           */}
//           <p>
//             {selectedLanguage === 'English' ? (
//               <div>
//                 {question.image?.[0] && question.image.map((imgItem, index) => (
//                   <img src={imgItem.imageUrl} key={index} />
//                 ))}
//               </div>
//             ) : (
//               <div>
//                 {paper.hindiquestions[index].image?.[0] && paper.hindiquestions[index].image.map((imgItem, index) => (
//                   <img src={imgItem.imageUrl} key={index} />
//                 ))}
//               </div>
//             )}
//           </p>

//           <h3>Options:</h3>
//           <ul>
//             {['A', 'B', 'C', 'D'].map((optionKey) =>
//               renderOption(
//                 paper[`${selectedLanguage.toLowerCase()}option${optionKey}`][index],
//                 selectedLanguage
//               )
//             )}
//           </ul>

//           <p>Answer: {paper.answers[index].option}</p>
//           <p>
//             Solution: {selectedLanguage === 'English'
//               ? paper.solutions[index].option
//               : paper.hindiSolutions[index].option}
//             <p>
//               {selectedLanguage === 'English' ? (
//                 <div>
//                   {paper.solutions?.[index].image[0] &&
//                     paper.solutions?.[index].image.map((imgItem, index) => (
//                       <img src={imgItem.imageUrl} key={index} />
//                     ))}
//                 </div>
//               ) : (
//                 <div>
//                   {paper.hindiSolutions[index].image[0] &&
//                     paper.hindiSolutions[index].image.map((imgItem, index) => (
//                       <img src={imgItem.imageUrl} key={index} />
//                     ))}
//                 </div>
//               )}
//             </p>

//           </p>

//           {editingIndex === index ? (
//             <EditQuestion
//               questionData={{
//                 question: question,
//                 optionA: paper.englishoptionA[index],
//                 optionB: paper.englishoptionB[index],
//                 optionC: paper.englishoptionC[index],
//                 optionD: paper.englishoptionD[index],
//                 hindiquestions: paper.hindiquestions[index],
//                 hindioptionA: paper.HindioptionA[index],
//                 hindioptionB: paper.HindioptionB[index],
//                 hindioptionC: paper.HindioptionC[index],
//                 hindioptionD: paper.HindioptionD[index],
//                 sections: paper.sections[index],
//                 solutions: paper.solutions[index],
//                 hindiSolutions: paper.hindiSolutions[index],
//                 answer: paper.answers[index],
//               }}
//               selectedLanguage={selectedLanguage}
//               onSave={(editedQuestion) => handleQuestionEdit(index, editedQuestion)}
//             />
//           ) : (
//             <button onClick={() => setEditingIndex(index)}>Edit Question</button>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ViewQuestions;
