import React from "react";
import classes from "./Footer.module.css";
import Latest from "../images/vecteezy_using-exam-sheets-to-study-in-isometric-illustration_.jpg";

const LatestCard = () => {
  return (
    <>
      <div className={classes.container}>
        <div className={classes.footer_individual_section}>
          <img
            className={classes.image_sub_section}
            src={Latest}
            alt="latestimage"
          />
          <h4>Latest Exam Patterns</h4>
          <p>Based on real time exam interface</p>
        </div>
      </div>
    </>
  );
};

export default LatestCard;
