import React from "react";
import classes from "./ExamModeTestSeriesCard.module.css";

const ExamModeTestSeriesCard = ({ examModeData }) => {
  return (
    <div className={classes.ExamMode_container}>
      <div className={classes.ExamMode_heading_section}>
        <h6 className={classes.ExamMode_describe_category}>
          {examModeData && examModeData.test_series_name}
        </h6>
      </div>
      <div className={classes.ExamMode_card_description_section}>
        <h5>Features</h5>
        <div className={classes.ExamMode_individual_unorder_list}>
          <ul className={classes.ExamMode_basic_list_item}>
            <li>Mock Test based on latest pattern</li>
            <li>Previous year included pdf</li>
            <li>sectional based on different level </li>
            <li>chapterwise based on weightage</li>
          </ul>
        </div>
        <div className={classes.ExamMode_card_footer_section}>
          <div className={classes.ExamMode_card_btn_div}>
          <button className={classes.ExamMode_buy_now_section}
            // onClick={() => handleJoinTestPassChange(passdata.exam_id)}
          >
            buy now
          </button>
          <button className={classes.ExamMode_show_btn}>
            show more
          </button>
          </div>
          <div className={classes.ExamMode_price_sub_section}>
            {/* <label className={classes.ExamMode_strike_through_section}>
              {examModeData && examModeData.max_price}
            </label> */}
            {/* <label>{examModeData && examModeData.price}</label> */}
          </div>
          <div className={classes.ExamMode_footer_sub_section}>
            <p>use coupon code for extra discount... Apply code</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamModeTestSeriesCard;
