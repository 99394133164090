import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from "multiselect-react-dropdown";
import classes from "./CreatePaperCode.module.css";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import Cookies from "universal-cookie";

const langaugeArray = ["English", "Hindi"];

function CreatePaperCode() {
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [formValues, setFormValues] = useState({
    subjects: [],
    examMode: "",
    paper_code: "",
    test_title: "",
    questions: "",
    marks: "",
    time: "",
    langauge: [],
    status: "",
    test_type: "",
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
  });
  const multiselectRef = useRef();

  const cookies = new Cookies();
  const token = cookies.get("token");

  useEffect(() => {
    setSubjectOptions([]);

    const fetchData = async () => {
      try {
        let array = [];
        const response = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/subjectmastertable",
          {
            headers: {
              Authorization: `Bearer ${token} `,
            },
          }
        );
        for (let i = 0; i < response.data.length; i++) {
          array.push(response.data[i]);
        }
        setSubjectOptions(array);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const handleInputChange = (e) => {
  //   const { id, value } = e.target;
  //   setFormValues({
  //     ...formValues,
  //     [id]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
  
    // Check if the input value contains '/'
    if (value.includes('/')) {
      // Alert the user that '/' is not allowed
      alert("The '/' character is not allowed");
      
      // Replace '/' with an empty string to remove it from the input value
      const sanitizedValue = value.replace(/\//g, '-');
  
      // Update the form values without the '/'
      setFormValues({
        ...formValues,
        [id]: sanitizedValue,
      });
    } else {
      // Update the form values normally if '/' is not present
      setFormValues({
        ...formValues,
        [id]: value,
      });
    }
  };

  const handleLanguageChange = (selectedList) => {
    setFormValues({
      ...formValues,
      langauge: selectedList,
    });
  };

  const handleTypeChange = (e) => {
    setFormValues({
      ...formValues,
      test_type: e.target.value,
    });
  };

  const handleDateChange1 = (date) => {
    console.log("Date Check:", date);
    setFormValues({
      ...formValues,
      start_date: date,
    });
  };

  const handleDateChange2 = (date) => {
    console.log("Date Check:", date);
    setFormValues({
      ...formValues,
      end_date: date,
    });
  };

  const handleTimeChange1 = (time) => {
    setFormValues({
      ...formValues,
      start_time: time,
    });
  };

  const handleTimeChange2 = (time) => {
    setFormValues({
      ...formValues,
      end_time: time,
    });
  };

  const addSubject = () => {
    setFormValues({
      ...formValues,
      subjects: [...formValues.subjects, ""],
    });
  };

  const removeSubject = (index) => {
    const updatedSubjects = [...formValues.subjects];
    updatedSubjects.splice(index, 1);
    setFormValues({
      ...formValues,
      subjects: updatedSubjects,
    });
  };

  const validateForm = () => {
    const {
      subjects,
      examMode,
      paper_code,
      test_title,
      questions,
      marks,
      time,
      langauge,
      test_type,
      start_date,
      start_time,
      end_date,
      end_time,
    } = formValues;

    if (!subjects.length || subjects.includes("")) {
      console.log("subject selected", subjects);
      alert("Please select at least one subject.");
      return false;
    }
    if (!examMode) {
      alert("Please select an exam mode.");
      return false;
    }
    if (!paper_code) {
      alert("Please enter a paper code.");
      return false;
    }
    if (!test_title) {
      alert("Please enter a test title.");
      return false;
    }
    if (!questions) {
      alert("Please enter the total number of questions.");
      return false;
    }
    if (!marks) {
      alert("Please enter the total marks.");
      return false;
    }
    if (!time) {
      alert("Please enter the total time.");
      return false;
    }
    if (!langauge.length) {
      alert("Please select at least one language.");
      return false;
    }
    if (!test_type) {
      alert("Please select a test type.");
      return false;
    }
    if (!start_date) {
      alert("Please select a start date.");
      return false;
    }
    if (!start_time) {
      alert("Please select a start time.");
      return false;
    }
    if (!end_date) {
      alert("Please select an end date.");
      return false;
    }
    if (!end_time) {
      alert("Please select an end time.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Format the start_date, start_time, end_date, and end_time
    const formattedValues = {
      ...formValues,
      start_date: formValues.start_date
        ? moment(formValues.start_date).format("YYYY-MM-DD")
        : null,
      start_time: formValues.start_time
        ? moment(formValues.start_time).format("HH:mm")
        : null,
      end_date: formValues.end_date
        ? moment(formValues.end_date).format("YYYY-MM-DD")
        : null,
      end_time: formValues.end_time
        ? moment(formValues.end_time).format("HH:mm")
        : null,
    };

    console.log("Form submitted with values:", formattedValues);

    // console.log("Form submitted with values:", formValues);
    console.log(
      "process.env.REACT_APP_PINNACLE_BASE_URL:",
      process.env.REACT_APP_PINNACLE_BASE_URL
    );
    try {
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/admin/papercode/PaperCodeMasterTable",
        // formValues
        formattedValues,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response from backend:", response);
      console.log("response.statusText:", response.statusText);
      setFormValues({
        subjects: [],
        examMode: "",
        paper_code: "",
        test_title: "",
        questions: "",
        marks: "",
        time: "",
        langauge: [],
        test_type: "",
        status: "",
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
      });
      setSubjectOptions([]);
      document.querySelector('input[name="test_type"]:checked').checked = false;
      multiselectRef.current.resetSelectedValues();

      Swal.fire({
        icon: "success",
        title: "Create Paper Code Successful!",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      // console.error("Error submitting form:", error);
      console.error("Error submitting form:", error.response.data.message);

      Swal.fire({
        icon: "error",
        title: "Sorry!",
        // text: "Something went wrong!",
        text: `${error.response.data.message}`,
      });
    }
  };

  console.log("subject", formValues.subjects);
  console.log("subjectOptions", subjectOptions);

  return (
    <div className={classes.create_paper_details_container}>
      <h1 className={classes.create_paper_h1}>Create Paper Code</h1>
      <form onSubmit={handleSubmit}>
        <div className={classes.form_group}>
          <label htmlFor="subjects">Subject Name :</label>

          <div className={classes.test_subject}>
            <select
              value={formValues.subjects[0] || ""}
              onChange={(e) => {
                const updatedSubjects = [...formValues.subjects];
                updatedSubjects[0] = e.target.value;
                setFormValues({
                  ...formValues,
                  subjects: updatedSubjects,
                });
              }}
              required
            >
              <option value="" disabled hidden>
                Enter Subject Name
              </option>
              {subjectOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.subject_name}
                </option>
              ))}
            </select>
            <button
              className={classes.blue_button}
              type="button"
              onClick={addSubject}
            >
              +
            </button>
          </div>

          {formValues.subjects.slice(1).map((subject, index) => (
            <div key={index} className={classes.test_subject}>
              <select
                value={formValues.subjects[index + 1] || ""}
                onChange={(e) => {
                  const updatedSubjects = [...formValues.subjects];
                  updatedSubjects[index + 1] = e.target.value;
                  setFormValues({
                    ...formValues,
                    subjects: updatedSubjects,
                  });
                }}
                required
              >
                <option value="" disabled hidden>
                  Select Subject Name
                </option>
                {subjectOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.subject_name}
                  </option>
                ))}
              </select>
              {formValues.subjects.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeSubject(index + 1)}
                  className={classes.red_button}
                >
                  -
                </button>
              )}
            </div>
          ))}
        </div>

        <div className={classes.form_group}>
          <label htmlFor="examMode">Exam Mode :</label>
          <select
            id="examMode"
            value={formValues.examMode}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled hidden>
              exam mode
            </option>
            <option value="1">Mock test - Full length</option>
            <option value="2">Sectional Test</option>
            <option value="3">Chapter Test</option>
            <option value="4">PYP - Full Length</option>
            <option value="5">PYP - Full Length (New Pattern)</option>
            {/* <option value="6">Current Affairs Test</option>
            <option value="7">Challenging Test</option> */}
          </select>
        </div>

        <div className={classes.form_group}>
          <label htmlFor="paper_code">Paper Code :</label>
          <input
            id="paper_code"
            onChange={handleInputChange}
            value={formValues.paper_code}
            placeholder="Enter Paper Code"
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="test_title">Paper Title :</label>
          <input
            id="test_title"
            value={formValues.test_title}
            onChange={handleInputChange}
            placeholder="Enter Paper Title"
            required
          />
        </div>
        <div className={classes.form_group}>
          <label htmlFor="questions">Total Questions :</label>
          <input
            id="questions"
            value={formValues.questions}
            onChange={handleInputChange}
            placeholder="Enter Total Questions"
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="marks">Total Marks :</label>
          <input
            id="marks"
            value={formValues.marks}
            onChange={handleInputChange}
            placeholder="Enter Total Marks"
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="time">Total Time :</label>
          <input
            id="time"
            value={formValues.time}
            onChange={handleInputChange}
            placeholder="Enter Total Time"
            required
          />
        </div>
        <div className={classes.form_group}>
          <label htmlFor="langauge">Choose Language :</label>
          <Multiselect
            id="langauge"
            options={langaugeArray}
            onSelect={handleLanguageChange}
            onRemove={handleLanguageChange}
            isObject={false}
            placeholder="Language"
            ref={multiselectRef}
            required
          />
        </div>
        <div>
          <Row className={classes.choose_raw}>
            <Col className={classes.choose_type_col}>Choose type :</Col>
            <Col>
              <label className={classes.live_label}>Live</label>
              <input
                className={classes.radio_btn}
                type="radio"
                id="live"
                name="test_type"
                value="live"
                onChange={handleTypeChange}
                required
              />
            </Col>
            <Col>
              <label className={classes.live_label}>Mock</label>
              <input
                className={classes.radio_btn}
                type="radio"
                id="mock"
                name="test_type"
                value="mock"
                onChange={handleTypeChange}
                required
              />
            </Col>
          </Row>
        </div>

        <div className={classes.form_group}>
          <label htmlFor="status">Status :</label>
          <select
            id="status"
            value={formValues.status}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled hidden>
              Select Anyone
            </option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>

        <div className={classes.form_group}>
          <label htmlFor="start_date">Start Date :</label>
          <DatePicker
            selected={formValues.start_date}
            onChange={handleDateChange1}
            dateFormat="yyyy-MM-dd"
            className="form_group"
            placeholderText="start date"
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="start_time">Paper Start Time :</label>
          <DatePicker
            selected={formValues.start_time}
            onChange={handleTimeChange1}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="HH:mm"
            placeholderText="Start Time"
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="end_date">End Date :</label>
          <DatePicker
            selected={formValues.end_date}
            onChange={handleDateChange2}
            dateFormat="yyyy-MM-dd"
            placeholderText="End Date"
            required
          />
        </div>

        <div className={classes.form_group}>
          <label htmlFor="end_time">Paper End Time :</label>
          <DatePicker
            selected={formValues.end_time}
            onChange={handleTimeChange2}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="HH:mm"
            placeholderText="End Time"
            required
          />
        </div>

        <div className={classes.submit_button_container}>
          <button type="submit" className={classes.submit_button}>
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreatePaperCode;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { Multiselect } from "multiselect-react-dropdown";
// // import "./CreatePaperCode.css";
// import classes from "./CreatePaperCode.module.css";
// import { Row, Col, Modal, Button, Table } from "react-bootstrap";
// import Swal from "sweetalert2";

// const langaugeArray = ["English", "Hindi"];

// function CreatePaperCode() {

// const [subjectOptions,setSubjectOptions] = useState([]);
//   useEffect(() => {
//     // Fetch data from an API
//     setSubjectOptions([{id:0,exam_name:"Select Exams"}])

//     const fetchData = async () => {
//       try {
//         let array = [{id:0,subject_name:"Select Exams"}]
//         const response = await axios.get(
//           process.env.REACT_APP_PINNACLE_BASE_URL + "/subjectmastertable"
//         );
//         // setId(response.data[1].count_id + 1);
//         // console.log(response.data)
//         for(let i=0;i<response.data.length;i++){
//           array.push(response.data[i]);
//         }
//         console.log(array)
//           setSubjectOptions(array);
//         // console.log("Response from backend:", response.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const [formValues, setFormValues] = useState({
//     subjects: [],
//     examMode: "",
//     paper_code: "",
//     test_title: "",
//     questions: "",
//     marks: "",
//     time: "",
//     langauge: [],
//     test_type: "",
//     start_date: "",
//     start_time: "",
//     end_date: "",
//     end_time: "",
//   });

//   const handleInputChange = (e) => {
//     const { id, value } = e.target;
//     setFormValues({
//       ...formValues,
//       [id]: value,
//     });
//     console.log(value)
//   };

//   const handleLanguageChange = (selectedList) => {
//     setFormValues({
//       ...formValues,
//       langauge: selectedList,
//     });
//   };

//   const handleTypeChange = (e) => {
//     // const { id } = e.target.value;
//     setFormValues({
//       ...formValues,
//       test_type: e.target.value,
//     });
//   };
//   if(formValues.test_type){
//     console.log(formValues.test_type)

//   }

//   const handleDateChange1 = (date) => {
//     setFormValues({
//       ...formValues,
//       start_date: date.toLocaleDateString(),
//     });
//   };

//   const handleDateChange2 = (date) => {
//     setFormValues({
//       ...formValues,
//       end_date: date.toLocaleDateString(),
//     });
//   };

//   const handleTimeChange1 = (time) => {
//     setFormValues({
//       ...formValues,
//       start_time: time,
//     });
//   };

//   const handleTimeChange2 = (time) => {
//     setFormValues({
//       ...formValues,
//       end_time: time,
//     });
//   };

//   const addSubject = () => {
//     setFormValues({
//       ...formValues,
//       subjects: [...formValues.subjects, ""],
//     });
//   };

//   const removeSubject = (index) => {
//     const updatedSubjects = [...formValues.subjects];
//     updatedSubjects.splice(index, 1);
//     setFormValues({
//       ...formValues,
//       subjects: updatedSubjects,
//     });
//   };

//   const validateForm = () => {
//     const {
//       subjects,
//       examMode,
//       paper_code,
//       test_title,
//       questions,
//       marks,
//       time,
//       langauge,
//       test_type,
//       start_date,
//       start_time,
//       end_date,
//       end_time,
//     } = formValues;

//     if (!subjects.length || subjects.includes("")) {
//       alert("Please select at least one subject.");
//       return false;
//     }
//     if (!examMode) {
//       alert("Please select an exam mode.");
//       return false;
//     }
//     if (!paper_code) {
//       alert("Please enter a paper code.");
//       return false;
//     }
//     if (!test_title) {
//       alert("Please enter a test title.");
//       return false;
//     }
//     if (!questions) {
//       alert("Please enter the total number of questions.");
//       return false;
//     }
//     if (!marks) {
//       alert("Please enter the total marks.");
//       return false;
//     }
//     if (!time) {
//       alert("Please enter the total time.");
//       return false;
//     }
//     if (!langauge.length) {
//       alert("Please select at least one language.");
//       return false;
//     }
//     if (!test_type) {
//       alert("Please select a test type.");
//       return false;
//     }
//     if (!start_date) {
//       alert("Please select a start date.");
//       return false;
//     }
//     if (!start_time) {
//       alert("Please select a start time.");
//       return false;
//     }
//     if (!end_date) {
//       alert("Please select an end date.");
//       return false;
//     }
//     if (!end_time) {
//       alert("Please select an end time.");
//       return false;
//     }
//     return true;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!validateForm()) {
//       return;
//     }

//     console.log("Form submitted with values:", formValues);
//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/papercode/PaperCodeMasterTable",
//         formValues
//       );
//       console.log("Response from backend:", response.data);
//       setFormValues({
//         subjects: [],
//         examMode: "",
//         paper_code: "",
//         test_title: "",
//         questions: "",
//         marks: "",
//         time: "",
//         langauge: [],
//         test_type: "",
//         start_date: "",
//         start_time: "",
//         end_date: "",
//         end_time: "",
//       });
//       setSubjectOptions([])
//       // Show success message
//       Swal.fire({
//         icon: "success",
//         title: "Create Paper Code Successful!",
//         showConfirmButton: false,
//         timer: 1500,
//       });
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       // Show error message
//       Swal.fire({
//         icon: "error",
//         title: "Oops...",
//         text: "Something went wrong!",
//       });
//     }
//   };

//   return (
//     <div className={classes.create_paper_details_container}>
//     <h1 className={classes.create_paper_h1}>Create Paper Code</h1>
//       <form onSubmit={handleSubmit}>
//         <div className={classes.form_group}>
//           <label htmlFor="subjects">Subject Name :</label>
//           <div className={classes.test_subject}>
//             <select
//               value={formValues.subjects[0]} // Update the value prop
//               onChange={(e) => {
//                 const updatedSubjects = [...formValues.subjects];
//                 updatedSubjects[0] = e.target.value;
//                 setFormValues({
//                   ...formValues,
//                   subjects: updatedSubjects,
//                 });
//               }}
//               required
//             >
//               {subjectOptions.map((option, optionIndex) => (
//                 <option key={option.id} value={option.id}>
//                   {option.subject_name}
//                 </option>
//               ))}
//             </select>
//             <button
//               className={classes.blue_button}
//               type="button"
//               onClick={addSubject}
//             >
//               +
//             </button>
//           </div>

//           {formValues.subjects.slice(1).map((subject, index) => (
//             <div key={index} className={classes.test_subject}>
//               <select
//                 value={formValues.subjects[index + 1]} // Update the value prop
//                 onChange={(e) => {
//                   const updatedSubjects = [...formValues.subjects];
//                   updatedSubjects[index + 1] = e.target.value;
//                   setFormValues({
//                     ...formValues,
//                     subjects: updatedSubjects,
//                   });
//                 }}
//               >
//                 {subjectOptions.map((option, optionIndex) => (
//                   <option key={optionIndex} value={option.id}>
//                     {option.subject_name}
//                   </option>
//                 ))}
//               </select>
//               {formValues.subjects.length > 1 && (
//                 <button
//                   type="button"
//                   onClick={() => removeSubject(index + 1)}
//                   className={classes.red_button}
//                 >
//                   -
//                 </button>
//               )}
//             </div>
//           ))}

//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="exam-mode">Exam Mode :</label>
//           <select id="examMode" value={formValues.examMode} onChange={handleInputChange} placeholder="exam mode" required>
//             <option value="" disabled hidden>exam mode</option>
//             <option value="1">Mock test- Full length</option>
//             <option value="2">Sectional Test</option>
//             <option value="3">Chapter Test</option>
//             <option value="4">PYP- Full Length</option>
//             <option value="5">PYP-Full Length(New Pattern)</option>
//             <option value="6">Current Affairs Test</option>
//             <option value="7">Challenging Test</option>
//           </select>
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="paper-code">Paper Code :</label>
//           <input
//             id="paper_code"
//             onChange={handleInputChange}
//             value={formValues.paper_code}
//             placeholder="Enter Paper Code"
//             required
//           />
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="paper-title">Paper Title :</label>
//           <input
//             id="test_title"
//             value={formValues.test_title}
//             onChange={handleInputChange}
//             placeholder="Enter Paper Title"
//             required
//           />
//         </div>
//         <div className={classes.form_group}>
//           <label htmlFor="total-questions">Total Questions :</label>
//           <input
//             id="questions"
//             value={formValues.questions}
//             onChange={handleInputChange}
//             placeholder="Enter Total Questions"
//             required
//           />
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="total-marks">Total Marks :</label>
//           <input
//             id="marks"
//             value={formValues.marks}
//             onChange={handleInputChange}
//             placeholder="Enter Total Marks"
//             required
//           />
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="total-time">Total Time :</label>
//           <input
//             id="time"
//             value={formValues.time}
//             onChange={handleInputChange}
//             placeholder="Enter Total Time"
//             required
//           />
//         </div>
//         <div className={classes.form_group}>
//           <label htmlFor="langauge">Choose Language :</label>
//           <Multiselect
//             id="langauge"
//             options={langaugeArray}
//             onSelect={handleLanguageChange}
//             onRemove={handleLanguageChange}
//             isObject={false}
//             placeholder="Language"
//             // className={classes.choose_lang}
//           />
//         </div>
//          <div>
//           <Row className={classes.choose_raw}>
//             <Col className={classes.choose_type_col}>Choose type :</Col>
//             <Col>
//               <label className={classes.live_label}>Live</label>
//               <input
//                 className={classes.radio_btn}
//                 type="radio"
//                 id="live"
//                 name="live"
//                 value="live"
//                 style={{ float: "left" }}
//                 required
//               />
//             </Col>
//             <Col>
//               <label className={classes.live_label}>Mock</label>
//               <input
//                 className={classes.radio_btn}
//                 type="radio"
//                 id="mock"
//                 name="live"
//                 value="mock"
//                 required
//               />
//             </Col>
//           </Row>
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="status">Status :</label>
//           <select
//             id="status"
//             value={formValues.status}
//             onChange={handleInputChange}
//             required
//           >
//             <option value="" disabled hidden>Select Anyone</option>
//             <option value="active">Active</option>
//             <option value="inactive">Inactive</option>
//           </select>
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="start-date">Start Date :</label>
//           <DatePicker
//             selected={formValues.start_date}
//             // selected={selectedDate1}
//             onChange={handleDateChange1}
//             dateFormat="dd/MM/yyyy"
//             className="form_group"
//             placeholderText="start date"
//             required
//           />
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="start-time">Paper Start Time :</label>
//           <DatePicker
//             selected={formValues.start_time}
//             // selected={selectedTime1}
//             onChange={handleTimeChange1}
//             showTimeSelect
//             showTimeSelectOnly
//             timeIntervals={60}
//             timeCaption="Time"
//             dateFormat="hh:mm"
//             placeholderText="Start Time"
//             required
//           />
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="end-date">End Date :</label>
//           <DatePicker
//             selected={formValues.end_date}
//             // selected={selectedDate2}
//             onChange={handleDateChange2}
//             dateFormat="dd/MM/yyyy"
//             placeholderText="End Date"
//             required
//           />
//         </div>

//         <div className={classes.form_group}>
//           <label htmlFor="end-time">Paper End Time :</label>
//           <DatePicker
//             selected={formValues.end_time}
//             // selected={selectedTime2}
//             onChange={handleTimeChange2}
//             showTimeSelect
//             showTimeSelectOnly
//             timeIntervals={60}
//             timeCaption="Time"
//             dateFormat="hh:mm"
//             placeholderText="End Time"
//             required
//           />
//         </div>

//         <div className={classes.submit_button_container}>
//           <button type="submit" className={classes.submit_button}>
//             SUBMIT
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default CreatePaperCode;
