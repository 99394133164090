// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RelatedProducts.css */

.related-products {
    margin-top: 20px;
  }
  
  .related-products h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border: 1px solid #e0e0e0; /* Change to your desired border color */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .product-card img {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .product-card h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .product-card p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .product-card span {
    font-size: 18px;
    font-weight: bold;
    color: #f50057; /* Change to your desired price color */
  }
  
  .product-card button {
    padding: 8px 15px;
    font-size: 14px;
    background-color: #f50057; /* Change to your desired button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .product-card button:hover {
    background-color: #e91e63; /* Change to your desired hover color */
  }
  
  /* Apply additional styles as needed */
  `, "",{"version":3,"sources":["webpack://./src/component/RelatedProducts.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,yBAAyB,EAAE,wCAAwC;IACnE,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc,EAAE,uCAAuC;EACzD;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,yBAAyB,EAAE,wCAAwC;IACnE,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB,EAAE,uCAAuC;EACpE;;EAEA,sCAAsC","sourcesContent":["/* RelatedProducts.css */\n\n.related-products {\n    margin-top: 20px;\n  }\n  \n  .related-products h2 {\n    font-size: 20px;\n    margin-bottom: 10px;\n  }\n  \n  .product-card {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 15px;\n    border: 1px solid #e0e0e0; /* Change to your desired border color */\n    border-radius: 5px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .product-card img {\n    width: 100px;\n    height: auto;\n    margin-bottom: 10px;\n  }\n  \n  .product-card h3 {\n    font-size: 16px;\n    margin-bottom: 5px;\n  }\n  \n  .product-card p {\n    font-size: 14px;\n    text-align: center;\n    margin-bottom: 10px;\n  }\n  \n  .product-card span {\n    font-size: 18px;\n    font-weight: bold;\n    color: #f50057; /* Change to your desired price color */\n  }\n  \n  .product-card button {\n    padding: 8px 15px;\n    font-size: 14px;\n    background-color: #f50057; /* Change to your desired button color */\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .product-card button:hover {\n    background-color: #e91e63; /* Change to your desired hover color */\n  }\n  \n  /* Apply additional styles as needed */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
