// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateUserForm_create_user_form__Eo6cn {
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.CreateUserForm_add_user__QdhPK{
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.CreateUserForm_form_group__f5FUq {
  margin-bottom: 15px;
}

.CreateUserForm_form_group__f5FUq input[type="email"],
.CreateUserForm_form_group__f5FUq input[type="tel"],
.CreateUserForm_form_group__f5FUq input[type="text"],
.CreateUserForm_form_group__f5FUq input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.CreateUserForm_live_label__pKuG0{
  display: flex;
  margin: 0 0 8px 25px;
}

.CreateUserForm_radio_btn__sDv6X{
  display: flex;
  margin-top: -28px;
}

.CreateUserForm_crete_btn_position__a7Qlh{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.CreateUserForm_creat_btn__p7iZL {
  padding: 10px;
  width:150px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/CreateUserForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;;;EAIE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".create_user_form {\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.add_user{\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n  font-size: 20px;\n  margin-top: 10px;\n  margin-bottom: 20px;\n}\n\n.form_group {\n  margin-bottom: 15px;\n}\n\n.form_group input[type=\"email\"],\n.form_group input[type=\"tel\"],\n.form_group input[type=\"text\"],\n.form_group input[type=\"password\"] {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n}\n\n.live_label{\n  display: flex;\n  margin: 0 0 8px 25px;\n}\n\n.radio_btn{\n  display: flex;\n  margin-top: -28px;\n}\n\n.crete_btn_position{\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.creat_btn {\n  padding: 10px;\n  width:150px;\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"create_user_form": `CreateUserForm_create_user_form__Eo6cn`,
	"add_user": `CreateUserForm_add_user__QdhPK`,
	"form_group": `CreateUserForm_form_group__f5FUq`,
	"live_label": `CreateUserForm_live_label__pKuG0`,
	"radio_btn": `CreateUserForm_radio_btn__sDv6X`,
	"crete_btn_position": `CreateUserForm_crete_btn_position__a7Qlh`,
	"creat_btn": `CreateUserForm_creat_btn__p7iZL`
};
export default ___CSS_LOADER_EXPORT___;
