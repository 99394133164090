import React, { useState, useEffect} from "react";
import classes from "./RefundPolicy.module.css";
import Layout from "./Layout"
import axios from "axios";

const RefundPolicy = () => {
    const [refundPolicyContent, setRefundPolicyContent] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchRefundPolicyContent = async () => {
            try {
                // Ensure the base URL environment variable is defined
                // const baseUrl = "http://localhost:5000"; // Corrected base URL
                // if (!baseUrl) {
                //     throw new Error("Base URL is not defined");
                // }

                // Fetch data using axios
                const response = await axios.get(`${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/terms-and-conditions`);

                if (response.data) {
                    console.log("TERMS_AND_CONDITIONS_DATA:", response.data);
                    setRefundPolicyContent(response.data.data.refund_policy || "");
                } else {
                    setError("Failed to load terms and conditions.");
                }
            } catch (error) {
                console.error("Error fetching terms and conditions:", error);
                setError("An error occurred while fetching terms and conditions.");
            } finally {
                setLoading(false);
            }
        };

        fetchRefundPolicyContent();
    }, []);

    return (
        <Layout>
            <div className={classes.refund_policy_div}>
                <h2>Refund & Policy</h2>
                {loading && <p>Loading terms and conditions...</p>}
                {error && <p className={classes.error}>{error}</p>}
                {!loading && !error && (
                    <div className={classes.termsContent} dangerouslySetInnerHTML={{ __html: refundPolicyContent }}/>
                )}
            </div>
        </Layout>
    );
};

export default RefundPolicy;
