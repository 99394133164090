// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentSuccess_payment_container__cTeXj {
  max-width: 600px; /* Reduce the width of the container */
  margin: 20px auto; /* Center the container */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center; /* Center-align text */
}

.PaymentSuccess_details_table__vMrHs {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.PaymentSuccess_details_table__vMrHs td {
  padding: 8px 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.PaymentSuccess_details_table__vMrHs td:first-child {
  font-weight: bold;
  background-color: #f0f0f0;
}

.PaymentSuccess_check_icon__gjSI0 {
  width: 100px; /* Set the width of the check image */
  height: auto;
  margin: 10px 0; /* Add spacing around the image */
}

.PaymentSuccess_failed_message__Duiau {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/component/PaymentSuccess.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,sCAAsC;EACxD,iBAAiB,EAAE,yBAAyB;EAC5C,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB,EAAE,sBAAsB;AAC5C;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,YAAY,EAAE,qCAAqC;EACnD,YAAY;EACZ,cAAc,EAAE,iCAAiC;AACnD;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".payment_container {\n  max-width: 600px; /* Reduce the width of the container */\n  margin: 20px auto; /* Center the container */\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  background-color: #f9f9f9;\n  text-align: center; /* Center-align text */\n}\n\n.details_table {\n  width: 100%;\n  border-collapse: collapse;\n  margin: 20px 0;\n}\n\n.details_table td {\n  padding: 8px 12px;\n  border: 1px solid #ddd;\n  text-align: left;\n}\n\n.details_table td:first-child {\n  font-weight: bold;\n  background-color: #f0f0f0;\n}\n\n.check_icon {\n  width: 100px; /* Set the width of the check image */\n  height: auto;\n  margin: 10px 0; /* Add spacing around the image */\n}\n\n.failed_message {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment_container": `PaymentSuccess_payment_container__cTeXj`,
	"details_table": `PaymentSuccess_details_table__vMrHs`,
	"check_icon": `PaymentSuccess_check_icon__gjSI0`,
	"failed_message": `PaymentSuccess_failed_message__Duiau`
};
export default ___CSS_LOADER_EXPORT___;
