// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import Layout from "./Layout";
// import classes from "./IndividualTestPass.module.css";
// import IndividualTestSeriesTierCard from "./IndividualTestSeriesTierCard";
// import { useParams } from "react-router-dom";
// import Spinner from "./Spinner";

// const IndividualTestPass = () => {
//   const { product_id } = useParams();
//   const [freeTestDataWeb, setFreeTestDataWeb] = useState([]);
//   const [individualtestPassData, setIndividualTestPassData] = useState(null);

//   const [loading, setLoading] = useState(true); // State to manage loading

//   const cache = useRef({});

//   useEffect(() => {
//     const fetchTestSeriesData = async () => {
//       // console.log(" line 14 i got clicked");
//       // console.log(exam_id);

//       // if (cache.current[product_id]) {
//       //   console.log(`Fetching data for product_id ${product_id} from cache`);
//       //   setIndividualTestPassData(cache.current[product_id]);
//       //   setLoading(false);
//       //   return;
//       // }

//       console.log(`Fetching data for product_id ${product_id} from API`);
//       try {
//         const response = await axios.post(
//           process.env.REACT_APP_PINNACLE_BASE_URL + "/individualexamtestpass",

//           {
//             // email_id: "harishmodi129@gmail.com",
//             product_id: product_id,
//           }
//         );

//         // const fetchedData = response.data;
//         // cache.current[product_id] = fetchedData;
//         // setIndividualTestPassData(fetchedData);

//         // setIndividualTestPassData(response.data.examData);
//         setIndividualTestPassData(response.data);
//         setLoading(false);
//         console.log(response.data);
//         // setFreeTestDataWeb(response.data);
//         // console.log(response.data);
//       } catch (error) {
//         console.log("an error occured", error);
//         setLoading(false);
//       }
//     };

//     fetchTestSeriesData();
//   }, [product_id]);

//   // useEffect(() => console.log(freeTestDataWeb));

//   // useEffect(() => {
//   //   async function freeTestDataWeb_Fun() {
//   //     let free_test_data_web = [
//   //       {
//   //         // email_id: "harishmodi@129@gmail.com",
//   //         // email_id: "neerajit@ssccglpinnacle.com",
//   //         // exam_post_tier_id: post_id,
//   //         // exam_id,
//   //         // tier_id,
//   //       },
//   //     ];
//   //     let free_test_data_web_request = await fetch(
//   //       process.env.REACT_APP_PINNACLE_BASE_URL + "/NewTestsWeb",
//   //       {
//   //         method: "POST",
//   //         headers: {
//   //           "Content-Type": "application/json",
//   //         },
//   //         body: JSON.stringify(free_test_data_web),
//   //       }
//   //     );
//   //     let free_test_data_web_response = await free_test_data_web_request.json();
//   //     console.log("free_test_data_web_response", free_test_data_web_response);
//   //     setFreeTestDataWeb(free_test_data_web_response);
//   //     setLoading(false);
//   //   }

//   //   freeTestDataWeb_Fun();
//   // }, []);

//   // console.log(individualtestPassData);

//   let testPassname;

//   // if (exam_id == 1) {
//   //   testPassname = "SSC";
//   // } else if (exam_id == 2) {
//   //   testPassname = "Delhi Police";
//   // } else if (exam_id == 3) {
//   //   testPassname = "Railway";
//   // } else {
//   //   testPassname = "";
//   // }

//   // const override: CSSProperties = {
//   //   display: "block",
//   //   margin: "0 auto",
//   //   borderColor: "red",
//   // };

//   return (
//     <Layout>
//       {/* <div style={{ margingTop: "3rem" }}> */}
//         {/* <div className={classes.offer_up_to_main_div}>
//           <div className={classes.offer_heading_div}>
//             <h2>Pinnacle Test Pass </h2>
//             <p>
//               One for <span>all</span>
//             </p>
//           </div>
//           <div className={classes.up_to_off_div}>
//             <h4>
//               Special Offer
//               <br />
//               <span className={classes.hurry_up_span}>Hurry Up!</span>
//             </h4>
//             <p>
//               <span className={classes.span_tag_1}>UPTO</span>
//               <span className={classes.span_tag_2}>75%</span>
//               <span className={classes.span_tag_3}>OFF</span> On All Test
//               Courses
//             </p>
//           </div>
//         </div> */}

//         {/* <div className={classes.category_section_item}>
//           {testPassData &&
//             testPassData.map((passdata, index) => (
//               <Card key={index} passdata={passdata} />
//             ))}
//         </div> */}

//         {/* <div className={classes.heading_of_individual_sub_section}>
//           <h2>70% OFF Hurry up</h2>
//           <button
//             style={{
//               backgroundColor: "#77a7ff",
//               border: "none",
//               color: "white",
//               position: "right",
//             }}
//           >
//             {" "}
//             Buy Now {testPassname} TCS TEST Pass
//           </button>
//         </div>
//         */}
//         {loading ? (
//           <Spinner />
//         ) : (
//           <div className={classes.individual_card_section}>
//             {individualtestPassData &&
//               individualtestPassData.map((testpassdata, index) => (
//                 <div
//                   key={index}
//                 >
//                   <IndividualTestSeriesTierCard
//                     product_id={product_id}
//                     testpassdata={testpassdata}
//                     // exam_id={exam_id}
//                   />
//                 </div>
//               ))}
//           </div>
//         )}
//       {/* </div> */}
//     </Layout>
//   );
// };

// export default IndividualTestPass;




import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layout from "./Layout";
import classes from "./IndividualTestPass.module.css";
import IndividualTestSeriesTierCard from "./IndividualTestSeriesTierCard";
import { useParams } from "react-router-dom";
import Spinner from "./Spinner";

const IndividualTestPass = () => {
  const { product_id } = useParams();
  const [individualtestPassData, setIndividualTestPassData] = useState(null);
  const [loading, setLoading] = useState(true); // State to manage loading
  const cache = useRef({});

  useEffect(() => {
    const fetchTestSeriesData = async () => {
      console.log(`Fetching data for product_id ${product_id} from API`);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/individualexamtestpass`,
          {
            product_id: product_id,
          }
        );
        setIndividualTestPassData(response.data);
        setLoading(false);
        console.log(response.data);
      } catch (error) {
        console.log("An error occurred", error);
        setLoading(false);
      }
    };

    fetchTestSeriesData();
  }, [product_id]);

  return (
    <Layout>
      {loading ? (
        <Spinner />
      ) : (
        <div className={classes.individual_card_section}>
          {individualtestPassData &&
            individualtestPassData.map((testpassdata, index) => (
              <IndividualTestSeriesTierCard
                key={index}
                product_id={product_id}
                testpassdata={testpassdata}
              />
            ))}
        </div>
      )}
    </Layout>
  );
};

export default IndividualTestPass;

