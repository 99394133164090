// import React, { useEffect, useState } from "react";
// import Layout from "./Layout";
// import { useSelector } from "react-redux";
// // import { addProfile } from "../redux/slices/userProfileSlice";
// import { useAuthContext } from "../hooks/useAuthContext";
// import axios from "axios";

// const MyOrders = () => {
//   const [productData, setProductData] = useState(null);
//   const [message, setMessage] = useState("");

//   const userProfile = useSelector((state) => state.userProfile);
//   // const { token } = useAuthContext();

//   useEffect(() => {
//     // console.log("i got called");
//     const fetchOrders = async () => {
//       try {
//         const response = await axios.post(
//           process.env.REACT_APP_PINNACLE_BASE_URL + "/purchaseorders",
//           {
//             email_id: userProfile.email_id,
//           }
//         );
//         if (response.data.length === 0) {
//           setMessage("You have not bought any product yet.");
//         } else if (response.data.message) {
//           setMessage(response.data.message);
//         } else {
//           setProductData(response.data);
//         }
//         console.log(response.data);
//       } catch (error) {
//         console.log("an internal server error occur");
//       }
//     };

//     fetchOrders();
//   }, []);
//   return (
//     <Layout>
//       <div style={{marginTop:"8px"}}>
//         <h1>Purchased Test Series</h1>
//         <table>
//           <thead>
//             <tr>
//               <td>Order_id</td>
//               <td>Product Name</td>
//               <td>Product Price </td>
//               <td>Product Validation Period</td>
//               <td>Expiry Date</td>
//             </tr>
//           </thead>
//           <tbody>
//             {productData &&
//               productData.map((purchasedOrder) => (
//                 <tr key={purchasedOrder.order_id}>
//                   <td> {purchasedOrder?.order_id || " "}</td>
//                   <td>{purchasedOrder.product_title}</td>
//                   <td>{purchasedOrder.product_price}</td>
//                   <td>{purchasedOrder.product_validation_period}</td>
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>
//     </Layout>
//   );
// };

// export default MyOrders;

// import React, { useEffect, useState } from "react";
// import Layout from "./Layout";
// import { useSelector } from "react-redux";
// import { useAuthContext } from "../hooks/useAuthContext";
// import axios from "axios";
// import { useNavigate, Link } from "react-router-dom";
// import classes from "./MyOrders.module.css";

// const MyOrders = () => {
//   const [productData, setProductData] = useState(null);
//   const [message, setMessage] = useState("");

//   const userProfile = useSelector((state) => state.userProfile);
//   const navigate = useNavigate();

//   console.log("userProfile:", userProfile);

//   // Function to handle row click and navigate to product details
//   const handleProductClick = (productId) => {
//     navigate(`/individualexamtestpasssection/${productId}`);
//   };

//   // Function to handle video course button click
//   // const handleVideoCourseClick = () => {
//   //   navigate("/"); // Replace with the correct route for video courses
//   // };

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_PINNACLE_BASE_URL}/purchaseorders`,
//           {
//             email_id: userProfile.email_id,
//             id: userProfile._id,
//           }
//         );

//         if (response.data.length === 0) {
//           setMessage("You have not bought any product yet.");
//         } else if (response.data.message) {
//           setMessage(response.data.message);
//         } else {
//           setProductData(response.data);
//         }
//       } catch (error) {
//         console.log("An internal server error occurred");
//       }
//     };

//     fetchOrders();
//   }, [userProfile.email_id, userProfile._id]);

//   return (
//     <Layout>
//       <div style={{ marginTop: "8px" }}>
//         {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//           <h1>Purchased Test Series</h1>
//           <button >
//           <Link to={`https://ssccglpinnacle.com/videos/mylearning/${userProfile._id}`}>
//           {/* <Link to= {`https://ssccglpinnacle.com/videos`}> */}
//         {/* Video Course
//           </Link>
//           </button>
//         </div> */}
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <h1>Purchased Test Series</h1>
//           <button
//             style={{
//               backgroundColor: "#007BFF",
//               color: "white",
//               border: "none",
//               padding: "5px 10px",
//               fontSize: "16px",
//               borderRadius: "5px",
//               cursor: "pointer",
//               display: "inline-block",
//             }}
//           >
//             <Link
//               to={`https://ssccglpinnacle.com/videos`}
//               style={{
//                 color: "white",
//                 textDecoration: "none",
//                 display: "block",
//                 width: "100%",
//                 height: "100%",
//               }}
//             >
//               Video Course
//             </Link>
//           </button>
//         </div>
//         {message && <p>{message}</p>}
//         <table>
//           <thead>
//             <tr>
//               <td>Order ID</td>
//               <td>Product Name</td>
//               <td>Product Price</td>
//               <td>Product Validation Period</td>
//               <td>Expiry Date</td>
//             </tr>
//           </thead>
//           <tbody>
//             {productData &&
//               productData.map((purchasedOrder) => (
//                 <tr key={purchasedOrder.order_id}>
//                   <td>{purchasedOrder?.order_id || " "}</td>
//                   <td
//                     onClick={() =>
//                       handleProductClick(purchasedOrder.product_id)
//                     }
//                     style={{ cursor: "pointer", color: "blue" }}
//                   >
//                     {purchasedOrder.product_title}
//                   </td>
//                   <td>{purchasedOrder.product_price}</td>
//                   <td>{purchasedOrder.product_validation_period}</td>
//                   <td>{purchasedOrder.expiry_date}</td>
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>
//     </Layout>
//   );
// };

// export default MyOrders;

import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import classes from "./MyOrders.module.css";

const MyOrders = () => {
  const [productData, setProductData] = useState(null);
  const [message, setMessage] = useState("");

  const userProfile = useSelector((state) => state.userProfile);
  const navigate = useNavigate();

  const handleProductClick = (productId) => {
    navigate(`/individualexamtestpasssection/${productId}`);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/purchaseorders`,
          {
            email_id: userProfile.email_id,
            id: userProfile._id,
          }
        );

        if (response.data.length === 0) {
          setMessage(
            "You have not bought any product yet and First check you are  login to  see any information regarding your products"
          );
        } else if (response.data.message) {
          setMessage(response.data.message);
        } else {
          setProductData(response.data);
        }
      } catch (error) {
        console.log("An internal server error occurred");
      }
    };

    fetchOrders();
  }, [userProfile.email_id, userProfile._id]);

  return (
    <Layout>
      <div className={classes.container}>
        <div className={classes.header}>
          <h1 className={classes.title}>Purchased Test Series</h1>
          <button className={classes.button_link}>
            <Link
              to={`https://ssccglpinnacle.com/myorders_typing`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased Typing Software
            </Link>
          </button>
          <button className={classes.button_link}>
            <Link
              to={`https://ssccglpinnacle.com/mylearning`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased E-Books
            </Link>
          </button>
          <button className={classes.button_link}>
            <Link
              to={`https://ssccglpinnacle.com/videos/myorders`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased Video Course
            </Link>
          </button>
          <button className={classes.button_books}>
            <Link
              to={`https://ssccglpinnacle.com/purchase-book`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Purchased Books
            </Link>
          </button>
        </div>
        {message && <p className={classes.message}>{message}</p>}
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Product Name</th>
              <th>Product Price</th>
              <th>Product Validation Period</th>
              <th>Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            {productData &&
              productData.map((purchasedOrder) => (
                <tr key={purchasedOrder.order_id}>
                  <td>{purchasedOrder?.order_id || " "}</td>
                  <td
                    onClick={() =>
                      handleProductClick(purchasedOrder.product_id)
                    }
                    className={classes.productTitle}
                  >
                    {purchasedOrder.product_title}
                  </td>
                  <td>{purchasedOrder.product_price}</td>
                  <td>{purchasedOrder.product_validation_period}</td>
                  <td>{purchasedOrder.expiry_date}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default MyOrders;
