import "./CashFreeButton";
import "./mobilestyle.css";
import "./header1.css";
import "./Header1Section.css";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
import CountDownTimerSection from "./CountDownTimerSection";
import QuestionNavigationSection from "./QuestionNavigationSection";
import QuestionsSection from "./QuestionsSection";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { IoReorderThreeOutline } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import QinstructionSection from "./QinstructionSection";
import { useCookies } from "react-cookie";
import logo from "../images/Bubble-Preloader.gif";
import { useSelector } from "react-redux";
import { useAuthContext } from "../hooks/useAuthContext";

function Header1Section({
  hrss,
  minss,
  test_series_id,
  exam_mode_id,
  secss,
  paper_code,
  NotVisited,
  subjectID,
  subject_name,
  Answered,
  NotAnswered,
  Marked,
  MarkedAnswered,
  title,
  languages,
  section,
  pids,
  TestTp,
  exam_post_tier,
  exam_id,
  post_id,
  product_id,
}) {
  const [hoursMinSecs, setHoursMinSecs] = useState({
    hours: hrss,
    minutes: minss,
    seconds: secss,
  });

  const [hrs, setHrs] = useState(hrss);
  const [mins, setMins] = useState(minss);
  const [secs, setSecs] = useState(secss);
  const [togglevalue, settogglevalue] = useState(false);
  const [sectionStatus, setSectionStatus] = useState(false);
  const [sectionSubmitstate, setSectionSubmitstate] = useState(false);
  //   const [userProfile] = useCookies();
  const [paperid, setPaperid] = useState(pids);
  const [option, setOption] = useState();
  const [testType, setTestType] = useState("sectional");
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState(languages);
  const [subject, setSubject] = useState(subjectID);
  const [subjectData, setSubjectData] = useState([]);
  const [attempted, setAttempted] = useState([]);
  const [unAttempted, setUnAttempted] = useState([]);
  const [marked, setMarked] = useState([]);
  const [AnswerStatus, setAnswerStatus] = useState([]);
  const [AnsweredCount, setAnsweredCount] = useState(Answered);
  const [NotAnsweredCount, setNotAnsweredCount] = useState(NotAnswered);
  const [MarkedCount, setMarkedCount] = useState(Marked);
  const [MarkedAnsweredCount, setMarkedAnsweredCount] = useState(
    MarkedAnswered
  );
  const [NotVisitedCount, setNotVisitedCount] = useState(NotVisited);
  const [paper_ids, setPaper_ids] = useState();
  const [SubjectName, setSubjectName] = useState(subject_name);
  const [SingleTm, setSingleTm] = useState();
  const [sectionValue, setSectionValue] = useState(section);
  const [sectionCurrentValue, setSectionCurrentValue] = useState(section);
  const [show, setShow] = useState(false);
  const [pause, setPause] = useState(false);
  const [show2, setShow2] = useState(false);
  const [lastshow, setlastshow] = useState(false);
  const [sectionshow, setsectionshow] = useState(false);
  const [rmTm, setrmTm] = useState();
  const [eidData, setEidData] = useState();
  const [PauseDetails, setPauseDetails] = useState([]);
  const [show_paper, setshow_paper] = useState(false);
  const [showQuestions, setshowQuestions] = useState(true);
  const [instructionShow, setInstructionShow] = useState(false);
  const [Next, setNext] = useState("Next");
  const [buttonType, setButtonType] = useState();
  const navigate = useNavigate();
  const handle = useFullScreenHandle();
  const [theArray, setTheArray] = useState([]);
  const [FirstLetter, setFirstLetter] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [choosed_answer, setchoosed_answer] = useState();
  const [PrevValue, setPrevValue] = useState();
  const [CurrentPaperId, setCurrentPaperId] = useState();
  const [questionNavId, setQuestionNavId] = useState();
  const [lastQuestion, setLastQuestion] = useState();
  //const [sectionTimeEnd, setSectionTimeEnd] = useState(false)
  const tier_id = exam_post_tier;

  const userProfile = useSelector((state) => state.userProfile);
  const { token } = useAuthContext();

  //   if (userProfile.email_id) {
  //     history.pushState(null, null, location.href);
  //     window.onpopstate = function (event) {
  //       history.go(1);
  //     };
  //   }

  const activeThreeoutline = (event) => {
    setIsActive((current) => !current);
  };

  const GoToFirstFun = () => {
    setlastshow(false);
    setPaperid(1);
    setSubject(subjectID);
    setOption(choosed_answer);
  };

  const checkChslTier2FullTest = () => {
    if (exam_post_tier == "11") {
      return true;
    }
    return false;
  };

  // console.log("checkChslTier2FullTest " + checkChslTier2FullTest());

  const GoToFirstSection = async () => {
    console.log("go to first section call");
    setsectionshow(false);
    setSectionSubmitstate(false);

    let paper_details = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
      section: sectionCurrentValue,
      rTem: rmTm,
    };
    let answers_result = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/sectionSubmit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(paper_details),
      }
    );
    answers_result = await answers_result.json();
    console.log("ANSWERS_RESULT", answers_result);

    setSectionStatus("true");

    if (sectionValue == 2) {
      setPaperid(61);
      setSubject(7);
    }

    if (sectionValue == 3) {
      setPaperid(131);
      setSubject(31);
      if (checkChslTier2FullTest()) {
        setPaperid(121);
      }
    }

    console.log("sectionValue", sectionValue);

    let paper_code_details = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      section: sectionValue,
      paper_id: paperid,
    };

    let paper_code_res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/paperCodeDetailsSection",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(paper_code_details),
      }
    );

    paper_code_res = await paper_code_res.json();
    console.log("PAPER CODE DETAILS SECTION", paper_code_res);

    hrss = paper_code_res.hrs;
    minss = paper_code_res.mins;
    secss = paper_code_res.secs;

    setHoursMinSecs({ hours: hrss, minutes: minss, seconds: secss });
    setHrs(hrss);
    setMins(minss);
    setSecs(secss);

    let rTem =
      hrs.toString().padStart(2, "0") +
      ":" +
      mins.toString().padStart(2, "0") +
      ":" +
      secs.toString().padStart(2, "0");

    rmTimeFun(rTem);

    let answers = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      test_series_id: test_series_id,
      exam_mode_id: exam_mode_id,
      paper_id: paperid,
      CurrentPaperId: paperid,
      option: "",
      answer_status: 2,
      SingleTm: SingleTm,
      rTem: rmTm,
      subject: subject,
      section: sectionCurrentValue,
    };

    console.log("ANSWER IN GO_TO_FIRST_SECTION", answers);

    let answers_res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/save_nextsection",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(answers),
      }
    );
    answers_res = await answers_res.json();
    console.log("ANSWERS_RES", answers_res);

    setOption(answers_res.choosed_answer);

    setAnsweredCount(0);
    setNotAnsweredCount(0);
    setMarkedCount(0);
    setMarkedAnsweredCount(0);
    setNotVisitedCount(0);

    if (sectionCurrentValue < 3) {
      setSectionCurrentValue(sectionValue);
    } else {
      setSectionCurrentValue(sectionCurrentValue);
      submitTest();
    }
  };

  const GoToNextSection = async () => {
    // console.log("i got called");
    setsectionshow(false);
    setSectionSubmitstate(false);
    let sectValue = parseInt(sectionCurrentValue) + 1;
    console.log("SECTION VALUE", sectionValue);
    let paper_details = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
      section: sectionCurrentValue,
      rTem: rmTm,
    };
    let answers_result = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/sectionSubmit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(paper_details),
      }
    );
    answers_result = await answers_result.json();

    setSectionStatus("true");

    if (sectionCurrentValue == 1) {
      setPaperid(61);
      setSubject(7);
    }

    if (sectionCurrentValue == 2) {
      setPaperid(131);
      setSubject(31);
      if (checkChslTier2FullTest()) {
        setPaperid(121);
      }
    }

    let paper_code_details = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      section: sectValue,
    };

    let paper_code_res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/paperCodeDetailsSection",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(paper_code_details),
      }
    );

    paper_code_res = await paper_code_res.json();

    console.log("paper_code_res: ", paper_code_res);

    hrss = paper_code_res.hrs;
    minss = paper_code_res.mins;
    secss = paper_code_res.secs;

    setHoursMinSecs({ hours: hrss, minutes: minss, seconds: secss });
    setHrs(hrss);
    setMins(minss);
    setSecs(secss);

    let rTem =
      hrs.toString().padStart(2, "0") +
      ":" +
      mins.toString().padStart(2, "0") +
      ":" +
      secs.toString().padStart(2, "0");

    rmTimeFun(rTem);

    let answers = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      test_series_id: test_series_id,
      exam_mode_id: exam_mode_id,
      paper_id: paperid,
      CurrentPaperId: paperid,
      option: option,
      answer_status: 2,
      SingleTm: SingleTm,
      rTem: rmTm,
      subject: subject,
      section: sectionCurrentValue,
    };

    let answers_res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/save_nextsection",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(answers),
      }
    );
    answers_res = await answers_res.json();
    setOption(answers_res.choosed_answer);

    setAnsweredCount(0);
    setNotAnsweredCount(0);
    setMarkedCount(0);
    setMarkedAnsweredCount(0);
    setNotVisitedCount(0);

    if (sectionCurrentValue < 3) {
      setSectionCurrentValue(parseInt(sectionCurrentValue) + 1);
      setSectionValue(parseInt(sectionCurrentValue) + 1);
    } else {
      setSectionCurrentValue(sectionCurrentValue);
      submitTest();
    }
    // console.log("check base url", process.env.REACT_APP_PINNACLE_BASE_URL);
  };

  const handleNoFirst = () => {
    setlastshow(false);
  };

  const handleNoSection = () => {
    setsectionshow(false);
  };

  const handleClose = async (e) => {
    // console.log("HandleClose", "i got called");
    setButtonType(e.target.value);
    setShow(false);
    setPause(!pause);
    setShow2(true);
    let paper_details = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
    };
    let answers_result = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/answerDetailsSection",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(paper_details),
      }
    );
    answers_result = await answers_result.json();
    console.log("ANSWER_RESULT", answers_result);
    setPauseDetails(answers_result);
  };

  const submitSection = async (e) => {
    // console.log("submitsection i got called");
    setsectionshow(true);
    let section_details = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
      section: sectionCurrentValue,
    };
    let section_result = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/sectionSummary",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(section_details),
      }
    );
    section_result = await section_result.json();

    setAttempted(section_result.attempted);
    setUnAttempted(section_result.unattempted);
    setMarked(section_result.marked);
    if (sectionCurrentValue < 3) {
      setSectionValue(parseInt(sectionCurrentValue) + 1);
    } else {
      setSectionValue(parseInt(sectionCurrentValue));
    }
  };

  const handleClose2 = () => {
    setShow2(false);
    setPause(!pause);
  };

  // const handleCloseTest = async () => {
  //   let pause_details = {
  //     email_id: userProfile.email_id,
  //     paper_code: paper_code,
  //     exam_mode_id: exam_mode_id,
  //     test_series_id: test_series_id,
  //     rTem: rmTm,
  //     pause_question: paperid,
  //     section: sectionValue,
  //   };
  //   await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/pauseSectionTest", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify(pause_details),
  //   });
  //   navigate(`/testseries/${userProfile.email_id}`);
  // };

  const handleCloseTest = async () => {
    // console.log("handleCloseTest", "i got called");
    let pause_details = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
      rTem: rmTm,
      pause_question: paperid,
      section: sectionValue,
    };
    await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/pauseSectionTest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pause_details),
    });
    // navigate(`/testseries/${userProfile.email_id}`);
    navigate(
      `/testseriessingle/${exam_id}/${post_id}/${tier_id}/${product_id}`
    );
  };

  const handleCloseNo = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const submitTest = async () => {
    if (sectionCurrentValue < 4) {
      let paper_details = {
        email_id: userProfile.email_id,
        paper_code: paper_code,
        exam_mode_id: exam_mode_id,
        test_series_id: test_series_id,
        section: sectionCurrentValue,
        rTem: rmTm,
      };
      let answers_result = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/sectionSubmit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(paper_details),
        }
      );
      answers_result = await answers_result.json();
      console.log("ANSWER_RESULT", answers_result);
    }

    let submit_details = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
      rTem: rmTm,
    };
    let submit_result = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/submitSectionTest",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(submit_details),
      }
    );
    submit_result = await submit_result.json();
    console.log("SUBMIT_RESULT", submit_result);
    if (submit_result.status == "success") {
      if (TestTp == "live") {
        navigate(
          `/LiveTestResult/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
            test_series_id
          )}`
        );
      } else {
        navigate(
          `/attemptedsection/${btoa(exam_mode_id)}/${btoa(paper_code)}/${btoa(
            test_series_id
          )}/${title}/${tier_id}/${exam_id}/${post_id}/${product_id}`
        );
      }
    }
  };

  const toggle = () => {
    if (!togglevalue) settogglevalue(true);
    else settogglevalue(false);
  };

  const increment = async (e) => {
    console.log("i got called");
    let y = paperid - 1;
    console.log("PAPER ID", paperid);
    console.log("Y", y);
    console.log("DATA", data);
    if (data[y].answer == "0" && option == "0") {
      data[y].answer = "0";
    } else if (option == "0" && data[y].answer != "0") {
      // Do nothing, if this case is valid to skip
    } else {
      data[y].answer = option;
    }

    // data[y].answer == "0" && option == "0"
    //   ? (data[y].answer = "0")
    //   : option == "0" && data[y].answer != "0"
    //   ? ""
    //   : (data[y].answer = option);

    let data_length;

    console.log("OPTION", option);

    setQuestionNavId(paperid);

    if (sectionCurrentValue == 1) {
      data_length = 60;
    } else if (sectionCurrentValue == 2) {
      data_length = 130;
      if (checkChslTier2FullTest()) {
        data_length = 120;
      }
    } else if (sectionCurrentValue == 3) {
      data_length = 150;
      if (checkChslTier2FullTest()) {
        data_length = 135;
      }
    }

    if (parseInt(paperid) == data_length) {
      await answerFun(e.target.id, parseInt(paperid), "nxt");
      setsectionshow(true);
      let section_details = {
        email_id: userProfile.email_id,
        paper_code: paper_code,
        exam_mode_id: exam_mode_id,
        test_series_id: test_series_id,
        section: sectionCurrentValue,
      };
      let section_result = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/sectionSummary",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(section_details),
        }
      );
      section_result = await section_result.json();

      setAttempted(section_result.attempted);
      setUnAttempted(section_result.unattempted);
      setMarked(section_result.marked);
      if (sectionCurrentValue < 3) {
        setNext("Next");
        answerFun(e.target.id, parseInt(paperid), "nxt");
        console.log("Answer Function", answerFun(e.target.id, paperid, "nxt"));
        setSectionValue(parseInt(sectionCurrentValue) + 1);
        if (
          e.target.id == 1 &&
          (data[y].answer == "0" ||
            data[y].answer == "" ||
            data[y].answer == undefined) &&
          (option == "" ||
            option == undefined ||
            option == 0 ||
            option == "undefined")
        ) {
          setPrevValue("2");
          const obj = {
            item_no: paperid,
            Type: 2,
            SingleTm: SingleTm,
            option: option,
          };
          setTheArray([...theArray, obj]);
        } else if (
          e.target.id == 1 &&
          (data[y].answer != "0" || option != "0") &&
          (data[y].answer != "" || option != "") &&
          (data[y].answer != "" || option != "0")
        ) {
          setPrevValue("1");
          const obj = {
            item_no: paperid,
            Type: 1,
            SingleTm: SingleTm,
            option: option,
          };
          setTheArray([...theArray, obj]);
        } else if (
          e.target.id == 4 &&
          data[y].answer == "0" &&
          (option == "0" || option == "")
        ) {
          setPrevValue("3");
          const obj = {
            item_no: paperid,
            Type: 3,
            SingleTm: SingleTm,
            option: option,
          };
          setTheArray([...theArray, obj]);
        } else if (
          e.target.id == 4 &&
          (data[y].answer != "0" || option != "0" || option == "")
        ) {
          setPrevValue("4");
          const obj = {
            item_no: paperid,
            Type: 4,
            SingleTm: SingleTm,
            option: option,
          };
          setTheArray([...theArray, obj]);
        } else {
          setPrevValue("2");
        }
      } else {
        setSectionValue(parseInt(sectionCurrentValue));
        setNext("Next");
        answerFun(e.target.id, parseInt(paperid), "nxt");
      }
    } else {
      setPaperid(parseInt(paperid) + 1);
      setOption("0");
      setLastQuestion(parseInt(paperid) + 1);
      if (
        e.target.id == 1 &&
        (data[y].answer == "0" ||
          data[y].answer == "" ||
          data[y].answer == undefined) &&
        (option == "" ||
          option == undefined ||
          option == 0 ||
          option == "undefined")
      ) {
        setPrevValue("2");
        const obj = {
          item_no: paperid,
          Type: 2,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("the Array", theArray);
      } else if (
        e.target.id == 1 &&
        (data[y].answer != "0" || option != "0") &&
        (data[y].answer != "" || option != "") &&
        (data[y].answer != "" || option != "0")
      ) {
        setPrevValue("1");
        const obj = {
          item_no: paperid,
          Type: 1,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("the Array", theArray);
      } else if (
        e.target.id == 4 &&
        data[y].answer == "0" &&
        (option == "0" || option == "")
      ) {
        setPrevValue("3");
        const obj = {
          item_no: paperid,
          Type: 3,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("the Array", theArray);
      } else if (
        e.target.id == 4 &&
        (data[y].answer != "0" || option != "0" || option == "")
      ) {
        setPrevValue("4");
        const obj = {
          item_no: paperid,
          Type: 4,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("the Array", theArray);
      } else {
        setPrevValue("2");
      }
      setchoosed_answer("");
      answerFun(e.target.id, parseInt(paperid) + 1, "nxt");
      setNext("Next");
    }

    setSectionStatus("false");
  };

  const BlurAnswerValue = (prevAnswerValue) => {
    console.log("PREVIOUS ANSWER VALUE", prevAnswerValue);
    console.log("Type of prevAnswerValue:", typeof prevAnswerValue);

    setPrevValue(prevAnswerValue);
  };

  const questionNavigationFun = (e) => {
    setPaperid(e.target.value);
    console.log("Question Navigation value", e.target.value);
    console.log(" Question Navigation Fun PAPERID", paperid);

    let z = e.target.value - 1;
    // setOption(data[z].answer);
    console.log(data[z]);
    console.log("OPTIon", data[z].answer);
    setCurrentPaperId(e.target.value);

    if (questionNavId == e.target.value) {
    } else {
      setQuestionNavId(e.target.value);
      setOption(data[z].answer ? data[z].answer : "");

      if (
        typeof PrevValue === "string" &&
        PrevValue !== "1" &&
        PrevValue !== "2" &&
        PrevValue !== "3" &&
        PrevValue !== "4"
      ) {
        console.log("PrevValue", PrevValue);
        var PrevStatus = PrevValue.split(",").pop();
        console.log("PrevStatus", PrevStatus);
      } else {
        var PrevStatus = PrevValue;
      }

      // if (
      //   PrevValue != undefined ||
      //   PrevValue != "1" ||
      //   PrevValue != "2" ||
      //   PrevValue != "3" ||
      //   PrevValue != "4"
      // ) {
      //   var PrevStatus = PrevValue.split(",").pop();
      // } else {
      //   var PrevStatus = PrevValue;
      // }

      if (PrevStatus == undefined || PrevStatus == "0") {
        answerFun("2", e.target.value, "nav");
      } else if (PrevStatus == 1) {
        answerFun("1", e.target.value, "nav");
      } else if (PrevStatus == 2) {
        answerFun("2", e.target.value, "nav");
      } else if (PrevStatus == 3) {
        answerFun("3", e.target.value, "nav");
      } else if (PrevStatus == 4) {
        answerFun("4", e.target.value, "nav");
      }

      // if (PrevStatus == undefined || PrevStatus == "0") {
      //   answerFun("2", e.target.value, "nav");
      // } else if (PrevStatus == 1) {
      //   answerFun("1", e.target.value, "nav");
      // } else if (PrevStatus == 2) {
      //   answerFun("2", e.target.value, "nav");
      // } else if (PrevStatus == 3) {
      //   answerFun("3", e.target.value, "nav");
      // } else if (PrevStatus == 4) {
      //   answerFun("4", e.target.value, "nav");
      // }

      if (PrevStatus == undefined || PrevStatus == "0") {
        const obj = {
          item_no: paperid,
          Type: 2,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("QUESTION NAVIGATION", theArray);
      } else if (
        PrevStatus == 1 && lastQuestion != undefined
          ? data[lastQuestion - 1].answer != "0" &&
            data[lastQuestion - 1].answer != "" &&
            data[lastQuestion - 1].answer != undefined
          : PrevStatus == 1
      ) {
        const obj = {
          item_no: paperid,
          Type: 1,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("QUESTION NAVIGATION", theArray);
      } else if (
        PrevStatus == 2 && lastQuestion != undefined
          ? data[lastQuestion - 1].answer == "0" ||
            data[lastQuestion - 1].answer == ""
          : PrevStatus == 2
      ) {
        const obj = {
          item_no: paperid,
          Type: 2,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("QUESTION NAVIGATION", theArray);
      } else if (PrevStatus == 3) {
        const obj = {
          item_no: paperid,
          Type: 2,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("QUESTION NAVIGATION", theArray);
      } else if (PrevValue == 4) {
        const obj = {
          item_no: paperid,
          Type: 2,
          SingleTm: SingleTm,
          option: option,
        };
        setTheArray([...theArray, obj]);
        console.log("QUESTION NAVIGATION", theArray);
      }
      setIsActive(false);
    }

    setLastQuestion(e.target.value);
  };

  const answerFun = async (answer_status, CurrentPaperId, SaveType) => {
    let z = paperid - 1;
    console.log("ANSWER_STATUS", answer_status);
    console.log("CURRENTPAPERID", CurrentPaperId);
    console.log("PAPERID", paperid);
    console.log("z", z);

    let answers = {
      email_id: userProfile.email_id,
      paper_code: paper_code,
      test_series_id: test_series_id,
      exam_mode_id: exam_mode_id,
      paper_id: paperid,
      CurrentPaperId: CurrentPaperId,
      option:
        option == "0" && data[z].answer != "0"
          ? data[z].answer
          : option == undefined
          ? ""
          : option != "0"
          ? option
          : "",
      answer_status: answer_status,
      SingleTm: SingleTm,
      rTem: rmTm,
      subject: subject,
      section: sectionCurrentValue,
      SaveType: SaveType,
    };

    console.log("ANSWERS", answers);

    let answers_res = await fetch(
      process.env.REACT_APP_PINNACLE_BASE_URL + "/save_nextsection",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(answers),
      }
    );
    answers_res = await answers_res.json();
    console.log("ANSWER_COMING_RES", answers_res);
    setSubject(answers_res.sub_id);
    setSubjectName(answers_res.subjectname);
    setAnswerStatus(answers_res.answer_status);
    setPrevValue("" + answers_res.answer_status_new);
    setAnsweredCount(answers_res.answered_count);
    setNotAnsweredCount(answers_res.notanswered_count);
    setMarkedCount(answers_res.marked_count);
    setMarkedAnsweredCount(answers_res.marked_answered_count);
    setNotVisitedCount(answers_res.not_visited);
    setPaper_ids(answers_res.paper_ids);
  };

  const chooseFun = (e) => {
    setOption(e.target.value);
  };

  const resetRadioState = async () => {
    setOption("");

    let c = paperid - 1;
    data[c].answer = "";
    setPrevValue("2");
    data[c].answered_ques = "2";

    const obj = {
      item_no: paperid,
      Type: 2,
      SingleTm: SingleTm,
      option: option,
    };
    setTheArray([...theArray, obj]);

    let cleardt = {
      paper_code: paper_code,
      email_id: userProfile.email_id,
      exam_mode_id: exam_mode_id,
      test_series_id: test_series_id,
      paper_id: paperid,
    };
    await fetch(process.env.REACT_APP_PINNACLE_BASE_URL + "/clearResponse", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(cleardt),
    });
  };

  const testTypeFun = (e) => {
    const testType = e.target.value;
    setTestType(testType);
  };

  useEffect(() => {
    async function fetchdata() {
      let dt = {
        paper_code: paper_code,
        email_id: userProfile.email_id,
        exam_mode_id: exam_mode_id,
        test_series_id: test_series_id,
      };
      let state_res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/indexSection",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(dt),
        }
      );
      state_res = await state_res.json();
      setData(state_res[0]["details"]);
      console.log("State_res", state_res);
      // console.log("Details", state_res[0]["details"]);

      setSubjectData(state_res[0]["subjects"]);
      // console.log("subject Data", state_res[0]["subjects"]);
      setLoading(false);
    }
    fetchdata();
  }, []);

  useEffect(() => {
    async function paperdata() {
      // console.log("PAUSEQUESTIONCHOOSEANSWER", "i got called");
      let dt = {
        paper_code: paper_code,
        email_id: userProfile.email_id,
        exam_mode_id: exam_mode_id,
        test_series_id: test_series_id,
        question_no: paperid,
      };
      let state_res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/pauseQuestionChoosedAnswerSection",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(dt),
        }
      );
      state_res = await state_res.json();
      setchoosed_answer(state_res.choosed_answer);
      setOption(state_res.choosed_answer);
      setPrevValue(state_res.answer_status);
      setAnswerStatus(state_res.answer_status);
      setQuestionNavId(paperid);
    }
    paperdata();
  }, []);

  useEffect(() => {
    async function eid() {
      let eid = [{ email_id: userProfile.email_id }];
      let eid_req = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/testName",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(eid),
        }
      );
      eid_req = await eid_req.json();
      setEidData(eid_req[0]["full_name"]);
      setFirstLetter(eid_req[0]["full_name"].charAt(0));
    }
    eid();
  }, []);

  const languageFun = (e) => {
    setLanguage(e.target.value);
    setNext("Lg");
    // answerFun("2");
  };

  const subjectFun = async (e) => {
    if (e.target.value > sectionCurrentValue) {
      setSubjectName(e.target.getAttribute("data-id"));
      console.log("SUBJECTNAME", e.target.getAttribute("data-id"));

      setSectionValue(e.target.value);

      setsectionshow(true);

      let section_details = {
        email_id: userProfile.email_id,
        paper_code: paper_code,
        exam_mode_id: exam_mode_id,
        test_series_id: test_series_id,
        section: sectionCurrentValue,
        status: 1,
      };
      let section_result = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/sectionSummary",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(section_details),
        }
      );
      section_result = await section_result.json();

      setAttempted(section_result.attempted);
      setUnAttempted(section_result.unattempted);
      setMarked(section_result.marked);
    } else {
    }
  };

  const sTimeFun = (sTm) => {
    setSingleTm(sTm);
  };

  const subjectFn = (gh) => {
    console.log(gh);
  };

  const rmTimeFun = (rTm) => {
    setrmTm(rTm);
  };

  const sectionS = (stat) => {
    setSectionStatus(stat);
  };

  const sectionSubmitstat = (stt) => {
    setSectionSubmitstate(stt);
  };

  const questionPaperFun = () => {
    if (!show_paper) {
      setshow_paper(true);
      setshowQuestions(false);
      setInstructionShow(false);
    } else {
      setshow_paper(false);
      setshowQuestions(true);
      setInstructionShow(false);
    }
  };

  const instructionFun = () => {
    if (!instructionShow) {
      setshow_paper(false);
      setshowQuestions(false);
      setInstructionShow(true);
    } else {
      setshow_paper(false);
      setshowQuestions(true);
      setInstructionShow(false);
    }
  };

  if (sectionSubmitstate == true && sectionCurrentValue == 1) {
    console.log(
      `GoToNextSection1: sectionSubmitstate: ${sectionSubmitstate}, sectionCurrentValue: ${sectionCurrentValue}`
    );
    GoToNextSection();
  } else if (sectionSubmitstate == true && sectionCurrentValue == 2) {
    // console.log(3n);
    console.log(
      `GoToNextSection2: sectionSubmitstate: ${sectionSubmitstate}, sectionCurrentValue: ${sectionCurrentValue}`
    );
    GoToNextSection();
  } else if (rmTm == "00:00:00" && sectionCurrentValue == 3) {
    submitTest();
  }

  //if(sectionTimeEnd == true){
  //setsectionshow(true)
  //}

  return (
    <React.Fragment>
      <FullScreen handle={handle}>
        {isLoading ? (
          <h3 style={{ textAlign: "center" }}>
            <img src={logo} alt="loading..." />
          </h3>
        ) : (
          <Row className="main-container">
            <Row className="header1 mobile-box-shadow-none mobile-padding0 mobile-height-14vh">
              <Col
                md={1}
                className="mobile-padding0 mobile-box-shadow80808042"
                style={{ padding: "0" }}
              >
                <h2>
                  <img
                    className="mobile-pinnacle-logo"
                    src="https://ssccglpinnacle.com/images/pinnacle_logo.png"
                    alt="logo"
                  />
                </h2>
              </Col>
              <Col md={5}>
                <h2 className="papercode-tile mobile-margin-top10 mobile-text-align-center">
                  {title}
                </h2>
              </Col>
              <Col md={3}>
                <h2 className="timer mobile-timer">
                  Time Left:{" "}
                  <CountDownTimerSection
                    hoursMinSecs={hoursMinSecs}
                    sectionSubmitstat={sectionSubmitstat}
                    sectionS={sectionS}
                    sectionState={sectionStatus}
                    paper_code={paper_code}
                    Paper_id={paperid}
                    pause={pause}
                    rmTimeFun={rmTimeFun}
                  />
                </h2>
              </Col>
              <Col
                md={2}
                className="mobile-display-none"
                style={{ paddingRight: "5px" }}
              >
                <button
                  className="full-screen"
                  onClick={handle.active == false ? handle.enter : handle.exit}
                >
                  Switch Full Screen
                </button>
              </Col>
              <Col
                md={1}
                className="mobile-display-none"
                style={{ paddingLeft: "0px" }}
              >
                {handle.active == true ? (
                  ""
                ) : (
                  <button className="pause" onClick={handleShow}>
                    Pause
                  </button>
                )}
              </Col>
            </Row>

            <Row className="q-sction mobile-q-sction">
              {togglevalue == true ? (
                <Col md={12} className="ques-row fullscreenmode">
                  <Row className="header2 mobile-header2">
                    <Col md={1}>
                      <h2 className="section">SECTIONS</h2>
                    </Col>
                    {testType && testType == "full" ? (
                      <Col md={9}>
                        <button className="subject active">Full Test</button>
                      </Col>
                    ) : (
                      <Col md={9} className="mobile-subject-scroll"></Col>
                    )}
                    <Col md={2}>
                      <span style={{ fontSize: "14px" }}>View In </span>
                      <select className="lang" onChange={languageFun}>
                        <option value="English">English</option>
                        <option value="Hindi">Hindi</option>
                      </select>
                    </Col>
                  </Row>
                  <Row className="questin-div">
                    <Col md={12} className="question-section">
                      {data &&
                        data
                          .filter((pid) => pid.qid == paperid)
                          .map((item) => {
                            return (
                              <QuestionsSection
                                key={item.qid}
                                question_id={item.question_id}
                                paper_code={paper_code}
                                chooseOption={chooseFun}
                                optionItem={option}
                                {...item}
                                language={language}
                                singleTm={sTimeFun}
                                pause={pause}
                                subject={subject}
                                Next={Next}
                                choosed_answer={item.answer}
                                theArray={theArray}
                              />
                            );
                          })}
                    </Col>
                  </Row>
                  <Row className="bottom-div fullscreenbottom">
                    <Col>
                      <button
                        className="mark-review"
                        id="4"
                        onClick={increment}
                      >
                        Mark for Review & Next
                      </button>
                      <button
                        className="clear-response"
                        onClick={resetRadioState}
                      >
                        Clear Response
                      </button>
                      <button className="save-next" id="1" onClick={increment}>
                        Save & Next
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <button
                      className="outer-buttton-full arrow"
                      onClick={toggle}
                    >
                      <FaAngleLeft />
                    </button>
                  </Row>
                </Col>
              ) : (
                showQuestions && (
                  <Col md={9} className="ques-row mobile-width100">
                    <Row className="header2 mobile-header2">
                      <Col md={1} className="mobile-display-none">
                        <h2 className="section">SECTIONS</h2>
                      </Col>
                      {testType && testType == "full" ? (
                        <Col md={9}>
                          <button className="subject active">Full Test</button>
                        </Col>
                      ) : (
                        <Col md={9} className="mobile-subject-scroll">
                          {subjectData &&
                            subjectData.map((sbj) => (
                              <button
                                className={`subject ${
                                  sbj.section_id == sectionCurrentValue
                                    ? "active"
                                    : ""
                                } mobile-subject`}
                                style={{ textTransform: "inherit" }}
                                value={sbj.section_id}
                                data-subject={sbj.subject_id}
                                data-paperid={sbj.ppr_id}
                                id={sbj.ppr_id}
                                data-id={sbj.subject_name}
                                key={sbj.ppr_id}
                                onClick={subjectFun}
                              >
                                {sbj.section}
                                {sbj.section_id == 1
                                  ? "(Math | Reasoning)"
                                  : sbj.section_id == 2
                                  ? "(English | GK)"
                                  : "(Computer Knowledge)"}
                              </button>
                            ))}
                        </Col>
                      )}
                      <Col md={2} className="mobile-display-none">
                        <span style={{ fontSize: "14px" }}>View In </span>
                        <select className="lang" onChange={languageFun}>
                          <option value="English">English</option>
                          <option value="Hindi">Hindi</option>
                        </select>
                      </Col>
                    </Row>
                    <Row className="questin-div">
                      <Col md={12} className="question-section">
                        {data &&
                          data
                            .filter((pid) => pid.qid == paperid)
                            .map((item) => {
                              return (
                                <QuestionsSection
                                  key={item.qid}
                                  question_id={item.question_id}
                                  paper_code={paper_code}
                                  chooseOption={chooseFun}
                                  optionItem={option}
                                  {...item}
                                  language={language}
                                  singleTm={sTimeFun}
                                  pause={pause}
                                  subject={subject}
                                  Next={Next}
                                  choosed_answer={item.answer}
                                  theArray={theArray}
                                />
                              );
                            })}
                      </Col>
                    </Row>
                    <Row className="bottom-div mobile-width100-perc">
                      <Col>
                        <button
                          className="mark-review mobile-font-size12 mobile-text-change"
                          id="4"
                          onClick={increment}
                        >
                          <span id="4">Mark for Review & Next</span>
                        </button>
                        <button
                          className="clear-response mobile-font-size12 mobile-text-change-clear"
                          onClick={resetRadioState}
                        >
                          <span onClick={resetRadioState}>Clear Response</span>
                        </button>
                        <button
                          className="save-next"
                          id="1"
                          onClick={increment}
                        >
                          Save & Next
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <button
                        className="outer-buttton mobile-display-none"
                        onClick={toggle}
                      >
                        <FaAngleRight />
                      </button>
                    </Row>
                  </Col>
                )
              )}

              <QinstructionSection
                data={data}
                show_paper={show_paper}
                instructionShow={instructionShow}
                questionPaperFun={questionPaperFun}
                instructionFun={instructionFun}
                language={language}
              />

              <button
                className="mobile-display-block mobile-three-outline desktop-display-none"
                onClick={activeThreeoutline}
              >
                <IoReorderThreeOutline />
              </button>

              {togglevalue == true ? (
                ""
              ) : (
                <Col
                  md={3}
                  className={
                    isActive
                      ? "nav-plate mobile-display-block mobile-nav-plate"
                      : "nav-plate mobile-display-none"
                  }
                >
                  <Row className="navigation_plate">
                    <Col className="plate">
                      <QuestionNavigationSection
                        questionNavigationfun={questionNavigationFun}
                        paper_code={paper_code}
                        section={sectionCurrentValue}
                        full_name={eidData}
                        FirstLetter={FirstLetter}
                        test_type={testTypeFun}
                        qno={data}
                        subject={sectionCurrentValue}
                        testType={testType}
                        AnswerStatus={AnswerStatus}
                        AnsweredCount={AnsweredCount}
                        NotAnsweredCount={NotAnsweredCount}
                        MarkedCount={MarkedCount}
                        MarkedAnsweredCount={MarkedAnsweredCount}
                        NotVisitedCount={NotVisitedCount}
                        paper_ids={paper_ids}
                        theArray={theArray}
                        BlurAnswerValue={BlurAnswerValue}
                      />

                      <Row className="submit-colum">
                        <Row className="instruction-column m-0 p-0">
                          <Col style={{ padding: "0" }}>
                            <button onClick={questionPaperFun}>
                              Question Paper
                            </button>
                            <button onClick={instructionFun}>
                              Instructions
                            </button>
                          </Col>
                        </Row>
                        <Row className="submit-test">
                          <Col md={6} style={{padding: "1px 2px 0 0" }}>
                            <button
                              onClick={submitSection}
                              value={sectionCurrentValue}
                            >
                              Submit Section {sectionCurrentValue}
                            </button>
                          </Col>
                          <Col md={6} style={{padding: "1px 0 0 2px" }}>
                            {handle.active == true ? (
                              ""
                            ) : (
                              <button onClick={handleClose} value="submit">
                                Submit Test
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Row>
        )}

        <Modal size="md" backdrop="static" show={show}>
          <Modal.Body className="are-you-sure">
            Are you sure you want to pause this test?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseNo}>
              No
            </Button>
            <Button
              variant="primary"
              className="pause-yes"
              onClick={handleClose}
              value="pause"
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" backdrop="static" show={show2} onHide={handleClose2}>
          <Modal.Body>
            <Table bordered>
              <thead className="model-thead">
                <tr>
                  <th>Section</th>
                  <th>No. of Questions</th>
                  <th>Answered</th>
                  <th>Not Answered</th>
                  <th>Marked for Review</th>
                  <th>Not Visited</th>
                </tr>
              </thead>
              <tbody className="model-tbody">
                {PauseDetails &&
                  PauseDetails.map((dt, i) => (
                    <tr key={i}>
                      <td>{dt.subject_name}</td>
                      <td>{dt.questions}</td>
                      <td>{dt.answered}</td>
                      <td>{dt.notanswered_count}</td>
                      <td>{dt.marked_answered_count}</td>
                      <td>{dt.not_visited}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
          {buttonType == "submit" || buttonType == "" ? (
            <Modal.Footer>
              <Button
                variant="secondary"
                className="go-to-submit-test"
                onClick={handleClose2}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className="resume-test"
                onClick={submitTest}
              >
                Submit
              </Button>
            </Modal.Footer>
          ) : (
            <Modal.Footer>
              <Button
                variant="secondary"
                className="go-to-test"
                onClick={handleCloseTest}
              >
                Tests
              </Button>
              <Button
                variant="primary"
                className="resume-test"
                onClick={handleClose2}
              >
                Resume Test
              </Button>
            </Modal.Footer>
          )}
        </Modal>

        <Modal size="md" backdrop="static" show={lastshow}>
          <Modal.Body className="are-you-sure" style={{ fontSize: "14px" }}>
            You have reached the last question of the exam. Do you want to go to
            the first question ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleNoFirst}>
              No
            </Button>
            <Button
              variant="primary"
              className="pause-yes"
              onClick={GoToFirstFun}
              value="pause"
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          backdrop="static"
          show={sectionshow}
          container={document.fullscreenElement || document.body}
        >
          <Modal.Header
            className="are-you-sure"
            style={{ fontSize: "14px", textAlign: "center", display: "block" }}
          >
            Summary of Section {sectionCurrentValue}
            {sectionCurrentValue == 1
              ? "(Math | Reasoning)"
              : sectionCurrentValue == 2
              ? "(English | GK)"
              : "(Computer Knowledge)"}
          </Modal.Header>
          <Modal.Body className="are-you-sure" style={{ fontSize: "14px" }}>
            <Row className="border-bottom1px padding13px0px">
              <Col md={8}>
                <span>Sectional Time Left:</span>
              </Col>
              <Col md={4} className="text-align-right">
                <span>{rmTm}</span>
              </Col>
            </Row>
            <Row className="border-bottom1px padding13px0px">
              <Col md={8}>
                <span>Attempted:</span>
              </Col>
              <Col md={4} className="text-align-right">
                <span>{attempted}</span>
              </Col>
            </Row>
            <Row className="border-bottom1px padding13px0px">
              <Col md={8}>
                <span>Unattempted:</span>
              </Col>
              <Col md={4} className="text-align-right">
                <span>{unAttempted}</span>
              </Col>
            </Row>
            <Row className="border-bottom1px padding13px0px">
              <Col md={8}>
                <span>Marked:</span>
              </Col>
              <Col md={4} className="text-align-right">
                <span>{marked}</span>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row style={{ width: "100%" }}>
              <Col md={12}>Are you sure you want to submit this section?</Col>
            </Row>
            <Button variant="secondary" onClick={handleNoSection}>
              No
            </Button>
            <Button
              variant="primary"
              className="pause-yes"
              section="section"
              onClick={GoToFirstSection}
              // onClick={GoToNextSection}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </FullScreen>
    </React.Fragment>
  );
}
export default Header1Section;
