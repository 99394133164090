import React, { useState, useEffect } from "react";
import Layout from "./Layout";
import { useParams } from "react-router-dom";
import axios from "axios";

const IndividualExamPostTierTestSeriesCard = () => {
  const [examPostTestSeriesData, setExamPostTestSeriesData] = useState(null);
  const { id } = useParams();
  console.log("IndividualExamPostTierTestSeriesCard", id);

  const fetchdata = async () => {
    try {
      const post_tier_id = Number(id);
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/postindividualexamposttiertestseries",
        {
          post_tier_id: post_tier_id,
        }
      );

      setExamPostTestSeriesData(response.data);
      console.log(response.data);
    } catch (error) {
      console.log("an internal server error");
    }
  };

  useEffect(() => {
    fetchdata();
  }, [id]);

  return (
    <>
      {examPostTestSeriesData &&
        examPostTestSeriesData.map((individualtestseriesdetail, index) => (
          <IndividualExamPostTierTestSeriesCard />
        ))}
    </>
  );
};

export default IndividualExamPostTierTestSeriesCard;
