// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocxReader2_imageFile__3QaHB {
    height: 150px;
    width: 150px;
    margin: 10px;
}

.DocxReader2_tox__0G4oA .DocxReader2_tox-editor-header__n90SM {
  display: grid;
  grid-template-columns: 1fr min-content;
  z-index: 0;
}



/* Editor component */

.DocxReader2_editorStyle__r7FKC {
    /* width: 100%; */
    /* border: 1px solid green; */
    /* border-radius: 4px; */
    /* background-color: #f5f5f5; */
    /* color: #333; */
    /* color: green; */
    /* padding: 10px; */
    margin: 20px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* transition: background-color 0.3s ease-in-out; */
    /* outline: none; */
  }

.DocxReader2_title__mU4KJ {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #333;
  }
  
.DocxReader2_showTextImage__oAQ3-{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.DocxReader2_spinner__nUzL8 {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: DocxReader2_spin__WbSwC 2s linear infinite;
}

@keyframes DocxReader2_spin__WbSwC {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/DocxReader2.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;EACE,aAAa;EACb,sCAAsC;EACtC,UAAU;AACZ;;;;AAIA,qBAAqB;;AAErB;IACI,iBAAiB;IACjB,6BAA6B;IAC7B,wBAAwB;IACxB,+BAA+B;IAC/B,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,8CAA8C;IAC9C,mDAAmD;IACnD,mBAAmB;EACrB;;AAEF;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;EACb;;AAEF;IACI,aAAa;IACb,qCAAqC;AACzC;;AAEA;EACE,0BAA0B,EAAE,eAAe;EAC3C,8BAA8B,EAAE,SAAS;EACzC,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,qDAAkC;AACpC;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".imageFile {\n    height: 150px;\n    width: 150px;\n    margin: 10px;\n}\n\n.tox .tox-editor-header {\n  display: grid;\n  grid-template-columns: 1fr min-content;\n  z-index: 0;\n}\n\n\n\n/* Editor component */\n\n.editorStyle {\n    /* width: 100%; */\n    /* border: 1px solid green; */\n    /* border-radius: 4px; */\n    /* background-color: #f5f5f5; */\n    /* color: #333; */\n    /* color: green; */\n    /* padding: 10px; */\n    margin: 20px;\n    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\n    /* transition: background-color 0.3s ease-in-out; */\n    /* outline: none; */\n  }\n\n.title {\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 16px;\n    color: #333;\n  }\n  \n.showTextImage{\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n}\n\n.spinner {\n  border: 16px solid #f3f3f3; /* Light grey */\n  border-top: 16px solid #3498db; /* Blue */\n  border-radius: 50%;\n  width: 120px;\n  height: 120px;\n  animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageFile": `DocxReader2_imageFile__3QaHB`,
	"tox": `DocxReader2_tox__0G4oA`,
	"tox-editor-header": `DocxReader2_tox-editor-header__n90SM`,
	"editorStyle": `DocxReader2_editorStyle__r7FKC`,
	"title": `DocxReader2_title__mU4KJ`,
	"showTextImage": `DocxReader2_showTextImage__oAQ3-`,
	"spinner": `DocxReader2_spinner__nUzL8`,
	"spin": `DocxReader2_spin__WbSwC`
};
export default ___CSS_LOADER_EXPORT___;
