// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import classes from "./UpdateStarteDate.module.css";
// import Cookies from "universal-cookie";

// function UpdateStartDate() {
//   const [paperCode, setPaperCode] = useState("");
//   const [startDate, setStartDate] = useState("");
//   const [startTime, setStartTime] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [endTime, setEndTime] = useState("");
//   const [responseData, setResponseData] = useState([]);

//   const cookies = new Cookies();

//   const token = cookies.get("token");

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const formData = new FormData();
//       formData.append("paperCode", paperCode);
//       formData.append("startDate", startDate);
//       formData.append("startTime", startTime);
//       formData.append("endDate", endDate);
//       formData.append("endTime", endTime);

//       // Reset form fields after successful submission
//       setPaperCode("");
//       setStartDate("");
//       setStartTime("");
//       setEndDate("");
//       setEndTime("");
//     } catch (error) {
//       // Handle error if API request fails
//       console.error("Error adding product:", error);
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           process.env.REACT_APP_PINNACLE_BASE_URL +
//             "/api/v1/admin/papercode/PaperCodeMasterTable",
//           {
//             headers: {
//               Authorization: `Bearer ${token} `,
//             },
//           }
//         );
//         if (response.status !== 200) {
//           throw new Error("Failed to fetch papers");
//         }

//         // If the request was successful, update the state with the response data
//         setResponseData(response.data);
//         console.log("responseData", response.data);
//       } catch (error) {
//         console.error("Error fetching papers:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <div className={classes.updated_heading}>
//         <h1 className={classes.update_heading_h1}>Update Start Date</h1>
//       </div>
//       <form className={classes.submit_form} onSubmit={handleSubmit}>
//         <label className={classes.paper_code_label}>Paper Code :</label>
//         <select
//           // type="text"
//           className={classes.select_paper_code}
//           value={paperCode}
//           onChange={(e) => setPaperCode(e.target.value)}
//           required
//         >
//           <option className={classes.paper_code_option}>Paper Code</option>
//           {responseData.map((paper, index) => (
//             <option key={index} value={paper.paper_code}>
//               {paper.paper_code}
//             </option>
//           ))}
//         </select>

//         <label className={classes.start_date_label}>
//           Start Date :
//           <input
//             className={classes.input_start_date}
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             required
//           />
//         </label>
//         <label className={classes.start_time_label}>
//           Start Time :
//           <input
//             className={classes.input_start_time}
//             type="time"
//             value={startTime}
//             onChange={(e) => setStartTime(e.target.value)}
//             required
//           />
//         </label>
//         <label className={classes.end_date_label}>
//           End Date :
//           <input
//             className={classes.input_end_date}
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             required
//           />
//         </label>
//         <label className={classes.end_time_label}>
//           End Time :
//           <input
//             className={classes.input_end_time}
//             type="time"
//             value={endTime}
//             onChange={(e) => setEndTime(e.target.value)}
//             required
//           />
//         </label>
//         <div className={classes.updated_btn}>
//           <button
//             className={classes.submit_btn_update_start_time_component}
//             type="submit"
//           >
//             Update
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default UpdateStartDate;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Multiselect } from "multiselect-react-dropdown";
// import Swal from "sweetalert2";
// import classes from "./UpdateStarteDate.module.css";
// import Cookies from "universal-cookie";

// function UpdateStartDate() {
//   const [selectedPaperCodes, setSelectedPaperCodes] = useState([]);
//   const [testTitle, setTestTitle] = useState("");
//   const [startDate, setStartDate] = useState("");
//   const [startTime, setStartTime] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [endTime, setEndTime] = useState("");
//   const [responseData, setResponseData] = useState([]);
//   const [loading, setLoading] = useState(true); // Loading state


//   const cookies = new Cookies();
//   const token = cookies.get("token");

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (selectedPaperCodes.length > 1) {
//       Swal.fire({
//         icon: "warning",
//         title: "Too Many Selections",
//         text: "Please select only one paper code!",
//       });
//       return; // Prevent further execution
//     }

//     try {
//       const requestData = {
//         paperCodes: selectedPaperCodes,
//         testTitle,
//         start_date: startDate,
//         start_time: startTime,
//         end_date: endDate,
//         end_time: endTime,
//       };

//       console.log(requestData);

//       const response = await axios.put(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/updatetest",
//         requestData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200) {
//         console.log("Update successful", response.data);
//         // Show success alert after successful submission
//         Swal.fire({
//           icon: "success",
//           title: "Update Successful",
//           text: "Paper details have been updated successfully!",
//         });
//       } else {
//         throw new Error("Failed to update paper details");
//       }

//       setSelectedPaperCodes([]);
//       setTestTitle("");
//       setStartDate("");
//       setStartTime("");
//       setEndDate("");
//       setEndTime("");
//     } catch (error) {
//       console.error("Error adding product:", error);
//     }
//   };

//   useEffect(() => {

//     const fetchData = async () => {
//       try {
//         // setLoading(true); // Show loading spinner
//         const response = await axios.get(
//           process.env.REACT_APP_PINNACLE_BASE_URL +
//             "/api/v1/admin/papercode/PaperCodeMasterTable",
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         if (response.status !== 200) {
//           throw new Error("Failed to fetch papers");
//         }

//         const validData = response.data.filter(
//           (item) => item.paper_code && typeof item.paper_code === "string"
//         );

//         setResponseData(validData);
//         console.log("responseData", validData);
//       } catch (error) {
//         console.error("Error fetching papers:", error);
//       } finally {
//                 setLoading(false)

//       }
//     };

//     fetchData();
//   }, [token]);

//   if (responseData.length === 0) {
//             // Show loading alert before data fetching
//     setLoading(true);
//     Swal.fire({
//       title: "Loading...",
//       text: "Fetching paper codes, please wait.",
//       allowOutsideClick: false,
//       didOpen: () => {
//         Swal.showLoading();
//       },
//     });
//   }

//   // Update form fields whenever selectedPaperCodes changes
//   useEffect(() => {
//     if (selectedPaperCodes.length > 0) {
//       const selectedPaper = responseData.find(
//         (item) => item.paper_code === selectedPaperCodes[0]
//       );

//       if (selectedPaper) {
//         setTestTitle(selectedPaper.test_title || "");
//         setStartDate(selectedPaper.start_date || "");
//         setStartTime(selectedPaper.start_time || "");
//         setEndDate(selectedPaper.end_date || "");
//         setEndTime(selectedPaper.end_time || "");
//       }
//     }
//   }, [selectedPaperCodes, responseData]);

//   const handleSelectedPaperCode = (selectedList) => {
//     setSelectedPaperCodes(selectedList.map((item) => item.paper_code));
//   };

//   return (
//     <div>
//       <div className={classes.updated_heading}>
//         <h1 className={classes.update_heading_h1}>Update Start Date</h1>
//       </div>
//       <form className={classes.submit_form} onSubmit={handleSubmit}>
//         <label className={classes.paper_code_label}>Paper Code :</label>
//         <Multiselect
//           options={responseData}
//           selectedValues={responseData.filter((item) =>
//             selectedPaperCodes.includes(item.paper_code)
//           )}
//           onSelect={handleSelectedPaperCode}
//           onRemove={(selectedList) => {
//             setSelectedPaperCodes(selectedList.map((item) => item.paper_code));
//           }}
//           displayValue="paper_code"
//           placeholder="Select Paper Code"
//           // singleSelect // Allow only single selection
//         />

//         <label className={classes.test_title_label}>
//           Test Title :
//           <input
//             className={classes.input_test_title}
//             type="text"
//             value={testTitle}
//             onChange={(e) => setTestTitle(e.target.value)}
//             required
//           />
//         </label>

//         <label className={classes.start_date_label}>
//           Start Date :
//           <input
//             className={classes.input_start_date}
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             required
//           />
//         </label>
//         <label className={classes.start_time_label}>
//           Start Time :
//           <input
//             className={classes.input_start_time}
//             type="time"
//             value={startTime}
//             onChange={(e) => setStartTime(e.target.value)}
//             required
//           />
//         </label>
//         <label className={classes.end_date_label}>
//           End Date :
//           <input
//             className={classes.input_end_date}
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             required
//           />
//         </label>
//         <label className={classes.end_time_label}>
//           End Time :
//           <input
//             className={classes.input_end_time}
//             type="time"
//             value={endTime}
//             onChange={(e) => setEndTime(e.target.value)}
//             required
//           />
//         </label>
//         <div className={classes.updated_btn}>
//           <button
//             className={classes.submit_btn_update_start_time_component}
//             type="submit"
//           >
//             Update
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default UpdateStartDate;




import React, { useState, useEffect } from "react";
import axios from "axios";
import { Multiselect } from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import classes from "./UpdateStarteDate.module.css";
import Cookies from "universal-cookie";

function UpdateStartDate() {
  const [selectedPaperCodes, setSelectedPaperCodes] = useState([]);
  const [testTitle, setTestTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state

  const cookies = new Cookies();
  const token = cookies.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedPaperCodes.length > 1) {
      Swal.fire({
        icon: "warning",
        title: "Too Many Selections",
        text: "Please select only one paper code!",
      });
      return; // Prevent further execution
    }

    try {
      const requestData = {
        paperCodes: selectedPaperCodes,
        testTitle,
        start_date: startDate,
        start_time: startTime,
        end_date: endDate,
        end_time: endTime,
      };

      console.log(requestData);

      const response = await axios.put(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/updatetest",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Update successful", response.data);
        // Show success alert after successful submission
        Swal.fire({
          icon: "success",
          title: "Update Successful",
          text: "Paper details have been updated successfully!",
        });

        // Clear the form after successful submission
        setSelectedPaperCodes([]);
        setTestTitle("");
        setStartDate("");
        setStartTime("");
        setEndDate("");
        setEndTime("");
      } else {
        throw new Error("Failed to update paper details");
      }
    } catch (error) {
      console.error("Error updating test:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue updating the paper details.",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loading spinner before making the request
      try {
        const response = await axios.get(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/admin/papercode/PaperCodeMasterTable",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status !== 200) {
          throw new Error("Failed to fetch paper codes");
        }

        const validData = response.data.filter(
          (item) => item.paper_code && typeof item.paper_code === "string"
        );

        setResponseData(validData);
        console.log("responseData", validData);
      } catch (error) {
        console.error("Error fetching paper codes:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch paper codes.",
        });
      } finally {
        setLoading(false); // Hide the loading spinner
      }
    };

    fetchData();
  }, [token]);

  // Ensure the loading state is managed correctly during fetching
  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: "Loading...",
        text: "Fetching paper codes, please wait.",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  }, [loading]);

  // Update form fields whenever selectedPaperCodes changes
  useEffect(() => {
    if (selectedPaperCodes.length > 0) {
      const selectedPaper = responseData.find(
        (item) => item.paper_code === selectedPaperCodes[0]
      );

      if (selectedPaper) {
        setTestTitle(selectedPaper.test_title || "");
        setStartDate(selectedPaper.start_date || "");
        setStartTime(selectedPaper.start_time || "");
        setEndDate(selectedPaper.end_date || "");
        setEndTime(selectedPaper.end_time || "");
      }
    }
  }, [selectedPaperCodes, responseData]);

  const handleSelectedPaperCode = (selectedList) => {
    setSelectedPaperCodes(selectedList.map((item) => item.paper_code));
  };

  return (
    <div>
      <div className={classes.updated_heading}>
        <h1 className={classes.update_heading_h1}>Update Start Date</h1>
      </div>
      <form className={classes.submit_form} onSubmit={handleSubmit}>
        <label className={classes.paper_code_label}>Paper Code :</label>
        <Multiselect
          options={responseData}
          selectedValues={responseData.filter((item) =>
            selectedPaperCodes.includes(item.paper_code)
          )}
          onSelect={handleSelectedPaperCode}
          onRemove={handleSelectedPaperCode}
          displayValue="paper_code"
          // singleSelect // Allow only single selection
          placeholder="Select Paper Code"
        />

        <label className={classes.test_title_label}>
          Test Title :
          <input
            className={classes.input_test_title}
            type="text"
            value={testTitle}
            onChange={(e) => setTestTitle(e.target.value)}
            required
          />
        </label>

        <label className={classes.start_date_label}>
          Start Date :
          <input
            className={classes.input_start_date}
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
          />
        </label>
        <label className={classes.start_time_label}>
          Start Time :
          <input
            className={classes.input_start_time}
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            required
          />
        </label>
        <label className={classes.end_date_label}>
          End Date :
          <input
            className={classes.input_end_date}
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </label>
        <label className={classes.end_time_label}>
          End Time :
          <input
            className={classes.input_end_time}
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            required
          />
        </label>
        <div className={classes.updated_btn}>
          <button
            className={classes.submit_btn_update_start_time_component}
            type="submit"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default UpdateStartDate;

