import Header1Section from "./Header1Section";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { useNavigate } from "react-router-dom"; // If you're using react-router

function TestSection() {
  const navigate = useNavigate();

  const cookies = useCookies();
  const handle = useFullScreenHandle();
  const {
    language,
    papercode,
    exam_mode_id,
    test_series_id,
    paperids,
    exam_id,
    product_id,
    post_id,
  } = useParams();
  const languages = atob(language);
  const paper_cod = atob(papercode);
  const exam_mod_id = atob(exam_mode_id);
  const test_seris_id = atob(test_series_id);
  const paper_id = atob(paperids);
  const [paperDetailPaperId, setPaperDetailPaperId] = useState();
  const [paper_code, setPaperCode] = useState();
  const [section, setSection] = useState();
  const [subjectID, setSubjectID] = useState("");
  const [hrss, setHours] = useState("");
  const [minss, setMinutes] = useState("");
  const [secss, setSeconds] = useState("");
  const [Answered, setAnsweredCount] = useState(0);
  const [NotAnswered, setNotAnsweredCount] = useState(0);
  const [Marked, setMarkedCount] = useState(0);
  const [MarkedAnswered, setMarkedAnsweredCount] = useState(0);
  const [NotVisited, setNotVisitedCount] = useState("");
  const [subject_name, setSubject_name] = useState("");
  const [title, setTitle] = useState();
  const [testType, setTestType] = useState();
  const [exam_post_tier, set_exam_post_tier] = useState();
  const userProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    async function PaperDetailsSection() {
      let papers = {
        paper_code: paper_cod,
        email_id: userProfile.email_id,
        paper_id: paper_id,
      };
      let papers_res = await fetch(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/paperCodeDetailsSection",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(papers),
        }
      );

      papers_res = await papers_res.json();
      console.log("papers_res", papers_res);
      setPaperDetailPaperId(papers_res.pid);

      setPaperCode(papers_res.paper_code);
      setSubjectID(papers_res.subject_id);
      setHours(papers_res.hrs);
      setMinutes(papers_res.mins);
      setSeconds(papers_res.secs);
      setSubject_name(papers_res.subject_name);
      setAnsweredCount(papers_res.answered_count);
      setNotAnsweredCount(papers_res.notanswered_count);
      setMarkedCount(papers_res.marked_count);
      setMarkedAnsweredCount(papers_res.marked_answered_count);
      setNotVisitedCount(papers_res.not_visited);
      setSection(papers_res.section);
      setTitle(papers_res.title);
      setTestType(papers_res.test_type);
      set_exam_post_tier(papers_res.post_tier_id);
    }
    PaperDetailsSection();
  }, [paper_cod]);

  useEffect(() => {
    // Push the current state into the history stack
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      // Whenever the back or forward button is pressed, we push the state again
      window.history.pushState(null, document.title, window.location.href);
    };

    // Listen for popstate event, which occurs when the back or forward buttons are clicked
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Cleanup event listener when the component unmounts
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <>
      <div className="App">
        {title && (
          <Header1Section
            hrss={hrss}
            minss={minss}
            test_series_id={test_seris_id}
            exam_mode_id={exam_mod_id}
            secss={secss}
            paper_code={paper_code}
            subjectID={subjectID}
            NotVisited={NotVisited}
            Answered={Answered}
            NotAnswered={NotAnswered}
            Marked={Marked}
            MarkedAnswered={MarkedAnswered}
            subject_name={subject_name}
            title={title}
            languages={languages}
            section={section}
            pids={paperDetailPaperId ? paperDetailPaperId : paper_id}
            TestTp={testType}
            exam_post_tier={exam_post_tier}
            exam_id={exam_id}
            post_id={post_id}
            product_id={product_id}
          />
        )}
      </div>
    </>
  );
}

export default TestSection;
