// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProductDetails.css */

/* .main_page {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: row;
} */
/* 
.content-testseries {
  display: flex;
  justify-content: center;
  margin-top: 20px;
} */

.ProductDetails_sidebar__Fmw24 {
  background-color: #f5f5f5;
  border-right: 1px solid #ddd;
}


/* .product-details {
  flex: 3;
  padding: 0.5rem;
  /* padding: 20px; */
/* background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
} */

/* Apply additional styles to the sidebar and product details section */

/* Apply additional styles as needed */
`, "",{"version":3,"sources":["webpack://./src/component/ProductDetails.module.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;;;;GAIG;AACH;;;;;GAKG;;AAEH;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;;;AAGA;;;qBAGqB;AACrB;;;;GAIG;;AAEH,uEAAuE;;AAEvE,sCAAsC","sourcesContent":["/* ProductDetails.css */\n\n/* .main_page {\n  font-family: Arial, sans-serif;\n  display: flex;\n  flex-direction: row;\n} */\n/* \n.content-testseries {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n} */\n\n.sidebar {\n  background-color: #f5f5f5;\n  border-right: 1px solid #ddd;\n}\n\n\n/* .product-details {\n  flex: 3;\n  padding: 0.5rem;\n  /* padding: 20px; */\n/* background-color: white;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n  overflow: hidden;\n} */\n\n/* Apply additional styles to the sidebar and product details section */\n\n/* Apply additional styles as needed */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `ProductDetails_sidebar__Fmw24`
};
export default ___CSS_LOADER_EXPORT___;
