import React from "react";
import classes from "./Navbar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
// import Sidebar from "./Sidebar.js";
// import './Navbar.css';

const Navbar = ({ toggleSidebar }) => {
  const handleToggleSidebar = () => {
    toggleSidebar();
  };

  return (
    <div className={classes.header}>
      <button className={classes.toggleButton} onClick={handleToggleSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <h1>Admin Dashboard</h1>
    </div>
  );
};

export default Navbar;
