// import { Row, Col } from "react-bootstrap";
// import CountUpSection from "./CountUpSection";
// import MathElement from "./MathMl";
// import MathJax from "react-mathjax-preview";
// import ReportModelSection from "./ReportModelSection";
// import { useMemo } from "react";

// function QuestionsSection({
//   question_id,
//   question,
//   qid,
//   chooseOption,
//   optionItem,
//   option1,
//   option2,
//   option3,
//   option4,
//   hindi_question,
//   hindi_option1,
//   hindi_option2,
//   hindi_option3,
//   hindi_option4,
//   language,
//   positive_marks,
//   negative_marks,
//   singleTm,
//   hrs,
//   mins,
//   secs,
//   pause,
//   Next,
//   subject,
//   paper_code,
//   choosed_answer,
//   theArray,
// }) {
//   let timememo = useMemo(() => {
//     return [...theArray].reverse().filter((svq) => svq.item_no == qid);
//   }, []);

//   if (timememo != "" && timememo != undefined) {
//     var optionVal = timememo[0]["option"];
//     if (optionVal == "0") {
//       var optionVal = choosed_answer;
//     }
//     if (timememo[0]["SingleTm"] != "" && timememo[0]["SingleTm"] != undefined) {
//       const [hr, mn, sc] = timememo[0]["SingleTm"].toString().split(":");
//       var MinSecs = {
//         hours: parseInt(hr),
//         minutes: parseInt(mn),
//         seconds: parseInt(sc),
//       };
//     }
//   } else {
//     var optionVal = choosed_answer;
//     var MinSecs = {
//       hours: parseInt(hrs),
//       minutes: parseInt(mins),
//       seconds: parseInt(secs),
//     };
//   }

//   var m,
//     urls = [],
//     optionurl1 = [],
//     optionurl2 = [],
//     optionurl3 = [],
//     optionurl4 = [],
//     rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;

//   while ((m = rex.exec(question))) {
//     urls.push(m[1]);
//   }

//   while ((m = rex.exec(option1))) {
//     optionurl1.push(m[1]);
//   }
//   while ((m = rex.exec(option2))) {
//     optionurl2.push(m[1]);
//   }
//   while ((m = rex.exec(option3))) {
//     optionurl3.push(m[1]);
//   }
//   while ((m = rex.exec(option4))) {
//     optionurl4.push(m[1]);
//   }

//   const singleTime = (stm) => {
//     singleTm(stm);
//   };

//   const mathtag = (
//     <span
//       class="MathJax"
//       id="MathJax-Element-1-Frame"
//       tabindex="0"
//       data-mathml='<math xmlns="http://www.w3.org/1998/Math/MathML"><msqrt><mi>x</mi><mo>+</mo><mn>2</mn><msqrt><mi>x</mi><mo>+</mo><mn>2</mn><msqrt><mi>x</mi><mo>+</mo><mn>2</mn><msqrt><mn>3</mn><mi>x</mi></msqrt></msqrt></msqrt></msqrt></math>'
//       role="presentation"
//       style="position: relative;"
//     >
//       <nobr aria-hidden="true">
//         <span
//           class="math"
//           id="MathJax-Span-1"
//           style="width: 14.169em; display: inline-block;"
//         >
//           <span style="display: inline-block; position: relative; width: 11.773em; height: 0px; font-size: 120%;">
//             <span style="position: absolute; clip: rect(0.419em, 1011.77em, 3.128em, -999.997em); top: -2.133em; left: 0em;">
//               <span class="mrow" id="MathJax-Span-2">
//                 <span class="msqrt" id="MathJax-Span-3">
//                   <span style="display: inline-block; position: relative; width: 11.773em; height: 0px;">
//                     <span style="position: absolute; clip: rect(2.607em, 1010.73em, 4.69em, -999.997em); top: -4.008em; left: 0.992em;">
//                       <span class="mrow" id="MathJax-Span-4">
//                         <span
//                           class="mi"
//                           id="MathJax-Span-5"
//                           style="font-family: MathJax_Math-italic;"
//                         >
//                           x
//                         </span>
//                         <span
//                           class="mo"
//                           id="MathJax-Span-6"
//                           style="font-family: MathJax_Main; padding-left: 0.211em;"
//                         >
//                           +
//                         </span>
//                         <span
//                           class="mn"
//                           id="MathJax-Span-7"
//                           style="font-family: MathJax_Main; padding-left: 0.211em;"
//                         >
//                           2
//                         </span>
//                         <span class="msqrt" id="MathJax-Span-8">
//                           <span style="display: inline-block; position: relative; width: 8.492em; height: 0px;">
//                             <span style="position: absolute; clip: rect(2.919em, 1007.5em, 4.43em, -999.997em); top: -4.008em; left: 0.992em;">
//                               <span class="mrow" id="MathJax-Span-9">
//                                 <span
//                                   class="mi"
//                                   id="MathJax-Span-10"
//                                   style="font-family: MathJax_Math-italic;"
//                                 >
//                                   x
//                                 </span>
//                                 <span
//                                   class="mo"
//                                   id="MathJax-Span-11"
//                                   style="font-family: MathJax_Main; padding-left: 0.211em;"
//                                 >
//                                   +
//                                 </span>
//                                 <span
//                                   class="mn"
//                                   id="MathJax-Span-12"
//                                   style="font-family: MathJax_Main; padding-left: 0.211em;"
//                                 >
//                                   2
//                                 </span>
//                                 <span class="msqrt" id="MathJax-Span-13">
//                                   <span style="display: inline-block; position: relative; width: 5.211em; height: 0px;">
//                                     <span style="position: absolute; clip: rect(3.023em, 1004.22em, 4.326em, -999.997em); top: -4.008em; left: 0.992em;">
//                                       <span class="mrow" id="MathJax-Span-14">
//                                         <span
//                                           class="mi"
//                                           id="MathJax-Span-15"
//                                           style="font-family: MathJax_Math-italic;"
//                                         >
//                                           x
//                                         </span>
//                                         <span
//                                           class="mo"
//                                           id="MathJax-Span-16"
//                                           style="font-family: MathJax_Main; padding-left: 0.211em;"
//                                         >
//                                           +
//                                         </span>
//                                         <span
//                                           class="mn"
//                                           id="MathJax-Span-17"
//                                           style="font-family: MathJax_Main; padding-left: 0.211em;"
//                                         >
//                                           2
//                                         </span>
//                                         <span
//                                           class="msqrt"
//                                           id="MathJax-Span-18"
//                                         >
//                                           <span style="display: inline-block; position: relative; width: 1.93em; height: 0px;">
//                                             <span style="position: absolute; clip: rect(3.18em, 1001.04em, 4.169em, -999.997em); top: -4.008em; left: 0.836em;">
//                                               <span
//                                                 class="mrow"
//                                                 id="MathJax-Span-19"
//                                               >
//                                                 <span
//                                                   class="mn"
//                                                   id="MathJax-Span-20"
//                                                   style="font-family: MathJax_Main;"
//                                                 >
//                                                   3
//                                                 </span>
//                                                 <span
//                                                   class="mi"
//                                                   id="MathJax-Span-21"
//                                                   style="font-family: MathJax_Math-italic;"
//                                                 >
//                                                   x
//                                                 </span>
//                                               </span>
//                                               <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                             </span>
//                                             <span style="position: absolute; clip: rect(3.596em, 1001.1em, 3.961em, -999.997em); top: -4.529em; left: 0.836em;">
//                                               <span style="display: inline-block; position: relative; width: 1.096em; height: 0px;">
//                                                 <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: -0.102em;">
//                                                   −
//                                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                                 </span>
//                                                 <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: 0.419em;">
//                                                   −
//                                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                                 </span>
//                                               </span>
//                                               <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                             </span>
//                                             <span style="position: absolute; clip: rect(3.076em, 1000.84em, 4.378em, -999.997em); top: -4.06em; left: 0em;">
//                                               <span style="font-family: MathJax_Main;">
//                                                 √
//                                               </span>
//                                               <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                             </span>
//                                           </span>
//                                         </span>
//                                       </span>
//                                       <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                     </span>
//                                     <span style="position: absolute; clip: rect(3.596em, 1004.17em, 3.961em, -999.997em); top: -4.685em; left: 0.992em;">
//                                       <span style="display: inline-block; position: relative; width: 4.169em; height: 0px;">
//                                         <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: -0.102em;">
//                                           −
//                                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                         </span>
//                                         <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: 3.44em;">
//                                           −
//                                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                         </span>
//                                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.419em;">
//                                           −
//                                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                         </span>
//                                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.94em;">
//                                           −
//                                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                         </span>
//                                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 1.461em;">
//                                           −
//                                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                         </span>
//                                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 1.982em;">
//                                           −
//                                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                         </span>
//                                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.503em;">
//                                           −
//                                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                         </span>
//                                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.023em;">
//                                           −
//                                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                         </span>
//                                       </span>
//                                       <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                     </span>
//                                     <span style="position: absolute; clip: rect(3.023em, 1001.04em, 4.534em, -999.997em); top: -4.112em; left: 0em;">
//                                       <span style="font-family: MathJax_Size1;">
//                                         √
//                                       </span>
//                                       <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                     </span>
//                                   </span>
//                                 </span>
//                               </span>
//                               <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                             </span>
//                             <span style="position: absolute; clip: rect(3.596em, 1007.4em, 3.961em, -999.997em); top: -4.945em; left: 0.992em;">
//                               <span style="display: inline-block; position: relative; width: 7.398em; height: 0px;">
//                                 <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: -0.102em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: 6.721em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.419em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.94em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 1.513em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.034em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.555em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.076em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.596em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 4.169em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 4.69em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 5.211em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 5.732em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                                 <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 6.253em;">
//                                   −
//                                   <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                                 </span>
//                               </span>
//                               <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                             </span>
//                             <span style="position: absolute; clip: rect(2.711em, 1001.04em, 4.794em, -999.997em); top: -4.112em; left: 0em;">
//                               <span style="font-family: MathJax_Size2;">√</span>
//                               <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                             </span>
//                           </span>
//                         </span>
//                       </span>
//                       <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                     </span>
//                     <span style="position: absolute; clip: rect(3.596em, 1010.68em, 3.961em, -999.997em); top: -5.258em; left: 0.992em;">
//                       <span style="display: inline-block; position: relative; width: 10.68em; height: 0px;">
//                         <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: -0.102em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: 10.003em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.419em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.94em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 1.513em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.034em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.555em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.128em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.648em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 4.169em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 4.742em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 5.263em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 5.784em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 6.305em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 6.878em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 7.398em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 7.919em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 8.492em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 9.013em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                         <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 9.534em;">
//                           −
//                           <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                         </span>
//                       </span>
//                       <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                     </span>
//                     <span style="position: absolute; clip: rect(2.398em, 1001.04em, 5.107em, -999.997em); top: -4.112em; left: 0em;">
//                       <span style="font-family: MathJax_Size3;">√</span>
//                       <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
//                     </span>
//                   </span>
//                 </span>
//               </span>
//               <span style="display: inline-block; width: 0px; height: 2.138em;"></span>
//             </span>
//           </span>
//           <span style="display: inline-block; overflow: hidden; vertical-align: -1.059em; border-left: 0px solid; width: 0px; height: 3.003em;"></span>
//         </span>
//       </nobr>
//       <span class="MJX_Assistive_MathML" role="presentation">
//         <math xmlns="http://www.w3.org/1998/Math/MathML">
//           <msqrt>
//             <mi>x</mi>
//             <mo>+</mo>
//             <mn>2</mn>
//             <msqrt>
//               <mi>x</mi>
//               <mo>+</mo>
//               <mn>2</mn>
//               <msqrt>
//                 <mi>x</mi>
//                 <mo>+</mo>
//                 <mn>2</mn>
//                 <msqrt>
//                   <mn>3</mn>
//                   <mi>x</mi>
//                 </msqrt>
//               </msqrt>
//             </msqrt>
//           </msqrt>
//         </math>
//       </span>
//     </span>
//   );

//   return (
//     <>
//       <Row className="question-row justify-content-center">
//         <Col
//           md={8}
//           className="mobile-width30-perc mobile-float-left text-center d-flex"
//         >
//           <span className="question-no mobile-font-size11 mobile-margin-top15">
//             Question No. {question_id}
//           </span>
//         </Col>
//         <Col
//           md={3}
//           className="mark-section mobile-width30-perc mobile-float-left text-center m-0 p-0"
//         >
//           <table
//             className="question-index-table table"
//             style={{ marginBottom: 0, borderBottom: "1px solid #ddd" }}
//           >
//             <thead className="thead-light">
//               <tr>
//                 <th className="align-middle text-center">Marks</th>
//                 <th className="align-middle text-center">Time</th>
//                 <th className="align-middle text-center">Report</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td className="align-middle text-center">
//                   <span className="positive-mark">+{positive_marks}</span>
//                   <span className="minus-mark">-{negative_marks}</span>
//                 </td>

//                 <td className="align-middle text-center">
//                   <span className="single-timer">
//                     <CountUpSection
//                       MinSecs={MinSecs}
//                       paper_code={paper_code}
//                       Paper_id={qid}
//                       singleTime={singleTime}
//                       pause={pause}
//                     />
//                   </span>
//                 </td>
//                 <td className="align-middle text-center">
//                   <ReportModelSection
//                     question_id={qid}
//                     paper_code={paper_code}
//                   />
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </Col>
//       </Row>

//       <Row className="question-with-option" key={qid}>
//         <Col>
//           <Row className="qusetion-option">
//             <Col className="ques">
//               {language === "Hindi" ? (
//                 <MathElement elements={hindi_question} />
//               ) : (
//                 <MathElement elements={question} />
//               )}
//               {/*
//               {Next == "Next" ? (
//                 language == "Hindi" && subject != 7 ? (
//                   <MathElement elements={hindi_question} />
//                 ) : (
//                   <MathElement elements={question} />
//                 )
//               ) : language == "Hindi" && subject != 7 ? (
//                 urls == "" ? (
//                   <MathJax math={hindi_question} />
//                 ) : (
//                   <MathElement elements={hindi_question} />
//                 )
//               ) : urls == "" ? (
//                 <MathJax math={question} />
//               ) : (
//                 <MathElement elements={question} />
//               )}
//                 */}
//             </Col>
//           </Row>
//           <Row style={{ marginTop: "2%" }}>
//             <Col className="options">
//               <div>
//                 <label>
//                   <input
//                     type="radio"
//                     value="a"
//                     checked={
//                       optionItem != "0"
//                         ? optionItem == "a"
//                         : optionVal == "a"
//                         ? optionVal
//                         : optionItem == "a"
//                     }
//                     name="option"
//                     onChange={chooseOption}
//                   />
//                     {language === "Hindi" ? (
//                       <MathElement elements={hindi_option1} />
//                     ) : (
//                       <MathElement elements={option1} />
//                     )}
//                   {/*
//                   {Next == "Next" ? (
//                     language == "Hindi" && subject != 7 ? (
//                       <MathElement
//                         elements={hindi_option1.replace(/\&nbsp;/g, "")}
//                       />
//                     ) : (
//                       <MathElement elements={option1.replace(/\&nbsp;/g, "")} />
//                     )
//                   ) : language == "Hindi" && subject != 7 ? (
//                     optionurl1 == "" ? (
//                       <MathJax math={hindi_option1.replace(/\&nbsp;/g, "")} />
//                     ) : (
//                       <MathElement
//                         elements={hindi_option1.replace(/\&nbsp;/g, "")}
//                       />
//                     )
//                   ) : optionurl1 == "" ? (
//                     <MathJax math={option1.replace(/\&nbsp;/g, "")} />
//                   ) : (
//                     <MathElement elements={option1.replace(/\&nbsp;/g, "")} />
//                   )}
//                     */}
//                 </label>
//               </div>
//               <div>
//                 <label>
//                   <input
//                     type="radio"
//                     value="b"
//                     checked={
//                       optionItem != "0"
//                         ? optionItem == "b"
//                         : optionVal == "b"
//                         ? optionVal
//                         : optionItem == "b"
//                     }
//                     name="option"
//                     onChange={chooseOption}
//                   />
//                   {language === "Hindi" ? (
//                     <MathElement elements={hindi_option2} />
//                   ) : (
//                     <MathElement elements={option2} />
//                   )}
//                   {/*
//                   {Next == "Next" ? (
//                     language == "Hindi" && subject != 7 ? (
//                       <MathElement
//                         elements={hindi_option2.replace(/\&nbsp;/g, "")}
//                       />
//                     ) : (
//                       <MathElement elements={option2.replace(/\&nbsp;/g, "")} />
//                     )
//                   ) : language == "Hindi" && subject != 7 ? (
//                     optionurl2 == "" ? (
//                       <MathJax math={hindi_option2.replace(/\&nbsp;/g, "")} />
//                     ) : (
//                       <MathElement
//                         elements={hindi_option2.replace(/\&nbsp;/g, "")}
//                       />
//                     )
//                   ) : optionurl2 == "" ? (
//                     <MathJax math={option2.replace(/\&nbsp;/g, "")} />
//                   ) : (
//                     <MathElement elements={option2.replace(/\&nbsp;/g, "")} />
//                   )}
//                     */}
//                 </label>
//               </div>
//               <div>
//                 <label>
//                   <input
//                     type="radio"
//                     value="c"
//                     checked={
//                       optionItem != "0"
//                         ? optionItem == "c"
//                         : optionVal == "c"
//                         ? optionVal
//                         : optionItem == "c"
//                     }
//                     name="option"
//                     onChange={chooseOption}
//                   />
//                   {language === "Hindi" ? (
//                     <MathElement elements={hindi_option3} />
//                   ) : (
//                     <MathElement elements={option3} />
//                   )}
//                   {/*
//                   {Next == "Next" ? (
//                     language == "Hindi" && subject != 7 ? (
//                       <MathElement
//                         elements={hindi_option3.replace(/\&nbsp;/g, "")}
//                       />
//                     ) : (
//                       <MathElement elements={option3.replace(/\&nbsp;/g, "")} />
//                     )
//                   ) : language == "Hindi" && subject != 7 ? (
//                     optionurl3 == "" ? (
//                       <MathJax math={hindi_option3.replace(/\&nbsp;/g, "")} />
//                     ) : (
//                       <MathElement
//                         elements={hindi_option3.replace(/\&nbsp;/g, "")}
//                       />
//                     )
//                   ) : optionurl3 == "" ? (
//                     <MathJax math={option3.replace(/\&nbsp;/g, "")} />
//                   ) : (
//                     <MathElement elements={option3.replace(/\&nbsp;/g, "")} />
//                   )}
//                     */}
//                 </label>
//               </div>
//               <div>
//                 <label>
//                   <input
//                     type="radio"
//                     value="d"
//                     checked={
//                       optionItem != "0"
//                         ? optionItem == "d"
//                         : optionVal == "d"
//                         ? optionVal
//                         : optionItem == "d"
//                     }
//                     name="option"
//                     onChange={chooseOption}
//                   />
//                   {language === "Hindi" ? (
//                     <MathElement elements={hindi_option4} />
//                   ) : (
//                     <MathElement elements={option4} />
//                   )}
//                   {/*
//                   {Next == "Next" ? (
//                     language == "Hindi" && subject != 7 ? (
//                       <MathElement
//                         elements={hindi_option4.replace(/\&nbsp;/g, "")}
//                       />
//                     ) : (
//                       <MathElement elements={option4.replace(/\&nbsp;/g, "")} />
//                     )
//                   ) : language == "Hindi" && subject != 7 ? (
//                     optionurl4 == "" ? (
//                       <MathJax math={hindi_option4.replace(/\&nbsp;/g, "")} />
//                     ) : (
//                       <MathElement
//                         elements={hindi_option4.replace(/\&nbsp;/g, "")}
//                       />
//                     )
//                   ) : optionurl4 == "" ? (
//                     <MathJax math={option4.replace(/\&nbsp;/g, "")} />
//                   ) : (
//                     <MathElement elements={option4.replace(/\&nbsp;/g, "")} />
//                   )}
//                     */}
//                 </label>
//               </div>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </>
//   );
// }
// export default QuestionsSection;

import { Row, Col } from "react-bootstrap";
import CountUpSection from "./CountUpSection";
import MathElement from "./MathMl";
// import MathJax from "react-mathjax-preview";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import ReportModelSection from "./ReportModelSection";
import { useMemo } from "react";
import { render } from "react-dom";

function QuestionsSection({
  question_id,
  question,
  qid,
  chooseOption,
  optionItem,
  option1,
  option2,
  option3,
  option4,
  hindi_question,
  hindi_option1,
  hindi_option2,
  hindi_option3,
  hindi_option4,
  language,
  positive_marks,
  negative_marks,
  singleTm,
  hrs,
  mins,
  secs,
  pause,
  Next,
  subject,
  paper_code,
  choosed_answer,
  theArray,
}) {
  // Replace \n characters in question and options
  const replaceNewlines = (text) =>
    text
      ?.replace(/\\n/g, " ")
      .replace(/\n/g, " ")
      .replace(/\r/g, " ")
      .replace(/\\r/g, " ");
  // const replaceNewlines = (text) => text;

  const sanitizedQuestion = replaceNewlines(question);
  const sanitizedOption1 = replaceNewlines(option1);
  const sanitizedOption2 = replaceNewlines(option2);
  const sanitizedOption3 = replaceNewlines(option3);
  const sanitizedOption4 = replaceNewlines(option4);

  const sanitizedHindiQuestion = replaceNewlines(hindi_question);
  const sanitizedOption1Hindi = replaceNewlines(hindi_option1);
  const sanitizedOption2Hindi = replaceNewlines(hindi_option2);
  const sanitizedOption3Hindi = replaceNewlines(hindi_option3);
  const sanitizedOption4Hindi = replaceNewlines(hindi_option4);

  let timememo = useMemo(() => {
    return [...theArray].reverse().filter((svq) => svq.item_no == qid);
  }, []);

  if (timememo != "" && timememo != undefined) {
    var optionVal = timememo[0]["option"];
    if (optionVal == "0") {
      var optionVal = choosed_answer;
    }
    if (timememo[0]["SingleTm"] != "" && timememo[0]["SingleTm"] != undefined) {
      const [hr, mn, sc] = timememo[0]["SingleTm"].toString().split(":");
      var MinSecs = {
        hours: parseInt(hr),
        minutes: parseInt(mn),
        seconds: parseInt(sc),
      };
    }
  } else {
    var optionVal = choosed_answer;
    var MinSecs = {
      hours: parseInt(hrs),
      minutes: parseInt(mins),
      seconds: parseInt(secs),
    };
  }

  var m,
    urls = [],
    optionurl1 = [],
    optionurl2 = [],
    optionurl3 = [],
    optionurl4 = [],
    rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;

  while ((m = rex.exec(sanitizedQuestion))) {
    urls.push(m[1]);
  }

  while ((m = rex.exec(sanitizedOption1))) {
    optionurl1.push(m[1]);
  }
  while ((m = rex.exec(sanitizedOption2))) {
    optionurl2.push(m[1]);
  }
  while ((m = rex.exec(sanitizedOption3))) {
    optionurl3.push(m[1]);
  }
  while ((m = rex.exec(sanitizedOption4))) {
    optionurl4.push(m[1]);
  }

  const singleTime = (stm) => {
    singleTm(stm);
  };

  const config = {
    loader: { load: ["input/tex", "input/mml", "output/chtml"] }, // Load both TeX and MathML for MathJax
    tex: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
    },
  };

  const hasHtmlContent = (text) => /<[^>]+>/.test(text);

  const renderMathContent = (content) => {
    return hasHtmlContent(content) ? (
      // If content includes HTML, render with dangerouslySetInnerHTML for mixed content
      <div dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
      // If pure math content, render with MathJax
      <MathJax>{content}</MathJax>
    );
  };
  const mathtag = (
    <span
      class="MathJax"
      id="MathJax-Element-1-Frame"
      tabindex="0"
      data-mathml='<math xmlns="http://www.w3.org/1998/Math/MathML"><msqrt><mi>x</mi><mo>+</mo><mn>2</mn><msqrt><mi>x</mi><mo>+</mo><mn>2</mn><msqrt><mi>x</mi><mo>+</mo><mn>2</mn><msqrt><mn>3</mn><mi>x</mi></msqrt></msqrt></msqrt></msqrt></math>'
      role="presentation"
      style="position: relative;"
    >
      <nobr aria-hidden="true">
        <span
          class="math"
          id="MathJax-Span-1"
          style="width: 14.169em; display: inline-block;"
        >
          <span style="display: inline-block; position: relative; width: 11.773em; height: 0px; font-size: 120%;">
            <span style="position: absolute; clip: rect(0.419em, 1011.77em, 3.128em, -999.997em); top: -2.133em; left: 0em;">
              <span class="mrow" id="MathJax-Span-2">
                <span class="msqrt" id="MathJax-Span-3">
                  <span style="display: inline-block; position: relative; width: 11.773em; height: 0px;">
                    <span style="position: absolute; clip: rect(2.607em, 1010.73em, 4.69em, -999.997em); top: -4.008em; left: 0.992em;">
                      <span class="mrow" id="MathJax-Span-4">
                        <span
                          class="mi"
                          id="MathJax-Span-5"
                          style="font-family: MathJax_Math-italic;"
                        >
                          x
                        </span>
                        <span
                          class="mo"
                          id="MathJax-Span-6"
                          style="font-family: MathJax_Main; padding-left: 0.211em;"
                        >
                          +
                        </span>
                        <span
                          class="mn"
                          id="MathJax-Span-7"
                          style="font-family: MathJax_Main; padding-left: 0.211em;"
                        >
                          2
                        </span>
                        <span class="msqrt" id="MathJax-Span-8">
                          <span style="display: inline-block; position: relative; width: 8.492em; height: 0px;">
                            <span style="position: absolute; clip: rect(2.919em, 1007.5em, 4.43em, -999.997em); top: -4.008em; left: 0.992em;">
                              <span class="mrow" id="MathJax-Span-9">
                                <span
                                  class="mi"
                                  id="MathJax-Span-10"
                                  style="font-family: MathJax_Math-italic;"
                                >
                                  x
                                </span>
                                <span
                                  class="mo"
                                  id="MathJax-Span-11"
                                  style="font-family: MathJax_Main; padding-left: 0.211em;"
                                >
                                  +
                                </span>
                                <span
                                  class="mn"
                                  id="MathJax-Span-12"
                                  style="font-family: MathJax_Main; padding-left: 0.211em;"
                                >
                                  2
                                </span>
                                <span class="msqrt" id="MathJax-Span-13">
                                  <span style="display: inline-block; position: relative; width: 5.211em; height: 0px;">
                                    <span style="position: absolute; clip: rect(3.023em, 1004.22em, 4.326em, -999.997em); top: -4.008em; left: 0.992em;">
                                      <span class="mrow" id="MathJax-Span-14">
                                        <span
                                          class="mi"
                                          id="MathJax-Span-15"
                                          style="font-family: MathJax_Math-italic;"
                                        >
                                          x
                                        </span>
                                        <span
                                          class="mo"
                                          id="MathJax-Span-16"
                                          style="font-family: MathJax_Main; padding-left: 0.211em;"
                                        >
                                          +
                                        </span>
                                        <span
                                          class="mn"
                                          id="MathJax-Span-17"
                                          style="font-family: MathJax_Main; padding-left: 0.211em;"
                                        >
                                          2
                                        </span>
                                        <span
                                          class="msqrt"
                                          id="MathJax-Span-18"
                                        >
                                          <span style="display: inline-block; position: relative; width: 1.93em; height: 0px;">
                                            <span style="position: absolute; clip: rect(3.18em, 1001.04em, 4.169em, -999.997em); top: -4.008em; left: 0.836em;">
                                              <span
                                                class="mrow"
                                                id="MathJax-Span-19"
                                              >
                                                <span
                                                  class="mn"
                                                  id="MathJax-Span-20"
                                                  style="font-family: MathJax_Main;"
                                                >
                                                  3
                                                </span>
                                                <span
                                                  class="mi"
                                                  id="MathJax-Span-21"
                                                  style="font-family: MathJax_Math-italic;"
                                                >
                                                  x
                                                </span>
                                              </span>
                                              <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                            </span>
                                            <span style="position: absolute; clip: rect(3.596em, 1001.1em, 3.961em, -999.997em); top: -4.529em; left: 0.836em;">
                                              <span style="display: inline-block; position: relative; width: 1.096em; height: 0px;">
                                                <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: -0.102em;">
                                                  −
                                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                                </span>
                                                <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: 0.419em;">
                                                  −
                                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                                </span>
                                              </span>
                                              <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                            </span>
                                            <span style="position: absolute; clip: rect(3.076em, 1000.84em, 4.378em, -999.997em); top: -4.06em; left: 0em;">
                                              <span style="font-family: MathJax_Main;">
                                                √
                                              </span>
                                              <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                      <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                    </span>
                                    <span style="position: absolute; clip: rect(3.596em, 1004.17em, 3.961em, -999.997em); top: -4.685em; left: 0.992em;">
                                      <span style="display: inline-block; position: relative; width: 4.169em; height: 0px;">
                                        <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: -0.102em;">
                                          −
                                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                        </span>
                                        <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: 3.44em;">
                                          −
                                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                        </span>
                                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.419em;">
                                          −
                                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                        </span>
                                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.94em;">
                                          −
                                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                        </span>
                                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 1.461em;">
                                          −
                                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                        </span>
                                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 1.982em;">
                                          −
                                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                        </span>
                                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.503em;">
                                          −
                                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                        </span>
                                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.023em;">
                                          −
                                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                        </span>
                                      </span>
                                      <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                    </span>
                                    <span style="position: absolute; clip: rect(3.023em, 1001.04em, 4.534em, -999.997em); top: -4.112em; left: 0em;">
                                      <span style="font-family: MathJax_Size1;">
                                        √
                                      </span>
                                      <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                            </span>
                            <span style="position: absolute; clip: rect(3.596em, 1007.4em, 3.961em, -999.997em); top: -4.945em; left: 0.992em;">
                              <span style="display: inline-block; position: relative; width: 7.398em; height: 0px;">
                                <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: -0.102em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: 6.721em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.419em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.94em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 1.513em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.034em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.555em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.076em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.596em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 4.169em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 4.69em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 5.211em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 5.732em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                                <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 6.253em;">
                                  −
                                  <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                                </span>
                              </span>
                              <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                            </span>
                            <span style="position: absolute; clip: rect(2.711em, 1001.04em, 4.794em, -999.997em); top: -4.112em; left: 0em;">
                              <span style="font-family: MathJax_Size2;">√</span>
                              <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                    </span>
                    <span style="position: absolute; clip: rect(3.596em, 1010.68em, 3.961em, -999.997em); top: -5.258em; left: 0.992em;">
                      <span style="display: inline-block; position: relative; width: 10.68em; height: 0px;">
                        <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: -0.102em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="position: absolute; font-family: MathJax_Main; top: -4.008em; left: 10.003em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.419em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 0.94em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 1.513em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.034em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 2.555em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.128em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 3.648em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 4.169em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 4.742em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 5.263em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 5.784em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 6.305em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 6.878em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 7.398em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 7.919em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 8.492em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 9.013em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                        <span style="font-family: MathJax_Main; position: absolute; top: -4.008em; left: 9.534em;">
                          −
                          <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                        </span>
                      </span>
                      <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                    </span>
                    <span style="position: absolute; clip: rect(2.398em, 1001.04em, 5.107em, -999.997em); top: -4.112em; left: 0em;">
                      <span style="font-family: MathJax_Size3;">√</span>
                      <span style="display: inline-block; width: 0px; height: 4.013em;"></span>
                    </span>
                  </span>
                </span>
              </span>
              <span style="display: inline-block; width: 0px; height: 2.138em;"></span>
            </span>
          </span>
          <span style="display: inline-block; overflow: hidden; vertical-align: -1.059em; border-left: 0px solid; width: 0px; height: 3.003em;"></span>
        </span>
      </nobr>
      <span class="MJX_Assistive_MathML" role="presentation">
        <math xmlns="http://www.w3.org/1998/Math/MathML">
          <msqrt>
            <mi>x</mi>
            <mo>+</mo>
            <mn>2</mn>
            <msqrt>
              <mi>x</mi>
              <mo>+</mo>
              <mn>2</mn>
              <msqrt>
                <mi>x</mi>
                <mo>+</mo>
                <mn>2</mn>
                <msqrt>
                  <mn>3</mn>
                  <mi>x</mi>
                </msqrt>
              </msqrt>
            </msqrt>
          </msqrt>
        </math>
      </span>
    </span>
  );

  return (
    <>
      <MathJaxContext config={config}>
        <Row className="question-row justify-content-center">
          <Col
            md={8}
            className="mobile-width30-perc mobile-float-left text-center d-flex"
          >
            <span className="question-no mobile-font-size11 mobile-margin-top15">
              Question No. {question_id}
            </span>
          </Col>
          <Col
            md={3}
            className="mark-section mobile-width30-perc mobile-float-left text-center m-0 p-0"
          >
            <table
              className="question-index-table table"
              style={{ marginBottom: 0, borderBottom: "1px solid #ddd" }}
            >
              <thead className="thead-light">
                <tr>
                  <th className="align-middle text-center">Marks</th>
                  <th className="align-middle text-center">Time</th>
                  <th className="align-middle text-center">Report</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle text-center">
                    <span className="positive-mark">+{positive_marks}</span>
                    <span className="minus-mark">-{negative_marks}</span>
                  </td>

                  <td className="align-middle text-center">
                    <span className="single-timer">
                      <CountUpSection
                        MinSecs={MinSecs}
                        paper_code={paper_code}
                        Paper_id={qid}
                        singleTime={singleTime}
                        pause={pause}
                      />
                    </span>
                  </td>
                  <td className="align-middle text-center">
                    <ReportModelSection
                      question_id={qid}
                      paper_code={paper_code}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row className="question-with-option" key={qid}>
          <Col>
            <Row className="qusetion-option">
              <Col className="ques">
                {/* {language === "Hindi" ? (
                <MathElement elements={sanitizedHindiQuestion} />
              ) : (
                <MathElement elements={sanitizedQuestion} />
              )} */}
                {language === "Hindi"
                  ? renderMathContent(sanitizedHindiQuestion)
                  : renderMathContent(sanitizedQuestion)}
                {/*
              {Next == "Next" ? (
                language == "Hindi" && subject != 7 ? (
                  <MathElement elements={hindi_question} />
                ) : (
                  <MathElement elements={question} />
                )
              ) : language == "Hindi" && subject != 7 ? (
                urls == "" ? (
                  <MathJax math={hindi_question} />
                ) : (
                  <MathElement elements={hindi_question} />
                )
              ) : urls == "" ? (
                <MathJax math={question} />
              ) : (
                <MathElement elements={question} />
              )}
                */}
              </Col>
            </Row>
            <Row style={{ marginTop: "2%" }}>
              <Col className="options">
                <div>
                  <label>
                    <input
                      type="radio"
                      value="a"
                      checked={
                        optionItem != "0"
                          ? optionItem == "a"
                          : optionVal == "a"
                          ? optionVal
                          : optionItem == "a"
                      }
                      name="option"
                      onChange={chooseOption}
                    />
                    {/* {language === "Hindi" ? (
                    <MathElement elements={sanitizedOption1Hindi} />
                  ) : (
                    <MathElement elements={sanitizedOption1} />
                  )} */}
                    {language === "Hindi"
                      ? renderMathContent(sanitizedOption1Hindi)
                      : renderMathContent(sanitizedOption1)}
                    {/*
                  {Next == "Next" ? (
                    language == "Hindi" && subject != 7 ? (
                      <MathElement
                        elements={hindi_option1.replace(/\&nbsp;/g, "")}
                      />
                    ) : (
                      <MathElement elements={option1.replace(/\&nbsp;/g, "")} />
                    )
                  ) : language == "Hindi" && subject != 7 ? (
                    optionurl1 == "" ? (
                      <MathJax math={hindi_option1.replace(/\&nbsp;/g, "")} />
                    ) : (
                      <MathElement
                        elements={hindi_option1.replace(/\&nbsp;/g, "")}
                      />
                    )
                  ) : optionurl1 == "" ? (
                    <MathJax math={option1.replace(/\&nbsp;/g, "")} />
                  ) : (
                    <MathElement elements={option1.replace(/\&nbsp;/g, "")} />
                  )}
                    */}
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="b"
                      checked={
                        optionItem != "0"
                          ? optionItem == "b"
                          : optionVal == "b"
                          ? optionVal
                          : optionItem == "b"
                      }
                      name="option"
                      onChange={chooseOption}
                    />
                    {/* {language === "Hindi" ? (
                    <MathElement elements={sanitizedOption2Hindi} />
                  ) : (
                    <MathElement elements={sanitizedOption2} />
                  )} */}
                    {language === "Hindi"
                      ? renderMathContent(sanitizedOption2Hindi)
                      : renderMathContent(sanitizedOption2)}
                    {/*
                  {Next == "Next" ? (
                    language == "Hindi" && subject != 7 ? (
                      <MathElement
                        elements={hindi_option2.replace(/\&nbsp;/g, "")}
                      />
                    ) : (
                      <MathElement elements={option2.replace(/\&nbsp;/g, "")} />
                    )
                  ) : language == "Hindi" && subject != 7 ? (
                    optionurl2 == "" ? (
                      <MathJax math={hindi_option2.replace(/\&nbsp;/g, "")} />
                    ) : (
                      <MathElement
                        elements={hindi_option2.replace(/\&nbsp;/g, "")}
                      />
                    )
                  ) : optionurl2 == "" ? (
                    <MathJax math={option2.replace(/\&nbsp;/g, "")} />
                  ) : (
                    <MathElement elements={option2.replace(/\&nbsp;/g, "")} />
                  )}
                    */}
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="c"
                      checked={
                        optionItem != "0"
                          ? optionItem == "c"
                          : optionVal == "c"
                          ? optionVal
                          : optionItem == "c"
                      }
                      name="option"
                      onChange={chooseOption}
                    />
                    {/* {language === "Hindi" ? (
                    <MathElement elements={sanitizedOption3Hindi} />
                  ) : (
                    <MathElement elements={sanitizedOption3} />
                  )} */}
                    {language === "Hindi"
                      ? renderMathContent(sanitizedOption3Hindi)
                      : renderMathContent(sanitizedOption3)}
                    {/*
                  {Next == "Next" ? (
                    language == "Hindi" && subject != 7 ? (
                      <MathElement
                        elements={hindi_option3.replace(/\&nbsp;/g, "")}
                      />
                    ) : (
                      <MathElement elements={option3.replace(/\&nbsp;/g, "")} />
                    )
                  ) : language == "Hindi" && subject != 7 ? (
                    optionurl3 == "" ? (
                      <MathJax math={hindi_option3.replace(/\&nbsp;/g, "")} />
                    ) : (
                      <MathElement
                        elements={hindi_option3.replace(/\&nbsp;/g, "")}
                      />
                    )
                  ) : optionurl3 == "" ? (
                    <MathJax math={option3.replace(/\&nbsp;/g, "")} />
                  ) : (
                    <MathElement elements={option3.replace(/\&nbsp;/g, "")} />
                  )}
                    */}
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="d"
                      checked={
                        optionItem != "0"
                          ? optionItem == "d"
                          : optionVal == "d"
                          ? optionVal
                          : optionItem == "d"
                      }
                      name="option"
                      onChange={chooseOption}
                    />
                    {/* {language === "Hindi" ? (
                    <MathElement elements={sanitizedOption4Hindi} />
                  ) : (
                    <MathElement elements={sanitizedOption4} />
                  )} */}
                    {language === "Hindi"
                      ? renderMathContent(sanitizedOption4Hindi)
                      : renderMathContent(sanitizedOption4)}
                    {/*
                  {Next == "Next" ? (
                    language == "Hindi" && subject != 7 ? (
                      <MathElement
                        elements={hindi_option4.replace(/\&nbsp;/g, "")}
                      />
                    ) : (
                      <MathElement elements={option4.replace(/\&nbsp;/g, "")} />
                    )
                  ) : language == "Hindi" && subject != 7 ? (
                    optionurl4 == "" ? (
                      <MathJax math={hindi_option4.replace(/\&nbsp;/g, "")} />
                    ) : (
                      <MathElement
                        elements={hindi_option4.replace(/\&nbsp;/g, "")}
                      />
                    )
                  ) : optionurl4 == "" ? (
                    <MathJax math={option4.replace(/\&nbsp;/g, "")} />
                  ) : (
                    <MathElement elements={option4.replace(/\&nbsp;/g, "")} />
                  )}
                    */}
                  </label>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </MathJaxContext>
    </>
  );
}
export default QuestionsSection;
