// import React, { useEffect, useState } from "react";
// import "./ProductInfo.css";
// import axios from "axios";
// import { Row, Col } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
//import { load } from "@cashfreepayments/cashfree-js";
// import Box from "@mui/material/Box";
// import Slider from "@mui/material/Slider";
// import { load } from "@cashfreepayments/cashfree-js";
// import { ClassNames } from "@emotion/react";
// import classes from "./ProductInfo.module.css";
// import check from "../images/check.png";
// import Card from "./Card";
// import LatestCard from "./LatestCard";
// import SavedQuestionsCard from "./SavedQuestionCard";
// import DepthAnalysisCard from "./DepthAnalysisCard";
// import { IoIosArrowDropdown } from "react-icons/io";

// const faqData = [
//   {
//     question: "Can I attempt a test multiple times ?",
//     answer:
//       "A test can be attempted only once. However, you can refter to the Test Series e-book including the solutions as many times as you want",
//   },
//   {
//     question:
//       "Can I download the question paper with solutions after the test?",
//     answer:
//       "No, you cannot download the paper; however, you can access the paper and its solutions by logging into your account on the website or on the App.",
//   },
//   {
//     question: "Do you also provide solutions for the questions?",
//     answer:
//       "Yes, detailed solutions will be available to you after you finish the test. You will be able to access these solutions anytime after you have taken the test.",
//   },
//   {
//     question: "Will I get any report card after the test?",
//     answer:
//       "Yes, you will get a detailed analysis of your performance after submitting the test. You will also receive an email with your test report.",
//   },
//   {
//     question:
//       "Till when can I access the solutions and analysis of my attempted test?",
//     answer:
//       "To access solutions and analysis, you should have a valid Pass and the test should not have expired. Attempted Tests will be available for review till 15 days after the Test Expiry date.",
//   },
//   {
//     question:
//       "Will all of my attempted tests be available during the Pass validity period?",
//     answer:
//       "Yes, till your Pass is valid you can review attempted tests provided the test has not expired. The test expiry date is independent of the Testbook Pass expiry date. Attempted Tests are available for the next 15 days after the Test expiry date.",
//   },
//   {
//     question: "I am not able to access My Test, what should I do?",
//     answer:
//       "Mostly, your Testbook Pass has expired and you need to renew it. If that's not the case, please write to support@ssccglpinnacle.com.",
//   },
//   {
//     question:
//       "I am not able to see Tests for my desired exam. What should I do?",
//     answer:
//       "You can see all available test series for all exams in the All Tests tab. Please ensure you are in the right course (course dropdown beside the Pinnacle logo on the top left corner). You will find your Test in the My Tests tab under respective Exam tabs. All your attempted tests should be available in the Attempted Tests tab. If you are still not able to find your exam or tests, please write to support@ssccglpinnacle.com.",
//   },
//   {
//     question:
//       "I do not have a computer; can I appear for the test through my phone?",
//     answer:
//       "Yes, you may appear for the test through your phone. Simply download the PinnacleExamPreparationApp on your Android phone and take the tests there.",
//   },
//   {
//     question:
//       "What if my computer gets disconnected from the internet during the test?",
//     answer:
//       "No need to panic. We auto-pause your test and you can resume the test from where you left. You can also manually pause a test by clicking on the 'Pause' icon on the top left corner inside the timer circle. Please note that these functionalities are not available in a Live Test. Kindly ensure that you have an uninterrupted internet and power connection during the Live Tests.",
//   },
// ];

// const ProductInfo = () => {
//   const [testPassData, setTestPassData] = useState();
//   const [openListItem, setOpenListItem] = useState(Array(10).fill(false));

//   useEffect(() => console.log(" line 82 testPassData", testPassData), []);

// const marks = [
//   {
//     value: 90,
//   },
//   {
//     value: 180,
//   },
//   {
//     value: 365,
//   },
// ];

// function valuetext(value) {
//   console.log("valuetext" + value);
//   return `${value}°C`;
// }

// let cashfree;
// var initializeSDK = async function () {
//   cashfree = await load({
//     mode: "sandbox",
//   });
// };
// initializeSDK();

// const doPayment = async (id) => {
//   let checkoutOptions = {
//     paymentSessionId: id,
//     redirectTarget: "_self",
//   };
//   console.log(checkoutOptions);
//   cashfree.checkout(checkoutOptions);
// };

// const createOrder = async () => {
//   try {
//     let data = { email_id: "haridattbhatt0@gmail.com" };
//     let url = process.env.REACT_APP_PINNACLE_BASE_URL + "/payment";
//     console.log(url);
//     console.log(data);
//     const response = await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     });

//     console.log("Response Status:", response.status);
//     console.log("Response order Amount:", response.orderamount);

//     if (response.ok) {
//       const responseData = await response.json();
//       console.log(responseData);
//       console.log("JSON Data:" + responseData);
//       // console.log(responseData.status);
//       console.log("JSON Data: status " + responseData.status);
//       // console.log(responseData.message);
//       console.log("JSON Data: message " + responseData.message);
//       doPayment(responseData.message);
//     } else {
//       console.log("Response Error:");
//     }
//   } catch (err) {
//     console.log("Error fetching data:");
//     console.error(err);
//   }
// };

// const payMe = () => {
//   console.log("payme");
//   createOrder();
// };

// useEffect(() => {
//   const fetchTestPassData = async () => {
//     try {
//       const postData = {
//         selectExam: selectedExams,
//         selectedCategorie: selectedCategories,
//         selectedMode: selectedModes,
//       };

//       console.log(postData);

//       const response = await axios.post(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/testpass",
//         postData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       setTestPassData(response.data);
//     } catch (error) {
//       console.error("Error fetching test pass data:", error);
//     }
//   };

//   fetchTestPassData();
// }, [selectedExams, selectedCategories, selectedModes]);

// const generateBreadcrumb = () => {
//   let breadcrumb = "Home";
//   console.log(selectedExams);

//   const selectedItems = [];

//   if (selectedExams && selectedExams.length > 0) {
//     selectedItems.push(...selectedExams);
//   }

//   // if (selectedCategories && selectedCategories.length > 0) {
//   //   selectedItems.push(...selectedCategories);
//   // }

//   breadcrumb +=
//     selectedItems.length > 0 ? " > " + selectedItems.join(", ") : "";

//   return breadcrumb;
// };

// const handleOpenFrequentlyAsked = (index) => {
//   setOpenListItem((prevState) =>
//     prevState.map((item, idx) => (idx === index ? !item : item))
//   );
// };

// const handleChangeIndividualTestPass = () => {

// }

/* useEffect(() => {
    // Define an async function to fetch test pass data
    const fetchTestPassData = async () => {
      try {
          
        const postData = {        
          selectCategories: selectedCategories,
        }

        // Make the API request to add the product
        const response = await axios.post(process.env.REACT_APP_PINNACLE_BASE_URL + '/testpasscategory', postData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Set the fetched data in the state
        setTestPassData(response.data);
      } catch (error) {
        console.error('Error fetching test pass data:', error);
      }
    };

    // Call the fetchTestPassData function when the component mounts
    fetchTestPassData();
  }, []); */

// const testlist = () => {
//   navigate('/checkout');
// }
// console.log("process.env.REACT_APP_PINNACLE_BASE_URL", process.env.REACT_APP_PINNACLE_BASE_URL)

// const fetchData = async () => {
//   try {
//     const response = await axios.get(process.env.REACT_APP_PINNACLE_BASE_URL + "/testpass");
//     setTestPassData(response.data);
//     console.log("line 261", response.data);
//   } catch (error) {
//     console.log({ message: "an internal server error" });
//   }
// };

// console.log(testPassData);

// useEffect(() => {
//   fetchData();
// }, []);

// return (
//   <div className="product-info">
//     <p className="breadcrumb">{generateBreadcrumb()}</p>
//     {/* Conditionally render different content based on selectedExams and selectedCategories */}
//     {selectedExams && selectedCategories && (
//       <div className="ssc-content railway-content">
//         {/* Content for SSC and Railway exams */}
//         <h2>
//           {selectedExams == "" ? "All" : selectedExams.join(" and ")} Exams
//           Content
//         </h2>
//         <p>
//           This is content for{" "}
//           {selectedExams == "" ? "All" : selectedExams.join(" and ")} exams.
//         </p>
//         {/* Add your content here */}
//       </div>
//     )}

//     {selectedExams && selectedExams.includes("SSC") && (
//       <div className="ssc-content">
//         {/* Content for SSC */}
//         {/* ... previous SSC content */}
//       </div>
//     )}

//     {selectedExams && selectedExams.includes("Railway") && (
//       <div className="railway-content">
//         {/* Content for Railway */}
//         {/* ... previous Railway content */}
//       </div>
//     )}

//     {testPassData.map((testPass) => (
//       <div
//         className="testseries-testpass-div"
//         style={{ cursor: "pointer", float: "left", marginRight: "50px" }}
//         key={testPass._id}
//       >
//         <h4>{testPass.name}</h4>
//         <p>Exam: {testPass.exam}</p>
//         <p>Price: {sliderValue}</p>
//         <p>Expire Period: {sliderValue} days</p>
//         <Box sx={{ width: 300 }}>
//           <Slider
//             aria-label="Custom marks"
//             defaultValue={20}
//             value={sliderValue} // Pass state variable as value
//             onChange={handleSliderChange} // Pass onChange function
//             getAriaValueText={valuetext}
//             step={10}
//             valueLabelDisplay="auto"
//             marks={marks}
//           />
//         </Box>
//         <button className="testseries-buy-button" onClick={payMe}>
//           Buy Now
//         </button>
//       </div>
//     ))}

//     {/* Add more conditions for other exams or categories as needed */}
//   </div>
// );

// <>

//  <div className={classes.sub_section}>
//     <div className={classes.feature_description_section}>
//       <div className={classes.include_feature}>
//         <img className={classes.verified_image_section} src={check} />
//         <li>Also includes</li>
//       </div>
//       <div className={classes.include_feature}>
//         <img className={classes.verified_image_section} src={check} />
//         <li>All Test Series</li>
//       </div>
//       <div className={classes.include_feature}>
//         <img className={classes.verified_image_section} src={check} />
//         <li>Prev.Year Paper</li>
//       </div>
//       <div className={classes.include_feature}>
//         <img className={classes.verified_image_section} src={check} />
//         <li>Pratice</li>
//       </div>
//       <div className={classes.include_feature}>
//         <img className={classes.verified_image_section} src={check} />
//         <li>Pro live test</li>
//       </div>
//       <div className={classes.include_feature}>
//         <img className={classes.verified_image_section} src={check} />
//         <li>Unlimited Test Re-Attempts</li>
//       </div>
//     </div>
//   // </div>

//    <div className={classes.intro_section}>
//     <h4>Pinnacle</h4>
//     <div className={classes.advertisement_section}>
//       <p>Get ready for every exam with one pass</p>
//       <div>
//         <h5>Get Pass Pro</h5>
//       </div>
//     </div>
//     <div className={classes.sub_section}>
//       <ul className={classes.sub_section_list_item}>
//         <div className={classes.include_feature}>
//           <img className={classes.verified_image_section} src={check} />
//           <li>Also includes</li>
//         </div>
//         <div className={classes.feature_description_section}>
//           <li>
//             <img className={classes.verified_image_section} src={check} />
//             All Test Series
//           </li>
//           <li>
//             <img className={classes.verified_image_section} src={check} />
//             Prev.Year Paper
//           </li>
//           <li>
//             <img className={classes.verified_image_section} src={check} />
//             Pratice
//           </li>
//           <li>
//             <img className={classes.verified_image_section} src={check} />
//             Pro live test
//           </li>
//           <li>
//             <img className={classes.verified_image_section} src={check} />
//             Unlimited Test Re-Attempts
//           </li>
//         </div>
//       </ul>
//     </div>
//   </div>

// <div className={classes.test_series_category_section}>
//   <div className={classes.offer_up_to_main_div}>
//     <div className={classes.offer_heading_div}>
//       <h2>Pinnacle Test Pass </h2>
//       <p>One for <span>all</span></p>
//     </div>
//     <div className={classes.up_to_off_div}>
//       <h4>
//         Special Offer
//         <br />
//         <span className={classes.hurry_up_span}>Hurry Up!</span>
//       </h4>
//       <p>
//         <span className={classes.span_tag_1}>UPTO</span>
//         <span className={classes.span_tag_2}>75%</span>
//         <span className={classes.span_tag_3}>OFF</span> On All Test
//         Courses
//       </p>
//     </div>
//   </div>

//   <div className={classes.category_section_item}>
//     {testPassData &&
//       testPassData.map((passdata, index) => (
//         <Card key={index} passdata={passdata} />
//       ))}
//   </div>
// </div>

// <div className={classes.footer_section}>
//   <h2> Why Take Pinnacle Test Series</h2>
// </div>
// <div className={classes.category_section_item}>
//   <div className={classes.feature_sub_section}>
//     <LatestCard />
//   </div>
//   <div className={classes.feature_sub_section}>
//     <SavedQuestionsCard />
//   </div>
//   <div className={classes.feature_sub_section}>
//     <DepthAnalysisCard />
//   </div>
// </div>
// <div>
//   <div className={classes.frequently_asked_question}>
//     <ul className={classes.frequently_asked_unorder_list}>
//       {faqData.map((faq, index) => (
//         <li key={index} className={classes.frequently_asked_subsection}>
//           <div
//             className={
//               classes.frequently_asked_question_individually_list_item
//             }
//             onClick={() => handleOpenFrequentlyAsked(index)}
//           >
//             {faq.question} <IoIosArrowDropdown />
//           </div>
//           {openListItem[index] && <p>{faq.answer}</p>}
//         </li>
//       ))}
//     </ul>
//   </div>
// </div>
// </>
// );
// };

// export default ProductInfo;

// ****** new code chat gpt ********

import React, { useEffect, useState } from "react";
import axios from "axios";
import classes from "./ProductInfo.module.css";
import Card from "./Card";
import LatestCard from "./LatestCard";
import SavedQuestionsCard from "./SavedQuestionCard";
import DepthAnalysisCard from "./DepthAnalysisCard";
import { IoIosArrowDropdown } from "react-icons/io";
import Carousel from "./Carousel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const faqData = [
  {
    question: "Can I attempt a test multiple times ?",
    answer:
      "A test can be attempted only once. However, you can refer to the Test Series e-book including the solutions as many times as you want.",
  },
  {
    question:
      "Can I download the question paper with solutions after the test?",
    answer:
      "No, you cannot download the paper; however, you can access the paper and its solutions by logging into your account on the website or on the App.",
  },
  {
    question: "Do you also provide solutions for the questions?",
    answer:
      "Yes, detailed solutions will be available to you after you finish the test. You will be able to access these solutions anytime after you have taken the test.",
  },
  {
    question: "Will I get any report card after the test?",
    answer:
      "Yes, you will get a detailed analysis of your performance after submitting the test. You will also receive an email with your test report.",
  },
  {
    question:
      "Till when can I access the solutions and analysis of my attempted test?",
    answer:
      "To access solutions and analysis, you should have a valid Pass and the test should not have expired. Attempted Tests will be available for review till 15 days after the Test Expiry date.",
  },
  {
    question:
      "Will all of my attempted tests be available during the Pass validity period?",
    answer:
      "Yes, till your Pass is valid you can review attempted tests provided the test has not expired. The test expiry date is independent of the Testbook Pass expiry date. Attempted Tests are available for the next 15 days after the Test expiry date.",
  },
  {
    question: "I am not able to access My Test, what should I do?",
    answer:
      "Mostly, your Testbook Pass has expired and you need to renew it. If that's not the case, please write to support@ssccglpinnacle.com.",
  },
  {
    question:
      "I am not able to see Tests for my desired exam. What should I do?",
    answer:
      "You can see all available test series for all exams in the All Tests tab. Please ensure you are in the right course (course dropdown beside the Pinnacle logo on the top left corner). You will find your Test in the My Tests tab under respective Exam tabs. All your attempted tests should be available in the Attempted Tests tab. If you are still not able to find your exam or tests, please write to support@ssccglpinnacle.com.",
  },
  {
    question:
      "I do not have a computer; can I appear for the test through my phone?",
    answer:
      "Yes, you may appear for the test through your phone. Simply download the PinnacleExamPreparationApp on your Android phone and take the tests there.",
  },
  {
    question:
      "What if my computer gets disconnected from the internet during the test?",
    answer:
      "No need to panic. We auto-pause your test and you can resume the test from where you left. You can also manually pause a test by clicking on the 'Pause' icon on the top left corner inside the timer circle. Please note that these functionalities are not available in a Live Test. Kindly ensure that you have an uninterrupted internet and power connection during the Live Tests.",
  },
];

const ProductInfo = () => {
  const [testPassData, setTestPassData] = useState([]);
  const [openListItem, setOpenListItem] = useState(Array(10).fill(false));
  const [productData, setProductData] = useState(null);
  const [message, setMessage] = useState("");

  const userProfile = useSelector((state) => state.userProfile);
  const navigate = useNavigate();
  
  const fetchOrders = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/purchaseorders`,
        {
          email_id: userProfile.email_id,
        }
      );
      
      if (response.data.length === 0) {
        setMessage("You have not bought any product yet.");
      } else if (response.data.message) {
        setMessage(response.data.message);
      } else {
        setProductData(response.data);
      }
    } catch (error) {
      console.error("Error fetching purchase orders:", error);
    }
  };

  const fetchData = async () => {
    try {
      console.log("i got called");
      const response = await axios.get(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/testpass"
      );
      setTestPassData(response.data);
    } catch (error) {
      console.error("Error fetching test pass data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchOrders();
  }, []);

  const handleOpenFrequentlyAsked = (index) => {
    setOpenListItem((prevState) =>
      prevState.map((item, idx) => (idx === index ? !item : item))
    );
  };

  return (
    // <Layout>
    <>
      <div>
        <Carousel />
      </div>

      {/*<div className={classes.offer_up_to_main_div_one}>
        <div className={classes.offer_heading_div}>
          <h2>Pinnacle Test Pass </h2>
          <p>
            One for <span>all</span>
          </p>
        </div>
        <div className={classes.up_to_off_div}>
          <h4>
            Special Offer
            <br />
            <span className={classes.hurry_up_span}>Hurry Up!</span>
          </h4>
          <p>
            <span className={classes.span_tag_1}>UPTO</span>
            <span className={classes.span_tag_2}>75%</span>
            <span className={classes.span_tag_3}>OFF</span> On All Test
            Courses
          </p>
        </div>
      </div> */}

      <div className={classes.category_section_item}>
        {testPassData &&
          testPassData.map((passdata, index) => (
            <Card key={index} passdata={passdata} productData={productData} />
          ))}
      </div>

      <div className={classes.footer_section}>
        <h2> Why Take Pinnacle Test Series</h2>
      </div>
      <div className={classes.category_section_item}>
        <div className={classes.feature_sub_section}>
          <LatestCard />
        </div>
        <div className={classes.feature_sub_section}>
          <SavedQuestionsCard />
        </div>
        <div className={classes.feature_sub_section}>
          <DepthAnalysisCard />
        </div>
      </div>

      <div className={classes.frequently_asked_question}>
        <ul className={classes.frequently_asked_unorder_list}>
          {faqData.map((faq, index) => (
            <li key={index} className={classes.frequently_asked_subsection}>
              <div
                className={
                  classes.frequently_asked_question_individually_list_item
                }
                onClick={() => handleOpenFrequentlyAsked(index)}
              >
                {faq.question} <IoIosArrowDropdown />
              </div>
              {openListItem[index] && <p>{faq.answer}</p>}
            </li>
          ))}
        </ul>
      </div>
    </>
    /* </Layout> */
  );
};

export default ProductInfo;
