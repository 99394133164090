// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUsPage_about_us_page_div__Kln\\+l{
    /* margin-top: 8rem; */
}

.AboutUsPage_about_us_page_div__Kln\\+l h2{
    font-size: 26px;
    font-weight: bold;
    line-height: 4rem;
    margin: 8px;
}

.AboutUsPage_about_us_page_div__Kln\\+l h3{
    font-size: 22px;
    font-weight: bold;
    line-height: 4rem;
    margin: 8px;
}

.AboutUsPage_about_us_page_div__Kln\\+l p{
    font-size: 17px;
    font-weight: lighter;
    line-height: 2rem;
    margin: 8px;
}`, "",{"version":3,"sources":["webpack://./src/component/AboutUsPage.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".about_us_page_div{\n    /* margin-top: 8rem; */\n}\n\n.about_us_page_div h2{\n    font-size: 26px;\n    font-weight: bold;\n    line-height: 4rem;\n    margin: 8px;\n}\n\n.about_us_page_div h3{\n    font-size: 22px;\n    font-weight: bold;\n    line-height: 4rem;\n    margin: 8px;\n}\n\n.about_us_page_div p{\n    font-size: 17px;\n    font-weight: lighter;\n    line-height: 2rem;\n    margin: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about_us_page_div": `AboutUsPage_about_us_page_div__Kln+l`
};
export default ___CSS_LOADER_EXPORT___;
