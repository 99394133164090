// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 320px) and (max-width: 900px) {
  .mobile-three-outline {
    width: 46px;
    top: 40px;
    font-size: 20px;
  }
  .header1 img {
    margin-top: 0px;
  }
  .mobile-margin-top15 {
    margin-left: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/Header1Section.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,SAAS;IACT,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@media only screen and (min-width: 320px) and (max-width: 900px) {\n  .mobile-three-outline {\n    width: 46px;\n    top: 40px;\n    font-size: 20px;\n  }\n  .header1 img {\n    margin-top: 0px;\n  }\n  .mobile-margin-top15 {\n    margin-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
