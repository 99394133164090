// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import classes from "./Header.module.css";
// import { FaLanguage, FaBell, FaUser } from "react-icons/fa";
// import { CiSearch } from "react-icons/ci";
// import axios from "axios";

// const Header = () => {
//   const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
//   const [query, setQuery] = useState("");
//   const [results, setResults] = useState([]);
//   const navigate = useNavigate();

//   const handleChangeUserProfile = () => {
//     navigate("/home/useraccount");
//   };

//   const userMenuChange = () => {
//     setIsUserMenuVisible(!isUserMenuVisible);
//   };

//   const handleShowMoreTestChange = (id) => {
//     navigate(`/individualexamtestpasssection/${id}`);
//     console.log(id);
//   };

//   const handleSearch = async (e) => {
//     const searchQuery = e.target.value;
//     setQuery(searchQuery);

//     if (searchQuery.length) {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_PINNACLE_BASE_URL}/testpass?q=${searchQuery}`
//         );
//         setResults(response.data);
//         console.log(response.data);
//       } catch (error) {
//         console.error("Error fetching search results", error);
//       }
//     } else {
//       setResults([]);
//     }
//   };

//   // Filter results based on product title
//   const filteredResults = results.filter(result =>
//     result.Product_title.toLowerCase().includes(query.toLowerCase())
//   );

//   return (
//     <div className={classes.Container}>
//       <div className={classes.navbar_header_div}>
//         <div className={classes.search_input}>
//           <CiSearch />
//           <input
//             className={classes.search_input_input}
//             type="text"
//             value={query}
//             onChange={handleSearch}
//             placeholder="search"
//           />
//           {filteredResults.length > 0 && (
//             <div className={classes.results_dropdown}>
//               {filteredResults.map((result) => (
//                 <div
//                   key={result._id}
//                   className={classes.result_item}
//                   onClick={() => handleShowMoreTestChange(result.id)}
//                 >
//                   <div className={classes.result_item_icon}>
//                     <CiSearch />
//                   </div>
//                   {result.Product_title}
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//         <div className={classes.para_p}>
//           <p>Get Pass</p>
//         </div>
//         <div className={classes.all_icon_div}>
//           <div className={classes.icon_lang}>
//             <FaLanguage />
//           </div>
//           <div className={classes.icon_bell}>
//             <FaBell />
//           </div>
//           <div className={classes.icon_user} onClick={userMenuChange}>
//             <FaUser />
//             {isUserMenuVisible && (
//               <div className={classes.user_menu}>
//                 <button onClick={handleChangeUserProfile}>User Profile</button>
//                 {/* Add more menu items here */}
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;

// import React, { useState, useEffect } from "react";
// import UserProfileCard from "./UserProfileCard.js";
// import UserProfile from "./UserProfile.js";
// import { FcBusinessman } from "react-icons/fc";
// import { useNavigate, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
// import classes from "./Header.module.css";
// import { FaLanguage, FaBell, FaUser } from "react-icons/fa";
// import { CiSearch } from "react-icons/ci";
// import { LuSearch } from "react-icons/lu";
// import Logout from "./loginComponent/Logout";
// import axios from "axios";
// import pinnacle from "../images/download.png";
// import TestSeriesUnlockOverlay from "./TestSeriesUnlockOverlay.js";

// const Header = () => {
//   const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
//   const [query, setQuery] = useState("");
//   const [results, setResults] = useState([]);
//   const navigate = useNavigate();
//   const { product_id } = useParams();
//   const [isModelOpen, setIsModelOpen] = useState(false);

//   const [message, setMessage] = useState(null);

//   const handleShowMessage = (msg) => {
//     setMessage(msg);
//     console.log(msg);
//   };

//   const handleJoinTestPassChange = () => {
//     setIsModelOpen(!isModelOpen);
//   };

//   const handleClick = () => {
//     window.location.href = "https://ssccglpinnacle.com/";
//   };
//   const handleHomeClick = () => {
//     window.location.href = "https://testportal.ssccglpinnacle.com/";
//   };

//   const user = useSelector((state) => state.userProfile);
//   console.log("User:", user);

//   const handleChangeUserProfile = () => {
//     navigate("/home/useraccount");
//   };

//   const userMenuChange = () => {
//     setIsUserMenuVisible(!isUserMenuVisible);
//   };

//   const handleShowMoreTestChange = (id) => {
//     navigate(`/individualexamtestpasssection/${id}`);
//     console.log(id);
//   };

//   const handleSearch = async (e) => {
//     const searchQuery = e.target.value;
//     setQuery(searchQuery);

//     if (searchQuery.length) {
//       try {
//         const response1 = await axios.get(
//           `${process.env.REACT_APP_PINNACLE_BASE_URL}/testpass?q=${searchQuery}`
//         );

//         let combinedResults = [...response1.data];

//         if (product_id) {
//           const response2 = await axios.post(
//             `${process.env.REACT_APP_PINNACLE_BASE_URL}/individualexamtestpass`,
//             {
//               email_id: "harishmodi@ssccglpinnacle.com",
//               product_id: product_id,
//               q: searchQuery,
//             }
//           );
//           combinedResults = [...combinedResults, ...response2.data];
//         }

//         setResults(combinedResults);
//         console.log(combinedResults);
//       } catch (error) {
//         console.error("Error fetching search results", error);
//       }
//     } else {
//       setResults([]);
//     }
//   };

//   const filteredResults = results.filter(
//     (result) =>
//       result.Product_title?.toLowerCase().includes(query.toLowerCase()) ||
//       result.post_tier_name?.toLowerCase().includes(query.toLowerCase())
//   );

//   return (
//     <>
//       {isModelOpen && <TestSeriesUnlockOverlay id={132} />}
//       <div className={classes.container_fluid}>
//         <div className={classes.navbar_header_div}>
//           <div className={classes.heading_logo_div}>
//             <a
//               href="https://ssccglpinnacle.com/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img
//                 className={classes.pinnacle_logo}
//                 src={pinnacle}
//                 alt="Pinnacle Logo"
//                 onClick={handleClick}
//               />
//             </a>
//             <img className={classes.pinnacle_logo} src={pinnacle} />
//           </div>
//           <div className={classes.search_input}>
//             <input
//               className={classes.search_input_input}
//               type="text"
//               value={query}
//               onChange={handleSearch}
//               placeholder="search"
//             />
//             <div className={classes.searchicon_home}>
//               <LuSearch />
//             </div>
//             {filteredResults.length > 0 && (
//               <div className={classes.results_dropdown}>
//                 {filteredResults.map((result) => (
//                   <div
//                     key={result._id}
//                     className={classes.result_item}
//                     onClick={() => handleShowMoreTestChange(result.id)}
//                   >
//                     <div className={classes.result_item_icon}>
//                       <CiSearch />
//                     </div>
//                     {result.Product_title || result.post_tier_name}
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//           <div className={classes.para_p}>
//             <button
//               className={classes.test_pass_button}
//               onClick={handleJoinTestPassChange}
//             >
//               Get Pass
//             </button>
//             <button
//               className={classes.test_pass_button}
//               onClick={handleHomeClick}
//             >
//               Home
//             </button>
//           </div>
//           <div className={classes.all_icon_div}>
//             <div className={classes.icon_lang}>
//               <FaLanguage />
//             </div>
//             <div className={classes.icon_bell}>
//               <FaBell />
//             </div>
//             <div className={classes.log_in_out_div}>
//               <Logout
//                 onShowMessage={handleShowMessage}
//                 onClick={handleChangeUserProfile}
//               />{" "}
//               *
//             </div>
//             <div className={classes.icon_user} onClick={userMenuChange}>
//               {user ? <FaUser /> : <FcBusinessman />}
//               <FaUser />

//               {isUserMenuVisible && (
//                 <div className={classes.user_menu}>
//                   <button onClick={handleChangeUserProfile}>User Profile</button>
//                   <UserProfileCard
//                     user={user}
//                     onShowMessage={handleShowMessage}
//                   />
//                   <UserProfile />
//                   Add more menu items here
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Header;

//**************** */ Header section import to new page ***************************

// // import React {useState} from "react";
// import React, { useState } from "react";
// import Navbar from "./Navbar";
// import classes from "./Header.module.css";
// import pic from "../images/pinnacleWhiteLogo.png";
// import SearchBar from "./SearchBar.js";
// import Navbar1 from "./Navbar1";
// import { Link } from "react-router-dom";
// // import Navbar1 from "./Navbar2";
// // import Logout from "./loginComponent/Logout";
// // import { useState } from "react";

// function Header(props) {
//   // const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
//   // const [message, setMessage] = useState(null);

//   // const handleShowMessage = (msg) => {
//   //  // setMessage(msg);
//   //  console.log(msg);
//   // };

//   //  const handleLogout = () => {
//   //   setIsUserMenuVisible(false); // Close the user menu on logout
//   //   // Additional logout logic if needed
//   // };

//   return (
//     <div className={classes.Headercontainer1}>
//       <header className={classes["header4"]}>
//         <li className={classes.headerimage} style={{listStyle:"none"}}>
//           <Link
//             className={classes.none_video}
//             to="https://ssccglpinnacle.com/"
//           >
//             <img src={pic} />
//           </Link>
//         </li>

//         {/* <SearchBar /> */}
//         <Navbar />
//         {/* <div className={classes.log_in_out_div}>
//           <Logout
//             onShowMessage={handleShowMessage}
//             // onClick={handleChangeUserProfile}
//             onLogout={handleLogout}
//           />{" "}

//         </div> */}
//       </header>
//       <Navbar1 />
//       {/* <Navbar1 /> */}
//     </div>
//   );
// }

// export default Header;

import React, { useState } from "react";
// import UserProfileCard from "./UserProfileCard.js";
import UserProfile from "./UserProfile.js";
// import { FcBusinessman } from "react-icons/fc";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import classes from "./Header.module.css";
import { FaUser, FaBars, FaTimes } from "react-icons/fa";
// import { FaUser } from "react-icons/fa";
// import { CiSearch } from "react-icons/ci";
import { LuSearch } from "react-icons/lu";
import Logout from "./loginComponent/Logout";
import axios from "axios";
import pinnacle from "../images/new_pinnacle_logo.png";
// import TestSeriesUnlockOverlay from "./TestSeriesUnlockOverlay.js";
// import { IoBookOutline } from "react-icons/io5";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { PiBookDuotone } from "react-icons/pi";
import { RxHome } from "react-icons/rx";
import { FaRegKeyboard } from "react-icons/fa6";
import google from "../images/google.svg";
import { AiOutlineLineChart } from "react-icons/ai";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ImBlogger } from "react-icons/im";
import gmail1 from "../images/gmail1.svg";

const Header = () => {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const { product_id } = useParams();
  // const [isModelOpen, setIsModelOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  console.log("results:", results);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // const [message, setMessage] = useState(null);

  const handleShowMessage = (msg) => {
    // setMessage(msg);
    console.log(msg);
  };

  // const handleJoinTestPassChange = () => {
  //   setIsModelOpen(!isModelOpen);
  // };

  const handleClick = () => {
    // window.location.href = "https://ssccglpinnacle.com/";
    window.open("https://ssccglpinnacle.com/", "_blank"); // Open in a new tab
  };
  const handleHomeClick = () => {
    // window.location.href = "https://ssccglpinnacle.com/";
    window.open("https://ssccglpinnacle.com/", "_blank"); // Open in a new tab
  };

  const user = useSelector((state) => state.userProfile);
  console.log("User:", user.full_name);

  // Get the first letter of the user's name
  const firstLetter = user.full_name
    ? user.full_name.charAt(0).toUpperCase()
    : "";
  console.log("First Letter of User's Name:", firstLetter);

  const handleChangeUserProfile = () => {
    navigate("/home/useraccount");
  };

  const userMenuChange = () => {
    setIsUserMenuVisible(!isUserMenuVisible);
  };

  const handleLogout = () => {
    setIsUserMenuVisible(false); // Close the user menu on logout
    // Additional logout logic if needed
  };

  // const handleShowMoreTestChange = (id) => {
  //   // navigate(`/individualexamtestpasssection/${id}`);
  //   console.log(id);
  // };

  const handleSearch = async (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);

    if (searchQuery.length) {
      try {
        const response1 = await axios.get(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/testpass?q=${searchQuery}`
        );

        let combinedResults = [...response1.data];

        if (product_id) {
          const response2 = await axios.post(
            `${process.env.REACT_APP_PINNACLE_BASE_URL}/individualexamtestpass`,
            {
              email_id: user.email_id,
              product_id: product_id,
              q: searchQuery,
            }
          );
          combinedResults = [...combinedResults, ...response2.data];
        }

        setResults(combinedResults);
        console.log(combinedResults);
      } catch (error) {
        console.error("Error fetching search results", error);
      }
    } else {
      setResults([]);
    }
  };

  // const filteredResults = results.filter(
  //   (result) =>
  //     result.Product_title?.toLowerCase().includes(query.toLowerCase()) ||
  //     result.post_tier_name?.toLowerCase().includes(query.toLowerCase())
  // );

  return (
    <div className={classes.first_row}>
      <div className={classes.main_row}>
        <div className={classes.heading_logo_div}>
          <a
            href="https://ssccglpinnacle.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.pinnacle_logo}
              src={pinnacle}
              alt="Pinnacle Logo"
              onClick={handleClick}
            />
          </a>
        </div>
        <div className={classes.search_bar_div}>
          <div className={classes.search_input}>
            <input
              className={classes.search_input_input}
              type="text"
              value={query}
              onChange={handleSearch}
              placeholder="search"
            />
            <div className={classes.searchicon_home}>
              <LuSearch />
            </div>
          </div>
        </div>

        <div className={classes.nav}>
          <a
            href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={google} alt="google_play_img" />
          </a>
        </div>

        {/* Hamburger Menu Icon */}
        <div className={classes.hamburger_icon} onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <div
          className={`${classes.second_row} ${
            isMenuOpen ? classes.showMenu : ""
          }`}
        >
          {/* <div style={{ display: "flex" }}>
            <img
              className={classes.gmail_image_section}
              src={gmail1}
              alt="gmail_footer_img"
            />
            <p className={classes.gmail_p_tag} href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com" target="_blank" rel="noopener noreferrer">
              support@ssccglpinnacle.com
            </p>
          </div> */}
          <div style={{ display: "flex" }}>
            <img
              className={classes.gmail_image_section}
              src={gmail1}
              alt="gmail_footer_img"
            />
            <a
              className={classes.gmail_p_tag}
              href="https://mail.google.com/mail/?view=cm&fs=1&to=support@ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              support@ssccglpinnacle.com
            </a>
          </div>
          <div className={classes.para_p}>
            <button
              className={classes.test_pass_button}
              onClick={handleHomeClick}
            >
              <RxHome />
              Home
            </button>
          </div>
          <div className={classes.test_pass_div}>
            <li
              className={classes.test_pass_li}
              onClick={() =>
                (window.location.href =
                  "https://testportal.ssccglpinnacle.com/")
              }
            >
              <AiOutlineLineChart
                className={classes.line}
                color="white"
                fontSize="14px"
                fontWeight="500"
              />
              &nbsp;Test Portal
            </li>
          </div>
          <div className={classes.eBooks_div}>
            <li>
              <a
                href="https://ssccglpinnacle.com/typing"
                target="_blank" // This will open the link in a new tab
                rel="noopener noreferrer" // This is for security reaso
                className={classes.eBook_link}
              >
                <span className={classes.eBook_span_tag}>
                  <FaRegKeyboard />
                </span>
                Typing
              </a>
            </li>
          </div>
          <div className={classes.books_div}>
            <li>
              <a
                href="https://ssccglpinnacle.com/books"
                target="_blank" // This will open the link in a new tab
                rel="noopener noreferrer" // This is for security reaso
                className={classes.book_link}
              >
                <span className={classes.book_span_tag}>
                  <PiBookDuotone />
                </span>
                Books
              </a>
            </li>
          </div>
          <div className={classes.videos_div}>
            <li>
              <a
                href="https://ssccglpinnacle.com/videos"
                target="_blank" // This will open the link in a new tab
                rel="noopener noreferrer" // This is for security reaso
                className={classes.video_link}
              >
                <span className={classes.video_span_tag}>
                  <MdOutlineOndemandVideo />
                </span>
                Videos
              </a>
            </li>
          </div>
          <div className={classes.catalogue_div}>
            <li className={classes.catalogue_li}>
              <a
                className={classes.none_video}
                href="https://ssccglpinnacle.com/ebooks"
                target="_blank" // This will open the link in a new tab
                rel="noopener noreferrer" // This is for security reasons
              >
                <BsFillMenuButtonWideFill
                  className={classes.digital}
                  color="white"
                  fontSize="14px"
                  fontWeight="500"
                />
                {/* &nbsp;Digital Catalogue */}
                &nbsp;E-Books
              </a>
            </li>
          </div>
          <div className={classes.icon_lang}>
            <li className={classes.blog_li}>
              <a
                className={classes.none_video}
                href="https://ssccglpinnacle.com/blog"
                target="_blank" // This will open the link in a new tab
                rel="noopener noreferrer" // This is for security reaso
              >
                <ImBlogger
                  className={classes.home}
                  color="white"
                  fontSize="14px"
                  fontWeight="500"
                />
                &nbsp;Blog
              </a>
            </li>
          </div>

          <div className={classes.log_in_out_div}>
            <Logout
              onShowMessage={handleShowMessage}
              onClick={handleChangeUserProfile}
              onLogout={handleLogout}
            />
          </div>

          <div
            className={classes.icon_user}
            onClick={userMenuChange}
            onMouseEnter={userMenuChange}
            onMouseLeave={userMenuChange}
          >
            {firstLetter ? (
              <div className={classes.latterIcon}>
                {firstLetter}
                {isUserMenuVisible && (
                  <div className={classes.user_menu}>
                    <UserProfile user={user} firstLetter={firstLetter} />
                  </div>
                )}
              </div>
            ) : (
              <FaUser />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

// <>
//   {/* {isModelOpen && <TestSeriesUnlockOverlay id={132} />} */}

//   <div className={classes.container_fluid}>
//     <div className={classes.navbar_header_div}>

//       <div className={classes.heading_logo_div}>
//         <a
//           href="https://ssccglpinnacle.com/"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <img
//             className={classes.pinnacle_logo}
//             src={pinnacle}
//             alt="Pinnacle Logo"
//             onClick={handleClick}
//           />
//         </a>
//         {/* <img className={classes.pinnacle_logo} src={pinnacle} /> */}
//       </div>

//       <div className={classes.search_input}>
//         <input
//           className={classes.search_input_input}
//           type="text"
//           value={query}
//           onChange={handleSearch}
//           placeholder="search"
//         />
//         <div className={classes.searchicon_home}>
//           <LuSearch />
//         </div>
//         {/* {filteredResults.length > 0 && (
//           <div className={classes.results_dropdown}>
//             {filteredResults.map((result) => (
//               <div
//                 key={result._id}
//                 className={classes.result_item}
//                 onClick={() => handleShowMoreTestChange(result.id)}
//               >
//                 <div className={classes.result_item_icon}>
//                   <CiSearch />
//                 </div>
//                 {result.Product_title || result.post_tier_name}
//               </div>
//             ))}
//           </div>
//         )} */}
//       </div>

//       <div className={classes.google_play_store}>
//       <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"><img src={google} /> </a>
//       </div>

//       <div className={classes.para_p}>
//         <button
//           className={classes.test_pass_button}
//           onClick={handleHomeClick}
//         >
//           <RxHome />
//         </button>
//       </div>

//       <div className={classes.books_div}>
//         <li>
//           <a
//             href="https://ssccglpinnacle.com/books-catalog"
//             className={classes.book_link}
//           >
//             <span className={classes.book_span_tag}>
//               <PiBookDuotone />
//             </span>
//             Books
//           </a>
//         </li>
//       </div>

//       <div className={classes.videos_div}>
//         <li>
//           <a
//             href="https://ssccglpinnacle.com/videos"
//             className={classes.video_link}
//           >
//             <span className={classes.video_span_tag}>
//               <MdOutlineOndemandVideo />
//             </span>
//             Videos
//           </a>
//         </li>
//       </div>

//       <div className={classes.eBooks_div}>
//         <li>
//           <a
//             href="https://ssccglpinnacle.com/typing"
//             className={classes.eBook_link}
//           >
//             <span className={classes.eBook_span_tag}>
//               <FaRegKeyboard />
//             </span>
//             Typing
//           </a>
//         </li>
//       </div>

//       <div className={classes.all_icon_div}>
//         <div className={classes.icon_lang}>
//           <FaLanguage />
//         </div>
//         <div className={classes.icon_bell}>
//           <FaBell />
//         </div>
//         <div className={classes.log_in_out_div}>
//           <Logout
//             onShowMessage={handleShowMessage}
//             onClick={handleChangeUserProfile}
//             onLogout={handleLogout}
//           />{" "}
//           {/* * */}
//         </div>

//         <div
//           className={classes.icon_user}
//           onClick={userMenuChange}
//           onMouseEnter={userMenuChange}
//           onMouseLeave={userMenuChange}
//         >
//           {firstLetter ? (
//             <div className={classes.latterIcon}>
//               {firstLetter}
//               {isUserMenuVisible && (
//               <div className={classes.user_menu}>
//                 <UserProfile user={user} firstLetter={firstLetter} />
//               </div>
//             )}
//               </div>
//           ) : (
//             <FaUser />
//           )}

//           {/* {user ? <FaUser /> : <FcBusinessman />} */}
//           {/* <FaUser /> */}
//           {/**/}
//           {/* {isUserMenuVisible && (
//             <div className={classes.user_menu}> */}
//           {/* <button onClick={handleChangeUserProfile}>User Profile</button> */}
//           {/* <UserProfileCard
//                 user={user}
//                 onShowMessage={handleShowMessage}
//               /> */}
//           {/* <UserProfile /> */}
//           {/* Add more menu items here */}
//           {/* </div>
//           )} */}
//         </div>

//         {/* {isUserMenuVisible && (
//           <div className={classes.user_menu}>
//             <UserProfile user={user} firstLetter={firstLetter} />
//           </div>
//         )} */}
//       </div>
//     </div>
//   </div>

// </>
