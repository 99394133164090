// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import Cookies from "universal-cookie";
// import classes from "./StudentDetails.module.css";

// const StudentDetails = () => {
//   const { email_id } = useParams();
//   const [student, setStudent] = useState([]);
//   const [dummyProduct, setDummyProduct] = useState([]);
//   const [editMode, setEditMode] = useState(false);
//   const [showModal, setShowModal] = useState(false); // Modal visibility state
//   const [allDummyProducts, setAllDummyProducts] = useState([]);
//   // const [selectedProducts, setSelectedProducts] = useState([]); // Tracks selected products in modal
//   const [selectedProduct, setSelectedProduct] = useState("");
//   const [testSeriesName, setTestSeriesName] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const cookies = new Cookies();
//   const token = cookies.get("token");

//   const role = ["student", "manager", "employee"];

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setStudent({ ...student, [name]: value });
//   };

//   // Function to handle form submission
//   const submitStudentDetails = async () => {
//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/update-student`, // Change to your correct update endpoint
//         {
//           id: student.id, // You might be using a different field for ID
//           full_name: student.full_name,
//           email_id: student.email_id,
//           mobile_number: student.mobile_number,
//           role: student.role,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.status === 200) {
//         console.log("Student details updated successfully:", response.data);
//         alert("Student details updated successfully");
//         setEditMode(false); // Exit edit mode after saving
//       } else {
//         console.error("Error updating student details:", response);
//       }
//     } catch (error) {
//       console.error("Error updating student details:", error);
//       alert("An error occurred while updating student details.");
//     }
//   };

//   // Toggle between edit and save mode
//   const toggleEditMode = () => {
//     if (editMode) {
//       // If we are in edit mode and click "Save", submit the form
//       submitStudentDetails();
//     } else {
//       // If we are not in edit mode, switch to edit mode
//       setEditMode(true);
//     }
//   };

//   const openModal = () => {
//     setShowModal(true); // Open modal
//   };

//   const closeModal = () => {
//     setShowModal(false); // Close modal
//   };

//   // const handleSaveProduct = () => {
//   //   // Add selected products to dummyProduct list
//   //   setDummyProduct([...dummyProduct, ...selectedProducts]);
//   //   // Clear selection and close modal
//   //   setSelectedProducts([]);
//   //   closeModal();
//   // };

//     // Handle saving the selected product
//     const handleSaveProduct = async () => {
//       if (!selectedProduct) {
//         alert("Please select a product to add.");
//         return;
//       }

//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/add-product`, // Adjust endpoint
//           {
//             email_id: student.email_id,
//             // product_id: selectedProduct.id, // Product ID or product title
//             product_id: product.id, // Product
//             status: "purchased", // Set a default status or allow selection
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.status === 200) {
//           console.log("Product added successfully:", response.data);
//           setDummyProduct([...dummyProduct, response.data.newProduct]); // Update the product list
//           setSelectedProduct(""); // Clear selection after saving
//           closeModal(); // Close the modal after saving
//           alert("Product added successfully.");
//         } else {
//           console.error("Error adding product:", response);
//         }
//       } catch (error) {
//         console.error("Error adding product:", error);
//         alert("An error occurred while adding the product.");
//       }
//     };

//   useEffect(() => {
//     const fetchStudent = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/register-student/${email_id}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setStudent(response.data.student);
//         setDummyProduct(response.data.purchasedData);
//         setAllDummyProducts(response.data.alldummyproducts);
//         setTestSeriesName(response.data.testseriesname);
//         setRoles(response.data.student.role);
//       } catch (error) {
//         console.error("Error fetching student details:", error);
//       }
//     };
//     // console.log(allDummyProducts);

//     fetchStudent();
//   }, [email_id, token]);

//   // console.log(roles);

//   if (!student) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className={classes.student_details_container}>
//       <h5>
//         Student Details (Admin can edit here User Name, Email id, Mobile Number
//         & Role)
//       </h5>
//       <div className={classes.student_info}>
//         <table>
//           <tbody>
//             <tr>
//               <td>Student ID:</td>
//               <td>{student.id}</td>
//             </tr>

//             {editMode ? (
//               <>
//                 <tr>
//                   <td>Name:</td>
//                   <td>
//                     <input
//                       type="text"
//                       name="full_name"
//                       value={student.full_name}
//                       onChange={handleInputChange}
//                     />
//                   </td>
//                 </tr>
//                 <tr>
//                   <td>Email:</td>
//                   <td>
//                     <input
//                       type="email"
//                       name="email_id"
//                       value={student.email_id}
//                       onChange={handleInputChange}
//                     />
//                   </td>
//                 </tr>
//                 <tr>
//                   <td>Mobile:</td>
//                   <td>
//                     <input
//                       type="text"
//                       name="mobile_number"
//                       value={student.mobile_number}
//                       onChange={handleInputChange}
//                     />
//                   </td>
//                 </tr>
//                 <tr>
//                   <td>Role:</td>
//                   <td>
//                     <select
//                       name="role"
//                       value={student.role}
//                       onChange={handleInputChange}
//                     >
//                       {role.map((role) => (
//                         <option key={role} value={role}>
//                           {role.charAt(0).toUpperCase() + role.slice(1)}
//                         </option>
//                       ))}
//                     </select>
//                   </td>
//                 </tr>
//               </>
//             ) : (
//               <>
//                 <tr>
//                   <td>Name:</td>
//                   <td>{student.full_name}</td>
//                 </tr>
//                 <tr>
//                   <td>Email:</td>
//                   <td>{student.email_id}</td>
//                 </tr>
//                 <tr>
//                   <td>Mobile:</td>
//                   <td>{student.mobile_number}</td>
//                 </tr>
//                 <tr>
//                   <td>Role:</td>
//                   <td>{student.role}</td>
//                 </tr>
//               </>
//             )}
//             <tr>
//               <td colSpan="2">
//                 <button onClick={toggleEditMode}>
//                   {editMode ? "Save" : "Edit"}
//                 </button>
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>

//       <div className={classes.product_lists}>
//         <div className={classes.section}>
//           <div className={classes.headers}>
//             <h5>Product Buy List</h5>
//             <button onClick={openModal} className={classes.addbutton}>
//               + Add Product
//             </button>
//           </div>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr. No.</th>
//                 <th>Product Name</th>
//                 <th>Status</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {dummyProduct.length > 0 ? (
//                 dummyProduct.map((product, index) => (
//                   <tr key={product._id || index}>
//                     <td>{index + 1}</td>
//                     <td>{product.productName}</td>
//                     <td>{product.status}</td>
//                     <td>
//                       <button>Edit</button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="3">No products purchased</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//       <div className={classes.product_lists}>
//         <div className={classes.section}>
//           <h5>Test Series List</h5>
//           <div className={classes.tableContainer}>
//             {/* Add this wrapper div */}
//             <table border="1">
//               <thead>
//                 <tr>
//                   <th>Sr. No.</th>
//                   <th>Test Series Name</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {testSeriesName.length > 0 ? (
//                   testSeriesName.map((test, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{test.test_series_name}</td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="2">No Test Series available</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>

//       {/* Modal embedded inside the StudentDetails component */}
//       {showModal && (
//         <div className={classes.modal_overlay}>
//           <div className={classes.modal_content}>
//             <h2>Select Products</h2>
//             <div className={classes.product_list}>
//               <select
//               value={selectedProduct}
//               onChange={(e) => setSelectedProduct(e.target.value)}
//               >
//                 <option value="">--Select a Product--</option>
//                 {allDummyProducts.map((product) => (
//                   <option key={product._id} value={product.id}>
//                     {product.Product_title}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className={classes.modal_actions}>
//               <button onClick={closeModal}>Cancel</button>
//               <button onClick={handleSaveProduct}>Save</button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default StudentDetails;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import classes from "./StudentDetails.module.css";

const StudentDetails = () => {
  const { email_id } = useParams();
  const [student, setStudent] = useState([]);
  const [dummyProduct, setDummyProduct] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [allDummyProducts, setAllDummyProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(""); // Holds selected product id
  const [testSeriesName, setTestSeriesName] = useState([]);
  const [roles, setRoles] = useState([]);
  const cookies = new Cookies();
  const token = cookies.get("token");

  const role = ["student", "manager", "employee"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudent({ ...student, [name]: value });
  };

  console.log("alldummy products:", allDummyProducts);

  // Function to handle form submission
  const submitStudentDetails = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/update-student`,
        {
          id: student.id,
          full_name: student.full_name,
          email_id: student.email_id,
          mobile_number: student.mobile_number,
          role: student.role,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Student details updated successfully:", response.data);
        alert("Student details updated successfully");
        setEditMode(false); // Exit edit mode after saving
      } else {
        console.error("Error updating student details:", response);
      }
    } catch (error) {
      console.error("Error updating student details:", error);
      alert("An error occurred while updating student details.");
    }
  };

  // Toggle between edit and save mode
  const toggleEditMode = () => {
    if (editMode) {
      submitStudentDetails();
    } else {
      setEditMode(true);
    }
  };

  const openModal = () => {
    setShowModal(true); // Open modal
  };

  const closeModal = () => {
    setShowModal(false); // Close modal
  };

  // Handle saving the selected product
  const handleSaveProduct = async () => {
    if (!selectedProduct) {
      alert("Please select a product to add.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/add-product`,
        {
          email_id: student.email_id,
          product_id: selectedProduct, // Send selected product ID directly
          status: "SUCCESS",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Product added successfully:", response.data);
        // setDummyProduct([...dummyProduct, response.data.newProduct]); // Update the product list
        setDummyProduct((prevDummyProduct) => [
          ...prevDummyProduct,
          response.data.newProduct, // This now contains the productName and productId
        ]);
        setSelectedProduct(""); // Clear selection after saving
        closeModal(); // Close the modal after saving
        alert("Product added successfully.");
      } else {
        console.error("Error adding product:", response);
      }
    } catch (error) {
      console.error("Error adding product:", error);
      alert("An error occurred while adding the product.");
    }
  };

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/register-student/${email_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStudent(response.data.student);
        setDummyProduct(response.data.purchasedData);
        setAllDummyProducts(response.data.alldummyproducts);
        setTestSeriesName(response.data.testSeriesData);
        setRoles(response.data.student.role);
      } catch (error) {
        console.error("Error fetching student details:", error);
      }
    };

    fetchStudent();
  }, [email_id, token]);

  // console.log("Fetching student details", student);

  // Delete product function
  const handleDeleteProduct = async (email_id, product_id) => {
    try {
      // Ask for confirmation before deleting
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (confirmed.isConfirmed) {
        // Make DELETE request to backend
        const response = await axios.delete(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/delete-product`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the request headers
            },
            data: {
              email_id: email_id,
              product_id: product_id,
            },
          }
        );

        // If the product is successfully deleted, show success message
        if (response.status === 200) {
          Swal.fire("Deleted!", "Product has been deleted.", "success");

          // // Remove the deleted product from the state to update UI
          // setDummyProduct(dummyProduct.filter((p) => p.product_id !== product_id));
          // Immediately update UI to remove the product from the list
          setDummyProduct((prevDummyProducts) =>
            prevDummyProducts.filter((p) => p.productId !== product_id)
          );
        }
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      Swal.fire("Error!", "There was an error deleting the product.", "error");
    }
  };

  console.log("Student", student);
  console.log("Dummy Product", dummyProduct);

  if (!student) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.student_details_container}>
      <h5>
        Student Details (Admin can edit here User Name, Email id, Mobile Number
        & Role)
      </h5>
      <div className={classes.student_info}>
        <table>
          <tbody>
            <tr>
              <td>Student ID:</td>
              <td>{student.id}</td>
            </tr>

            {editMode ? (
              <>
                <tr>
                  <td>Name:</td>
                  <td>
                    <input
                      type="text"
                      name="full_name"
                      value={student.full_name}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>
                    <input
                      type="email"
                      name="email_id"
                      value={student.email_id}
                      onChange={handleInputChange}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mobile:</td>
                  <td>
                    <input
                      type="text"
                      name="mobile_number"
                      value={student.mobile_number}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Role:</td>
                  <td>
                    <select
                      name="role"
                      value={student.role}
                      onChange={handleInputChange}
                    >
                      {role.map((role) => (
                        <option key={role} value={role}>
                          {role.charAt(0).toUpperCase() + role.slice(1)}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>Name:</td>
                  <td>{student.full_name}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{student.email_id}</td>
                </tr>
                <tr>
                  <td>Mobile:</td>
                  <td>{student.mobile_number}</td>
                </tr>
                <tr>
                  <td>Role:</td>
                  <td>{student.role}</td>
                </tr>
              </>
            )}
            <tr>
              <td colSpan="2">
                <button onClick={toggleEditMode}>
                  {editMode ? "Save" : "Edit"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={classes.product_lists}>
        <div className={classes.section}>
          <div className={classes.headers}>
            <h5>Product Buy List</h5>
            <button onClick={openModal} className={classes.addbutton}>
              + Add Product
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Product Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dummyProduct.length > 0 ? (
                dummyProduct.map((product, index) => (
                  <tr key={product._id || index}>
                    <td>{index + 1}</td>
                    <td>{product.productName}</td>
                    <td>{product.status}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          color: "red",
                          cursor: "pointer",
                          marginLeft: "15px",
                          fontSize: "16px",
                        }}
                        onClick={() =>
                          handleDeleteProduct(
                            student.email_id,
                            product.productId
                          )
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No products purchased</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.product_lists}>
        <div className={classes.section}>
          <h5>Test Series List</h5>
          <div className={classes.tableContainer}>
            <table border="1">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Test Series Name</th>
                  <th>Total Test</th>
                  <th>Take Test</th>
                  <th>Remaining Take Test</th>
                </tr>
              </thead>
              <tbody>
                {testSeriesName.length > 0 ? (
                  testSeriesName.map((test, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{test.test_series_name}</td>
                      <td>{test.numberOfTests}</td>
                      <td>{test.TakeTests}</td>
                      <td>{test.numberOfTests - test.TakeTests}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No Test Series available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Modal embedded inside the StudentDetails component */}
      {showModal && (
        <div className={classes.modal_overlay}>
          <div className={classes.modal_content}>
            <h2>Select Products</h2>
            <div className={classes.product_list}>
              <select
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)} // Track selected product id
              >
                <option value="">--Select a Product--</option>
                {allDummyProducts.map((product) => (
                  <option key={product._id} value={product.id}>
                    {" "}
                    {/* Send product.id */}
                    {product.Product_title}
                  </option>
                ))}
              </select>
            </div>
            <div className={classes.modal_actions}>
              <button onClick={closeModal}>Cancel</button>
              <button onClick={handleSaveProduct}>Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentDetails;
