import React, { useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./components/Dashboard/Sidebar";
import Navbar from "./components/Dashboard/Navbar";
import Content from "./components/Dashboard/Content";
import Users from "./components/Users";
// import Products from "./components/Products";
import AddUser from "./components/Dashboard/AddUser";
import UserList from "./components/Dashboard/UserList";
import Students from "./components/Dashboard/Students";
import StudentDetails from "./components/Dashboard/StudentDetails";
import PaperCodeDetails from "./components/Dashboard/CreatePaperCode";
// import AddProduct from "./components/Dashboard/AddProduct";
// import ProductList from "./components/Dashboard/ProductList";
import PapercodeList from "./components/Dashboard/PapercodeList";
import PapercodeAdd from "./components/Dashboard/PapercodeAdd";
import TestseriesAdd from "./components/Dashboard/TestseriesAdd";
import ViewQuestions from "./components/Dashboard/ViewQuestions";
import AddPaper from "./components/Dashboard/AddPaper";
import UpdateStartDate from "./components/Dashboard/UpdateStartDate";
import Addpapercodecgltier2 from "./components/Dashboard/AddPaperCodeCglTier2";
import classes from "./Index.module.css";
import CreateTestSeries from "./components/Dashboard/CreateTestSeries";
import ListTestSeries from "./components/Dashboard/ListTestSeries";
import NewProducts from "./components/Dashboard/NewProducts";
import AllProducts from "./components/Dashboard/AllProducts";
// import TestSeriesProductAdd from "./components/Dashboard/TestSeriesProductAdd";
import AddTestSeriesProduct from "./components/Dashboard/AddTestSeriesProduct";
import AddTestSeriesInProduct from "./components/Dashboard/AddTestSeriesInProduct";
import ListTestSeriesProduct from "./components/Dashboard/ListTestSeriesProduct";
import RoleBasedAccess from "../RoleBasedRoute";
import UnAuthorized from "../UnAuthorized";
import { useAuthContext } from "../hooks/useAuthContext";
import { useDispatch, useSelector } from "react-redux";
import UploadPaperTier2 from "./components/Dashboard/UploadPaperTier2";

import Instruction from "../component/Instruction";
import ErrorRecord from "./components/Dashboard/ErrorRecord";
import Transactions from "./components/Dashboard/Transactions";
// import InstructionNew from "../component/InstructionNew";


// const Admin = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
//   // const [isFullscreen, setIsFullscreen] = useState(false);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     // <div className={classes.admin_div}>
//     <>
//       <div className={`${classes.sidebar_div} ${classes.fixed_sidebar}`}>
//         {isSidebarOpen && <Sidebar toggleSidebar={toggleSidebar} />}
//       </div>

//       {/* <div className={classes.main_div} style={{ marginLeft: isSidebarOpen && !isFullscreen ? '250px' : 0 }}> */}
//       <div
//         className={`${classes.main_div} ${
//           isSidebarOpen ? classes.withSidebar : ""
//         }`}
//       >
//         {/* <Navbar toggleSidebar={toggleSidebar} toggleFullscreen={toggleFullscreen} isSidebarOpen={isSidebarOpen} /> */}
//         <Navbar toggleSidebar={toggleSidebar} />
//         {/* <Navbar /> */}
//         <Content>
//           <Routes>
//             <Route path="/dashboard" element={<PapercodeList />} />
//             <Route path="/users" element={<Users />} />
//             <Route path="/users/add" element={<AddUser />} />
//             <Route path="/users/list" element={<UserList />} />
//             {/* <Route path="/products" element={<Products />} /> */}
//             {/* <Route path="/products/add" element={<AddProduct />} /> */}
//             {/* <Route path="/products/list" element={<ProductList />} /> */}
//             <Route path="/products/newproducts" element={<NewProducts />} />
//             <Route path="/products/allproducts" element={<AllProducts />} />
//             <Route
//               path="/products/test_series_product_add"
//               element={<AddTestSeriesInProduct />}
//             />
//             <Route
//               path="/products/list_test_series_in_product"
//               element={<ListTestSeriesProduct />}
//             />

//             <Route
//               path="/papercode/createpapercode"
//               element={<PaperCodeDetails />}
//             />
//             <Route
//               path="/papercode/add_test_series"
//               element={<CreateTestSeries />}
//             />
//             <Route
//               path="/papercode/list_test_series"
//               element={<ListTestSeries />}
//             />

//             <Route path="/papercode/add" element={<PapercodeAdd />} />
//             <Route path="/papercode/list" element={<PapercodeList />} />
//             <Route path="/testseries/add" element={<TestseriesAdd />} />
//             <Route path="/testseries/addpaper" element={<AddPaper />} />
//             <Route
//               path="/view-questions/:papercode/:exam_mode_id"
//               element={<ViewQuestions />}
//             />
//             <Route
//               path="/testseries/udatestartdate"
//               element={<UpdateStartDate />}
//             />
//             <Route
//               path="/view-questions/:papercode"
//               element={<ViewQuestions />}
//             />
//             <Route
//               path="/addpaperdetails/cgltier2"
//               element={<Addpapercodecgltier2 />}
//             />
//             {/* <Route path="/unauthorized" element={<Unauthorized />} /> */}
//           </Routes>
//         </Content>
//       </div>
//     </>
//   );
// };

// export default Admin;

const Admin = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const userProfile = useSelector((state) => state.userProfile);

  console.log("User Profile in Admin Component:", userProfile);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Define protected components
  const ProtectedPapercodeList = RoleBasedAccess(["manager", "employee"])(
    PapercodeList
  );
  const ProtectedUsers = RoleBasedAccess(["manager"])(Users);
  const ProtectedAddUser = RoleBasedAccess(["manager"])(AddUser);
  const ProtectedUserList = RoleBasedAccess(["manager"])(UserList);
  const ProtectedStudents = RoleBasedAccess(["manager"])(Students);
  const ProtectedTransition = RoleBasedAccess(["manager"])(Transactions);
  const ProtectedStudentDetails = RoleBasedAccess(["manager"])(StudentDetails);
  const ProtectedNewProducts = RoleBasedAccess(["manager"])(NewProducts);
  const ProtectedAllProducts = RoleBasedAccess(["manager"])(AllProducts);
  const ProtectedAddTestSeriesInProduct = RoleBasedAccess(["manager"])(
    AddTestSeriesInProduct
  );
  const ProtectedListTestSeriesProduct = RoleBasedAccess(["manager"])(
    ListTestSeriesProduct
  );
  const ProtectedPaperCodeDetails = RoleBasedAccess(["manager", "employee"])(
    PaperCodeDetails
  );
  const ProtectedCreateTestSeries = RoleBasedAccess(["manager", "employee"])(
    CreateTestSeries
  );
  const ProtectedListTestSeries = RoleBasedAccess(["manager", "employee"])(
    ListTestSeries
  );
  const ProtectedPapercodeAdd = RoleBasedAccess(["manager", "employee"])(
    PapercodeAdd
  );
  const ProtectedTestseriesAdd = RoleBasedAccess(["manager", "employee"])(
    TestseriesAdd
  );
  const ProtectedAddPaper = RoleBasedAccess(["manager", "employee"])(AddPaper);
  const ProtectedViewQuestions = RoleBasedAccess(["manager", "employee"])(
    ViewQuestions
  );
  const ProtectedUpdateStartDate = RoleBasedAccess(["manager", "employee"])(
    UpdateStartDate
  );
  const ProtectedAddpapercodecgltier2 = RoleBasedAccess([
    "manager",
    "employee",
  ])(Addpapercodecgltier2);

  const ProtectedErrorRecord = RoleBasedAccess(["manager", "employee"])(ErrorRecord);

  // const ProtectedInstruction = RoleBasedAccess(["manager", "employee"])(Instruction);

  return (
    <>
      <div className={`${classes.sidebar_div} ${classes.fixed_sidebar}`}>
        {isSidebarOpen && <Sidebar toggleSidebar={toggleSidebar} />}
      </div>
      <div
        className={`${classes.main_div} ${
          isSidebarOpen ? classes.withSidebar : ""
        }`}
      >
        <Navbar toggleSidebar={toggleSidebar} />
        <Content>
          <Routes>
            <Route path="/dashboard" element={<ProtectedPapercodeList />} />
            <Route path="/users" element={<ProtectedUsers />} />
            <Route path="/users/add" element={<ProtectedAddUser />} />
            <Route path="/users/list" element={<ProtectedUserList />} />
            <Route path="/users/students" element={<ProtectedStudents />} />
            <Route path="/users/transactions" element={<ProtectedTransition/>}/>

            
            {/* <Route path="/users/studentdetails" element={<ProtectedStudentDetails />} /> */}
            <Route path="/users/studentdetails/:email_id" element={<ProtectedStudentDetails />} />
            <Route
              path="/products/newproducts"
              element={<ProtectedNewProducts />}
            />
            <Route
              path="/products/allproducts"
              element={<ProtectedAllProducts />}
            />
            <Route
              path="/products/test_series_product_add"
              element={<ProtectedAddTestSeriesInProduct />}
            />
            <Route
              path="/products/list_test_series_in_product"
              element={<ProtectedListTestSeriesProduct />}
            />
            <Route
              path="/papercode/createpapercode"
              element={<ProtectedPaperCodeDetails />}
            />
            <Route
              path="/papercode/add_test_series"
              element={<ProtectedCreateTestSeries />}
            />
            <Route
              path="/papercode/list_test_series"
              element={<ProtectedListTestSeries />}
            />
            <Route path="/papercode/add" element={<ProtectedPapercodeAdd />} />
            <Route
              path="/papercode/list"
              element={<ProtectedPapercodeList />}
            />
            <Route
              path="/testseries/add"
              element={<ProtectedTestseriesAdd />}
            />
            <Route
              path="/testseries/addpaper"
              element={<ProtectedAddPaper />}
            />
            <Route
              path="/view-questions/:papercode/:exam_mode_id"
              element={<ProtectedViewQuestions />}
            />
            <Route
              path="/testseries/udatestartdate"
              element={<ProtectedUpdateStartDate />}
            />
            <Route
              path="/view-questions/:papercode"
              element={<ProtectedViewQuestions />}
            />
            <Route
              path="/addpaperdetails/cgltier2"
              element={<ProtectedAddpapercodecgltier2 />}
            />
            <Route
              path="/papercode/uploadpapertiertwo"
              element={<UploadPaperTier2 />}
            />
            <Route path="/unauthorized" element={<UnAuthorized />} />

            <Route path="/testrecord/errorrecord" element={<ProtectedErrorRecord/>} />
            

            {/* <Route
              path="/papercode/instruction/:papercode"
              element={<Instruction />}
            /> */}

            <Route
              path="/papercode/Instruction/:exam_id/:post_id/:exam_mode_id/:papercode/:test_series_id/:test_title/:tier_id/:product_id"
              element={<Instruction />}
            />

            {/* <Route path="/InstructionNew" element={<InstructionNew />} /> */}


          </Routes>
        </Content>
      </div>
    </>
  );
};

export default Admin;
