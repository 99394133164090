import { createContext, useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addProfile, reset } from "../redux/slices/userProfileSlice"; // Update the path as needed
import Cookies from "universal-cookie";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, token: action.payload, loading: false };
    case "LOGOUT":
      return { ...state, token: null, loading: false };
    case "LOADING":
      return { ...state, loading: true };
    case "FINISHED_LOADING":
      return { ...state, loading: false };
    default:
      return state;
  }
};

const isJSONString = (str) => {
  if (typeof str !== "string") return false;
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    token: null,
    loading: true,
  });

  const reduxDispatch = useDispatch();

  // const items = useSelector((state) => state.userProfile);

  useEffect(() => {
    const cookies = new Cookies();
    // const token = localStorage.getItem("token");
    // console.log("TOKEN INSIDE AUTHCONTEXT PROVIDER", token);
    // // const token = localStorage.getItem("token") || cookies.get("token");
    const token = cookies.get("token");

    // console.log("line 66", token);
    const userData =
      // localStorage.getItem("userData") || cookies.get("userData");
      cookies.get("userData");
    // console.log("line 68", token);
    // console.log("line 70", userData);

    // console.log('TOKEN INSIDE AUTHCONTEXT PROVIDER', token);
    // console.log('USER DATA INSIDE AUTHCONTEXT PROVIDER', userData);

    // if (token) {
    //   dispatch({ type: "LOGIN", payload: token });
    // }

    if (token) {
      dispatch({
        type: "LOGIN",
        payload: token,
      });
    }

    // if (userData) {
    //   reduxDispatch(addProfile(JSON.parse(userData)));
    // }

    if (userData) {
      // console.log(userData);
      // console.log("Type of userData:", typeof userData);
      try {
        const parsedUserData =
          typeof userData === "string" ? JSON.parse(userData) : userData;
        reduxDispatch(addProfile(parsedUserData));
      } catch (error) {
        console.log("Error parsing  userData ", error);
        // localStorage.removeItem("userData");
        cookies.remove("userData", { domain: ".ssccglpinnacle.com" });
      }
    }

    console.log("line 64", userData);

    const verifyToken = async (token) => {
      try {
        const response = await fetch(
          process.env.REACT_APP_PINNACLE_BASE_URL +
            "/api/v1/users/verify-token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
          }
        );

        const data = await response.json();
        console.log("Token verification response:", data);
        // console.log("Parsed response data:", data);
        // console.log("data MESSAGE", data);

        // if (data.valid) {
        //     dispatch({ type: 'LOGIN', payload: token });
        // } else {
        //     localStorage.removeItem('token');
        //     localStorage.removeItem('userData');
        //     localStorage.removeItem('expiration');
        //     dispatch({ type: 'LOGOUT' });
        // }
        if (!data.valid) {
          // console.log("Token is invalid, logging out.");
          // localStorage.removeItem("token");
          // localStorage.removeItem("userData");
          // localStorage.removeItem("expiration");
          cookies.remove("token", { domain: ".ssccglpinnacle.com" });
          cookies.remove("userData", { domain: ".ssccglpinnacle.com" });

          dispatch({ type: "LOGOUT", payload: null });
          // Clear user profile from Redux store
          reduxDispatch(reset());
        } else {
          console.log("Token is valid, proceeding with login.");
          dispatch({ type: "LOGIN", payload: token });

          const parsedUserData = isJSONString(userData)
            ? JSON.parse(userData)
            : userData;
          reduxDispatch(addProfile(parsedUserData));
        }
      } catch (error) {
        // console.error("Error verifying token:", error);
        // localStorage.removeItem("token");
        // localStorage.removeItem("userData");
        // localStorage.removeItem("expiration");
        console.log("an error occur", error);
        cookies.remove("token", { domain: ".ssccglpinnacle.com" });
        cookies.remove("userData", { domain: ".ssccglpinnacle.com" });
        dispatch({ type: "LOGOUT" });
        // Clear user profile from Redux store
        reduxDispatch(reset());
      }
    };

    if (token) {
      verifyToken(token);
    } else {
      dispatch({ type: "FINISHED_LOADING" });
    }
  }, []);

  // console.log("AuthContext state:", state);

  //   if (token) {
  //     verifyToken(token);
  //   } else {
  //     dispatch({ type: "FINISHED_LOADING" });
  //   }
  // }, [reduxDispatch]);

  // console.log("AuthContext state: ", state);
  // console.log("Items", items);

  if (state.loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="spinner"></div>
        <h1>LOADING...</h1>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
