// import React from "react";
// import classes from "./UserProfile.module.css";
// import Logout from "./loginComponent/Logout";

// function UserProfile() {
//   return <div className={classes.header}>{/* <Logout /> */}</div>;
// }

// export default UserProfile;

import React from "react";
import classes from "./UserProfile.module.css"; // Assume you have corresponding CSS module for styling

const UserProfile = ({ user, firstLetter }) => {
  return (
    <div className={classes.userProfileContainer}>
      <div className={classes.colourspace}></div>
      <div className={classes.userDetails}>
        <div className={classes.Icon} >
      {firstLetter && (
          <div className={classes.latterIcon5}>
            {firstLetter}
          </div>
        )}
        </div>
        <div className={classes.userDetailItem1}>
          <span className={classes.detailValue1}>{user.full_name}</span>
        </div>
        <div className={classes.userDetailItem2}>
          <span className={classes.detailValue2}>{user.email_id}</span>
        </div>
        {/* Add more user details as needed */}
      </div>
      {/* <div className={classes.actions}>
        <button className={classes.editProfileButton}>Edit Profile</button>
        <button className={classes.logoutButton}>Logout</button>
      </div> */}
    </div>
  );
};

export default UserProfile;
