import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ExamModeTestSeriesCard from "./ExamModeTestSeriesCard";
import Layout from "./Layout";
import classes from "./ExamModeTestSeriesCard.module.css";

const ExamModeCard = () => {
  const [examModeTestSeriesData, setExamModeTestSeriesData] = useState(null);

  const { exam_mode_id } = useParams();
  //   console.log(exam_mode_id);

  const PostExamModeData = async () => {
    console.log(exam_mode_id);
    try {
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/getexammodedata",
        {
          exam_mode_id: exam_mode_id,
        }
      );

      setExamModeTestSeriesData(response.data);
      console.log(response.data);
    } catch (error) {
      console.log("an internal server error occur");
    }
  };

  useEffect(() => {
    PostExamModeData();
  }, [exam_mode_id]);
  return (
    <>
      <Layout>
        <div className={classes.a_section_of_div}>
          {examModeTestSeriesData &&
            examModeTestSeriesData.map((examModeData, index) => (
              <ExamModeTestSeriesCard examModeData={examModeData} />
            ))}
        </div>
      </Layout>
    </>
  );
};

export default ExamModeCard;
