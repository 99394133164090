import React, { useState } from "react";
import ReactDom from "react-dom";
import classes from "./UpdateTestSeriesProduct.module.css";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

const Backdrop = ({ handleChangeOverlayModel }) => {
  return (
    <div onClick={handleChangeOverlayModel} className={classes.backdrop}></div>
  );
};

const UpdateProductInTestSeries = ({
  handleChangeOverlayModel,
  selectedProduct,
  testSeriesProductData,
}) => {
  // console.log(testSeriesProductData);
  // console.log(selectedProduct);

  // const [selectedTestSeriesName, setSelectedTestSeriesName] = useState(
  //   selectedProduct.testSeriesName
  // );
  const [selectedTestSeriesId, setSelectedTestSeriesId] = useState(
    selectedProduct.test_series_id
  );

  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(false);

  const handleUpdateTestSeries = (e) => {
    // console.log("This function got clicked");
    // const selectedTestSeriesIndex = e.target.selectedIndex;
    // const selectedTestSeriesId = e.target[selectedTestSeriesIndex].value;
    // const selectedTestSeriesName = e.target[selectedTestSeriesIndex].text;
    // // const selectedOption = testSeriesProductData[selectedIndex];
    // setSelectedTestSeriesId(selectedTestSeriesId);
    // setSelectedTestSeries(selectedTestSeriesName);
    // setSelectedTestSeries(e.target.value);
    setSelectedTestSeriesId(e.target.value);
  };

  // console.log(selectedTestSeriesId);

  // console.log(selectedTestSeries);

  const updateTestSeriesFormData = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");

    const formData = {
      id: selectedProduct.id,
      product_id: selectedProduct.product_id,
      test_series_id: selectedTestSeriesId,

      // test_series_id: selectedTestSeriesId,
    };
    try {
      const updateTestSeries = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/updatetestseriesinproductoverlay",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSuccessMessage("update successfully");
      setSelectedTestSeriesId("");
    } catch (error) {
      console.log("message:", "i got an error");
    } 
  };
  return (
    <div className={classes.updateOverlayModel}>
      <div style={{ textAlign: "right" }}>
        <RxCross1 onClick={handleChangeOverlayModel} />
      </div>
      {loading ? (
        <ClipLoader
          size={50}
          color={"#123abc"}
          loading={loading}
          css={{ display: "block", margin: "0 auto" }}
        />
      ) : (
        <>
          {successMessage && (
            <div className={classes.successMessage}>{successMessage}</div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              padding: "1rem",
            }}
          >
            <label style={{ display: "flex", flexDirection: "column" }}>
              Product Name
              <input
                style={{ width: "20rem" }}
                value={selectedProduct.productName}
                readOnly
              />
            </label>
            <label
              style={{
                display: "flex",
                flexDirection: "column",
                width: "20rem",
              }}
            >
              Test Series Name
              <select
                value={selectedTestSeriesId}
                onChange={handleUpdateTestSeries}
                style={{ height: "2rem", outline: "none" }}
              >
                {testSeriesProductData.map((test, index) => (
                  <option key={index} value={test.test_series_id}>
                    {test.testSeriesName}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            <button
              onClick={handleChangeOverlayModel}
              style={{
                backgroundColor: "red",
                border: "none",
                color: "white",
                borderRadius: "5px",
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={updateTestSeriesFormData}
              style={{ border: "none" }}
            >
              Save
            </button>
          </div>
          <button
            style={{
              width: "20%",
              backgroundColor: "red",
              color: "white",
              border: "none",
            }}
            onClick={handleChangeOverlayModel}
          >
            Close
          </button>
        </>
      )}
    </div>
  );
};

const UpdateProductInTestSeriesOverlay = ({
  handleChangeOverlayModel,
  selectedProduct,
  testSeriesProductData,
}) => {
  console.log(selectedProduct);
  // console.log(testSeriesProductData);
  return (
    <>
      {ReactDom.createPortal(
        <Backdrop handleChangeOverlayModel={handleChangeOverlayModel} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDom.createPortal(
        <UpdateProductInTestSeries
          handleChangeOverlayModel={handleChangeOverlayModel}
          selectedProduct={selectedProduct}
          testSeriesProductData={testSeriesProductData}
        />,
        document.getElementById("updatetestseriesinproduct")
      )}
    </>
  );
};
export default UpdateProductInTestSeriesOverlay;
