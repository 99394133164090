import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../redux/slices/userProfileSlice";
import { useAuthContext } from "../../hooks/useAuthContext";
import Cookies from "universal-cookie";
import classes from "./Logout.module.css";

const cookies = new Cookies();

const Logout = ({ onShowMessage }) => {
  const { token, dispatch: authDispatch } = useAuthContext();
  console.log("line 12", token);
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);

  console.log("userProfile", userProfile);
  // console.log("TOKEN", token);

  const logoutHandler = async (e) => {
    e.preventDefault();

    if (!userProfile._id) {
      const message =
        "Please Register with a valid email address or mobile number";
      onShowMessage(message);
      setTimeout(() => {
        navigate("/login");
      }, 1500);
      return;
    }

    try {
      const data = { _id: userProfile._id };
      const url =
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/logout";

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const jsonData = await response.json();
      if (response.ok) {
        console.log("JSON Data:", jsonData);

        // Clear user profile from Redux store
        reduxDispatch(reset());

        // Dispatch logout to AuthContext
        authDispatch({ type: "LOGOUT" });

        // Clear local storage
        // localStorage.removeItem("userData");
        // localStorage.removeItem("expiration");
        // localStorage.removeItem("token");
        cookies.remove("token", { path: "/", domain: ".ssccglpinnacle.com" });
        cookies.remove("userData", {
          path: "/",
          domain: ".ssccglpinnacle.com",
        });

        // cookies.remove("token", { path: "/", domain: ".localhost:3000" });
        // cookies.remove("userData", {
        //   path: "/",
        //   domain: "localhost:3000",
        // });

        onShowMessage(jsonData.message);
        navigate("/");
      } else {
        console.log("Response Error:", response.statusText);
        onShowMessage(jsonData.message);
      }
    } catch (err) {
      console.log("Error fetching data: " + err);
      onShowMessage(err.message);
    }
  };

  return (
    <div className={classes.login_logout_div}>
      {token ? (
        <button
          className={classes.login_singup}
          type="submit"
          onClick={logoutHandler}
        >
          Logout
        </button>
      ) : (
        <button
          className={classes.login_singup}
          type="submit"
          onClick={() => navigate("/login")}
        >
          Login/Signup
        </button>
      )}
    </div>
  );
};

export default Logout;

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { addProfile } from "../../redux/slices/userProfileSlice";
// import { useAuthContext } from "../../hooks/useAuthContext";

// const Logout = ({ onShowMessage }) => {
//   const { token, dispatch } = useAuthContext();

//   const navigate = useNavigate();
//   const reduxDispatch = useDispatch();

//   const items = useSelector((state) => state);
//   console.log("Items", items);
//   console.log("TOKEN",token);

//   const logoutHandler = async (e) => {
//     e.preventDefault();

//     // if (!items.userProfile._id) {
//     //   const message =
//     //     "Please Register with a valid email address or mobile number";
//     //   onShowMessage(message);
//     //   setTimeout(() => {
//     //     navigate("/login");
//     //   }, 1500);
//     //   return;
//     // }

//     try {
//       let data = { _id: items.userProfile._id };
//       let url = process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/users/logout";

//       const response = await fetch(url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify(data),
//       });

//       const jsonData = await response.json();
//       if (response.ok) {
//         console.log("JSON Data:", jsonData);

//         // Remove user from storage
//         reduxDispatch(addProfile({}));
//         dispatch({ type: "LOGOUT" });

//         // Clear local storage
//         localStorage.removeItem("userData");
//         localStorage.removeItem("expiration");
//         localStorage.removeItem("token");
//         onShowMessage(jsonData.message);

//         navigate("/");
//       } else {
//         console.log("Response Error:", response.statusText);
//         onShowMessage(jsonData.message);
//       }
//     } catch (err) {
//       console.log("Error fetching data: " + err);
//       onShowMessage(err.message);
//     }
//   };

//   return (
//     <>
//        {token ? (
//         <button type="submit" onClick={logoutHandler}>
//           Logout
//         </button>
//       ) : (
//         <button type="submit" onClick={() => navigate("/login")}>
//           Login/Signup
//         </button>
//       )}
//         {/**/}
//     </>
//   );
// };

// export default Logout;
