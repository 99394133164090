import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { load } from "@cashfreepayments/cashfree-js";

const CashFreeButton = ({
  productId,
  user,
  buttonText = "Buy It Now", // Default button text
  className = "", // Default class name
  style = {}, // Default styles
}) => {
  // console.log("cashfree payment component");
  // console.log("line 13", CashFreeButton);

  // let cashfree;

  const [cashfree, setCashfree] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const initializeSDK = async () => {
      const sdk = await load({
        mode: "production",
      });
      setCashfree(sdk);
      console.log("Cashfree SDK initialized");
    };
    initializeSDK();
  }, []);
  // var initializeSDK = async function () {
  //   cashfree = await load({
  //     mode: "Production",
  //   });
  // };
  // initializeSDK();

  const doPayment = async (id, orderId) => {
    if (cashfree) {
      try {
        console.log("initiating cashfree checkout", id, orderId);
        cashfree.checkout({
          paymentSessionId: id,
          // returnUrl: `https://testportal.ssccglpinnacle.com`,
          // returnUrl: `https://onlineexam.ssccglpinnacle.com/api/v1/payment/status/${orderId}`,
          // returnUrl: `https://onlineexam.ssccglpinnacle.com/paymentsuccess?order_id=${orderId}`,
          returnUrl: `https://testportal.ssccglpinnacle.com/paymentsuccess?order_id=${orderId}`,

          // returnUrl: `https://onlineexam.sscglpinnacle.com/api/v1/payment/status/${orderId}`,
          // returnUrl: `http://localhost:5000/payment/status/{order_id}`,
          // process.env.REACT_APP_PINNACLE_BASE_URL +
          // "/api/v1/payment/status/{order_id}",
          redirectTarget: "_blank",
          onSuccess: (paymentResponse) => {
            console.log("Payment successful", paymentResponse);
          },
          onFailure: (paymentResponse) => {
            console.error("PAYMENT FAILED", paymentResponse);
          },
        });
      } catch (error) {
        console.error("Error during cashfree checkout initiation", error);
      }
    } else {
      console.error("cashfree SDK not loaded");
    }
  };

  const payMe = async (productId) => {
    console.log("INSIDE PAYMENT", productId);
    // console.log("i got called");
    try {
      if (!user || !user._id) {
        // Swal.fire({
        //   icon: "error",
        //   title: "Error",
        //   text: "Please log in to make a purchase.",
        // });
        Swal.fire({
          icon: "error",
          title: "Error",
          html: `
            <p>Please log in to make a purchase.</p>
          `,
          showConfirmButton: true,
          confirmButtonText: "Go to Login",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login"); // Navigate to login page without refresh
          }
        });
        return;
      }

      // let data = { userId: user._id, productId };
      const data = {
        userId: user._id,
        productId,
      };
      console.log("DATA", data);
      const url = "https://onlineexam.ssccglpinnacle.com/api/v1/payment";
      console.log("URL line 67", url);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      console.log("response: ", response);

      if (response.ok) {
        const responseData = await response.json();
        console.log("RESPONSE: ", responseData);
        if (responseData.error) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: responseData.error,
          });
        } else {
          doPayment(responseData.cftoken, responseData.orderId);
        }
        // doPayment(responseData.message);
      } else {
        // console.log("Response Error:");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to initiate payment.Please try again",
        });
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occured. Please try again",
      });
    }
  };

  return (
    <button
      onClick={() => payMe(productId)}
      className={className}
      style={style}
    >
      {buttonText}
    </button>
  );
};

export default CashFreeButton;

// import React from 'react'
// import Swal from "sweetalert2";
// import { load } from "@cashfreepayments/cashfree-js";

// const CashFreeButton = ({productId, user}) => {

//   let cashfree;
//   var initializeSDK = async function () {
//     cashfree = await load({
//       mode: "sandbox",
//     });
//   };
//   initializeSDK();

//   const doPayment = async (id) => {
//     // let checkoutOptions = {
//     //   paymentSessionId: id,
//     //   returnUrl: 'http://localhost:3001/api/status/{order_id}',
//     //   redirectTarget: "_self",
//     // };
//     // console.log(checkoutOptions);
//     // cashfree.checkout(checkoutOptions);

//     // Assuming Cashfree's checkout is available and initialized in your project
//     cashfree.checkout({
//       paymentSessionId: id,
//       returnUrl: 'http://localhost:3001/api/status/{order_id}',
//       redirectTarget: "_self",
//       onSuccess: (paymentResponse) => {
//         console.log('Payment successful', paymentResponse);
//         // Handle success (e.g., show a success message, update order status)
//       },
//       onFailure: (paymentResponse) => {
//         console.error('PAYMENT FAILED', paymentResponse);
//         // Handle failure (e.g., show an error message)
//       },
//     });

//   };

//   // const createOrder = async () => {
//   //   try {
//   //     let data = { email_id: "haridattbhatt0@gmail.com" };
//   //     let url = "http://localhost:3001/payment";
//   //     console.log(url);
//   //     console.log(data);
//   //     const response = await fetch(url, {
//   //       method: "POST",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //       body: JSON.stringify(data),
//   //     });

//   //     console.log("Response Status:", response.status);
//   //     console.log("Response order Amount:", response.orderamount);

//   //     if (response.ok) {
//   //       const responseData = await response.json();
//   //       console.log(responseData);
//   //       console.log("JSON Data:" + responseData);
//   //       // console.log(responseData.status);
//   //       console.log("JSON Data: status " + responseData.status);
//   //       // console.log(responseData.message);
//   //       console.log("JSON Data: message " + responseData.message);
//   //       doPayment(responseData.message);
//   //     } else {
//   //       console.log("Response Error:");
//   //     }
//   //   } catch (err) {
//   //     console.log("Error fetching data:");
//   //     console.error(err);
//   //   }
//   // };

//     const payMe = async (productId) => {
//         // console.log("payme");
//         // createOrder();
//         try {
//           if (!user || !user._id) {
//             Swal.fire({
//               icon: "error",
//               title: "Error",
//               text: "Please log in to make a purchase.",
//             });
//             return;
//           }

//           let data = { userId: user._id , productId };
//           let url = "http://localhost:3001/payment";
//           console.log(url);
//           console.log(data);
//           const response = await fetch(url, {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify(data),
//           });

//           console.log("Response Status:", response.status);
//           // console.log("Response order Amount:", response.orderamount);

//           if (response.ok) {
//             const responseData = await response.json();
//             console.log(responseData);
//             console.log("JSON Data:" + responseData);
//             // console.log(responseData.status);
//             console.log("JSON Data: status " + responseData.status);
//             // console.log(responseData.message);
//             console.log("JSON Data: message " + responseData.message);
//             doPayment(responseData.message);
//           } else {
//             console.log("Response Error:");
//           }
//         } catch (err) {
//           console.log("Error fetching data:");
//           console.error(err);
//         }
//       };
//   return (
//     <div>
//         <button className= "buyButton-intro" onClick={() => payMe(productId)}>Buy It Now</button>
//     </div>
//   )
// }

// export default CashFreeButton
