import React, { useState } from 'react';
// import './UserList.css';
import classes from "./UserList.module.css";

const UserList = () => {
  const [data, setData] = useState([
    {
      id: 1,
      username: 'john_doe',
      password: '********',
      email: 'john@example.com',
      ts: '2023-08-10',
    },
    {
      id: 2,
      username: 'jane_smith',
      password: '********',
      email: 'jane@example.com',
      ts: '2023-08-11',
    },
    // Add more sample data here...
  ]);

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Email ID',
      accessor: 'email',
    },
    {
      Header: 'Username',
      accessor: 'username',
    },
    {
      Header: 'Password',
      accessor: 'password',
    },
    {
      Header: 'Timestamp',
      accessor: 'ts',
    },
  ];

  return (
    <div>
      <h1 className={classes.user_list}>User List</h1>
      <table className="table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.accessor}>{column.Header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {columns.map((column) => (
                <td key={column.accessor}>{row[column.accessor]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
