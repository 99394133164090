// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import classes from "./PapercodeList.module.css";
// import { FixedSizeList } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";

// const Row = ({ data, index, style, handleStatusChange, handleDeleteQuestions }) => (
//   <tr
//     style={{
//       ...style,
//       display: "flex",
//       flexDirection: "row",
//       justifyContent: "space-between",
//       borderBottom: "1px solid black",
//     }}
//     className={index % 2 ? classes.even : classes.odd}
//   >
//     <td
//       style={{
//         borderRight: "1px solid grey",
//         borderLeft: "1px solid grey",
//         textAlign: "center",
//         flex: "0.25",
//         justifyContent:"center",
//         alignItems:"center",
//         display:"flex",
//       }}
//     >
//       {data[index].id}
//     </td>
//     <td
//       style={{
//         borderRight: "1px solid grey",
//         fontSize: "13px",
//         textAlign: "center",
//         flex: "0.5",
//         textAlign:"center",
//         justifyContent:"center",
//         alignItems:"center",
//         display:"flex"
//       }}
//     >
//       {data[index].paper_code && data[index].paper_code.substring(0, 50)}
//     </td>
//     <td
//       style={{
//         borderRight: "1px solid grey",
//         fontSize: "13px",
//         textAlign: "center",
//         flex: "0.5",
//         textAlign:"center",
//         justifyContent:"center",
//         alignItems:"center",
//         display:"flex"
//       }}
//     >
//       {data[index].test_title && data[index].test_title.substring(0, 50)}
//     </td>
//     <td
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.5",
//         justifyContent:"center",
//         alignItems:"center",
//         display:"flex"
//       }}
//     >
//       {data[index].start_date}
//     </td>

//     <td
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.5",
//         justifyContent:"center",
//         alignItems:"center",
//         display:"flex"
//       }}
//     >
//       <div
//         onClick={() =>
//           handleStatusChange(data[index].paper_code, data[index].id)
//         }
//       >
//         {data[index].status === "1" || data[index].status === "true" ? (
//           <button style={{ backgroundColor: "green", color: "white" }}>
//             {" "}
//             Active{" "}
//           </button>
//         ) : (
//           <button style={{ backgroundColor: "red", color: "white" }}>
//             {" "}
//             Inactive{" "}
//           </button>
//         )}
//       </div>
//     </td>
//     <td
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.25",
//         justifyContent:"center",
//         alignItems:"center",
//         display:"flex"
//       }}
//     >
//       {data[index].test_type}
//     </td>

//     <td style={{ flex: "0.5", textAlign: "center", paddingRight: "2px", justifyContent:"center", alignItems:"center", display:"flex"}}>
//       <Link
//         to={`/admin/view-questions/${data[index].paper_code}/${data[index].exam_mode_id}`}
//       >
//         View Questions
//       </Link>
//       <FontAwesomeIcon
//          icon={faTrash}
//          style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
//          onClick={() => handleDeleteQuestions(data[index].paper_code, data[index].exam_mode_id)}
//        />
//     </td>
//   </tr>
// );

// const PapercodeList = () => {
//   const [paperCodes, setPaperCodes] = useState([]);
//   // const [status, setStatus] = useState("Inactive");

//   const handleStatusChange = async (paperCode, id) => {
//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/updatepapercodestatus",
//         {
//           paper_code: paperCode,
//           id: id,
//         }
//       );
//       console.log(response.data);
//       if (response.data === "Active") {
//         alert("Active");
//         try {
//           const response = await axios.get(
//             process.env.REACT_APP_PINNACLE_BASE_URL + "/getpapercodes"
//           );
//           setPaperCodes(response.data);
//           console.log(response.data);
//         } catch (error) {
//           console.error("Error fetching data:", error);
//         }
//       } else {
//         alert("Inactive");
//         try {
//           const response = await axios.get(
//             process.env.REACT_APP_PINNACLE_BASE_URL + "/getpapercodes"
//           );
//           setPaperCodes(response.data);
//           console.log(response.data);
//         } catch (error) {
//           console.error("Error fetching data:", error);
//         }
//       }
//     } catch (error) {
//       console.error("Error updating paper code status:", error);
//     }
//   };

//   const handleDeleteQuestions = async (paperCode, examModeId) => {
//     const userConfirmed = window.confirm("Do you want to delete all questions?");
//     if (userConfirmed) {
//       try {
//         const response = await axios.delete(`${process.env.REACT_APP_PINNACLE_BASE_URL}/delete/${paperCode}/${examModeId}`);
//         if (response.status === 200) {
//           alert("All questions deleted successfully");
//           fetchData();
//         }
//       } catch (error) {
//         console.error("Error deleting questions:", error);
//         alert("Failed to delete questions");
//       }
//     } else {
//       alert("Deletion cancelled");
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(process.env.REACT_APP_PINNACLE_BASE_URL + "/getpapercodes");
//       setPaperCodes(response.data);
//       console.log("PAPER CODE LIST",response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <div>
//       <h1 className={classes.papercode_list_h1}>PaperCode List</h1>
//       {/* <div style={{ border: "1px solid grey", textAlign: "center" }}>
//         <ul
//           style={{
//             display: "flex",
//             padding: "0",
//             textAlign: "center",
//             background: "#f2f2f2",
//             margin: "0",
//             height: "35px",

//             flexDirection: "row",
//             listStyle: "none",
//           }}
//         >
//           <li style={{ flex: "0.25" }}>S.No.</li>
//           <li style={{ flex: "0.5" }}>Paper Code</li>
//           <li style={{ flex: "0.5" }}>Test Title</li>
//           <li style={{ flex: "0.5" }}>open Date</li>
//           <li style={{ flex: "0.5" }}>Status</li>
//           <li style={{ flex: "0.5" }}>Test Type</li>
//           <li style={{ flex: "0.5" }}>MORE</li>
//         </ul>
//       </div> */}

//       <div className={classes.header}>
//         <ul>
//           <li style={{ flex: "0.265" }}>S.No.</li>
//           <li style={{ flex: "0.60" }}>Paper Code</li>
//           <li style={{ flex: "0.60" }}>Test Title</li>
//           <li style={{ flex: "0.60" }}>Open Date</li>
//           <li style={{ flex: "0.60" }}>Status</li>
//           <li style={{ flex: "0.250" }}>Test Type</li>
//           <li style={{ flex: "0.64" }}>MORE</li>
//         </ul>
//       </div>
//       <AutoSizer>
//         {({ height, width }) => (
//           <FixedSizeList
//             height={350}
//             width={width}
//             itemCount={paperCodes.length}
//             itemSize={50}
//             style={{ height: "75vh" }}
//           >
//             {({ index, style }) => (
//               <Row
//                 data={paperCodes}
//                 index={index}
//                 style={style}
//                 handleStatusChange={handleStatusChange}
//                 handleDeleteQuestions={handleDeleteQuestions}
//               />
//             )}
//           </FixedSizeList>
//         )}
//       </AutoSizer>
//     </div>
//   );
// };

// export default PapercodeList;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import classes from "./PapercodeList.module.css";
// import { FixedSizeList } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";

// const Row = ({ data, index, style, handleStatusChange, handleDeleteQuestions }) => (
//   <div
//     style={{
//       ...style,
//       display: "flex",
//       flexDirection: "row",
//       justifyContent: "space-between",
//       borderBottom: "1px solid black",
//     }}
//     className={index % 2 ? classes.even : classes.odd}
//   >
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         borderLeft: "1px solid grey",
//         textAlign: "center",
//         flex: "0.25",
//         justifyContent: "center",
//         alignItems: "center",
//         display: "flex",
//       }}
//     >
//       {data[index].id}
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         fontSize: "13px",
//         textAlign: "center",
//         flex: "0.5",
//         justifyContent: "center",
//         alignItems: "center",
//         display: "flex",
//       }}
//     >
//       {data[index].paper_code && data[index].paper_code.substring(0, 50)}
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         fontSize: "13px",
//         textAlign: "center",
//         flex: "0.5",
//         justifyContent: "center",
//         alignItems: "center",
//         display: "flex",
//       }}
//     >
//       {data[index].test_title && data[index].test_title.substring(0, 50)}
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.5",
//         justifyContent: "center",
//         alignItems: "center",
//         display: "flex",
//       }}
//     >
//       {data[index].start_date}
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.5",
//         justifyContent: "center",
//         alignItems: "center",
//         display: "flex",
//       }}
//     >
//       <div onClick={() => handleStatusChange(data[index].paper_code, data[index].id)}>
//         {data[index].status === "1" || data[index].status === "true" ? (
//           <button style={{ backgroundColor: "green", color: "white" }}> Active </button>
//         ) : (
//           <button style={{ backgroundColor: "red", color: "white" }}> Inactive </button>
//         )}
//       </div>
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.25",
//         justifyContent: "center",
//         alignItems: "center",
//         display: "flex",
//       }}
//     >
//       {data[index].test_type}
//     </div>
//     <div
//       style={{
//         flex: "0.5",
//         textAlign: "center",
//         paddingRight: "2px",
//         justifyContent: "center",
//         alignItems: "center",
//         display: "flex",
//       }}
//     >
//       <Link to={`/admin/view-questions/${data[index].paper_code}/${data[index].exam_mode_id}`}>
//         View Questions
//       </Link>
//       <FontAwesomeIcon
//         icon={faTrash}
//         style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
//         onClick={() => handleDeleteQuestions(data[index].paper_code, data[index].exam_mode_id)}
//       />
//     </div>
//   </div>
// );

// const PapercodeList = () => {
//   const [paperCodes, setPaperCodes] = useState([]);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(process.env.REACT_APP_PINNACLE_BASE_URL + "/getpapercodes");
//       setPaperCodes(response.data);
//       console.log("PAPER CODE LIST", response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleStatusChange = async (paperCode, id) => {
//     try {
//       const response = await axios.post(process.env.REACT_APP_PINNACLE_BASE_URL + "/updatepapercodestatus", {
//         paper_code: paperCode,
//         id: id,
//       });
//       console.log(response.data);
//       alert(response.data === "Active" ? "Active" : "Inactive");
//       fetchData();
//     } catch (error) {
//       console.error("Error updating paper code status:", error);
//     }
//   };

//   const handleDeleteQuestions = async (paperCode, examModeId) => {
//     const userConfirmed = window.confirm("Do you want to delete all questions?");
//     if (userConfirmed) {
//       try {
//         const response = await axios.delete(`${process.env.REACT_APP_PINNACLE_BASE_URL}/delete/${paperCode}/${examModeId}`);
//         if (response.status === 200) {
//           alert("All questions deleted successfully");
//           fetchData();
//         }
//       } catch (error) {
//         console.error("Error deleting questions:", error);
//         alert("Failed to delete questions");
//       }
//     } else {
//       alert("Deletion cancelled");
//     }
//   };

//   return (
//     <div>
//       <h1 className={classes.papercode_list_h1}>PaperCode List</h1>
//       <div className={classes.header}>
//         <ul>
//           <li style={{ flex: "0.265" }}>S.No.</li>
//           <li style={{ flex: "0.60" }}>Paper Code</li>
//           <li style={{ flex: "0.60" }}>Test Title</li>
//           <li style={{ flex: "0.60" }}>Open Date</li>
//           <li style={{ flex: "0.60" }}>Status</li>
//           <li style={{ flex: "0.250" }}>Test Type</li>
//           <li style={{ flex: "0.64" }}>MORE</li>
//         </ul>
//       </div>
//       <AutoSizer>
//         {({ height, width }) => (
//           <FixedSizeList
//             height={350}
//             width={width}
//             itemCount={paperCodes.length}
//             itemSize={50}
//             style={{ height: "75vh" }}
//           >
//             {({ index, style }) => (
//               <Row
//                 data={paperCodes}
//                 index={index}
//                 style={style}
//                 handleStatusChange={handleStatusChange}
//                 handleDeleteQuestions={handleDeleteQuestions}
//               />
//             )}
//           </FixedSizeList>
//         )}
//       </AutoSizer>
//     </div>
//   );
// };

// export default PapercodeList;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import classes from "./PapercodeList.module.css";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { FaPencilAlt } from "react-icons/fa";
import Cookies from "universal-cookie";
import debounce from "lodash.debounce";

const Row = ({
  data,
  index,
  style,
  handleStatusChange,
  handleDeleteQuestions,
  handleFullDelete,
  toggleDeleteVisibility,
  showDelete,
  handleTakeTest,
  handleDeleteTakeTest,
  email_id,
}) => (
  // console.log(
  //   "line 505",
  //   data
  // )(
  <div
    style={{
      ...style,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: "1px solid black",
    }}
    className={index % 2 ? classes.even : classes.odd}
  >
    <div
      style={{
        borderRight: "1px solid grey",
        borderLeft: "1px solid grey",
        textAlign: "center",
        flex: "0.25",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {data[index].id}
    </div>
    <div
      style={{
        borderRight: "1px solid grey",
        fontSize: "13px",
        textAlign: "center",
        flex: "0.5",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {data[index].paper_code && data[index].paper_code.substring(0, 50)}
    </div>
    <div
      style={{
        borderRight: "1px solid grey",
        fontSize: "13px",
        textAlign: "center",
        flex: "0.5",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {data[index].test_title && data[index].test_title.substring(0, 50)}
    </div>
    <div
      style={{
        borderRight: "1px solid grey",
        textAlign: "center",
        flex: "0.5",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {data[index].start_date}
    </div>
    <div
      style={{
        borderRight: "1px solid grey",
        textAlign: "center",
        flex: "0.5",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div
        onClick={() =>
          handleStatusChange(data[index].paper_code, data[index].id)
        }
      >
        {data[index].status === "1" || data[index].status === "true" ? (
          <button style={{ backgroundColor: "green", color: "white" }}>
            Active
          </button>
        ) : (
          <button style={{ backgroundColor: "red", color: "white" }}>
            Inactive
          </button>
        )}
      </div>
    </div>
    <div
      style={{
        borderRight: "1px solid grey",
        textAlign: "center",
        flex: "0.25",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {data[index].test_type}
    </div>
    <div
      style={{
        flex: "0.5",
        textAlign: "center",
        paddingRight: "2px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        position: "relative",
      }}
    >
      <Link
        to={`/admin/view-questions/${data[index].paper_code}/${data[index].exam_mode_id}`}
      >
        View Questions
      </Link>
      <FaPencilAlt
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={() => toggleDeleteVisibility(data[index].id)}
      />
      {showDelete === data[index].id && (
        <div
          style={{
            position: "absolute", // Ensures the element stays in the same position even when scrolling
            transform: "translateX(5%)", // Adjust for centering alignment
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white", // Optional: to distinguish from background
            padding: "15px", // Optional: for spacing
            border: "1px solid #ddd", // Optional: border for visibility
            borderRadius: "5px", // Optional: rounded corners
            boxShadow: "0px 40px 8px rgba(0, 0, 0, 0.1)", // Optional: shadow for emphasis
            zIndex: 1000, // Ensures it's above other content
            marginTop: "95%",
          }}
        >
          <div
            onClick={() =>
              handleDeleteTakeTest(
                data[index].paper_code,
                email_id,
              )
            }
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: "red", cursor: "pointer" }}
            />
            <span style={{ marginLeft: "8px", cursor: "pointer" }}>
              Take Test Data
            </span>
          </div>
          <div onClick={() => handleTakeTest(data[index].paper_code)}>
            <FontAwesomeIcon
              icon={faIdCard}
              style={{ color: "black", cursor: "pointer", marginTop: "16px" }}
            />
            <span style={{ marginLeft: "8px", cursor: "pointer" }}>
              Take Test
            </span>
          </div>
          <div
            onClick={() =>
              handleDeleteQuestions(
                data[index].paper_code,
                data[index].exam_mode_id,
                data[index].exam_tier_id
              )
            }
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: "red", cursor: "pointer", marginTop: "16px" }}
            />
            <span style={{ marginLeft: "8px", cursor: "pointer" }}>
              Mock Delete
            </span>
          </div>
          <div
            onClick={() =>
              handleFullDelete(
                data[index].paper_code,
                data[index].exam_mode_id,
                data[index].exam_tier_id
              )
            }
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: "red", cursor: "pointer", marginTop: "16px" }}
            />
            <span style={{ marginLeft: "8px", cursor: "pointer" }}>
              Full Delete
            </span>
          </div>
        </div>
      )}
    </div>
  </div>
);

const PapercodeList = () => {
  const [paperCodes, setPaperCodes] = useState([]);
  const [filteredPaperCodes, setFilteredPaperCodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDelete, setShowDelete] = useState(null);
  const cookies = new Cookies();
  const token = cookies.get("token");
  const navigate = useNavigate();
  console.log(token);

  useEffect(() => {
    fetchData();
  }, []);

  const userProfile = useSelector((state) => state.userProfile);
  const email_id = userProfile.email_id;

  console.log("User Profile:", userProfile);
  console.log("User Email:", email_id);


  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/getpapercodes",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setPaperCodes(response.data);
  //     setFilteredPaperCodes(response.data);
  //     console.log("PAPER CODE LIST", response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PINNACLE_BASE_URL + "/api/v1/admin/getpapercodes",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // axios.get("http://localhost:5000/getpapercodescgltier2"),

      // Combine both data arrays
      // const combinedData = [...response1.data, ...response2.data];

      setPaperCodes(response.data);
      setFilteredPaperCodes(response.data);
      console.log("PAPER CODE LIST", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      const lowercasedFilter = searchValue.trim().toLowerCase();
      const filtered = paperCodes.filter(
        (paperCode) =>
          paperCode.paper_code &&
          paperCode.paper_code.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredPaperCodes(filtered);
    }, 300),
    [paperCodes]
  );

  // useEffect(() => {
  //   const lowercasedFilter = searchTerm.trim().toLowerCase();
  //   const filtered = paperCodes.filter(
  //     (paperCode) =>
  //       paperCode.paper_code &&
  //       paperCode.paper_code.toLowerCase().includes(lowercasedFilter)
  //   );
  //   setFilteredPaperCodes(filtered);
  // }, [searchTerm, paperCodes]);

  // const handleSearch = useMemo(
  //   () =>
  //     debounce((searchValue) => {
  //       if (!searchValue) {
  //         setFilteredPaperCodes(paperCodes);
  //         return;
  //       }
  //       const lowercasedFilter = searchValue.trim().toLowerCase();
  //       const filtered = paperCodes.filter(
  //         (paperCode) =>
  //           paperCode.paper_code &&
  //           paperCode.paper_code.toLowerCase().includes(lowercasedFilter)
  //       );
  //       setFilteredPaperCodes(filtered);
  //     }, 300),
  //   [paperCodes]
  // );

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedSearch(value);
  };
  const handleStatusChange = async (paperCode, id) => {
    // console.log(paperCode, id);
    // console.log(process.env.REACT_APP_PINNACLE_BASE_URL);
    // console.log(token);
    try {
      console.log("handleStatusChange called");
      console.log(
        "Request URL:",
        process.env.REACT_APP_PINNACLE_BASE_URL + "/updatepapercodestatus"
      );
      console.log("Request Body:", { paper_code: paperCode, id: id });
      const response = await axios.post(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/admin/updatepapercodestatus",
        // "/updatepapercodestatus",

        {
          paper_code: paperCode,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      alert(response.data === "Active" ? "Active" : "Inactive");
      fetchData();
    } catch (error) {
      console.error("Error updating paper code status:", error);
    }
  };

  const handleDeleteQuestions = async (paperCode, examModeId) => {
    const userConfirmed = window.confirm(
      "Do you want to delete all questions?"
    );
    if (userConfirmed) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/delete/${paperCode}/${examModeId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          alert("All questions deleted successfully");
          fetchData();
        }
      } catch (error) {
        console.error("Error deleting questions:", error);
        alert("Failed to delete questions");
      }
    } else {
      alert("Deletion cancelled");
    }
  };

  const handleFullDelete = async (paperCode, examModeId) => {
    const userConfirmed = window.confirm(
      "Do you want to delete paper code and its all questions?"
    );
    if (userConfirmed) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/delete/fulldelete/${paperCode}/${examModeId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          alert("Paper code & all questions deleted successfully");
          fetchData();
        }
      } catch (error) {
        console.error("Error deleting paper code & questions:", error);
        alert("Failed to delete paper code & questions");
      }
    } else {
      alert("Deletion cancelled");
    }
  };

  // // Updated handleTakeTest function
  // const handleTakeTest = (paperCode) => {
  //   // const encodedPaperCode = encodeBase64(paperCode);
  //   navigate(`/admin/papercode/instruction/${paperCode}`);
  // };

  // Function to fetch necessary parameters based on papercode
  const fetchParamsForPapercode = async (paperCode) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/getParamsByPapercode/${paperCode}`,
        // `${process.env.REACT_APP_PINNACLE_BASE_URL}/getParamsByPapercode/${paperCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching parameters:", error);
      return null;
    }
  };

  const handleTakeTest = async (paperCode) => {
    // Fetch additional parameters for the papercode
    const params = await fetchParamsForPapercode(paperCode);

    if (params) {
      const {
        exam_id,
        post_id,
        exam_mode_id,
        test_series_id,
        tier_id,
        product_id,
        test_title,
      } = params;

      console.log("Fetching parameters", params);

      console.log(`/Instruction/${exam_mode_id}/${btoa(paperCode)}/${
          test_series_id
        }/${test_title}/${tier_id}/${exam_id}/${post_id}/${product_id}`);
      // Navigate with the fetched parameters
      navigate(
        `/Instruction/${exam_mode_id}/${btoa(paperCode)}/${
          test_series_id
        }/${test_title}/${tier_id}/${exam_id}/${post_id}/${product_id}`
      );
    } else {
      alert("Failed to fetch parameters for the test.");
    }
  };

  const handleDeleteTakeTest = async (paperCode, email_id) => { 
    const userConfirmed = window.confirm(
      `Do you want to delete the test for user with email & paper_code: ${email_id} ${paperCode}?`
    );
    if (userConfirmed) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/delete-take-test-data`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { email_id, paper_code: paperCode } // Correct format for DELETE request with body
          }
        );
        console.log(response);
         
        if (response.status === 200) {
          alert("Test deleted successfully for the user");
          fetchData();
        }
      } catch (error) {
        console.error("Error deleting test:", error);
        alert("Failed to delete test");
      }
    } else {
      alert("Deletion cancelled");
    }
  };
  

  const toggleDeleteVisibility = (id) => {
    setShowDelete((prevId) => (prevId === id ? null : id));
  };

  return (
    <div>
      <h1 className={classes.papercode_list_h1}>Paper Code List</h1>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by paper code"
        value={searchTerm}
        onChange={handleInputChange}
        className={classes.searchBar}
      />

      <div className={classes.header}>
        <ul>
          <li style={{ flex: "0.265" }}>S.No.</li>
          <li style={{ flex: "0.60" }}>Paper Code</li>
          <li style={{ flex: "0.60" }}>Test Title</li>
          <li style={{ flex: "0.60" }}>Open Date</li>
          <li style={{ flex: "0.60" }}>Status</li>
          <li style={{ flex: "0.250" }}>Test Type</li>
          <li style={{ flex: "0.64" }}>MORE</li>
        </ul>
      </div>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={350}
            width={width}
            // itemCount={paperCodes.length}
            itemCount={filteredPaperCodes.length}
            itemSize={50}
            style={{ height: "75vh" }}
          >
            {({ index, style }) => (
              <Row
                // data={paperCodes}
                data={filteredPaperCodes}
                index={index}
                style={style}
                handleStatusChange={handleStatusChange}
                handleDeleteQuestions={handleDeleteQuestions}
                handleFullDelete={handleFullDelete}
                toggleDeleteVisibility={toggleDeleteVisibility}
                showDelete={showDelete}
                handleTakeTest={handleTakeTest}
                handleDeleteTakeTest={handleDeleteTakeTest}
                email_id={email_id}
                // exam_tier_id={exam_tier_id}
              />
            )}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};

export default PapercodeList;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import classes from "./PapercodeList.module.css";
// import { FixedSizeList } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";

// const Row = ({ data, index, style, handleStatusChange, handleDelete }) => (
//   <div
//     style={{
//       ...style,
//       display: "flex",
//       flexDirection: "row",
//       justifyContent: "space-between",
//       borderBottom: "1px solid black",
//       alignItems: "center",
//     }}
//     className={index % 2 ? classes.even : classes.odd}
//   >
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         borderLeft: "1px solid grey",
//         textAlign: "center",
//         flex: "0.25",
//       }}
//     >
//       {data[index].id}
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         fontSize: "13px",
//         textAlign: "center",
//         flex: "0.5",
//       }}
//     >
//       {data[index].paper_code && data[index].paper_code.substring(0, 50)}
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         fontSize: "13px",
//         textAlign: "center",
//         flex: "0.5",
//       }}
//     >
//       {data[index].test_title && data[index].test_title.substring(0, 50)}
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.5",
//       }}
//     >
//       {data[index].start_date}
//     </div>

//     <div
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.5",
//       }}
//     >
//       <div
//         onClick={() =>
//           handleStatusChange(data[index].paper_code, data[index].id)
//         }
//       >
//         {data[index].status === "1" || data[index].status === "true" ? (
//           <button style={{ backgroundColor: "green", color: "white" }}>
//             Active
//           </button>
//         ) : (
//           <button style={{ backgroundColor: "red", color: "white" }}>
//             Inactive
//           </button>
//         )}
//       </div>
//     </div>
//     <div
//       style={{
//         borderRight: "1px solid grey",
//         textAlign: "center",
//         flex: "0.25",
//       }}
//     >
//       {data[index].test_type}
//     </div>

//     <div style={{ flex: "0.5", textAlign: "center", paddingRight: "2px" }}>
//       <Link
//         to={`/admin/view-questions/${data[index].paper_code}/${data[index].exam_mode_id}`}
//       >
//         View Questions
//       </Link>
//       <FontAwesomeIcon
//         icon={faTrash}
//         style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
//         onClick={() => handleDelete(data[index].paper_code)}
//       />
//     </div>
//   </div>
// );

// const PapercodeList = () => {
//   const [paperCodes, setPaperCodes] = useState([]);
//   const [status, setStatus] = useState("Inactive");

//   const handleStatusChange = async (paperCode, id) => {
//     try {
//       const response = await axios.post(
//         process.env.REACT_APP_PINNACLE_BASE_URL + "/updatepapercodestatus",
//         {
//           paper_code: paperCode,
//           id: id,
//         }
//       );
//       console.log(response.data);
//       if (response.data === "Active") {
//         alert("Active");
//       } else {
//         alert("Inactive");
//       }
//       fetchData();
//     } catch (error) {
//       console.error("Error updating paper code status:", error);
//     }
//   };

//   const handleDelete = async (paperCode) => {
//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_PINNACLE_BASE_URL}/deletepapercode/${paperCode}`
//       );
//       console.log(response.data);
//       alert("Deleted successfully");
//       fetchData();
//     } catch (error) {
//       console.error("Error deleting paper code:", error);
//     }
//   };

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(process.env.REACT_APP_PINNACLE_BASE_URL + "/getpapercodes");
//       setPaperCodes(response.data);
//       console.log(response.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   return (
//     <div>
//       <h1 className={classes.papercode_list_h1}>PaperCode List</h1>
//       <div className={classes.header}>
//         <ul>
//           <li style={{ flex: "0.265" }}>S.No.</li>
//           <li style={{ flex: "0.60" }}>Paper Code</li>
//           <li style={{ flex: "0.60" }}>Test Title</li>
//           <li style={{ flex: "0.60" }}>Open Date</li>
//           <li style={{ flex: "0.60" }}>Status</li>
//           <li style={{ flex: "0.250" }}>Test Type</li>
//           <li style={{ flex: "0.64" }}>MORE</li>
//         </ul>
//       </div>
//       <AutoSizer>
//         {({ height, width }) => (
//           <FixedSizeList
//             height={350}
//             width={width}
//             itemCount={paperCodes.length}
//             itemSize={50}
//             style={{ height: "75vh" }}
//           >
//             {({ index, style }) => (
//               <Row
//                 data={paperCodes}
//                 index={index}
//                 style={style}
//                 handleStatusChange={handleStatusChange}
//                 handleDelete={handleDelete}
//               />
//             )}
//           </FixedSizeList>
//         )}
//       </AutoSizer>
//     </div>
//   );
// };

// export default PapercodeList;
