// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  /* VerifiedEmail.css */
.VerifiedEmailMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9; /* Light background for better contrast */
    padding: 20px;
    box-sizing: border-box;
  }
  
  .VerifiedEmailMessage img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  .VerifiedEmailMessage h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333; /* Darker text for better readability */
  }
  
  .VerifiedEmailMessage p {
    font-size: 1.2rem;
    color: #666; /* Slightly lighter text for the paragraph */
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  `, "",{"version":3,"sources":["webpack://./src/Pages/VerifiedEmail.css"],"names":[],"mappings":";EACE,sBAAsB;AACxB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,yBAAyB,EAAE,yCAAyC;IACpE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW,EAAE,uCAAuC;EACtD;;EAEA;IACE,iBAAiB;IACjB,WAAW,EAAE,4CAA4C;EAC3D;;EAEA;IACE,oCAAoC;IACpC,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,gCAAgC;EAClC;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["\n  /* VerifiedEmail.css */\n.VerifiedEmailMessage {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    text-align: center;\n    background-color: #f9f9f9; /* Light background for better contrast */\n    padding: 20px;\n    box-sizing: border-box;\n  }\n  \n  .VerifiedEmailMessage img {\n    width: 100px;\n    height: 100px;\n    margin-bottom: 20px;\n  }\n  \n  .VerifiedEmailMessage h1 {\n    font-size: 2rem;\n    margin-bottom: 10px;\n    color: #333; /* Darker text for better readability */\n  }\n  \n  .VerifiedEmailMessage p {\n    font-size: 1.2rem;\n    color: #666; /* Slightly lighter text for the paragraph */\n  }\n  \n  .spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    width: 36px;\n    height: 36px;\n    border-radius: 50%;\n    border-left-color: #09f;\n    animation: spin 1s ease infinite;\n  }\n  \n  @keyframes spin {\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
