// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddTestSeriesProduct_working_area_start__yM3F- {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
}

.AddTestSeriesProduct_label_intro_section__m2EZQ {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AddTestSeriesProduct_user_input_section__IFoYu {
  border-radius: 5px;
  outline: none;
}

.AddTestSeriesProduct_basic_functionality_of_submit_button__o0z-- {
  text-align: center;
}

.AddTestSeriesProduct_save_button__OP3\\+b {
  width: 25%;
  background-color: blue;
  border-radius: 5px;
  border: none;
  height: 3rem;
}
`, "",{"version":3,"sources":["webpack://./src/admin/components/Dashboard/AddTestSeriesProduct.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd","sourcesContent":[".working_area_start {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  padding: 2rem;\n}\n\n.label_intro_section {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.user_input_section {\n  border-radius: 5px;\n  outline: none;\n}\n\n.basic_functionality_of_submit_button {\n  text-align: center;\n}\n\n.save_button {\n  width: 25%;\n  background-color: blue;\n  border-radius: 5px;\n  border: none;\n  height: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"working_area_start": `AddTestSeriesProduct_working_area_start__yM3F-`,
	"label_intro_section": `AddTestSeriesProduct_label_intro_section__m2EZQ`,
	"user_input_section": `AddTestSeriesProduct_user_input_section__IFoYu`,
	"basic_functionality_of_submit_button": `AddTestSeriesProduct_basic_functionality_of_submit_button__o0z--`,
	"save_button": `AddTestSeriesProduct_save_button__OP3+b`
};
export default ___CSS_LOADER_EXPORT___;
