// // import React, { useEffect, useRef } from "react";
// // import parse from "html-react-parser";
// // import classes from './MathMl.module.css';

// // const MathElement = ({ elements }) => {

// //   const ref = useRef(null);
// //   const element = parse(elements);

// //   console.log("ELEMENT WITHOUT UPDATE", elements);
// //   console.log("ELEMENT", element);

// //   return <div ref={ref} className={classes.mathElementDiv}>{element}</div>;
// // };

// // export default MathElement;

// import React from "react";
// import parse from "html-react-parser";
// import classes from "./MathMl.module.css";

// const MathElement = ({ elements }) => {

//   const parsedContent = parse(elements);

//   return (
//     <div className={classes.mathElementDiv}>
//       {parsedContent}
//     </div>
//   );
// };

// export default MathElement;

// import React from "react";
// import parse from "html-react-parser";
// import MathJax from "react-mathjax-preview";
// import classes from "./MathMl.module.css";
// // import MathJax from 'react-mathjax';

// const MathElement = ({ elements }) => {
//   // const imgRegex = /<img[^>]+src="?([^"\s]+)"?[^>]*>/i;
//   // const hasMathTag = /<math[^>]*>/i.test(elements);
//   // Regex to extract <img> tags and their `src`
//   const imgRegex = /<img[^>]+src="?([^"\s]+)"?[^>]*>/gi;
//   const hasMathTag = /<math[^>]*>/i.test(elements);

// let hasImageTag = 0;
//     let images = [];

// if(elements.match(imgRegex)){
//   images.push(elements.match(imgRegex)[0]);
// }

// if(images.length > 0){
//   console.log("IMAGES", images);
//   hasImageTag = 1;
// }

// Extract all images
//   const images = [];
//   let match;
//   while ((match = imgRegex.exec(elements)) !== null) {
//     images.push(match[0]); // Add full <img> tag
//   }

//   // Remove images from the original elements
//   const cleanElements = elements.replace(imgRegex, "");

//   // Function to render images
//   const renderImages = () => {
//     return images.map((imgTag, index) => {
//       // Use html-react-parser to parse each <img> tag
//       return <div key={index}>{parse(imgTag)}</div>;
//     });
//   };

//   return (
//     <div className={classes.mathElementDiv}>
//       {hasMathTag ? (
//         <>
//           {/* Render math content using MathJax */}
//           <MathJax math={cleanElements} />
//           {/* Render images after the math content */}
//           {renderImages()}
//           {/*
//         <MathJax math={elements} />
//         */}
//         </>
//       ) : (
//         parse(elements)
//       )}
//     </div>
//   );
// };

// export default MathElement;

// import React from "react";
// import parse from "html-react-parser";
// import MathJax from "react-mathjax-preview";
// import classes from "./MathMl.module.css";

// const formatElements = (text) => {
//   if (!text) return "";

//   // Replace HTML tags and newline characters with <br />
//   return (
//     text
//       .replace(/<p([^>]*)>/gi, '<p$1 style="display:inline;">') // Apply display:inline to <p> tags
//       // Replace other HTML tags with <br />
//       .replace(/\n|\r/g, <br/>)
//   ); // Replace newline characters with <br />
//   // Remove consecutive <br /> tags
// };

// const MathElement = (props) => {
//   // const elements = props.elements
//   //   .replace(/<p([^>]*)>/gi, "<span$1>")
//   //   .replace(/<\/p>/gi, "</span>");
//   // const elements = props.elements.replace(
//   //   /<p([^>]*)>/gi,
//   //   '<p$1 style="display:inline;">'
//   // );
//   const elements = formatElements(props.elements);

//   console.log("mathElement", props);

//   // Regex to extract <img> tags and their `src`
//   const imgRegex = /<img[^>]+src="?([^"\s]+)"?[^>]*>/gi;
//   const hasMathTag = /<math[^>]*>/i.test(elements);

//   // Extract all images
//   const images = [];
//   let match;
//   while ((match = imgRegex.exec(elements)) !== null) {
//     images.push(match[0]); // Add full <img> tag
//   }

//   // Remove images from the original elements to extract clean math content
//   const cleanElements = elements.replace(imgRegex, "");

//   // Function to render images
//   const renderImages = () => {
//     return images.map((imgTag, index) => (
//       // <span key={index} style={{ display: marginRight: "10px" }}>
//       <span key={index} style={{ display: "block", marginRight: "10px" }}>
//         {parse(imgTag)}
//       </span>
//     ));
//   };

//   // Regex to match math tags
//   const mathRegex = /<math[^>]*>.*?<\/math>/gi;

//   // Split elements into math blocks and HTML blocks
//   const parts = elements.split(mathRegex);

//   // Extract all math tags separately
//   const mathParts = [...elements.matchAll(mathRegex)].map((match) => match[0]);

//   // console.log("PARTS", parts);
//   // console.log("MATHPARTS", mathParts);

//   return (
//     <div className={classes.mathElementDiv}>
//       {/* If both math and images are present */}
//       {hasMathTag && images.length > 0 && (
//         <>
//           {/*
//             <MathJax math={cleanElements} />
//             */}

//           {parts.map((part, index) => (
//             <React.Fragment key={index}>
//               {/* Render non-math HTML content */}
//               {parse(part)}

//               {/* If there's a corresponding math part, render it */}
//               {mathParts[index] && <MathJax math={mathParts[index]} />}
//             </React.Fragment>
//           ))}
//           {/*renderImages()*/}
//         </>
//       )}

//       {/* If only math content is present */}
//       {hasMathTag && images.length === 0 && (
//         <>
//           {parts.map((part, index) => (
//             <React.Fragment key={index}>
//               {/* Render non-math HTML content */}
//               {parse(part)}

//               {/* If there's a corresponding math part, render it */}
//               {mathParts[index] && <MathJax math={mathParts[index]} />}
//             </React.Fragment>
//           ))}
//           {/*
//         <MathJax math={cleanElements} />
//         */}
//         </>
//       )}

//       {/* If only images are present */}

//       {!hasMathTag && images.length > 0 && parse(elements)}

//       {/* If no math or images are present, render as normal HTML */}
//       {!hasMathTag && images.length === 0 && parse(elements)}
//     </div>
//   );
// };

// export default MathElement;
import React, { useEffect } from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";

const MathElement = ({ elements }) => {
  const config = {
    loader: { load: ["input/tex", "input/mml", "output/chtml"] }, // Load both TeX and MathML for MathJax
    tex: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
    },
  };

  useEffect(() => {
    if (window.MathJax) {
      console.log("MathJax version:", window.MathJax.version); // Check MathJax version
    }
  }, []);

  // Check if content has HTML tags
  const hasHtmlContent = (text) => /<[^>]+>/.test(text);

  // Function to render content based on its type
  const renderMathContent = (content) => {
    return hasHtmlContent(content) ? (
      // Render as HTML if it contains tags
      <div dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
      // Render with MathJax if it's pure math
      <MathJax>{content}</MathJax>
    );
  };

  return (
    <MathJaxContext config={config}>
      <div style={{ fontFamily: "Nirmala UI, Cambria Math, sans-serif" }}>
        {renderMathContent(elements)}
      </div>
    </MathJaxContext>
  );
};

export default MathElement;
