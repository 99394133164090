// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate } from "react-router-dom";

// const RoleBasedAccess = (allowedRoles) => {
//   const state = useSelector((state) => state);
//   console.log("Redux State:", state.userProfile);

//   console.log(allowedRoles);

//   // Return a functional component
//   return (WrappedComponent) => {
//     const WithRoleAccess = (props) => {
//       // Use useSelector directly in the component function
//       const userProfile = useSelector((state) => state.userProfile);

//       // Properly log the userProfile object
//       console.log("User Profile:", userProfile);

//       if (!userProfile || !allowedRoles.includes(userProfile.role)) {
//         // Redirect to unauthorized page if the user does not have the required role
//         return <Navigate to="/unauthorized" replace />;
//       }

//       // Render the wrapped component if the user has the required role
//       return <WrappedComponent {...props} />;
//     };

//     return WithRoleAccess;
//   };
// };

// export default RoleBasedAccess;
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RoleBasedAccess = (allowedRoles) => {
  console.log("Initializing RoleBasedAccess with roles:", allowedRoles);

  return (WrappedComponent) => {
    // console.log(WrappedComponent);
    const WithRoleAccess = (props) => {
      console.log(props);
      const userProfile = useSelector((state) => state.userProfile);

      console.log("Allowed Roles in component:", allowedRoles);
      console.log("User Profile:", userProfile);

      if (!userProfile || !allowedRoles.includes(userProfile.role)) {
        return <Navigate to="/unauthorized" replace />;
      }

      return <WrappedComponent {...props} />;
    };

    return WithRoleAccess;
  };
};

export default RoleBasedAccess;
