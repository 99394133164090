import React, { useState, useEffect } from "react";

const CountDownTimer = ({ hoursMinSecs, pause, rmTimeFun, onTimeUp }) => {
  // console.log("CHECKING THE PAUSE PROPS", pause, typeof pause);

  const { hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds]);

  const tick = () => {
    if (hrs == 0 && mins == 0 && secs == 0) {
      onTimeUp();
    } else if (mins == 0 && secs == 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs == 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  };

  const reset = () =>
    setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

  React.useEffect(() => {
    const timerId = setInterval(() => {
      if (!pause) {
        tick();
      }
    }, 1000);
    return () => clearInterval(timerId);
  }, [secs, pause]);

  let rTem =
    hrs.toString().padStart(2, "0") +
    ":" +
    mins.toString().padStart(2, "0") +
    ":" +
    secs.toString().padStart(2, "0");

  useEffect(() => {
    rmTimeFun(rTem);
  }, [secs]);

  return (
    <>
      {`${hrs.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`}

      {/* {`<span>${hrs.toString().padStart(2, '0')}</span>:<span>${mins
                .toString()
                .padStart(2, '0')}</span>:<span>${secs.toString().padStart(2, '0')}</span>`} */}
    </>
  );
};

export default CountDownTimer;
