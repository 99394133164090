import React from "react";
import classes from "./Footer.module.css";
import DepthAnalysis from "../images/vecteezy_vector-illustration-of-financial-data-graph-chart-trend_25249648.jpg";

const DepthAnalysisCard = () => {
  return (
    <>
      <div className={classes.container}>
        <div className={classes.footer_individual_section}>
          <img
            className={classes.image_sub_section}
            src={DepthAnalysis}
            alt="depthanalysis"
          />
          <h4> In-depth Performance Analysis</h4>
          <p>
            Get Insights on your strength & Weakness .All india Rank &
            Performance comparison with the Topper
          </p>
        </div>
      </div>
    </>
  );
};

export default DepthAnalysisCard;
