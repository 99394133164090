import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import classes from "./UpdateOverlay.module.css";
import ReactDom from "react-dom";
import axios from "axios";
import Cookies from "universal-cookie";

const Backdrop = () => {
  return <div className={classes.backdrop}></div>;
};

const UpdateOverlay = ({ onClose, exam_mode_id, paper_code, exam_tier_id, exam_post_id }) => {
  const [selectedSubject, setSelectedSubject] = useState("6");
  const [subjectData, setSubjectData] = useState(null);
  const [handleRange, setHandleRange] = useState();
  const [questionRange, setQuestionRange] = useState("");
  const [loader, setLoader] = useState(false);

  const cookies = new Cookies();

  const token = cookies.get("token");
  const handleSubjectChange = (e) => {
    console.log(e.target.value);
    setSelectedSubject(e.target.value);
  };
  // alert(exam_mode_id)
  // alert(paper_code)

  useEffect(() => {
    fetchSubject();
  }, []);

  const fetchSubject = async (req, res) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PINNACLE_BASE_URL +
          "/api/v1/admin/getupdatesubjectname",
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      const Data = response.data;

      setSubjectData(Data);
      // console.log(response.data);
    } catch (error) {
      console.error("error fetching  subject data:", error);
    }
  };
  // console.log(subjectData);

  const handleSelectrange = (e) => {
    setHandleRange(e.target.value);
  };

  const handleinputRangeChange = (e) => {
    setQuestionRange(e.target.value);
  };

  const handleFormDataSubmit = async () => {
    // e.preventDefault();
    const rangeParts = questionRange
      .split("-")
      .map((part) => parseInt(part.trim(), 10));
    if (
      rangeParts.length !== 2 ||
      isNaN(rangeParts[0]) ||
      isNaN(rangeParts[1])
    ) {
      alert('Invalid range format. Please use "start-end" format.');
      return;
    }

    const range = {
      start: rangeParts[0], // adjust for 0-based index
      end: rangeParts[1], // adjust for 0-based index
    };
    console.log("starting range is: " + range.start);
    console.log("ending range is: " + range.end);

    const formData = {
      selectedSubject,
      range,
      exam_mode_id,
      paper_code,
      exam_tier_id,
      exam_post_id,
    };

    try {
      // console.log("entered into try catch");
      setLoader(true);
      const Response = await axios.post(
        `${process.env.REACT_APP_PINNACLE_BASE_URL}/api/v1/admin/updatesubjectrange`,

        formData,
        {
          headers: {
            Authorization: `Bearer ${token} `,
          },
        }
      );
      console.log("response:" + Response.data);
      if (Response.status == 200) {
        setLoader(false);
        alert("Range Updated Successfully!!!");
      } else {
        alert("Range Updation failed !!!");
      }
    } catch (error) {
      console.error("Error submitting form data", error);
    }
  };

  return (
    <div className={classes.updateOverlayModel}>
      {loader ? (
        <div style={{ marginTop: "20%" }}>
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <p>Updating plzzz wait ....</p>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.header_section}>
            <h4>Edit subject</h4>
            <RxCross1 onClick={onClose} />
          </div>
          <div className={classes.choose_subject}>
            <label>Select Subject</label>
            <select value={selectedSubject} onChange={handleSubjectChange}>
              {subjectData &&
                subjectData.map((variousSubject, index) => (
                  <option value={variousSubject.id} key={index}>
                    {variousSubject.subject_name}
                  </option>
                ))}
            </select>
          </div>
          <div className={classes.choose_range}>
            <input
              onChange={handleSelectrange}
              value="1"
              type="radio"
              name="range"
              checked={handleRange == "1"}
            />
            <label>Whole Paper</label>
            <input
              onChange={handleSelectrange}
              value="2"
              type="radio"
              name="range"
              checked={handleRange == "2"}
            />
            <label>Single Question</label>
            <input
              onChange={handleSelectrange}
              value="3"
              type="radio"
              name="range"
              checked={handleRange == "3"}
            />
            <label>Range</label>
          </div>
          <div>
            {handleRange == 3 && (
              <input
                type="text"
                placeholder="for eg. 1-25"
                onChange={handleinputRangeChange}
              />
            )}
          </div>
          <div className={classes.footer_section}>
            <button type="submit" onClick={handleFormDataSubmit}>
              Submit
            </button>
            <button onClick={onClose}>Close </button>
          </div>
        </>
      )}
    </div>
  );
};

const UpdateSubjectOverlay = ({
  exam_mode_id,
  paper_code,
  handleSubjectChange,
  exam_tier_id,
  exam_post_id
}) => {
  return (
    <>
      {ReactDom.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDom.createPortal(
        <UpdateOverlay
          onClose={handleSubjectChange}
          exam_mode_id={exam_mode_id}
          paper_code={paper_code}
          exam_tier_id={exam_tier_id}
          exam_post_id={exam_post_id}
        />,
        document.getElementById("updateoverlay-root")
      )}
    </>
  );
};
export default UpdateSubjectOverlay;
