import React from "react";
import classes from "./ProductHeader.module.css"; // Import CSS module for this component

const ProductHeader = ({ title, handleBuyProduct, price, offer }) => {
  return (
    <div className={classes.component}>
      <div className={classes.title}>{title}</div>
      <button className={classes.buynow} onClick={handleBuyProduct}>
        Buy Now
      </button>
      <div className={classes.price}>
        Special <span className={classes.highlight}>{offer}</span> till 11:59 PM today Rs {price}/-
      </div>
    </div>
  );
};

export default ProductHeader;